export const transformData = (list, vendorConfigList) => {
  const data = list.map((item, index) => {
    let tempObj = {};

    if (item.cholestrolData) {
      let cholestrolData = Object.entries(item.cholestrolData);
      cholestrolData?.map((d, i) => {
        if (d[0]) {
          let newFormValues = {};
          newFormValues[d[0]] = d[1];
          tempObj = { ...tempObj, ...newFormValues };
        }
      });
    }

    return {
      ...item,
      ...tempObj,

      name: { name: item.name, imageUrl: item.imageUrl, empId: item.empId },
    };
  });

  return data;
};

export const transformDataNew = (list) => {
  const data = list.map((item, index) => {
    let tempObj = {};

    if (item.cholestrolData) {
      let cholestrolData = Object.entries(item.cholestrolData);
      cholestrolData?.map((d, i) => {
        if (d[0]) {
          let newFormValues = {};
          newFormValues[d[0]] = d[1];
          tempObj = { ...tempObj, ...newFormValues };
        }
      });
    }

    return {
      ...item,
      ...tempObj,

      name: { name: item.name, imageUrl: item.imageUrl, empId: item.empId },
      mobile: { mobile: item.mobile, empId: item.empId },
    };
  });

  return data;
};

export const transformDataDialog = (list) => {
  const data = list.map((item, index) => {
    return {
      ...item,
      name: { name: item.name, imageUrl: item.imageUrl, empId: item.emp_id },
    };
  });

  return data;
};

export const transformDataDownLoadReport = (list) => {
  const data = list.map((item) => {
    let tempObj = {};
    if (item.cholestrolData) {
      let cholestrolData = Object.entries(item.cholestrolData);
      cholestrolData?.map((d, i) => {
        if (d[0]) {
          //console.log({ dataType: dataType });
          let newFormValues = {};
          newFormValues[d[0]] = d[1];
          tempObj = { ...tempObj, ...newFormValues };
        }
      });
    }

    return {
      ...item,
      ...tempObj,
      //name: { name: item.name, imageUrl: item.imageUrl, empId: item.empId },
      glass: item.glass ? "Yes" : item.glass === false ? "No" : "",
      cataract: item.cataract ? "Yes" : item.cataract === false ? "No" : "",
      bloodSampleCollected: item.bloodSampleCollected
        ? "Yes"
        : item.bloodSampleCollected === false
        ? "No"
        : "",
      urineSampleCollected: item.urineSampleCollected
        ? "Yes"
        : item.urineSampleCollected === false
        ? "No"
        : "",
      audiometryDone: item.audiometryDone ? "Yes" : item.audiometryDone === false ? "No" : "",
      pft: item.pft ? "Yes" : item.pft === false ? "No" : "",
      xrayDone: item.xrayDone ? "Yes" : item.xrayDone === false ? "No" : "",
      fitToWork: item.fitToWork ? "Yes" : item.fitToWork === false ? "No" : "",
      vaccination: item.vaccination ? "Yes" : item.vaccination === false ? "No" : "",
    };
  });

  return data;
};

export const transformDataOnRoll = (list) => {
  const data = list.map((item, index) => {
    return {
      id: item.id,
      empId: item.empId,
      name: { name: item.name, imageUrl: item.imageUrl, empId: item.empId },
      department: item.department,
      mobile: item.mobile,
      age: item.age,
      ///empId: item.caseOnRollData.empId,

      bloodTestUrl: item.bloodTestUrl,

      creatinine: item.caseOnRollData.creatinine,
      cbc: item.caseOnRollData.cbc,
      esr: item.caseOnRollData.esr,
      fastingPlasmaGlucoseHba1c: item.caseOnRollData.fastingPlasmaGlucoseHba1c,
      lipidProfile: item.caseOnRollData.lipidProfile,
      urineRoutine: item.caseOnRollData.urineRoutine,
      stoolRoutine: item.caseOnRollData.stoolRoutine,
      echoReport: item.caseOnRollData.echoReport,
      ecg: item.caseOnRollData.ecg,
      chestXRay: item.caseOnRollData.chestXRay,
      audiometer: item.caseOnRollData.audiometer,
      spirometry: item.caseOnRollData.spirometry,
      eyeCheckUp: item.caseOnRollData.eyeCheckUp,
    };
  });

  return data;
};

export const convertMapToObject = (data) => {
  const myMap = data ? new Map(Object.entries(data)) : null;

  const arr = myMap
    ? Array.from(myMap, function (item) {
        return { name: item[0], value: item[1] };
      })
    : [];

  return arr;
};

export const convertMapToObjectNew = (data) => {
  const myMap = data ? new Map(Object.entries(data)) : null;

  const arr = myMap
    ? Array.from(myMap, function (item) {
        return { name: item[0], value: item[1]?.count };
      })
    : [];

  return arr;
};

export const convertMapToObjectNew2 = (data) => {
  const myMap = data ? new Map(Object.entries(data)) : null;

  const arr = myMap
    ? Array.from(myMap, function (item) {
        return { name: item[0], value: item[1]?.entities };
      })
    : [];

  return arr;
};
export const convertMapToObjectSorted = (data) => {
  const myMap = data ? new Map(Object.entries(data)) : null;
  var mapAsc = myMap ? new Map([...myMap.entries()].sort()) : null;

  const arr = mapAsc
    ? Array.from(mapAsc, function (item) {
        return { name: item[0], value: item[1] };
      })
    : [];

  return arr;
};

export const listSortedByProp = (data) => {
  const myMap = data ? new Map(Object.entries(data)) : null;
  var mapAsc = myMap ? new Map([...myMap.entries()].sort()) : null;

  const arr = mapAsc
    ? Array.from(mapAsc, function (item) {
        return {
          name: item[0],
          value: item[1].percent,
          sequence: item[1].sequence,
          count: item[1].value,
        };
      })
    : [];

  const sortedArr = arr ? arr.sort((a, b) => a.sequence - b.sequence) : [];

  return sortedArr;
};

export const compare = (a, b) => {
  if (a.order < b.order) {
    return -1;
  }
  if (a.order > b.order) {
    return 1;
  }
  return 0;
};
