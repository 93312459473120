import { Box } from "@mui/material";
import { Fragment, useState } from "react";
import IssueReportedComp from "./issueReportedComp";
import IssueReportedFormComp from "./issueReportedFormComp";

const IssueReportedElementComp = ({ patientDetails }) => {
  const [isEdit, setIsEdit] = useState(false);

  return (
    <Fragment>
      <Box>
        {isEdit ? (
          <IssueReportedFormComp
            patientDetails={patientDetails}
            setIsEdit={setIsEdit}
          />
        ) : (
          <IssueReportedComp
            patientDetails={patientDetails}
            setIsEdit={setIsEdit}
          />
        )}
      </Box>
    </Fragment>
  );
};

export default IssueReportedElementComp;
