import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../assets/constantsFile";
import { getDelay } from "../../assets/utils";
import { getData } from "../../services/api/getApi";
import PrintReportComponent from "../viewReport/printReportComponent";

const ShowPrescriptions = (props) => {
  const [pDetails, setPdetails] = useState();

  const getPDetails = async () => {
    const url = BASE_URL + "doctor/appointment/details/" + props.appointmentId;

    const token = "";

    const user = await getData(url, token);

    if (user.error) {
    } else {
      const data = await user.data;

      const transformedPatients = data.map((patientData, index) => {
        return {
          index: index,
          advice: patientData.advice,
          docId: patientData.docId,
          clinicId: patientData.clinicId,
          patientId: patientData.patientId,

          patient: {
            patientId: patientData.patientId,
            name: patientData.patientName,
            gender: patientData.gender,
            age: patientData.age,
          },
          name: patientData.patientName,
          gender: patientData.gender,
          age: patientData.age,
          attended: patientData.attended, /////to be added
          issue: patientData.issue, /////to be added
          chiefComplaints: patientData.chiefComplaints,

          paymentRequired: patientData.paymentRequired,
          symptom: patientData.symptom,
          startTime: patientData.startTime,
          appointmentDelay: getDelay(patientData.startTime),
          endTime: patientData.endTime,
          date: patientData.date,
          appointmentId: patientData.appointmentId,
          observation: patientData.observation,
          slotNo: patientData.slotNo,
          consultationStartTime: patientData.consultationStartTime,
          consultationEndTime: patientData.consultationEndTime,
          consultationType: patientData.consultationType,
          caseId: patientData.caseId,
          doctorName: patientData.doctorName,
          doctorSpeciality: patientData.doctorSpeciality,
          amountPayable: patientData.amountPayable,
          amountCollected: patientData.amountCollected,
          modeOfPayment: patientData.modeOfPayment,
          patientName: patientData.patientName,
          firstConsultationDate: patientData.firstConsultationDate,
          lastConsultationDate: patientData.lastConsultationDate,

          firstName: patientData.firstName,
          middleName: patientData.middleName,
          lastName: patientData.lastName,
          fatherName: patientData.fatherName,
          dateOfBirth: patientData.dateOfBirth,

          email: patientData.email,
          mobile: patientData.mobile,
          countryCode: patientData.countryCode,

          guardianFirstName: patientData.guardianFirstName,
          guardianLastName: patientData.guardianLastName,
          guardianContactNumber: patientData.guardianContactNumber,
          source: patientData.source,

          videoURL: patientData.videoURL,
          vitals: patientData.vitals,
          prescriptionVM: patientData.prescriptionVM,
          reports: patientData.reports,
          paymentVM: patientData.paymentVM,
          personalVisitNeeded: patientData.personalVisitNeeded,
          nextAppointmentDate: patientData.nextAppointmentDate,
          patientInfoVM: patientData.patientInfoVM,

          patientDeatils: {
            advice: patientData.advice,
            patientData: patientData,
            appointmentId: patientData.appointmentId,
            patientId: patientData.patientId,
            caseId: patientData.caseId,
            docId: patientData.docId,
            videoURL: patientData.videoURL,

            patient: {
              patientId: patientData.patientId,
              name: patientData.patientName,
              gender: patientData.gender,
              age: patientData.age,
              issue: patientData.issue,
            },
            vitals: patientData.vitals,
            reports: patientData.reports,
            prescriptionVM: patientData.prescriptionVM,
            observation: patientData.observation ? patientData.observation : "",
            issue: patientData.issue,
            patientMedicalHistory: {
              patientName: patientData.patientName,
              habits: patientData.habits,
              preExistingConditions: patientData.preExistingConditions,
              drugHistory: patientData.drugHistory,
              drugAllergy: patientData.drugAllergy,
              parentalHistory: patientData.parentalHistory,
              occupation: patientData.occupation,
            },
          },

          printPrescription: patientData,
        };
      });

      setPdetails(transformedPatients[0]);
      console.log({ transformedPatients: transformedPatients });
    }
  };

  useEffect(() => {
    getPDetails();
  }, [props.patientDetails]);
  console.log({ pDetails: pDetails });

  return (
    <Box>
      <PrintReportComponent patientDetails={pDetails} />
    </Box>
  );
};

export default ShowPrescriptions;
