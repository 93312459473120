import { TextField } from "@mui/material";

const OTPComp = ({ otp, setOtp }) => {
  return (
    <TextField
      fullWidth
      size="medium"
      autoComplete="new-password"
      required
      label="ENTER OTP"
      value={otp || ""}
      onChange={(e) => {
        if (!isNaN(e.target.value) && e.target.value.length < 7) {
          setOtp(e.target.value);
        }
      }}
      InputLabelProps={{
        style: { fontSize: ".875rem" },
      }}
    />
  );
};

export default OTPComp;
