import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Grid,
  List,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import ChatMain from "../../../chatBot/chatMain";
import UpArrowButton from "../../modules/home/comps/components/upArrow/UpArrowButton";

const BottomNavbar = () => {
  const [mobile, setMobile] = useState("");
  return (
    <Fragment>
      <CssBaseline />

      <AppBar
        position="fixed"
        color="primary"
        sx={{ top: "auto", bottom: 0, background: "#990000" }}>
        <Toolbar>
          <UpArrowButton />
          <ChatMain />
          <Box sx={{ width: "100%" }}>
            <Grid container columnSpacing={2}>
              <Grid item lg={6} display="flex" justifyContent="flex-end" alignItems="center">
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Enter 10 digit mobile number"
                  sx={{
                    width: 270,
                    background: "#fff",
                    fieldset: { border: "none ! important" },
                    input: { backgroundc: "#fff" },
                  }}
                  inputProps={{ style: { color: "#4a4a4a" } }}
                  value={mobile || ""}
                  onChange={(e) => {
                    if (!isNaN(e.target.value) && e.target.value.length < 11) {
                      setMobile(e.target.value);
                    }
                  }}
                />
              </Grid>
              <Grid item lg={6} display="flex" justifyContent="flex-start" alignItems="center">
                <Button>
                  <Typography variant="h6" sx={{ color: "#fff" }}>
                    Get a free call
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};
export default BottomNavbar;
