import { Box, Card, CardContent, CardMedia, Rating, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";

const TeamCard = ({ data }) => {
  const [showHoverEffect, setShowHoverEffect] = useState(true);

  return (
    <Box
      onPointerEnter={() => setShowHoverEffect(false)}
      onPointerLeave={() => setShowHoverEffect(true)}
      sx={{
        marginBlock: "5px",
        maxWidth: 345,

        transition: "box-shadow 0.3s ease",
        boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.1)",
        "&:hover": {
          boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.1)",
          transform: "translateY(-5px)",
        },
      }}>
      <Card>
        <CardMedia sx={{ height: 300 }} image={data?.src} title="" />

        <Box sx={{ backgroundColor: "#0463FA", padding: "10px", paddingInline: "40px" }}>
          <Typography
            // variant="body2"
            sx={{ color: "#FFFFFF", fontSize: "18px", textAlign: "center" }}>
            {data?.name}
          </Typography>
          <Typography
            // variant="body2"
            sx={{ color: "#FFFFFF", fontSize: "14px", textAlign: "center" }}>
            Co-Founder
          </Typography>
          <Typography
            // variant="body2"
            sx={{ color: "#FFFFFF", fontSize: "14px", textAlign: "center" }}>
            {data?.designation1}
          </Typography>
          <Typography
            // variant="body2"
            sx={{ color: "#FFFFFF", fontSize: "14px", textAlign: "center" }}>
            {data?.designation2}
          </Typography>
          <Typography
            // variant="body2"
            sx={{ color: "#FFFFFF", fontSize: "14px", textAlign: "center" }}>
            {data?.designation3}
          </Typography>
          <Typography
            // variant="body2"
            sx={{ color: "#FFFFFF", fontSize: "14px", textAlign: "center" }}>
            {data?.education}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

export default TeamCard;
