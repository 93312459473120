import RootTemplate from "../../global/templates/rootTemplate";
import EDEnrollNewMain from "../../modules/employeeDetails/enrollNew/edEnrollNewMain";

const EDEnrollNewIndex = () => {
  return (
    <RootTemplate>
      <EDEnrollNewMain />
    </RootTemplate>
  );
};

export default EDEnrollNewIndex;
