import { Box, Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import { colors } from "../../../../../assets/color";

const StatusLegendComp = ({ size }) => {
  return (
    <Fragment>
      <Box component={Stack} direction="row" spacing={2}>
        <Typography
          sx={{
            color: " #000",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "110%",
            letterSpacing: " -0.266px",
          }}>
          Totally showing {size} records
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          display="flex"
          justifyContent="center"
          alignItems="center">
          <Box
            sx={{
              height: 16,
              width: 16,
              background: colors.completed,
              borderRadius: "1px",
              boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25)",
            }}
          />
          <Typography
            sx={{
              color: " #000",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "110%",
              letterSpacing: " -0.266px",
            }}>
            Completed
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          display="flex"
          justifyContent="center"
          alignItems="center">
          <Box
            sx={{
              height: 16,
              width: 16,
              background: colors.pending,
              borderRadius: "1px",
              boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25)",
            }}
          />
          <Typography
            sx={{
              color: " #000",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "110%",
              letterSpacing: " -0.266px",
            }}>
            Pending
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          display="flex"
          justifyContent="center"
          alignItems="center">
          <Box
            sx={{
              height: 16,
              width: 16,
              borderRadius: "1px",
              background: colors.cancelled,
              boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25)",
            }}
          />
          <Typography
            sx={{
              color: " #000",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "110%",
              letterSpacing: " -0.266px",
            }}>
            Cancelled
          </Typography>
        </Stack>
      </Box>
    </Fragment>
  );
};

export default StatusLegendComp;
