import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Icon,
  IconButton,
  Link,
  Portal,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
//import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiRef,
  GridPagination,
  GridFooterContainer,
  GridFooter,
} from "@mui/x-data-grid";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getUrlExtension } from "../../../assets/utils";
import MuiPagination from "@mui/material/Pagination";
import TableLegend from "../components/tableLegend";
import { StyledDataGrid } from "./CustomStyledDatagrid";

const CustomTypography = ({ title }) => {
  return (
    <Typography
      sx={{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeightt: 17,
        color: "#A8A8BD",
        textTransform: "capitalize",
      }}>
      {title}
    </Typography>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function CustomPagination11() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Stack
      spacing={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{ flexGrow: 1 }}>
      <MuiPagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        boundaryCount={10}
        siblingCount={1}
        // @ts-expect-error
        renderItem={(item) => (
          <PaginationItem
            type="last"
            slots={{
              previous: () => (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ArrowBackIcon />
                  <Typography sx={{ marginLeft: "10px" }}>Previous</Typography>
                </Box>
              ),
              next: () => (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ marginRight: "10px" }}>Next</Typography>
                  <ArrowForwardIcon />
                </Box>
              ),
            }}
            {...item}
          />
        )}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    </Stack>
  );
}

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
      renderItem={(item) => (
        <PaginationItem
          type="last"
          slots={{
            previous: () => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ArrowBackIcon />
                <Typography sx={{ marginLeft: "10px" }}>Previous</Typography>
              </Box>
            ),
            next: () => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ marginRight: "10px" }}>Next</Typography>
                <ArrowForwardIcon />
              </Box>
            ),
          }}
          {...item}
        />
      )}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
    "& .paxton-table--header": {
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        padding: 0,
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 600,
        textTransform: "uppercase",
        fontSize: "16px",
      },
    },
    "& .paxton-table--row": {
      borderBottom: "0.5px solid #C5C5D3",
      margintop: 6,
      marginBottom: 6,
      backgroundColor: "#fff",
      paddingTop: 6,
      paddingBottom: 6,
      boxSizing: "content-box",
    },
    "& .MuiDataGrid-cell": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: 12,
      lineHeightt: 15,
      color: "##383838",
      textTransform: "capitalize",
      boxSizing: "none",
      borderBottom: "none ! important",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer": {
      border: "none",
    },
    "& .MuiDataGrid-viewport": {
      maxHeight: "unset !important",
    },
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "670px !important",
    },
    //'& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {display: 'none' }
  },
}));

const CustomDataPanels = ({ corpId, apiRef, setApiRef, dataList, columns }) => {
  console.log({ columns });
  const navigate = useNavigate();

  const classes = useStyles();

  //const apiRef = useGridApiRef();

  useEffect(() => {
    setApiRef(apiRef);
  }, [apiRef]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = (url) => {
    const ext = getUrlExtension(url).toLowerCase();
    if (ext === "pdf") {
      window.open(url);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [selectedImage, setSelectedImage] = useState("");
  return (
    <Fragment>
      <Portal>
        <Dialog open={open} onClose={handleClose} maxWidth={"xl"} fullWidth={true}>
          <DialogContent>
            <Box
              sx={{ minHeight: "70vh" }}
              display="flex"
              justifyContent="center"
              alignItems="center">
              {selectedImage ? <img width="100%" src={selectedImage} /> : "No Document."}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Portal>

      {/* <Box
        style={{
          height: "63vh",
          width: "100%",
          padding: "20px",
          backgroundColor: "#FFFFFF",
          border: "0.5px solid grey",
          borderRadius: "16px",
          marginBlock: "20px",
        }}
        >
        <Box style={{ display: "flex", height: "100%" }}>
          <Box
            sx={{
              flexGrow: 1,
              width: "100%",
              "& .super-app.negative": {
                backgroundColor: "rgba(157, 255, 118, 0.49)",
                color: "#1a3e72",
                fontWeight: "600",
              },
              "& .super-app.positive": {
                backgroundColor: "#d47483",
                color: "#1a3e72",
                fontWeight: "600",
              },
            }}> */}
      <StyledDataGrid
        sx={{
          border: "none",
        }}
        columnHeaderHeight={45}
        apiRef={apiRef}
        rowHeight={40}
        rows={dataList}
        columns={columns}
        // className={classes.root}
        // getRowClassName={() => "paxton-table--row"}
        // getCellClassName={() => "paxton-table--cell"}
        disableRowSelectionOnClick
        pagination
        slots={{
          //footer: CustomFooterStatusComponent,
          pagination: CustomPagination,
        }}
      />
      {/* </Box>
        </Box>
      </Box> */}
    </Fragment>
  );
};

export default CustomDataPanels;

export function CustomFooterStatusComponent(props) {
  return (
    <GridFooterContainer>
      <Grid container>
        <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="center">
          <GridFooter
            sx={{
              border: "none", // To delete double border.
              height: 70,
            }}
          />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="flex-start" alignItems="center">
          <Box>
            <TableLegend />
          </Box>
        </Grid>
      </Grid>
    </GridFooterContainer>
  );
}
