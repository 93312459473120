import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MuiPagination from "@mui/material/Pagination";
import {
  DataGrid,
  GridFooterContainer,
  GridPagination,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  gridClasses,
  gridFilteredSortedRowIdsSelector,
  gridPageCountSelector,
  gridPageSelector,
  gridRowTreeSelector,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
} from "@mui/x-data-grid";
import { Box, Button, PaginationItem, Paper, Typography, createSvgIcon } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { styled } from "@mui/system";
import { StyledDataGrid } from "../../corporatePortal/global/customDatagrids/CustomStyledDatagrid";

const StyledDataGrid1 = styled(DataGrid)(({ theme }) => ({
  border: 0,
  width: "100%",
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#F5F5FF",
    paddingInline: 30,
  },
  "& .MuiDataGrid-row": {
    paddingInline: 30,
  },
  color: theme.palette.mode === "light" ? "rgba(0,0,0,.85)" : "rgba(255,255,255,0.85)",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  WebkitFontSmoothing: "auto",
  letterSpacing: "normal",
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: `1px solid ${theme.palette.mode === "light" ? "#f0f0f0" : "#303030"}`,
  },
  "& .MuiDataGrid-cell": {
    color: theme.palette.mode === "light" ? "rgba(0,0,0,.85)" : "rgba(255,255,255,0.65)",
    border: "none",
  },

  "& .MuiPaginationItem-root": {
    borderRadius: 0,
  },
}));

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      variant="outlined"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
      renderItem={(item) => (
        <PaginationItem
          type="last"
          slots={{
            previous: () => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ChevronLeftIcon />
              </Box>
            ),
            next: () => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ChevronRightIcon />
              </Box>
            ),
          }}
          {...item}
        />
      )}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

function CustomToolbar() {
  const apiRef = useGridApiContext();

  const getRowsWithGroups = ({ apiRef }) => gridFilteredSortedRowIdsSelector(apiRef);

  const getRowsWithoutGroups = ({ apiRef }) => {
    const rows = gridFilteredSortedRowIdsSelector(apiRef);
    const tree = gridRowTreeSelector(apiRef);
    return rows.filter((rowId) => tree[rowId].type !== "group");
  };

  const ExportIcon = createSvgIcon(
    <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
    "SaveAlt"
  );

  const handleExport = (options) => apiRef.current.exportDataAsCsv(options);

  const buttonBaseProps = {
    color: "primary",
    size: "small",
    startIcon: <ExportIcon sx={{ color: "#FFF" }} />,
  };

  return (
    <GridToolbarContainer sx={{ display: "flex", justifyContent: "flex-end" }}>
      {/* <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector /> */}
      <Button
        variant="contained"
        {...buttonBaseProps}
        onClick={() => handleExport({ getRowsToExport: getRowsWithoutGroups })}>
        Download
      </Button>
    </GridToolbarContainer>
  );
}

const CustomDataGridLayout = ({
  rows = [],
  columns = [],
  apiRef,
  dataGridHeight,
  styles,
  rowHeight,
  getRowId,
  getRowClassName,
  density = "standard",
}) => {
  return (
    <Box sx={{ paddingBlock: 1, marginTop: 2 }}>
      <Paper
        elevation={3}
        sx={{
          boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.1)",
          "& .super-app-theme--header": {
            backgroundColor: "#F5F5FF",
          },
        }}>
        <StyledDataGrid1
          sx={{
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
              outline: "none",
            },
            height: dataGridHeight || "70vh",
            ...styles,
          }}
          rowHeight={rowHeight}
          rows={rows}
          getRowId={getRowId}
          apiRef={apiRef}
          density={density}
          columns={columns}
          disableSelectionOnClick
          disableRowSelectionOnClick
          slots={{
            pagination: CustomPagination,
            // toolbar: CustomToolbar,
          }}
          getRowClassName={getRowClassName}
        />
      </Paper>
    </Box>
  );
};

export default CustomDataGridLayout;
