import { Fragment } from "react";
import { useLocation, useParams } from "react-router-dom";
import MainPageLayoutWithBackTitleLeft from "../../../global/templates/mainPageLayoutWithBackTitleLeft";
import ViewTicketDetailsMain from "./ticketView/viewTicketDetailsMain";

const TicketViewMain = () => {
  const { ticketId } = useParams();
  const { state } = useLocation();
  console.log({ ticketId, state });

  return (
    <Fragment>
      <MainPageLayoutWithBackTitleLeft title="Tickets View">
        <ViewTicketDetailsMain data={state} />
      </MainPageLayoutWithBackTitleLeft>
    </Fragment>
  );
};

export default TicketViewMain;
