import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Portal,
  Typography,
  colors,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useState } from "react";
import { useEffect } from "react";
import StatusTrackerDetailGrid from "../../../../global/customDatagrids/statusTrackerDetailGrid";

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#127DDD",
  "#D45FFF",
  "#DDFFDF",
];

let renderLabel = function (entry) {
  return `${entry.name}(${entry.value}%)`;
};

const CustomizedAxisTick = (props) => {
  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-20)"
        fontSize={12}
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomTooltipContent = ({ active, payload, label, tooltipTitle }) => {
  if (active) {
    const data = payload?.[0]?.payload; // Get the data of the hovered bar

    return (
      // <Tooltip>
      <Box sx={{ backgroundColor: "#FFFFFF", padding: "10px" }}>
        {label ? <Typography>{`${label}`}</Typography> : null}
        {data?.value ? (
          <Typography>{`${"Count"}: ${data?.value}`}</Typography>
        ) : null}
        {data?.percent ? (
          <Typography>{`${tooltipTitle ? tooltipTitle : "Percentage"}: ${
            data?.percent
          }`}</Typography>
        ) : null}
        {data?.count ? (
          <Typography>{`Count: ${data?.count}`}</Typography>
        ) : null}
      </Box>
      // </Tooltip>
    );
  }

  return null;
};

const CustomTooltipContent2 = ({ active, payload, label, tooltipTitle }) => {
  if (active) {
    const data = payload?.[0]?.payload;
    console.log({ dataToolTip: data });
    return (
      <Box sx={{ backgroundColor: "#FFFFFF", padding: "10px" }}>
        {label ? <Typography>{`${label}`}</Typography> : null}
        {data?.value ? (
          <Typography>{`${"Count"}: ${data?.value}`}</Typography>
        ) : null}
        {data?.percent ? (
          <Typography>{`${tooltipTitle ? tooltipTitle : "Percentage"}: ${
            data?.percent
          }`}</Typography>
        ) : null}
        {data?.count ? (
          <Typography>{`Count: ${data?.count}`}</Typography>
        ) : null}
      </Box>
    );
  }

  return null;
};

const CustomBarChart = ({
  height,
  barColor,
  data,
  title,
  dialogData,
  tooltipTitle,
  popupData,
  setSelectedBar,
  disableClickable,
}) => {
  console.log({ dialogData });
  const [apiRef, setApiRef] = useState(null);
  const exportCSV = () => {
    apiRef.current.exportDataAsCsv();
  };
  const [open, setOpen] = useState(false);
  const [selectedChart, setSelectedChart] = useState("");
  const [selectedBarData, setSelectedBarData] = useState([]);
  console.log({ setSelectedBar });
  const handleOpen = (title) => {
    setOpen(true);
    setSelectedChart(title);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedChart("");
    setSelectedBarData([]);
  };
  console.log({ HLELLLELE: data });
  console.log({ mmmmmm: dialogData?.[selectedChart] });
  useEffect(() => {
    if (dialogData) {
      const data = dialogData[selectedChart];
      console.log({ data });
      if (data) {
        setSelectedBarData(data);
      } else {
        console.log("Selected chart not found in dialogData");
      }
    }
  }, [selectedChart]);
  console.log({ popupData });

  useEffect(() => {
    if (popupData) {
      setSelectedBarData(popupData);
    }
  }, [popupData]);

  console.log({ selectedBarData });
  console.log({ selectedChart });
  console.log({ data });

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        border: "1px solid grey",
        height: height || "300px",
        paddingInline: "10px",
        paddingBlock: "20px",
        borderRadius: "10px",
      }}
    >
      <Typography
        textAlign="left"
        sx={{
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "120%",
        }}
      >
        {title}
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={730}
          height={250}
          data={data}
          onClick={(bar) => {
            console.log({ bar });
            if (disableClickable) {
              return;
            } else {
              handleOpen(bar?.activeLabel);
              if (setSelectedBar) {
                setSelectedBar(bar?.activeLabel);
              }
            }
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            style={{ fontSize: 12 }}
            interval={0}
            tick={CustomizedAxisTick}
            height={70}
          />
          <YAxis />

          <Tooltip
            content={<CustomTooltipContent2 tooltipTitle={tooltipTitle} />}
          />

          <Legend />
          <Bar
            dataKey="value"
            fill={barColor || "#82ca9d"}
            barSize={130}
            name="Count"
          >
            <LabelList dataKey="value" position="top" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      {/* <Typography textAlign="center"> {title} </Typography> */}

      <Portal>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={"xl"}
          fullWidth={true}
        >
          <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
            {title}
          </DialogTitle>
          <Typography
            variant="h6"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {selectedChart}
          </Typography>
          <Box sx={{ marginLeft: "20px" }}>
            <Button
              variant="contained"
              size="small"
              sx={{ height: 40, borderRadius: 4 }}
              onClick={exportCSV}
            >
              Download CSV
            </Button>
          </Box>
          <DialogActions sx={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
          <DialogContent>
            <StatusTrackerDetailGrid
              setApiRef={setApiRef}
              employeeList={selectedBarData || []}
            />
          </DialogContent>
        </Dialog>
      </Portal>
    </Box>
  );
};

export default CustomBarChart;
