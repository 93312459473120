import { Autocomplete, Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";

export const DepartmetsAndtests = ({
  selectedTest,
  setTelectedTest,
  testList,

  selectedDepartment,
  setSelectedDepartment,
  departmentList,

  analyticsStatic,
  setAnalytics,

  selectStatus,
  setSelectStatus,
  statusList,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item lg>
        <Box
          sx={{
            boxSizing: "border-box",
            background: "#FFFFFF",
            border: "0.5px solid #979797",
            borderRadius: 5,
            px: 3,
            py: 0.5,
          }}>
          <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center">
            <Button
              size="small"
              onClick={() => {
                setTelectedTest(null);
                setSelectedDepartment(null);
              }}>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: 13,
                  color: "#404040",
                  textTransform: "capitalize",
                }}>
                Show All
              </Typography>
            </Button>
            <Box
              sx={{
                width: 0,
                height: 30,
                border: "0.5px solid #6B6B6B",
                //transform: "rotate(90deg)",
              }}
            />
            <Autocomplete
              value={selectedDepartment}
              onChange={(event, newValue) => {
                setSelectedDepartment(newValue);
                console.log({ newValue });
              }}
              sx={{ width: 600 }}
              size="small"
              fullWidth
              disablePortal
              options={departmentList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ "& fieldset": { border: "none" } }}
                  label="Select Department"
                  InputLabelProps={{
                    style: {
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: 13,
                      color: "#404040",
                    },
                  }}
                />
              )}
            />

            <Box
              sx={{
                width: 0,
                height: 30,
                border: "0.5px solid #6B6B6B",
                //transform: "rotate(90deg)",
              }}
            />

            <Autocomplete
              value={selectedTest}
              onChange={(event, newValue) => {
                setTelectedTest(newValue);
                if (newValue) {
                  setAnalytics(
                    analyticsStatic.filter((item) =>
                      newValue.name === "ALL" ? item : item.name === newValue.name
                    )
                  );
                } else {
                  setAnalytics(analyticsStatic);
                }
              }}
              sx={{ width: 800 }}
              getOptionLabel={(item) => item.name}
              size="small"
              fullWidth
              disablePortal
              options={testList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ "& fieldset": { border: "none" } }}
                  label="Select Test"
                  InputLabelProps={{
                    style: {
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: 13,
                      color: "#404040",
                    },
                  }}
                />
              )}
            />

            <Box
              sx={{
                width: 0,
                height: 30,
                border: "0.5px solid #6B6B6B",
                //transform: "rotate(90deg)",
              }}
            />

            <Autocomplete
              value={selectStatus}
              onChange={(event, newValue) => {
                setSelectStatus(newValue);
              }}
              sx={{ width: 600 }}
              getOptionLabel={(item) => item.name}
              size="small"
              fullWidth
              disablePortal
              options={statusList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ "& fieldset": { border: "none" } }}
                  label="Select Status"
                  InputLabelProps={{
                    style: {
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: 13,
                      color: "#404040",
                    },
                  }}
                />
              )}
            />
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

export const Departments = ({ selectedDepartment, setSelectedDepartment, departmentList }) => {
  return (
    <Grid container spacing={2}>
      <Grid item lg>
        <Box
          sx={{
            boxSizing: "border-box",
            background: "#FFFFFF",
            border: "0.5px solid #979797",
            borderRadius: 5,
            px: 3,
            py: 0.5,
          }}>
          <Stack direction="row" display="flex" justifyContent="space-between" alignItems="center">
            <Button
              size="small"
              onClick={() => {
                setSelectedDepartment(null);
              }}>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: 13,
                  color: "#404040",
                  textTransform: "capitalize",
                }}>
                Show All
              </Typography>
            </Button>
            <Box
              sx={{
                width: 0,
                height: 30,
                border: "0.5px solid #6B6B6B",
                //transform: "rotate(90deg)",
              }}
            />
            <Autocomplete
              value={selectedDepartment}
              onChange={(event, newValue) => {
                setSelectedDepartment(newValue);
                console.log({ newValue });
              }}
              sx={{ width: 600 }}
              size="small"
              fullWidth
              disablePortal
              options={departmentList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ "& fieldset": { border: "none" } }}
                  label="Select Department"
                  InputLabelProps={{
                    style: {
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: 13,
                      color: "#404040",
                    },
                  }}
                />
              )}
            />
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};
