import { CssBaseline } from "@mui/material";
import { Fragment } from "react";
import CorpHome from "../modules/home/corpHome";
import BottomNavbar from "../global/footers/bottomNavbar";
import NavBarAfterLoginODNew from "../global/headers/navBarAfterLoginODNew";

const CorpLanding = ({ unauthenticated }) => {
  console.log({ unauthenticated });
  return (
    <Fragment>
      <CssBaseline />
      <NavBarAfterLoginODNew unauthenticated />
      <CorpHome topMarginValue={9} />
      <BottomNavbar />
    </Fragment>
  );
};

export default CorpLanding;
