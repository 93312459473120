import React, { Fragment, useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Menu,
  MenuItem,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../assets/logo.png";
import { useTheme } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import ContactUsDialog from "./subComps/contactUsDialog";

const GlobalTopbar = ({ scrollToSection, moveCursorToForm }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const pages = [
    { title: "Clients", onClick: () => scrollToSection("clients") },
    // { title: "About us", onClick: () => scrollToSection("aboutUs") },
    { title: "Services", onClick: () => scrollToSection("whatWeOffer") },
    // {
    //   title: "Prefer us",
    //   onClick: () => scrollToSection("specialization"),
    // },
    // {
    //   title: "Specialize in",
    //   onClick: () => scrollToSection("specialization"),
    // },
    // { title: "Best for you", onClick: () => scrollToSection("whatWeDo") },
    // { title: "Prefer us", onClick: () => scrollToSection("whyPeoplePreferUs") },
    // { title: "Testimonials", onClick: () => scrollToSection("testimonials") },
    { title: "Contact Us", onClick: () => scrollToSection("contactUs") },
  ];

  const loginLinks = [
    { title: "Corporate Login", path: "/orglogin" },
    { title: "Doctor Login", path: "/login" },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleButtonClick = () => {
    if (moveCursorToForm.current) {
      moveCursorToForm.current();
    }
  };

  const drawerList = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}>
      <List>
        {pages.map((text) => (
          <ListItem button key={text.title} onClick={text.onClick}>
            <ListItemText primary={text.title} />
          </ListItem>
        ))}

        {loginLinks.map((link, index) => (
          <ListItem button component={Link} to={link.path} key={index}>
            <ListItemText primary={link.title} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Fragment>
      <AppBar
        position="static"
        elevation={3}
        sx={{ padding: { xs: 2, sm: 2, md: 1 }, backgroundColor: "#FFF" }}>
        <Toolbar>
          {(isXs || isSm) && (
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon sx={{ color: "#000" }} />
            </IconButton>
          )}

          <Grid container spacing={1} display="flex" alignItems="center">
            <Grid item xs={6}>
              <Box
                sx={{
                  flexGrow: 4,
                  display: "flex",
                  justifyContent: isXs || isSm ? "center" : "flex-start",
                }}>
                <Box
                  component={"img"}
                  src={logo}
                  alt="Logo"
                  sx={{ height: "42px", marginLeft: { xs: 0, sm: 0, md: "40px" } }}
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                sx={{
                  display: { lg: "flex", md: "none", sm: "none", xs: "none" },
                  alignItems: "center",
                  justifyContent: "flex-end",
                  //flexGrow: 1,
                }}>
                {pages.map((page) => (
                  <Button
                    sx={{
                      color: "#000",
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "17.6px",
                      letterSpacing: "0.304px",
                    }}
                    key={page.title}
                    onClick={page.onClick}>
                    {page.title}
                  </Button>
                ))}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                sx={{
                  flexGrow: 4,
                  display: { xs: "none", sm: "none", md: "flex" },
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "10px",
                  marginRight: "40px",
                }}>
                <Button
                  onClick={handleButtonClick}
                  sx={{
                    borderRadius: "7px",
                    border: "1px solid #3E48EE",
                    color: "#3E48EE",
                    backgroundColor: "#FFF",
                    ":hover": {
                      backgroundColor: "#FFF",
                    },
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "110%",
                    letterSpacing: "-0.304px",
                  }}
                  variant="contained">
                  Get Quote
                </Button>
                {/* <ContactUsDialog buttonSource="GETQUOTE" /> */}
                <Button
                  onClick={() => navigate("/orglogin")}
                  sx={{
                    borderRadius: "7px",
                    backgroundColor: "#3E48EE",
                    color: "#FFF",
                    paddingInline: "40px",
                    ":hover": {
                      backgroundColor: "#3E48EE",
                    },
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "110%",
                    letterSpacing: "-0.304px",
                  }}
                  variant="contained">
                  Login
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  sx={{ mt: 2 }}>
                  {loginLinks.map((link, index) => (
                    <MenuItem key={index} onClick={handleMenuClose}>
                      <Link
                        to={link.path}
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          color: "#2868D3",
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}>
                        {link.title}
                      </Link>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerList()}
      </Drawer>
    </Fragment>
  );
};

export default GlobalTopbar;
