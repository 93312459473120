import { Box, Divider, Grid, Stack, Typography } from "@mui/material";

const PrintDiagnosis = (props) => {
  const diagnosisInfo = props.diagnosisInfo;
  return (
    <Grid container>
      {diagnosisInfo ? (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography sx={{ marginTop: "20px", fontWeight: 700,lineHeight: "13px",color: "#404040", }}>
            On Examination(O/E)
          </Typography>
          <Typography
            sx={{ marginTop: "10px", borderBottom: "1px solid #000000" }}
          >
            {diagnosisInfo}
          </Typography>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
};

export default PrintDiagnosis;
