import { Box, Grid, Stack, Typography } from "@mui/material";

const NoOfCasesComp = ({ data }) => {
  let width1 = data[0]?.percent;
  let width2 = data[1]?.percent;

  const total = data.reduce((acc, entry) => acc + entry.value, 0);

  console.log({ dataqweqw: data, total });
  return (
    <Box sx={{ border: 0, p: 2, borderRadius: 3 }}>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <Stack
            direction="row"
            flex={1}
            display="flex"
            justifyContent="center"
            spacing={2}
            height={30}>
            <Typography># Cases</Typography>
            <Box component={Stack} direction="row" flex={1} height={30}>
              <Box
                sx={{
                  flex: 1,
                  height: "100%",
                  background: "#42BD53",
                  width: `${width1}%`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}></Box>
              <Box
                sx={{
                  height: "100%",
                  background: "#F66",
                  width: `${width2}%`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}></Box>
            </Box>
          </Stack>
        </Grid>
        <Grid item lg={12}>
          <Stack direction="row" display="flex" justifyContent="space-evenly">
            <Typography
              sx={{
                fontSize: 12,
                color: "#000",
                fontWeight: 700,
              }}>{`OPD (${width1}%)`}</Typography>
            <Typography
              sx={{
                fontSize: 12,
                color: "#000",
                fontWeight: 700,
              }}>{`INJURY (${width2}%)`}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NoOfCasesComp;
