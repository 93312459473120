import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  Box,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { BASE_URL } from "../../../../assets/constantsFile";
import { getData } from "../../../../services/api/getApi";
import { saveData } from "../../../../services/api/postApi";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import saveIcon from "../../../../assets/images/svg/formEdit.svg";
import editIcon from "../../../../assets/images/svg/editIcon.svg";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const style = {
  row: {
    display: "flex",
    alignItems: "center",
    px: 1,
    py: 0.87,
    borderRadius: "16.39px",
    background: "#F4FBFF",
  },
  title: {
    fontFamily: "Roboto",
    fontWeight: "600",
    fontSize: "1rem",
    lineHeight: "19px",
    color: "#127DDD",
  },
  textRow: { display: "flex", alignItems: "center" },
  text: {
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "19px",
    px: 2,
    py: 1,
  },
  border: { height: "36px", borderLeft: "1px solid #127ddd" },
  icon: { mt: 0.25, display: "flex", justifyContent: "center" },
};

const PrescribeNewTestComp = ({
  patientId,
  appointmentId,
  caseId,
  reportItems,
}) => {
  const [rawListOfTests, setRawListOfTests] = useState([]);
  const [listOfTests, setListOfTests] = useState([]);

  const [tests, setTests] = useState(reportItems ? reportItems : []);

  useEffect(() => {
    getAllTests();
  }, []);

  useEffect(() => {
    setListOfTests(
      rawListOfTests.filter(
        (ar) => !tests.find((rm) => rm.reportName === ar.reportName)
      )
    );
  }, [tests]);

  const getAllTests = async () => {
    const url = BASE_URL + "lab/tests/all";
    const tests = await getData(url, "");

    if (tests.error) {
    } else {
      const data = tests.data;

      const uniqueList = data.filter(
        (tag, index, array) =>
          array.findIndex((t) => t.reportName == tag.reportName) == index
      );

      setRawListOfTests(uniqueList);
      reportItems && reportItems.length > 0
        ? setListOfTests(
            uniqueList.filter(
              (ar) => !reportItems.find((rm) => rm.reportName === ar.reportName)
            )
          )
        : setListOfTests(uniqueList);
    }
  };

  const addReports = (value) => {
    let o = {
      reports: value,
      patientId: patientId,
      docId: localStorage.getItem("DOCID"),
      appointmentId: appointmentId,
      caseId: caseId,
    };

    console.log({ "report object to be saved": o });
    saveTest(o);
  };

  const saveTest = async (obj) => {
    const user = await saveData(
      BASE_URL + "doctor/prescription/tests",
      obj,
      ""
    );

    if (user.error) {
      console.log({ "error saving": user.error });
    } else {
      setisEdit(false);
      console.log({ " saved successfully": user.data });
    }
  };

  const [isEdit, setisEdit] = useState(
    reportItems && reportItems.length > 0 ? false : true
  );
  return (
    <Fragment>
      <Grid container sx={style.row}>
        <Grid item lg={3}>
          <Typography sx={style.title}>Required Tests</Typography>
        </Grid>
        <Grid item lg={8} sx={style.textRow}>
          <Box sx={style.border}></Box>
          {isEdit && (
            <Autocomplete
              sx={{ py: 1, px: 0.5 }}
              disableCloseOnSelect
              disableClearable
              size="small"
              fullWidth
              multiple
              freeSolo
              id="tags-outlined"
              getOptionLabel={(option) => option.reportName}
              options={listOfTests}
              filterSelectedOptions
              value={tests}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.reportName}
                </li>
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    sx={{ fontSize: 10 }}
                    variant="outlined"
                    label={option.reportName}
                    {...getTagProps({ index })}
                  />
                ))
              }
              onChange={(event, value) => {
                setTests(value);
                console.log(value);
              }}
              onInputChange={(event, newValue) => {
                console.log(newValue);

                const customTest = [
                  {
                    reportName: newValue ? newValue : "custom",
                    prescribedDate: new Date(),
                    reportURL: null,
                  },
                ];
                setListOfTests([...rawListOfTests, ...customTest]);

                //listOfTests.push(customTest);
              }}
              ListboxProps={{ sx: { fontSize: 11 } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select from all tests..."
                  placeholder="All Reports"
                  InputLabelProps={{
                    style: {
                      fontSize: ".875rem",
                      fontWeight: "500",
                      color: "#127DDD",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root:hover": {
                      "& > fieldset": {
                        borderColor: "#127DDD",
                      },
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#127DDD",
                      },
                    },
                  }}
                />
              )}
            />
          )}
          {!isEdit && (
            <Typography sx={style.text} variant="caption">
              {tests.map(
                (item, index) =>
                  item.reportName + (index < tests.length - 1 ? ", " : "")
              )}
            </Typography>
          )}
        </Grid>
        <Grid item lg={1} sx={style.icon}>
          {isEdit && (
            <IconButton
              aria-label="update"
              size="small"
              onClick={() => addReports(tests)}
            >
              <img src={saveIcon} sx={{ width: "100%" }} />
            </IconButton>
          )}
          {!isEdit && (
            <IconButton
              aria-label="edit"
              size="small"
              onClick={() => {
                setisEdit(true);
              }}
            >
              <img src={editIcon} sx={{ width: "100%" }} />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default PrescribeNewTestComp;
