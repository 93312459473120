import { Avatar, Box, Link, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import logo from "../../../logo.svg";
import { Fragment } from "react";
import useWindowDimensions from "../../../common/hooks/useWindowDimension";
import { HashLink as LinkNew } from "react-router-hash-link";
const instaLink = "https://instagram.com/uno.care?igshid=YmMyMTA2M2Y=";

const DigitalMarketingFooter = () => {
  const { width } = useWindowDimensions();
  return (
    <Fragment>
      {/* {width < 600 && (
        <Box
          sx={{
            background: "linear-gradient(180deg, #61B2FB 0%, #D4F1FF 100%)",
          }}></Box>
      )}
      {width >= 600 && ( */}
      <Box
        sx={{
          background: "linear-gradient(180deg, #61B2FB 0%, #D4F1FF 100%)",
          height: "175px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}></Box>
      {/* )} */}
    </Fragment>
  );
};

export default DigitalMarketingFooter;
