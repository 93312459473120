import RootTemplate from "../../global/templates/rootTemplate";
import PreEmpStatusTrackerMain from "../../modules/preemployment/statusTracker/preEmpStatusTrackerMain";

const PreEmpStatusTrackerIndex = () => {
  return (
    <RootTemplate>
      <PreEmpStatusTrackerMain />
    </RootTemplate>
  );
};

export default PreEmpStatusTrackerIndex;
