import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { addDays } from "date-fns";
import calendar from "../../../../../assets/images/calendar.png";
import { getData } from "../../../../../../services/api/getApi";
import { deleteData, saveData } from "../../../../../../services/api/postApi";
import { BASE_URL } from "../../../../../../assets/constantsFile";

const Notes = ({ patients }) => {
  const appointmentId = patients?.appointmentId;
  const caseId = patients?.caseId;
  const patientId = patients?.patientInfoVMV2?.patientId;

  const [diagonsis, setDiagonsis] = useState("");
  const [advice, setAdvice] = useState("");
  const [surgeryRequired, setSurgeryRequired] = useState("");
  const [personalVisit, setPersonalVisit] = useState(false);
  const [nextVisit, setNextVisit] = useState(false);
  const [admission, setAdmission] = useState(false);
  const [personalVisitDays, setPersonalVisitDays] = useState("");
  const [NextVisitDays, setNextVisitDays] = useState("");

  const [personalVisitDate, setPersonalVisitDate] = useState("");
  const [nextVisitDate, setNextVisitDate] = useState("");

  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [openNotice, setOpenNotice] = useState(false);

  const getPDetails = async () => {
    if (patientId && appointmentId && caseId) {
      const url =
        BASE_URL +
        "patient/detailed/" +
        patientId +
        "?appointmentId=" +
        appointmentId +
        "&caseId=" +
        caseId;

      const data = await getData(url);
      if (data && data.data) {
        console.log("SUCCESS GET", data.data);
        const data1 = data?.data?.notes;
        setAdmission(data1.admission);
        setAdvice(data1.advice);
        setDiagonsis(data1.diagnosis);
        setNextVisit(data1.nextVisit);
        setNextVisitDate(data1.nextVisitDate);
        setPersonalVisit(data1.personalVisit);
        setPersonalVisitDate(data1.personalVisitDate);
        setSurgeryRequired(data1.surgeryText);
      } else if (data && data.error) {
        console.log("SUCCESS GET", data.error);
      }
    }
  };
  console.log({ nextVisit, nextVisitDate });
  useEffect(() => {
    getPDetails();
  }, [patients]);

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };

  useEffect(() => {
    const enteredNumber = parseInt(personalVisitDays);
    if (!isNaN(enteredNumber)) {
      const currentDate = new Date();
      const modifiedDate = addDays(currentDate, enteredNumber);
      setPersonalVisitDate(modifiedDate.toISOString().slice(0, 10));
    } else {
      setPersonalVisitDate("");
    }
  }, [personalVisitDays]);

  useEffect(() => {
    const enteredNumber = parseInt(NextVisitDays);
    if (!isNaN(enteredNumber)) {
      const currentDate = new Date();
      const modifiedDate = addDays(currentDate, enteredNumber);
      setNextVisitDate(modifiedDate.toISOString().slice(0, 10));
    } else {
      setNextVisitDate("");
    }
  }, [NextVisitDays]);

  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  // Handle personal visit checkbox
  const handlePersonalVisitChange = (event) => {
    const isChecked = event.target.checked;
    setPersonalVisit(isChecked);
    if (isChecked) {
      handleOpen1();
    } else {
      handleClose1();
    }
  };

  // Handle next visit checkbox
  const handleNextVisitChange = (event) => {
    const isChecked = event.target.checked;
    setNextVisit(isChecked);
    if (isChecked) {
      handleOpen2();
    } else {
      handleClose2();
    }
  };

  const handlePersonalVisit = async () => {
    const obj = {
      appointmentId: appointmentId,
      personalVisitNeeded: personalVisit,
      personalAppointmentDate: personalVisitDate,
    };
    const url = BASE_URL + "clinic/markPersonalVisitNeeded";
    const result = await saveData(url, obj);
    if (result && result.data) {
      console.log("SUCCESS POST", result.data);
      handleClose1();
    } else if (result && result.error) {
      console.log("ERROR POST", result.error);
    }
  };

  const handleNextVisit = async () => {
    const url =
      BASE_URL + `cms/markNextVisitNeeded/${appointmentId}?nextAppointmentDate=${nextVisitDate}`;
    const result = await saveData(url, "");
    if (result && result.data) {
      console.log("SUCCESS POST", result.data);
      setOpen2(false);
    } else if (result && result.error) {
      console.log("ERROR POST", result.error);
    }
  };

  const Obj = {
    appointmentId: appointmentId,
    diagnosis: diagonsis,
    advice: advice,
    personalVisit: personalVisit,
    personalVisitDate: personalVisitDate,
    nextVisit: nextVisit,
    nextVisitDate: nextVisitDate,
    admission: admission,
    surgeryRequired: surgeryRequired === "" ? false : true,
    surgeryText: surgeryRequired,
  };
  console.log({ MYOBJECT: Obj });
  const handleNotes = async () => {
    const url = BASE_URL + "doctor/notes";
    const result = await saveData(url, Obj);
    if (result && result.data) {
      console.log("SUCCESS POST", result.data);
      setSeverity("success");
      setMessage("Saved Successfully");
      setOpenNotice(true);
    } else if (result && result.error) {
      console.log("ERROR POST", result.error);
      setSeverity("error");
      setMessage("Error Occured");
      setOpenNotice(true);
    }
  };

  return (
    <Fragment>
      <Snackbar open={openNotice} autoHideDuration={1000} onClose={handleCloseNotice}>
        <Alert onClose={handleCloseNotice} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#000000",
              marginBlock: "10px",
            }}>
            Diagnois
          </Typography>
          <TextField
            multiline
            sx={{
              background: "#fff",
              color: "#127DDD",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
              "& input::placeholder": {
                color: "#777777",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
              },
            }}
            size="small"
            fullWidth
            placeholder="Enter Diagonsis"
            value={diagonsis || ""}
            onChange={(e) => setDiagonsis(e.target.value)}
            inputProps={{
              style: {
                height: 50,
                color: "#777777",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#000000",
              marginBlock: "10px",
            }}>
            Advice
          </Typography>
          <TextField
            multiline
            sx={{
              background: "#fff",
              color: "#127DDD",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
              "& input::placeholder": {
                color: "#777777",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
              },
            }}
            size="small"
            fullWidth
            placeholder="Enter Advice"
            value={advice || ""}
            onChange={(e) => setAdvice(e.target.value)}
            inputProps={{
              style: {
                height: 50,
                color: "#777777",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <Grid container spacing={2}>
            <Grid item xs={4} lg={4}>
              <FormControlLabel
                required
                control={
                  <Checkbox
                    size="medium"
                    checked={personalVisit || false}
                    onChange={handlePersonalVisitChange}
                  />
                }
                label="Personal Visit"
                style={{
                  fontWeight: "400",
                  fontSize: "10px",
                  lineHeight: "15px",
                  color: "#000000",
                  marginBlock: "10px",
                }}
              />
            </Grid>
            <Grid item xs={4} lg={4}>
              <FormControlLabel
                required
                control={
                  <Checkbox
                    size="medium"
                    checked={nextVisit || false}
                    onChange={handleNextVisitChange}
                  />
                }
                label="Next Visit"
                style={{
                  fontWeight: "400",
                  fontSize: "10px",
                  lineHeight: "15px",
                  color: "#000000",
                  marginBlock: "10px",
                }}
              />
            </Grid>
            <Grid item xs={4} lg={4}>
              <FormControlLabel
                required
                control={
                  <Checkbox
                    size="medium"
                    checked={admission || false}
                    onChange={(event) => setAdmission(event.target.checked)}
                  />
                }
                label="Admission"
                style={{
                  fontWeight: "400",
                  fontSize: "10px",
                  lineHeight: "15px",
                  color: "#000000",
                  marginBlock: "10px",
                }}
              />
            </Grid>
            <Grid item xs={4} lg={4}>
              {personalVisitDate && personalVisit === true && (
                <Box sx={{ backgroundColor: "#E5F3FF", borderRadius: "5px", padding: "10px" }}>
                  {personalVisitDate}
                </Box>
              )}
            </Grid>
            <Grid item xs={4} lg={4}>
              {nextVisitDate && nextVisit === true && (
                <Box sx={{ backgroundColor: "#E5F3FF", borderRadius: "5px", padding: "10px" }}>
                  {nextVisitDate}
                </Box>
              )}
            </Grid>

            <Grid item xs={4} lg={4}></Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: "15px",
                color: "#000000",
                marginBlock: "10px",
              }}>
              Surgery Required
            </Typography>
            <TextField
              multiline
              sx={{
                background: "#fff",
                color: "#127DDD",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              placeholder="Surgery Required"
              value={surgeryRequired || ""}
              onChange={(e) => setSurgeryRequired(e.target.value)}
              inputProps={{
                style: {
                  height: 50,
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={6} lg={6} textAlign="right">
          <Button
            variant="outlined"
            style={{
              borderRadius: "15px",
              border: "1px solid",
              textTransform: "none",
              marginInline: "10px",
              padding: "10px",
            }}>
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "13px",
                lineHeight: "15px",
                color: "#127DDD",
              }}>
              Cancel
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Button
            onClick={() => {
              handleNotes();
            }}
            variant="contained"
            style={{
              borderRadius: "15px",
              textTransform: "none",
              marginInline: "10px",
              padding: "10px",
            }}>
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "13px",
                lineHeight: "15px",
                color: "#FFFFFF",
              }}>
              Save
            </Typography>
          </Button>
        </Grid>
      </Grid>

      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open1}
        onClose={() => {
          handleClose1();
          setPersonalVisit(false);
        }}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(187, 187, 187, 0.1)",
          },
          marginTop: "-100px",
        }}>
        <Box
          sx={{
            backgroundColor: "#fff",
            boxShadow: "0px 1px 4px 1px rgba(0, 0, 0, 0.1)",
            borderRadius: "5px",
            padding: "20px",
            width: "350px",
            justifyContent: "center",
          }}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              onClick={() => {
                handleClose1();
                setPersonalVisit(false);
              }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid container spacing={1} sx={{ marginTop: "-35px" }}>
            <Grid item xs={6} lg={6}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#000000",
                  marginBottom: "10px",
                }}>
                Visit After
              </Typography>
              <Grid container>
                <Grid item xs={5} lg={5}>
                  <TextField
                    sx={{
                      background: "#fff",
                      color: "#127DDD",
                      fontWeight: "300",
                      fontSize: "13px",
                      lineHeight: " 15px",
                      "& input::placeholder": {
                        color: "#777777",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: " 15px",
                      },
                    }}
                    size="small"
                    placeholder="Days"
                    value={personalVisitDays}
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        setPersonalVisitDays(e.target.value);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={3} lg={3}></Grid>
                <Grid item xs={4} lg={4}></Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} lg={6}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#000000",
                  marginBottom: "10px",
                }}>
                Date
              </Typography>

              <Box
                sx={{
                  padding: "10px",
                  backgroundColor: "#FFFFFF",
                  border: "0.5px solid #6B6B6B",
                  borderRadius: "5px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "15px",
                    color: "#000000",
                  }}>
                  {personalVisitDate}
                </Typography>
                <Box
                  component={"img"}
                  src={calendar}
                  width={24}
                  height={24}
                  alt="Icon"
                  style={{ textAlign: "right", marginTop: "-5px" }}
                />
              </Box>
            </Grid>
            <Grid item xs={6} lg={6} textAlign="right">
              <Button
                onClick={() => {
                  handleClose1();
                  setPersonalVisit(false);
                }}
                variant="outlined"
                style={{
                  borderRadius: "15px",
                  border: "1px solid",
                  textTransform: "none",
                  marginInline: "10px",
                  padding: "10px",
                }}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "15px",
                    color: "#127DDD",
                  }}>
                  Cancel
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={6} lg={6}>
              <Button
                onClick={() => {
                  handlePersonalVisit();
                }}
                variant="contained"
                style={{
                  borderRadius: "15px",
                  textTransform: "none",
                  marginInline: "10px",
                  padding: "10px",
                }}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "15px",
                    color: "#FFFFFF",
                  }}>
                  Save
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open2}
        onClose={() => {
          handleClose2();
          setNextVisit(false);
        }}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(187, 187, 187, 0.1)",
          },
          marginTop: "-100px",
        }}>
        <Box
          sx={{
            backgroundColor: "#fff",
            boxShadow: "0px 1px 4px 1px rgba(0, 0, 0, 0.1)",
            borderRadius: "5px",
            padding: "20px",
            width: "350px",
            justifyContent: "center",
          }}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              onClick={() => {
                handleClose2();
                setNextVisit(false);
              }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid container spacing={1} sx={{ marginTop: "-35px" }}>
            <Grid item xs={6} lg={6}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#000000",
                  marginBottom: "10px",
                }}>
                Visit After
              </Typography>
              <Grid container>
                <Grid item xs={5} lg={5}>
                  <TextField
                    sx={{
                      background: "#fff",
                      color: "#127DDD",
                      fontWeight: "300",
                      fontSize: "13px",
                      lineHeight: " 15px",
                      "& input::placeholder": {
                        color: "#777777",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: " 15px",
                      },
                    }}
                    size="small"
                    placeholder="Days"
                    value={NextVisitDays}
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        setNextVisitDays(e.target.value);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={3} lg={3}></Grid>
                <Grid item xs={4} lg={4}></Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} lg={6}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#000000",
                  marginBottom: "10px",
                }}>
                Date
              </Typography>

              <Box
                sx={{
                  padding: "10px",
                  backgroundColor: "#FFFFFF",
                  border: "0.5px solid #6B6B6B",
                  borderRadius: "5px",
                  height: "40px",
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "15px",
                    color: "#000000",
                  }}>
                  {nextVisitDate}
                </Typography>
                <Box
                  component={"img"}
                  src={calendar}
                  width={24}
                  height={24}
                  alt="Icon"
                  style={{ textAlign: "right", marginTop: "-5px" }}
                />
              </Box>
            </Grid>
            <Grid item xs={6} lg={6} textAlign="right">
              <Button
                onClick={() => {
                  handleClose2();
                  setNextVisit(false);
                }}
                variant="outlined"
                style={{
                  borderRadius: "15px",
                  border: "1px solid",
                  textTransform: "none",
                  marginInline: "10px",
                  padding: "10px",
                }}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "15px",
                    color: "#127DDD",
                  }}>
                  Cancel
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={6} lg={6}>
              <Button
                onClick={() => {
                  handleNextVisit();
                }}
                variant="contained"
                style={{
                  borderRadius: "15px",
                  textTransform: "none",
                  marginInline: "10px",
                  padding: "10px",
                }}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "15px",
                    color: "#FFFFFF",
                  }}>
                  Save
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default Notes;
