import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Portal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { BASE_URL } from "../../../../../../assets/constantsFile";
import { enqueueSnackbar } from "notistack";
import { uploadFile } from "../../../../../../services/api/postApi";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import SingleUpload from "../../../../preemployment/newRequestForm/comps/singleUpload";
import BookIcon from "@mui/icons-material/Book";

const RaiseTicketDialogEmergency = ({
  corpId = localStorage.getItem("CORPID"),
  orgName = localStorage.getItem("CORP_NAME"),
  userIDAuth = localStorage.getItem("ORGUSERIDAUTH"),
  userMobile = localStorage.getItem("ORGUSERMOBILE"),
  userName = localStorage.getItem("ORGUSERNAME"),
  fetchTicketList,
  source,
  value,
  formData,
}) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [formValues, setFormValues] = useState({});

  const submitHandler = async (e) => {
    e.preventDefault();
    const url = BASE_URL + `org/v2/raiseTicket`;
    formData.append("userAuthId", userIDAuth || "");
    formData.append("raisedBy", userName || "");
    formData.append("raisedById", userIDAuth || "");
    formData.append("raisedByMobileNo", userMobile || "");
    formData.append("ticketType", "EMERGENCY");

    formData.append("corpId", corpId || "");
    formData.append("corpName", orgName || "");
    formData.append("ticketCategory", "CORP");
    formData.append("status", "TICKET_RAISED");

    formData.append(
      "ticketInfo",
      JSON.stringify({
        corpId: corpId,
        empId: formValues.empId,
        empName: formValues.empName,
        issue: formValues.issue,
      })
    );

    const res = await uploadFile(url, formData);
    if (res.error) {
      console.warn({ error: res.error });
      enqueueSnackbar("Failed to raise ticket!", {
        variant: "error",
      });
    } else {
      console.log({ success: res.data });
      enqueueSnackbar("Successfully raised ticket.", {
        variant: "success",
      });
      fetchTicketList();
      handleClose();
      setFormValues({});
    }
  };

  return (
    <Fragment>
      <Box>
        <Card
          sx={{ minWidth: 275, background: "#efefff", height: 170 }}
          onClick={() => {
            setOpen(true);
          }}>
          <CardActionArea>
            <CardContent>
              <Stack direction="row" spacing={2}>
                <img height={120} width={200} src={value?.imageUrl} />

                <Box
                  sx={{
                    position: "absolute ",
                    top: 20,
                    right: -20,
                    height: 180,
                    width: 180,
                    borderRadius: 100,
                    mt: 100,
                    background: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <Stack
                    direction="row"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      {value?.title}
                    </Typography>
                    <Box
                      sx={{
                        height: 30,
                        width: 30,
                        borderRadius: 15,
                        background: "#efefff",
                      }}
                      display="flex"
                      alignItems="center"
                      justifyContent="center">
                      <ArrowRightAltIcon fontSize="10" />
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
      <Portal>
        <Dialog fullWidth={true} maxWidth="md" open={open} onClose={handleClose}>
          <DialogTitle
            component={Paper}
            textAlign="center"
            sx={{
              fill: "#FFF",
              filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
            }}>
            <Stack direction="row" justifyContent="space-between">
              <Box
                width="100%"
                component={Stack}
                direction="row"
                display="flex"
                justifyContent="center">
                <Typography
                  sx={{
                    color: " #000",
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}>
                  Emergency
                </Typography>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <IconButton onClick={handleClose} sx={{ p: 0, m: 0 }}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <form onSubmit={submitHandler}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "fit-content",
                  my: 5,
                  mx: 5,
                }}>
                <Grid container spacing={3}>
                  <Grid item lg={6} display="flex" justifyContent="center" alignItems="center">
                    <Stack spacing={2} width={"100%"}>
                      <Stack direction="row" spacing={1}>
                        <BookIcon fontSize="10" />
                        <Typography sx={{ fontSize: 10 }}>Emp Id</Typography>
                      </Stack>
                      <Box>
                        <TextField
                          size="small"
                          fullWidth
                          placeholder="Enter employee id..."
                          value={formValues.empId || ""}
                          onChange={(e) => setFormValues({ ...formValues, empId: e.target.value })}
                        />
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item lg={6} display="flex" justifyContent="center" alignItems="center">
                    <Stack spacing={2} width={"100%"}>
                      <Stack direction="row" spacing={1}>
                        <BookIcon fontSize="10" />
                        <Typography sx={{ fontSize: 10 }}>Name</Typography>
                      </Stack>
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="Enter employee name..."
                        value={formValues.empName || ""}
                        onChange={(e) => setFormValues({ ...formValues, empName: e.target.value })}
                      />
                    </Stack>
                  </Grid>
                  <Grid item lg={12} display="flex" justifyContent="center" alignItems="center">
                    <Stack spacing={2} flex={1}>
                      <Stack direction="row" spacing={1}>
                        <BookIcon fontSize="10" />
                        <Typography sx={{ fontSize: 10 }}>Issue</Typography>
                      </Stack>
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="Enter employee Issue..."
                        value={formValues.issue || ""}
                        onChange={(e) => setFormValues({ ...formValues, issue: e.target.value })}
                      />
                    </Stack>
                  </Grid>

                  <Grid item lg={12}>
                    <Stack direction="row" spacing={2} display="flex" alignItems="center">
                      <Typography
                        sx={{
                          color: " #000",
                          fontFamily: "Poppins",
                          fontSize: 12,
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "normal",
                          opacity: 0.8,
                        }}>
                        Upload
                      </Typography>
                      <SingleUpload title="Upload" formData={formData} />
                    </Stack>
                  </Grid>
                  <Grid item lg={12} display="flex" justifyContent="center">
                    <Button variant="contained" size="small" sx={{ minWidth: 140 }} type="submit">
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </DialogContent>
        </Dialog>
      </Portal>
    </Fragment>
  );
};

export default RaiseTicketDialogEmergency;
