import { Box, Grid, IconButton, Typography } from "@mui/material";
import { Fragment } from "react";
import ChatIcon from "@mui/icons-material/Chat";
import ClearIcon from "@mui/icons-material/Clear";

const ChatHeader = ({ setIsChatOpen }) => {
  return (
    <Fragment>
      <Box
        sx={{ height: 80, background: "rgb(255, 255, 255)", borderRadius: 4, px: 3 }}
        display="flex"
        justifyContent="center"
        alignItems="center">
        <Grid container spacing={2}>
          <Grid item lg={2} display="flex" justifyContent="center" alignItems="center">
            <ChatIcon sx={{ fontSize: 40 }} color="primary" />
          </Grid>
          <Grid item lg={8}>
            <Typography
              sx={{
                cursor: "default",
                fontSize: "24px",
                fontWeight: " 600",
                lineHeight: "31px",
                overflow: "hidden!important",
                paddingRight: "15px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}>
              UnoTalk
            </Typography>
          </Grid>
          <Grid item lg={2} display="flex" alignItems="flex-start" justifyContent="flex-start">
            <IconButton onClick={() => setIsChatOpen(false)}>
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default ChatHeader;
