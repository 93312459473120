import React from "react";
import RootTemplate from "../../global/templates/rootTemplate";
import AnalyticsMain from "../../modules/pharmacy/analytics/analyticsMain";

const AnalyticsIndex = () => {
  return (
    <RootTemplate>
      <AnalyticsMain />
    </RootTemplate>
  );
};

export default AnalyticsIndex;
