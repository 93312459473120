import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { complaintOptions } from "../../../../../../../assets/constantsFile";

const CustomAutocompleteComplaints = ({
  formValues,
  setFormValues,
  property,
}) => {
  useEffect(() => {
    if (formValues[property] === null) {
      setFormValues({ ...formValues, [property]: null });
    }
  }, [formValues[property]]);

  return (
    <Box>
      <Stack>
        {/* <Typography
          sx={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: 13,
            lineHeightt: 17,
            color: "#000",
            textTransform: "capitalize",
          }}
        >
          {"Complaint"}
        </Typography> */}

        <Autocomplete
          size="small"
          fullWidth
          value={formValues[property] || ""}
          onChange={(event, newValue, reason) => {
            setFormValues({ ...formValues, [property]: newValue });
          }}
          onInputChange={(event, newInputValue, reason) => {
            setFormValues({ ...formValues, [property]: newInputValue });
          }}
          options={complaintOptions}
          freeSolo
          getOptionLabel={(option) => `${option}`}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              placeholder="Enter Complaint"
              label={"Complaint"}
            />
          )}
        />
      </Stack>
    </Box>
  );
};
export default CustomAutocompleteComplaints;
