import { Container, Grid } from "@mui/material";
import {
  CustomHeadingChipCenter,
  CustomHeadingSubTitleCenter,
} from "../../global/headings/customHeadings";
import TestimonialCarousel from "./testimonialCarousel";

const LandingTestimonial = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <CustomHeadingChipCenter>Testimonial</CustomHeadingChipCenter>
        </Grid>
        <Grid item lg={12}>
          <CustomHeadingSubTitleCenter>What Say Our Patients!</CustomHeadingSubTitleCenter>
        </Grid>
        <Grid item lg={12}>
          <TestimonialCarousel />
        </Grid>
      </Grid>
    </Container>
  );
};

export default LandingTestimonial;
