import RootTemplate from "../../global/templates/rootTemplate";
import CSRStatusTrackerMain from "../../modules/csr/statusTracker/csrStatusTrackerMain";

const CSRStatusTrackerIndex = () => {
  return (
    <RootTemplate>
      <CSRStatusTrackerMain />
    </RootTemplate>
  );
};

export default CSRStatusTrackerIndex;
