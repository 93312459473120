import { Typography } from "@mui/material";

export const CustomTypographyHeading = ({ props, children }) => {
  return (
    <Typography {...props} sx={{ fontWeight: 600 }}>
      {children}
    </Typography>
  );
};

export const CustomTypographyParagraph = ({ props, children }) => {
  return (
    <Typography
      {...props}
      sx={{
        boxSizing: "border-box",
        color: "rgb(141, 142, 146)",
        display: "block",
        fontFamily: "Open Sans, sans-serif",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        textAlign: "justify",
      }}>
      {children}
    </Typography>
  );
};

export const CustomTypographyParagraphClient = ({ props, children }) => {
  return (
    <Typography
      {...props}
      sx={{
        boxSizing: "border-box",
        color: "#fff",
        display: "block",
        fontFamily: "Open Sans, sans-serif",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        textAlign: "justify",
      }}>
      {children}
    </Typography>
  );
};
