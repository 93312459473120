import axios from "axios";
import { BASE_URL } from "../../assets/constantsFile";
import { Resolver } from "../resolver/resolver";

export async function saveData(URL, DATA, TOKEN) {
  let authHeader_local = localStorage.getItem("authHeader_local");

  return await Resolver(
    axios(URL, {
      method: "POST",
      data: DATA,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authHeader_local,
      },
    }).then((res) => res.data)
  );
}

export async function uploadFile(url, formData) {
  let authHeader_local = localStorage.getItem("authHeader_local");

  return await Resolver(
    axios(url, {
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + authHeader_local,
      },
    }).then((res) => res.data)
  );
}

export async function saveDataWithMultipartForm(URL, DATA) {
  let authHeader_local = localStorage.getItem("authHeader_local");

  const formData = new FormData();
  for (const key in DATA) {
    if (key === "files") {
      DATA[key].forEach((file, index) => {
        formData.append(`files[${index}]`, file.file, file.name);
      });
    } else {
      formData.append(key, DATA[key]);
    }
  }

  console.log(formData);

  return await Resolver(
    axios(URL, {
      method: "POST",
      data: formData, // Use the FormData object here
      headers: {
        "Content-Type": "multipart/form-data", // Set the Content-Type header
        Authorization: "Bearer " + authHeader_local,
      },
    }).then((res) => res.data)
  );
}

export async function saveDataPassToken(URL, DATA) {
  return await Resolver(
    axios(URL, {
      method: "POST",
      data: DATA,
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " +
          "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ2YXJkaGFuNkBnbWFpbC5jb20iLCJyb2xlIjoiRE9DVE9SIiwiZG9jSWQiOiI5ODQzY2ZjNS1mN2E2LTRmMTYtOTE3Yy0wYjVkMDUwYWI5YzEiLCJleHAiOjE2ODEyMTM4NDgsInVzZXJJRCI6Ijk4NDNjZmM1LWY3YTYtNGYxNi05MTdjLTBiNWQwNTBhYjljMSIsImlhdCI6MTY3MzQzNzg0OH0.4IZO53MtWSxjwLCJsBMUK4GiXurdUDdR7dtzGbhvSp6CxAd4gPC2lVe3vcyWjjQO_Lw-RaCraI0oWKGkeRJweg",
      },
    }).then((res) => res.data)
  );
}

export async function saveDataWithoutAuth(URL, DATA) {
  const headers = {
    "Content-Type": "application/json",
  };
  return await Resolver(
    axios(URL, {
      method: "POST",
      data: DATA,
      headers: headers,
    }).then((res) => res.data)
  );
}

export async function deleteData(URL, TOKEN) {
  let authHeader_local = localStorage.getItem("authHeader_local");

  return await Resolver(
    axios(URL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authHeader_local,
      },
    }).then((res) => res.data)
  );
}

export async function addObservationByDoctor(obj) {
  let authHeader_local = localStorage.getItem("authHeader_local");

  const headers = {
    Authorization: "Bearer my-token",
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(
    axios
      .post(BASE_URL + "doctor/prescription/observations", obj, { headers })
      .then((res) => res.data)
  );
}

export async function updateIssueByDoctor(obj) {
  let authHeader_local = localStorage.getItem("authHeader_local");

  const headers = {
    Authorization: "Bearer my-token",
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(
    axios
      .post(BASE_URL + "doctor/prescription/issue", obj, { headers })
      .then((res) => res.data)
  );
}

export async function addTestByDoctor(obj) {
  let authHeader_local = localStorage.getItem("authHeader_local");

  const headers = {
    Authorization: "Bearer my-token",
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(
    axios
      .post(BASE_URL + "doctor/prescription/tests", obj, { headers })
      .then((res) => res.data)
  );
}
