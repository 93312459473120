import { Box, Grid } from "@mui/material";
import { useState } from "react";
import CustomTitles from "../../../global/titles/customTitles";
import ProfileComp from "./comps/profileComp";

const EDEmployeeLifeCycleMain = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);

  return (
    <Box>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="Employee Life Cycle" />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <ProfileComp setSelectedEmployeeId={setSelectedEmployeeId} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EDEmployeeLifeCycleMain;
