import {
  Alert,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Icon,
  IconButton,
  Link,
  PaginationItem,
  Portal,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import {
  DataGrid,
  GridPagination,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
  gridPageCountSelector,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
} from "@mui/x-data-grid";
import { Fragment, useEffect, useState } from "react";
import { BASE_URL } from "../../../assets/constantsFile";
import { getUrlExtension } from "../../../assets/utils";
import { updateData } from "../../../services/api/patchApi";
import MuiPagination from "@mui/material/Pagination";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { EncryptedTypography, EncryptedTypographyName } from "../components/encryptedTypography";
import { StyledDataGrid } from "./CustomStyledDatagrid";

const strToBool = (str) => {
  return str === "YES" ? true : str === "NO" ? false : null;
};

const CustomTypography = ({ title }) => {
  return (
    <Typography
      sx={{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: 13,
        lineHeightt: 17,
        color: "#8A8A8A",
        textTransform: "capitalize",
      }}>
      {title}
    </Typography>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
    // remove padding at beginning of column header container so that text is inline with column text
    "& .paxton-table--header": {
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        padding: 0,
      },
      // set header font styling
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 600,
        textTransform: "uppercase",
        fontSize: "16px",
      },
    },
    // Datagrid Row Styling
    "& .paxton-table--row": {
      border: ".5px solid #6B6B6B",
      //borderBottom: "1px solid #6B6B6B",
      borderRadius: 5,
      margintop: 7,
      marginBottom: 7,
      backgroundColor: "#fff",
      paddingTop: 8,
      paddingBottom: 8,
      boxSizing: "content-box",
    },
    // remove borders and separators
    "& .paxton-table--cell": {
      borderRight: "1px solid #6B6B6B",
    },
    "& .MuiDataGrid-cell": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: 12,
      lineHeightt: 15,
      color: "##383838",
      textTransform: "capitalize",
      boxSizing: "none",
      borderBottom: "none ! important",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer": {
      border: "none",
    },
    // This is to fix the rows being cut off due to adding padding
    "& .MuiDataGrid-viewport": {
      maxHeight: "unset !important",
    },
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "670px !important", // <= manually setting the max height, but this is not responsive
    },
  },
}));

const RecommendedTestsDataGrid = ({ setApiRef, employeeList = [], corpId, columns = [] }) => {
  columns = [
    {
      field: "id",
      renderHeader: (params) => <CustomTypography title="ID" />,
      width: 5,
      hide: true,
      align: "left",
      renderCell: (cellValues) => <EncryptedTypography text={cellValues.value} />,
    },
    {
      field: "empId",
      renderHeader: (params) => <CustomTypography title="Emp. ID" />,
      width: 100,
      type: "number",
      align: "left",
      renderCell: (cellValues) => <EncryptedTypography text={cellValues.value} />,
    },
    {
      field: "employeename",
      renderHeader: (params) => <CustomTypography title="Name" />,
      width: 200,
      valueFormatter: (value) => `${value.name}`,
      renderCell: (cellValues) => <EncryptedTypographyName data={cellValues} />,
    },
    {
      field: "lastAhcDate",
      renderHeader: (params) => <CustomTypography title="Last Checkup" />,
      width: 100,
      align: "left",
      renderCell: (cellValues) => <CustomTypography title={cellValues.value} />,
    },
    {
      field: "coveredInThisAHC",
      renderHeader: (params) => <CustomTypography title="To Be Covered in This AHC" />,
      width: 170,
      align: "left",
    },

    {
      field: "height",
      renderHeader: (params) => <CustomTypography title="Height" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
    {
      field: "weight",
      renderHeader: (params) => <CustomTypography title="Weight" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
    {
      field: "bloodPressure",
      renderHeader: (params) => <CustomTypography title="Blood Pressure" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
    {
      field: "isSugarTest",
      renderHeader: (params) => <CustomTypography title="RBS (Sugar)" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
    {
      field: "isPft",
      renderHeader: (params) => <CustomTypography title="Pulmonary Function Test(PFT)" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
    {
      field: "isAudiometry",
      renderHeader: (params) => <CustomTypography title="Audiometry" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
    {
      field: "isXRay",
      renderHeader: (params) => <CustomTypography title="X-ray " />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
    {
      field: "isECG",
      renderHeader: (params) => <CustomTypography title="ECG" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
    {
      field: "isEye",
      renderHeader: (params) => <CustomTypography title="Eye Test" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
    {
      field: "isCbc",
      renderHeader: (params) => <CustomTypography title="CBC" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
    {
      field: "isBloodGroup",
      renderHeader: (params) => <CustomTypography title="Blood Group" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
    {
      field: "isTSH",
      renderHeader: (params) => <CustomTypography title="TSH" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
    {
      field: "isSGPT",
      renderHeader: (params) => <CustomTypography title="SGPT" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
    {
      field: "isSGOT",
      renderHeader: (params) => <CustomTypography title="SGOT" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },

    {
      field: "isLipidProfile",
      renderHeader: (params) => <CustomTypography title="Lipid Profile" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },

    {
      field: "isHIV",
      renderHeader: (params) => <CustomTypography title="HIV" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
    {
      field: "isESR",
      renderHeader: (params) => <CustomTypography title="ESR" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
    {
      field: "isHBSAG",
      renderHeader: (params) => <CustomTypography title="HBSAG" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
    {
      field: "isCreatinine",
      renderHeader: (params) => <CustomTypography title="S. Creatinine" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
    {
      field: "issBilirubin",
      renderHeader: (params) => <CustomTypography title="Bilirubin" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
    {
      field: "isHb1ac",
      renderHeader: (params) => <CustomTypography title="HbA1C" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
    {
      field: "isWidal",
      renderHeader: (params) => <CustomTypography title="Widal" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
    {
      field: "isVDRL",
      renderHeader: (params) => <CustomTypography title="VDRL" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
    {
      field: "isUrine",
      renderHeader: (params) => <CustomTypography title="Urine  " />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
    {
      field: "isStoolSample",
      renderHeader: (params) => <CustomTypography title="Stool" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
    {
      field: "isLiverProfile",
      renderHeader: (params) => <CustomTypography title="Liver Profile" />,
      width: 100,
      editable: false,
      type: "singleSelect",
      valueOptions: ["YES", "NO", "N/A"],
    },
  ];
  const classes = useStyles();

  const apiRef = useGridApiRef();

  useEffect(() => {
    setApiRef(apiRef);
  }, [apiRef]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = (url) => {
    const ext = getUrlExtension(url).toLowerCase();
    if (ext === "pdf") {
      window.open(url);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [rows, setRows] = useState(employeeList);

  useEffect(() => {
    setRows(employeeList);
  }, [employeeList]);

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const [selectedImage, setSelectedImage] = useState("");
  console.log({ rows });

  const _submitHandler = async () => {
    const url = BASE_URL + `org/employee/tests/update`;

    const payload = rows.map((item, index) => ({
      id: item.id,

      isXRay: strToBool(item?.isXRay),
      isCbc: strToBool(item?.isCbc),
      isUrine: strToBool(item?.isUrine),
      isFitness: strToBool(item?.isFitness),
      isEye: strToBool(item?.isEye),
      isAudiometry: strToBool(item?.isAudiometry),
      isPft: strToBool(item?.isPft),
      issBilirubin: strToBool(item?.issBilirubin),
      isTestCode: strToBool(item?.isTestCode),
      isStoolSample: strToBool(item?.isStoolSample),
      isECG: strToBool(item?.isECG),
      isLiverProfile: strToBool(item?.isLiverProfile),
      isHIV: strToBool(item?.isHIV),
      isSGOT: strToBool(item?.isSGOT),
      isSGPT: strToBool(item?.isSGPT),
      isESR: strToBool(item?.isESR),
      isHBSAG: strToBool(item?.isHBSAG),
      isVDRL: strToBool(item?.isVDRL),
      isWidal: strToBool(item?.isWidal),
      isHb1ac: strToBool(item?.isHb1ac),
      isTSH: strToBool(item?.isTSH),
      isBloodGroup: strToBool(item?.isBloodGroup),
      isSugarTest: strToBool(item?.isSugarTest),
    }));

    const response = await updateData(url, payload);

    if (response.error) {
      console.log("failed");
      setSeverity("error");
      setMessage("An error Occured");
      setOpenNotice(true);
    } else {
      console.log("success");
      setSeverity("success");
      setMessage("Saved Successfully");
      setOpenNotice(true);
    }
  };

  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [openNotice, setOpenNotice] = useState(false);

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };

  const exportCSV = () => {
    apiRef.current.exportDataAsCsv();
  };

  function Pagination({ page, onPageChange, className }) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination
        color="primary"
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event, newPage - 1);
        }}
        renderItem={(item) => (
          <PaginationItem
            type="last"
            slots={{
              previous: () => (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ArrowBackIcon />
                  <Typography sx={{ marginLeft: "10px" }}>Previous</Typography>
                </Box>
              ),
              next: () => (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ marginRight: "10px" }}>Next</Typography>
                  <ArrowForwardIcon />
                </Box>
              ),
            }}
            {...item}
          />
        )}
      />
    );
  }

  function CustomPagination(props) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
  }

  return (
    <Fragment>
      <Portal>
        <Dialog open={open} onClose={handleClose} maxWidth={"xl"} fullWidth={true}>
          <DialogContent>
            <Box
              sx={{ minHeight: "70vh" }}
              display="flex"
              justifyContent="center"
              alignItems="center">
              {selectedImage ? <img width="100%" src={selectedImage} /> : "No Document."}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Portal>

      <Snackbar open={openNotice} autoHideDuration={1000} onClose={handleCloseNotice}>
        <Alert onClose={handleCloseNotice} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>

      <Box style={{ height: "63vh", width: "100%" }}>
        <Box style={{ display: "flex", height: "100%" }}>
          <Box
            sx={{
              flexGrow: 1,
              width: "100%",
              "& .super-app.negative": {
                backgroundColor: "rgba(157, 255, 118, 0.49)",
                color: "#1a3e72",
                fontWeight: "600",
              },
              "& .super-app.positive": {
                backgroundColor: "#d47483",
                color: "#1a3e72",
                fontWeight: "600",
              },
            }}>
            <StyledDataGrid
              apiRef={apiRef}
              rowHeight={40}
              rows={rows}
              columns={columns}
              // className={classes.root}
              // getRowClassName={() => "paxton-table--row"}
              // getCellClassName={() => "paxton-table--cell"}
              disableRowSelectionOnClick
              processRowUpdate={processRowUpdate}
              slots={{
                pagination: CustomPagination,
              }}

              // initialState={{
              //   columns: {
              //     columnVisibilityModel: {
              //       // Hide columns status and traderName, the other columns will remain visible
              //       age: false,
              //     },
              //   },
              // }}
              // slots={{
              //   toolbar: GridToolbar,
              // }}
            />
          </Box>
        </Box>
      </Box>
      {/* <Box
        component={Stack}
        spacing={3}
        direction={"row"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}>
        <Button
          sx={{ height: 40, borderRadius: 4, px: 3 }}
          onClick={() => console.log("")}
          variant="contained">
          Upload CSV
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ height: 40, borderRadius: 4, px: 3 }}
          onClick={exportCSV}>
          Download CSV
        </Button>
      </Box> */}
    </Fragment>
  );
};

export default RecommendedTestsDataGrid;
