import { Box, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import SearchEmployeeAutocomplete from "../../../../global/components/searchEmployeeAutocomplete";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { getData } from "../../../../../services/api/getApi";
import { _getCSREmployeeList, getDepartments } from "../../../../global/services/corporateServices";
import TableLegend from "../../../../global/components/tableLegend";
import MaskingButton from "../../../../global/components/maskingButton";
import DownloadCSV from "../../../../global/components/downloadCSV";
import FilterMain from "./fliters/filterMain";
import CSRDataGrid from "./csrDataGrid";

const CSRHRMain = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [apiRef, setApiRef] = useState(null);
  const exportCSV = () => {
    apiRef.current.exportDataAsCsv();
  };

  const [employeeList, setEmployeeList] = useState([]);
  const [employeeListStatic, setEmployeeListStatic] = useState([]);
  const [selectedEmploymentType, setSelectedEmploymentType] = useState("ALL");
  const [departmentList, setDepartmentList] = useState([]);

  const [selectDepartment, setSelectDepartment] = useState(null);
  const [selectStatus, setSelectStatus] = useState("ALL");
  const [healthStatus, setHealthStatus] = useState("ALL");

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    getDepartments(corpId, setDepartmentList);
    _getCSREmployeeList(corpId, setEmployeeList, setEmployeeListStatic);
  }, []);

  useEffect(() => {
    setEmployeeList(
      employeeListStatic.filter(
        (item) =>
          (selectDepartment ? item.department === selectDepartment : item) &&
          (selectedEmploymentType === "ALL"
            ? item
            : selectedEmploymentType === "ONROLL"
            ? item.employmentType === "ONROLL"
            : selectedEmploymentType === "CONTRACTOR"
            ? item.employmentType !== "ONROLL"
            : item) &&
          (selectStatus === "COMPLETED" || selectStatus === "PENDING"
            ? item.status === selectStatus
            : item) &&
          (healthStatus === "FIT"
            ? !item.healthStatus || item.healthStatus === "FIT"
            : healthStatus === "UNFIT"
            ? item.healthStatus === "UNFIT"
            : item)
      )
    );
  }, [selectDepartment, selectStatus, healthStatus, selectedEmploymentType]);

  const clearSearch = () => {
    setSearchText("");
    setEmployeeList(employeeListStatic);
    setSelectDepartment(null);
    setSelectStatus("ALL");
    setSelectedEmploymentType("ALL");
    setHealthStatus("ALL");
  };

  console.log({ selectDepartment });

  console.log({ employeeListStatic, selectedEmploymentType, employeeList });

  const showAllHandler = () => {
    setEmployeeList(employeeListStatic);
    setSelectDepartment(null);
    setSelectStatus("ALL");
    setSelectedEmploymentType("ALL");
    setSearchText("");
    setHealthStatus("ALL");
  };
  return (
    <Box sx={{ mt: 1 }}>
      <Grid container rowSpacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={7} lg={7}>
                {/* <FilterMain
                  showAllHandler={showAllHandler}
                  selectedEmploymentType={selectedEmploymentType}
                  setSelectedEmploymentType={setSelectedEmploymentType}
                  healthStatus={healthStatus}
                  setHealthStatus={setHealthStatus}
                  selectStatus={selectStatus}
                  setSelectStatus={setSelectStatus}
                  selectDepartment={selectDepartment}
                  setSelectDepartment={setSelectDepartment}
                  departmentList={departmentList}
                /> */}
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <SearchEmployeeAutocomplete
                  employees={employeeListStatic}
                  employeeList={employeeList}
                  setEmployeeList={setEmployeeList}
                  clearSearch={clearSearch}
                  searchTerm={searchText}
                  setSearchTerm={setSearchText}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={1} lg={1} display="flex" justifyContent="flex-end">
                <Stack direction="row" spacing={1}>
                  <DownloadCSV exportCSV={exportCSV} type="ICON" />

                  <MaskingButton type="ICON" />
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 1 }}>
          <TableLegend />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CSRDataGrid apiRef={apiRef} setApiRef={setApiRef} data={employeeList} corpId={corpId} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CSRHRMain;
