import { Box, Grid, Paper, Zoom } from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import ChatHeader from "./comps/chatHeader";
import ChatFooter from "./comps/chatFooter";
import ChatFabButton from "./comps/chatFabButton";
import ChatGreetings from "./comps/chatGreetings";
import ChatPredefinedHandles from "./comps/chatPredefinedHandles";
import ChatArea from "./comps/chatArea";
import { ChatContext } from "../common/context/usercontext";

const ChatMain = () => {
  const theme = useTheme();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [chat, setChat] = useState("");
  const [chatStack, setChatStack] = useState([]);

  const ref = useRef(null);

  useEffect(() => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  }, [chatStack]);

  const [disableMessgaeArea, setDisableMessgaeArea] = useState(true);

  return (
    <ChatContext.Provider value={{ chatStack: chatStack, setChatStack: setChatStack }}>
      <Fragment>
        {isChatOpen ? (
          <Zoom
            in={true}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${transitionDuration.exit}ms`,
            }}
            unmountOnExit>
            <Box
              component={Paper}
              elevation={5}
              sx={{
                position: "fixed",
                bottom: 10,
                right: 120,
                //background: "red",
                height: 600,
                width: 350,
                borderRadius: 4,
                scrollMargin: 100,
              }}>
              <ChatHeader setIsChatOpen={setIsChatOpen} />

              <Box
                sx={{
                  height: 440,
                  background: "rgb(234, 238, 243)",
                  overflow: "auto",
                  px: 3,
                  //py: 2,
                }}>
                <Grid container spacing={2}>
                  <ChatGreetings />
                  <ChatPredefinedHandles
                    chatStack={chatStack}
                    setChatStack={setChatStack}
                    disableMessgaeArea={disableMessgaeArea}
                    setDisableMessgaeArea={setDisableMessgaeArea}
                  />
                  <ChatArea chatStack={chatStack} />
                </Grid>
                <Box ref={ref} sx={{ py: 3 }}></Box>
              </Box>

              <ChatFooter
                chat={chat}
                setChat={setChat}
                chatStack={chatStack}
                setChatStack={setChatStack}
                disableMessgaeArea={disableMessgaeArea}
                setDisableMessgaeArea={setDisableMessgaeArea}
              />
            </Box>
          </Zoom>
        ) : (
          <ChatFabButton setIsChatOpen={setIsChatOpen} />
        )}
      </Fragment>
    </ChatContext.Provider>
  );
};

export default ChatMain;
