import { useState } from "react";

import {
  useHMSStore,
  selectPeers,
  useHMSActions,
  selectIsConnectedToRoom,
  selectLocalPeer,
  useAVToggle,
  selectCameraStreamByPeerID,
} from "@100mslive/react-sdk";
import { Fragment } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import VideoTileLocal from "./videoTileLocal";
import VideoTilePeers from "./videoTilePeers";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import CallEndIcon from "@mui/icons-material/CallEnd";
import RejoinForm from "./rejoinForm";

function InitiateCall({ name, token }) {
  const hmsActions = useHMSActions();
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const peers = useHMSStore(selectPeers);
  const localPeer = useHMSStore(selectLocalPeer);

  const { isLocalAudioEnabled, isLocalVideoEnabled, toggleAudio, toggleVideo } =
    useAVToggle();

  useEffect(() => {
    handleSubmit();
  }, []);

  const handleSubmit = async () => {
    if (!isConnected) {
      await hmsActions.join({
        userName: name,
        authToken: token,
      });
    }
  };

  const videoRef = useRef(null);
  const videoTrack = useHMSStore(selectCameraStreamByPeerID(localPeer?.id));

  const isLocal = true;
  useEffect(() => {
    (async () => {
      console.log(videoRef.current);
      console.log(videoTrack);
      if (videoRef.current && videoTrack) {
        if (videoTrack.enabled) {
          await hmsActions.attachVideo(videoTrack.id, videoRef.current);
        } else {
          await hmsActions.detachVideo(videoTrack.id, videoRef.current);
        }
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoTrack]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUnload = async () => {
    hmsActions.leave();
  };

  useEffect(() => {
    window.addEventListener("onunload", () => handleUnload());
    window.addEventListener("beforeunload", handleUnload());
    return () => {
      window.removeEventListener("onunload", handleUnload());
      window.removeEventListener("beforeunload", handleUnload());
    };
  }, [hmsActions]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleSubmit();

      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Fragment>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            height: "83.5vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Fragment>
          {isConnected ? (
            <Fragment>
              <Dialog sx={{ left: -600 }} open={open} onClose={handleClose}>
                <DialogTitle>{"Leave Call"}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Do you want to leave the call?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                    onClick={() => {
                      hmsActions.leave();
                      handleClose();
                    }}
                    autoFocus
                  >
                    Leave
                  </Button>
                </DialogActions>
              </Dialog>
              <Fragment>
                <Box sx={{ display: "flex" }}>
                  <Box
                    sx={{
                      height: "83.5vh",
                      width: "100%",
                      background: "rgba(0, 23, 63, 0.76)",
                      //border: "1px solid gray",
                      //  borderRadius: 1,
                    }}
                  >
                    <Box
                      sx={{
                        // background: "red",
                        height: "72vh",
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      {peers &&
                        peers
                          .filter(
                            (peer) => !peer.isLocal && peer?.name !== "Beam"
                          )
                          .map((peer) => {
                            return (
                              <>
                                <VideoTilePeers isLocal={false} peer={peer} />
                              </>
                            );
                          })}
                    </Box>
                    <Box
                      sx={{
                        //background: "blue",
                        height: "8.5vh",
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          //position: "relative",
                          height: "9vh",
                          width: "100%",
                          backgroundColor: "#00173F",
                          mt: 4,
                          // bottom: 55,
                          //left: 60,
                        }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{ pl: 2 }}
                          columnSpacing={3}
                        >
                          <Grid
                            item
                            lg={5}
                            display="flex"
                            alignItems={"center"}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 500, color: "#fff" }}
                            >
                              {`${localPeer.name}`} (You)
                            </Typography>
                          </Grid>
                          <Grid item>
                            {isLocalAudioEnabled ? (
                              <IconButton
                                aria-label="mic"
                                color="primary"
                                sx={{ border: 1 }}
                                onClick={toggleAudio}
                              >
                                <MicIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                aria-label="mic"
                                color="primary"
                                sx={{ border: 1 }}
                                onClick={toggleAudio}
                              >
                                <MicOffIcon />
                              </IconButton>
                            )}
                          </Grid>
                          <Grid item>
                            {isLocalVideoEnabled ? (
                              <IconButton
                                aria-label="mic"
                                color="primary"
                                sx={{ border: 1 }}
                                onClick={toggleVideo}
                              >
                                <VideocamIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                aria-label="mic"
                                color="primary"
                                sx={{ border: 1 }}
                                onClick={toggleVideo}
                              >
                                <VideocamOffIcon />
                              </IconButton>
                            )}
                          </Grid>
                          <Grid item>
                            <IconButton
                              aria-label="mic"
                              color="error"
                              sx={{ border: 1 }}
                              onClick={handleClickOpen}
                            >
                              <CallEndIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        //background: "#fff",
                        height: "19vh",
                        width: "14.5vw",
                        position: "absolute",
                        bottom: "10vh",
                        left: "41vw",
                      }}
                    >
                      {localPeer && (
                        <VideoTileLocal peer={localPeer} isLocal={true} />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Fragment>
            </Fragment>
          ) : (
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <RejoinForm handleSubmit={handleSubmit} />
            </Box>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}

export default InitiateCall;
