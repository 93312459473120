import { Box } from "@mui/material";
import BackgroundImage from "../../../assets/images/svg/loginBackground.svg";
import { Fragment } from "react";

const LoginLeftPane = () => {
  return (
    <Fragment>
      <Box
        sx={{
          height: "100vh",
          background: "linear-gradient(to bottom, #cffaff, #cfe3f8)",
        }}
      >
        <Box
          component="img"
          sx={{ objectFit: "contain" }}
          alt="Banner"
          src={BackgroundImage}
        />
      </Box>
    </Fragment>
  );
};

export default LoginLeftPane;
