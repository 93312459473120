import React, { StrictMode } from "react";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import customTheme from "./assets/themes/customTheme";
import { ThemeProvider } from "@mui/material";
import { userContext } from "./common/context/usercontext";
import App from "./App";
import "typeface-roboto";
import { SnackbarProvider } from "notistack";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const MyApp = () => {
  return (
    <StrictMode>
      <ThemeProvider theme={customTheme}>
        <userContext.Provider value={{ val: "", docName: "", fileUrl: "" }}>
          <BrowserRouter>
            <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
              <App />
            </SnackbarProvider>
          </BrowserRouter>
        </userContext.Provider>
      </ThemeProvider>
    </StrictMode>
  );
};

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<MyApp />);

export default MyApp;
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
