import {
  Autocomplete,
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import StatusTrackerBarChart from "./statusTrackerBarChart";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { getData } from "../../../../../services/api/getApi";

const getEmployeeDataList = (data, selectedPeriod, healthStatus) => {
  if (!data || typeof data !== "object") {
    console.error(`Invalid data object: ${data}`);
    return [];
  }

  const camelCasePeriod = selectedPeriod
    ?.replace(/\s+/g, " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join("");

  console.log({ camelCasePeriod });

  const dataKey = `mapOfEmployeesHealth${camelCasePeriod}`;

  console.log({ dataKey });

  const periodData = data[dataKey];

  console.log({ periodData });

  if (!periodData || typeof periodData !== "object") {
    console.error(`Invalid data key: ${dataKey}`);
    return [];
  }

  const healthData =
    periodData[`${healthStatus.toUpperCase()?.replace(/_/g, " ")} EMPLOYEES`];

  console.log(`${healthStatus.toUpperCase()} EMPLOYEES`);

  console.log({ healthData });

  return healthData;
};

const convertDataToArray = (data) => {
  if (!data || typeof data !== "object") {
    console.log("Invalid data object");
    return [];
  }

  const dataArray = Object.keys(data).map((key) => ({
    name: key,
    sequence: data[key].sequence,
    percent: data[key].percent,
    value: data[key].value,
  }));

  // Sort the array based on the "name" property
  dataArray.sort((a, b) => {
    if (a.name === "TOTAL OPD") {
      return -1; // "TOTAL OPD" comes first
    } else if (b.name === "TOTAL OPD") {
      return 1; // "TOTAL OPD" comes first
    } else {
      return a.sequence - b.sequence; // Sort by sequence for other items
    }
  });

  return dataArray;
};

const healthStatusList = [
  {
    id: 0,
    value: "",
    label: "ALL",
  },
  {
    id: 1,
    value: "FIT",
    label: "Fit",
  },
  {
    id: 1,
    value: "REST",
    label: "Rest",
  },
  {
    id: 1,
    value: "UNDER_MEDICATION",
    label: "Under Medication",
  },
];

const departmentInit = [
  {
    id: 0,
    value: "",
    label: "ALL",
  },
];

const CustomizedAxisTick = (props) => {
  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-60)"
        fontSize={10}
      >
        {payload.value}
      </text>
    </g>
  );
};

const StatusTracker = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [isThisYearHovered, setIsThisYearHovered] = useState(false);
  const [durationList, setDurationList] = useState([
    {
      id: 1,
      value: "THIS_WEEK",
      label: "This Week",
      interval: "THIS WEEK",
    },
    {
      id: 2,
      value: "LAST_WEEK",
      label: "Last Week",
      interval: "LAST WEEK",
    },
    {
      id: 3,
      value: "THIS_MONTH",
      label: "This Month",
      interval: "THIS MONTH",
    },
    {
      id: 4,
      value: "LAST_MONTH",
      label: "Last Month",
      interval: "LAST MONTH",
    },
    {
      id: 5,
      value: "THIS_YEAR",
      label: "This Year",
      interval: "THIS YEAR",
    },
  ]);

  const [departmentList, setDepartmentList] = useState([]);
  const [statusTrackerData, setStatusTrackerData] = useState([]);
  const [duration, setDuration] = useState(durationList[0]);
  const [department, setDepartment] = useState(departmentInit[0]);
  const [healthStatus, setHealthStatus] = useState(healthStatusList[0]);

  const _getDepartmentList = async () => {
    const url = BASE_URL + `org/ohcStatusDepartments/${corpId}`;

    const departmentList = await getData(url, "");
    if (departmentList.error) {
      setDepartmentList([]);
    } else {
      const listTemp = departmentList.data.map((item, index) => ({
        id: index + 1,
        value: item,
        label: item,
      }));
      const list = [...departmentInit, ...listTemp];
      setDepartmentList(list);
    }
  };
  useEffect(() => {
    _getDepartmentList();
  }, []);

  const _getStatusTrackerData = async () => {
    const url =
      BASE_URL +
      `org/ohcStatusTracker/${corpId}?` +
      (duration ? `duration=${duration.value}&` : "") +
      (department ? `department=${department.value}` : "") +
      (healthStatus ? `&healthStatus=${healthStatus.value}` : "");

    const status = await getData(url, "");
    if (status.error) {
      setStatusTrackerData([]);
    } else {
      setStatusTrackerData(status.data);
    }
  };

  useEffect(() => {
    _getStatusTrackerData();
  }, [duration, department, healthStatus]);

  const [heathStatusGraphData, setHeathStatusGraphData] = useState([]);
  const [healthStatusTrackerData, setHealthStatusTrackerData] = useState("");
  const [employeeData, setEmployeeData] = useState("");
  const [selectedHSGraphData, setSelectedHSGraphData] = useState([]);

  const getHealthStatusData = async () => {
    const url = BASE_URL + "org/ohcEmployeeHealthTracker?corpId=" + corpId;

    const status = await getData(url, "");
    if (status.error) {
      setHeathStatusGraphData([]);
      setHealthStatusTrackerData([]);
    } else {
      setHeathStatusGraphData(status.data.mapOfEmployeeHealthByStats);
      setHealthStatusTrackerData(status.data);
    }
  };

  useEffect(() => {
    getHealthStatusData();
  }, []);

  useEffect(() => {
    if (duration.interval) {
      const data = heathStatusGraphData[duration.interval];
      console.log({ dattatata: convertDataToArray(data) });
      if (data) {
        setSelectedHSGraphData(convertDataToArray(data));
        console.log({ data });
      } else {
        console.log("Selected chart not found in dialogData");
        setSelectedHSGraphData([]);
      }
    }
  }, [duration, heathStatusGraphData]);

  const [selectedBar, setSelectedBar] = useState("");

  console.log({ duration });

  useEffect(() => {
    const intervalKey = duration?.interval
      ?.replace(/\s+/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join("");
    const tempData =
      healthStatusTrackerData[`mapOfEmployeesHealth${intervalKey}`];
    console.log({ OKOKOKO: `mapOfEmployeesHealth${intervalKey}` });
    setEmployeeData(tempData);
    console.log({ intervalKey, tempData });
  }, [duration, selectedBar, heathStatusGraphData]);

  console.log({ employeeData });

  const handleDurationChange = (event, newValue) => {
    if (newValue) {
      setDuration(newValue);
      console.log({ newValue });
      if (newValue.value === "THIS_YEAR") {
        // Generate a list of months for the current year
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
        const monthsList = Array.from(
          { length: currentMonth + 1 },
          (_, index) => {
            const monthValue = new Date(currentYear, index, 1)
              .toLocaleString("default", { month: "long" })
              .toUpperCase();
            const monthLabel = new Date(currentYear, index, 1).toLocaleString(
              "default",
              {
                month: "long",
              }
            );
            return {
              id: monthValue,
              value: monthValue,
              label: monthLabel,
              interval: monthValue,
            };
          }
        );

        // Update durationList to include monthsList
        setDurationList([...durationList, ...monthsList]);
      } else {
        // Reset durationList to the original list if the selected value is not "THIS_YEAR"
        setDurationList([
          {
            id: 1,
            value: "THIS_WEEK",
            label: "This Week",
            interval: "THIS WEEK",
          },
          {
            id: 2,
            value: "LAST_WEEK",
            label: "Last Week",
            interval: "LAST WEEK",
          },
          {
            id: 3,
            value: "THIS_MONTH",
            label: "This Month",
            interval: "THIS MONTH",
          },
          {
            id: 4,
            value: "LAST_MONTH",
            label: "Last Month",
            interval: "LAST MONTH",
          },
          {
            id: 5,
            value: "THIS_YEAR",
            label: "This Year",
            interval: "THIS YEAR",
          },
        ]);
      }
    }
  };

  console.log({ selectedHSGraphData });

  console.log({ duration });

  const ohcDoughnutChartData = {
    data: selectedHSGraphData?.filter((item) => item.name !== "TOTAL OPD"),
    title: duration?.interval,
    labelHeading: "Total #",
    setSelectedBar: setSelectedBar, // Assuming setSelectedBar is a function
    dialogData: employeeData,
    totalOpds: selectedHSGraphData.find((obj) => obj.name === "TOTAL OPD")
      ?.value,
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box
            sx={{
              boxSizing: "border-box",
              background: "#FFFFFF",
              border: "0.5px solid #979797",
              borderRadius: 5,
              px: 3,
              py: 0.5,
            }}
          >
            <Stack
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                sx={{
                  width: 0,
                  height: 30,
                }}
              />
              {/* <Autocomplete
                value={duration}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setDuration(newValue);
                    console.log({ newValue });
                  }
                }}
                sx={{ width: 600 }}
                size="small"
                fullWidth
                disablePortal
                options={durationList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ "& fieldset": { border: "none" } }}
                    label="Select Duration"
                    InputLabelProps={{
                      style: {
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: 13,
                        color: "#404040",
                      },
                    }}
                  />
                )}
              />

             */}
              <Autocomplete
                value={duration}
                onChange={handleDurationChange}
                sx={{ width: 600 }}
                size="small"
                fullWidth
                disablePortal
                options={durationList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ "& fieldset": { border: "none" } }}
                    label="Select Duration"
                    InputLabelProps={{
                      style: {
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: 13,
                        color: "#404040",
                      },
                    }}
                  />
                )}
              />

              <Box
                sx={{
                  width: 0,
                  height: 30,
                  border: "0.5px solid #6B6B6B",
                  //transform: "rotate(90deg)",
                }}
              />
              <Autocomplete
                value={healthStatus}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setHealthStatus(newValue);
                    console.log({ newValue });
                  }
                }}
                sx={{ width: 600 }}
                //getOptionLabel={(item) => item.name}
                size="small"
                fullWidth
                disablePortal
                options={healthStatusList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ "& fieldset": { border: "none" } }}
                    label="Select Health Status"
                    InputLabelProps={{
                      style: {
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: 13,
                        color: "#404040",
                      },
                    }}
                  />
                )}
              />

              <Box
                sx={{
                  width: 0,
                  height: 30,
                  border: "0.5px solid #6B6B6B",
                  //transform: "rotate(90deg)",
                }}
              />

              <Autocomplete
                value={department}
                onChange={(event, newValue) => {
                  setDepartment(newValue);
                }}
                sx={{ width: 600 }}
                //getOptionLabel={(item) => item.name}
                size="small"
                fullWidth
                disablePortal
                options={departmentList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ "& fieldset": { border: "none" } }}
                    label="Select Department"
                    InputLabelProps={{
                      style: {
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: 13,
                        color: "#404040",
                      },
                    }}
                  />
                )}
              />
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>
            {`Total Number of OPD's: ${statusTrackerData?.totalOpds || ""}`}{" "}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {/* <OhcDoughnutChart
            data={selectedHSGraphData?.filter(
              (item) => item.name !== "TOTAL OPD"
            )}
            title={duration?.interval}
            labelHeading={"Total #"}
            setSelectedBar={setSelectedBar}
            dialogData={employeeData}
          /> */}
          {/* <CustomBarChart
            data={selectedHSGraphData}
            title={selectedInterval}
            setSelectedBar={setSelectedBar}
            dialogData={employeeData}
          />  */}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} mb={20}>
          <Box
            sx={{
              height: "55vh",
              width: "100%",
            }}
          >
            <StatusTrackerBarChart
              statusTrackerData={statusTrackerData}
              ohcDoughnutChartData={ohcDoughnutChartData}
            />
          </Box>
        </Grid>
        {/* <Grid container spacing={3}> */}
        {/* <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          display="flex"
          justifyContent="center"
          alignItems="center">
          <ButtonGroup size="small" variant="contained">
            <Button
              variant={selectedInterval === "THIS WEEK" ? "contained" : "outlined"}
              onClick={() => setSelectedInterval("THIS WEEK")}>
              This Week
            </Button>
            <Button
              variant={selectedInterval === "THIS MONTH" ? "contained" : "outlined"}
              onClick={() => setSelectedInterval("THIS MONTH")}>
              This Month
            </Button>
            <Button
              variant={selectedInterval === "THIS YEAR" ? "contained" : "outlined"}
              onClick={() => setSelectedInterval("THIS YEAR")}>
              This Year
            </Button>
          </ButtonGroup>
        </Grid> */}

        {/* </Grid> */}
      </Grid>
    </Box>
  );
};

export default StatusTracker;
