import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Fragment } from "react";
import { useState } from "react";
import flagIcon from "../../../../../assets/images/flagIcon.png";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import PopUpMobile from "./PopUpMobile";
import { saveDataWithoutAuth } from "../../../../../../services/api/postApi";
import { BASE_URL } from "../../../../../../assets/constantsFile";

const BookForm = () => {
  const [appointmentDate, setAppointmentDate] = useState(new Date());
  const [formValues, setFormValues] = useState({
    fullName: "",
    age: "",
    gender: "",
    diseases: "",
    mobile: "",
    emailId: "",
    address: "",
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    console.log("ok");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const data = {
    fullName: formValues?.fullName || null,
    age: formValues?.age || null,
    gender: formValues?.gender || null,
    bookAppointmentDate: appointmentDate?.toISOString().split("T")[0] || null,
    mobile: formValues?.mobile || null,
    diseases: formValues?.diseases || null,
    address: formValues?.address || null,
    email: formValues?.emailId || null,
  };

  const saveForm = async () => {
    const url = BASE_URL + `healthcamp/marketing/digital`;
    const result = await saveDataWithoutAuth(url, data);

    if (result?.data) {
      console.log({ SUCCESS: result?.data });
      setFormValues({
        fullName: "",
        age: "",
        gender: "",
        diseases: "",
        mobile: "",
        emailId: "",
        address: "",
      });
      setAppointmentDate(null);
      handleOpen();
    } else {
      console.log({ error: result?.error });
    }
  };
  return (
    <Fragment>
      <Box
        sx={{
          paddingInline: "30px",
          paddingTop: "10px",
          paddingBottom: "30px",
          borderRadius: "20px",
          backgroundColor: "#FFF",
          boxShadow: "10px 10px 50px 10px rgba(229, 233, 246, 0.40)",
          position: "relative",
        }}>
        <Grid container spacing={2} sx={{ justifyContent: "space-between" }}>
          <Grid item xs={12} lg={12}>
            <Typography
              sx={{
                color: "#404040",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "normal",
                marginBlock: "20px",
              }}>
              Kindly Fill In The Required Deatails In The Form and Book An Appointment
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Typography
              sx={{
                color: "#404040",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "normal",
                marginBlock: "5px",
              }}>
              Full Name
            </Typography>
            <TextField
              sx={{
                background: "#fff",
                color: "#000000",
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#6B6B6B",
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              placeholder="Enter Full Name"
              value={formValues.fullName}
              onChange={(e) => {
                setFormValues({ ...formValues, fullName: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12} lg={12}>
            <Typography
              sx={{
                color: "#404040",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "normal",
                marginBlock: "5px",
              }}>
              Age
            </Typography>
            <TextField
              sx={{
                background: "#fff",
                color: "#000000",
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#6B6B6B",
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              placeholder="Enter Age"
              value={formValues.age}
              onChange={(e) => {
                if (
                  !isNaN(e.target.value) &&
                  e.target.value < 120 &&
                  e.target.value >= 0 &&
                  e.target.value.length < 4
                ) {
                  setFormValues({ ...formValues, age: e.target.value });
                }
              }}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <Typography
              sx={{
                color: "#404040",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "normal",
                marginBlock: "5px",
              }}>
              Gender
            </Typography>
            <FormControl fullWidth>
              <Select
                sx={{
                  background: "#fff",
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: " 15px",
                  paddingTop: "3px",
                  width: "100%",
                }}
                size="small"
                displayEmpty
                value={formValues.gender || ""}
                placeholder="Enter Gender"
                onChange={(e) => {
                  setFormValues({ ...formValues, gender: e.target.value });
                }}>
                <MenuItem value="" disabled>
                  Select Gender
                </MenuItem>
                <MenuItem value={"MALE"}>MALE</MenuItem>
                <MenuItem value={"FEMALE"}>FEMALE</MenuItem>
                <MenuItem value={"OTHER"}>OTHER</MenuItem>
                <MenuItem value={"DONOTDISCLOSE"}>DONOTDISCLOSE</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Typography
              sx={{
                color: "#404040",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "normal",
                marginBlock: "5px",
              }}>
              Disease/ Illness Type
            </Typography>
            <TextField
              sx={{
                background: "#fff",
                color: "#000000",
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#6B6B6B",
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              placeholder="Enter Disease/ Illness"
              value={formValues.diseases}
              onChange={(e) => {
                setFormValues({ ...formValues, diseases: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <Typography
              sx={{
                color: "#404040",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "normal",
                marginBlock: "5px",
              }}>
              Select Appointment Date
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disablePast
                inputFormat="dd MMM yyyy"
                disableMaskedInput={true}
                openTo="day"
                views={["year", "month", "day"]}
                value={appointmentDate}
                name="DOA"
                onChange={(newValue) => {
                  setAppointmentDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    sx={{ fontSize: 10, color: "#000000" }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Typography
              sx={{
                color: "#404040",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "normal",
                marginBlock: "5px",
              }}>
              Mobile Number
            </Typography>
            <TextField
              sx={{
                background: "#fff",
                color: "#000000",
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#6B6B6B",
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              placeholder="Enter Mobile Number"
              value={formValues.mobile}
              onChange={(e) => {
                if (!isNaN(e.target.value) && e.target.value.length < 11) {
                  setFormValues({ ...formValues, mobile: e.target.value });
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      component="img"
                      src={flagIcon}
                      alt="logo"
                      style={{ width: "22px", height: "22px" }}
                    />
                    <Box
                      sx={{
                        borderBottom: 0.5,
                        borderColor: "#6B6B6B",
                        width: "30px",
                        rotate: "90deg",
                        marginRight: "-15px",
                        marginLeft: "-5px",
                      }}></Box>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <Typography
              sx={{
                color: "#404040",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "normal",
                marginBlock: "5px",
              }}>
              Email ID
            </Typography>
            <TextField
              sx={{
                background: "#fff",
                color: "#000000",
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#6B6B6B",
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              placeholder="Enter Email ID"
              value={formValues.emailId}
              onChange={(e) => {
                setFormValues({ ...formValues, emailId: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <Typography
              sx={{
                color: "#404040",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "normal",
                marginBlock: "5px",
              }}>
              Address
            </Typography>
            <TextField
              sx={{
                background: "#fff",
                color: "#000000",
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#6B6B6B",
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              placeholder="Enter address "
              value={formValues.address}
              onChange={(e) => {
                setFormValues({ ...formValues, address: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12} lg={12} sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              sx={{ textTransform: "capitalize", borderRadius: "10px" }}
              onClick={() => saveForm()}>
              <Typography
                sx={{
                  color: "#FFF",
                  paddingInline: "20px",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "normal",
                }}>
                Book Appointment
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
      <PopUpMobile open={open} handleClose={handleClose} />
    </Fragment>
  );
};

export default BookForm;
