import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { getData } from "../../../../../services/api/getApi";
import { saveData } from "../../../../../services/api/postApi";
import EmployeeProfileMain from "../../../employeeprofile/employeeProfileMain";
import SingleUpload from "../../../../global/components/upload/singleUpload";
import PrintConsolidated from "./printConsolidated";

const ProfileComp = ({ corpId = localStorage.getItem("CORPID"), setSelectedEmployeeId }) => {
  const [openNotice, setOpenNotice] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };
  const [searchText, setSearchText] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeID, setEmployeeID] = useState(null);

  const searchByEmpId = async (e) => {
    // e.preventDefault();
    const searchText = e && typeof e === "string" ? e : "";
    if (searchText) {
      const url = BASE_URL + `org/detailed/${searchText}?corpId=${corpId}`;
      let authHeader_local = localStorage.getItem("authHeader_local");

      try {
        const employee = await axios.get(url, {
          headers: { Authorization: "Bearer " + authHeader_local },
        });

        // Check if the response has an error status

        // Update the state with the successful response
        const list = [employee.data];
        setEmployeeList(list);
        setEmployeeID(employee.data.empId);
        setSelectedEmployeeId(employee.data.empId);
      } catch (error) {
        if (error.response.status === 400) {
          // Handle the 400 error by showing a Snackbar alert
          setSeverity("error");
          setMessage("Employee Id Not found");
          setOpenNotice(true);
          // Reset the state
          setEmployeeList([]);
          setEmployeeID(null);
          setSelectedEmployeeId(null);
        }
        console.error("An error occurred:", error);
      }
    }
  };

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [oldEmployeeList, setOldEmployeeList] = useState([]);

  const fetchOldEmployee = useCallback(async (nameValue) => {
    // Adjust the API endpoint for employee search
    const url = BASE_URL + `org/employee/search/${nameValue}?corpId=${corpId}`;
    const oldEmployees = await getData(url, "");

    if (oldEmployees && oldEmployees.error) {
      console.log("Error fetching employees");
    } else if (oldEmployees && oldEmployees.data) {
      console.log("Success fetching employees");
      setOldEmployeeList(oldEmployees.data);
    }
  }, []);
  const [inputValue, setInputValue] = useState("");

  const _handleSubmit = async () => {
    const url = BASE_URL + `org/print/tests`;
    setIsLoading(true);
    const payload = {
      corpId: corpId,
      empId: employeeID,
    };

    const printData = await saveData(url, payload, "");

    if (printData.error) {
      console.log({ error: printData.error });
      setSeverity("error");
      setMessage("No tests found for this employee.");
      setOpenNotice(true);
      setIsLoading(false);
    } else {
      console.log({ success: printData.data });
      //printJS(printData.data);
      setIsLoading(false);
      window.open(printData.data, "_blank", "popup,width=1300,height=700,top=200,left=340");
    }
  };

  console.log({ searchText });

  const [isLoadng, setIsLoading] = useState(false);

  //console.log({ employeeList });
  return (
    <Container maxWidth={false} sx={{ backgroundColor: "#F5F5F5", minHeight: "76vh" }}>
      <Snackbar open={openNotice} autoHideDuration={2000} onClose={handleCloseNotice}>
        <Alert onClose={handleCloseNotice} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {/* <form onSubmit={searchByEmpId}> */}
            <Box>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Autocomplete
                  value={selectedEmployee}
                  onChange={(event, value, reason) => {
                    setSelectedEmployee(value);
                    setSearchText(value?.empId);
                    searchByEmpId(value?.empId);
                    if (!value) {
                      setEmployeeID(null);
                      setEmployeeList([]);
                      setSearchText("");
                      setSelectedEmployee(null);
                      setInputValue("");
                    }
                    if (reason === "clear") {
                      setEmployeeID(null);
                      setEmployeeList([]);
                      setSearchText("");
                      setSelectedEmployee(null);
                      setInputValue("");
                    }
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    if (newInputValue) {
                      fetchOldEmployee(newInputValue);
                    }
                  }}
                  fullWidth
                  // freeSolo
                  // disableClearable
                  size="small"
                  options={oldEmployeeList}
                  getOptionLabel={(option) =>
                    option ? `${option?.empId || ""} ${option?.empName || ""}` : ""
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option?.empId}>
                      <Typography sx={{ fontSize: "15px" }}>
                        {option?.empId} {option?.empName}
                      </Typography>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        backgroundColor: "#fff",
                        borderTopLeftRadius: 20,
                        borderBottomLeftRadius: 20,
                        "& fieldset": {
                          borderTopLeftRadius: 20,
                          borderBottomLeftRadius: 20,
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        },
                      }}
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          height: 30,
                        },
                      }}
                      variant="outlined"
                      placeholder="Search employee by name or empId"
                      size="small"
                    />
                  )}
                  noOptionsText={oldEmployeeList.length === 0 ? "No Options" : null}
                />
                <Button
                  onClick={() => {
                    searchByEmpId(searchText);
                  }}
                  size="large"
                  variant="contained"
                  type="submit"
                  sx={{
                    height: 48,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderTopRightRadius: 20,
                    borderBottomRightRadius: 20,
                  }}>
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: 14,
                      color: "#FFFFFF",
                    }}>
                    Search
                  </Typography>
                </Button>
              </Stack>
            </Box>
            {/* </form> */}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} display="flex" justifyContent="flex-end">
            <Stack direction="row" spacing={1}>
              <Box sx={{ display: { lg: "flex", xs: "none" } }}>
                <SingleUpload
                  employeeID={employeeID}
                  title="Upload Consolidated Report"
                  url={
                    BASE_URL +
                    `org/upload?empId=${employeeID}&fileType=CONSOLIDATED_REPORT&corpId=${corpId}`
                  }
                />
              </Box>
              <Box sx={{ display: { lg: "none", xs: "flex" } }}>
                <SingleUpload
                  employeeID={employeeID}
                  title="Upload Report"
                  url={
                    BASE_URL +
                    `org/upload?empId=${employeeID}&fileType=CONSOLIDATED_REPORT&corpId=${corpId}`
                  }
                />
              </Box>
              {/* <SingleUpload
                employeeID={employeeID}
                title="Vaccination"
                url={
                  BASE_URL +
                  `org/upload?empId=${employeeID}&fileType=VACCINATION_CERTIFICATE&corpId=${corpId}`
                }
              /> */}
              <Button
                disabled={employeeList ? false : true}
                variant="contained"
                size="small"
                sx={{
                  height: 48,
                  width: 130,
                  borderTopLeftRadius: 20,
                  borderBottomLeftRadius: 20,
                  borderTopRightRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                onClick={() => {
                  setEmployeeID(null);
                  setEmployeeList([]);
                  setSearchText("");
                  setSelectedEmployee(null);
                  setInputValue("");
                }}>
                Clear
              </Button>

              {corpId !== "86870741-5de9-4891-bd1a-1837ef673b66" && (
                <Button
                  disabled={employeeID ? false : true}
                  variant="contained"
                  size="small"
                  onClick={_handleSubmit}
                  sx={{
                    height: 48,
                    width: 130,
                    borderTopLeftRadius: 20,
                    borderBottomLeftRadius: 20,
                    borderTopRightRadius: 20,
                    borderBottomRightRadius: 20,
                  }}>
                  Print!
                  {isLoadng && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                      }}>
                      <CircularProgress size={30} />
                    </Box>
                  )}
                </Button>
              )}
            </Stack>
          </Grid>

          {employeeID ? (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <EmployeeProfileMain employeeID={employeeID} />
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Container>
  );
};

export default ProfileComp;
