import {
  Box,
  Button,
  Container,
  Grid,
  Slide,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import whatWeDoBg from "../assets/whatWeDoBg.png";
import whatWeDo from "../assets/whatWeDo.png";
import { useTheme } from "@emotion/react";
import logo from "../assets/logo.png";

const WhatWeDo = ({ scrollToSection }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Fragment>
      <Box
        sx={{
          backgroundImage: `url(${whatWeDoBg})`,
          height: isSmallScreen || isExtraSmallScreen ? null : "85vh",
          paddingBlock: isSmallScreen || isExtraSmallScreen ? "20px" : "45px",
          paddingLeft: isSmallScreen || isExtraSmallScreen ? null : "65px",
          paddingRight: isSmallScreen || isExtraSmallScreen ? null : "65px",
          overflow: "hidden",
        }}>
        <Container maxWidth={false}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography
                sx={{
                  color: "#000000",
                  fontSize: isExtraSmallScreen || isSmallScreen ? "24px" : "48px",
                  fontWeight: "700",
                  lineHeight: "52.8px",
                  mb: 1,
                }}>
                What We Do Best For You
              </Typography>

              <Box>
                {/* <Box
                    component={Stack}
                    direction="row"
                    spacing={1}
                    sx={{
                      flexGrow: 4,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: {
                        xs: "center",
                        sm: "center",
                        md: "flex-start",
                        lg: "flex-start",
                      },
                    }}>
                    <Typography
                      style={{
                        color: "#000000",
                        fontSize: isExtraSmallScreen || isSmallScreen ? "24px" : "20px",
                        fontWeight: "200",
                        lineHeight: "56px",
                      }}>
                      at
                    </Typography>
                    <Box
                      component={"img"}
                      src={logo}
                      alt="Logo"
                      sx={{ height: "42px", marginLeft: { xs: 0, sm: 0, md: "40px" } }}
                    />
                  </Box> */}
                {/* <Typography
                  style={{
                    color: "#000000",
                    fontSize: isExtraSmallScreen || isSmallScreen ? "24px" : "40px",
                    fontWeight: "200",
                    lineHeight: "56px",
                  }}>
                  At Uno.care,
                  <br />
                </Typography> */}
                <Typography
                  style={{
                    color: "#000000",

                    fontSize: isExtraSmallScreen || isSmallScreen ? "20px" : "24px",
                    fontWeight: "400",
                    lineHeight: "33.6px",
                  }}>
                  At{" "}
                  <span style={{ color: "rgb(62, 72, 238)" }}>
                    <b>Uno.care</b>
                  </span>
                  , We pride ourselves on delivering the best health care services to our community.
                  Our dedicated team of highly skilled professionals is committed to provide
                  compassionate, patient-centered care. With state of the art facilities and
                  advanced medical technology, we prioritize patient safety, comfort and
                  satisfaction which makes us a trusted healthcare provider.
                  <br />
                  <br />
                </Typography>
                <Typography
                  style={{
                    color: "#000000",
                    // textAlign: "justify",
                    fontSize: isExtraSmallScreen || isSmallScreen ? "20px" : "24px",
                    fontWeight: "400",
                    lineHeight: "26.4px",
                    wordWrap: "break-word",
                  }}>
                  Experience exceptional care at Uno.care
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{
                display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img
                style={{
                  height: 500,
                  objectFit: "contain",
                }}
                src={whatWeDo}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box
                sx={{
                  position: "relative",
                  marginTop: { xs: 2, sm: 2, lg: -8 },

                  ":hover": {
                    "& .hover-effect": {
                      top: 0,
                      left: 0,
                    },
                  },
                }}>
                <Button
                  onClick={() => scrollToSection("contactUs")}
                  style={{
                    width: 261,
                    height: 54,
                    paddingLeft: 28,
                    paddingRight: 28,
                    paddingTop: 16,
                    paddingBottom: 16,
                    backgroundColor: "#3E48EE",
                    borderRadius: 8,
                  }}>
                  <Typography
                    style={{
                      textAlign: "center",
                      color: "#FFF",
                      fontSize: "18px",
                      fontWeight: "600",
                      lineHeight: "21.6px",
                    }}>
                    Schedule a Meeting
                  </Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Fragment>
  );
};

export default WhatWeDo;
