import { TextField } from "@mui/material";
import { colors } from "../../../assets/color";

const UserNameComp = ({ userName, setUserName }) => {
  return (
    <TextField
      fullWidth
      size="medium"
      required
      label="User Name"
      autoComplete="userName"
      autoFocus
      value={userName || ""}
      onChange={(e) => {
        setUserName(e.target.value);
      }}
      InputProps={{
        style: { color: colors.primary },
      }}
      InputLabelProps={{
        style: { fontSize: ".875rem", color: colors.primary },
      }}
    />
  );
};

export default UserNameComp;
