import RootTemplate from "../../global/templates/rootTemplate";
import FaHealthChampionsMain from "../../modules/firstAid/healthChampions/faHealthChampionsMain";

const FAHealthChampionsIndex = () => {
  return (
    <RootTemplate>
      <FaHealthChampionsMain />
    </RootTemplate>
  );
};

export default FAHealthChampionsIndex;
