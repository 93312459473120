import { Box, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import Background from "./Background";
import LeftPart from "./leftPart";
import RightPart from "./rightPart";
import RightBanner from "../Banners/RightBanner";

const AppointmentForm = () => {
  return (
    <Fragment>
      <Background />
      {/* <Grid container sx={{ marginTop: "-700px", position: "relative" }}> */}
      <Grid container sx={{ marginTop: "-670px", position: "relative" }}>
        <Grid item xs={5} lg={5}>
          {/* <LeftPart /> */}
          <RightBanner />
        </Grid>
        <Grid item xs={7} lg={7} sx={{ paddingInline: "60px" }}>
          <RightPart />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AppointmentForm;
