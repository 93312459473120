import { Box } from "@mui/material";
import PrimaryTabComp from "./primaryTabComp";

const DetailIndex = ({ buttonLabel, patient, patientDetails, source }) => {
  return (
    <Box sx={{ backgroundColor: "#fff", borderRadius: 2 }}>
      <PrimaryTabComp
        buttonLabel={buttonLabel}
        patient={patient}
        patientDetails={patientDetails}
        source={source}
      />
    </Box>
  );
};

export default DetailIndex;
