import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { useState } from "react";
import hospitalItc from "../../assets/images/hospitalItc.png";
import pithampur from "../../assets/images/pithampur.png";
import ghataclinic from "../../assets/images/ghataclinic.png";
import depalpur from "../../assets/images/depalpur.png";
import banediya from "../../assets/images/banediya.png";
import gautampura from "../../assets/images/gautampura.png";
import { Fragment } from "react";
import { CustomHeadingSubTitleCenter } from "../../global/headings/customHeadings";

const LocationCard = ({ data, animationDuration }) => {
  const [showHoverEffect, setShowHoverEffect] = useState(true);

  return (
    <Box
      onPointerEnter={() => setShowHoverEffect(false)}
      variant="outlined"
      onPointerLeave={() => setShowHoverEffect(true)}
      sx={{
        padding: "10px",
        borderRadius: "23px",
        transition: "box-shadow 0.3s ease",
        "&:hover": {
          boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.1)",
          transform: "translateY(-5px)",
        },
      }}>
      <Box component={"img"} src={data?.src} style={{ height: "90%" }} />
    </Box>
  );
};

const ShowLocations = () => {
  const cardData = [
    {
      src: pithampur,
    },
    {
      src: depalpur,
    },
    {
      src: gautampura,
    },
    {
      src: ghataclinic,
    },
    {
      src: banediya,
    },
  ];

  return (
    <Fragment>
      <Grid
        container
        spacing={1}
        marginBlock={"10px"}
        sx={{ justifyContent: "center", alignItems: "center" }}>
        {cardData.map((item, index) => (
          <Grid item lg={3} key={index}>
            <LocationCard data={item} />
          </Grid>
        ))}
      </Grid>
      <CustomHeadingSubTitleCenter>Indore Trauma Center</CustomHeadingSubTitleCenter>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
        <Box
          variant="outlined"
          sx={{
            padding: "10px",

            borderRadius: "23px",
            transition: "box-shadow 0.3s ease",
            "&:hover": {
              boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.1)",
              transform: "translateY(-5px)",
            },
          }}>
          <Box component={"img"} src={hospitalItc} style={{ height: "90%" }} />
        </Box>
      </Box>
    </Fragment>
  );
};
export default ShowLocations;
