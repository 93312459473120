import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ClickAwayListener,
  CssBaseline,
  Fade,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const customFontSize = 14;

const AppBarSubMenuNew = ({
  navbarPermissions = JSON.parse(localStorage.getItem("NAVBAR_PERMISSIONS")),
}) => {
  //console.log({ navbarPermissions });

  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedItem([]);
  };

  const [selectedItem, setSelectedItem] = useState([]);

  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Fragment>
      <CssBaseline />
      <ClickAwayListener onClickAway={handleClose}>
        <Menu
          disableAutoFocusItem
          sx={{ pointerEvents: "none" }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            onMouseLeave: handleClose,
            style: { minWidth: 120 },
          }}
        >
          {selectedItem.map((item, index) =>
            item?.sublist ? (
              <Box key={index}>
                <MenuItem key={index} sx={{ pointerEvents: "auto" }}>
                  <Accordion
                    elevation={0}
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>{item?.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack
                        spacing={2}
                        display="flex"
                        alignItems="flex-start"
                        sx={{ ml: 3 }}
                      >
                        {item?.sublist?.map((page, index) => (
                          <Paper
                            elevation={0}
                            key={index}
                            onClick={handleClose}
                            sx={{ background: "#fff", pointerEvents: "auto" }}
                          >
                            <Link
                              to={page?.url}
                              style={{
                                px: 0,
                                my: 0.5,
                                display: "block",
                                fontSize: customFontSize,
                                fontWeight: "600",
                                fontFamily: "Roboto",
                                textAlign: "center",
                                color: "black",
                              }}
                            >
                              {page?.name}
                            </Link>
                          </Paper>
                        ))}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </MenuItem>
              </Box>
            ) : (
              <MenuItem
                onClick={() => {
                  navigate(item?.url);
                  handleClose();
                }}
                key={index}
                sx={{ background: "#fff", pointerEvents: "auto", pl: 3 }}
              >
                <Link
                  // to={item?.url}
                  style={{
                    px: 0,
                    my: 0.5,
                    display: "block",
                    fontSize: customFontSize,
                    fontWeight: "600",
                    fontFamily: "Roboto",
                    textAlign: "center",
                    color: "black",
                  }}
                >
                  {item?.name}
                </Link>
              </MenuItem>
            )
          )}
        </Menu>
      </ClickAwayListener>

      <Box
        component={Paper}
        sx={{
          background: "#FFFFFF",
          borderRadius: 0,
          px: 2,
          borderTop: "0.5px solid #000",
        }}
      >
        <Grid container>
          <Grid item lg={12}>
            <Box>
              <Stack
                direction="row"
                display="flex"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={0}
              >
                {navbarPermissions?.map((menuItem, index) => (
                  <Box
                    key={index}
                    sx={{
                      py: 0,
                    }}
                  >
                    <Button
                      onMouseOver={(e) => {
                        handleClick(e);
                        setSelectedItem(
                          menuItem?.subMenu?.filter((v) => v.access)
                        );
                      }}
                      //onMouseOut={handleClose}
                      //onPointerLeave={handleClose}
                      //onMouseLeave={handleClose}
                      //href={menuItem?.url}
                      sx={{
                        // m: 0,
                        // p: 0,
                        px: 0,
                        my: 0.5,
                        display: "block",
                        // fontSize: customFontSize,
                        //fontWeight: "600",
                        fontFamily: "Roboto",
                        textAlign: "center",
                        textTransform: "capitalize",
                        //color: "#fff",
                      }}
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          color:
                            menuItem?.exactpath === "corpemergencycontrol"
                              ? "red"
                              : "#fff",
                        }}
                      >
                        <Typography
                          style={{
                            // flexShrink: 1,
                            fontSize: 14,
                            color: "#000000",
                            fontWeight: "500",
                          }}
                        >
                          {menuItem.name}
                        </Typography>
                        {/* <KeyboardArrowDownIcon fontSize="small" /> */}
                      </Box>
                    </Button>
                  </Box>
                ))}
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default AppBarSubMenuNew;
