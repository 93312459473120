import { Fragment, useState } from "react";
import CustomDataPanels from "../../../preemployment/newRequestForm/comps/customDataPanels";
import { Box, Button, colors, Typography } from "@mui/material";
import useWindowDimensions from "../../../../../assets/customhooks";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import { PhotoViewer } from "../../../../../assets/photoViewer";
import dayjs from "dayjs";

const CustomTypographyTableCell = ({ children, color = "#000" }) => {
  return (
    <Typography
      sx={{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 12,
        lineHeightt: "normal",
        color: color,
        textTransform: "capitalize",
      }}>
      {children}
    </Typography>
  );
};

const CustomTypographyTableHeader = ({ title }) => {
  return (
    <Typography
      sx={{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 14,
        lineHeightt: "normal",
        color: "#000",
        textTransform: "capitalize",
        //opacity: 0.6,
      }}>
      {title}
    </Typography>
  );
};

const DataTable = ({ data }) => {
  const { height, width } = useWindowDimensions();

  const [open, setOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  const handleClickOpen = (url) => {
    setOpen(true);
    setImageUrl(url);
  };

  const handleClose = () => {
    setOpen(false);
    setImageUrl(null);
  };

  const columns = (width) => [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: width / 18,
      headerClassName: "super-app-theme--header",
      display: "flex",
    },
    {
      field: "invoiceDate",
      display: "flex",
      renderHeader: (params) => (
        <Box sx={{ ml: 5 }}>
          <CustomTypographyTableHeader title="Invoice Date" />
        </Box>
      ),
      width: width / 9,
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "left",
      renderCell: (cellValues) => (
        <Box sx={{ ml: 5 }}>
          <CustomTypographyTableCell>
            {cellValues.value ? dayjs(cellValues.value).format("LL") : ""}
          </CustomTypographyTableCell>
        </Box>
      ),
    },
    {
      field: "serviceDetails",
      display: "flex",
      renderHeader: (params) => <CustomTypographyTableHeader title="Service Details" />,
      width: (3 * width) / 18,
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "left",
      renderCell: (cellValues) => (
        <CustomTypographyTableCell>{cellValues.value}</CustomTypographyTableCell>
      ),
    },
    {
      field: "paymentStatus",
      display: "flex",
      renderHeader: (params) => <CustomTypographyTableHeader title="Payment Status" />,
      width: width / 6,
      headerClassName: "super-app-theme--header",
      align: "left",
      headerAlign: "left",
      renderCell: (cellValues) => (
        <CustomTypographyTableCell color={PaymentStatusColor[cellValues.value]}>
          {cellValues.value
            ? PaymentStatusList.find((v) => v.value === cellValues.value)?.label
            : ""}
        </CustomTypographyTableCell>
      ),
    },
    {
      field: "totalInvoiceAmount",
      display: "flex",
      renderHeader: (params) => <CustomTypographyTableHeader title="Total Invoice Amount" />,
      width: width / 6,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => (
        <CustomTypographyTableCell>{cellValues.value}</CustomTypographyTableCell>
      ),
    },
    {
      field: "totalReceivableAmount",
      display: "flex",
      renderHeader: (params) => <CustomTypographyTableHeader title="Total Receivables" />,
      width: width / 6,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => (
        <CustomTypographyTableCell>{cellValues.value}</CustomTypographyTableCell>
      ),
    },
    {
      field: "invoiceUrl",
      display: "flex",
      renderHeader: (params) => <CustomTypographyTableHeader title="Invoice File" />,
      width: width / 6,
      headerClassName: "super-app-theme--header",
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => (
        <Button onClick={() => handleClickOpen(cellValues.value)}>
          <CustomTypographyTableCell>
            <span style={{ color: "#127DDD" }}>View File</span>
          </CustomTypographyTableCell>
        </Button>
      ),
    },
  ];

  return (
    <Fragment>
      <PhotoViewer url={imageUrl} open={open} handleClose={handleClose} />
      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          height: height - 250,
          minHeight: 300,
          px: 1,
          "& .super-app-theme--header": {
            backgroundColor: "#F5F5FF",
          },
        }}>
        <CustomDataPanels
          dataList={data}
          columns={columns(width - 69)}
          enableCheckbox={true}
          disableMultipleRowSelection={true}
        />
      </Box>
    </Fragment>
  );
};

export default DataTable;

const PaymentStatusList = [
  { label: "Full Payment Pending", value: "FULL_PAYMENT_PENDING" },
  { label: "Partial Payment Received", value: "PARTIAL_PAYMENT_RECEIVED" },
  { label: "Full Payment Received", value: "FULL_PAYMENT_RECEIVED" },
];

export const PaymentStatusColor = {
  FULL_PAYMENT_RECEIVED: "green",
  PARTIAL_PAYMENT_RECEIVED: "#FF7F7F",
  FULL_PAYMENT_PENDING: "red",
};
