export const colors = {
  primary: "#127DDD",
  white: "#FFFFFF",
  black: "#000000",
  lightgray: "rgba(0, 0, 0, 0.3)",
  darkgray: "rgba(0, 0, 0, 0.87)",

  completed: "#CCFFCB",
  pending: "#FFFCD2",
  cancelled: "#FFE0E0",
  bookingConfirmed: "#eab676",
};
