import RootTemplate from "../../global/templates/rootTemplate";
import StaffingDoctorsMain from "../../modules/staffing/doctors/staffingDoctorsMain";

const DoctorsIndex = () => {
  return (
    <RootTemplate>
      <StaffingDoctorsMain />
    </RootTemplate>
  );
};

export default DoctorsIndex;
