import { Box, Grid, Typography } from "@mui/material";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { getData } from "../../../../../services/api/getApi";
import { useEffect, useState } from "react";
import BPRangeIMG from "../../../../assets/images/bp-range-chart3.png";
import CustomDoughnutChartNew from "./customDoughnutChartNew";
import StackedBarChart from "./stackedBarChart";
import { listSortedByProp } from "../../../../global/actions/actions";

const getEmpBreakupLabels = (id) => {
  let labels = {
    ONROLLSMALL: "OnRoll",
    ONROLL: "ONROLL",
    CONTRACTORSMALL: "Contractor",
    CONTRACTOR: "CONTRACTOR",
  };

  if (id === "455f4926-ea38-476d-ad49-0c3587c65200") {
    labels = {
      ONROLLSMALL: "Officers",
      ONROLL: "OFFICERS",
      CONTRACTORSMALL: "Workers",
      CONTRACTOR: "WORKERS",
    };
  } else if (id === "c59a2fbc-bc3d-453c-aae4-313581793890") {
    labels = {
      ONROLLSMALL: "Staff",
      ONROLL: "STAFF",
      CONTRACTORSMALL: "OffRoll",
      CONTRACTOR: "OFFROLL",
    };
  }

  return labels;
};

const graphList = (value, graphValue, config, customLables) => {
  const tempMap = new Map();
  config?.map((val) => {
    tempMap.set(val?.nameId, val?.access);
  });

  let empBreakupData = listSortedByProp(value?.EMPLOYEE_BREAKUP);
  empBreakupData = empBreakupData?.map((item) => ({
    count: item?.count,
    name: item?.name,
    legendLabel: customLables?.[item?.name],
    sequence: item?.sequence,
    value: item?.value,
  }));

  console.log({ employeebreakup: empBreakupData });
  let list = [
    {
      id: 1,
      type: "NONBPVITALS",
      name: "sugar",
      title: "Sugar Chart Percentage",
      labelHeading: "Total #",
      isActive:
        tempMap.get("SugarPercent") &&
        listSortedByProp(value?.SUGAR)?.length !== 0 &&
        !listSortedByProp(value?.SUGAR)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#F8A649", "#F66"],
      chartdata: listSortedByProp(value?.SUGAR),
      dialogData: graphValue?.SUGAR,
    },
    {
      id: 2,
      type: "NONBPVITALS",
      name: "creatinine",
      title: "Creatinine Chart Percentage",
      labelHeading: "Total #",
      isActive:
        tempMap.get("CreatininePercent") &&
        listSortedByProp(value?.CREATININE)?.length !== 0 &&
        !listSortedByProp(value?.CREATININE)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      labelWidth: "120px",
      colors: ["#42BD53", "#F8A649", "#F66"],
      chartdata: listSortedByProp(value?.CREATININE),
      dialogData: graphValue?.CREATININE,
    },
    {
      id: 3,
      type: "NONBPVITALS",
      name: "eyetest",
      title: "Eye Test Chart",
      labelHeading: "Total #",
      isActive:
        tempMap.get("EyeTest") &&
        listSortedByProp(value?.EYE_TEST)?.length !== 0 &&
        !listSortedByProp(value?.EYE_TEST)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#F8A649", "#F66"],
      chartdata: listSortedByProp(value?.EYE_TEST),
      dialogData: graphValue?.EYE_TEST,
    },
    {
      id: 4,
      type: "NONBPVITALS",
      name: "sgot",
      title: "SGOT Chart Percentage",
      labelHeading: "Total #",
      isActive:
        tempMap.get("SgotPercent") &&
        listSortedByProp(value?.SGOT)?.length !== 0 &&
        !listSortedByProp(value?.SGOT)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#F8A649", "#F66"],
      chartdata: listSortedByProp(value?.SGOT),
      dialogData: graphValue?.SGOT,
    },
    {
      id: 5,
      type: "NONBPVITALS",
      name: "urine",
      title: "Urine Glucose Chart",
      labelHeading: "Total #",
      isActive:
        tempMap.get("UrinePercent") &&
        listSortedByProp(value?.URINE_GLUCOSE)?.length !== 0 &&
        !listSortedByProp(value?.URINE_GLUCOSE)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#F8A649", "#F66"],
      chartdata: listSortedByProp(value?.URINE_GLUCOSE),
      dialogData: graphValue?.URINE_GLUCOSE,
    },
    {
      id: 6,
      type: "NONBPVITALS",
      name: "bmi",
      title: "BMI Chart",
      labelHeading: "Total #",
      isActive:
        tempMap.get("BMIPercent") &&
        listSortedByProp(value?.BMI)?.length !== 0 &&
        !listSortedByProp(value?.BMI)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#F8A649", "#F66"],
      chartdata: listSortedByProp(value?.BMI),
      dialogData: graphValue?.BMI,
    },
    {
      id: 7,
      type: "NONBPVITALS",
      name: "cholestrol",
      title: "Cholestrol Chart",
      labelHeading: "Total #",
      isActive:
        tempMap.get("ChlestrolPercent") &&
        listSortedByProp(value?.TOTAL_CHOLESTROL)?.length !== 0 &&
        !listSortedByProp(value?.TOTAL_CHOLESTROL)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#F8A649", "#F66"],
      chartdata: listSortedByProp(value?.TOTAL_CHOLESTROL),
      dialogData: graphValue?.TOTAL_CHOLESTROL,
    },
    {
      id: 8,
      type: "BPVITALS",
      name: "BP",
      title: "BP Chart Percentage",
      labelHeading: "Total #",
      labelWidth: "200px",
      isActive:
        tempMap.get("BpPercent") &&
        listSortedByProp(value?.BP)?.length !== 0 &&
        !listSortedByProp(value?.BP)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#F8A649", "#F66"],
      chartdata: listSortedByProp(value?.BP),
      dialogData: graphValue?.BP,
    },
    {
      id: 9,
      type: "EMPLOYEESBREAKUP",
      name: "onRollCheckStatus",
      title: `${customLables?.ONROLLSMALL} Employees Test Status`,
      labelHeading: "Total #",
      isActive:
        tempMap.get("OnRollEmployeesCheckupStatus") &&
        listSortedByProp(value?.CHECKUP_STATUS_ONROLL)?.length !== 0 &&
        !listSortedByProp(value?.CHECKUP_STATUS_ONROLL)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#F8A649", "#F66"],
      chartdata: listSortedByProp(value?.CHECKUP_STATUS_ONROLL),
      dialogData: graphValue?.CHECKUP_STATUS_ONROLL,
    },
    {
      id: 10,
      type: "EMPLOYEESBREAKUP",
      name: "offRollCheckStatus",
      title: `${customLables?.CONTRACTORSMALL} Employees Test Status`,
      labelHeading: "Total #",
      isActive:
        tempMap.get("OffRollEmployeesCheckupStatus") &&
        listSortedByProp(value?.CHECKUP_STATUS_OFFROLL)?.length !== 0 &&
        !listSortedByProp(value?.CHECKUP_STATUS_OFFROLL)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#F8A649", "#F66"],
      chartdata: listSortedByProp(value?.CHECKUP_STATUS_OFFROLL),
      dialogData: graphValue?.CHECKUP_STATUS_OFFROLL,
    },

    {
      id: 11,
      type: "NONBPVITALS",
      name: "haemoglobin",
      title: "Haemoglobin Chart",
      labelHeading: "Total #",
      isActive:
        tempMap.get("HaemoglobinPercent") &&
        listSortedByProp(value?.HAEMOGLOBIN)?.length !== 0 &&
        !listSortedByProp(value?.HAEMOGLOBIN)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#F8A649", "#F66"],
      chartdata: listSortedByProp(value?.HAEMOGLOBIN),
      dialogData: graphValue?.HAEMOGLOBIN,
    },
    {
      id: 12,
      type: "NONBPVITALS",
      name: "ecg",
      title: "ECG Chart",
      labelHeading: "Total #",
      isActive:
        tempMap.get("EcgPercent") &&
        listSortedByProp(value?.ECG)?.length !== 0 &&
        !listSortedByProp(value?.ECG)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#F8A649", "#F66"],
      chartdata: listSortedByProp(value?.ECG),
      dialogData: graphValue?.ECG,
    },
    {
      id: 13,
      type: "NONBPVITALS",
      name: "xray",
      title: "Chest Xray Chart",
      labelHeading: "Total #",
      isActive:
        tempMap.get("XrayPercent") &&
        listSortedByProp(value?.XRAY)?.length !== 0 &&
        !listSortedByProp(value?.XRAY)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#F8A649", "#F66"],
      chartdata: listSortedByProp(value?.XRAY),
      dialogData: graphValue?.XRAY,
    },
  ];

  return list.filter((val) => val.isActive);
};

const reorderSequence = (data) => {
  const newSequenceOrder = [2, 5, 3, 1, 4, 6, 8, 7, 9, 10, 11, 12, 13, 14, 15];
  const sortedData = data.sort((a, b) => {
    return (
      newSequenceOrder.indexOf(a.sequence) -
      newSequenceOrder.indexOf(b.sequence)
    );
  });

  return sortedData;
};

const VitalsGraphMahindra = ({
  corpId = localStorage.getItem("CORPID"),
  selectedDepartment,
  statusTrackerConfig = JSON.parse(
    localStorage.getItem("STATUSTRACKER_CONFIG")
  ),
  selectedEmployementType,
  selectedCampId,
}) => {
  const [vitalData, setVitalData] = useState([]);
  const [graphData, setGraphData] = useState([]);

  const _fetchVitalData = async () => {
    if (selectedCampId !== "") {
      const url =
        BASE_URL +
        `org/ahcStatusTracker/${corpId}?department=${
          selectedDepartment || ""
        }&employmentType=${
          selectedEmployementType === "ALL" || selectedEmployementType === null
            ? ""
            : selectedEmployementType
        }&campCycleId=${selectedCampId === "null" ? "" : selectedCampId}`;
      const vitals = await getData(url, "");
      if (vitals.error) {
        setVitalData([]);
      } else {
        setVitalData(vitals.data.graphValueMap);
      }
    }
  };

  const _fetchGraphData = async () => {
    if (selectedCampId !== "") {
      const url =
        BASE_URL +
        `org/ahcStatusDepartmentsData/${corpId}?campCycleId=${
          selectedCampId === "null" ? "" : selectedCampId
        }`;

      const graphData = await getData(url);
      if (graphData.error) {
        setGraphData([]);
      } else {
        setGraphData(graphData.data.dataMaps);
      }
    }
  };

  useEffect(() => {
    _fetchVitalData();
  }, [selectedDepartment, selectedEmployementType]);

  useEffect(() => {
    _fetchGraphData();
  }, [selectedCampId]);

  console.log({ corpId, vitalData, graphData });
  console.log({ statusTrackerConfig });

  return (
    <Box>
      <Grid container spacing={3}>
        {/************** Employees Breakup chart start **********/}
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            container
            spacing={3}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {graphList(
              vitalData,
              graphData,
              statusTrackerConfig,
              getEmpBreakupLabels(corpId)
            )
              ?.filter((value) => value.type === "EMPLOYEESBREAKUP")
              ?.map((val, index) => (
                <Grid item lg={4} md={4} sm={12} xs={12} key={index}>
                  <CustomDoughnutChartNew
                    COLORS={val?.colors}
                    data={val?.chartdata}
                    title={val?.title}
                    labelHeading={val?.labelHeading}
                    dialogData={val?.dialogData}
                    selectedDepartment={selectedDepartment}
                    disabled={true}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
        {/************** Employees Breakup chart end **********/}

        {/************** Healthy/Unhealthy chart Start **********/}
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            container
            spacing={3}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {graphList(
              vitalData,
              graphData,
              statusTrackerConfig,
              getEmpBreakupLabels(corpId)
            )
              ?.filter((value) => value.type === "EMPLOYEESBYDEPARTMENT")
              ?.map((val, index) => (
                <Grid
                  item
                  lg={
                    graphList(
                      vitalData,
                      graphData,
                      statusTrackerConfig,
                      getEmpBreakupLabels(corpId)
                    )?.filter((value) => value.type === "EMPLOYEESBYDEPARTMENT")
                      .length > 1
                      ? 6
                      : 12
                  }
                  md={12}
                  sm={12}
                  xs={12}
                  key={index}
                >
                  <StackedBarChart
                    barWidth={val?.barWidth}
                    title={val?.title}
                    mapName={val?.mapName}
                    data={val?.chartdata}
                    dialogData={val?.dialogData}
                    dialogDataHealthy={val?.dialogDataHealthy}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
        {/************** Healthy/Unhealthy chart End **********/}

        {/************** BP chart Start **********/}
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {graphList(
            vitalData,
            graphData,
            statusTrackerConfig,
            getEmpBreakupLabels(corpId)
          )
            ?.filter((value) => value.type === "BPVITALS")
            ?.map((val, index) => (
              <Grid
                key={index}
                container
                spacing={3}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box
                    sx={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid grey",
                      height: "300px",
                      paddingInline: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <Typography
                      textAlign="left"
                      sx={{
                        paddingTop: "20px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "120%",
                      }}
                    >
                      BP Reference Chart
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="img"
                        src={BPRangeIMG}
                        width="100%%"
                        height={"240px"}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <CustomDoughnutChartNew
                    COLORS={val?.colors}
                    data={val?.chartdata}
                    title={val?.title}
                    labelHeading={val?.labelHeading}
                    dialogData={val?.dialogData}
                    labelWidth={val?.labelWidth}
                    selectedDepartment={selectedDepartment}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            ))}
        </Grid>
        {/************** BP chart end **********/}

        {/************** Other Vitals chart Start **********/}
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            container
            spacing={3}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {graphList(
              vitalData,
              graphData,
              statusTrackerConfig,
              getEmpBreakupLabels(corpId)
            )
              ?.filter((value) => value.type === "NONBPVITALS")
              ?.map((val, index) => (
                <Grid item lg={4} md={4} sm={12} xs={12} key={index}>
                  <CustomDoughnutChartNew
                    COLORS={val?.colors}
                    data={val?.chartdata}
                    title={val?.title}
                    labelHeading={"Total #"}
                    dialogData={val?.dialogData}
                    selectedDepartment={selectedDepartment}
                    labelWidth={val?.labelWidth}
                    disabled={true}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
        {/************** Other Vitals chart End **********/}
      </Grid>
    </Box>
  );
};

export default VitalsGraphMahindra;
