import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import CustomDataPanels from "./comps/customDataPanels";
import { type } from "@testing-library/user-event/dist/type";
import useWindowDimensions from "../../../../assets/customhooks";
import StatusFilterComp from "./comps/statusFilterComp";
import StatusLegendComp from "./comps/statusLegendComp";
import NewFormComp from "./comps/newFormComp";
import { BASE_URL } from "../../../../assets/constantsFile";
import dayjs from "dayjs";
import { getData } from "../../../../services/api/getApi";

const StatusListForNonFilter = {
  TICKET_RAISED: {
    id: 1,
    label: "Pending",
    value: "TICKET_RAISED",
    color: "#e2435c",
  },
  BOOKING_CONFIRMED: {
    id: 2,
    label: "Booking Confirmed",
    value: "BOOKING_CONFIRMED",
    color: "#eab676",
  },
  COMPLETED: {
    id: 3,
    label: "Completed",
    value: "COMPLETED",
    color: "#3caf4a",
  },
};

const TestType = {
  PRE_EMPLOYMENT: {
    id: 1,
    label: "Pre employment",
    value: "PRE_EMPLOYMENT",
    color: "#e2435c",
  },
};

const CustomTypographyTableCell = ({ children }) => {
  return (
    <Typography
      sx={{
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "12px",
        lineHeightt: "normal",
        color: "#000",
        textTransform: "capitalize",
      }}>
      {children}
    </Typography>
  );
};

const CustomTypographyTableHeader = ({ title }) => {
  return (
    <Typography
      sx={{
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeightt: "normal",
        color: "#000",
        textTransform: "capitalize",
        opacity: 0.6,
      }}>
      {title}
    </Typography>
  );
};

const columns = (width) => [
  {
    field: "empId",
    display: "flex",
    renderHeader: (params) => (
      <Box sx={{ ml: 5 }}>
        <CustomTypographyTableHeader title="Emp. ID" />
      </Box>
    ),
    width: width / 6,
    headerClassName: "super-app-theme--header",
    align: "left",
    headerAlign: "left",
    renderCell: (cellValues) => (
      <Box sx={{ ml: 5 }}>
        <CustomTypographyTableCell>{cellValues.value}</CustomTypographyTableCell>{" "}
      </Box>
    ),
  },
  {
    field: "name",
    display: "flex",
    renderHeader: (params) => <CustomTypographyTableHeader title="Name" />,
    width: width / 6,
    headerClassName: "super-app-theme--header",
    align: "left",
    headerAlign: "left",
    renderCell: (cellValues) => (
      <CustomTypographyTableCell>{cellValues.value}</CustomTypographyTableCell>
    ),
  },
  {
    field: "testType",
    display: "flex",
    renderHeader: (params) => <CustomTypographyTableHeader title="Test Type" />,
    width: width / 6,
    headerClassName: "super-app-theme--header",
    align: "left",
    headerAlign: "left",
    renderCell: (cellValues) => (
      <CustomTypographyTableCell>{cellValues.value}</CustomTypographyTableCell>
    ),
  },
  {
    field: "date",
    display: "flex",
    renderHeader: (params) => <CustomTypographyTableHeader title="Date" />,
    width: width / 6,
    headerClassName: "super-app-theme--header",
    align: "left",
    headerAlign: "left",
    renderCell: (cellValues) => (
      <CustomTypographyTableCell>{cellValues.value}</CustomTypographyTableCell>
    ),
  },
  {
    field: "package",
    display: "flex",
    renderHeader: (params) => <CustomTypographyTableHeader title="Package" />,
    width: width / 6,
    headerClassName: "super-app-theme--header",
    align: "left",
    headerAlign: "left",
    renderCell: (cellValues) => (
      <CustomTypographyTableCell>{cellValues.value}</CustomTypographyTableCell>
    ),
  },
  {
    field: "status",
    display: "flex",
    renderHeader: (params) => <CustomTypographyTableHeader title="Status" />,
    width: width / 6,
    headerClassName: "super-app-theme--header",
    align: "left",
    headerAlign: "left",
    renderCell: (cellValues) => (
      <CustomTypographyTableCell>{cellValues.value}</CustomTypographyTableCell>
    ),
  },
];
const NewRequestformMain = ({
  corpId = localStorage.getItem("CORPID"),
  startDate = dayjs().subtract(300, "day").format("YYYY-MM-DD"),
  endDate = dayjs().format("YYYY-MM-DD"),
}) => {
  const { height, width } = useWindowDimensions();
  const [status, setStatus] = useState("ALL");

  console.log({ height, width });
  const [ticketList, setTicketList] = useState([]);
  const [ticketListStatic, setTicketListStatic] = useState([]);

  const fetchTicketList = async () => {
    const url =
      BASE_URL + `org/ticket/all?corpId=${corpId}&startDate=${startDate}&endDate=${endDate}`;

    const res = await getData(url);
    if (res.error) {
      setTicketList([]);
    } else {
      let filteredData = res.data
        ?.filter((item) => item.ticketType === "PRE_EMPLOYMENT")
        .map((item, index) => ({
          ...item,
          id: index + 1,
          name: item?.ticketInfo.name,
          empId: item?.ticketInfo.empId,
          testType: item?.ticketInfo.testType ? TestType[item?.ticketInfo.testType]?.label : "",
          package: item?.ticketInfo.package,
          statusEnum: item?.status,
          status: item?.status ? StatusListForNonFilter[item?.status]?.label : "",
          date: item.date ? dayjs(item.date).format("LL") : "",
        }));
      setTicketList(filteredData);
      setTicketListStatic(filteredData);
    }
  };
  useEffect(() => {
    fetchTicketList();
  }, []);
  console.log({ ticketList });

  useEffect(() => {
    setTicketList(
      ticketListStatic.filter((a) => (!status || status === "ALL" ? true : status === a.statusEnum))
    );
  }, [status]);

  let formData = new FormData();

  return (
    <Fragment>
      <Container maxWidth={false}>
        <Box>
          <Grid container spacing={2}>
            <Grid item lg={12}>
              <Stack
                direction="row-reverse"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                spacing={3}>
                <NewFormComp fetchTicketList={fetchTicketList} formData={formData} />
              </Stack>
            </Grid>
            <Grid item lg={12}>
              <Stack
                direction="row"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                spacing={3}>
                <StatusFilterComp status={status} setStatus={setStatus} />
                <StatusLegendComp size={ticketList?.length} />
              </Stack>
            </Grid>
            <Grid item lg={12}>
              <Box
                sx={{
                  flexGrow: 1,
                  width: "100%",
                  height: height - 254,
                  // borderRadius: 6,
                  // boxShadow: "0px 0px 6px 0px rgba(108, 120, 221, 0.40);",
                  px: 1,
                  "& .super-app-theme--header": {
                    backgroundColor: "#F5F5FF",
                    // borderTopLeftRadius: 6,
                    // borderTopRightRadius: 6,
                  },
                }}>
                <CustomDataPanels dataList={ticketList} columns={columns(width - 122)} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Fragment>
  );
};

export default NewRequestformMain;
