import RootTemplate from "../../global/templates/rootTemplate";
import PharmacyMedicinesMain from "../../modules/pharmacy/medicines/PharmacyMedicinesMain";
import MedicinesMain from "../../modules/pharmacy/medicines/medicinesMain";

const PermittedList = [
  "6d5ce94f-6b9b-4efa-a650-1298481cb413", // EEPL
  "bbb269e5-b020-4257-ad8f-4da8c811801a", // Case Constructions
  "13a4b8c6-750f-442a-8414-f5465481e5d9",
  "656f54f4-e8c0-42f5-afc1-31e158fe8f6f", // Symbiosis University of Applied Sciences
  "1ab48481-e6f5-4dd6-8b50-983c94d763a1",
  "820df4bf-eb31-4bc9-9cd1-789b36f47883",
  "3e875c62-9ecb-49b7-9fda-067379425f75",
  "dfe08099-3324-4d60-807a-b04fe8b4a373",
  "872cd841-9f7a-432d-b8e9-422b780bca10",
  "1f7b5e98-04ee-47e5-b6cd-300f0dc98281", //DCM Nouvelle Speciality Chemicals
  "323d16b9-709b-4b83-9826-c1917e7114a6", //Glenmark pharmaceuticals
  "f6eaa9c1-5208-4b15-aa42-ee649ae0990f", //JBM
  "e3c09e06-145f-40c4-bce6-f8772789601b", //L&T
  "0143386f-5bc2-4df8-ad70-43d4c7360cb2", //MUDRA STEEL INDUSTRIES PRIVATE LIMITED
  "e78bd9d1-06f0-4701-9c0d-8e1d1a79fe69",
  "cd86fc1d-ca50-42f2-9a53-1003aea3993e", // Pinnacle
  "47f6ab07-4fc2-45f8-83e0-38c88504861a", //RPSPL PRIVATE LIMITED
  "ee825bad-ed64-46ed-a3a2-67a17f81ac7f", // Innovative Clad Solutions Pvt Ltd
  "9b7e33eb-d085-4df5-bdc1-146d930c2387", // Aperam Alloys India Private Limited
  "c59a2fbc-bc3d-453c-aae4-313581793890", // Indore Composite
];

const MedicinesIndex = ({ corpId = localStorage.getItem("CORPID") }) => {
  return (
    <RootTemplate>
      {PermittedList.includes(corpId) ? (
        <MedicinesMain />
      ) : (
        <PharmacyMedicinesMain />
      )}
    </RootTemplate>
  );
};

export default MedicinesIndex;
