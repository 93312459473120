import { Box, Container, Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import { Link } from "react-router-dom";

const DefaultMain = () => {
  return (
    <Fragment>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12} textAlign="center">
            <Box
              sx={{
                height: "55vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography variant="h6">
                Your company has not opted for this service. Please contact&nbsp;
                <Link to="https://www.uno.care/corplanding">uno.care</Link> for this.
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}></Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {/* <LandingServiceAlt /> */}
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default DefaultMain;
