import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  Portal,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { Fragment, useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import zoomIcon from "../../../../../assets/images/zoomIcon.png";
import StatusTrackerDetailGrid from "../../../../global/customDatagrids/statusTrackerDetailGrid";
const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#127DDD",
  "#D45FFF",
  "#DDFFDF",
];

let renderLabel = function (entry) {
  return `${entry.name}(${entry.value}%)`;
};

const CustomizedAxisTick = (props) => {
  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-20)"
        fontSize={12}
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomTooltipContent = ({ active, payload, label, tooltipTitle }) => {
  if (active) {
    const data = payload?.[0]?.payload; // Get the data of the hovered bar

    return (
      // <Tooltip>
      <Box sx={{ backgroundColor: "#FFFFFF", padding: "10px" }}>
        {label ? <Typography>{`${label}`}</Typography> : null}
        {data?.value ? (
          <Typography>{`${"Count"}: ${data?.value}`}</Typography>
        ) : null}
        {data?.percent ? (
          <Typography>{`${tooltipTitle ? tooltipTitle : "Percentage"}: ${
            data?.percent
          }`}</Typography>
        ) : null}
        {data?.count ? (
          <Typography>{`Count: ${data?.count}`}</Typography>
        ) : null}
      </Box>
      // </Tooltip>
    );
  }

  return null;
};

const CustomBarGraphOhcStatusTracker = ({
  data,
  title,
  dialogData,
  barsName,
  barColor,
  popupData,
  setSelectedBar,
  disableClickable,
  drawerData,
  minWidth,
}) => {
  console.log({ dialogData });
  const [apiRef, setApiRef] = useState(null);
  const exportCSV = () => {
    apiRef.current.exportDataAsCsv();
  };
  const [open, setOpen] = useState(false);
  const [selectedChart, setSelectedChart] = useState("");
  const [selectedBarData, setSelectedBarData] = useState([]);
  const handleOpen = (title) => {
    setOpen(true);
    setSelectedChart(title);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedChart("");
    setSelectedBarData([]);
  };
  console.log({ HLELLLELE: data });
  console.log({ mmmmmm: dialogData?.[selectedChart] });
  useEffect(() => {
    if (dialogData) {
      const data = dialogData[selectedChart];
      console.log({ data });
      if (data) {
        setSelectedBarData(data?.filter((obj) => obj.emp_id !== null));
      } else {
        console.log("Selected chart not found in dialogData");
      }
    }
  }, [selectedChart]);
  console.log({ popupData });

  useEffect(() => {
    if (popupData) {
      setSelectedBarData(popupData);
    }
  }, [popupData]);

  console.log({ selectedBarData });
  console.log({ selectedChart });
  console.log({ data });

  const [graphTitle, setGraphTitle] = useState("");
  const [graphData, setGraphData] = useState([]);
  const [graphFill, setGraphFill] = useState("");

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          border: "1px solid grey",
          paddingInline: "10px",
          paddingBlock: "20px",
          borderRadius: "10px",
          minWidth: minWidth,
        }}
      >
        <Box sx={{ height: "300px" }}>
          {data.every((item) => item.value === 0) ? (
            <Box
              width={"100%"}
              height={250}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ textAlign: "center" }}>
                No Data Found
              </Typography>
            </Box>
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                barSize={40}
                width={730}
                height={250}
                data={data}
                onClick={(bar) => {
                  console.log({ bar });
                  if (disableClickable) {
                    return;
                  } else {
                    handleOpen(bar?.activeLabel);
                    if (setSelectedBar) {
                      setSelectedBar(bar?.activeLabel);
                    }
                  }
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  style={{ fontSize: 12 }}
                  tick={<CustomizedAxisTick />}
                  interval={0}
                  height={100}
                />

                <YAxis />
                <Tooltip />
                <Legend verticalAlign="top" height={36} />
                <Bar
                  dataKey="value"
                  fill={barColor ? barColor : "#82ca9d"}
                  name={barsName}
                >
                  <LabelList dataKey="value" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )}
        </Box>
        <Stack
          direction="row"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography textAlign="center">{title}</Typography>
          <IconButton
            onClick={() => {
              handleDrawerToggle();
              setGraphTitle(title);
              setGraphData(drawerData);
              setGraphFill(barColor ? barColor : "#82ca9d");
            }}
          >
            <Box
              component={"img"}
              src={zoomIcon}
              sx={{ height: "20px", width: "20px" }}
            />
          </IconButton>
        </Stack>
      </Box>

      <Portal>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={"xl"}
          fullWidth={true}
        >
          <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
            {title}
          </DialogTitle>
          <Typography
            variant="h6"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {selectedChart}
          </Typography>
          <Box sx={{ marginLeft: "20px" }}>
            <Button
              variant="contained"
              size="small"
              sx={{ height: 40, borderRadius: 4 }}
              onClick={exportCSV}
            >
              Download CSV
            </Button>
          </Box>
          <DialogActions sx={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
          <DialogContent>
            <StatusTrackerDetailGrid
              setApiRef={setApiRef}
              employeeList={selectedBarData || []}
            />
          </DialogContent>
        </Dialog>
      </Portal>

      <Portal>
        <Drawer
          anchor="bottom"
          variant="temporary"
          open={openDrawer}
          onClose={handleDrawerToggle}
        >
          <Box
            sx={{
              height: "70vh",
              width: "100%",
              mb: 10,
            }}
          >
            <Box sx={{ height: "300px" }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart width={730} height={250} data={graphData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    style={{ fontSize: 12 }}
                    tick={<CustomizedAxisTick />}
                    // interval={4}
                    height={100}
                  />
                  <YAxis />
                  <Tooltip />
                  <Legend verticalAlign="top" height={36} />
                  <Bar
                    dataKey="value"
                    fill={graphFill}
                    barSize={130}
                    name="Number of Employees"
                  >
                    <LabelList dataKey="value" position="top" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Box>
            <Stack
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack width={"55%"} display={"flex"} alignItems="flex-end">
                <Typography textAlign="center">{graphTitle}</Typography>
              </Stack>
              <Stack>
                <Button onClick={handleDrawerToggle}>Close</Button>
              </Stack>
            </Stack>
          </Box>
        </Drawer>
      </Portal>
    </Fragment>
  );
};

export default CustomBarGraphOhcStatusTracker;
