import { Box, Divider, Grid, Stack, Typography } from "@mui/material";

const PrintIssue = (props) => {
  const issueInfo = props.issueInfo;
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography sx={{ marginTop: "20px" , fontWeight: 700,lineHeight: "13px",color: "#404040",  }}>Symptoms</Typography>
        <Typography  sx={{ marginTop: "10px" , borderBottom: "1px solid #000000" }}>{issueInfo}</Typography>
      </Grid>
    </Grid>
  );
};

export default PrintIssue;
