import { Avatar, Box, IconButton, Stack, Typography } from "@mui/material";
import { Fragment, useContext, useState } from "react";
import { CorprateContext } from "../../../common/context/usercontext";
import { maskText } from "../../../assets/utils";
import { useNavigate } from "react-router-dom";

export const EncryptedTypography = ({
  text,
  corpRole = JSON.parse(localStorage.getItem("CORPROLE")),
}) => {
  const [isDisplay, setIsDisplay] = useState(true);
  const { isMaskEnabled, setIsMaskEnabled } = useContext(CorprateContext);
  let navigate = useNavigate();

  return (
    <Fragment>
      {corpRole ? (
        <IconButton onDoubleClick={() => setIsDisplay(!isDisplay)} sx={{ userSelect: "text" }}>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: 12,
              lineHeightt: 15,
              color: "#383838",
              textTransform: "capitalize",
            }}>
            {isDisplay && isMaskEnabled ? maskText(text) : text}
          </Typography>
        </IconButton>
      ) : (
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: 12,
            lineHeightt: 15,
            color: "#383838",
            textTransform: "capitalize",
          }}>
          {isDisplay && isMaskEnabled ? maskText(text) : text}
        </Typography>
      )}
    </Fragment>
  );
};

export const EncryptedTypographyMobile = ({
  text,
  corpRole = JSON.parse(localStorage.getItem("CORPROLE")),
}) => {
  const [isDisplay, setIsDisplay] = useState(true);
  const { isMaskEnabled, setIsMaskEnabled } = useContext(CorprateContext);
  let navigate = useNavigate();

  const { mobile, empId } = text;

  return (
    <Fragment>
      {corpRole ? (
        <IconButton
          disabled={isDisplay && isMaskEnabled}
          onDoubleClick={() => setIsDisplay(!isDisplay)}
          sx={{ userSelect: "text" }}
          onClick={() =>
            navigate(`/corporatehome/employeeprofile/${empId}`, {
              state: {
                employeeId: empId,
              },
            })
          }>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: 12,
              lineHeightt: 15,
              color: "#383838",
              textTransform: "capitalize",
            }}>
            {isDisplay && isMaskEnabled ? maskText(mobile) : mobile}
          </Typography>
        </IconButton>
      ) : (
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: 12,
            lineHeightt: 15,
            color: "#383838",
            textTransform: "capitalize",
          }}>
          {isDisplay && isMaskEnabled ? maskText(mobile) : mobile}
        </Typography>
      )}
    </Fragment>
  );
};

export const EncryptedTypographyEmpId = ({
  text,
  corpRole = JSON.parse(localStorage.getItem("CORPROLE")),
}) => {
  const [isDisplay, setIsDisplay] = useState(true);
  const { isMaskEnabled, setIsMaskEnabled } = useContext(CorprateContext);
  let navigate = useNavigate();

  return (
    <Fragment>
      {corpRole ? (
        <IconButton
          disabled={isDisplay && isMaskEnabled}
          onDoubleClick={() => setIsDisplay(!isDisplay)}
          sx={{ userSelect: "text" }}
          onClick={() =>
            navigate(`/corporatehome/employeeprofile/${text}`, {
              state: {
                employeeId: text,
              },
            })
          }>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: 12,
              lineHeightt: 15,
              color: "#383838",
              textTransform: "capitalize",
            }}>
            {isDisplay && isMaskEnabled ? maskText(text) : text}
          </Typography>
        </IconButton>
      ) : (
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: 12,
            lineHeightt: 15,
            color: "#383838",
            textTransform: "capitalize",
          }}>
          {isDisplay && isMaskEnabled ? maskText(text) : text}
        </Typography>
      )}
    </Fragment>
  );
};

export const EncryptedTypographyName = ({
  data,
  corpRole = JSON.parse(localStorage.getItem("CORPROLE")),
}) => {
  const [isDisplay, setIsDisplay] = useState(true);
  const { isMaskEnabled, setIsMaskEnabled } = useContext(CorprateContext);

  let navigate = useNavigate();

  return (
    <Fragment>
      {corpRole ? (
        <Stack direction="row">
          <Box>
            <Stack direction="row" alignItems="center" spacing={1}>
              {isDisplay && isMaskEnabled ? (
                <Avatar sx={{ width: 25, height: 25 }} alt={"*"} />
              ) : (
                <IconButton
                  disabled={isDisplay && isMaskEnabled}
                  sx={{ p: 0 }}
                  onClick={() =>
                    navigate(`/corporatehome/employeeprofile/${data.value.empId}`, {
                      state: {
                        employeeId: data.value.empId,
                      },
                    })
                  }>
                  <Avatar
                    sx={{ width: 25, height: 25 }}
                    alt={data.value.name}
                    src={data.value.imageUrl}
                  />
                </IconButton>
              )}
              <IconButton
                disabled={isDisplay && isMaskEnabled}
                onDoubleClick={() => setIsDisplay(!isDisplay)}
                onClick={() =>
                  navigate(`/corporatehome/employeeprofile/${data.value.empId}`, {
                    state: {
                      employeeId: data.value.empId,
                    },
                  })
                }
                sx={{ userSelect: "text" }}>
                <Typography
                  noWrap
                  sx={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: 12,
                    lineHeightt: 15,
                    color: "##383838",
                    textTransform: "capitalize",
                  }}>
                  {isDisplay && isMaskEnabled ? maskText(data.value.name) : data.value.name}
                </Typography>
              </IconButton>
            </Stack>
          </Box>
        </Stack>
      ) : (
        <Stack direction="row">
          <Box>
            <Stack direction="row" alignItems="center" spacing={1}>
              {isDisplay && isMaskEnabled ? (
                <Avatar sx={{ width: 25, height: 25 }} alt={"*"} />
              ) : (
                <Avatar
                  sx={{ width: 25, height: 25 }}
                  alt={data.value.name}
                  src={data.value.imageUrl}
                />
              )}

              <Typography
                noWrap
                sx={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: 12,
                  lineHeightt: 15,
                  color: "##383838",
                  textTransform: "capitalize",
                }}>
                {isDisplay && isMaskEnabled ? maskText(data.value.name) : data.value.name}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      )}
    </Fragment>
  );
};
