import { CssBaseline } from "@mui/material";
import React, { Component, Fragment } from "react";
import MyAppBarAfterLogin from "../common/header/myAppBarAfterLogin";
import PrescriptionTemplateMainComponent from "../templates/prescriptionTemplateMainComponent";

const Templates = (props) => {
  return (
    <Fragment>
      <CssBaseline />
      <MyAppBarAfterLogin />

      <PrescriptionTemplateMainComponent />
    </Fragment>
  );
};

export default Templates;
