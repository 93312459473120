import { useTheme } from "@emotion/react";
import { Box, Button, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import a from "../assets/a.png";
import b from "../assets/b.png";
import c from "../assets/c.png";
import d from "../assets/d.png";
import defaultImg from "../assets/default.png";
import digitalHealth from "../assets/digitalHealth.png";
import nablCertified from "../assets/nablCertified.png";
import emergency from "../assets/emergency.png";
import bestPrice from "../assets/bestPrice.png";
import whyPeoplePreferUsTitle from "../assets/whyPeoplePreferUsTitle.png";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { makeStyles } from "@mui/styles";
import ContactUsDialog from "./subComps/contactUsDialog";

const useStyles = makeStyles((theme) => ({
  animatedImage: {
    height: 52,
    width: 52,
    animation: "$translateImage 1.2s infinite alternate ease-in-out",
  },
  "@keyframes translateImage": {
    "0%": {
      transform: "translateY(0)",
    },
    "100%": {
      transform: "translateY(-10px)", // Adjust as needed
    },
  },
}));

const Specialization = ({ scrollToSection }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [currentImage, setCurrentImage] = useState(defaultImg);

  const CardBox = ({ data }) => {
    return (
      <Box
        onMouseEnter={() => setCurrentImage(data.hoverImage)} // Update image on hover
        onMouseLeave={() => setCurrentImage(defaultImg)} // Reset to default on leave
        sx={{
          width: 325,
          height: "200px",
          background:
            "linear-gradient(to bottom, rgba(52, 63, 208, 1) 30%, rgba(98, 109, 255, 1) 100%)",
          flexShrink: 0,
          backgroundColor: "#EAF2FE",
          borderRadius: "12px",
          transition: "all 0.3s ease-in-out",
          ":hover": {
            boxShadow: "0px 0px 15px 2px rgba(0, 0, 0, 0.3)",
            transform: "scale(1.1)",
          },
        }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "20px",
              fontWeight: "600",
              lineHeight: "130%" /* 26px */,
              paddingTop: 3,
              paddingLeft: 4,
            }}>
            {data?.title.split("\n").map((line, index) => (
              <Fragment key={index}>
                {line}
                <br />
              </Fragment>
            ))}
          </Typography>
          <Box
            sx={{
              padding: 2,
              height: 80,
              width: 80,
              backgroundColor: "#F3F4FF",
              boxShadow: "0px 0px 15px 2px rgba(0, 0, 0, 0.10)",
              borderBottomLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}>
            <Box
              component={"img"}
              src={data?.icon}
              // className={classes.animatedImage}
            />
          </Box>
        </Box>
        <Box sx={{ paddingInline: 4, mt: 1 }}>
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "18px",
              fontWeight: "300",
              lineHeight: "130%" /* 26px */,
            }}>
            {data?.text.split("\n").map((line, index) => (
              <Fragment key={index}>
                {line}
                <br />
              </Fragment>
            ))}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
            }}>
            <ContactUsDialog buttonSource="Schedule now" />
          </Box>
        </Box>
      </Box>
    );
  };

  const temp = [
    {
      icon: bestPrice,
      title: "Best\nprice guaranteed",
      text: "Without compromising on\nquality & care.",
      hoverImage: a, // Add corresponding hover image
    },
    {
      icon: emergency,
      title: "Emergency services\nwith care coordination",
      text: "Without compromising on\nquality & care.",
      hoverImage: b, // Add corresponding hover image
    },
    {
      icon: nablCertified,
      title: "100% authentic test",
      text: "Adhering to the highest\nquality standards.",
      hoverImage: c, // Add corresponding hover image
    },
    {
      icon: digitalHealth,
      title: "100%\ndigital health",
      text: "Optimal patient care &\nefficiency.",
      hoverImage: d, // Add corresponding hover image
    },
  ];
  return (
    <Fragment>
      <Box
        sx={{
          // height: isSmallScreen || isExtraSmallScreen ? null : "110vh",
          paddingBlock: isSmallScreen || isExtraSmallScreen ? "20px" : "35px",
          paddingLeft: isSmallScreen || isExtraSmallScreen ? null : "50px",
          paddingRight: isSmallScreen || isExtraSmallScreen ? null : "50px",
        }}>
        <Container maxWidth={false}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                marginBottom: 7,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img src={whyPeoplePreferUsTitle} style={{ height: 30 }} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={4.5}
              sx={{
                display: { xs: "none", sm: "none", md: "block", lg: "block" },
              }}>
              <Typography
                sx={{
                  //width: "95%",
                  color: "#2F3177",
                  fontSize: "40px",
                  fontWeight: "700",
                  lineHeight: "130%",
                }}>
                We care for your health more than you..
              </Typography>

              <Box>
                <Box component={"img"} src={currentImage} style={{ height: 350 }} />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={7}
              lg={7.5}
              sx={{
                display: "flex",
                justifyContent: {
                  xs: "center",
                  sm: "center",
                  md: "flex-end",
                  lg: "flex-end",
                },
                flexWrap: "wrap",
                gap: 2,
              }}>
              {temp.map((item, index) => (
                <CardBox data={item} key={index} />
              ))}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Fragment>
  );
};

export default Specialization;
