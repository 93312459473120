import React, { Fragment, useEffect } from "react";
import {
  useHMSActions,
  useHMSStore,
  selectCameraStreamByPeerID,
  useAVToggle,
} from "@100mslive/react-sdk";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import CallEndIcon from "@mui/icons-material/CallEnd";
import { useState } from "react";

const VideoTileLocal = ({ peer, isLocal }) => {
  const hmsActions = useHMSActions();
  const videoRef = React.useRef(null);
  const videoTrack = useHMSStore(selectCameraStreamByPeerID(peer.id));

  console.log({ videoTrack });

  React.useEffect(() => {
    (async () => {
      console.log(videoRef.current);
      console.log(videoTrack);
      if (videoRef.current && videoTrack) {
        if (videoTrack.enabled) {
          await hmsActions.attachVideo(videoTrack.id, videoRef.current);
        } else {
          await hmsActions.detachVideo(videoTrack.id, videoRef.current);
        }
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoTrack]);

  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.onunload = () => {
      hmsActions.leave();
    };
  }, [hmsActions]);

  const { isLocalAudioEnabled, isLocalVideoEnabled, toggleAudio, toggleVideo } =
    useAVToggle();

  return (
    <Fragment>
      <Box>
        <Box sx={{ width: "10vw", mt: 0.5, ml: 1 }}>
          {isLocalVideoEnabled ? (
            <video
              //height="120"
              width="200"
              style={{
                border: "1px solid black",
                borderRadius: 10,
              }}
              ref={videoRef}
              autoPlay={true}
              playsInline
              muted={true}
            ></video>
          ) : (
            <Box height="200" width="300">
              <Avatar
                variant="square"
                sx={{
                  borderRadius: 2,
                  height: 102,
                  width: 132,
                  p: 5,
                  color: "#fff",
                  backgroundColor: "rgba(0, 23, 63, 0.76)",
                  textAlign: "center",
                }}
              >
                {peer.name} {peer.isLocal ? "(You)" : ""}
              </Avatar>
            </Box>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

export default VideoTileLocal;
