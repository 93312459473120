import React, { Component, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
// import MyAppBarBeforeLogin from "../common/header/myAppBarBeforeLoginNew";
import { useNavigate } from "react-router";
import LandingPageNewIndex from "../landingPageNew/pages/landingPageNewIndex";
import LandingIndex from "../landing/landingIndex";
import { ThemeProvider } from "@mui/material";
import LandingTheme from "../landing/theme/theme";

// import Index from "../components/landing";
// import DigitalMarketingHeader from "../newLandingPage/global/headers/newLandingPageHeader";
// import NewLandingPageIndex from "../newLandingPage/pages/newLandingPageIndex";

const Home = (props) => {
  let navigate = useNavigate();

  useEffect(() => {
    const savedToken = localStorage.getItem("SAVEDTOKEN");

    console.log({ savedToken });

    if (savedToken) {
      navigate("/patients");
    }
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={LandingTheme}>
        <LandingIndex />
      </ThemeProvider>

      {/* <LandingPageNewIndex /> */}
      {/* <NewLandingPageIndex /> */}
      {/* <MyAppBarBeforeLogin /> */}
      {/* <Index /> */}
    </React.Fragment>
  );
};

export default Home;
