import {
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import { PhotoViewer } from "../../../../../assets/photoViewer";
import AssignmentIcon from "@mui/icons-material/Assignment";

const DisplayCard = ({ item, type = null }) => {
  const [imageURL, setImageURL] = useState("");
  const [open, setOpen] = useState(false);

  const handleOpen = (url) => {
    setOpen(true);
    setImageURL(url);
  };
  const handleClose = (url) => {
    setOpen(false);
    setImageURL("");
  };

  return (
    <Fragment>
      <Card sx={{ px: 1, py: 1, width: "250px" }}>
        <CardContent>
          <Stack
            spacing={1}
            direction="row"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>{item?.name}</Typography>
            <IconButton
              onClick={() => {
                if (type === "FIRE_SAFETY") {
                  handleOpen(item?.fireSafetyUrl);
                } else if (type === "FIRST_AID") {
                  handleOpen(item?.firstAidUrl);
                } else if (type === null) {
                  handleOpen(item?.firstAidUrl);
                }
              }}
            >
              <AssignmentIcon color="primary" />
            </IconButton>
          </Stack>
        </CardContent>
      </Card>

      <PhotoViewer url={imageURL} open={open} handleClose={handleClose} />
    </Fragment>
  );
};

export default DisplayCard;
