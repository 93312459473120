import { Box, Button, Grid, IconButton, Tab, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import TestWise from "./testWise";
import DownlaodReports from "./downlaodReports";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomTitles from "../../../global/titles/customTitles";
import SelectCamp from "../../../global/selectCamp/selectCamp";
import Analytics from "./analytics";
import YearOnYearComparsion from "./yearOnYearComparsion";
import { TabContext, TabList, TabPanel } from "@mui/lab";

const AHCStatusTrackerMain = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isDownLoadReports, setIsDownLoadReports] = useState(false);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="AHC Status Tracker" />
        </Grid>

        {corpId === "1ab48481-e6f5-4dd6-8b50-983c94d763a1" ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box sx={{ width: "100%" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Analytics" value="1" />
                    <Tab label="Year On Year Comparison" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1" sx={{ p: 0, paddingTop: 1 }}>
                  <Analytics />
                </TabPanel>

                <TabPanel value="2" sx={{ p: 0 }}>
                  <YearOnYearComparsion />
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
        ) : (
          <Fragment>
            <Grid item lg={2} md={2} sm={12} xs={12}>
              {isDownLoadReports && (
                <IconButton
                  onClick={() => {
                    setIsDownLoadReports(!isDownLoadReports);
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}
            </Grid>

            <Grid item lg={8} md={8} sm={12} xs={12}></Grid>

            <Grid item lg={2} md={2} sm={12} xs={12}>
              {!isDownLoadReports && (
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setIsDownLoadReports(!isDownLoadReports);
                  }}
                  sx={{
                    height: 48,
                    px: 3,
                    borderTopLeftRadius: 20,
                    borderBottomLeftRadius: 20,
                    borderTopRightRadius: 20,
                    borderBottomRightRadius: 20,
                  }}
                >
                  Download Reports
                </Button>
              )}
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              {isDownLoadReports ? <DownlaodReports /> : <TestWise />}
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Box>
  );
};

export default AHCStatusTrackerMain;
