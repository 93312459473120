import { Box, Grid } from "@mui/material";
import ImageGallerySliderComp from "./imageGallerySliderComp";

const GalleryComp = ({ slideList = [] }) => {
  console.log({ slideList });
  return (
    <Box
      sx={{ height: "70vh", p: 1, mb: 1 }}
      display="flex"
      justifyContent="center"
      alignItems="center">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ImageGallerySliderComp slideList={slideList} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GalleryComp;
