import { CssBaseline } from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import MyAppBarAfterLogin from "../common/header/myAppBarAfterLogin";
import SlotMainComponent from "../slots/slotMainComponent";
import { getSlotListByDocId } from "../services/api/apiCalls";

const Slots = (props) => {
  return (
    <Fragment>
      <CssBaseline />
      <MyAppBarAfterLogin />
      <SlotMainComponent />
    </Fragment>
  );
};

export default Slots;
