import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import CustomAutocomplete from "../../../../../../../../assets/customAutocomplete/customAutocomplete";
import { Add, Edit } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { fetchMedicineListForMyOpd } from "../../../../../../../services/inventory";
import Close from "@mui/icons-material/Close";

const AddMedicine = ({
  corpId = localStorage.getItem("CORPID"),
  medicines = [],
  setMedicines,
  fetch,
}) => {
  const [currentMedicine, setCurrentMedicine] = useState({
    medicineOptionNameWithBrand: "",
    medicineName: "",
    brandName: "",
    batchLabelWithExpiryDate: "",
    batchNo: "",
    quantity: "",
    availableQuantity: "",
    baseUnit: "",
    batchExpiryDate: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);

  const handleAddOrEditMedicine = () => {
    if (isEditing) {
      const updatedMedicines = [...medicines];
      updatedMedicines[editIndex] = currentMedicine;
      setMedicines(updatedMedicines);
      setIsEditing(false);
      setEditIndex(null);
      setCurrentMedicine({
        medicineOptionNameWithBrand: "",
        medicineName: "",
        productCode: "",
        brandName: "",
        batchLabelWithExpiryDate: "",
        batchNo: "",
        quantity: "",
        availableQuantity: "",
        baseUnit: "",
        batchExpiryDate: "",
      });
    } else {
      const existingMedicineIndex = medicines.findIndex(
        (medicine) =>
          medicine.productCode === currentMedicine.productCode &&
          medicine.batchNo === currentMedicine.batchNo
      );
      console.log({ existingMedicineIndex });
      if (existingMedicineIndex !== -1) {
        // Update the existing medicine
        const updatedMedicines = [...medicines];
        const existingMedicine = updatedMedicines[existingMedicineIndex];

        console.log({ existingMedicine });

        existingMedicine.quantity =
          parseFloat(existingMedicine.quantity) +
          parseFloat(currentMedicine.quantity);
        existingMedicine.availableQuantity = parseFloat(
          currentMedicine.availableQuantity
        );

        console.log({ existingMedicine });

        setMedicines(updatedMedicines);
      } else {
        // Add new medicine
        setMedicines([...medicines, currentMedicine]);
      }

      setCurrentMedicine({
        medicineOptionNameWithBrand: "",
        medicineName: "",
        productCode: "",
        brandName: "",
        batchLabelWithExpiryDate: "",
        batchNo: "",
        quantity: "",
        availableQuantity: "",
        baseUnit: "",
        batchExpiryDate: "",
      });
    }
  };

  const handleEditMedicine = (index) => {
    setCurrentMedicine(medicines[index]);
    setIsEditing(true);
    setEditIndex(index);
  };

  const handleDeleteMedicine = () => {
    const updatedMedicines = medicines.filter(
      (_, index) => index !== deleteIndex
    );
    setMedicines(updatedMedicines);
    setDeleteIndex(null);
  };

  const [medicineList, setMedicineList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchMedicineListForMyOpd(corpId, setMedicineList, setIsLoading);
  }, [fetch]);

  const handleCancelEdit = () => {
    setCurrentMedicine({
      medicineOptionNameWithBrand: "",
      medicineName: "",
      productCode: "",
      brandName: "",
      batchLabelWithExpiryDate: "",
      batchNo: "",
      quantity: "",
      availableQuantity: "",
      baseUnit: "",
      batchExpiryDate: "",
    });
    setIsEditing(false);
    setEditIndex(null);
  };

  const flattenedArray = Object.entries(medicineList).flatMap(
    ([medicineName, batches]) => {
      return batches.map((batch) => ({
        medicineOptionNameWithBrand:
          medicineName + " Brand Name - " + batch.brandName,
        medicineName,
        batchLabelWithExpiryDate:
          batch.batchLabel + " Expiry Date - " + batch.expiryDate,
        ...batch,
      }));
    }
  );

  useEffect(() => {
    const filteredBatches = flattenedArray.filter(
      (item) => item.medicineName === currentMedicine.medicineName
    );
    if (filteredBatches.length === 1) {
      setCurrentMedicine((prev) => ({
        ...prev,
        batchLabelWithExpiryDate:
          filteredBatches[0].batchLabel +
          " Expiry Date - " +
          filteredBatches[0].expiryDate,
        batchNo: filteredBatches[0].batchCode,
        availableQuantity: filteredBatches[0].availableQuantity,
        baseUnit: filteredBatches[0].baseUnit,
        batchExpiryDate: filteredBatches[0].expiryDate || null,
        productCode: filteredBatches[0].productCode,
      }));
    }
  }, [currentMedicine.medicineName, currentMedicine.brandName]);

  console.log({ currentMedicine, medicines, flattenedArray });

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={2} lg={2.5}>
          <CustomAutocomplete
            label={"Medicine name"}
            placeholder={"eg: Dolo 650"}
            options={[
              ...new Set(
                flattenedArray.map(
                  (option) => option?.medicineOptionNameWithBrand
                )
              ),
            ]}
            value={currentMedicine?.medicineOptionNameWithBrand}
            onChange={(event, newValue, reason) => {
              const filteredBatches = flattenedArray?.filter(
                (item) => item.medicineName === currentMedicine?.medicineName
              )?.length;
              console.log({ newValue });
              setCurrentMedicine({
                ...currentMedicine,
                medicineOptionNameWithBrand: newValue,
                brandName:
                  flattenedArray?.find(
                    (item) => item?.medicineOptionNameWithBrand === newValue
                  )?.brandName || "",
                medicineName:
                  flattenedArray?.find(
                    (item) => item?.medicineOptionNameWithBrand === newValue
                  )?.medicineName || "",
                batchLabelWithExpiryDate: filteredBatches && "",
                batchNo: filteredBatches && "",
                batchExpiryDate: filteredBatches && "",
                availableQuantity: filteredBatches && "",
                productCode: flattenedArray?.find(
                  (item) => item?.medicineOptionNameWithBrand === newValue
                )?.productCode,
                quantity: "",
              });
              if (reason === "clear") {
                setCurrentMedicine({
                  ...currentMedicine,
                  medicineOptionNameWithBrand: "",
                  medicineName: "",
                  productCode: "",
                  brandName: "",
                  batchLabelWithExpiryDate: "",
                  batchNo: "",
                  quantity: "",
                  availableQuantity: "",
                  baseUnit: "",
                  batchExpiryDate: "",
                });
              }
            }}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : ""
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2.5}>
          <TextField
            size="small"
            fullWidth
            disabled
            label={"Brand"}
            placeholder={"eg: Dolo 650"}
            value={currentMedicine.brandName || ""}
            onChange={(event, newValue) => {
              setCurrentMedicine({
                ...currentMedicine,
                brandName: newValue || "",
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <CustomAutocomplete
            // disabled={
            //   flattenedArray.filter(
            //     (item) => item.medicineName === currentMedicine.medicineName
            //   ).length === 1
            //     ? true
            //     : false
            // }
            label={"Batch Label"}
            placeholder={"Select Batch Label"}
            options={flattenedArray
              .filter(
                (item) => item.medicineName === currentMedicine.medicineName
              )
              .map((obj) => obj.batchLabelWithExpiryDate)}
            value={currentMedicine.batchLabelWithExpiryDate}
            onChange={(event, newValue, reason) => {
              setCurrentMedicine({
                ...currentMedicine,
                batchLabelWithExpiryDate: newValue || "",
                batchNo:
                  flattenedArray?.find(
                    (item) => item?.batchLabelWithExpiryDate === newValue
                  )?.batchCode || "",
                availableQuantity: flattenedArray?.find(
                  (item) => item?.batchLabelWithExpiryDate === newValue
                )?.availableQuantity,
                batchExpiryDate:
                  flattenedArray?.find(
                    (item) => item?.batchLabelWithExpiryDate === newValue
                  )?.expiryDate || null,
                productCode: flattenedArray?.find(
                  (item) => item?.batchLabelWithExpiryDate === newValue
                )?.productCode,
                baseUnit:
                  flattenedArray?.find(
                    (item) => item?.batchLabelWithExpiryDate === newValue
                  )?.baseUnit || "",
                quantity: "",
              });
              if (reason === "clear") {
                setCurrentMedicine({
                  ...currentMedicine,
                  // medicineOptionNameWithBrand: "",
                  // medicineName: "",
                  // productCode: "",
                  // brandName: "",
                  batchLabelWithExpiryDate: "",
                  batchNo: "",
                  quantity: "",
                  availableQuantity: "",
                  baseUnit: "",
                  batchExpiryDate: "",
                });
              }
            }}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : ""
            }
          />
          <Typography
            sx={{ fontSize: "13px", textAlign: "center", color: "red" }}
          >
            {currentMedicine.batchExpiryDate
              ? `Expiry Date: ${currentMedicine.batchExpiryDate}`
              : ""}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <TextField
            type="number"
            label={"Quantity"}
            placeholder={"Enter Quantity"}
            size="small"
            fullWidth
            value={currentMedicine.quantity}
            onChange={(e) => {
              //   const inputValue = e.target.value;
              //   const quantityZero =
              //     currentMedicine?.availableQuantity -
              //     (medicines?.find(
              //       (item) => item?.batchNo === currentMedicine?.batchNo
              //     )?.quantity || 0);

              //   if (
              //     (!isNaN(inputValue) &&
              //       inputValue > 0 &&
              //       inputValue <= parseInt(currentMedicine.availableQuantity) &&
              //       inputValue <= quantityZero) ||
              //     e.target.value === ""
              //   ) {
              //     setCurrentMedicine({
              //       ...currentMedicine,
              //       quantity: e.target.value || "",
              //     });
              //   }
              // }}
              const inputValue = e.target.value;
              const quantityZero =
                currentMedicine?.availableQuantity -
                (medicines?.find(
                  (item) => item?.batchNo === currentMedicine?.batchNo
                )?.quantity || 0);

              const isInputValid = /^\d*$/.test(inputValue);

              if (isEditing) {
                if (
                  (isInputValid &&
                    inputValue <= parseInt(currentMedicine.availableQuantity) &&
                    inputValue > 0) ||
                  inputValue === ""
                ) {
                  setCurrentMedicine({
                    ...currentMedicine,
                    quantity: inputValue || "",
                  });
                }
              } else {
                if (
                  (isInputValid &&
                    inputValue <= quantityZero &&
                    inputValue <= parseInt(currentMedicine.availableQuantity) &&
                    inputValue > 0) ||
                  inputValue === ""
                ) {
                  setCurrentMedicine({
                    ...currentMedicine,
                    quantity: inputValue || "",
                  });
                }
              }
            }}
          />
          <Typography
            sx={{ fontSize: "13px", textAlign: "center", color: "red" }}
          >
            {currentMedicine.batchNo &&
            currentMedicine.availableQuantity.toString()
              ? `Current Quantity: ${
                  isEditing
                    ? parseInt(currentMedicine?.availableQuantity)
                    : parseInt(currentMedicine?.availableQuantity) -
                      (parseInt(
                        medicines?.find(
                          (item) => item?.batchNo === currentMedicine?.batchNo
                        )?.quantity
                      ) || 0)
                }`
              : ""}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <TextField
            disabled={true}
            label={"Base Unit"}
            placeholder={"Tablet"}
            size="small"
            fullWidth
            value={currentMedicine.baseUnit}
            onChange={(e) => {
              setCurrentMedicine({
                ...currentMedicine,
                baseUnit: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          lg={1}
          sx={{ display: "flex", gap: 1 }}
        >
          {isEditing && (
            <Close
              onClick={() => handleCancelEdit()}
              sx={{
                mt: 1,
                borderRadius: 5,
                p: 0,
                color: "#FFF",
                backgroundColor: "#127DDD",
                ":hover": {
                  cursor: "pointer",
                  backgroundColor: "#127DDD",
                },
              }}
            />
          )}

          <Button
            disabled={
              currentMedicine.medicineName &&
              currentMedicine.quantity &&
              currentMedicine.batchNo
                ? // currentMedicine.baseUnit
                  false
                : true
            }
            variant="contained"
            size="small"
            sx={{
              textTransform: "capitalize",
              paddingInline: 0,
              height: "40px",
            }}
            startIcon={<Add sx={{ color: "#FFF" }} />}
            onClick={handleAddOrEditMedicine}
          >
            {isEditing ? "Edit" : "Add"}
          </Button>
        </Grid>
      </Grid>

      <Typography sx={{ marginTop: 1, fontWeight: "bold", marginBottom: 3 }}>
        Added Medicine
      </Typography>

      <Grid
        container
        sx={{
          backgroundColor: "#F5F5FF",
          alignItems: "center",
          paddingInline: 5,
          paddingBlock: 1,
        }}
      >
        <Grid item xs={12} sm={12} md={2} lg={3}>
          <Typography
            sx={{
              color: "#000",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            Medicine Name
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <Typography
            sx={{
              color: "#000",

              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            Brand
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <Typography
            sx={{
              color: "#000",

              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            Batch No
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={1.5}>
          <Typography
            sx={{
              color: "#000",

              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            Quantity
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <Typography
            sx={{
              color: "#000",

              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            Base Unit
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={1}></Grid>
      </Grid>

      {medicines.map((medicine, index) => (
        <Grid
          container
          key={index}
          sx={{
            backgroundColor: "#FFF",
            alignItems: "center",
            paddingInline: 5,
            marginTop: 2,
            paddingBlock: 1,
          }}
        >
          <Grid item xs={12} sm={12} md={2} lg={3}>
            <Typography
              sx={{
                color: "#000",

                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              {medicine.medicineName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Typography
              sx={{
                color: "#000",

                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              {medicine.brandName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Typography
              sx={{
                color: "#000",

                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              {medicine.batchNo}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={1.5}>
            <Typography
              sx={{
                color: "#000",

                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              {medicine.quantity}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Typography
              sx={{
                color: "#000",

                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              {medicine.baseUnit}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={1}>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box
                sx={{
                  border: "1px solid #127DDD",
                  height: 25,
                  width: 25,
                  borderRadius: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => handleEditMedicine(index)}
              >
                <Edit sx={{ color: "#127DDD", height: 18, width: 18 }} />
              </Box>
              <Box
                sx={{
                  border: "1px solid red",
                  height: 25,
                  width: 25,
                  borderRadius: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setDeleteIndex(index)}
              >
                <CloseIcon sx={{ color: "red", height: 18, width: 18 }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      ))}

      <Dialog open={deleteIndex !== null} onClose={() => setDeleteIndex(null)}>
        <DialogTitle>Delete Medicine</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this medicine?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteIndex(null)}>Cancel</Button>
          <Button onClick={handleDeleteMedicine} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default AddMedicine;
