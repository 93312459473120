import { Box, Button, IconButton, Stack } from "@mui/material";
import ChartComponent from "./chartComponent";
import bloodpressuregauge1 from "../../../assets/images/bloodpressuregauge1.png";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";

const DisplayCharts = ({ setSelectedTab, setDefaultTab, selectedVital }) => {
  return (
    <Box
      sx={{
        border: "1px solid black",
        borderRadius: "25px",
        px: 2,
        py: 2,
        height: "83vh",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Stack direction="row" justifyContent="flex-end">
        <IconButton
          size="large"
          onClick={() => {
            setSelectedTab("default");
            setDefaultTab("1");
          }}
        >
          <SettingsBackupRestoreIcon fontSize="large" color="primary" />
        </IconButton>
      </Stack>
      <ChartComponent
        label={selectedVital.label}
        image={selectedVital.imagesrc}
        vitalList={selectedVital.vitalList}
        itemKey={selectedVital.key}
      />
    </Box>
  );
};
export default DisplayCharts;
