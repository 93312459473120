// import {
//   Box,
//   Button,
//   IconButton,
//   Slide,
//   Typography,
//   useMediaQuery,
// } from "@mui/material";
// import React, { Fragment, useEffect, useState } from "react";
// import bpclColor from "../assets/bpclColor.png";
// import eicherColor from "../assets/eicherColor.png";
// import forceColor from "../assets/forceColor.png";
// import landtColor from "../assets/landtColor.png";
// import mahindraColor from "../assets/mahindraColor.png";
// import panasonicColor from "../assets/panasonicColor.png";
// import ultratechColor from "../assets/ultratechColor.png";
// import caseColor from "../assets/caseColor.png";
// import maanColor from "../assets/maanColor.png";
// import arneelColor from "../assets/arneelColor.png";
// import shaktiColor from "../assets/shaktiColor.png";
// import nationalColor from "../assets/nationalColor.png";
// import liugongColor from "../assets/liugongColor.png";
// import ourClientTitle from "../assets/ourClientTitle.png";
// import { useTheme } from "@emotion/react";
// import { ArrowBack, ArrowForward } from "@mui/icons-material";

// const Clients = () => {
//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
//   const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
//   const clients = [
//     { imgHovered: bpclColor },
//     { imgHovered: eicherColor },
//     { imgHovered: forceColor },
//     { imgHovered: landtColor },
//     { imgHovered: mahindraColor },
//     { imgHovered: panasonicColor },
//     { imgHovered: ultratechColor },
//     { imgHovered: caseColor },
//     { imgHovered: maanColor },
//     { imgHovered: arneelColor },
//     { imgHovered: shaktiColor },
//     { imgHovered: nationalColor },
//     { imgHovered: liugongColor },
//   ];
//   const [hoveredIndex, setHoveredIndex] = useState(null);

//   const [currentIndex, setCurrentIndex] = useState(0);

//   // const handlePrev = () => {
//   //   setCurrentIndex((prevIndex) =>
//   //     prevIndex === 0 ? clients.length - 1 : prevIndex - 1
//   //   );
//   // };

//   // const handleNext = () => {
//   //   setCurrentIndex((prevIndex) =>
//   //     prevIndex === clients.length - 1 ? 0 : prevIndex + 1
//   //   );
//   // };

//   // // Function to get the list of images to be displayed
//   // const getVisibleClients = () => {
//   //   if (isSmallScreen) {
//   //     return [clients[currentIndex]];
//   //   }
//   //   const visibleClients = [];
//   //   for (let i = 0; i < 6; i++) {
//   //     visibleClients.push(clients[(currentIndex + i) % clients.length]);
//   //   }
//   //   return visibleClients;
//   // };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentIndex((prevIndex) =>
//         prevIndex === clients.length - 1 ? 0 : prevIndex + 1
//       );
//     }, 3000); // Change client every 3 seconds

//     return () => clearInterval(interval); // Clear interval on component unmount
//   }, [clients.length]);

//   const getVisibleClients = () => {
//     if (isSmallScreen) {
//       return [clients[currentIndex]];
//     }
//     const visibleClients = [];
//     for (let i = 0; i < 6; i++) {
//       visibleClients.push(clients[(currentIndex + i) % clients.length]);
//     }
//     return visibleClients;
//   };

//   return (
//     <Fragment>
//       <Box
//         sx={{
//           background:
//             "linear-gradient(to bottom, rgba(52, 63, 208, 1) 30%, rgba(98, 109, 255, 1) 100%)",
//         }}
//       >
//         <Box
//           sx={{
//             height: isSmallScreen || isExtraSmallScreen ? null : "20vh",
//             paddingBlock: isSmallScreen || isExtraSmallScreen ? "20px" : "35px",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <img src={ourClientTitle} alt="title" style={{ height: 30 }} />
//         </Box>
//         <Typography
//           sx={{
//             textAlign: "center",
//             fontSize: isSmallScreen || isExtraSmallScreen ? "24px" : "32px",
//             fontWeight: "500",
//             lineHeight: "37.5px",
//             color: "#FFF",
//           }}
//         >
//           Join the journey of employee health care like these industry giants
//         </Typography>
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             position: "relative",
//             height: 250,
//             marginTop: -3,
//           }}
//         >
//           {/* <IconButton
//             onClick={handlePrev}
//             sx={{
//               position: "absolute",
//               left: isExtraSmallScreen || isSmallScreen ? 0 : 4,
//               zIndex: 2,
//               backgroundColor: "#0045B2",
//               "&:hover": {
//                 backgroundColor: "#0045B2",
//               },
//             }}
//           >
//             <ArrowBack sx={{ color: "#FFF" }} />
//           </IconButton> */}

//           <Box
//             sx={{
//               overflow: "hidden",
//               width: "100%",
//               display: "flex",
//               justifyContent: "center",
//             }}
//           >
//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 gap: 5,
//                 transition: "transform 0.5s ease-in-out",
//                 height: 100,
//               }}
//             >
//               {getVisibleClients().map((client, index) => (
//                 <Box
//                   onMouseEnter={() => setHoveredIndex(index)}
//                   onMouseLeave={() => setHoveredIndex(null)}
//                   key={index}
//                   component="img"
//                   src={client.imgHovered}
//                   sx={{
//                     height: 81,
//                     zIndex: 999,
//                     transition: "transform 0.3s ease", // Smooth transition
//                     "&:hover": {
//                       height: 81,
//                       transform:
//                         hoveredIndex === index ? "translateY(-10px)" : null,
//                     },
//                   }}
//                 />
//               ))}
//             </Box>
//           </Box>

//           {/* <IconButton
//             onClick={handleNext}
//             sx={{
//               position: "absolute",
//               right: 0,
//               zIndex: 2,
//               backgroundColor: "#0045B2",
//               "&:hover": {
//                 backgroundColor: "#0045B2",
//               },
//             }}
//           >
//             <ArrowForward sx={{ color: "#FFF" }} />
//           </IconButton> */}
//         </Box>
//       </Box>
//     </Fragment>
//   );
// };

// export default Clients;

import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { Fragment } from "react";
import ourClientTitle from "../assets/ourClientTitle.png";
import { useTheme } from "@emotion/react";
import Slider1 from "./subComps/slider1";
import Slider2 from "./subComps/slider2";

const Clients = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Fragment>
      <Box
        sx={{
          background:
            "linear-gradient(to bottom, rgba(52, 63, 208, 1) 30%, rgba(98, 109, 255, 1) 100%)",
        }}>
        <Box
          sx={{
            height: isSmallScreen || isExtraSmallScreen ? null : "20vh",
            paddingBlock: isSmallScreen || isExtraSmallScreen ? "20px" : "35px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <img src={ourClientTitle} alt="title" style={{ height: 30 }} />
        </Box>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: isSmallScreen || isExtraSmallScreen ? "24px" : "32px",
            fontWeight: "500",
            lineHeight: "37.5px",
            color: "#FFF",
          }}>
          Join the health care revolution like these industry giants
        </Typography>

        {/* <Slider1 /> */}
        <Slider2 />
      </Box>
    </Fragment>
  );
};

export default Clients;
