import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Portal,
  Typography,
  Button,
  TextField,
  Stack,
  Autocomplete,
  Container,
} from "@mui/material";
import { useFileUpload } from "use-file-upload";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Fragment, useState } from "react";
import { useSnackbar } from "notistack";
import EmployeeSearch from "./subComp/employeeSearch";
import { BASE_URL } from "../../../../../../assets/constantsFile";
import { saveDataWithMultipartForm } from "../../../../../../services/api/postApi";
import CustomAutocomplete from "../../../../../../assets/customAutocomplete/customAutocomplete";

const CustomTextField = ({
  corpId = localStorage.getItem("CORPID"),
  item,
  formValues,
  setFormValues,
  required,
  asterickColor,
}) => {
  const [files, selectFiles] = useFileUpload();

  const handleUpload = () => {
    selectFiles({ multiple: true }, (files) => {
      const propertyName = item.property;
      const currentFiles = formValues[propertyName] || [];

      const updatedSelectedFiles = [...currentFiles];

      files.forEach(({ source, name, size, file }) => {
        updatedSelectedFiles.push({ source, name, size, file });
      });
      setFormValues({ ...formValues, [propertyName]: updatedSelectedFiles });
    });
  };

  if (item.type === "number") {
    return (
      <Box>
        <Stack>
          <TextField
            variant="outlined"
            size="small"
            label={item.title}
            sx={{
              "& .MuiFormLabel-asterisk": {
                color: asterickColor,
              },
            }}
            required={required}
            placeholder={`Enter ${item.title}`}
            value={formValues[item.property] || ""}
            onChange={(e) => {
              if (
                !isNaN(e.target.value) &&
                e.target.value.length < item.length
              ) {
                let newFormValues = { ...formValues };
                newFormValues[item.property] = e.target.value;
                setFormValues(newFormValues);
              }
            }}
          />
        </Stack>
      </Box>
    );
  }

  if (item.type === "gender") {
    return (
      <Box>
        <Stack>
          <CustomAutocomplete
            label={item.title}
            placeholder="Gender"
            required={required}
            asterickColor={asterickColor}
            value={formValues[item.property] || null}
            onChange={(event, newValue) => {
              let newFormValues = { ...formValues };
              newFormValues[item.property] = newValue;
              setFormValues(newFormValues);
            }}
            options={["MALE", "FEMALE", "DONOTDISCLOSE", "OTHER"]}
            getOptionLabel={(option) => option}
          />
        </Stack>
      </Box>
    );
  }
  if (item.type === "autoComplete") {
    return (
      <Box>
        <Stack>
          <CustomAutocomplete
            value={formValues[item.property] || null}
            onChange={(event, newValue) => {
              let newFormValues = { ...formValues };
              newFormValues[item.property] = newValue;
              setFormValues(newFormValues);
            }}
            onInputChange={(event, newInputValue) => {
              let newFormValues = { ...formValues };
              newFormValues[item.property] = newInputValue;
              setFormValues(newFormValues);
            }}
            options={item.optionList}
            freeSolo={true}
            required={required}
            asterickColor={asterickColor}
            placeholder={item.placeholder}
            label={item.title}
            getOptionLabel={(option) => option}
          />
        </Stack>
      </Box>
    );
  }

  if (item.type === "upload") {
    return (
      <Box>
        <Stack>
          <Button
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#FFFFFF",
              marginTop: "5px",
              textTransform: "capitalize",
            }}
            variant="contained"
            startIcon={<AttachFileIcon style={{ color: "#FFFFFF" }} />}
            onClick={handleUpload}
          >
            {item.title}
          </Button>
        </Stack>
      </Box>
    );
  }
  if (item.type === "empAutocomplete") {
    return (
      <Box>
        <Stack>
          <EmployeeSearch
            item={item}
            formValues={formValues}
            setFormValues={setFormValues}
            required={required}
            asterickColor={asterickColor}
          />
        </Stack>
      </Box>
    );
  }

  return (
    <Box>
      <Stack>
        <TextField
          label={item.title}
          sx={{
            "& .MuiFormLabel-asterisk": {
              color: asterickColor,
            },
          }}
          variant="outlined"
          size="small"
          required={required}
          placeholder={`Enter ${item.title}`}
          value={formValues[item.property] || ""}
          onChange={(e) => {
            let newFormValues = { ...formValues };
            newFormValues[item.property] = e.target.value;
            setFormValues(newFormValues);
          }}
        />
      </Stack>
    </Box>
  );
};

const injuryList = [
  "ELECTROCUTION",
  "FIRE_INJURY",
  "BURN_INJURY",
  "MACHINE_INJURY",
  "AMPUTATION",
  "ACID_INJURY",
  "HEART_ATTACK",
];
const fields = (val) => [
  {
    id: 1,
    title: "Employee ID",
    property: "empId",
    type: "empAutocomplete",
  },
  {
    id: 2,
    title: "Name",
    property: "name",
  },
  {
    id: 3,
    title: "Age",
    property: "age",
    type: "number",
    length: 3,
  },
  {
    id: 4,
    title: "Gender",
    property: "gender",
    type: "gender",
  },
  {
    id: 5,
    title: "Mobile Number",
    property: "mobile",
    type: "number",
    length: 11,
  },
  {
    id: 10,
    title: "Details of Injury/Accident",
    property: "injuryRemarks",
  },
  {
    id: 11,
    title: "Injury/Accident Type",
    property: "injuryType",
    type: "autoComplete",
    optionList: injuryList,
    placeholder: "Select/Write Injury Type",
  },
  {
    id: 12,
    title: "Injury/Accident Location",
    property: "injuryLocation",
    type: "autoComplete",
    optionList: ["INDUSTRIAL", "NON_INDUSTRIAL"],
    placeholder: "Select Injury/Accident Location",
  },
  {
    id: 13,
    title: "Treatment Done At",
    property: "treatmentDoneAt",
    type: "autoComplete",
    optionList: ["REFERRED_OUTSIDE", "TREATED_IN_OHC"],
    placeholder: "Select Injury/Accident Location",
  },
  {
    id: 14,
    title: "Injury Severity",
    property: "injurySeverity",
    type: "autoComplete",
    optionList: ["MILD", "SEVERE", "CRITICAL"],
    placeholder: "Select Injury/Accident Severity",
  },
  {
    id: 15,
    title: "Upload Images/PDF",
    property: "files",
    type: "upload",
  },
];

const RecordInjury = ({ corpId = localStorage.getItem("CORPID") }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [formValues, setFormValues] = useState({
    corpId: corpId,
    empId: "",
    ohcType: "INJURY",
  });

  const submitHandler = (e) => {
    e.preventDefault();
    const url = BASE_URL + `org/daily/stats`;
    if (formValues.name && formValues.injuryLocation) {
      const data = saveDataWithMultipartForm(url, formValues);

      if (data.error) {
        enqueueSnackbar(`${data.error.response.data.message}`, {
          variant: "error",
        });
      } else {
        console.log("success");
        enqueueSnackbar(`Successfully Saved`, {
          variant: "success",
        });
        setFormValues({});
      }
    }
  };

  const handleRemove = (index) => {
    console.log("Removing file at index:", index);
    const updatedFiles = formValues?.files?.filter((_, i) => i !== index);
    setFormValues({ ...formValues, files: updatedFiles });
  };

  return (
    <Fragment>
      <Container maxWidth={false} sx={{ minHeight: "78vh", mt: 2 }}>
        <form onSubmit={submitHandler}>
          <Grid container rowSpacing={2} columnSpacing={4}>
            {fields(formValues).map((item, index) => (
              <Grid item lg={4} key={index}>
                <CustomTextField
                  item={item}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  required={
                    item.property === "name" ||
                    item.property === "injuryLocation" ||
                    item.property === "gender" ||
                    item.property === "injuryType" ||
                    item.property === "treatmentDoneAt" ||
                    item.property === "injurySeverity" ||
                    item.property === "age"
                      ? true
                      : false
                  }
                  asterickColor={"red"}
                />
              </Grid>
            ))}

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ display: "flex", flexWrap: "wrap" }}
            >
              {formValues?.files?.length
                ? formValues?.files.map((file, index) => (
                    <Paper
                      key={index}
                      elevation={3}
                      style={{
                        maxWidth: "150px",
                        marginInline: "10px",
                        marginBlock: "5px",
                      }}
                    >
                      <Box position="relative">
                        {file.name.toLowerCase().endsWith(".pdf") ? (
                          <Box
                            sx={{
                              height: "200px",
                              width: "150px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <PictureAsPdfIcon
                              style={{ fontSize: 100, color: "#127DDD" }}
                            />
                          </Box>
                        ) : (
                          <img
                            src={file.source}
                            alt={file.source}
                            style={{ maxWidth: "130px", height: "130px" }}
                          />
                        )}
                        <IconButton
                          size="small"
                          color="secondary"
                          onClick={() => handleRemove(index)}
                          style={{
                            position: "absolute",
                            top: 5,
                            right: 5,
                            color: "red",
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <Typography variant="body2" align="center">
                          {file.name}
                        </Typography>
                      </Box>
                    </Paper>
                  ))
                : null}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                mt: 2,
              }}
            >
              <Button
                disabled={
                  formValues.name &&
                  formValues.injuryLocation &&
                  formValues.gender &&
                  formValues.injuryType &&
                  formValues.treatmentDoneAt &&
                  formValues.injurySeverity &&
                  formValues.age
                    ? false
                    : true
                }
                variant="contained"
                size="large"
                type="submit"
                sx={{
                  width: 140,
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: 13,
                    lineHeightt: 17,
                    color: "#FFFFFF",
                  }}
                >
                  Submit
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Fragment>
  );
};

export default RecordInjury;
