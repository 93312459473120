import { Box, Container, Divider, Grid } from "@mui/material";
import React from "react";
import CounterCard from "./counterCard";
import CounterComp from "./counterComp";

const Counter = () => {
  return (
    <Box component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4}>
            <CounterComp
              count={70}
              suffix="+"
              title="No Of People Touched"
              // description="From buttons, to inputs, navbars, alerts or cards, you are covered"
            />
          </Grid>
          <Grid item xs={12} md={4} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <CounterComp
              count={15}
              suffix="+"
              title="No Of Lives Impacted"
              // description="Mix the sections, change the colors and unleash your creativity"
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <CounterComp
              count={4}
              suffix="+"
              title="No Of Tests Done"
              // description="Save 3-4 weeks of work when you use our pre-made pages for your website"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Counter;
