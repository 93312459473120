import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { BASE_URL } from "../../../../../../../../../assets/constantsFile";
import { getData } from "../../../../../../../../../services/api/getApi";
import { saveData } from "../../../../../../../../../services/api/postApi";

const CommentModal = ({
  open,
  handleClose,
  setRxComment,
  rxComment,
  medicineListVM,
  patients,
}) => {
  console.log({ CONTEXT: patients });
  const [comment, setComment] = useState("");

  const appointmentId = patients?.appointmentId || null;
  const caseId = patients?.caseId || null;
  const patientId = patients?.patientInfoVMV2?.patientId || null;

  console.log({ patients });

  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [openNotice, setOpenNotice] = useState(false);

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };

  const getPDetails = async () => {
    if (patientId && appointmentId && caseId) {
      const url =
        BASE_URL +
        "patient/detailed/" +
        patientId +
        "?appointmentId=" +
        appointmentId +
        "&caseId=" +
        caseId;

      const data = await getData(url);
      if (data && data.data) {
        console.log("SUCCESS GET", data.data);
        const data1 = data.data;
        const appointments = data1.appointments;
        const prescriptionVM = appointments
          ? appointments[0].prescriptionVM
          : null;
        const rxComment =
          prescriptionVM && prescriptionVM.rxComment
            ? prescriptionVM.rxComment
            : null;

        setRxComment(rxComment);
        setComment(rxComment || "");
      } else if (data && data.error) {
        console.log("SUCCESS GET", data.error);
      }
    }
  };
  console.log({ RXCOMMENT: rxComment });

  useEffect(() => {
    getPDetails();
  }, [patients, open]);

  const Obj = {
    docId: patients?.doctorVM?.docId,
    patientId: patients?.patientInfoVMV2?.patientId,
    appointmentId: patients?.appointmentId,
    medicines: medicineListVM,
    rxComment: comment,
  };

  console.log({ MYOBJECT: Obj });

  const saveRx = async () => {
    const url = BASE_URL + "doctor/prescription";

    const response = await saveData(url, Obj);
    if (response && response.data) {
      console.log("SUCCESS POST", response.data);
      setSeverity("success");
      setMessage("Saved Successfully");
      setOpenNotice(true);
      getPDetails();
      handleClose();
    } else if (response && response.error) {
      console.log("ERROR POST", response.error);
      setSeverity("error");
      setMessage("Error Occured");
      setOpenNotice(true);
    }
  };

  return (
    <Fragment>
      <Snackbar
        open={openNotice}
        autoHideDuration={1000}
        onClose={handleCloseNotice}
      >
        <Alert
          onClose={handleCloseNotice}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(187, 187, 187, 0.1)",
          },
          marginTop: "-100px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            boxShadow: "0px 1px 4px 1px rgba(0, 0, 0, 0.1)",
            borderRadius: "5px",
            padding: "20px",
            width: "350px",
            justifyContent: "center",
          }}
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#000000",
                  marginBottom: "10px",
                }}
              >
                Leave a Comment
              </Typography>
              <TextField
                multiline
                sx={{
                  background: "#fff",
                  color: "#127DDD",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                  "& input::placeholder": {
                    color: "#777777",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                  },
                }}
                size="small"
                fullWidth
                placeholder="Enter Comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                inputProps={{
                  style: {
                    height: 100,
                    color: "#777777",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} lg={6} textAlign="right">
              <Button
                onClick={handleClose}
                variant="outlined"
                style={{
                  borderRadius: "15px",
                  border: "1px solid",
                  textTransform: "none",
                  marginInline: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "15px",
                    color: "#127DDD",
                  }}
                >
                  Cancel
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={6} lg={6}>
              <Button
                onClick={() => {
                  saveRx();
                }}
                variant="contained"
                style={{
                  borderRadius: "15px",
                  textTransform: "none",
                  marginInline: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "15px",
                    color: "#FFFFFF",
                  }}
                >
                  Save
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default CommentModal;
