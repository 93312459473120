import React, { useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { useLocation, useNavigate } from "react-router";
import { prescriptionContext } from "../common/context/usercontext";
import ConsultationWithoutVideoIndex from "../components/consultation/consultationWithoutVideoIndex";

const ConsultationPage = () => {
  const location = useLocation();

  const patient = location?.state?.patient;
  const patientDeatils = location?.state?.patientDeatils;
  const tabValue = location?.state?.tabValue;

  return (
    <React.Fragment>
      <CssBaseline />
      {/* <MyAppBarAfterLogin /> */}

      <prescriptionContext.Provider
        value={{
          prescriptionData: [],
          prescriptionEditMode: true,
          prescriptionId: null,
          prescriptionVM: null,
        }}
      >
        <ConsultationWithoutVideoIndex
          patient={patient}
          patientDetails={patientDeatils}
          tabValue={tabValue}
        />
      </prescriptionContext.Provider>
    </React.Fragment>
  );
};

export default ConsultationPage;
