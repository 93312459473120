import { Box, Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import DashboardHeader from "../components/dashboardHeader";
import DashboardItem from "../components/dashboardItem";

const WalkInDashboard = ({ patientList }) => {
  const walkinList = patientList.filter(
    (patient, index) =>
      patient.appointmentStatus === "UPCOMING" &&
      patient.consultationType === "WALKIN"
  );
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <DashboardHeader />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        {walkinList && walkinList.length > 0 ? (
          <Box sx={{ height: "68vh", overflow: "auto", px: 1 }}>
            {walkinList.map((d, i) => (
              <DashboardItem key={i} data={d} />
            ))}
          </Box>
        ) : (
          <Grid container sx={{ height: "68vh" }}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography>No patient here.</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default WalkInDashboard;
