import { Button, Chip, Typography } from "@mui/material";
import { Fragment } from "react";

const ShowAllFilter = ({ showAllHandler }) => {
  return (
    <Fragment>
      {/* <Chip
        sx={{ borderRadius: 3, height: 36 }}
        label={
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: 11,
              color: "#404040",
              textTransform: "capitalize",
            }}>
            Show All
          </Typography>
        }
        variant="outlined"
        onClick={showAllHandler}
      /> */}

      <Button
        variant="contained"
        size="small"
        onClick={showAllHandler}
        sx={{ borderRadius: 3, height: 36, borderColor: "rgba(0, 0, 0, 0.3)" }}>
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: 11,
            color: "#fff",
            textTransform: "capitalize",
          }}>
          Show All
        </Typography>
      </Button>
    </Fragment>
  );
};

export default ShowAllFilter;
