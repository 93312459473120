import { Container, CssBaseline, Typography } from "@mui/material";
import { Fragment } from "react";
import BottomNavbar from "../../global/footers/bottomNavbar";
import CorpLandingPageMain from "./comps/corpLandingPageMain";

const CorpHome = ({ topMarginValue }) => {
  return (
    <Fragment>
      <CssBaseline />
      <Container
        disableGutters
        maxWidth={false}
        sx={{ height: "1vh", background: "#F5F5F5" }}
      >
        <CorpLandingPageMain topMarginValue={topMarginValue} />
        <BottomNavbar />
      </Container>
    </Fragment>
  );
};
export default CorpHome;
