import { Box } from "@mui/material";
import { Fragment, useState } from "react";
import EditDetail from "./edit";
import DisplayDetail from "./display";

const Detail = ({ patientDetails, label, keyValue }) => {
  const [isEdit, setIsEdit] = useState(false);
  console.log({ Infokey: patientDetails });
  return (
    <Fragment>
      <Box >
        {isEdit ? (
          <EditDetail
            patientDetails={patientDetails}
            setIsEdit={setIsEdit}
            label={label}
            keyValue={keyValue}
          />
        ) : (
          <DisplayDetail
            patientDetails={patientDetails}
            setIsEdit={setIsEdit}
            label={label}
            keyValue={keyValue}
          />
        )}
      </Box>
    </Fragment>
  );
};

export default Detail;
