import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import overflowImg from "../assets/overflowImg.png";

const OverflowImg = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <Box
      sx={{
        height: isSmallScreen || isExtraSmallScreen ? null : "60vh",
        overflowY: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
      }}>
      <img
        src={overflowImg}
        alt="Scrollable content"
        style={{ width: "100%", objectFit: "contain" }}
      />
    </Box>
  );
};

export default OverflowImg;
