import { Box, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import AboutUsDocIcon from "../../../../assets/images/AboutUsDocIcon.png";
import BackgroundImage from "../../../../assets/images/BackgroundImage.png";

const AboutUs = () => {
  return (
    <Fragment>
      <Box sx={{ paddingInline: "60px", paddingBlock: "50px" }}>
        <Grid container>
          <Grid item xs={6} lg={6}>
            <Box>
              <Box
                component="img"
                src={BackgroundImage}
                style={{ height: "100%", width: "100%", position: "relative", rotate: "-10deg" }}
              />
              <Box textAlign="center" sx={{ marginTop: "-450px" }}>
                <Box
                  component="img"
                  src={AboutUsDocIcon}
                  style={{
                    height: "100%",
                    width: "90%",
                    position: "relative",
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} lg={6}>
            <Box>
              <Typography
                sx={{
                  color: "#127DDD",
                  textAlign: "right",
                  fontSize: "24px",
                  fontWeight: "400",
                  textTransform: "uppercase",
                }}>
                About us
              </Typography>
              <Typography sx={{ textAlign: "right" }}>
                <span
                  style={{
                    color: "#000000",

                    fontSize: "48px",
                    fontWeight: "400",
                  }}>
                  Why{" "}
                </span>
                <span
                  style={{
                    color: "#000000",

                    fontSize: "48px",
                    fontWeight: "700", // Changed "700" to 700 (number instead of string)
                  }}>
                  UNO.care?
                </span>
              </Typography>
              <Typography
                sx={{
                  marginLeft: "70px",
                  textAlign: "justify",
                  color: "#000000",

                  fontSize: "16px",
                  fontWeight: "400",
                }}>
                At UNO.CARE we ensure best medical practices, best doctors, best pathologies at
                affordable prices.
                <br />
                <br />
                Our aim is to simplify a patient's journey to recovery. To achieve this, we have
                set-up labs with the best lab equipments, stores with all kinds of medicines,
                clinics with best-in industry doctors along with trained staff to assist patients.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default AboutUs;
