import React, { Fragment } from "react";
import LandingPageMain from "../landingPageMain";
import LandingHeader from "../global/header/landingHeader";
import LandingFooter from "../global/footer/landingFooter";
import { useRef } from "react";
import LandingHeaderNew from "../global/header/landingHeaderNew";

const LandingPageNewIndex = (props) => {
  const contactRef = useRef(null);
  const aboutRef = useRef(null);
  const specificationRef = useRef(null);

  // Function to scroll to the Contact section
  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToAbout = () => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToSpecification = () => {
    if (specificationRef.current) {
      specificationRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const { window } = props;

  return (
    <Fragment>
      {/* <LandingHeader
        scrollToContact={scrollToContact}
        window={window}
        scrollToSpecification={scrollToSpecification}
        scrollToAbout={scrollToAbout}
      /> */}
      <LandingHeaderNew
        scrollToContact={scrollToContact}
        window={window}
        scrollToSpecification={scrollToSpecification}
        scrollToAbout={scrollToAbout}
      />
      <LandingPageMain
        contactRef={contactRef}
        aboutRef={aboutRef}
        specificationRef={specificationRef}
        scrollToContact={scrollToContact}
      />
      <LandingFooter />
    </Fragment>
  );
};

export default LandingPageNewIndex;
