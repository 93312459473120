import { Avatar, Box, Button, Card, CardActions, CardContent, Grid } from "@mui/material";
import { useState } from "react";
import shakti from "../../assets/images/shakti.png";
import caseCons from "../../assets/images/caseCons.png";
import arneel from "../../assets/images/arneel.png";
import liugong from "../../assets/images/liugong.png";
import maan from "../../assets/images/maan.png";
import nationalSteel from "../../assets/images/nationalSteel.png";
import { Fragment } from "react";

const CustomCard = ({ data, animationDuration }) => {
  const [showHoverEffect, setShowHoverEffect] = useState(true);

  return (
    <Box
      onPointerEnter={() => setShowHoverEffect(false)}
      variant="outlined"
      onPointerLeave={() => setShowHoverEffect(true)}
      sx={{
        padding: "10px",
        borderRadius: "23px",
        transition: "box-shadow 0.3s ease",
        "&:hover": {
          boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.1)",
          transform: "translateY(-5px)",
        },
      }}>
      <Box component={"img"} src={data?.src} style={{ height: "90%" }} />
    </Box>
  );
};
const CustomCard2 = ({ img }) => {
  const [showHoverEffect, setShowHoverEffect] = useState(true);

  return (
    <Box
      onPointerEnter={() => setShowHoverEffect(false)}
      variant="outlined"
      onPointerLeave={() => setShowHoverEffect(true)}
      sx={{
        padding: "10px",
        borderRadius: "23px",
        transition: "box-shadow 0.3s ease",
        "&:hover": {
          boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.1)",
          transform: "translateY(-5px)",
        },
      }}>
      <Box component={"img"} src={img} style={{ height: "90%" }} />
    </Box>
  );
};
const ShowClients = () => {
  const cardData = [
    {
      src: caseCons,
    },
    {
      src: arneel,
    },
    {
      src: nationalSteel,
    },
    {
      src: liugong,
    },
    {
      src: maan,
    },
    {
      src: shakti,
    },
  ];

  return (
    <Fragment>
      <Grid
        container
        spacing={1}
        marginBlock={"10px"}
        sx={{ justifyContent: "center", alignItems: "center" }}>
        {cardData.map((item, index) => (
          <Grid item lg={3} key={index}>
            <CustomCard data={item} />
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
};
export default ShowClients;
