import { Fragment } from "react";
import CustomSelect from "../../../../../global/components/customSelect";

const employmentTypeOptions = [
  { label: "ALL", value: "ALL" },
  { label: "ONROLL", value: "ONROLL" },
  { label: "CONTRACTOR", value: "CONTRACTOR" },
];

const EmploymentTypeFilter = ({ selectedEmploymentType, setSelectedEmploymentType }) => {
  return (
    <Fragment>
      <CustomSelect
        value={selectedEmploymentType}
        setvalue={setSelectedEmploymentType}
        options={employmentTypeOptions}
        label="Employment Type"
      />
    </Fragment>
  );
};

export default EmploymentTypeFilter;
