import React, { Fragment, useEffect, useState } from "react";
import LandingMain from "./design3/comps/landingMain";
import { BASE_URL } from "../assets/constantsFile";
import { saveDataWithoutAuth } from "../services/api/postApi";
import { getDataWithoutToken } from "../services/api/getApi";
import { tr } from "date-fns/locale";
// import LandingMain from "./design2/comps/landingMain";
// import LandingMain from "./design1/comps/landingMain";

const LandingIndex = () => {
  let flag = true;
  const saveLoginMetaData = async (location) => {
    console.log({ location });
    const url = BASE_URL + `event/log`;
    const payload = {
      location: location,
      eventType: "WEBSITE",
    };

    const res = await saveDataWithoutAuth(url, payload);

    if (res.error) {
      console.log({ error: res.error });
    } else {
      console.log({ data: res.data });
    }
  };

  useEffect(() => {
    if (flag) {
      getLocation();
      flag = false;
    }
  }, []);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      saveLoginMetaData("Geolocation is not supported by this browser.");
    }
  };

  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        saveLoginMetaData("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        saveLoginMetaData("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        saveLoginMetaData("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        saveLoginMetaData("An unknown error occurred.");
        break;
    }
  };
  const showPosition = async (position) => {
    let location = `latitude:${position.coords.latitude}, longitude:${position.coords.longitude}`;

    let loc = await getDetailOfLocation(position.coords.latitude, position.coords.longitude);

    console.log({ loc });
    saveLoginMetaData(loc);
  };

  const getDetailOfLocation = async (lat, long) => {
    let location = null;
    if (lat && long) {
      const url = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${long}&localityLanguage=en`;

      const res = await getDataWithoutToken(url);

      if (res.error) {
        console.log({ error: res.error });
      } else {
        console.log({ data4331: res.data });
        location = res.data?.locality;
      }
    }

    return location;
  };
  return (
    <Fragment>
      {/* <LandingMain /> */}
      <LandingMain />
    </Fragment>
  );
};

export default LandingIndex;
