import { Box, Grid, Stack, Typography } from "@mui/material";

const legendList = [
  {
    id: 1,
    title: "Report Available",
    color: "#127DDD",
  },
  {
    id: 2,
    title: "Report Not Available",
    color: "red",
  },
  {
    id: 3,
    title: "Test Not Required / Not Done",
    color: "#000000",
  },
  {
    id: 4,
    title: "Abnormal Readings",
    color: "#FFA500",
  },
];
const TableLegend = () => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1}>
          {legendList.map((value, index) => (
            <Stack
              sx={{ flexDirection: "row", marginRight: "10px" }}
              key={index}
            >
              <CustomCircle color={value.color} />

              <Typography
                sx={{
                  marginLeft: "10px",
                  fontWeight: "500",
                  fontSize: 12,
                  color: "#404040",
                }}
              >
                {value.title}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default TableLegend;

export const CustomCircle = ({ color }) => {
  return (
    <Box
      sx={{
        height: 16,
        width: 16,
        backgroundColor: color,
        borderRadius: "10px",
      }}
    />
  );
};
