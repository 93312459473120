import { Grid, IconButton, TextField, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { ISSUEREPORTED_TEXT } from "../../../../assets/constantsText";
import { updateData } from "../../../../services/api/patchApi";
import { BASE_URL } from "../../../../assets/constantsFile";

const IssueReportedFormComp = ({ patientDetails, setIsEdit }) => {
  const [text, setText] = useState(patientDetails ? patientDetails.issue : "");

  const updateValue = (e) => {
    e.preventDefault();
    const obj = {
      patientId: patientDetails.patientId,
      symptom: text,
    };
    updateFieldData(obj);
  };
  const updateFieldData = async (data) => {
    const url = BASE_URL + "patient/medicalHistory";
    const response = await updateData(url, data);

    if (response.error) {
      console.log("error");
    } else {
      console.log("success");
      setIsEdit(false);
      patientDetails.issue = data.issue;
    }
  };
  return (
    <Fragment>
      <form onSubmit={updateValue}>
        <Grid container>
          <Grid
            item
            lg={3}
            sx={{
              borderRight: 1,
              minHeight: 50,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: "600", fontSize: ".875rem" }}
            >
              {ISSUEREPORTED_TEXT}
            </Typography>
          </Grid>
          <Grid
            item
            lg={8}
            sx={{
              borderRight: 1,
              minHeight: 50,
              display: "flex",
              alignItems: "center",
              pl: 2,
              pr: 2,
            }}
          >
            <TextField
              label=""
              size="small"
              fullWidth
              variant="outlined"
              value={text || ""}
              onChange={(e) => {
                setText(e.target.value);
              }}
              inputProps={{
                style: { fontSize: ".875rem", fontWeight: "500" },
              }}
              InputLabelProps={{
                style: {
                  fontSize: ".875rem",
                  fontWeight: "500",
                  color: "#127DDD",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root:hover": {
                  "& > fieldset": {
                    borderColor: "#127DDD",
                  },
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#127DDD",
                  },
                },
              }}
            />
          </Grid>
          <Grid
            item
            lg={1}
            sx={{
              minHeight: 50,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton aria-label="save/update" size="small" type="submit">
              <SaveAsIcon fontSize="inherit" color="primary" />
            </IconButton>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default IssueReportedFormComp;
