import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import RecommendedTestsDataGrid from "../../../global/customDatagrids/recommendedTestsDataGrid";
import { useEffect, useState } from "react";
import { getDepartments } from "../../../global/services/corporateServices";
import ParseCSV from "./comps/parseCSV";
import { BASE_URL } from "../../../../assets/constantsFile";
import { getFormattedDDMonthYYYY } from "../../../../assets/utils";
import CustomTitles from "../../../global/titles/customTitles";
import SearchEmployeeAutocomplete from "../../../global/components/searchEmployeeAutocomplete";
import { getData } from "../../../../services/api/getApi";
import MaskingButton from "../../../global/components/maskingButton";

const employementType = "";

const displayText = (isFlag) => {
  return isFlag ? "YES" : isFlag === false ? "NO" : "N/A";
};

const AHCReccommendedTestsMain = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [apiRef, setApiRef] = useState(null);

  const [openNotice, setOpenNotice] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };

  const [employeeList, setEmployeeList] = useState([]);
  const [employeeListStatic, setEmployeeListStatic] = useState([]);
  const [empList, setEmpList] = useState([]);

  const getEmployeeListToBeTested = async () => {
    const url = BASE_URL + `org/employee/tests?corpId=${corpId}`;
    let data = [];
    const departments = await getData(url, "");
    if (departments.error) {
      data = [];
    } else {
      data = departments.data;
    }
    setEmployeeList(data);
    setEmployeeListStatic(data);
    setEmpList(data);
  };

  useEffect(() => {
    getEmployeeListToBeTested();
    getDepartments(corpId, setDepartmentList);
  }, []);

  console.log({ employeeList });

  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [departmentList, setDepartmentList] = useState([]);

  console.log({ selectedDepartment });

  const exportCSV = () => {
    apiRef.current.exportDataAsCsv();
  };

  const [searchText, setSearchText] = useState("");

  console.log({ searchText });

  const searchByEmpId = async (e) => {
    // e.preventDefault();
    const searchText = e && typeof e === "string" ? e : "";
    console.log({ searchText, e });
    if (searchText !== "") {
      const firstSpaceIndex = searchText?.indexOf(" ");

      // If there is a space, split at the first space; otherwise, use the whole string as empId
      const empIdSplitted =
        firstSpaceIndex !== -1 ? searchText?.slice(0, firstSpaceIndex) : searchText;
      const nameSplitted =
        firstSpaceIndex !== -1 ? searchText?.slice(firstSpaceIndex + 1) : searchText;

      console.log({ empIdSplitted, nameSplitted });

      const filteredDataContractor = employeeList.filter((obj) => {
        const empIdMatch = obj.empId?.trim()?.toLowerCase() === empIdSplitted.toLowerCase();
        const nameMatch = obj?.name?.name?.toLowerCase().includes(nameSplitted?.toLowerCase());

        // Check for matches based on empId and at least a part of the name
        return empIdMatch && nameMatch;
      });

      console.log({ filteredDataContractor });

      if (employementType === "CONTRACTOR" && filteredDataContractor.length === 0) {
        setSeverity("error");
        setMessage("Employee Not found");
        setOpenNotice(true);
      }

      setEmployeeList(filteredDataContractor);
    }
  };

  const clearSearch = () => {
    setSearchText("");
    setEmployeeList(empList);
  };

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="Recommended Tests" />
        </Grid>
        <Grid
          item
          lg={5}
          md={5}
          sm={12}
          xs={12}
          display="flex"
          //justifyContent="center"
          alignItems="center"
          sx={{ pl: 3 }}>
          {/* <Departments
            selectedDepartment={selectedDepartment}
            setSelectedDepartment={setSelectedDepartment}
            departmentList={departmentList}
          /> */}

          <Typography variant="h6" sx={{ color: "red" }}>
            Last Updated on{" "}
            {employeeList.length > 0
              ? employeeList[0].lastUploadDate
                ? getFormattedDDMonthYYYY(employeeList[0].lastUploadDate)
                : ""
              : ""}
          </Typography>
        </Grid>
        <Grid item lg={1} md={1} sm={12} xs={12}></Grid>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          {/* <SearchEmployee
            setEmployeeList={setEmployeeList}
            employeeList={employeeList}
            employeeListStatic={employeeListStatic}
          /> */}
          <SearchEmployeeAutocomplete
            employees={empList}
            handleSearchButtonClick={(e) => searchByEmpId(e)}
            clearSearch={clearSearch}
            searchTerm={searchText}
            setSearchTerm={setSearchText}
          />
        </Grid>
        <Grid item lg={1} md={1} sm={12} xs={12}>
          <MaskingButton />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <RecommendedTestsDataGrid
            setApiRef={setApiRef}
            employeeList={employeeList.map((item, index) => ({
              id: item.id,
              empId: item.empId,
              corpId: item.corpId,
              employeename: {
                name: item.name,
                imageUrl: item.imageUrl,
                empId: item.empId,
              },
              lastAhcDate: getFormattedDDMonthYYYY(item?.lastAhcDate),

              isXRay: displayText(item.isXRay),
              isCbc: displayText(item.isCbc),
              isUrine: displayText(item.isUrine),
              isFitness: displayText(item.isFitness),
              isEye: displayText(item.isEye),
              isAudiometry: displayText(item.isAudiometry),
              isPft: displayText(item.isPft),
              issBilirubin: displayText(item.issBilirubin),
              isTestCode: displayText(item.isTestCode),
              isStoolSample: displayText(item.isStoolSample),
              isECG: displayText(item.isECG),
              isLiverProfile: displayText(item.isLiverProfile),
              isHIV: displayText(item.isHIV),
              isSGOT: displayText(item.isSGOT),
              isSGPT: displayText(item.isSGPT),
              isESR: displayText(item.isESR),
              isHBSAG: displayText(item.isHBSAG),
              isVDRL: displayText(item.isVDRL),
              isWidal: displayText(item.isWidal),
              isHb1ac: displayText(item.isHb1ac),
              isTSH: displayText(item.isTSH),
              isBloodGroup: displayText(item.isBloodGroup),
              isSugarTest: displayText(item.isSugarTest),
            }))}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            component={Stack}
            spacing={3}
            direction={"row"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}>
            {/* <Button
              sx={{ height: 40, borderRadius: 4, px: 3 }}
              onClick={() => console.log("")}
              variant="contained">
              Upload CSV
            </Button> */}
            <ParseCSV setEmployeeList={setEmployeeList} corpId={corpId} />
            <Button
              variant="contained"
              size="small"
              sx={{ height: 40, borderRadius: 4, px: 3 }}
              onClick={exportCSV}>
              Download CSV
            </Button>
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} textAlign={"center"} sx={{ mb: 5 }}>
          <Typography variant="caption" sx={{ color: "red" }}>
            Do not Change Headers.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AHCReccommendedTestsMain;
