import { Alert, Box, Button, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../../../assets/constantsFile";
import { saveData } from "../../../../services/api/postApi";
import Gender from "../../../global/components/gender";
import { getCorpMedicineList } from "../../../global/services/corporateServices";
import BulkUpload from "./comps/bulkUpload";
import ParseCSV from "./comps/parseCSV";
import CustomTitles from "../../../global/titles/customTitles";
import axios from "axios";

const EDEnrollNewMain = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [formValues, setFormValues] = useState({
    corpId: corpId,
    employmentType: "PRE_EMPLOYMENT",
    preEmploymentStatus: "PENDING",
  });

  const [openNotice, setOpenNotice] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };

  const _submitHandler = async (e) => {
    e.preventDefault();
    const url = BASE_URL + `org/register`;
    let authHeader_local = localStorage.getItem("authHeader_local");

    try {
      const newEmployee = await axios(url, {
        method: "POST",
        data: formValues,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authHeader_local,
        },
      });

      // If the request is successful, log the response data
      console.log("Success:", newEmployee.data);
    } catch (error) {
      // If the error response status is 400, log the error message
      if (error.response && error.response.status === 400) {
        console.error("Error:", error.response.data.message);
        setSeverity("error");
        setMessage(error.response.data.message);
        setOpenNotice(true);
      } else {
        // For other errors, log a generic error message
        console.error("An error occurred:", error.message);
      }
    }
  };
  //console.log({ formValues, });

  const [medicineList, setMedicineList] = useState([]);
  const [medInitials, setMedInitials] = useState("N");

  useEffect(() => {
    getCorpMedicineList(corpId, medInitials, setMedicineList);
  }, [medInitials]);

  console.log({ medicineList });

  const [employeeList, setEmployeeList] = useState([]);

  const handleDownload = () => {
    const url =
      "https://storage-echikitsalaya.s3.ap-south-1.amazonaws.com/CORP_SAMPLE_FILES/demoPreEmployment.csv";
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = "downloaded_file"; // You can specify the desired filename here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box sx={{ px: 4 }}>
      <Snackbar open={openNotice} autoHideDuration={2000} onClose={handleCloseNotice}>
        <Alert onClose={handleCloseNotice} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <form onSubmit={(e) => _submitHandler(e)}>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CustomTitles title="Enroll New" />
          </Grid>

          <Grid item lg={4} md={4} sm={12} xs={12} display="flex" justifyContent="flex-start">
            <Button variant="contained" onClick={() => handleDownload()}>
              Download Sample CSV
            </Button>
          </Grid>

          <Grid item lg={8} md={8} sm={12} xs={12} display="flex" justifyContent="flex-end">
            {/* <BulkUpload /> */}
            <ParseCSV setEmployeeList={setEmployeeList} corpId={corpId} />
          </Grid>
          <Grid item lg={2} md={2} sm={12} xs={12}>
            <TextField
              sx={{ background: "white" }}
              required
              fullWidth
              size="small"
              label="Emp ID"
              value={formValues.empId || ""}
              onChange={(e) => {
                setFormValues({ ...formValues, empId: e.target.value });
              }}
            />
          </Grid>
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <TextField
              sx={{ background: "white" }}
              required
              fullWidth
              size="small"
              label="Name"
              value={formValues.name || ""}
              onChange={(e) => {
                setFormValues({ ...formValues, name: e.target.value });
              }}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <TextField
              sx={{ background: "white" }}
              fullWidth
              size="small"
              label="Age"
              value={formValues.age || ""}
              onChange={(e) => {
                setFormValues({ ...formValues, age: e.target.value });
              }}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <Gender formValues={formValues} setFormValues={setFormValues} />
            {/* <TextField
              fullWidth
              size="small"
              label="Gender"
              value={formValues.gender || ""}
              onChange={(e) => {
                setFormValues({ ...formValues, gender: e.target.value });
              }}
            /> */}
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <TextField
              sx={{ background: "white" }}
              fullWidth
              size="small"
              label="Blood group"
              value={formValues.bloodGroup || ""}
              onChange={(e) => {
                setFormValues({ ...formValues, bloodGroup: e.target.value });
              }}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <TextField
              sx={{ background: "white" }}
              fullWidth
              size="small"
              label="Designation"
              value={formValues.designation || ""}
              onChange={(e) => {
                setFormValues({ ...formValues, designation: e.target.value });
              }}
            />
          </Grid>
          <Grid item lg={10} md={10} sm={12} xs={12}>
            <TextField
              sx={{ background: "white" }}
              fullWidth
              size="small"
              label="Department"
              value={formValues.department || ""}
              onChange={(e) => {
                setFormValues({ ...formValues, department: e.target.value });
              }}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={12} xs={12}>
            <TextField
              sx={{ background: "white" }}
              fullWidth
              size="small"
              //label="Month of Joining"
              type="date"
              value={formValues.date || ""}
              onChange={(e) => {
                setFormValues({ ...formValues, date: e.target.value });
              }}
            />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center">
            <Button variant="contained" sx={{ px: 6 }} type="submit">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default EDEnrollNewMain;
