import { Box, Grid } from "@mui/material";
import AnalyticsComp from "./comp/analyticsComp";
import CustomTitles from "../../../global/titles/customTitles";

const EDAnalyticsMain = () => {
  return (
    <Box>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="Analytics" />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <AnalyticsComp />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EDAnalyticsMain;
