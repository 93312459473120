import { Box, Grid, Typography } from "@mui/material";
import CustomTitles from "../../../global/titles/customTitles";
import { BASE_URL } from "../../../../assets/constantsFile";
import { getData } from "../../../../services/api/getApi";
import { useEffect, useState } from "react";
import GalleryComp from "./comps/galleryComp";
import { _getPhotos } from "../../../global/services/corporateServices";

const AHCPhotoGalleryMain = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    _getPhotos(corpId, "GALLERY", setImageList);
  }, []);

  console.log({ imageList });
  return (
    <Box sx={{ background: "#f5f5f5" }}>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="AHC Photo Gallery" />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GalleryComp slideList={imageList} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AHCPhotoGalleryMain;
