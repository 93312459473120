import { Box, Grid, Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import LogoMain from "../logo/logoMain";
import LogoMainCustom from "../logo/logoMainCustom";

const PrintHeader = (props) => {
    const docDetail = props.docDetail;
    console.log({ docDetail: docDetail });
    const isWalkin = props.patientDetails.consultationType === "WALKIN";
    const isDolphine = docDetail.docId === "7873cd5c-8a98-426b-b645-5e45e440088d";

    const walkInComponent = (
        <Fragment>
            <Grid container columnSpacing={1} sx={{ background: "#127DDD", borderRadius: "10px" }}>
                <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    display="flex"
                    justifyContent="flex-start"
                    alignItems={"center"}
                    sx={{ py: 1 }}
                >
                    {docDetail?.clinicLogoURL && <LogoMainCustom clinicLogoURL={docDetail.clinicLogoURL} />}
                    {!docDetail?.clinicLogoURL && (
                        <Fragment>
                            <Box sx={{ pl: 2, py: 1 }}>
                                <Stack>
                                    <Stack direction="row" display="flex" justifyContent="flex-start">
                                        <Typography variant="body2" sx={{ fontWeight: 600, color: "#fff" }}>
                                            {docDetail.fullName ? "Dr. " + docDetail.fullName : ""}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" display="flex" justifyContent="flex-start">
                                        <Typography variant="body2" sx={{ fontWeight: 600, color: "#fff" }}>
                                            {docDetail?.degree}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" display="flex" justifyContent="flex-start">
                                        <Typography variant="body2" sx={{ fontWeight: 600, color: "#fff" }}>
                                            {docDetail?.specialization}
                                        </Typography>
                                    </Stack>
                                    {/* <Stack direction="row" display="flex" justifyContent="flex-start">
                                        <Typography variant="caption" sx={{ color: "#fff" }}>
                                            {docDetail.rmpNo ? "Reg No: " + docDetail.rmpNo : ""}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" display="flex" justifyContent="flex-start">
                                        <Typography variant="caption" sx={{ color: "#fff" }}>
                                            {docDetail.clinicPhoneNumber ? "Phone: " + docDetail.clinicPhoneNumber : ""}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" display="flex" justifyContent="flex-start">
                                        <Typography variant="caption" sx={{ color: "#fff" }}>
                                            {docDetail.email ? "Email: " + docDetail.email : ""}
                                        </Typography>
                                    </Stack> */}
                                </Stack>
                            </Box>
                        </Fragment>
                    )}
                </Grid>
            </Grid>
        </Fragment>
    );

    const dolphineComponent = (
        <Fragment>
            <Stack spacing={1}>
                <Stack direction="row" display="flex" justifyContent="flex-start">
                    <Typography variant="body1" sx={{ fontWeight: 600, color: "#fff" }}>
                        Dolphin Hospital
                    </Typography>
                </Stack>

                <Stack direction="row" display="flex" justifyContent="flex-start">
                    <Typography variant="body1" sx={{ fontWeight: 600, color: "#fff" }}>
                        Paediatrics Department
                    </Typography>
                </Stack>
            </Stack>
        </Fragment>
    );

    return (
        <Fragment>
            {docDetail && (
                <Fragment>
                    {isWalkin && walkInComponent}
                    {!isWalkin && (
                        <Fragment>
                            <Grid container columnSpacing={1} sx={{ background: "#127DDD", borderRadius: "10px" }}>
                                {/* <Grid
                                    item
                                    lg={8}
                                    md={8}
                                    sm={8}
                                    xs={8}
                                    display="flex"
                                    justifyContent="flex-start"
                                    alignItems={"center"}
                                >
                                    <LogoMain />
                                </Grid> */}

                                <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                >
                                    <Fragment>
                                        {isDolphine && dolphineComponent}
                                        {!isDolphine && (
                                            <Fragment>
                                                <Box sx={{ px: 2, py: 1 }}>
                                                    <Stack>
                                                        <Stack
                                                            direction="row"
                                                            display="flex"
                                                            justifyContent="flex-start"
                                                            alignItems="center"
                                                        >
                                                            <Typography
                                                                variant="h5"
                                                                sx={{
                                                                    fontWeight: 600,
                                                                    color: "#fff",
                                                                    textTransform: "capitalize",
                                                                }}
                                                            >
                                                                {docDetail?.hospitalVM?.name}
                                                            </Typography>
                                                        </Stack>
                                                        <Stack
                                                            direction="row"
                                                            display="flex"
                                                            justifyContent="flex-start"
                                                        >
                                                            <Typography
                                                                variant="body2"
                                                                sx={{ fontWeight: 600, color: "#fff" }}
                                                            >
                                                                {docDetail.fullName ? "Dr. " + docDetail.fullName : ""}
                                                                {", "}
                                                                {docDetail?.degree}
                                                            </Typography>
                                                        </Stack>

                                                        <Stack
                                                            direction="row"
                                                            display="flex"
                                                            justifyContent="flex-start"
                                                        >
                                                            <Typography
                                                                variant="body2"
                                                                sx={{ fontWeight: 600, color: "#fff" }}
                                                            ></Typography>
                                                        </Stack>

                                                        <Stack
                                                            direction="row"
                                                            display="flex"
                                                            justifyContent="flex-start"
                                                        >
                                                            <Typography
                                                                variant="body2"
                                                                sx={{ fontWeight: 600, color: "#fff" }}
                                                            >
                                                                {docDetail?.specialization}
                                                            </Typography>
                                                        </Stack>

                                                        {/* <Stack
                                                            direction="row"
                                                            display="flex"
                                                            justifyContent="flex-start"
                                                        >
                                                            <Typography variant="caption" sx={{ color: "#fff" }}>
                                                                {docDetail.rmpNo ? "Reg No: " + docDetail.rmpNo : ""}
                                                            </Typography>
                                                        </Stack>

                                                        <Stack
                                                            direction="row"
                                                            display="flex"
                                                            justifyContent="flex-start"
                                                        >
                                                            <Typography variant="caption" sx={{ color: "#fff" }}>
                                                                {docDetail.clinicPhoneNumber
                                                                    ? "Phone: " + docDetail.clinicPhoneNumber
                                                                    : ""}
                                                            </Typography>
                                                        </Stack>
                                                        <Stack
                                                            direction="row"
                                                            display="flex"
                                                            justifyContent="flex-start"
                                                        >
                                                            <Typography variant="caption" sx={{ color: "#fff" }}>
                                                                {docDetail.email ? "Email: " + docDetail.email : ""}
                                                            </Typography>
                                                        </Stack> */}
                                                    </Stack>
                                                </Box>
                                            </Fragment>
                                        )}
                                    </Fragment>
                                </Grid>
                            </Grid>
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default PrintHeader;
