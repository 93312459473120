import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { getData } from "../../../../../services/api/getApi";
import { useEffect, useState } from "react";
import EmployeeDataGrid from "./employeeDataGrid";

const PEDeatiledData = ({
  setIsGraph,
  selectedMonth,
  corpId = localStorage.getItem("CORPID"),
}) => {
  const startDate = "2023-01-01";
  const endDate = "2023-12-30";

  const [monthlyData, setMonthlyData] = useState([]);

  const _fetchEmployeeAnalytics = async () => {
    const url =
      BASE_URL +
      `org/preEmployed?corpId=${corpId}&startDate=${startDate}&endDate=${endDate}`;

    const response = await getData(url, "");

    if (response.error) {
      //console.warn("error");
    } else {
      //const data = Object.entries(response.data);
      setMonthlyData(response.data?.["2024"]?.[selectedMonth]);
    }
  };

  useEffect(() => {
    _fetchEmployeeAnalytics();
  }, []);

  //console.log({ selectedMonth });

  const [apiRef, setApiRef] = useState(null);
  console.log({ monthlyData });

  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        border: "1px solid grey",
        paddingInline: "10px",
        paddingBlock: "20px",
        borderRadius: "10px",
        width: "100%",
      }}
    >
      <Grid container spacing={1}>
        <Grid item lg={2} md={2} sm={12} xs={12}>
          <IconButton
            onClick={() => {
              setIsGraph(true);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} textAlign="center">
          <Typography variant="h6">{selectedMonth}</Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12} textAlign={"end"}>
          <Button variant="contained" onClick={() => setOpen(true)}>
            + Enroll new for pre-emp
          </Button>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <EmployeeDataGrid
            setApiRef={setApiRef}
            employeeList={monthlyData}
            open={open}
            setOpen={setOpen}
            corpId={corpId}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PEDeatiledData;
