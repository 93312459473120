import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import OHCInjuriesStatusTracker from "./ohcInjuriesStatusTracker";
import OHCInjuriesHealthRecord from "./ohcInjuriesHealthRecord";

const OHCInjuriesMain = () => {
  const _storedData = (() => {
    try {
      return JSON.parse(localStorage.getItem("SAVED_INJURIES_FILTERS")) || {};
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {};
    }
  })();

  useEffect(() => {
    setValue(_storedData.value || "1");
  }, []);

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const savedFilter = {
      value,
    };
    localStorage.setItem("SAVED_INJURIES_FILTERS", JSON.stringify(savedFilter));
  }, [value]);

  return (
    <Fragment>
      <Box sx={{ width: "100%" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab label="Status Tracker" value="1" />

              <Tab label="Health Record" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ p: 0 }}>
            <OHCInjuriesStatusTracker />
          </TabPanel>

          <TabPanel value="2" sx={{ p: 0 }}>
            <OHCInjuriesHealthRecord />
          </TabPanel>
        </TabContext>
      </Box>
    </Fragment>
  );
};

export default OHCInjuriesMain;
