import { Route } from "react-router-dom";
import SAIndex from "../pages/saIndex";
import { Fragment } from "react";
import SaRaiseTicketIndex from "../pages/saRaiseTicketIndex";

export const SecurityRoutes = (
  <Fragment>
    <Route path="/sa/:id" element={<SAIndex />} />
    <Route path="/sa/raiseticket" element={<SaRaiseTicketIndex />} />
  </Fragment>
);
