import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { BASE_URL } from "../../../../assets/constantsFile";
import { getData } from "../../../../services/api/getApi";
import { useEffect, useMemo, useState } from "react";
import Tickets from "../tickets";
import CustomTitles from "../../../global/titles/customTitles";
import TicketDashboardCard from "./ticketDashboardCard";
import CustomDateNew from "./comps/customDateNew";
import CustomAutoCompleteNew from "./comps/customAutoCompleteNew";

const manipulateStages = (originalArray, selectedTicketType) => {
  const newArray = [...originalArray];
  newArray.unshift(
    { stageName: "ALL", sequence: 0 },
    { stageName: "TICKET_RAISED", sequence: 1 },
    { stageName: "ASSIGNED", sequence: 2 }
  );
  newArray.push({ stageName: "DELETED", sequence: newArray.length + 2 });
  if (selectedTicketType?.value === "ALL") {
    newArray.push(
      { stageName: "IN_PROGRESS", sequence: newArray.length + 1 },
      { stageName: "COMPLETED", sequence: newArray.length + 2 }
    );
  }
  return newArray;
};
const getCurrentStatus = (status, ticketStatusStage) => {
  if (
    status === "TICKET_RAISED" ||
    status === "ASSIGNED" ||
    status === "DELETED" ||
    status === "IN_PROGRESS" ||
    status === "COMPLETED"
  ) {
    return status;
  } else if (status === "CARE_COORDINATOR") {
    return ticketStatusStage || status;
  } else if (status === "CLOSED") {
    return status;
  }
};

export const bgColors = ["#F4527C", "#7F6BE2", "#3CCAAC", "#FF8556"];

const toISOStringInIST = (date) => {
  const offset = date?.getTimezoneOffset();
  const istOffset = 330;
  const istTime = new Date(date?.getTime() + (offset + istOffset) * 60 * 1000);
  const istDate = istTime.getDate();
  const istMonth = istTime.getMonth() + 1;
  const istYear = istTime.getFullYear();
  return `${istYear}-${istMonth < 10 ? "0" + istMonth : istMonth}-${
    istDate < 10 ? "0" + istDate : istDate
  }`;
};

const TicketsMain = ({
  corpId = localStorage.getItem("CORPID"),
  date = new Date().toISOString("2023-01-01").split("T")[0],
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = (title) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [fromDate, setFromDate] = useState(toISOStringInIST(new Date()));

  const [toDate, setToDate] = useState(toISOStringInIST(new Date()));
  console.log({ fromDate, toDate });
  const [ticketList, setTicketList] = useState([]);
  const [fetch, setFetch] = useState(false);
  const getTicketsList = async () => {
    const url =
      BASE_URL +
      `org/ticket/all?corpId=${corpId}&startDate=${fromDate}&endDate=${toDate}`;
    const response = await getData(url, "");

    if (response.error) {
      setTicketList([]);
    } else {
      const val = response.data;
      setFetch(false);
      const temp = response?.data?.map((item, index) => ({
        ticketId: item?.ticketId,
        empId: item?.empId,
        name: item?.empId,
        mobile: item?.mobile,
        age: item?.age,
        gender: item?.gender,
        remarks: item?.remarks,
        date: item?.date,
        status: item?.status,
        ticketType: item?.ticketType,
        ticketStatusStage: item?.[0]?.ticketStatusStage,
        currentStatus: getCurrentStatus(item?.status, item?.ticketStatusStage),
        corpTicketRequests: item?.corpTicketRequests?.map((item2) => ({
          ...item2,
          finalStage: getCurrentStatus(item2?.status, item2?.ticketStatusStage),
        })),
      }));

      setTicketList(temp);
    }
  };

  console.log({ ticketList });

  useEffect(() => {
    const refreshTimer = localStorage.getItem("REFRESH_TIMER");
    const fetchData = async () => {
      await getTicketsList();
    };
    getTicketsList();
    if (refreshTimer !== "") {
      const interval = setInterval(fetchData, refreshTimer);

      return () => clearInterval(interval);
    } else {
      fetchData();
    }
  }, [corpId, fetch, fromDate, toDate]);

  const [stageList, setStageList] = useState([]);
  const fetHealthTicketStageList = async () => {
    const url =
      BASE_URL + "pharmacy/ticket/stage/config?ticketType=HEALTH_TICKET";
    const result = await getData(url);
    if (result && result.data) {
      setStageList(result.data);
    } else if (result && result.error) {
      setStageList([]);
    }
  };

  useEffect(() => {
    fetHealthTicketStageList();
  }, []);

  const [selectedTicketType, setSelectedTicketType] = useState({
    value: "ALL",
  });
  const [selectStage, setSelectedStage] = useState({
    stageName: "ALL",
    sequence: 0,
  });
  const handleSelectStage = (event, newValue, reason) => {
    setSelectedStage(newValue);

    if (reason === "clear") {
      setSelectedStage({ stageName: "ALL", sequence: 0 });
    }
  };

  const filteredTicketList = useMemo(() => {
    if (!ticketList) return [];

    return ticketList.filter(
      (item) =>
        (selectStage?.stageName === "ALL"
          ? true
          : selectStage?.stageName === item?.currentStatus) &&
        (selectedTicketType?.value === "ALL"
          ? true
          : selectedTicketType?.value === item?.ticketType)
    );
  }, [ticketList, selectStage, selectedTicketType]);

  return (
    <Box>
      <Grid container spacing={2} sx={{ backgroundColor: "#FFFFFF" }}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="Tickets" />
        </Grid>
        <Grid item lg={2} xs={12}>
          <CustomDateNew
            date={fromDate}
            setDate={setFromDate}
            disableFuture={true}
            label={"From Date"}
          />
        </Grid>
        <Grid item lg={2} xs={12}>
          <CustomDateNew
            date={toDate}
            setDate={setToDate}
            disableFuture={true}
            label={"To Date"}
          />
        </Grid>
        <Grid item lg={2} xs={12}>
          <CustomAutoCompleteNew
            options={[
              { value: "ALL" },
              { value: "HEALTH_TICKET" },
              { value: "ADMIN_TICKET" },
            ]}
            value={selectedTicketType}
            onChange={(event, newValue, reason) => {
              setSelectedTicketType(newValue);
              if (reason === "clear") {
                setSelectedTicketType({ value: "ALL" });
              }
            }}
            label={"Ticket Type"}
            getOptionLabel={(option) => option.value}
          />
        </Grid>
        <Grid item lg={2} xs={12}>
          <CustomAutoCompleteNew
            options={
              selectedTicketType.value === "ADMIN_TICKET"
                ? [
                    { stageName: "TICKET_RAISED", sequence: 0 },
                    { stageName: "IN_PROGRESS", sequence: 1 },
                    { stageName: "COMPLETED", sequence: 2 },
                  ]
                : manipulateStages(stageList, selectedTicketType)
            }
            value={selectStage}
            onChange={handleSelectStage}
            label={"Select Stages"}
            getOptionLabel={(option) => option.stageName}
          />
        </Grid>
        <Grid
          item
          lg={4}
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            onClick={() => handleOpen()}
            sx={{
              textTransform: "capitalize",
              backgroundColor: "#127DDD",
              borderRadius: "10px",
            }}
            variant="contained"
          >
            Raise Ticket
          </Button>
        </Grid>

        {filteredTicketList.length > 0 ? (
          filteredTicketList?.map((item, index) => (
            <TicketDashboardCard data={item} key={index} />
          ))
        ) : (
          <Box
            sx={{
              width: "100%",
              border: "1px solid #000",
              backgroundColor: "#FFF",
              height: "66vh",
              borderRadius: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBlock: "20px",
            }}
          >
            <Typography sx={{ color: "#000", fontSize: "15px" }}>
              No Tickets Found
            </Typography>
          </Box>
        )}
      </Grid>

      <Tickets setFetch={setFetch} handleClose={handleClose} open={open} />
    </Box>
  );
};

export default TicketsMain;
