import { Box, Grid, Typography, Stack } from "@mui/material";
import { Fragment, useState, useEffect } from "react";
import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApi";

const ReferredToComp = ({ appointmentId }) => {
  useEffect(() => {
    fetchRefralHandler();
  }, []);

  const [doctor, setDoctor] = useState("");

  const fetchRefralHandler = async () => {
    const url = BASE_URL + "doctor/refer/" + appointmentId;

    const user = await getData(url);

    if (user.error) {
      console.log("error");
    } else {
      const result = user.data;
      setDoctor(result);
      console.log({ setDocNameList: result });
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F5F2FF",
        borderRadius: "25px",
      }}
    >
      <Grid container>
        <Grid item sx={{ my: 2, mx: 2 }} lg={12}>
          <Stack direction={"row"} spacing={1}>
            <Typography sx={{ color: "#3300FF", fontWeight: "bold" }}>
              Referred to:
            </Typography>
            <Typography>
              {doctor.referredDoctorName || "NA"}
              {doctor.speciality && ", "} {doctor.speciality}
            </Typography>
          </Stack>
          <Stack direction={"row"} spacing={1}>
            <Typography sx={{ color: "#3300FF", fontWeight: "bold" }}>
              Notes:
            </Typography>
            <Typography>{doctor.issue || "NA"}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ReferredToComp;
