import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Paper,
  Portal,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import StepConnector from "@mui/material/StepConnector";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import CallIcon from "@mui/icons-material/Call";
import assignedIcon1 from "../../../assets/images/assignedIcon1.jpeg";
import billingIcon1 from "../../../assets/images/billingIcon1.jpeg";
import careCoordinatorIcon1 from "../../../assets/images/careCoordinatorIcon1.jpeg";
import admissionIcon1 from "../../../assets/images/admissionIcon1.png";
import consultationIcon1 from "../../../assets/images/consultationIcon1.jpeg";
import dischargeIcon from "../../../assets/images/dischargeIcon.png";
import healthPatientIcon1 from "../../../assets/images/healthPatientIcon1.jpeg";
import insuaranceicon1 from "../../../assets/images/insuaranceicon1.jpeg";
import onGoingTreatmentIcon1 from "../../../assets/images/onGoingTreatmentIcon1.png";
import ticketIcon1 from "../../../assets/images/ticketIcon1.jpeg";
import patientRecieved from "../../../assets/images/patientRecieved.jpeg";
import pharmacyIcon1 from "../../../assets/images/pharmacyIcon1.jpeg";
import receptionIcon1 from "../../../assets/images/receptionIcon1.jpeg";
import careCoordinatorAssigned from "../../../assets/images/careCoordinatorAssigned.jpeg";
import workinprogress from "../../../assets/images/workinprogress.png";
import ticketResolved from "../../../assets/images/ticketResolved.png";
import deletedIcon from "../../../assets/images/deletedIcon.png";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

const getIcon = (item) => {
  if (item?.currentStatus === "TICKET_RAISED") {
    return ticketIcon1;
  } else if (item?.currentStatus === "ASSIGNED") {
    return careCoordinatorAssigned;
  } else if (item?.currentStatus === "PATIENT_RECEIVED") {
    return patientRecieved;
  } else if (item?.currentStatus === "RECEPTION") {
    return receptionIcon1;
  } else if (item?.currentStatus === "BILLING") {
    return billingIcon1;
  } else if (item?.currentStatus === "CONSULTATION") {
    return consultationIcon1;
  } else if (item?.currentStatus === "PHARMACY_AND_DIAGNOSTIC_DONE") {
    return pharmacyIcon1;
  } else if (item?.currentStatus === "INSURANCE_DOCUMENT") {
    return insuaranceicon1;
  } else if (item?.currentStatus === "ADMISSION") {
    return admissionIcon1;
  } else if (item?.currentStatus === "ONGOING_TREATMENT") {
    return onGoingTreatmentIcon1;
  } else if (item?.currentStatus === "DISCHARGE") {
    return healthPatientIcon1;
  } else if (item?.status === "IN_PROGRESS") {
    return workinprogress;
  } else if (item?.status === "COMPLETED") {
    return workinprogress;
  } else if (item?.status === "DELETED") {
    return deletedIcon;
  }
};
const getColorBackgroundBorder = (item) => {
  if (item?.currentStatus === "TICKET_RAISED") {
    return "#C8D0CF";
  } else if (item?.currentStatus === "ASSIGNED") {
    return "#C2DFFF";
  } else if (item?.currentStatus === "PATIENT_RECEIVED") {
    return "#CAB2D5";
  } else if (item?.currentStatus === "RECEPTION") {
    return "#6A3D9A";
  } else if (item?.currentStatus === "BILLING") {
    return "#B15928";
  } else if (item?.currentStatus === "CONSULTATION") {
    return "#FDBE6E";
  } else if (item?.currentStatus === "PHARMACY_AND_DIAGNOSTIC_DONE") {
    return "#FFFF99";
  } else if (item?.currentStatus === "INSURANCE_DOCUMENT") {
    return "#FF7F00";
  } else if (item?.currentStatus === "ADMISSION") {
    return "#107DDD";
  } else if (item?.currentStatus === "ONGOING_TREATMENT") {
    return "#B2DF8A";
  } else if (item?.currentStatus === "DISCHARGE") {
    return "#31A02D";
  } else if (item?.status === "IN_PROGRESS") {
    return "#FFFF99";
  } else if (item?.status === "COMPLETED") {
    return "#31A02D";
  } else if (item?.status === "DELETED") {
    return "red";
  }
};

const getFontColor = (item) => {
  if (item?.currentStatus === "TICKET_RAISED") {
    return "#000";
  } else if (item?.currentStatus === "ASSIGNED") {
    return "#000";
  } else if (item?.currentStatus === "PATIENT_RECEIVED") {
    return "#000";
  } else if (item?.currentStatus === "RECEPTION") {
    return "#FFF";
  } else if (item?.currentStatus === "BILLING") {
    return "#FFF";
  } else if (item?.currentStatus === "CONSULTATION") {
    return "#000";
  } else if (item?.currentStatus === "PHARMACY_AND_DIAGNOSTIC_DONE") {
    return "#000";
  } else if (item?.currentStatus === "INSURANCE_DOCUMENT") {
    return "#FFF";
  } else if (item?.currentStatus === "ADMISSION") {
    return "#FFF";
  } else if (item?.currentStatus === "ONGOING_TREATMENT") {
    return "#000";
  } else if (item?.currentStatus === "DISCHARGE") {
    return "#FFF";
  } else if (item?.status === "IN_PROGRESS") {
    return "#000";
  } else if (item?.status === "COMPLETED") {
    return "#FFF";
  } else if (item?.status === "DELETED") {
    return "#FFF";
  }
};

const TicketDashboardCard = ({ data }) => {
  const formatDate = (dateString) => {
    if (!dateString) return "Invalid Date";

    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const corpTicketRequests = data?.corpTicketRequests;

  let stages = [];
  if (data?.ticketType === "HEALTH_TICKET") {
    stages = [
      { name: "Ticket Raised", status: "TICKET_RAISED", icon: ticketIcon1 },
      {
        name: "Care Coordinator Assigned",
        status: "ASSIGNED",
        icon: careCoordinatorAssigned,
      },
      {
        name: "Patient Received",
        status: "PATIENT_RECEIVED",
        icon: patientRecieved,
      },
      { name: "Reception Done", status: "RECEPTION", icon: receptionIcon1 },
      { name: "Billing", status: "BILLING", icon: billingIcon1 },
      { name: "Consultation", status: "CONSULTATION", icon: consultationIcon1 },
      {
        name: "Pharmacy and Diagnostic",
        status: "PHARMACY_AND_DIAGNOSTIC_DONE",
        icon: pharmacyIcon1,
      },
      {
        name: "Insurance Document",
        status: "INSURANCE_DOCUMENT",
        icon: insuaranceicon1,
      },
      { name: "Admission", status: "ADMISSION", icon: admissionIcon1 },
      {
        name: "On going Treatment",
        status: "ONGOING_TREATMENT",
        icon: onGoingTreatmentIcon1,
      },
      { name: "Ticket Deleted", status: "DELETED", icon: deletedIcon },
      { name: "Happy Person", status: "DISCHARGE", icon: healthPatientIcon1 },
    ];
  } else if (data?.ticketType === "ADMIN_TICKET") {
    stages = [
      { name: "Ticket Raised", status: "TICKET_RAISED", icon: ticketIcon1 },
      { name: "Work In Progress", status: "IN_PROGRESS", icon: workinprogress },
      { name: "Ticket Deleted", status: "DELETED", icon: deletedIcon },
      { name: "Ticket Resolved", status: "COMPLETED", icon: ticketResolved },
    ];
  }

  const stepsCompleted2 = stages.map((stage) => {
    const stageData = corpTicketRequests?.find(
      (request) => request.finalStage === stage.status
    );
    const isCompleted = !!stageData;
    const visible =
      stage.name === "Ticket Raised" ||
      isCompleted ||
      stage.name === "Happy Person" ||
      stage.name === "Ticket Resolved";
    const description = isCompleted ? stageData : null;

    return {
      name: stage.name,
      icon: stage.icon,
      visible: visible,
      isCompleted: isCompleted,
      description: description,
    };
  });

  const stepsConsize = [
    {
      name: "Ticket Raised",
      icon: ticketIcon1,
      isCompleted: true,
      visible: true,
    },
    {
      name: "Care Coordinator Assigned",
      icon: careCoordinatorAssigned,
      visible: data?.corpTicketRequests.some(
        (ticket) => ticket.finalStage === "ASSIGNED"
      )
        ? true
        : false,
      isCompleted: data?.corpTicketRequests.some(
        (ticket) => ticket.finalStage === "ASSIGNED"
      )
        ? true
        : false,
    },
    {
      name: "Patient Recieved",
      icon: patientRecieved,
      visible: data?.corpTicketRequests.some(
        (ticket) => ticket.finalStage === "PATIENT_RECEIVED"
      )
        ? true
        : false,
      isCompleted: data?.corpTicketRequests.some(
        (ticket) => ticket.finalStage === "PATIENT_RECEIVED"
      )
        ? true
        : false,
    },

    data?.currentStatus === "DISCHARGE" ||
    data?.currentStatus === "PATIENT_RECEIVED" ||
    data?.currentStatus === "ASSIGNED" ||
    data?.currentStatus === "TICKET_RAISED"
      ? null
      : {
          name: data?.currentStatus,
          icon: getIcon(data),
          visible: data?.corpTicketRequests.some(
            (ticket) => ticket.finalStage === `${data?.currentStatus}`
          )
            ? true
            : false,
          isCompleted: data?.corpTicketRequests.some(
            (ticket) => ticket.finalStage === `${data?.currentStatus}`
          )
            ? true
            : false,
        },
    ,
    {
      name: "Happy Person",
      icon: healthPatientIcon1,
      visible: true,
      isCompleted: data?.corpTicketRequests.some(
        (ticket) => ticket.finalStage === "DISCHARGE"
      )
        ? true
        : false,
    },
  ];

  console.log({ stepsConsize });

  console.log({ data });

  return (
    <Fragment>
      <Paper
        elevation={7}
        sx={{
          width: "100%",
          marginBlock: "10px",
          borderRadius: isMobile ? "10px" : "15px ",
          marginInline: isMobile ? "10px" : null,
        }}
      >
        <BrowserView>
          <Grid container>
            <Grid
              item
              lg={2}
              sx={{
                backgroundColor: "#E7F2FB",
                borderTopLeftRadius: "5px",
                borderBottomLeftRadius: "5px",
                padding: "10px",
              }}
            >
              <Grid container spacing={1}>
                <Grid
                  item
                  lg={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Stack
                    sx={{
                      borderWidth: "1px",
                      borderColor: getColorBackgroundBorder(data),
                      borderRadius: "15px",
                      padding: "5px",
                      textAlign: "center",
                      width: "220px",
                      backgroundColor: getColorBackgroundBorder(data),
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: getFontColor(data),
                        }}
                      >
                        {data?.ticketType === "ADMIN TICKET"
                          ? data?.status
                          : data?.currentStatus}
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
                <Grid
                  item
                  lg={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Stack>
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#275579",
                        }}
                      >
                        {data.time}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#91ABBC",
                          marginLeft: "10px",
                        }}
                      >
                        {formatDate(data.date)}
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
                <Grid
                  item
                  lg={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#127DDD",
                      marginLeft: "10px",
                    }}
                  >
                    {data?.ticketType}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              lg={10}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "15px",
                padding: "10px",
              }}
            >
              <Grid container spacing={1}>
                <Grid
                  item
                  lg={12}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "#8BA7BB",
                        fontWeight: "600",
                        fontSize: "17px",
                        textTransform: "capitalize",
                        marginLeft: "20px",
                      }}
                    >
                      {data?.name?.toLowerCase()}, {data?.age},{" "}
                      {data?.gender?.toLowerCase()}, {data.mobile}{" "}
                      {data.mobile ? (
                        <IconButton
                          onClick={() => {
                            window.open(`tel:${data.mobile}`);
                          }}
                        >
                          <CallIcon sx={{ color: "#127DDD" }} />
                        </IconButton>
                      ) : null}
                    </Typography>
                  </Box>
                  <Stack>
                    <Button
                      startIcon={
                        <VisibilityIcon style={{ color: "#127DDD" }} />
                      }
                      variant="outlined"
                      sx={{ borderRadius: "15px" }}
                      onClick={handleOpenModal}
                    >
                      View Detail
                    </Button>
                  </Stack>
                </Grid>

                <Grid item lg={12}>
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Stepper
                      connector={
                        <StepConnector
                          sx={{
                            ".MuiStepConnector-line": {
                              borderTop: "2px dashed #000",
                            },
                          }}
                        />
                      }
                    >
                      {data?.ticketType === "HEALTH_TICKET" &&
                        stepsConsize?.map((step, index) =>
                          step?.visible ? (
                            <Step key={step.name} completed={step?.isCompleted}>
                              <StepLabel
                                StepIconComponent={() => (
                                  <Box
                                    sx={{
                                      padding: "5px",
                                      height:
                                        step.name ===
                                        "Care Coordinator Assigned"
                                          ? "50px"
                                          : "40px",
                                      width:
                                        step.name ===
                                        "Care Coordinator Assigned"
                                          ? "50px"
                                          : "40px",
                                      borderRadius: "50%",
                                      border: "1px solid #000",
                                    }}
                                  >
                                    <Box
                                      component={"img"}
                                      src={step.icon}
                                      sx={{
                                        height:
                                          step.name ===
                                          "Care Coordinator Assigned"
                                            ? "40px"
                                            : "30px",
                                        width:
                                          step.name ===
                                          "Care Coordinator Assigned"
                                            ? "40px"
                                            : "30px",
                                      }}
                                    />
                                  </Box>
                                )}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: "600",
                                    textTransform: "capitalize",
                                    color: step.isCompleted
                                      ? "#127DDD"
                                      : "lightgrey",
                                  }}
                                >
                                  {step.name.toLowerCase()}
                                </Typography>
                              </StepLabel>
                            </Step>
                          ) : null
                        )}
                      {data?.ticketType === "ADMIN_TICKET" &&
                        stepsCompleted2?.map((step, index) =>
                          step?.visible ? (
                            <Step key={step.name} completed={step?.isCompleted}>
                              <StepLabel
                                StepIconComponent={() => (
                                  <Box
                                    sx={{
                                      padding: "5px",
                                      height:
                                        step.name ===
                                        "Care Coordinator Assigned"
                                          ? "50px"
                                          : "40px",
                                      width:
                                        step.name ===
                                        "Care Coordinator Assigned"
                                          ? "50px"
                                          : "40px",
                                      borderRadius: "50%",
                                      border: "1px solid #000",
                                    }}
                                  >
                                    <Box
                                      component={"img"}
                                      src={step.icon}
                                      sx={{
                                        height:
                                          step.name ===
                                          "Care Coordinator Assigned"
                                            ? "40px"
                                            : "30px",
                                        width:
                                          step.name ===
                                          "Care Coordinator Assigned"
                                            ? "40px"
                                            : "30px",
                                      }}
                                    />
                                  </Box>
                                )}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: "600",
                                    color: step.isCompleted
                                      ? "#127DDD"
                                      : "lightgrey",
                                  }}
                                >
                                  {step.name}
                                </Typography>
                              </StepLabel>
                            </Step>
                          ) : null
                        )}
                    </Stepper>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </BrowserView>
        <MobileView>
          <Grid container sx={{ padding: "10px" }}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: "#8BA7BB",
                  fontWeight: "600",
                  fontSize: "17px",
                  textTransform: "capitalize",
                  flexWrap: "wrap",
                }}
              >
                {data?.name?.toLowerCase()}, {data?.age},{" "}
                {data?.gender?.toLowerCase()}, {data.mobile}{" "}
              </Typography>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Stack>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#275579",
                      }}
                    >
                      {data.time}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#91ABBC",
                        marginLeft: "10px",
                      }}
                    >
                      {formatDate(data.date)}
                    </Typography>
                  </Box>
                </Stack>
                <Stack>
                  <Button
                    startIcon={<VisibilityIcon style={{ color: "#127DDD" }} />}
                    variant="outlined"
                    sx={{ borderRadius: "15px", width: "140px" }}
                    onClick={handleOpenModal}
                  >
                    View Detail
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{ border: "0.5px solid lightgrey", marginBlock: "5px" }}
                ></Box>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  sx={{
                    borderWidth: "1px",
                    borderColor: getColorBackgroundBorder(data),
                    borderRadius: "15px",
                    padding: "5px",
                    textAlign: "center",
                    backgroundColor: getColorBackgroundBorder(data),
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: getFontColor(data),
                      }}
                    >
                      {data?.ticketType === "ADMIN TICKET"
                        ? data?.status
                        : data?.currentStatus}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </MobileView>
      </Paper>

      <Portal>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={openModal}
          onClose={handleCloseModal}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <Box
            sx={{
              width: "80%",
              height: isMobile ? "80%" : "60%",
              bgcolor: "background.paper",
              borderRadius: "15px",
              boxShadow: 24,
            }}
          >
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Typography
              gutterBottom
              variant="h5"
              sx={{
                textAlign: "center",
                fontWeight: "600",
                lineHeight: "17px",
                color: "#000000",
                marginTop: "-25px",
                marginBottom: "10px",
              }}
            >
              Ticket Details
            </Typography>

            <Box
              sx={{
                p: 4,
                marginTop: 10,
                height: "75%",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "12px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#888",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "#555",
                },
              }}
            >
              <BrowserView>
                <Stepper
                  orientation="horizontal"
                  alternativeLabel
                  connector={
                    <StepConnector
                      sx={{
                        ".MuiStepConnector-lineHorizontal": {
                          borderWidth: "3px",
                          margin: "25px 20px",
                          borderColor: "#000",
                        },
                      }}
                    />
                  }
                >
                  {data?.ticketType === "HEALTH_TICKET" &&
                    stepsCompleted2?.map((step, index) =>
                      step?.visible ? (
                        <Step
                          key={step.name}
                          completed={step?.isCompleted}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <StepLabel
                            StepIconComponent={() => (
                              <Box
                                sx={{
                                  margin: "5px",
                                  padding: "10px",
                                  height: "70px",
                                  width: "70px",
                                  borderRadius: "50%",
                                  border: "1px solid #000",
                                }}
                              >
                                <Box
                                  component={"img"}
                                  src={step.icon}
                                  sx={{
                                    height: "50px",
                                    width: "50px",
                                  }}
                                />
                              </Box>
                            )}
                          >
                            <Typography
                              sx={{
                                width: "200px",
                                fontWeight: "600",
                                fontSize: "17px",
                                color: step.isCompleted
                                  ? "#127DDD"
                                  : "lightgrey",
                              }}
                            >
                              {step?.name}
                            </Typography>
                            <Typography
                              sx={{
                                width: "200px",
                                fontWeight: "600",
                                fontSize: "15px",
                                color: "#000",
                              }}
                            >
                              {step?.description?.time}
                            </Typography>
                            <Typography
                              sx={{
                                width: "200px",
                                fontWeight: "600",
                                fontSize: "15px",
                                color: "#000",
                                textTransform: "capitalize",
                              }}
                            >
                              {step?.description?.remarks}
                            </Typography>
                          </StepLabel>
                        </Step>
                      ) : null
                    )}
                  {data?.ticketType === "ADMIN_TICKET" &&
                    stepsCompleted2?.map((step, index) =>
                      step?.visible ? (
                        <Step
                          key={step.name}
                          completed={step?.isCompleted}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <StepLabel
                            StepIconComponent={() => (
                              <Box
                                sx={{
                                  margin: "5px",
                                  padding: "10px",
                                  height: "70px",
                                  width: "70px",
                                  borderRadius: "50%",
                                  border: "1px solid #000",
                                }}
                              >
                                <Box
                                  component={"img"}
                                  src={step.icon}
                                  sx={{
                                    height: "50px",
                                    width: "50px",
                                  }}
                                />
                              </Box>
                            )}
                          >
                            <Typography
                              sx={{
                                width: "200px",
                                fontWeight: "600",
                                fontSize: "17px",
                                color: step.isCompleted
                                  ? "#127DDD"
                                  : "lightgrey",
                              }}
                            >
                              {step?.name}
                            </Typography>
                            <Typography
                              sx={{
                                width: "200px",
                                fontWeight: "600",
                                fontSize: "15px",
                                color: "#000",
                              }}
                            >
                              {step?.description?.time}
                            </Typography>
                            <Typography
                              sx={{
                                width: "200px",
                                fontWeight: "600",
                                fontSize: "15px",
                                color: "#000",
                                textTransform: "capitalize",
                              }}
                            >
                              {step?.description?.remarks}
                            </Typography>
                          </StepLabel>
                        </Step>
                      ) : null
                    )}
                </Stepper>
              </BrowserView>
              <MobileView>
                <Stepper
                  orientation="vertical"
                  connector={
                    <StepConnector
                      sx={{
                        ".MuiStepConnector-lineVertical": {
                          borderWidth: "2px",
                          height: "50px",
                          borderColor: "#000",
                        },
                      }}
                    />
                  }
                >
                  {data?.ticketType === "HEALTH_TICKET" &&
                    stepsCompleted2?.map((step, index) =>
                      step?.visible ? (
                        <Step key={step.name} completed={step?.isCompleted}>
                          <StepLabel>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  margin: "5px",
                                  padding: "10px",
                                  height: "50px",
                                  width: "50px",
                                  borderRadius: "50%",
                                  border: "1px solid #000",
                                }}
                              >
                                <Box
                                  component={"img"}
                                  src={step.icon}
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                  }}
                                  alt="Icon"
                                />
                              </Box>
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: "600",
                                    fontSize: "17px",
                                    color: step.isCompleted
                                      ? "#127DDD"
                                      : "lightgrey",
                                  }}
                                >
                                  {step?.name}
                                </Typography>{" "}
                                <Typography
                                  sx={{
                                    width: "200px",
                                    fontWeight: "600",
                                    fontSize: "15px",
                                    color: "#000",
                                  }}
                                >
                                  {step?.description?.time}
                                </Typography>
                                <Typography
                                  sx={{
                                    width: "200px",
                                    fontWeight: "600",
                                    fontSize: "15px",
                                    color: "#000",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {step?.description?.remarks}
                                </Typography>
                                {step.name === "Patient Recieved" && (
                                  <IconButton
                                    sx={{ m: 0, p: 0 }}
                                    onClick={() =>
                                      window.open("tel:9971863733")
                                    }
                                  >
                                    <CallIcon sx={{ color: "#127DDD" }} />
                                  </IconButton>
                                )}
                              </Box>
                            </Box>
                          </StepLabel>
                        </Step>
                      ) : null
                    )}
                  {data?.ticketType === "ADMIN_TICKET" &&
                    stepsCompleted2?.map((step, index) =>
                      step?.visible ? (
                        <Step key={step.name} completed={step?.isCompleted}>
                          <StepLabel>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  margin: "5px",
                                  padding: "10px",
                                  height: "50px",
                                  width: "50px",
                                  borderRadius: "50%",
                                  border: "1px solid #000",
                                }}
                              >
                                <Box
                                  component={"img"}
                                  src={step.icon}
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                  }}
                                  alt="Icon"
                                />
                              </Box>
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: "600",
                                    fontSize: "17px",
                                    color: step.isCompleted
                                      ? "#127DDD"
                                      : "lightgrey",
                                  }}
                                >
                                  {step?.name}
                                </Typography>{" "}
                                <Typography
                                  sx={{
                                    width: "200px",
                                    fontWeight: "600",
                                    fontSize: "15px",
                                    color: "#000",
                                  }}
                                >
                                  {step?.description?.time}
                                </Typography>
                                <Typography
                                  sx={{
                                    width: "200px",
                                    fontWeight: "600",
                                    fontSize: "15px",
                                    color: "#000",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {step?.description?.remarks}
                                </Typography>
                                {step.name === "Patient Recieved" && (
                                  <IconButton
                                    sx={{ m: 0, p: 0 }}
                                    onClick={() =>
                                      window.open("tel:9971863733")
                                    }
                                  >
                                    <CallIcon sx={{ color: "#127DDD" }} />
                                  </IconButton>
                                )}
                              </Box>
                            </Box>
                          </StepLabel>
                        </Step>
                      ) : null
                    )}
                </Stepper>
              </MobileView>
            </Box>
          </Box>
        </Modal>
      </Portal>
    </Fragment>
  );
};

export default TicketDashboardCard;
