import { Grid } from "@mui/material";
import React, { Fragment } from "react";
import CustomTitles from "../../../../global/titles/customTitles";
import OHCOPDDashboardMain from "../../healthRecords/comps/ohcOPDDashboardMain";
import OpdDashboardMain from "./opdComp/opdDashboardMain";

const OhcOpdRecords = () => {
  return (
    <Fragment>
      <OpdDashboardMain />
    </Fragment>
  );
};

export default OhcOpdRecords;
