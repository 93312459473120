import { useLocation } from "react-router-dom";
import LoginIndex from "../modules/login/loginIndex";

const OrgLogin = () => {
  let location = useLocation();

  console.log({ location, pathname: location.pathname });
  return (
    <LoginIndex
      path={location.pathname?.toLowerCase() === "/orglogin" ? "/corporatehome" : location.pathname}
    />
  );
};
export default OrgLogin;
