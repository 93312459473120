import { createContext } from "react";

export const userContext = createContext({ user: {} });

export const headerContext = createContext({ header: {} });
export const prescriptionContext = createContext({ prescription: {} });

export const ChatContext = createContext();

export const CorprateContext = createContext();
