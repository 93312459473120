import { Avatar, Box, Card, CardContent, CardMedia, Grid, Stack, Typography } from "@mui/material";
import Doctor from "../../../assets/images/staffing/doctor.jpeg";
import DrPorusBatham from "../../../assets/images/staffing/drporusbatham.png";
import DrHarshPrajapati from "../../../assets/images/staffing/glenmarkpharmaceuticals/harshprajapati_doc_profile.jpg";

import CustomTitles from "../../../global/titles/customTitles";

const DocNameList = {
  "bbb269e5-b020-4257-ad8f-4da8c811801a": { name: "Dr. Prakhar Gite", imageUrl: Doctor }, //case
  "872cd841-9f7a-432d-b8e9-422b780bca10": { name: "Dr. Rakesh Solanki", imageUrl: Doctor },
  "13a4b8c6-750f-442a-8414-f5465481e5d9": {
    name: `Dr. Porus batham, MBBS AFIH`,
    imageUrl: DrPorusBatham,
  }, //volvo
  "323d16b9-709b-4b83-9826-c1917e7114a6": {
    name: `Dr. Harsh Prajapati`,
    imageUrl: DrHarshPrajapati,
  }, //Glenmark
};

const StaffingDoctorsMain = ({ corpId = localStorage.getItem("CORPID") }) => {
  return (
    <Box sx={{}}>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="Doctors" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display="flex"
          justifyContent="center"
          alignItems="center">
          <Typography variant="h6">Meet our qualified and experienced doctors.</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display="flex"
          justifyContent="center"
          alignItems="center">
          <Card sx={{ px: 5, py: 2 }}>
            <CardContent>
              <Stack spacing={3} display="flex" justifyContent="center" alignItems="center">
                <Avatar
                  src={DocNameList[corpId]?.imageUrl || Doctor}
                  sx={{ width: 200, height: 200 }}
                />
                <Typography variant="button">{DocNameList[corpId]?.name || ""}</Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StaffingDoctorsMain;
