import { Fragment } from "react";
import LandingHeaderNew from "../landingPageNew/global/header/landingHeaderNew";
import PrivacyDetails from "./comps/privacyDetails";

const PrivacyMain = () => {
  return (
    <Fragment>
      <LandingHeaderNew navItems={null} />
      <PrivacyDetails />
    </Fragment>
  );
};

export default PrivacyMain;
