import { Autocomplete, Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { Fragment, useEffect, useState } from "react";
import { searchByEmpIdPreEmployment } from "../services/corporateServices";
import CustomAutoComplete from "./customAutoComplete";

const SearchEmployeeAutocomplete = ({
  employees,
  employeeList,
  setEmployeeList,
  clearSearch,
  searchTerm,
  setSearchTerm,
}) => {
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const handleSearchChange = (event, value, reason) => {
    setSearchTerm(value);
    if (reason === "clear") {
      clearSearch();
    }
  };

  const handleEmployeeSelect = (event, value, reason) => {
    setSelectedEmployee(value);
    setEmployeeList([value]);
    if (reason === "clear") {
      clearSearch();
    }
  };

  console.log({ searchTerm });

  return (
    <Fragment>
      <form
        onSubmit={(e) =>
          searchByEmpIdPreEmployment(e, searchTerm.trim(), employees, setEmployeeList)
        }>
        <Grid container>
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <Box>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <CustomAutoComplete
                  freeSolo
                  fullWidth
                  disablePortal
                  options={employees}
                  getOptionLabel={(employee) => employee?.empId + " - " + employee?.name}
                  value={selectedEmployee}
                  onChange={handleEmployeeSelect}
                  inputValue={searchTerm || ""}
                  sx={{
                    "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                      fontSize: 11,
                      fontWeight: 600,
                      color: "#000",
                    },
                    "& .MuiInputBase-root": {
                      height: 36,
                    },
                  }}
                  ListboxProps={{
                    sx: { fontSize: 11, fontWeight: 600 },
                  }}
                  onInputChange={handleSearchChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      sx={{
                        "& fieldset": {
                          color: "#000",
                          borderTopLeftRadius: 10,
                          borderBottomLeftRadius: 10,
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        },
                        "& input::placeholder": {
                          color: "#000000",
                          fontWeight: 300,
                          fontSize: 13,
                        },
                      }}
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                          }
                        },
                      }}
                      placeholder="Search Employee by name or id ..."
                    />
                  )}
                  renderOption={(props, employee) => (
                    <li {...props}>
                      <div>{`${employee?.empId} - ${employee?.name}`}</div>
                    </li>
                  )}
                />
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  sx={{
                    height: 36,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                  }}>
                  {/* <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: 14,
                    color: "#FFFFFF",
                  }}>
                  Search
                </Typography> */}
                  <SearchIcon />
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default SearchEmployeeAutocomplete;
