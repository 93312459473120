import {
  Box,
  CardMedia,
  Container,
  Grid,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import {
  CustomHeadingChipCenter,
  CustomHeadingSubTitleCenter,
} from "../../global/headings/customHeadings";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import TeamCard from "./teamCard";
import bhupesh from "../../assets/images/bhupesh.png";
import vipul from "../../assets/images/vipul.png";
import vardaan from "../../assets/images/vardaan.png";

const Team = () => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
  };
  const slideList = [
    {
      id: 1,
      src: bhupesh,
      name: "BHUPESH DUA",
      designation1: "Director, SCM Planning & Perf Udaan, Flipkart, Biocon",
      education: "B.Tech, MBA, SIU",
    },
    {
      id: 2,
      src: vipul,
      name: "VIPUL KHANDELWAL",
      designation1:
        "Associate Director, SCM Product & Design Udaan, Founder Fresh O' Clock",
      education: "IIT Kanpur",
    },
    {
      id: 3,
      src: vardaan,
      name: "VARDHAN SRIVASTAVA",
      designation1: "Sr Tech Lead, Wittybrains, Tech Lead, PeopleStrong",
      education: "IIT Kanpur",
    },
  ];

  const customRenderItem = (item, props) => (
    <item.type {...item.props} {...props} />
  );

  return (
    <Box sx={{ marginBlock: "20px", marginBottom: "100px" }}>
      <Container>
        <Grid container spacing={2} sx={{ marginBottom: "50px" }}>
          <Grid item lg={12}>
            <CustomHeadingChipCenter>Our Founders</CustomHeadingChipCenter>
          </Grid>
          <Grid item lg={12}>
            <CustomHeadingSubTitleCenter>
              Meet the Leadership Team
            </CustomHeadingSubTitleCenter>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "93%" }}>
            <Carousel
              width="100%"
              showThumbs={false}
              showArrows={true}
              showStatus={false}
              autoPlay={true}
              infiniteLoop={true}
              centerMode={true}
              centerSlidePercentage={isSmallScreen ? 100 : 35}
              interval={5000}
              showIndicators={false}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <IconButton
                    title={label}
                    onClick={onClickHandler}
                    style={{
                      ...arrowStyles,
                      left: 15,
                      backgroundColor: "#FFFFFF",
                      marginTop: "10px",
                    }}
                  >
                    <ArrowBackIosIcon style={{ color: "#000000" }} />
                  </IconButton>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <IconButton
                    title={label}
                    onClick={onClickHandler}
                    style={{
                      ...arrowStyles,
                      right: 15,
                      backgroundColor: "#FFFFFF",
                      marginTop: "10px",
                    }}
                  >
                    <ArrowForwardIosIcon style={{ color: "#000000" }} />
                  </IconButton>
                )
              }
              renderItem={customRenderItem}
            >
              {slideList.map((item, index) => (
                <TeamCard key={index} data={item} />
              ))}
            </Carousel>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Team;
