import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  ImageList,
  ImageListItem,
  Portal,
  Stack,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import BackHandIcon from "@mui/icons-material/BackHand";
import DateRangeIcon from "@mui/icons-material/DateRange";
import HorizontalSplitIcon from "@mui/icons-material/HorizontalSplit";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import dayjs from "dayjs";
import { DateIcon1, NumberIcon, TypeIcon } from "../../../../../../assets/customicons/customIcons";
import { StatusListForNonFilter, TicketTypeLabel } from "../../../../../../assets/corpConstants";
import ViewImageComp from "../../comps/viewImageComp";

const ViewTicketDetailsServiceIssue = ({ data }) => {
  return (
    <Fragment>
      <Box>
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={2} rowSpacing={4}>
              <Grid item lg={4}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <NumberIcon fontSize="10" />
                    <Typography sx={{ fontSize: 10 }}>Number</Typography>
                  </Stack>
                  <Typography sx={{ fontSize: 14 }}>{data.ticketId}</Typography>
                </Stack>
              </Grid>

              <Grid item lg={4}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <TypeIcon fontSize="10" />
                    <Typography sx={{ fontSize: 10 }}>Ticket Type</Typography>
                  </Stack>
                  <Typography sx={{ fontSize: 14 }}>
                    {TicketTypeLabel[data.ticketType] || "n/a"}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item lg={4}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <BackHandIcon fontSize="10" />
                    <Typography sx={{ fontSize: 10 }}>Raised By</Typography>
                  </Stack>
                  <Typography sx={{ fontSize: 14 }}>{data.raisedBy || "n/a"}</Typography>
                </Stack>
              </Grid>

              <Grid item lg={4}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <DateIcon1 fontSize="10" />
                    <Typography sx={{ fontSize: 10 }}>Ticket Date</Typography>
                  </Stack>
                  <Typography sx={{ fontSize: 14 }}>
                    {data.date ? dayjs(data.date).format("LL") : ""}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item lg={4}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <SplitscreenIcon fontSize="10" />
                    <Typography sx={{ fontSize: 10 }}>Ticket Status</Typography>
                  </Stack>
                  <Typography sx={{ fontSize: 14 }}>
                    {StatusListForNonFilter.find((value) => value.value === data.status)?.label ||
                      "n/a"}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item lg={4}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <HorizontalSplitIcon fontSize="10" />
                    <Typography sx={{ fontSize: 10 }}>Service Name</Typography>
                  </Stack>
                  <Typography sx={{ fontSize: 14 }}>
                    {data.ticketInfo?.serviceName || "n/a"}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item lg={8}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <DateRangeIcon fontSize="10" />
                    <Typography sx={{ fontSize: 10 }}>Issue</Typography>
                  </Stack>
                  <Typography sx={{ fontSize: 14 }}>
                    {data?.ticketInfo?.sessionDate
                      ? dayjs(data?.ticketInfo?.issue).format("LL")
                      : "n/a"}
                  </Typography>
                </Stack>
              </Grid>

              {data?.attachmentDetails && data?.attachmentDetails.length > 0 && (
                <ViewImageComp imageList={data?.attachmentDetails} />
              )}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Fragment>
  );
};

export default ViewTicketDetailsServiceIssue;
