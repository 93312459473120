import { Grid } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import OhcDoughnutChart from "./ohcDoughnutChart";
import CustomBarGraphOhcStatusTracker from "./customBarGraphOhcStatusTracker";
import { convertMapToObject, convertMapToObjectSorted } from "../../../../global/actions/actions";

const CustomizedAxisTick = (props) => {
  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-60)" fontSize={10}>
        {payload.value}
      </text>
    </g>
  );
};

const StatusTrackerBarChart = ({ statusTrackerData, ohcDoughnutChartData }) => {
  const { data, title, labelHeading, setSelectedBar, dialogData, totalOpds } = ohcDoughnutChartData;
  console.log({ ohcDoughnutChartData });
  const [dataAgeGroup, setDataAgeGroup] = useState([]);
  const [dataAgeGroupGraphData, setDataAgeGroupGraphData] = useState([]);
  const [dataIllness, setDataIllness] = useState([]);
  const [dataIllnessGraphData, setDataIllnessGraphData] = useState([]);
  const [dataIllnessTop10, setDataIllnessTop10] = useState([]);
  const [dataDepartment, setDataDepartment] = useState([]);
  const [dataDepartmentTop10, setDataDepartmentTop10] = useState([]);

  useEffect(() => {
    const mapOfAge = statusTrackerData.mapOfAge;
    const mapOfAgeGraphData = statusTrackerData.mapOfAgeWithEmployeeDetails;
    const mapOfDepartment = statusTrackerData.mapOfDepartment;
    const mapOfDepartmentTop10 = statusTrackerData.mapOfDepartmentTop10;
    const mapOfIllness = statusTrackerData.mapOfIllness;
    const mapOfIllnessTop10 = statusTrackerData.mapOfIllnessTop10;
    const mapOfIllnessTop10GraphData = statusTrackerData.mapOfIllnessWithEmployeeDetails;
    setDataAgeGroupGraphData(mapOfAgeGraphData);
    setDataIllnessGraphData(mapOfIllnessTop10GraphData);
    setDataAgeGroup(convertMapToObjectSorted(mapOfAge));
    setDataDepartment(convertMapToObject(mapOfDepartment));
    setDataDepartmentTop10(convertMapToObject(mapOfDepartmentTop10));
    setDataIllness(convertMapToObject(mapOfIllness));
    setDataIllnessTop10(convertMapToObject(mapOfIllnessTop10));
  }, [statusTrackerData]);

  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const [graphTitle, setGraphTitle] = useState("");
  const [graphData, setGraphData] = useState([]);
  const [graphFill, setGraphFill] = useState("");

  console.log({ statusTrackerData });

  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <OhcDoughnutChart
            data={data}
            boxWidth={"100%"}
            boxHeight={"383px"}
            title={title}
            labelHeading={labelHeading}
            setSelectedBar={setSelectedBar}
            dialogData={dialogData}
            totalOpds={totalOpds}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomBarGraphOhcStatusTracker
            barSize={40}
            barsName={"No of Employees"}
            disableClickable={false}
            height={"380px"}
            barColor={"#82ca9d"}
            data={dataAgeGroup}
            title={"Age groups Graph"}
            dialogData={dataAgeGroupGraphData}
            drawerData={dataAgeGroup}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomBarGraphOhcStatusTracker
            barSize={40}
            barsName={"No of Employees"}
            disableClickable={false}
            height={"380px"}
            barColor={"#82ca9d"}
            data={dataIllnessTop10}
            title={"Top 10 Illness"}
            dialogData={dataIllnessGraphData}
            drawerData={dataIllness}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomBarGraphOhcStatusTracker
            barSize={40}
            barsName={"No of Employees"}
            height={"380px"}
            barColor={"#F66"}
            disableClickable={true}
            data={dataDepartmentTop10}
            drawerData={dataDepartment}
            title={"Top10 Department Graph"}
            dialogData={[]}
          />
        </Grid>
      </Grid>

      {/* <Portal>
        <Drawer anchor="bottom" variant="temporary" open={open} onClose={handleDrawerToggle}>
          <Box
            sx={{
              height: "70vh",
              width: "100%",
              mb: 10,
            }}>
            <Box sx={{ height: "300px" }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart width={730} height={250} data={graphData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    style={{ fontSize: 12 }}
                    //interval={0}
                  />
                  <YAxis />
                  <Tooltip />
                  <Legend verticalAlign="top" height={36} />
                  <Bar dataKey="value" fill={graphFill} barSize={130} name="Number of Employees">
                    <LabelList dataKey="value" position="top" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Box>
            <Stack
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center">
              <Stack width={"55%"} display={"flex"} alignItems="flex-end">
                <Typography textAlign="center">{graphTitle}</Typography>
              </Stack>
              <Stack>
                <Button onClick={handleDrawerToggle}>Close</Button>
              </Stack>
            </Stack>
          </Box>
        </Drawer>
      </Portal> */}
    </Fragment>
  );
};

export default StatusTrackerBarChart;
