import React from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import ShowTitle from "./comps/showTitle";
import { useState } from "react";
import { useFileUpload } from "use-file-upload";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"; // Import PDF icon
import axios from "axios";
import { BASE_URL } from "../../../../assets/constantsFile";
import CustomTitles from "../../../global/titles/customTitles";

const PharmacyConsumablesMain = () => {
  const corpId = localStorage.getItem("CORPID");
  const [files, selectFiles] = useFileUpload();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [remark, setRemark] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [empId, setEmpId] = useState("");

  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [openNotice, setOpenNotice] = useState(false);

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };

  const handleRemove = (index) => {
    console.log("Removing file at index:", index);
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
  };

  const handleUpload = () => {
    selectFiles({ multiple: true }, (files) => {
      // Note callback returns an array
      const updatedSelectedFiles = [...selectedFiles];
      files.map(({ source, name, size, file }) => {
        updatedSelectedFiles.push({ source, name, size, file });
      });
      setSelectedFiles(updatedSelectedFiles);
    });
  };

  console.log({ selectedFiles });

  const handleRaiseTicket = async () => {
    setIsLoading(true);
    let authHeader_local = localStorage.getItem("authHeader_local");
    let formData = new FormData();

    formData.append("empId", empId);
    formData.append("corpId", corpId);
    formData.append("remarks", remark);
    formData.append("status", "TICKET_RAISED");
    formData.append("ticketType", "CORP_CONSUMABLES");
    formData.append("ticketMode", "WEB");
    formData.append("ticketCategory", "CORP");

    selectedFiles.forEach((file, index) => {
      formData.append("file", file.file);
    });

    console.log("hiii");
    const url = BASE_URL + "org/raiseTicket";
    try {
      const result = await axios({
        method: "post",
        url: url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authHeader_local}`,
        },
      });

      if (result?.data) {
        console.log("SUCCESS", result.data);
        setSeverity("success");
        setMessage("Saved Successfully");
        setOpenNotice(true);
        setRemark("");
        setSelectedFiles([]);
        setIsLoading(false);
        setEmpId("");
      }
    } catch (error) {
      console.log("ERROR", error.response?.data);

      if (error.response && error.response.status === 400) {
        setSeverity("error");
        setMessage("Employee ID is incorrect");
      } else {
        setSeverity("error");
        setMessage("An error occurred");
      }

      setOpenNotice(true);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container>
      <Snackbar open={openNotice} autoHideDuration={1000} onClose={handleCloseNotice}>
        <Alert onClose={handleCloseNotice} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Grid container marginTop="140px">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="Raise Ticket For Consumables" />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginBlock: "10px" }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              sx={{
                fontSize: "17px",
                fontWeight: "400",
                lineHeight: "14px",
                color: "#000000",
                marginBlock: "10px",
              }}>
              Emp. ID
            </Typography>

            <TextField
              sx={{
                width: 200,
                background: "#fff",
                color: "#127DDD",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              placeholder="Enter Employee ID"
              value={empId}
              onChange={(e) => {
                setEmpId(e.target.value);
              }}
            />
          </Grid>
          <Typography
            sx={{
              fontSize: "17px",
              fontWeight: "400",
              lineHeight: "14px",
              color: "#000000",
              marginBlock: "10px",
            }}>
            Upload Images
          </Typography>

          <Button
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#FFFFFF",
              textTransform: "capitalize",
            }}
            variant="contained"
            startIcon={<AttachFileIcon style={{ color: "#FFFFFF" }} />}
            onClick={handleUpload}>
            Upload
          </Button>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: "flex" }}>
          {selectedFiles.length ? (
            selectedFiles.map((file, index) => (
              <Paper
                key={index}
                elevation={3}
                style={{
                  maxWidth: "200px",
                  marginInline: "10px",
                  marginBlock: "5px",
                  display: "flex",
                  flexWrap: "wrap",
                }}>
                <Box position="relative">
                  {file.name.toLowerCase().endsWith(".pdf") ? (
                    <Box
                      sx={{
                        height: "200px",
                        width: "150px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <PictureAsPdfIcon style={{ fontSize: 100, color: "#127DDD" }} />
                    </Box>
                  ) : (
                    <img
                      src={file.source}
                      alt={file.source}
                      style={{ maxWidth: "200px", height: "200px" }}
                    />
                  )}
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => handleRemove(index)}
                    style={{ position: "absolute", top: 5, right: 5, color: "red" }}>
                    <DeleteIcon />
                  </IconButton>
                  <Typography
                    variant="body2"
                    align="center"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "200px", // Ensure the text doesn't exceed the container width
                    }}>
                    {file.name}
                  </Typography>
                </Box>
              </Paper>
            ))
          ) : (
            <Typography>No Selected Files</Typography>
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography
            sx={{
              fontSize: "17px",
              fontWeight: "400",
              lineHeight: "14px",
              color: "#000000",
              marginBlock: "10px",
            }}>
            Remarks
          </Typography>

          <TextField
            multiline
            sx={{
              // width: 200,
              background: "#fff",
              color: "#127DDD",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
              "& input::placeholder": {
                color: "#777777",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
              },
            }}
            size="small"
            fullWidth
            placeholder="Enter Remarks"
            value={remark}
            onChange={(e) => {
              setRemark(e.target.value);
            }}
            inputProps={{
              style: {
                height: 80,
                color: "#080808",
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: " 15px",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginBlock: "10px" }}>
          <Button variant="contained" onClick={handleRaiseTicket}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PharmacyConsumablesMain;
