import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Button,
  IconButton,
  Portal,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { BASE_URL } from "../../../../assets/constantsFile";
import { updateData } from "../../../../services/api/patchApi";
import { useSnackbar } from "notistack";

const UpdateMobileModal = ({
  open,
  handleClose,
  existingMobile,
  empId,
  corpId,
}) => {
  const [mobile, setMobile] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setMobile(existingMobile);
  }, [existingMobile]);

  const handleSave = async () => {
    const url =
      BASE_URL +
      `org/updateMobileNo/${empId}?corpId=${corpId}&mobile=${mobile}`;
    const result = await updateData(url, "");
    if (result.data) {
      enqueueSnackbar("Successfully Updated Mobile Number", {
        variant: "success",
      });
      handleClose();
    } else {
      enqueueSnackbar(`${result.error.response.data.message}`, {
        variant: "error",
      });
    }
  };

  return (
    <Portal>
      <Dialog open={open} onClose={handleClose} fullWidth={true}>
        <DialogContent>
          <Box>
            <Box display="flex" justifyContent="flex-end">
              <IconButton
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <Typography
              gutterBottom
              sx={{
                textAlign: "center",
                fontWeight: "600",
                fontSize: "13px",
                lineHeight: "15px",
                color: "#000000",
                marginTop: "-25px",
                marginBottom: "10px",
              }}
            >
              Update Mobile Number
            </Typography>

            <Grid container>
              <Grid item xs={12} lg={12}>
                <TextField
                  sx={{
                    color: "#127DDD",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                    "& input::placeholder": {
                      color: "#777777",
                      fontWeight: "300",
                      fontSize: "13px",
                      lineHeight: " 15px",
                    },
                  }}
                  size="small"
                  fullWidth
                  placeholder="Enter Mobile"
                  value={mobile || ""}
                  onChange={(e) => {
                    if (!isNaN(e.target.value) && e.target.value.length < 11) {
                      setMobile(e.target.value);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", alignItems: "center" }}>
          <Button
            disabled={mobile === "" ? true : false}
            onClick={handleSave}
            variant="contained"
            sx={{ borderRadius: "10px" }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Portal>
  );
};

export default UpdateMobileModal;
