import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { Typography, Box, Divider } from "@mui/material";
import TableHeaderView from "../templateComponents/tableHeaderView";

const PrescriptionTemplateView = (props) => {
  const [formValues, setFormValues] = useState(
    props.newPrescription ? props.newPrescription : []
  );

  return (
    <Box sx={{ width: "64vw" }}>
      <Box>
        <TableHeaderView />
      </Box>

      <Box sx={{ mt: 1 }}>
        {formValues.map((element, index) => (
          <Grid container key={index} align="center">
            <Grid
              item
              lg={4}
              md={12}
              sm={12}
              xs={12}
              display="flex"
              justifyContent={"flex-start"}
            >
              <Typography noWrap variant="caption" sx={{ fontWeight: 600 }}>
                {" "}
                {element.medicineName}{" "}
              </Typography>
            </Grid>

            <Grid item lg={2} md={12} sm={12} xs={12}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                {element.m ? element.dosage : 0}&#8211;
                {element.a ? element.dosage : 0}&#8211;
                {element.n ? element.dosage : 0}
              </Typography>
            </Grid>

            <Grid item lg={1} md={12} sm={12} xs={12}>
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                {element.duration}
              </Typography>
            </Grid>

            <Grid
              item
              lg={5}
              md={12}
              sm={12}
              xs={12}
              display="flex"
              justifyContent={"flex-start"}
            >
              <Typography noWrap variant="caption" sx={{ fontWeight: 600 }}>
                {" "}
                {element.comment}{" "}
              </Typography>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider sx={{ mb: 1 }} />
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  );
};

export default PrescriptionTemplateView;
