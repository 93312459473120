import React, { useState } from "react";
import "./Carousel.css";
import { Typography } from "@mui/material";

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  return (
    <div className="header-carousel">
      <div className="owl-carousel-item">
        <img
          src={images[currentIndex]}
          alt={`Image ${currentIndex + 1}`}
          className="carousel-image"
        />
        <div className="owl-carousel-text">
          <h1 className="carousel-title">Cardiology</h1>
        </div>
      </div>
      <div className="arrow-dots-container">
        <button className="carousel-button prev" onClick={prevSlide}>
          <Typography sx={{ transform: "rotate(180deg)", fontWeight: "600", color: "#FFFFFF" }}>
            {">"}
          </Typography>
        </button>
        <div className="dots">
          {images.map((_, index) => (
            <span
              key={index}
              className={index === currentIndex ? "active-dot" : "dot"}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
        <button className="carousel-button next" onClick={nextSlide}>
          <Typography sx={{ fontWeight: "600", color: "#FFFFFF" }}>{">"}</Typography>
        </button>
      </div>
    </div>
  );
};

export default Carousel;
