import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { Fragment, useState } from "react";
import EmploymentTypeFilter from "./employmentTypeFilter";
import HealthStatusFilter from "./healthStatusFilter";
import StatusFilter from "./statusFilter";
import DepartmentFilter from "./departmentFilter";
import ShowAllFilter from "./showAllFilter";

const FilterMain = (props) => {
  const [age, setAge] = useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <Fragment>
      <Box>
        <Grid container spacing={1}>
          <Grid item lg={5}>
            <Stack direction="row" spacing={1}>
              <ShowAllFilter {...props} />
              <DepartmentFilter {...props} />
            </Stack>
          </Grid>
          <Grid item lg={2}>
            <StatusFilter {...props} />
          </Grid>
          <Grid item lg={2}>
            <EmploymentTypeFilter {...props} />
          </Grid>
          <Grid item lg={3}>
            <HealthStatusFilter {...props} />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default FilterMain;
