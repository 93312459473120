import React, { Fragment, useCallback, useState } from "react";
import { getData } from "../../../../services/api/getApi";
import { BASE_URL } from "../../../../assets/constantsFile";
import { Autocomplete, Box, Button, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";

const SearchByIdName = ({ formValues, setFormValues, corpId }) => {
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [openNotice, setOpenNotice] = useState(false);

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };

  const [searchText, setSearchText] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeID, setEmployeeID] = useState(null);

  const searchByEmpId = async (e) => {
    // e.preventDefault();
    const searchText = e && typeof e === "string" ? e : "";
    if (searchText) {
      const url = BASE_URL + `org/detailed/${searchText}?corpId=${corpId}`;
      let authHeader_local = localStorage.getItem("authHeader_local");

      try {
        const employee = await axios.get(url, {
          headers: { Authorization: "Bearer " + authHeader_local },
        });

        // Check if the response has an error status

        // Update the state with the successful response
        const list = [employee.data];
        setEmployeeList(list);
        setEmployeeID(employee.data.empId);
        setFormValues(employee.data);
      } catch (error) {
        if (error.response.status === 400) {
          // Handle the 400 error by showing a Snackbar alert
          setSeverity("error");
          setMessage("Employee Id Not found");
          setOpenNotice(true);
          setEmployeeList([]);
          setEmployeeID(null);
        }
        console.error("An error occurred:", error);
      }
    }
  };

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [oldEmployeeList, setOldEmployeeList] = useState([]);

  const fetchOldEmployee = useCallback(async (nameValue) => {
    const url = BASE_URL + `org/employee/search/${nameValue}?corpId=${corpId}`;
    const oldEmployees = await getData(url, "");
    if (oldEmployees && oldEmployees.error) {
      console.log("Error fetching employees");
    } else if (oldEmployees && oldEmployees.data) {
      console.log("Success fetching employees");
      setOldEmployeeList(oldEmployees.data);
    }
  }, []);
  const [inputValue, setInputValue] = useState("");
  return (
    <Fragment>
      <Box>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Autocomplete
            value={selectedEmployee}
            onChange={(event, value, reason) => {
              setSelectedEmployee(value);
              setSearchText(value?.empId);
              searchByEmpId(value?.empId);
              if (!value) {
                setEmployeeID(null);
                setEmployeeList([]);
                setSearchText("");
                setSelectedEmployee(null);
                setInputValue("");
              }
              if (reason === "clear") {
                setEmployeeID(null);
                setEmployeeList([]);
                setSearchText("");
                setSelectedEmployee(null);
                setInputValue("");
                setFormValues({
                  empId: "",
                  name: "",
                  mobile: "",
                  age: "",
                  gender: "",
                  issue: "",
                  remark: "",
                });
              }
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
              if (newInputValue) {
                fetchOldEmployee(newInputValue);
              }
            }}
            fullWidth
            size="small"
            options={oldEmployeeList}
            getOptionLabel={(option) =>
              option ? `${option?.empId || ""} ${option?.empName || ""}` : ""
            }
            renderOption={(props, option) => (
              <li {...props} key={option?.empId}>
                <Typography sx={{ fontSize: "15px" }}>
                  {option?.empId} {option?.empName}
                </Typography>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                sx={{
                  color: "#127DDD",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                  "& input::placeholder": {
                    color: "#777777",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                  },
                }}
                fullWidth
                variant="outlined"
                placeholder="Search employee by name or empId"
                size="small"
              />
            )}
            noOptionsText={oldEmployeeList.length === 0 ? "No Options" : null}
          />
          <Button
            onClick={() => {
              searchByEmpId(searchText);
            }}
            type="submit"
            variant="contained"
            sx={{
              marginLeft: "-2px",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              width: 180,
              px: 0,
              paddingBlock: "8px",
            }}>
            Search
          </Button>
        </Stack>
      </Box>
    </Fragment>
  );
};

export default SearchByIdName;
