import { Box, Grid } from "@mui/material";
import CustomTitles from "../../../global/titles/customTitles";
import { useEffect, useMemo, useState } from "react";
import { _getHealthChampionsList } from "../../../global/services/corporateServices";
import DisplayHealthChampions from "./comps/displayHealthChampions";
import FirstAidTabs from "./comps/firstAidTabs";
import FirstAid from "./comps/firstAid";

const FaHealthChampionsMain = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [healthChampionsList, setHealthChampionsList] = useState([]);

  useEffect(() => {
    _getHealthChampionsList(corpId, setHealthChampionsList);
  }, []);

  const filteredFirstAid = useMemo(() => {
    return healthChampionsList.filter((item) => item.firstAidUrl);
  }, [healthChampionsList]);

  const filteredFireSafety = useMemo(() => {
    return healthChampionsList.filter((item) => item.fireSafetyUrl);
  }, [healthChampionsList]);

  return (
    <Box>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="First Aid Health Champions" />
        </Grid>
        {corpId === "c8983984-8bbb-4783-8e43-8bf8193ca04e" ||
        corpId === "2a689e0e-3621-4b3d-bc5d-a6a6c64b9916" ||
        corpId === "872cd841-9f7a-432d-b8e9-422b780bca10" ? (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <FirstAidTabs
              fireSafety={filteredFireSafety}
              firstAid={filteredFirstAid}
            />
            {/* <FirstAid healthChampionsList={filteredFirstAid} /> */}
          </Grid>
        ) : (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <DisplayHealthChampions healthChampionsList={healthChampionsList} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default FaHealthChampionsMain;
