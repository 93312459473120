import React, { Component } from 'react';
import MyAppBarBeforeLogin from '../common/header/myAppBarBeforeLogin';

const Location = () => {
    return (
        <MyAppBarBeforeLogin/>
    );
}
 
export default Location;
