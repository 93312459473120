// import { Box, Grid } from "@mui/material";
// import AboutImg1 from "../../assets/images/about-1.jpg";
// import AboutImg2 from "../../assets/images/about-2.jpg";

// const AboutUsLeft = () => {
//   return (
// <Grid container>
//   <Grid item display="flex" justifyContent="flex-start" alignItems="flex-end">
//         <Box
//           sx={{
//             position: "relative",
//             left: 100,
//             top: 100,
//             backgroundColor: "#fff",
//             p: 2,
//             borderRadius: 3,
//           }}>
//           <Box
//             component="img"
//             alt="card"
//             src={AboutImg2}
//             sx={{
//               display: "flex",
//               width: "100%",
//               borderRadius: 3,
//             }}
//           />
//         </Box>
//         <Box>
//           <Box
//             component="img"
//             alt="card"
//             src={AboutImg1}
//             sx={{
//               display: "flex",
//               width: "100%",
//               borderRadius: 3,
//             }}
//           />
//         </Box>
//       </Grid>
//     </Grid>
//   );
// };

// export default AboutUsLeft;

import { Box, Grid } from "@mui/material";
import AboutImg1 from "../../assets/images/about-1.jpg";
import AboutImg2 from "../../assets/images/about-2.jpg";
import { Fragment } from "react";

const AboutUsLeft = () => {
  return (
    <Grid container sx={{ marginBottom: 12 }}>
      <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
        <Box
          sx={{
            position: "relative",
            width: "100%",
            borderRadius: 3,
          }}>
          <img
            src={AboutImg1}
            alt="card"
            style={{
              width: "100%",
              display: "block",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: "-120px",
              left: "-90px",
              width: "55%", // Adjust the width as needed
              borderRadius: 3,
              padding: 2,
              backgroundColor: "white",
            }}>
            <img
              src={AboutImg2}
              alt="card"
              style={{
                width: "100%",
                display: "block",
              }}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AboutUsLeft;
