import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Portal,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Male from "../../../assets/images/male.jpeg";
import Female from "../../../assets/images/female.jpeg";
import Other from "../../../assets/images/other.jpeg";
import Mappin from "../../../assets/images/mappin.png";
import Mail from "../../../assets/images/mail.png";
import Phonecall from "../../../assets/images/phonecall.png";
import { useLocation, useNavigate } from "react-router";
import { Fragment, useEffect, useState } from "react";
import VitalCompRx from "./vitalCompRx";
import VitalCompInjury from "./vitalCompInjury";
import VitalCompAHC from "./vitalCompAHC";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getData } from "../../../../services/api/getApi";
import { BASE_URL } from "../../../../assets/constantsFile";
import { getFormattedDDMonthYYYY, getUrlExtension } from "../../../../assets/utils";
import {
  getDataGridConfig,
  getEmployeeLifeCycleData,
} from "../../../global/services/corporateServices";
import VitalsGraphs from "../vitalsGraphs/vitalsGraphs";
import UpdateMobileModal from "./updateMobileModal";
import { PhotoViewer } from "../../../../assets/photoViewer";

const ProfileComp = ({ corpId = localStorage.getItem("CORPID"), employeeID, isBack }) => {
  const location = useLocation();

  const [openMobileUpdate, setOpenMobileUpdate] = useState(false);
  const handleCloseMobileUpdate = () => {
    setOpenMobileUpdate(false);
  };

  const [employee, setEmployee] = useState("");

  const searchByEmpId = async () => {
    const url =
      BASE_URL +
      `org/detailed/${employeeID ? employeeID : location?.state?.employeeId}?corpId=${corpId}`;

    const employee = await getData(url, "");

    if (employee.error) {
    } else {
      setEmployee(employee.data);

      console.log({ dta: employee.data });
    }
  };

  useEffect(() => {
    searchByEmpId();
  }, [openMobileUpdate]);

  const [open, setOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(false);

  const handleClickOpen = (url) => {
    // const ext = getUrlExtension(url).toLowerCase();
    // if (ext === "pdf") {
    //   // window.open(url);
    //   <PdfViewerModal open={open} pdfUrl={url} handleClose={handleClose} />;
    // } else {
    //   setOpen(true);
    // }
    setOpen(true);
    setImageUrl(url);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [selectedImage, setSelectedImage] = useState("");

  const currentPath = location.pathname;

  console.log({ currentPath });

  const [employeeData1, setEmployeeData1] = useState([]);

  useEffect(() => {
    getEmployeeLifeCycleData(
      corpId,
      employeeID || location?.state?.employeeId,
      "",
      "",
      setEmployeeData1
    );
  }, [currentPath]);

  console.log({ employeeData1 });

  let navigate = useNavigate();

  const [config, setConfig] = useState([]);

  useEffect(() => {
    getDataGridConfig(corpId, setConfig, "AHC_HEALTH_RECORDS_PAGE");
  }, []);
  return (
    <Fragment>
      {/* <Portal>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={"xl"}
          fullWidth={true}
        >
          <DialogContent>
            <Box
              sx={{ minHeight: "70vh" }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {selectedImage ? (
                <img width="100%" src={selectedImage} />
              ) : (
                "No Document."
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Portal> */}
      <PhotoViewer url={imageUrl} open={open} handleClose={handleClose} />
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          backgroundColor: "#F5F5F5",
          mt:
            currentPath === "/corporatehome/employeeprofile"
              ? 18
              : currentPath === "/corporatehome/employeedetails/employeelifecycle"
              ? 2
              : null,

          mb: 3,
        }}>
        {isBack && (
          <IconButton sx={{ py: 0 }} onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <Box sx={{ pt: 2 }}>
          <Grid container spacing={2}>
            <Grid item lg={5}>
              <Box
                sx={{
                  p: 2,
                  background: "#FFFFFF",
                  borderRadius: 3,
                }}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    lg={4}
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center">
                    {employee?.imageUrl ? (
                      <img
                        src={employee?.imageUrl}
                        style={{ height: 200, width: "90%", borderRadius: 2 }}
                      />
                    ) : (
                      <Avatar sx={{ height: 140, width: 140, fontSize: 46 }}>
                        {employee?.name?.charAt(0)}
                      </Avatar>
                    )}
                  </Grid>

                  <Grid item lg={8} xs={12}>
                    <Stack direction="row" justifyContent="center" alignItems="center">
                      <Box sx={{ px: 2 }}>
                        <Grid container spacing={1}>
                          <Grid item lg={12}>
                            <Typography
                              sx={{
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: "500",
                                fontSize: 20,
                                color: "#383838",
                              }}>
                              {employee?.name}
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: 12,
                                color: "#6B6B6B",
                              }}>
                              {employee?.age ? `Age - ${employee?.age}` : ""}
                            </Typography>
                          </Grid>
                          <Grid item lg={12}>
                            <Stack direction="row" justifyContent="space-between">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Box
                                  component="img"
                                  alt="Male"
                                  src={
                                    employee?.gender?.toLowerCase() === "male"
                                      ? Male
                                      : employee?.gender?.toLowerCase() === "female"
                                      ? Female
                                      : Other
                                  }
                                  sx={{ height: 12, width: 12 }}
                                />
                                <Typography
                                  sx={{
                                    fontFamily: "Roboto",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: 12,
                                    color: "#6B6B6B",
                                  }}>
                                  {employee?.gender || "N/A"}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Box
                                  component="img"
                                  alt="Mappin"
                                  src={Mappin}
                                  sx={{ height: 12, width: 12 }}
                                />
                                <Typography
                                  sx={{
                                    fontFamily: "Roboto",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: 12,
                                    color: "#6B6B6B",
                                  }}>
                                  {employee?.city || "N/A"}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Grid>
                          <Grid item lg={12}>
                            <Stack direction="row" justifyContent="space-between">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Box
                                  component="img"
                                  alt="Mail"
                                  src={Mail}
                                  sx={{ height: 12, width: 12 }}
                                />
                                <Typography
                                  sx={{
                                    fontFamily: "Roboto",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: 12,
                                    color: "#6B6B6B",
                                  }}>
                                  {employee?.email || "N/A"}
                                </Typography>
                              </Stack>
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Box
                                  component="img"
                                  alt="Phonecall"
                                  src={Phonecall}
                                  sx={{ height: 12, width: 12 }}
                                />
                                <Typography
                                  sx={{
                                    fontFamily: "Roboto",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: 12,
                                    color: "#6B6B6B",
                                  }}>
                                  {employee?.mobile}
                                </Typography>
                                <Tooltip title="Edit phone number">
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      setOpenMobileUpdate(true);
                                    }}>
                                    <EditIcon
                                      sx={{
                                        color: "red",
                                        height: "17px",
                                        width: "17px",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item lg={12}>
                    <Box sx={{ border: ".5px solid #BCBCBC", mt: 2, mb: 2 }} />
                  </Grid>
                  <Grid item lg={12}>
                    <Box>
                      <Grid container spacing={1}>
                        <Grid item lg={3}>
                          <Typography
                            sx={{
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: 12,
                              color: "#8A8A8A",
                            }}>
                            Designation
                          </Typography>
                        </Grid>
                        <Grid item lg={3}>
                          <Typography
                            sx={{
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: 12,
                              color: "#8A8A8A",
                            }}>
                            Department
                          </Typography>
                        </Grid>
                        <Grid item lg={3}>
                          <Typography
                            sx={{
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: 12,
                              color: "#8A8A8A",
                            }}>
                            Reporting To
                          </Typography>
                        </Grid>
                        <Grid item lg={3}>
                          <Typography
                            sx={{
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "500",
                              fontSize: 12,
                              color: "#8A8A8A",
                            }}>
                            Emp. ID
                          </Typography>
                        </Grid>
                        <Grid item lg={3}>
                          <Typography
                            sx={{
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: 11,
                              color: "#000000",
                            }}>
                            {employee?.designation || "N/A"}
                          </Typography>
                        </Grid>
                        <Grid item lg={3}>
                          <Typography
                            sx={{
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: 11,
                              color: "#000000",
                            }}>
                            {employee?.department}
                          </Typography>
                        </Grid>
                        <Grid item lg={3}>
                          <Typography
                            sx={{
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: 11,
                              color: "#000000",
                            }}>
                            {employee?.reportingTo || "N/A"}
                          </Typography>
                        </Grid>
                        <Grid item lg={3}>
                          <Typography
                            sx={{
                              fontFamily: "Roboto",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: 11,
                              color: "#000000",
                            }}>
                            {employee?.empId}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="center"
                    sx={{ mt: { lg: 20, xs: 0 } }}>
                    <Typography sx={{ color: "red" }}>
                      After editing mobile, please logout and login again on mobile app to see
                      reports.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item lg={7}>
              <Box
                sx={{
                  p: 2,
                  background: "#FFFFFF",
                  borderRadius: 3,
                  height: isBack ? "77vh" : "65vh",
                  overflow: "auto",
                }}>
                <Box>
                  <Grid container>
                    {corpId === "872cd841-9f7a-432d-b8e9-422b780bca10" &&
                      employeeData1 &&
                      employeeData1.length > 1 && (
                        <Grid item lg={12}>
                          <VitalsGraphs employeeData={employeeData1} />
                        </Grid>
                      )}
                    {employeeData1?.map((item, index) => (
                      <Grid item lg={12} key={index}>
                        <Box
                          sx={{
                            background: "#F4FBFF",
                            borderRadius: 3,
                            p: 2,
                          }}>
                          <Box
                            sx={{
                              background: "#C5ECFF",
                              borderRadius: 3,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}>
                            <Typography
                              sx={{
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: "500",
                                fontSize: 20,
                                textTransform: "capitalize",
                                color: "#127DDD",
                              }}>
                              {item?.employeeLifecycleType}
                              {item?.date && `: ${getFormattedDDMonthYYYY(new Date(item?.date))}`}
                            </Typography>
                          </Box>

                          {item?.employeeLifecycleType === "OHC_RX" && (
                            <VitalCompRx data={item?.orgDailyTrendsEntity} />
                          )}

                          {item?.employeeLifecycleType === "OHC_INJURY" && (
                            <VitalCompInjury data={item?.orgDailyTrendsEntity} />
                          )}

                          {(item?.employeeLifecycleType === "AHC" ||
                            item?.employeeLifecycleType === "PRE_EMPLOYMENT") && (
                            <VitalCompAHC
                              data={item?.orgEmployeesVitalsEntity}
                              handleClickOpen={handleClickOpen}
                              setSelectedImage={setSelectedImage}
                              employeeID={employeeID}
                              ahcConfig={config}
                            />
                          )}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <UpdateMobileModal
        open={openMobileUpdate}
        handleClose={handleCloseMobileUpdate}
        existingMobile={employee?.mobile}
        empId={employeeID}
        corpId={corpId}
      />
    </Fragment>
  );
};

export default ProfileComp;
