import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import Rx from "./rx/Rx";
import Tests from "./tests/Tests";
import Notes from "./notes/Notes";

const WritePrescriptionTab = ({ tabvalue, setTabValue }) => {
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("WRITE_PRESCRIPTION_TAB_VALUES_CMS", newValue);
  };

  useEffect(() => {
    const savedTab =
      typeof localStorage !== "undefined"
        ? localStorage.getItem("WRITE_PRESCRIPTION_TAB_VALUES_CMS")
        : null;
    if (savedTab) {
      setValue(savedTab);
    }
  }, []);

  const [patients, setPatients] = useState(null);

  // const fetchPatientDetail = async () => {
  //   const url = BASE_URL + "cms/detailed/appointment/" + appointmentId;
  //   const response = await getData(url);
  //   if (response?.data) {
  //     console.log({ DETAILED_DATA: response?.data });
  //     setPatients(response?.data);
  //   } else {
  //     console.log({ DETAILED_DATA: response?.error });
  //   }
  // };

  // console.log({ OKOK: patients });

  // useEffect(() => {
  //   fetchPatientDetail();
  // }, [appointmentId, tabvalue]);

  return (
    <Box>
      <TabContext value={value}>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <TabList
            TabIndicatorProps={{ style: { display: "none" } }}
            onChange={handleChange}
            centered
            sx={{
              padding: "10px",
              backgroundColor: "#FFFFFF",
              borderRadius: "30px",
              width: "300px",
              border: "1px solid #777777",
              ".Mui-selected": {
                backgroundColor: "#127DDD",
                fontWeight: "500",
                height: 2,
                "& p": {
                  color: "#127DDD !important",
                },
              },
            }}>
            <Tab
              label="Rx"
              sx={styles.tab}
              value="1"
              onClick={(e) => {
                setTabValue(!tabvalue);
              }}
            />
            <Tab
              label="Tests"
              sx={styles.tab}
              value="2"
              onClick={(e) => {
                setTabValue(!tabvalue);
              }}
            />
            <Tab
              label="Notes"
              sx={styles.tab}
              value="3"
              onClick={(e) => {
                setTabValue(!tabvalue);
              }}
            />
          </TabList>
        </Box>

        <Box>
          <TabPanel value="1" sx={{ px: 0, py: 0 }}>
            <Rx patients={patients} />
          </TabPanel>

          <TabPanel value="2" sx={{ px: 0, py: 0 }}>
            <Tests patients={patients} />
          </TabPanel>

          <TabPanel value="3" sx={{ px: 0, py: 0 }}>
            <Notes patients={patients} />
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
};

export default WritePrescriptionTab;

const styles = {
  tab: {
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "15px",
    color: "#8A8A8A",
    textTransform: "none",
  },
};
