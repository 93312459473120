import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import Imge from "../../assets/images/logos/logo_print.svg";
const PrintFooter = (props) => {
  const docDetail = props.docDetail;

  return (
    <Grid container>
      <Grid container item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>


        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box>
            <Typography
              sx={{ fontWeight: 400, fontSize: "15px", }}
            >
              This Document is NOT valid for legal purposes. It is generated
              under good faith solely for treatment of client, as per the verbal
              information provided by Informants/Client self.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PrintFooter;
