import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import SearchByIdName from "../subComps/searchByIdName";

const RaiseTicketForm = ({
  formValues,
  setFormValues,
  corpId = localStorage.getItem("CORPID"),
  handleRaiseTicket,
  ticketType,
  isLoading,
  setIsLoading,
}) => {
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Fragment>
      <form onSubmit={handleRaiseTicket}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <Typography
              sx={{
                fontSize: "17px",
                fontWeight: "400",
                lineHeight: "14px",
                color: "#000000",
                marginBlock: "10px",
              }}>
              Emp. ID
            </Typography>
            <SearchByIdName formValues={formValues} setFormValues={setFormValues} corpId={corpId} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Typography
              sx={{
                fontSize: "17px",
                fontWeight: "400",
                lineHeight: "14px",
                color: "#000000",
                marginBlock: "10px",
              }}>
              Name
            </Typography>
            <TextField
              required
              sx={{
                color: "#127DDD",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              placeholder="Enter Name"
              value={formValues.name || ""}
              onChange={(e) => {
                setFormValues({ ...formValues, name: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <Typography
              sx={{
                fontSize: "17px",
                fontWeight: "400",
                lineHeight: "14px",
                color: "#000000",
                marginBlock: "10px",
              }}>
              Mobile
            </Typography>
            <TextField
              sx={{
                color: "#127DDD",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              placeholder="Enter Mobile"
              value={formValues.mobile || ""}
              onChange={(e) => {
                if (!isNaN(e.target.value) && e.target.value.length < 11) {
                  setFormValues({
                    ...formValues,
                    mobile: e.target.value,
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={4} sm={12} md={12} lg={3}>
            <Typography
              sx={{
                fontSize: "17px",
                fontWeight: "400",
                lineHeight: "14px",
                color: "#000000",
                marginBlock: "10px",
              }}>
              Age
            </Typography>
            <TextField
              sx={{
                color: "#127DDD",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              placeholder="Enter Age"
              value={formValues.age || ""}
              onChange={(e) => {
                if (!isNaN(e.target.value) && e.target.value.length < 4) {
                  setFormValues({
                    ...formValues,
                    age: e.target.value,
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={8} sm={12} md={12} lg={3}>
            <Typography
              sx={{
                fontSize: "17px",
                fontWeight: "400",
                lineHeight: "14px",
                color: "#000000",
                marginBlock: "10px",
              }}>
              Gender
            </Typography>

            <Autocomplete
              fullWidth
              value={formValues.gender || null}
              onChange={(event, newValue) => {
                let newFormValues = { ...formValues };
                newFormValues["gender"] = newValue;
                setFormValues(newFormValues);
              }}
              size="small"
              disablePortal
              options={["MALE", "FEMALE", "DONOTDISCLOSE", "OTHER"]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder="Select Gender"
                  sx={{
                    color: "#127DDD",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                    "& input::placeholder": {
                      color: "#777777",
                      fontWeight: "300",
                      fontSize: "13px",
                      lineHeight: " 15px",
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={8} sm={12} md={12} lg={3}>
            <Typography
              sx={{
                fontSize: "17px",
                fontWeight: "400",
                lineHeight: "14px",
                color: "#000000",
                marginBlock: "10px",
              }}>
              Ticket Type
            </Typography>

            <TextField value={ticketType} size="small" fullWidth />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              sx={{
                fontSize: "17px",
                fontWeight: "400",
                lineHeight: "14px",
                color: "#000000",
                marginBlock: "10px",
              }}>
              Remarks
            </Typography>

            <TextField
              multiline
              sx={{
                color: "#127DDD",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              placeholder="Enter Remarks"
              value={formValues.remark || ""}
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  remark: e.target.value,
                });
              }}
              inputProps={{
                style: {
                  height: 80,
                  color: "#080808",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginBlock: "10px" }}>
            <Button variant="contained" type="submit">
              Raise Request
            </Button>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default RaiseTicketForm;
