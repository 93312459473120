import { Box, Grid } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ImageGallerySliderComp from "./imageGallerySliderComp";
import { useEffect, useState } from "react";
import { _getPhotos } from "../../../../global/services/corporateServices";

const DisplayImages = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    _getPhotos(corpId, "FIRST_AID", setImageList);
  }, []);
  return (
    <Box sx={{ height: "80vh", p: 1 }} display="flex" justifyContent="center" alignItems="center">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ImageGallerySliderComp slideList={imageList} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DisplayImages;
