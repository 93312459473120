import { Box, Grid, Typography } from "@mui/material";
import CustomBarChart from "../../../global/customCharts/customBarChart";
import { BASE_URL } from "../../../../assets/constantsFile";
import { getData } from "../../../../services/api/getApi";
import { useEffect, useState } from "react";
import PEMonthlyCharts from "./comps/peMonthlyCharts";
import PEDeatiledData from "./comps/peDeatiledData";
import PeStatusTracker from "./comps/peStatusTracker";
import CustomTitles from "../../../global/titles/customTitles";
import NewRequestformMain from "../newRequestForm/newRequestformMain";

const PreEmpStatusTrackerMain = ({ corpId = localStorage.getItem("CORPID") }) => {
  // "Exception for Case Construction"
  if (corpId === "bbb269e5-b020-4257-ad8f-4da8c811801a") {
    corpId = "872cd841-9f7a-432d-b8e9-422b780bca10";
  }
  return (
    <Box sx={{ background: "#f5f5f5" }}>
      <Grid container rowSpacing={1}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="" />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {/* {isGraph ? (
            <PEMonthlyCharts setIsGraph={setIsGraph} setSelectedMonth={setSelectedMonth} />
          ) : (
            <PEDeatiledData setIsGraph={setIsGraph} selectedMonth={selectedMonth} />
          )} */}
          {/* <NewRequestformMain corpId={corpId} /> */}
          <PeStatusTracker corpId={corpId} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PreEmpStatusTrackerMain;
