import { Box, Button, Grid, Stack, TextField } from "@mui/material";
import { Fragment, useState } from "react";
import {
  CustomTypographyParagraph,
  CustomTypographyParagraphClient,
} from "./customTypography";
import { BASE_URL } from "../../assets/constantsFile";
import { saveData } from "../../services/api/postApi";
import EmailComp from "./emailComp";
import { useNavigate } from "react-router";

const defaultText =
  "Thank you for your querry. we will shorlty connect with you! 👋";

const preDefinedHandles = [
  {
    id: 0,
    label: "About Us",
    value: "Help me know about UNO.care?",
  },
  {
    id: 1,
    label: "What we do?",
    value: "What UNO.care really does?",
  },
  {
    id: 2,
    label: "Contact us",
    value: "Let me connect with UNO.care for more information?",
  },
  {
    id: 3,
    label: "Raise Request",
    url: "/corporatehome/tickets/healthservice",
  },
];

const preDefinedResponses = {
  "About Us": {
    id: 0,
    label: "About Us",
    value: "Help me know about UNO.care?",
    answer: (
      <CustomTypographyParagraph>
        We are a initiative to revolutionize the healthcare sector.
      </CustomTypographyParagraph>
    ),
  },
  "What we do?": {
    id: 1,
    label: "What we do?",
    value: "What UNO.care really does?",
    answer: (
      <CustomTypographyParagraph>
        We provide solutions to meet specific needs.
      </CustomTypographyParagraph>
    ),
  },
  "Contact us": {
    id: 2,
    label: "Contact us",
    value: "Let me connect with UNO.care for more information?",
    answer: (
      <CustomTypographyParagraph>
        Type your message below...
      </CustomTypographyParagraph>
    ),
  },
};

const ChatPredefinedHandles = ({
  chatStack,
  setChatStack,
  disableMessgaeArea,
  setDisableMessgaeArea,
}) => {
  const navigate = useNavigate();

  const _handleChange = async (value) => {
    if (value?.label === "Contact us") {
      setDisableMessgaeArea(false);
    } else if (value.url) {
      navigate(value?.url);
    } else {
      setDisableMessgaeArea(true);
    }

    let client = {
      id: 1,
      chat: value?.value,
    };
    let bot = {
      id: 1,
      chat: preDefinedResponses[value?.label]?.answer,
    };

    let newChatStack = [...chatStack, ...[{ bot: bot, client: client }]];
    setChatStack(newChatStack);
  };
  return (
    <Fragment>
      <Grid item lg={12}>
        <Box>
          <Stack
            display="flex"
            direction="row"
            useFlexGap
            flexWrap="wrap"
            spacing={2}
          >
            {preDefinedHandles?.map((val, index) => (
              <Button
                key={index}
                onClick={() => _handleChange(val)}
                variant="outlined"
                sx={{
                  background: "rgb(255, 255, 255)",
                  borderRadius: 4,
                  textTransform: "capitalize",
                }}
              >
                {val?.label}
              </Button>
            ))}
          </Stack>
        </Box>
      </Grid>
    </Fragment>
  );
};

export default ChatPredefinedHandles;
