import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import {
  calculateSessionDuration,
  formatCustomDateTime,
  getMonthName,
} from "../../../../assets/utils";
import CloseIcon from "@mui/icons-material/Close";
import { getData } from "../../../../services/api/getApi";
import { BASE_URL } from "../../../../assets/constantsFile";
import CustomTitles from "../../../global/titles/customTitles";

const mergeTicketsWithPhotos = (tickets, photos) => {
  // Create a map to easily group photos by ticketId
  const photoMap = photos.reduce((map, photo) => {
    if (!map[photo.ticketId]) {
      map[photo.ticketId] = [];
    }
    map[photo.ticketId].push(photo);
    return map;
  }, {});
  // Merge ticket details with their respective photos
  return tickets.map((ticket) => ({
    ...ticket,
    photos: photoMap[ticket.ticketId] || [],
  }));
};

const mergeTicketsPhtotoWithSessions = (ticketsWithPhotos, sessions) => {
  // Create a map to easily access session details by session ID
  const sessionMap = sessions.reduce((map, session) => {
    map[session.id] = session;
    return map;
  }, {});

  console.log({ sessionMap, ticketsWithPhotos, sessions });

  // Merge ticket details with their respective session details
  return ticketsWithPhotos.map((ticket) => ({
    ...ticket,
    session: sessionMap[ticket.ticketInfo.sessionId] || null,
  }));
};

const Card = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const downloadImages = async () => {
    const zip = new JSZip();
    const imgFolder = zip.folder("images");
    if (data?.clips) {
      for (let i = 0; i < data?.clips?.length; i++) {
        const url = data.clips[i].photosUrl;
        console.log({ url });
        const response = await fetch(url, {
          mode: "no-cors",
        });
        const blob = await response.blob();
        const fileName = url?.split("/").pop();
        imgFolder.file(fileName, blob);
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, "session_clips.zip");
      });
    }
  };
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <Typography sx={styles.month}>{data?.month}</Typography>
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10}>
          <Typography sx={styles.fieldName}>{data?.sessionName}</Typography>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <Typography sx={styles.fieldName}>Session Start Date</Typography>
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10}>
          <Typography sx={styles.fieldName}>
            {formatCustomDateTime(data.sessionStartDate)}
          </Typography>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <Typography sx={styles.fieldName}>Session End Date</Typography>
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10}>
          <Typography sx={styles.fieldName}>
            {formatCustomDateTime(data.sessionEndDate)}
          </Typography>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <Typography sx={styles.fieldName}>About Session</Typography>
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10}>
          <Typography sx={styles.fieldName}>
            <AccessTimeIcon sx={{ marginRight: 1 }} />{" "}
            {calculateSessionDuration(
              data?.sessionStartDate,
              data?.sessionEndDate
            )}
          </Typography>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <Typography sx={styles.fieldName}>Doctor</Typography>
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10}>
          <Typography sx={(styles.fieldName, { textTransform: "capitalize" })}>
            {data?.doctorName}
          </Typography>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <Typography sx={styles.fieldName}>
            Know your heart health -
          </Typography>
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10}>
          <Typography sx={styles.fieldName}>{data?.detail}</Typography>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <Typography sx={styles.fieldName}>Session Clips -</Typography>
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10}>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {data?.clips.map((item, index) => (
              <Box
                component={"img"}
                onClick={() => {
                  setOpen(true);
                  setSelectedImage(item.photosUrl);
                }}
                key={index}
                src={item?.photosUrl}
                style={{
                  height: 65,
                  width: 65,
                  borderRadius: 5,
                  cursor: "pointer",
                  border: "1px solid #000000",
                }}
              />
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              m: 3,
            }}
          >
            <Button startIcon={<SaveAltIcon />} onClick={downloadImages}>
              <Typography sx={styles.download}>Download All</Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setOpen(false)}
            aria-label="close"
            sx={{ position: "absolute", right: 12, top: 5 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedImage && (
            <img src={selectedImage} alt="Selected" style={{ width: "100%" }} />
          )}
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

const CompletedSessionMain = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [startDate, setStartDate] = useState(
    dayjs().startOf("year").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    dayjs().endOf("year").format("YYYY-MM-DD")
  );

  const [finalArray, setFinalArray] = useState([]);

  const fetchData = async () => {
    const url1 =
      BASE_URL +
      `org/ticket/all?corpId=${corpId}&startDate=${startDate}&endDate=${endDate}`;
    const url2 =
      BASE_URL +
      `org/photos?corpId=${corpId}&corpUploadSubType=AWARENESS_SESSION`;
    const url3 = BASE_URL + `org/awarenessSessions/list`;

    const [result1, result2, result3] = await Promise.all([
      getData(url1),
      getData(url2),
      getData(url3),
    ]);

    if (result1.error || result2.error || result3.error) {
      console.log(result1.error || result2.error || result3.error);
      setFinalArray([]);
      return;
    }

    const completedSession = result1?.data?.filter(
      (item) =>
        item.ticketType === "HEALTH_AWARENESS" && item.status === "COMPLETED"
    );
    const sessionPhotos = result2.data;

    const ticketsWithPhotos = mergeTicketsWithPhotos(
      completedSession,
      sessionPhotos
    );
    const finalData = mergeTicketsPhtotoWithSessions(
      ticketsWithPhotos,
      result3.data
    );

    const temp = finalData.map((item, index) => ({
      ticketId: item?.ticketId,
      sessionName: item?.session?.sessionName,
      month: getMonthName(dayjs(item?.ticketInfo?.sessionDate).month()),
      detail: item?.session?.description,
      duration: item?.session?.duration,
      clips: item?.photos,
      sessionDate: item?.ticketInfo?.sessionDate,
      doctorName: item?.ticketInfo?.doctorName,
      sessionName: item?.ticketInfo?.sessionName,
      sessionEndDate: item?.ticketInfo?.sessionEndDate,
      sessionStartDate: item?.ticketInfo?.sessionStartDate,
    }));

    setFinalArray(temp);
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log({ Clips: finalArray?.clips });

  return (
    <Fragment>
      <Grid container spacing={2} sx={{ mt: 10 }}>
        {finalArray?.map((item, index) => (
          <Grid key={index} item xs={12} sm={12} md={12} lg={12}>
            <Card data={item} />
            {finalArray.length - 1 === index ? null : (
              <Divider variant="fullWidth" />
            )}
          </Grid>
        ))}
        {finalArray.length === 0 && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              minHeight: "40vh",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              No Completed Session Yet...
            </Typography>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

export default CompletedSessionMain;

const styles = {
  month: {
    marginRight: "5px",
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "110%",
    letterSpacing: "-0.285px",
  },
  fieldName: {
    marginRight: "5px",
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "110%",
    letterSpacing: "-0.285px",
    display: "flex",
    alignItems: "center",
  },
  download: {
    marginRight: "5px",
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "110%",
    letterSpacing: "-0.285px",
    textTransform: "capitalize",
  },
};
