import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  Link,
} from "@mui/material";
import { Fragment, useState } from "react";
import { getFormattedDDMonthYYYY } from "../../../../assets/utils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { saveData } from "../../../../services/api/postApi";
import { BASE_URL } from "../../../../assets/constantsFile";
import RightIcon from "../../../../assets/images/svg/rightIcon.svg";
import ReportIcon from "../../../../assets/images/svg/reportIcon.svg";

const ReportListComp = ({ reportItems, patientId, appointmentId, caseId }) => {
  console.log({ appointmentId: appointmentId });
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteReport = (e) => {
    e.preventDefault();
    reportItems.splice(selectedIndex, 1);
    console.log({ "remaining Arr": reportItems });
    let o = {
      reports: reportItems,
      patientId: patientId,
      docId: localStorage.getItem("DOCID"),
      appointmentId: appointmentId,
      caseId: caseId,
    };

    console.log({ "report object to be saved": o });
    saveTest(o);
  };

  const saveTest = async (data) => {
    const url = BASE_URL + "doctor/prescription/tests";
    const user = await saveData(url, data, "");

    if (user.error) {
      console.log({ "error saving": user.error });
    } else {
      handleClose();
      console.log({ " saved successfully": user.data });
    }
  };
  const dialog = (
    <Dialog
      fullWidth
      maxWidth={"xs"}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" align="center">
        Do you want to delete report?
      </DialogTitle>

      <DialogContent dividers>
        {selectedItem ? (
          <Grid
            container
            columns={15}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Grid item lg={3} md={15} sm={15} xs={15}>
              <Typography
                variant="body2"
                sx={{ fontWeight: "500", fontSize: ".70rem" }}
              >
                {selectedItem.reportName}
              </Typography>
            </Grid>

            <Grid item lg={2} md={15} sm={15} xs={15}>
              <Typography
                variant="body2"
                sx={{ fontWeight: "500", fontSize: ".70rem" }}
              >
                {selectedItem.reportFileType}
              </Typography>
            </Grid>

            <Grid item lg={3} md={15} sm={15} xs={15}>
              <Typography
                variant="body2"
                sx={{ fontWeight: "500", fontSize: ".70rem" }}
              >
                {getFormattedDDMonthYYYY(
                  new Date(selectedItem.prescribedDate || new Date())
                )}
              </Typography>
            </Grid>
            <Grid item lg={6} md={15} sm={15} xs={15}>
              <Chip
                component="a"
                size="small"
                icon={<VisibilityIcon fontSize="small" />}
                label={selectedItem.reportURL ? "view" : "pending"}
                color={selectedItem.reportURL ? "primary" : "default"}
                disabled={selectedItem.reportURL ? false : true}
                href={
                  selectedItem.reportFileType === "PDF"
                    ? "http://docs.google.com/gview?url=" +
                      selectedItem.reportURL +
                      "&embedded=true"
                    : "/myimage?ImageUrl=" + selectedItem.reportURL
                }
                clickable
                target="blank"
              />
            </Grid>

            <Grid item lg={1} md={15} sm={15} xs={15}></Grid>
          </Grid>
        ) : (
          ""
        )}
      </DialogContent>

      <DialogActions>
        <Stack
          sx={{ width: "100%" }}
          direction="row"
          display="flex"
          justifyContent="center"
          spacing={2}
        >
          <Button onClick={deleteReport} variant="outlined" size="small">
            Yes
          </Button>
          <Button
            onClick={handleClose}
            autoFocus
            variant="outlined"
            size="small"
          >
            No
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
  return (
    <Fragment>
      <Box>
        <Grid
          container
          rowSpacing={3}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            py: 3,
          }}
        >
          {reportItems?.length === 0 && (
            <Grid item lg={12} textAlign="center">
              <Typography>No Reports</Typography>
            </Grid>
          )}
          {reportItems?.length > 0 &&
            reportItems.map((item, index) => (
              <Grid
                item
                lg={6}
                key={index}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Box
                  sx={{
                    width: "250px",
                    border: "0.5px solid #127DDD",
                    height: "108px",
                    borderRadius: "25px",
                    display: "flex",
                    justifyContent: "center",
                    px: 1,
                  }}
                >
                  <Grid
                    container
                    columnSpacing={1.5}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid item lg={4}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          border: "0.5px solid #127DDD",
                          borderRadius: "20px",
                          background: "#E7F0FF",
                          height: "4rem",
                          width: "4rem",
                        }}
                      >
                        <img
                          src={ReportIcon}
                          style={{
                            height: "45px",
                            width: "45px",
                            marginTop: 9,
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item lg={6}>
                      <Grid
                        container
                        rowSpacing={1}
                        sx={{ display: "flex", pl: 1, flexDirection: "column" }}
                      >
                        <Grid item>
                          <Typography
                            component={item.reportURL && Link}
                            sx={{ fontWeight: 600 }}
                          >
                            {item.reportURL && (
                              <Link
                                color="#127DDD"
                                href={
                                  item.reportFileType === "PDF"
                                    ? "http://docs.google.com/gview?url=" +
                                      item.reportURL +
                                      "&embedded=true"
                                    : "/myimage?ImageUrl=" + item.reportURL
                                }
                              >
                                {item.reportName.toUpperCase()}
                              </Link>
                            )}
                            {!item.reportURL && item.reportName}
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Typography sx={{ color: "#6F6F6F" }}>
                            {getFormattedDDMonthYYYY(
                              new Date(item.prescribedDate || new Date())
                            )}
                          </Typography>
                        </Grid>
                        {!item.reportURL && (
                          <Grid item>
                            <Typography sx={{ color: "#6F6F6F" }}>
                              Pending
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item lg={1}>
                      <img src={RightIcon} />
                    </Grid>
                  </Grid>
                </Box>

                {/* <Grid container columns={15}>
                  <Grid
                    item
                    lg={3}
                    md={15}
                    sm={15}
                    xs={15}
                    sx={{
                      borderRight: 1,
                      display: "flex",
                      alignItems: "center",
                      p: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "500", fontSize: ".70rem" }}
                    >
                      {element.reportName}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    lg={2}
                    md={15}
                    sm={15}
                    xs={15}
                    sx={{
                      borderRight: 1,
                      display: "flex",
                      alignItems: "center",
                      p: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "500", fontSize: ".70rem" }}
                    >
                      {element.reportFileType}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    lg={3}
                    md={15}
                    sm={15}
                    xs={15}
                    sx={{
                      borderRight: 1,
                      display: "flex",
                      alignItems: "center",
                      p: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "500", fontSize: ".70rem" }}
                    >
                      {getFormattedDDMonthYYYY(
                        new Date(element.prescribedDate || new Date())
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={15}
                    sm={15}
                    xs={15}
                    sx={{
                      borderRight: 1,
                      display: "flex",
                      alignItems: "center",
                      p: 1,
                    }}
                  >
                    <Chip
                      component="a"
                      size="small"
                      icon={<VisibilityIcon fontSize="small" />}
                      label={element.reportURL ? "view" : "pending"}
                      color={element.reportURL ? "primary" : "default"}
                      disabled={element.reportURL ? false : true}
                      href={
                        element.reportFileType === "PDF"
                          ? "http://docs.google.com/gview?url=" +
                          element.reportURL +
                          "&embedded=true"
                          : "/myimage?ImageUrl=" + element.reportURL
                      }
                      clickable
                      target="blank"
                    />
                  </Grid>

                  <Grid
                    item
                    lg={1}
                    md={15}
                    sm={15}
                    xs={15}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      p: 1,
                    }}
                  >
                    <IconButton
                      aria-label="edit"
                      size="small"
                      onClick={() => {
                        handleClickOpen();
                        setSelectedItem(element);
                        setSelectedIndex(index);
                      }}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </Grid>
                </Grid> */}
              </Grid>
            ))}
        </Grid>
      </Box>

      {dialog}
    </Fragment>
  );
};

export default ReportListComp;
