import { createTheme } from "@mui/material";
import { colors } from "../color";
const defaultTheme = createTheme();
const customTheme = createTheme({
  palette: {
    primary: {
      main: "#127DDD",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#127DDD",
    },
    tertiary: {
      main: "#CFFFCE",
    },
    customLight: defaultTheme.palette.augmentColor({
      color: { main: "#FFFFFF" },
      name: "customLight",
    }),
    customDark: defaultTheme.palette.augmentColor({
      color: { main: "#127DDD" },
      name: "customDark",
    }),
    action: {
      selectedOpacity: 0.75,
      disabledBackground: "#A9D6FF",
      disabled: "#000",
    },
  },

  components: {
    ///header global custumization
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: "#FFFFFF",
          // boxShadow: "0px 4px 7px rgba(0, 0, 0, 0.25)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#ffffff",
        },
        text: {
          color: "#127DDD ",
        },
        outlined: {
          color: "#127DDD ",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: "#000",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: 25,
          height: 27,
        },
        content: {
          P: {
            fontSize: "0.875rem",
            fontWeight: 500,
            color: "#000",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          background: "white",
          "&.Mui-selected": {
            backgroundColor: "#127DDD",
            color: "white",
            borderRadius: "22px",
            fontWeight: "500",
          },
          textTransform: "capitalize",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.primary,
          },
          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.primary,
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: colors.primary,
            },
          "& .MuiOutlinedInput-input": {
            color: colors.primary,
          },
          "&:hover .MuiOutlinedInput-input": {
            color: colors.primary,
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: colors.primary,
          },
          "& .MuiInputLabel-outlined": {
            color: colors.primary,
          },
          "&:hover .MuiInputLabel-outlined": {
            color: colors.primary,
          },
          "& .MuiInputLabel-outlined.Mui-focused": {
            color: colors.primary,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          '&[aria-selected="true"]': {
            backgroundColor: "#e3abed",
          },

          "&:hover": {
            backgroundColor: "#9c27b0",
          },
          backgroundColor: "#fff",
        },
      },
    },
  },

  typography: {
    MuiLink: {
      color: "#000",
    },
    fontFamily: [
      "Roboto",
      "-apple-system",
      "Arial",
      "Segoe UI",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
    fontStyle: ["normal"].join(","),
  },
});

export const dataGridStyle = {
  border: "none",
  " .time": {
    // background: "#BFEAFF",
    // borderRadius: "1rem",
  },
  "& .textStyle": {
    pb: "1",
    pt: "1",
    fontFamily: "Roboto",
    fontHeight: "400",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    color: "#6B6B6B",
  },
  "& .rowStyle": {
    mb: 1.5,
    ml: 2,
    border: "0.5px solid #127DDD",
    borderRadius: "0.75rem",
    width: "96% !important",
  },
  "& .headerStyle": {
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    color: "#000000",
    "& > .MuiDataGrid-columnHeader--flexStart": {},
    "& > .MuiDataGrid-columnSeparator": {
      visibility: "hidden",
    },
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      justifyContent: "left",
      marginLeft: "20px",
    },
    // "&:nth-type-of(2)": {
    //     "& > .MuiDataGrid-columnHeader": {
    //         justifyContent: "left",
    //         marginLeft: "90px",
    //         color: "red"
    //     }
    // },
  },
  "& .buttonStyle": {
    fontFamily: "Roboto",
    border: "0.1rem",
    borderRadius: "1rem",
    fontWeight: "400",
    fontSize: "1.5rem",
    lineHeight: "1.5rem",
    textAlign: "center",
    color: "#127DDD",
  },
  "& .MuiDataGrid-main": {
    "&>.MuiDataGrid-columnHeaders": {
      borderBottom: "none",
      borderTop: "none",
    },

    "& div div div div >.MuiDataGrid-cell": {
      borderBottom: "none",
    },
  },
};
// let DataGridTheme = useTheme();

// const DataGridTheme = createTheme({
//     components: {
//         MuiTab: {
//             styleOverrides: {
//                 root: {
//                     "&.Mui-selected": {
//                         backgroundColor: "red",
//                         // color: theme.palette.secondary.contrastText,
//                         borderRadius: "25px"
//                     }
//                 }
//             }
//         },

//     }
// })

export default customTheme;

export const customThemeTabs = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          background: "white",
          textTransform: "capitalize",
        },
      },
    },
  },
});
