import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { Fragment } from "react";

const ChartComponent = ({ image, label, vitalList = [], itemKey }) => {
  return (
    <Fragment>
      <Grid container>
        <Grid item lg={12}>
          <Stack
            direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              sx={{
                backgroundColor: "#E7F0FF",
                width: "55px",
                height: "55px",
                mx: 2,
                my: 2,
                borderRadius: "15px",
              }}
            >
              <img
                src={image}
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
            </Box>
            <Typography>{label}</Typography>
          </Stack>
        </Grid>
        <Grid item lg={12}>
          {vitalList.map((item, index) => (
            <Box>
              <Grid container>
                {/* <Grid item lg={12}>
                  <Typography>{item["createdDate"]}</Typography>
                </Grid> */}
                <Grid item lg>
                  <Typography> {item[itemKey]}</Typography>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ChartComponent;
