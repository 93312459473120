import { Grid, IconButton, TextField, Typography, Box } from "@mui/material";
import { Fragment, useState } from "react";
import { updateData } from "../../../../services/api/patchApi";
import { BASE_URL } from "../../../../assets/constantsFile";
import editIcon from "../../../../assets/images/svg/editIcon.svg";
import saveIcon from "../../../../assets/images/svg/formEdit.svg";

const style = {
  row: {
    display: "flex",
    alignItems: "center",
    px: 1,
    py: 0.87,
    borderRadius: "16.39px",
    background: "#F4FBFF",
  },
  title: {
    fontFamily: "Roboto",
    fontWeight: "600",
    fontSize: "1rem",
    lineHeight: "19px",
    color: "#127DDD",
  },
  textRow: {
    display: "flex",
    alignItems: "center",
  },
  text: {
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "19px",
    pl: 2,
  },
  border: {
    height: "36px",
    borderLeft: "1px solid #127ddd",
  },
  icon: { mt: 0.25 },
};

const EditDetail = ({ patientDetails, setIsEdit, label, keyValue }) => {
  const [text, setText] = useState(
    patientDetails ? patientDetails[keyValue] : ""
  );

  const updateValue = (e) => {
    e.preventDefault();
    const obj = {
      patientId: patientDetails.patientId,
      [keyValue]: text,
    };
    updateFieldData(obj);
  };
  const updateFieldData = async (data) => {
    console.log({ formData: data });
    const url = BASE_URL + "patient/medicalHistory";
    const response = await updateData(url, data);

    if (response.error) {
      console.log("error");
    } else {
      console.log("success");
      setIsEdit(false);
      patientDetails[keyValue] = data[keyValue];
    }
  };
  return (
    <Fragment>
      <form onSubmit={updateValue}>
        <Grid container sx={style.row}>
          <Grid item lg={3}>
            <Typography sx={style.title}>{label}</Typography>
          </Grid>

          <Grid item lg={8} sx={style.textRow}>
            <Box sx={style.border}></Box>
            <TextField
              sx={{
                px: 0.5,
                "& .MuiOutlinedInput-root:hover": {
                  "& > fieldset": {
                    borderColor: "#127DDD",
                  },
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#127DDD",
                  },
                },
              }}
              label=""
              size="small"
              fullWidth
              variant="outlined"
              value={text || ""}
              onChange={(e) => {
                setText(e.target.value);
              }}
              inputProps={{
                style: { fontSize: ".875rem", fontWeight: "500" },
              }}
              InputLabelProps={{
                style: {
                  fontSize: ".875rem",
                  fontWeight: "500",
                  color: "#127DDD",
                },
              }}
            />
          </Grid>
          <Grid item lg={1} sx={style.icon}>
            <IconButton aria-label="save/update" size="small" type="submit">
              <img src={saveIcon} sx={{ width: "100%" }} />
            </IconButton>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default EditDetail;
