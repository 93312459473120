import { Box, Grid, Stack, Typography } from "@mui/material";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { CustomParagraphWhite1 } from "../../global/paragraphs/customParagraphs";
import { CustomHeadingWhiteLeft2 } from "../../global/headings/customHeadings";

const CustomBullet = ({ data }) => {
  return (
    <Stack direction="row" spacing={2}>
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ backgroundColor: "white", height: 60, width: 60, borderRadius: 30 }}>
        <PermIdentityIcon sx={{ color: "#0463FA" }} />
      </Box>
      <Stack>
        <CustomParagraphWhite1>{data?.text}</CustomParagraphWhite1>
        <CustomHeadingWhiteLeft2>{data?.service}</CustomHeadingWhiteLeft2>
      </Stack>
    </Stack>
  );
};

const FeatureBullets = () => {
  const bulletData = [
    {
      id: 1,
      text: "Expert In-Person Care",
      service: "OPD Consultation",
    },
    {
      id: 2,
      text: "Virtual Health Experts",
      service: "Online Consultation",
    },
    {
      id: 3,
      text: "Accurate Diagnostic Services",
      service: "Lab Tests",
    },
    {
      id: 4,
      text: "Convenient Medication Access",
      service: "Pharmacy",
    },
    {
      id: 5,
      text: "Skilled Surgical Procedures",
      service: "Surgery",
    },
    {
      id: 6,
      text: "Effective Physical Therapy",
      service: "Physiotherapy",
    },
  ];
  return (
    <Grid container spacing={2}>
      {bulletData?.map((item, index) => (
        <Grid item lg={6} xs={6} key={index}>
          <CustomBullet data={item} />
        </Grid>
      ))}
    </Grid>
  );
};

export default FeatureBullets;
