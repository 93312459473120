import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { BASE_URL } from "../../../../assets/constantsFile";
import { saveData } from "../../../../services/api/postApi";
import { dateDiffInDays, getNextDate } from "../../../../assets/utils";
import EditIcon from "@mui/icons-material/Edit";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CancelIcon from "@mui/icons-material/Cancel";

const style = {
    row: {
        display: "flex",
        alignItems: "center",
        px: 1,
        py: 0.87,
        borderRadius: "16.39px",
        background: "#F4FBFF",
    },
    title: {
        fontFamily: "Roboto",
        fontWeight: "600",
        fontSize: "1rem",
        lineHeight: "19px",
        color: "#127DDD",
    },
    textRow: {
        display: "flex",
        alignItems: "center",
    },
    text: {
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "1rem",
        lineHeight: "19px",
        pl: 2,
    },
    border: {
        pr: 2,
        height: "36px",
        borderLeft: "1px solid #127ddd",
    },
    icon: { mt: 0.25 },
};

const YesIcon = ({ selected = false }) => (
    <>
        <Box
            sx={{
                width: 30,
                height: 30,
                background: "#CFFFCE",
                border: selected && "1px solid #20945C ",
                borderRadius: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Typography sx={{ color: "#20945C" }}>Y</Typography>
        </Box>
    </>
);
const NoIcon = ({ selected = false }) => (
    <>
        <Box
            sx={{
                width: 30,
                height: 30,
                background: "#FFDADA",
                border: selected && "1px solid #F80000",
                borderRadius: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Typography sx={{ color: "#F80000" }}>N</Typography>
        </Box>
    </>
);

const PersonalVisitRequiredComp = ({ isPersonalVisitNeeded, personalAppointmentDate, appointmentId }) => {
    const [isEditNextVisit, setIEditNextVisit] = useState(isPersonalVisitNeeded ? false : true);
    const [isNextVisit, setIsNextVisit] = useState(isPersonalVisitNeeded ? "yes" : "no");

    const [visitInterval, setVisitInterval] = useState(
        personalAppointmentDate ? dateDiffInDays(new Date(), new Date(personalAppointmentDate)) : 0
    );
    const [nextDateValue, setNextDateValue] = useState(personalAppointmentDate ? personalAppointmentDate : new Date());

    const handlePersonalVisit = (newValue) => {
        const obj = {
            personalAppointmentDate: newValue ? new Date(newValue).toISOString().split("T")[0] : null,
            appointmentId: appointmentId,
            personalVisitNeeded: newValue ? true : false,
        };

        savePesonalVisit(obj);
    };

    const savePesonalVisit = async (data) => {
        const url = BASE_URL + "clinic/markPersonalVisitNeeded";
        const user = await saveData(url, data, "");

        if (user.error) {
            console.log(user.error);
        } else {
            console.log("success");
            setIEditNextVisit(false);
        }
    };

    return (
        <Fragment>
            <Grid container sx={style.row}>
                <Grid item lg={3}>
                    <Typography sx={style.title}>Personal Visit</Typography>
                </Grid>
                <Grid
                    item
                    lg={8}
                    sx={{
                        // borderRight: 1,
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Box sx={style.border}></Box>
                    <Grid container sx={{ height: "100%" }} columnSpacing={1}>
                        <Grid
                            item
                            lg={10}
                            sx={{
                                // borderRight: isNextVisit === "yes" ? 1 : 0,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Box>
                                {isNextVisit === "yes" ? (
                                    isEditNextVisit ? (
                                        <Fragment>
                                            <Stack
                                                spacing={2}
                                                direction="row"
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <TextField
                                                    sx={{
                                                        width: 70,
                                                        "& .MuiOutlinedInput-root:hover": {
                                                            "& > fieldset": {
                                                                borderColor: "#127DDD",
                                                            },
                                                        },
                                                        "& .MuiOutlinedInput-root": {
                                                            "&.Mui-focused fieldset": {
                                                                borderColor: "#127DDD",
                                                            },
                                                        },
                                                    }}
                                                    InputLabelProps={{
                                                        style: {
                                                            color: "#127DDD",
                                                        },
                                                    }}
                                                    size="small"
                                                    label="Interval"
                                                    variant="outlined"
                                                    name="visitInterval"
                                                    value={visitInterval}
                                                    onChange={(e) => {
                                                        if (!isNaN(e.target.value) && e.target.value >= 0) {
                                                            setVisitInterval(e.target.value);
                                                            setNextDateValue(getNextDate(e.target.value));
                                                        } else {
                                                            setVisitInterval(0);
                                                            setNextDateValue(getNextDate("0"));
                                                        }
                                                    }}
                                                />

                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        disablePast
                                                        label="Date"
                                                        openTo="day"
                                                        views={["year", "month", "day"]}
                                                        disableMaskedInput={true}
                                                        inputFormat="dd MMM yyyy"
                                                        value={nextDateValue}
                                                        onChange={(newValue) => {
                                                            setNextDateValue(newValue);
                                                            setVisitInterval(dateDiffInDays(new Date(), newValue));
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                size="small"
                                                                InputLabelProps={{
                                                                    style: {
                                                                        color: "#127DDD",
                                                                    },
                                                                }}
                                                                sx={{
                                                                    width: 160,
                                                                    "& .MuiOutlinedInput-root:hover": {
                                                                        "& > fieldset": {
                                                                            borderColor: "#127DDD",
                                                                        },
                                                                    },
                                                                    "& .MuiOutlinedInput-root": {
                                                                        "&.Mui-focused fieldset": {
                                                                            borderColor: "#127DDD",
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </Stack>
                                        </Fragment>
                                    ) : (
                                        <Typography variant="caption">
                                            Personal Visit is scheduled after {visitInterval} day/s.
                                        </Typography>
                                    )
                                ) : (
                                    "N/A"
                                )}
                            </Box>
                        </Grid>
                        <Grid
                            item
                            lg={2}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Box>
                                {isNextVisit === "yes" ? (
                                    isEditNextVisit ? (
                                        <Stack
                                            spacing={1}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <IconButton
                                                sx={{ p: 0 }}
                                                aria-label="update"
                                                size="small"
                                                onClick={() => handlePersonalVisit(nextDateValue)}
                                            >
                                                <SaveAsIcon
                                                    sx={{
                                                        border: 1,
                                                        borderRadius: 1,
                                                        borderColor: "#208F94",
                                                        p: 0.5,
                                                    }}
                                                    fontSize="inherit"
                                                    color="primary"
                                                />
                                            </IconButton>

                                            <IconButton
                                                sx={{ p: 0 }}
                                                aria-label="update"
                                                size="small"
                                                onClick={() => {
                                                    if (isPersonalVisitNeeded) {
                                                        setIsNextVisit("yes");
                                                        setIEditNextVisit(false);
                                                    } else {
                                                        setIsNextVisit("no");
                                                        setIEditNextVisit(true);
                                                    }
                                                }}
                                            >
                                                <CancelIcon
                                                    sx={{
                                                        border: 1,
                                                        borderRadius: 1,
                                                        borderColor: "#800000",
                                                        p: 0.5,
                                                        color: "#800000",
                                                    }}
                                                    fontSize="inherit"
                                                />
                                            </IconButton>
                                        </Stack>
                                    ) : (
                                        <IconButton
                                            aria-label="edit"
                                            size="small"
                                            onClick={() => setIEditNextVisit(true)}
                                        >
                                            <EditIcon
                                                sx={{
                                                    border: 1,
                                                    borderRadius: 1,
                                                    p: 0.5,
                                                }}
                                                fontSize="inherit"
                                            />
                                        </IconButton>
                                    )
                                ) : (
                                    ""
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={1} sx={{ display: "flex", justifyContent: "center", p: 0.5 }}>
                    <FormControl>
                        <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={isNextVisit}
                            onChange={(event) => {
                                setIsNextVisit(event.target.value);
                            }}
                            sx={{ display: "flex", flexDirection: "row" }}
                        >
                            <FormControlLabel
                                sx={{ m: 0 }}
                                value="yes"
                                control={
                                    <Radio
                                        checkedIcon={<YesIcon selected={true} />}
                                        icon={<YesIcon />}
                                        size="small"
                                        sx={{ p: 0, m: 0.75 }}
                                    />
                                }
                                // label="Y"
                                onClick={() => {
                                    visitInterval === 0 ? setIEditNextVisit(true) : setIEditNextVisit(false);
                                }}
                                // labelPlacement="start"
                            />
                            <FormControlLabel
                                sx={{ m: 0 }}
                                value="no"
                                control={
                                    <Radio
                                        checkedIcon={<NoIcon selected={true} />}
                                        icon={<NoIcon />}
                                        size="small"
                                        sx={{ p: 0, m: 0.75 }}
                                    />
                                }
                                // label="N"
                                onClick={() => {
                                    handlePersonalVisit(null);
                                    setVisitInterval(0);
                                    setNextDateValue(new Date());
                                }}
                                // labelPlacement="start"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default PersonalVisitRequiredComp;
