// import { Box, Grid, Stack, Typography } from "@mui/material";
// import dayjs from "dayjs";
// import React, { Fragment } from "react";

// const CarouselMonth = () => {
//   const data = [
//     { id: 0, name: "January" },
//     { id: 1, name: "Feburary" },
//     { id: 2, name: "March" },
//     { id: 3, name: "April" },
//     { id: 4, name: "May" },
//     { id: 5, name: "June" },
//     { id: 6, name: "July" },
//     { id: 7, name: "August" },
//     { id: 8, name: "September" },
//     { id: 9, name: "October" },
//     { id: 10, name: "November" },
//     { id: 11, name: "December" },
//   ];
//   console.log({ currentMonth: dayjs().month() });
//   return (
//     <Fragment>
//       <Box
//         sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
//       >
//         <Stack
//           sx={{
//             minHeight: "80vh",
//             width: 200,
//             background:
//               "linear-gradient(180deg, #FFF 0%, #8DB9FF 50.5%, #FFF 100%)",
//             flexDirection: "column",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           {data.map((item, index) => (
//             <Fragment key={index}>
//               {item.id === dayjs().month() ? (
//                 <Box
//                   sx={{
//                     backgroundColor: "#0463FA",
//                     height: 100,
//                     width: 230,
//                     textAlign: "center",
//                     alignContent: "center",
//                   }}
//                 >
//                   <Typography sx={styles.monthText}>{item.name}</Typography>
//                 </Box>
//               ) : (
//                 <Box
//                   sx={{
//                     height: 50,
//                     textAlign: "center",
//                     alignContent: "center",
//                   }}
//                 >
//                   <Typography sx={styles.monthText}>{item.name}</Typography>
//                 </Box>
//               )}
//             </Fragment>
//           ))}
//         </Stack>
//       </Box>
//     </Fragment>
//   );
// };

// export default CarouselMonth;

// const styles = {
//   monthText: {
//     color: "#FFF",
//     fontFamily: "Poppins",
//     fontSize: "20px",
//     fontStyle: "normal",
//     fontWeight: "600",
//     lineHeight: "110%" /* 22px */,
//     letterSpacing: "-0.38px",
//   },
// };

// import React, { useState, useEffect, Fragment } from "react";
// import { Box, Stack, Typography } from "@mui/material";
// import dayjs from "dayjs";

// const CarouselMonth = () => {
//   const data = [
//     { id: 0, name: "January" },
//     { id: 1, name: "February" },
//     { id: 2, name: "March" },
//     { id: 3, name: "April" },
//     { id: 4, name: "May" },
//     { id: 5, name: "June" },
//     { id: 6, name: "July" },
//     { id: 7, name: "August" },
//     { id: 8, name: "September" },
//     { id: 9, name: "October" },
//     { id: 10, name: "November" },
//     { id: 11, name: "December" },
//   ];

//   const currentMonth = dayjs().month();
//   const [selectedMonth, setSelectedMonth] = useState(currentMonth);

//   useEffect(() => {
//     const centerIndex = Math.max(Math.min(selectedMonth, data.length - 3), 2);
//     const start = Math.max(centerIndex - 2, 0);
//     setVisibleRange(data.slice(start, start + 5));
//   }, [selectedMonth]);

//   const [visibleRange, setVisibleRange] = useState(data.slice(0, 5));

//   const handleMonthClick = (index) => {
//     setSelectedMonth(index);
//   };

//   return (
//     <Fragment>
//       <Box
//         sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
//       >
//         <Stack
//           sx={{
//             height: "65vh",
//             width: 200,
//             background:
//               "linear-gradient(180deg, #FFF 0%, #8DB9FF 50.5%, #FFF 100%)",
//             flexDirection: "column",
//             justifyContent: "center",
//             alignItems: "center",
//             overflow: "hidden",
//           }}
//         >
//           {visibleRange.map((item) => (
//             <Box
//               key={item.id}
//               onClick={() => handleMonthClick(item.id)}
//               sx={{
//                 backgroundColor:
//                   item.id === selectedMonth ? "#0463FA" : "transparent",
//                 height: item.id === selectedMonth ? 100 : 50,
//                 width: 230,
//                 textAlign: "center",
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 transition: "all 0.3s ease-in-out",
//                 cursor: "pointer",
//                 position: item.id === selectedMonth ? "relative" : "static",
//                 zIndex: item.id === selectedMonth ? 1 : 0,
//               }}
//             >
//               <Typography sx={styles.monthText}>{item.name}</Typography>
//             </Box>
//           ))}
//         </Stack>
//       </Box>
//     </Fragment>
//   );
// };

// export default CarouselMonth;

// const styles = {
//   monthText: {
//     color: "#FFF",
//     fontFamily: "Poppins",
//     fontSize: "20px",
//     fontStyle: "normal",
//     fontWeight: "600",
//     lineHeight: "110%", // 22px
//     letterSpacing: "-0.38px",
//   },
// };

import React, { useState, useEffect, Fragment } from "react";
import { Box, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";

const CarouselMonth = ({ setMonth }) => {
  const data = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  const currentMonth = dayjs().month() + 1;
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [visibleRange, setVisibleRange] = useState([]);

  useEffect(() => {
    const getCircularMonths = (month, range) => {
      const result = [];
      for (let i = -range; i <= range; i++) {
        result.push(data[(month + i + 12) % 12]);
      }
      return result;
    };

    setVisibleRange(getCircularMonths(selectedMonth, 2));
    setMonth(
      selectedMonth
        ? data.find((item) => item.id === selectedMonth)
        : data.find((item) => item.id === dayjs().month() + 1)
    );
  }, [selectedMonth]);

  const handleMonthClick = (index, data) => {
    setSelectedMonth(index);
    setMonth(data);
  };

  return (
    <Fragment>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Stack
          sx={{
            height: "65vh",
            maxWidth: 200,
            background:
              "linear-gradient(180deg, #FFF 0%, #8DB9FF 50.5%, #FFF 100%)",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // overflow: "visbile",
          }}
        >
          {visibleRange.map((item) => (
            <Fragment key={item.id}>
              {item.id === selectedMonth ? (
                <Box
                  onClick={() => handleMonthClick(item.id, item)}
                  sx={{
                    backgroundColor: "#0463FA",
                    height: 100,
                    width: 230,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transition: "all 0.5s ease-in-out",
                    cursor: "pointer",
                    position: "relative",
                    zIndex: 1,
                    marginBlock: 1,
                    borderRadius: 5,
                  }}
                >
                  <Typography sx={styles.monthText}>{item.name}</Typography>
                </Box>
              ) : (
                <Box
                  onClick={() => handleMonthClick(item.id, item)}
                  sx={{
                    height: 50,
                    textAlign: "center",
                    alignContent: "center",
                    cursor: "pointer",
                    marginBlock: 1,
                  }}
                >
                  <Typography sx={styles.monthText}>{item.name}</Typography>
                </Box>
              )}
            </Fragment>
          ))}
        </Stack>
      </Box>
    </Fragment>
  );
};

export default CarouselMonth;

const styles = {
  monthText: {
    color: "#FFF",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "110%", // 22px
    letterSpacing: "-0.38px",
  },
};
