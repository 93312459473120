import { Box, Container, Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import LeftBanner from "./modules/Banners/LeftBanner";
import RightBanner from "./modules/Banners/RightBanner";
import TitleComp from "./modules/Title/TitleComp";
import ThreeStep from "./modules/ThreeStep/ThreeStep";
import AboutUs from "./modules/aboutUs/AboutUs";
import AppointmentForm from "./modules/appoinmentForm/AppointmentForm";
import Socials from "./modules/socials/Socials";
import MobileBanner from "./modules/mobile/bannerMobile/MobileBanner";
import SecondBanner from "./modules/mobile/bannerMobile/SecondBanner";
import TitleMobile from "./modules/mobile/bannerMobile/TitleMobile";
import ThreeStepMobile from "./modules/mobile/bannerMobile/ThreeStepMobile";
import AboutUsMobile from "./modules/mobile/bannerMobile/AboutUsMobile";
import MobileForm from "./modules/mobile/bannerMobile/MobileFrom";
import SocialsMobile from "./modules/mobile/bannerMobile/SocialsMobile";

const DigitalMarketingMain = () => {
  return (
    <Fragment>
      <Box display={{ xs: "none", sm: "none", md: "block", lg: "block", xl: "block" }}>
        {/* <Box sx={{ paddingBlock: "50px", paddingInline: "120px" }}>
          <Grid container>
            <Grid item lg={7}>
              <LeftBanner />
            </Grid>
            <Grid item lg={5}>
              <RightBanner />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <TitleComp title="Fastest & Easiest Solution" alignLeft={true} />
        </Box>
        <Box>
          <Grid container>
            <Grid item lg={12} sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "#404040",
                  fontFamily: "Roboto",
                  fontSize: "40px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                  textAlign: "center",
                }}>
                {" "}
                <span
                  style={{
                    color: "#404040",
                    fontFamily: "Roboto",
                    fontSize: "40px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                    textAlign: "center",
                  }}>
                  3 Steps{" "}
                </span>
                And Get{" "}
                <span
                  style={{
                    color: "#404040",
                    fontFamily: "Roboto",
                    fontSize: "40px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                    textAlign: "center",
                  }}>
                  Complete Health Check-Up{" "}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <ThreeStep />
        </Box>
        <Box>
          <TitleComp alignRight={true} />
        </Box>
        <Box>
          <AboutUs />
        </Box>
        <Box>
          <TitleComp title="CONNECT WITH US" />
        </Box>
        <Box>
          <Grid container>
            <Grid item lg={12} sx={{ textAlign: "center", marginBlock: "10px" }}>
              <Typography
                sx={{
                  color: "#404040",
                  fontFamily: "Roboto",
                  fontSize: "40px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                  textAlign: "center",
                }}>
                Just{" "}
                <span
                  style={{
                    color: "#404040",
                    fontFamily: "Roboto",
                    fontSize: "40px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                    textAlign: "center",
                  }}>
                  Fill In The Details{" "}
                </span>
                And Get{" "}
                <span
                  style={{
                    color: "#404040",
                    fontFamily: "Roboto",
                    fontSize: "40px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                    textAlign: "center",
                  }}>
                  Complete Health Check-Up{" "}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Box> */}
        <Box>
          <AppointmentForm />
        </Box>
        {/* <Box
          sx={{
            paddingInline: "80px",
            boxShadow: "10px 10px 50px 10px rgba(229, 233, 246, 0.40)",
          }}>
          <Socials />
        </Box> */}
      </Box>

      <Box display={{ xs: "block", sm: "block", md: "none", lg: "none", xl: "none" }}>
        {/* <Box sx={{ margin: "20px" }}>
          <Grid container>
            <Box>
              <Typography
                sx={{
                  color: "#404040",
                  fontSize: "20px",
                  fontWeight: "700",
                  lineHeight: "normal",
                  marginBlock: "10px",
                }}>
                Primary Healthcare For Bharat
              </Typography>
              <Typography
                sx={{
                  color: "#404040",
                  fontSize: "13px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  marginBlock: "10px",
                }}>
                Sab Theek Hojayega!
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={12}>
            <MobileBanner />
          </Grid>
          <Grid item xs={12} lg={12}>
            <SecondBanner />
          </Grid>
          <Grid item xs={12} lg={12}>
            <TitleMobile
              title="Fastest & Easiest Solution"
              buttomTitle="3 Steps And Get Complete Health Check-Up "
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <ThreeStepMobile />
          </Grid>
          <Grid item xs={12} lg={12}>
            <AboutUsMobile />
          </Grid> */}
        <Grid item xs={12} lg={12}>
          <MobileForm />
        </Grid>
        {/* <Grid item xs={12} lg={12}>
            <SocialsMobile />
          </Grid> */}
        {/* </Box> */}
      </Box>
    </Fragment>
  );
};

export default DigitalMarketingMain;
