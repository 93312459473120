import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Portal,
  Stack,
  Typography,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Fragment, useEffect, useState } from "react";
import { DataGrid, GridPagination, useGridApiRef } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import MuiPagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { gridPageCountSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid";
import { Close } from "@mui/icons-material";
import { BASE_URL } from "../../../../../../assets/constantsFile";
import { getData } from "../../../../../../services/api/getApi";
import { getFormattedDDMonthYYYY } from "../../../../../../assets/utils";
import { PhotoViewer } from "../../../../../../assets/photoViewer";
import DownloadCSV from "../../../../../global/components/downloadCSV";
import { StyledDataGrid } from "../../../../../global/customDatagrids/CustomStyledDatagrid";

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
      renderItem={(item) => (
        <PaginationItem
          type="last"
          slots={{
            previous: () => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ArrowBackIcon />
                <Typography sx={{ marginLeft: "10px" }}>Previous</Typography>
              </Box>
            ),
            next: () => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ marginRight: "10px" }}>Next</Typography>
                <ArrowForwardIcon />
              </Box>
            ),
          }}
          {...item}
        />
      )}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
    "& .paxton-table--header": {
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        padding: 0,
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 600,
        textTransform: "uppercase",
        fontSize: "16px",
      },
    },
    "& .paxton-table--row": {
      borderBottom: "0.5px solid #C5C5D3",
      marginTop: 7,
      marginBottom: 7,
      backgroundColor: "#fff",
      paddingTop: 5,
      paddingBottom: 5,
      boxSizing: "content-box",
    },
    "& .MuiDataGrid-cell": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: 12,
      lineHeight: 15,
      color: "##383838",
      textTransform: "capitalize",
      boxSizing: "none",
      borderBottom: "none ! important",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer": {
      border: "none",
    },
    "& .MuiDataGrid-viewport": {
      maxHeight: "unset !important",
    },
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "670px !important",
    },
  },
}));

const InjuryDashboardMain = ({ corpId = localStorage.getItem("CORPID"), date }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [injuryImages, setInjuryImages] = useState([]);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [opdList, setOpdList] = useState([]);

  const apiRef = useGridApiRef();

  const fetchInjuryHealthRecordData = async () => {
    setIsLoading(true);
    const url = BASE_URL + `org/opd/${corpId}?date=${date.toISOString().split("T")[0]}`;
    const opd = await getData(url, "");
    setIsLoading(false);
    if (opd.error) {
      setOpdList([]);
    } else {
      const rows = opd?.data
        ?.filter((item) => item.ohcType === "INJURY")
        ?.map((item) => ({
          isActive: item.isActive,
          id: item.id,
          empId: item.empId,
          gender: item.gender,
          department: item.department,
          name: item.name,
          age: item.age,
          date: getFormattedDDMonthYYYY(item.date),
          injuryRemarks: item.injuryRemarks,
          injuryType: item.injuryType,
          injuryLocation: item.injuryLocation,
          injuryDetails: item.injuryDetails,
          injurySeverity: item.injurySeverity,
          treatmentDoneAt: item.treatmentDoneAt,
          ohcType: item.ohcType,
        }));

      setOpdList(rows);
    }
  };

  const columns = [
    { field: "name", headerName: "Name", width: 200 },

    {
      field: "department",
      headerName: "Department",
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "age",
      headerName: "Age",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "date",
      headerName: "Date",
      width: 110,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "injuryRemarks",
      headerName: "Injury Remarks",
      headerAlign: "center",
      width: 200,
      align: "center",
    },
    {
      field: "injuryType",
      headerName: "Injury Type",
      headerAlign: "center",
      width: 150,
      align: "center",
    },
    {
      field: "injuryLocation",
      headerName: "Injury Location",
      headerAlign: "center",
      width: 150,
      align: "center",
    },
    {
      field: "treatmentDoneAt",
      headerName: "Treatment Done At",
      headerAlign: "center",
      width: 130,
      align: "center",
    },
    {
      field: "injurySeverity",
      headerName: "Injury Severity",
      headerAlign: "center",
      width: 130,
      align: "center",
    },
    // {
    //   field: "injuryDetails",
    //   headerName: "Injury Details",
    //   headerAlign: "center",
    //   width: 200,
    //   align: "center",
    //   renderCell: (params) =>
    //     params?.row?.injuryDetails?.length > 0 && (
    //       <Button
    //         variant="contained"
    //         size="small"
    //         onClick={() => {
    //           setInjuryImages(params?.row?.injuryDetails);
    //           setOpen(true);
    //         }}
    //       >
    //         View
    //       </Button>
    //     ),
    // },
  ];

  useEffect(() => {
    fetchInjuryHealthRecordData();
  }, [date]);

  const handleFileClick = (fileUrl) => {
    setSelectedFileUrl(fileUrl);
    console.log({ fileUrl });
    setOpen2(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFileUrl(null);
  };

  const handleClose2 = () => {
    setOpen(false);
    setSelectedFileUrl(null);
  };

  const classes = useStyles();

  const handleExportCSV = () => {
    if (apiRef.current) {
      apiRef.current.exportDataAsCsv();
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item lg={12}>
          <Box
            style={{
              height: "75vh",
              width: "100%",
              padding: "20px",
              backgroundColor: "#FFFFFF",
              border: "0.5px solid grey",
              borderRadius: "16px",
              marginBlock: "20px",
            }}>
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" color="primary" onClick={handleExportCSV}>
                Export CSV
              </Button>
            </Box>
            <Box style={{ display: "flex", height: "100%" }}>
              <StyledDataGrid
                apiRef={apiRef}
                sx={{ border: "none" }}
                columns={columns}
                rows={opdList}
                rowHeight={45}
                disableRowSelectionOnClick
                className={classes.root}
                //getRowClassName={() => "paxton-table--row"}
                getCellClassName={() => "paxton-table--cell"}
                slots={{ pagination: CustomPagination }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Portal>
        <Dialog open={open} onClose={handleClose} maxWidth={"xl"} fullWidth={true}>
          <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
            Injury Images
          </DialogTitle>
          <DialogActions sx={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ height: "75vh" }}>
            <Grid container spacing={2}>
              {injuryImages?.map((file, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                  <Paper
                    elevation={3}
                    onClick={() => handleFileClick(file.attachmentUrl)}
                    style={{ cursor: "pointer", height: 170, width: 170 }}>
                    <Box
                      display="flex"
                      justifyContent={"center"}
                      flexDirection="column"
                      alignItems="center"
                      style={{ height: 150, width: 150 }}>
                      {file.name.toLowerCase().endsWith(".pdf") ? (
                        <PictureAsPdfIcon style={{ fontSize: 100, color: "#127DDD" }} />
                      ) : (
                        <Box
                          component={"img"}
                          src={file.attachmentUrl}
                          alt={file.attachmentUrl}
                          style={{ height: 150, width: 150 }}
                        />
                      )}
                      <Typography variant="body2" align="center" flexWrap={"wrap"}>
                        {file.name}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            {selectedFileUrl && (
              <PhotoViewer url={selectedFileUrl} open={open2} handleClose={handleClose2} />
            )}
          </DialogContent>
        </Dialog>
      </Portal>
    </Fragment>
  );
};

export default InjuryDashboardMain;
