import { Box, Button, Container, Grid, Paper, Stack } from "@mui/material";
import { BASE_URL } from "../../assets/constantsFile";
import { Fragment, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { getData } from "../../services/api/getApi";
import PrintReportComponent from "./printReportComponent";

const ViewReportComponent = (props) => {
  console.log({ "props.patientDetails123": props.patientDetails });

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [docDetail, setDocDetail] = useState("");
  let patientDetails = "";
  let doctorId = "";
  let patientInfo = "";
  let vitalInfo = { bp: "" };
  let observationInfo = "";
  let reportList = [];
  let prescriptionList = [];

  if (props.patientDetails) {
    patientDetails = props.patientDetails;
    doctorId = patientDetails.docId;

    patientInfo = {
      patientId: patientDetails.patientId,
      name: patientDetails.patientName,
      age:
        (patientDetails.patientInfoVM.age
          ? patientDetails.patientInfoVM.age + "Y "
          : "") +
        (patientDetails.patientInfoVM.ageMonths
          ? patientDetails.patientInfoVM.ageMonths + "M"
          : ""),
      sex: patientDetails.gender,
      issue: patientDetails.issue,
    };
    vitalInfo = patientDetails.vitals ? patientDetails.vitals : { bp: "" };
    observationInfo = patientDetails.observation
      ? patientDetails.observation
      : "";
    reportList = patientDetails.reports ? patientDetails.reports : [];
    let prescriptionVM = patientDetails.prescriptionVM;

    if (prescriptionVM) {
      prescriptionList = prescriptionVM.medicines;
    }
  }

  const getDocInfo = async (dId) => {
    const user = await getData(BASE_URL + "doctor/" + dId);

    if (user.error) {
    } else {
      const data = user.data;
      setDocDetail(data);
    }
  };

  useEffect(() => {
    getDocInfo(doctorId);
  }, [doctorId]);

  const goBAck = () => {
    props.onViewPres("BACK");
  };
  console.log({ doctorId });
  console.log({ docDetail: docDetail });

  return (
    <Fragment>
      <Box component={"iframe"} sx={{ width: 0, height: 0, display: "none" }}>
        <Box
          ref={componentRef}
          sx={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }}
        >
          <PrintReportComponent patientDetails={patientDetails} />
        </Box>
      </Box>

      <Container maxWidth={false} disableGutters>
        <Box>
          <Grid container>
            <Grid item lg={12}>
              <Box sx={{ minHeight: "70vh" }}>
                <Grid container rowSpacing={2}>
                  <Grid
                    item
                    lg={6}
                    xs={6}
                    md={6}
                    sm={6}
                    display="flex"
                    justifyContent={"flex-start"}
                    alignItems="center"
                  ></Grid>
                  <Grid
                    item
                    lg={6}
                    xs={6}
                    md={6}
                    sm={6}
                    display="flex"
                    justifyContent={"flex-end"}
                  >
                    <Paper
                      component={Stack}
                      direction="row"
                      display={"flex"}
                      justifyContent={"space-between"}
                      elevation={0}
                      sx={{ width: 200, p: 1, height: 50 }}
                    >
                      <Button
                        variant="contained"
                        sx={{ width: 90 }}
                        onClick={handlePrint}
                      >
                        Print!
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ width: 90 }}
                        onClick={goBAck}
                      >
                        Back
                      </Button>
                    </Paper>
                  </Grid>

                  <Grid item lg={12} xs={12} md={12} sm={12}>
                    <Box
                      sx={{
                        border: "1px solid grey ",
                        mb: 2,
                      }}
                    >
                      <PrintReportComponent patientDetails={patientDetails} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Fragment>
  );
};

export default ViewReportComponent;
