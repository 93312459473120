import RootTemplate from "../../global/templates/rootTemplate";
import EmergencyHandlingMain from "../../modules/controllRoom/emergencyHandling/emergencyHandlingMain";

const EmergencyHandlingIndex = () => {
  return (
    <RootTemplate>
      <EmergencyHandlingMain />
    </RootTemplate>
  );
};

export default EmergencyHandlingIndex;
