import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  PieChart,
  Pie,
  Cell,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Portal,
  Typography,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import { downloadCsv, formatColumnName } from "../../../../../../assets/utils";
import { useGridApiRef } from "@mui/x-data-grid";
import CustomDataGridLayout from "../../../../../../assets/customDataGridLayout/customDataGridLayout";

const useStyles = makeStyles((theme) => ({
  legend: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  legendItem: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  colorSwatch: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1),
    backgroundColor: "#ccc",
    borderRadius: "50%",
  },
  label: {
    fontSize: theme.typography.fontSizeMedium,
    marginRight: "10px",
  },
  value: {
    fontSize: theme.typography.fontSizeSmall,
    marginLeft: theme.spacing(1),
  },
}));

const CustomLegend = ({ payload, COLORS }) => {
  const classes = useStyles();

  return (
    <Box className={classes.legend}>
      {payload.map((item, index) => (
        <div key={item.name} className={classes.legendItem}>
          <Box sx={{ display: "flex" }}>
            <Box
              className={classes.colorSwatch}
              style={{ backgroundColor: COLORS[index] }}
            />
            <Typography variant="body2" className={classes.label}>
              {item.name}
            </Typography>
          </Box>
        </div>
      ))}
    </Box>
  );
};

const CustomTooltipContent = ({ active, payload, label }) => {
  if (active) {
    const data = payload?.[0]?.payload;
    return (
      <Box sx={{ backgroundColor: "#FFFFFF", padding: "10px", zIndex: 20 }}>
        <Typography>{`${data.name}`}</Typography>
        <Typography>
          {data.percent ? `Percentage: ${data.percent}` : ""}
        </Typography>
        <Typography>{`Value: ${data.value}`}</Typography>
      </Box>
    );
  }

  return null;
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const DoublePieChart = ({
  data,
  title,
  disableClickable,
  COLORS = [],
  labelHeading,
  minHeight,
  downloadExternalData = false,
  handleDownloadExternalData,
  dialogData,
}) => {
  const handleDownload = () => {
    downloadCsv(data, `${title}`);
  };

  const total = data.reduce((acc, entry) => acc + entry.value, 0);

  const apiRef = useGridApiRef(null);
  const exportCSV = () => {
    apiRef.current.exportDataAsCsv();
  };
  const [open, setOpen] = useState(false);

  const [selectedChart, setSelectedChart] = useState("");
  const [selectedBarData, setSelectedBarData] = useState([]);

  useEffect(() => {
    if (dialogData) {
      const data = dialogData[selectedChart];
      if (data) {
        setSelectedBarData(
          data
            ?.filter((obj) => obj.emp_id !== null)
            .map((item, index) => ({ index: index, ...item }))
        );
      }
    }
  }, [selectedChart]);

  const columns =
    selectedBarData.length > 0
      ? Object.keys(selectedBarData[0])
          .filter((key) => !["index"].includes(key))
          .map((key) => {
            return {
              field: key,
              headerName: formatColumnName(key),
              width: key === "productName" || key === "brandName" ? 200 : 170,
              align: "left",
              headerAlign: "left",
            };
          })
      : [];

  const [cursor, setCursor] = useState("default");
  const handleMouseEnter = (bar) => {
    if (dialogData?.[bar?.name]?.length > 0) {
      setCursor("pointer");
    }
  };

  const handleMouseLeave = () => {
    setCursor("default");
  };

  return (
    <Box
      style={{
        width: "100%",
        border: "1px solid #0463FA",
        borderRadius: 5,
        padding: 10,
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          {title}
        </Typography>

        <Button
          disabled={data.every((item) => item.value === 0)}
          variant="contained"
          color="primary"
          onClick={() => {
            if (downloadExternalData && handleDownloadExternalData) {
              handleDownloadExternalData();
            } else {
              handleDownload();
            }
          }}
        >
          Download data
        </Button>
      </Box>
      {data.every((item) => item.value === 0) ? (
        <Box
          width={"100%"}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: minHeight || 350,
          }}
        >
          <Typography sx={{ textAlign: "center" }}>No Data Found</Typography>
        </Box>
      ) : (
        <ResponsiveContainer width="100%" minHeight={minHeight || 350}>
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              cursor={cursor}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={(bar) => {
                if (disableClickable) {
                  return;
                } else {
                  if (dialogData?.[bar?.name]?.length > 0) {
                    setOpen(true);
                  }
                  setSelectedChart(bar?.name);
                }
              }}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltipContent />} />
            <Legend
              payload={data}
              COLORS={COLORS}
              content={CustomLegend}
              layout="vertical"
              width={isMobile && "100%"}
              align={isMobile ? "center" : "right"}
              verticalAlign={isMobile ? "bottom" : "middle"}
            />
            <Tooltip content={<CustomTooltipContent />} />
          </PieChart>
        </ResponsiveContainer>
      )}
      <Portal>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          maxWidth={"xl"}
          fullWidth={true}
        >
          <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
            {title}
          </DialogTitle>
          <Typography
            variant="h6"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {selectedChart}
          </Typography>
          <Box sx={{ marginLeft: "20px" }}>
            <Button
              variant="contained"
              size="small"
              sx={{ height: 40, borderRadius: 4 }}
              onClick={exportCSV}
            >
              Download CSV
            </Button>
          </Box>
          <DialogActions sx={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
          <DialogContent>
            <CustomDataGridLayout
              apiRef={apiRef}
              rows={selectedBarData}
              columns={columns}
              getRowId={(row) => row.index}
              dataGridHeight={"50vh"}
              density="compact"
            />
          </DialogContent>
        </Dialog>
      </Portal>
    </Box>
  );
};

export default DoublePieChart;
