import { Box, Button, ButtonGroup, Container, Grid, Tab, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { TabContext, TabPanel } from "@mui/lab";
import UpcomingData from "./upcomingData";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { getData } from "../../../../../services/api/getApi";
import { getFormattedDDMonthYYYY } from "../../../../../assets/utils";

const dateColWidth = 140;
const columns = [
  { field: "id", hide: false },
  { field: "name", headerName: "Employee Name", width: 300 },
  { field: "department", headerName: "Department", width: 300 },
  { field: "dueDate", headerName: "Next Due Date", width: 200 },
];

const init = {
  columns: columns,
  initialState: {
    columns: {
      columnVisibilityModel: {
        id: false,
      },
    },
  },
  rows: [{ id: 1, name: "", empId: 0 }],
};

const initialState = {
  columns: {
    columnVisibilityModel: {
      id: false,
    },
  },
};

const Index = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [data, setData] = useState();
  const [bloodPressure, setBloodPressure] = useState(init);
  const [sugar, setSugar] = useState(init);
  const [upcomingTableData, setUpcomingTableData] = useState(init);
  const [selectedInterval, setSelectedInterval] = useState("TODAY");
  const fetchNotifications = async () => {
    const url = BASE_URL + `org/opd/upcoming`;
    // const url = BASE_URL + `org/notification/${corpId}`;

    const notifications = await getData(url, "");

    if (notifications.error) {
    } else {
      console.log({ notifications: notifications.data });
      setData(notifications.data);
      // const bp = notifications.data["BP"];
      // const sugar = notifications.data["SUGAR"];

      const upcomingData = notifications.data["TODAY"]?.map((item, index) => ({
        id: index,
        corpId: item.corpId,
        department: item.department,
        dueDate: getFormattedDDMonthYYYY(item?.nextAppointmentDate),
        empId: item.empId,
        empSystemId: item.empSystemId,
        name: item.name,
      }));

      // const dailyBP = bp["TODAY"].map((item, index) => ({
      //   id: index,
      //   corpId: item.corpId,
      //   department: item.department,
      //   dueDate: getFormattedDDMonthYYYY(item.dueDate),
      //   empId: item.empId,
      //   empSystemId: item.empSystemId,
      //   name: item.name,
      // }));
      // const dailSugar = sugar["TODAY"].map((item, index) => ({
      //   id: index,
      //   corpId: item.corpId,
      //   department: item.department,
      //   dueDate: getFormattedDDMonthYYYY(item.dueDate),
      //   empId: item.empId,
      //   empSystemId: item.empSystemId,
      //   name: item.name,
      // }));

      // console.log({ bp: dailyBP });
      // console.log({ sugar: dailSugar });

      const tableData = {
        columns: columns,
        initialState: initialState,
        rows: upcomingData,
      };
      // const tableDataBP = {
      //   columns: columns,
      //   initialState: initialState,
      //   rows: dailyBP,
      // };

      // const tableDataSugar = {
      //   columns: columns,
      //   initialState: initialState,
      //   rows: dailSugar,
      // };

      // setBloodPressure(tableDataBP);
      // setSugar(tableDataSugar);
      setUpcomingTableData(tableData);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (data) {
      // const bp = data["BP"];
      // const sugar = data["SUGAR"];
      const upcomingData = data[selectedInterval]?.map((item, index) => ({
        id: index,
        corpId: item.corpId,
        department: item.department,
        dueDate: getFormattedDDMonthYYYY(item?.nextAppointmentDate),
        empId: item.empId,
        empSystemId: item.empSystemId,
        name: item.name,
      }));
      // const dailyBP = bp[selectedInterval].map((item, index) => ({
      //   id: index,
      //   corpId: item.corpId,
      //   department: item.department,
      //   dueDate: getFormattedDDMonthYYYY(item.dueDate),
      //   empId: item.empId,
      //   empSystemId: item.empSystemId,
      //   name: item.name,
      // }));
      // const dailSugar = sugar[selectedInterval].map((item, index) => ({
      //   id: index,
      //   corpId: item.corpId,
      //   department: item.department,
      //   dueDate: getFormattedDDMonthYYYY(item.dueDate),
      //   empId: item.empId,
      //   empSystemId: item.empSystemId,
      //   name: item.name,
      // }));

      const tableData = {
        columns: columns,
        initialState: initialState,
        rows: upcomingData,
      };
      // const tableDataBP = {
      //   columns: columns,
      //   initialState: initialState,
      //   rows: dailyBP,
      // };

      // const tableDataSugar = {
      //   columns: columns,
      //   initialState: initialState,
      //   rows: dailSugar,
      // };
      // setBloodPressure(tableDataBP);
      // setSugar(tableDataSugar);
      setUpcomingTableData(tableData);
    }
  }, [selectedInterval]);
  return (
    <Container maxWidth={false} sx={{ backgroundColor: "#F5F5F5", minHeight: "88vh" }}>
      <TabContext value={value}>
        <Box sx={{ pt: 2 }}>
          <Grid container rowSpacing={2}>
            {/* <Grid item lg={6}>
              <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  variant="fullWidth"
                  TabIndicatorProps={{ style: { backgroundColor: "white" } }}>
                  <Tab label="Blood Pressure" value="1" sx={{ height: 20 }} />
                  <Tab label="Sugar" value="2" sx={{ height: 20 }} />
                </TabList>
              </Box>
            </Grid> */}
            <Grid item lg={12} display="flex" justifyContent="center">
              <ButtonGroup size="small" variant="contained">
                <Button
                  variant={selectedInterval === "TODAY" ? "contained" : "outlined"}
                  onClick={() => setSelectedInterval("TODAY")}>
                  Today
                </Button>
                <Button
                  variant={selectedInterval === "THIS WEEK" ? "contained" : "outlined"}
                  onClick={() => setSelectedInterval("THIS WEEK")}>
                  This Week
                </Button>
                <Button
                  variant={selectedInterval === "THIS MONTH" ? "contained" : "outlined"}
                  onClick={() => setSelectedInterval("THIS MONTH")}>
                  This Month
                </Button>
              </ButtonGroup>
            </Grid>

            <Grid item lg={12}>
              <TabPanel value="1" sx={{ p: 0 }}>
                <UpcomingData data={upcomingTableData} />
              </TabPanel>
              {/* <TabPanel value="1" sx={{ p: 0 }}>
                <BloodPressure data={bloodPressure} />
              </TabPanel>
              <TabPanel value="2" sx={{ p: 0 }}>
                <Sugar data={upcomingTableData} />
              </TabPanel> */}
            </Grid>
          </Grid>
        </Box>
      </TabContext>
    </Container>
  );
};

export default Index;
