import { Box, Button, Grid, IconButton, Tab, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CustomTitles from "../../../global/titles/customTitles";
import { _getCSREmployeeList } from "../../../global/services/corporateServices";

const CSRStatusTrackerMain = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeListStatic, setEmployeeListStatic] = useState([]);

  useEffect(() => {
    _getCSREmployeeList(corpId, setEmployeeList, setEmployeeListStatic);
  }, []);
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="CSR Status Tracker" />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              height: "60vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Typography>{`Total Number of CSR Employees :${employeeList.length}`}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CSRStatusTrackerMain;
