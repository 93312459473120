import { Fragment } from "react";

import { StrictMode } from "react";
import { HMSRoomProvider } from "@100mslive/react-sdk";
import { Box } from "@mui/material";
import InitiateCall from "./initiateCall";

const VideoMain = ({ name, token }) => {
  return (
    <Fragment>
      <Box sx={{ p: 0 }}>
        <StrictMode>
          <HMSRoomProvider>
            <InitiateCall name={name} token={token} />
          </HMSRoomProvider>
        </StrictMode>
      </Box>
    </Fragment>
  );
};

export default VideoMain;
