import { Box, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Fragment } from "react";

const NumberInputSimple = ({
  label,
  data,
  field,
  length,
  minValue,
  maxValue,
}) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    setValue(data[field]);
  }, [JSON.stringify(data)]);

  const checkMaxValue = (v) => {
    return maxValue ? v <= maxValue : true;
  };
  return (
    <Fragment>
      <Box>
        <Grid container>
          <Grid item>
            <TextField
              fullWidth
              label={label}
              variant="outlined"
              size="small"
              value={value || ""}
              onChange={(e) => {
                if (!isNaN(e.target.value) && checkMaxValue(e.target.value)) {
                  setValue(e.target.value);
                  data[field] = e.target.value;
                }
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
export default NumberInputSimple;
