import { Box, Grid, Stack } from "@mui/material";
import {
  CustomHeadingChipLeft,
  CustomHeadingLeft2,
  CustomHeadingSubTitleLeft,
} from "../../global/headings/customHeadings";
import { CustomParagraph1 } from "../../global/paragraphs/customParagraphs";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

const LandingAppointmentLeft = () => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item lg={12} sx={{ paddingBlock: "5px" }}>
          <CustomHeadingChipLeft>Contact Us</CustomHeadingChipLeft>
        </Grid>
        <Grid item lg={12} sx={{ paddingBlock: "5px" }}>
          <CustomHeadingSubTitleLeft>Have Any Query? Please Contact Us!</CustomHeadingSubTitleLeft>
        </Grid>
        <Grid item lg={12} sx={{ paddingBlock: "5px" }}>
          <CustomParagraph1>
            Feel free to reach out to our clinic for any inquiries or concerns. We are always prompt
            in our response, whether by phone or other means.
          </CustomParagraph1>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Box
            display={"flex"}
            //justifyContent={"center"}
            alignItems={"center"}
            sx={{ backgroundColor: "#EFF5FF", height: 150, px: 5, borderRadius: "10px" }}>
            <Stack direction={"row"} spacing={3}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ height: 60, width: 60, borderRadius: 30, backgroundColor: "#fff" }}>
                <PhoneIcon sx={{ color: "#0463FA" }} />
              </Box>
              <Stack>
                <CustomParagraph1>Call Us Now</CustomParagraph1>
                <CustomHeadingLeft2>1800 8890 189</CustomHeadingLeft2>
              </Stack>
            </Stack>
          </Box>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Box
            display={"flex"}
            //justifyContent={"center"}
            alignItems={"center"}
            sx={{ backgroundColor: "#EFF5FF", height: 150, px: 5, borderRadius: "10px" }}>
            <Stack direction={"row"} spacing={3}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ height: 60, width: 60, borderRadius: 30, backgroundColor: "#fff" }}>
                <EmailIcon sx={{ color: "#0463FA" }} />
              </Box>
              <Stack>
                <CustomParagraph1>Mail Us Now</CustomParagraph1>
                <CustomHeadingLeft2>support@uno.care</CustomHeadingLeft2>
              </Stack>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default LandingAppointmentLeft;
