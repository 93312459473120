import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import CalanderIcon from "../../../assets/images/svg/calendar.svg";
import DownArrow from "../../../assets/images/svg/downArrow.svg";
import { TextField } from "@mui/material";

const Style = {
  calendar: {
    // width: 245,
    minWidth: 230,
    maxWidth: 245,
    px: 2,
    py: 2,
    display: "flex",
    justifyContent: "center",
    height: 50,
    background: "white",
    borderRadius: 10,
    "& .MuiOutlinedInput-input": {
      color: "black",
      fontFamily: "Roboto",
      fontWeight: 600,
      ml: 3,
    },

    "& .MuiInputLabel-root": {
      // color: "red",
      // background: "yellow"
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    // "&:hover .MuiOutlinedInput-input": {
    //   color: "red",
    // },
    // "&:hover .MuiInputLabel-root": {
    //   color: "red",
    // },
    // "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
    // {
    //   borderColor: "red",
    // },
    // "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input":
    // {
    //   color: "purple",
    // },
    // "& .MuiInputLabel-root.Mui-focused": {
    //   color: "purple",
    // },
    // "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    // {
    //   borderColor: "purple",
    // },
  },
};

const DateComp = ({ dateValue, setDateValue }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        openTo="day"
        views={["year", "month", "day"]}
        disableMaskedInput={true}
        inputFormat="dd MMM yyyy"
        value={dateValue}
        onChange={(newValue) => {
          setDateValue(newValue);
        }}
        components={{
          OpenPickerIcon: () => <img src={DownArrow} />,
        }}
        // InputAdornmentProps={{ position: 'end', }}
        InputProps={{
          startAdornment: <img src={CalanderIcon} />,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps, readOnly: true }}
            size="small"
            sx={Style.calendar}
          />
        )}
        PaperProps={{
          sx: {
            "& .MuiPickersDay-root": {
              "&.Mui-selected": {
                backgroundColor: "#127DDD",
              },
            },
            "& .PrivatePickersYear-yearButton": {
              "&.Mui-selected": {
                backgroundColor: "#127DDD",
              },
            },

            "& .PrivatePickersMonth-root": {
              "&.Mui-selected": {
                backgroundColor: "#127DDD",
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};
export default DateComp;
