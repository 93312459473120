import { Box, Grid, Stack, Typography } from "@mui/material";
import { Fragment } from "react";

const CustomHeading = ({ children }) => {
  return (
    <Typography
      sx={{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: 12,
        textTransform: "capitalize",
        color: "#8A8A8A",
      }}>
      {children}
    </Typography>
  );
};

const CustomParagraph = ({ children }) => {
  return (
    <Typography
      sx={{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: 11,
        color: "#000000",
      }}>
      {children}
    </Typography>
  );
};

const VitalCompRx = ({ data = {} }) => {
  return (
    <Box sx={{ py: 2 }}>
      <Fragment>
        <Grid container columns={4} spacing={1}>
          <Grid item lg={4}>
            <Stack display="flex" alignItems="center" direction="row">
              <CustomHeading>Complaint: </CustomHeading>
              <CustomParagraph> {data?.complaint || "N/A"}</CustomParagraph>
            </Stack>
          </Grid>
          {/* *******Vitals ******** */}
          <Grid item lg={1}>
            <Stack display="flex" alignItems="center" direction="row">
              <CustomHeading>Height: </CustomHeading>
              <CustomParagraph> {data?.height || "N/A"}</CustomParagraph>
            </Stack>
          </Grid>
          <Grid item lg={1}>
            <Stack display="flex" alignItems="center" direction="row">
              <CustomHeading>Weight: </CustomHeading>
              <CustomParagraph> {data?.weight || "N/A"}</CustomParagraph>
            </Stack>
          </Grid>
          <Grid item lg={1}>
            <Stack display="flex" alignItems="center" direction="row">
              <CustomHeading>BP: </CustomHeading>
              <CustomParagraph> {data?.bp || "N/A"}</CustomParagraph>
            </Stack>
          </Grid>
          <Grid item lg={1}>
            <Stack display="flex" alignItems="center" direction="row">
              <CustomHeading>Sugar: </CustomHeading>
              <CustomParagraph> {data?.sugar || "N/A"}</CustomParagraph>
            </Stack>
          </Grid>
          {/* *******Flags ******** */}
          <Grid item lg={1}>
            <Stack display="flex" alignItems="center" direction="row">
              <CustomHeading>OHC Type: </CustomHeading>
              <CustomParagraph> {data?.ohcType || "N/A"}</CustomParagraph>
            </Stack>
          </Grid>

          <Grid item lg={1}>
            <Stack display="flex" alignItems="center" direction="row">
              <CustomHeading>Fit to Work: </CustomHeading>
              <CustomParagraph>
                {data?.fitToWork === null ? "N/A" : data?.fitToWork ? "YES" : "NO"}
              </CustomParagraph>
            </Stack>
          </Grid>

          <Grid item lg={1}>
            <Stack display="flex" alignItems="center" direction="row">
              <CustomHeading>Under Medication: </CustomHeading>
              <CustomParagraph>
                {" "}
                {data?.underMedication === null ? "N/A" : data?.underMedication ? "YES" : "NO"}
              </CustomParagraph>
            </Stack>
          </Grid>

          <Grid item lg={1}>
            <Stack display="flex" alignItems="center" direction="row">
              <CustomHeading>Rest: </CustomHeading>
              <CustomParagraph>
                {data?.rest === null ? "N/A" : data?.rest ? "YES" : "NO"}
              </CustomParagraph>
            </Stack>
          </Grid>

          <Grid item lg={1}>
            <Stack display="flex" alignItems="center" direction="row">
              <CustomHeading>Injury Details: </CustomHeading>
              {/* <CustomParagraph> {data?.injuryDetails || "N/A"}</CustomParagraph> */}
            </Stack>
          </Grid>

          <Grid item lg={1}>
            <Stack display="flex" alignItems="center" direction="row">
              <CustomHeading>Injury Remarks: </CustomHeading>
              <CustomParagraph> {data?.injuryRemarks || "N/A"}</CustomParagraph>
            </Stack>
          </Grid>

          <Grid item lg={1}>
            <Stack display="flex" alignItems="center" direction="row">
              <CustomHeading>Medicine: </CustomHeading>
              <CustomParagraph> {data?.medicine || "N/A"}</CustomParagraph>
            </Stack>
          </Grid>

          <Grid item lg={1}>
            <Stack display="flex" alignItems="center" direction="row">
              <CustomHeading>Next Appointment Date: </CustomHeading>
              <CustomParagraph>{data?.nextAppointmentDate || "N/A"}</CustomParagraph>
            </Stack>
          </Grid>

          <Grid item lg={1}>
            <Stack display="flex" alignItems="center" direction="row">
              <CustomHeading>RX Details: </CustomHeading>
              {/* <CustomParagraph> {data?.rxDetails || "N/A"}</CustomParagraph> */}
            </Stack>
          </Grid>
        </Grid>
      </Fragment>
    </Box>
  );
};

export default VitalCompRx;
