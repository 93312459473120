import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, Stack, Typography } from "@mui/material";
import { BASE_URL } from "../../../../../../assets/constantsFile";
import { getData } from "../../../../../../services/api/getApi";
import { useEffect } from "react";

const CustomAutocompleteName = ({
  formValues,
  setFormValues,
  corpId = localStorage.getItem("CORPID"),
}) => {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");

  const [employeeList, setEmployeeList] = React.useState([]);
  const fetchEmployeeData = async () => {
    setFormValues({ corpId: corpId, name: inputValue });
    if (inputValue) {
      const url =
        BASE_URL + `org/employee/search/${inputValue}?corpId=${corpId}`;

      const emp = await getData(url, "");

      if (emp.error) {
        setEmployeeList([]);
      } else {
        console.log({ success: emp.data });
        setEmployeeList(emp.data);
      }
    } else {
      setEmployeeList([]);
    }
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      fetchEmployeeData();
    }, 1000);

    return () => clearTimeout(getData);
  }, [inputValue]);

  useEffect(() => {
    setFormValues({
      corpId: corpId,
      name: value?.empName,
      empId: value?.empId,
    });
  }, [value]);
  return (
    <Box>
      <Stack>
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: 13,
            lineHeightt: 17,
            color: "#000",
            textTransform: "capitalize",
          }}
        >
          {"Name"}
        </Typography>

        <Autocomplete
          size="small"
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id="controllable-states-demo"
          options={employeeList}
          freeSolo
          getOptionLabel={(option) => `${option.empName}`}
          renderOption={(props, option) => (
            <li {...props}>
              <Box>{`${option.empId}  ${option.empName}  ${
                option.mobileNo || ""
              }`}</Box>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              sx={{
                "& .MuiInputLabel-root": { color: "#D4D4D4 ! important" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: "#D4D4D4 ! important" },
                },
                color: "#777777",
                fontSize: 12,
                backgroundColor: "#fff",
                "& input::placeholder": {
                  fontSize: 12,
                },
              }}
              label={""}
              placeholder="Enter Name"
              InputLabelProps={{
                style: {
                  fontSize: 10,
                },
              }}

              //   sx={{
              //     "& .MuiInputLabel-root": { color: "#D4D4D4 ! important" },
              //     "& .MuiOutlinedInput-root": {
              //       "& > fieldset": { borderColor: "#D4D4D4 ! important" },
              //     },
              //   }}
              //   inputProps={{
              //     style: {
              //       color: "#777777",
              //       fontSize: 12,
              //       backgroundColor: "#fff",
              //     },
              //   }}
              //   variant="outlined"
              //   size="small"
              //   label={""}
              //   placeholder={`Enter ${"Emp Id"}`}
            />
          )}
        />
      </Stack>
    </Box>
  );
};
export default CustomAutocompleteName;
