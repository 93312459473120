import RootTemplate from "../../global/templates/rootTemplate";
import AHCHealthRecordMain from "../../modules/ahc/heathRecord/ahcHealthRecordMain";

const AHCHealthRecordIndex = () => {
  return (
    <RootTemplate>
      <AHCHealthRecordMain />
    </RootTemplate>
  );
};

export default AHCHealthRecordIndex;
