import { CssBaseline } from "@mui/material";
import React, { Fragment } from "react";
import MyAppBarAfterLogin from "../common/header/myAppBarAfterLogin";
import PatientRegistrationMainComponent from "../patientRegistration/patientRegistrationMainComponent";

const Registration = (props) => {
  return (
    <Fragment>
      <CssBaseline />
      <MyAppBarAfterLogin />
      <PatientRegistrationMainComponent />
    </Fragment>
  );
};

export default Registration;
