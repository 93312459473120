import { Box, Grid } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useContext, useEffect, useState } from "react";
import { getData } from "../../../services/api/getApi";
import { BASE_URL } from "../../../assets/constantsFile";
import { getDelay } from "../../../assets/utils";
import { userContext } from "../../../common/context/usercontext";
import DateComp from "../components/dateComp";
import Upcoming from "./upcomingDashboard";
import LiveDashboard from "./liveDashboard";
import WalkInDashboard from "./walkInDashboard";
import AttendedDashboard from "./attendedDashboard";
import CancelledDashboard from "./cancelledDashboard";

const DashboardIndex = (props) => {
  const contextData = useContext(userContext);
  const activeTab = props.activeTab ? props.activeTab : "1";
  const [tabValue, setTabValue] = useState(activeTab);
  const [flag, setFlag] = useState(true);
  const [dateValue, setDateValue] = useState(new Date());
  const [patientList, setPatientList] = useState([]);

  const tabClick = (event, newValue) => {
    setFlag(!flag);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchPataientsHandler = async (docId, date, token) => {
    const patients = await getData(
      BASE_URL + "doctor/appointment/" + docId + "?date=" + date,
      token
    );

    if (patients.error) {
    } else {
      const data = await patients.data;
      const transformedPatients = data.map((patientData, index) => {
        return {
          index: index,
          appointmentStatus: patientData.appointmentStatus,
          advice: patientData.advice,
          docId: patientData.docId,
          clinicId: patientData.clinicId,
          patientId: patientData.patientId,
          noOfVisits: patientData.noOfVisits,
          lastVisit: patientData.lastVisit,
          reasonOfVisit: "N/A",
          newTime: "",
          canceledBy: "N/A",

          patient: {
            patientId: patientData.patientId,
            name: patientData.patientName,
            gender: patientData.gender,
            age:
              (patientData.patientInfoVM.age
                ? patientData.patientInfoVM.age + "Y "
                : "") +
              (patientData.patientInfoVM.ageMonths
                ? patientData.patientInfoVM.ageMonths + "M"
                : ""),
          },
          name: patientData.patientName,
          gender: patientData.gender,
          age:
            (patientData.patientInfoVM.age
              ? patientData.patientInfoVM.age + "Y "
              : "") +
            (patientData.patientInfoVM.ageMonths
              ? patientData.patientInfoVM.ageMonths + "M"
              : ""),
          attended: patientData.attended, /////to be added
          issue: patientData.issue, /////to be added

          paymentRequired: patientData.paymentRequired,
          symptom: patientData.symptom,
          startTime: patientData.startTime,
          appointmentDelay: getDelay(patientData.startTime),
          endTime: patientData.endTime,
          date: patientData.date,
          appointmentId: patientData.appointmentId,
          observation: patientData.observation,
          slotNo: patientData.slotNo,
          consultationStartTime: patientData.consultationStartTime,
          consultationEndTime: patientData.consultationEndTime,
          consultationType: patientData.consultationType,
          caseId: patientData.caseId,
          doctorName: patientData.doctorName,
          doctorSpeciality: patientData.doctorSpeciality,
          amountPayable: patientData.amountPayable,
          amountCollected: patientData.amountCollected,
          modeOfPayment: patientData.modeOfPayment,
          patientName: patientData.patientName,
          firstConsultationDate: patientData.firstConsultationDate,
          lastConsultationDate: patientData.lastConsultationDate,

          firstName: patientData.firstName,
          middleName: patientData.middleName,
          lastName: patientData.lastName,
          fatherName: patientData.fatherName,
          dateOfBirth: patientData.dateOfBirth,

          email: patientData.email,
          mobile: patientData.mobile,
          countryCode: patientData.countryCode,

          guardianFirstName: patientData.guardianFirstName,
          guardianLastName: patientData.guardianLastName,
          guardianContactNumber: patientData.guardianContactNumber,
          source: patientData.source,

          videoURL: patientData.videoURL,
          guestURLToken: patientData.guestURLToken,
          hostURLToken: patientData.hostURLToken,
          vitals: patientData.vitals,
          prescriptionVM: patientData.prescriptionVM,
          reports: patientData.reports,
          paymentVM: patientData.paymentVM,
          personalVisitNeeded: patientData.personalVisitNeeded,
          nextAppointmentDate: patientData.nextAppointmentDate,

          patientDeatils: {
            consultationType: patientData.consultationType,
            advice: patientData.advice,
            personalVisitNeeded: patientData.personalVisitNeeded,
            nextAppointmentDate: patientData.nextAppointmentDate,
            patientData: patientData,
            appointmentId: patientData.appointmentId,
            patientId: patientData.patientId,
            caseId: patientData.caseId,
            docId: patientData.docId,
            videoURL: patientData.videoURL,
            name: patientData.patientName,
            guestURLToken: patientData.guestURLToken,
            hostURLToken: patientData.hostURLToken,
            doctorName: patientData.doctorFullName,

            patient: {
              symptom: patientData.symptom,
              patientId: patientData.patientId,
              name: patientData.patientName,
              gender: patientData.gender,
              age:
                (patientData.patientInfoVM.age
                  ? patientData.patientInfoVM.age + "Y "
                  : "") +
                (patientData.patientInfoVM.ageMonths
                  ? patientData.patientInfoVM.ageMonths + "M"
                  : ""),
              issue: patientData.issue,
            },
            vitals: patientData.vitals,
            reports: patientData.reports,
            prescriptionVM: patientData.prescriptionVM,
            observation: patientData.observation ? patientData.observation : "",
            issue: patientData.issue,
            patientMedicalHistory: {
              patientName: patientData.patientName,
              habits: patientData.habits,
              preExistingConditions: patientData.preExistingConditions,
              drugHistory: patientData.drugHistory,
              drugAllergy: patientData.drugAllergy,
              parentalHistory: patientData.parentalHistory,
              occupation: patientData.occupation,
            },
          },

          printPrescription: patientData,
        };
      });
      setPatientList(transformedPatients);
      console.log("1111");
    }
  };

  useEffect(() => {
    fetchPataientsHandler(
      localStorage.getItem("DOCID"),
      dateValue.toISOString().split("T")[0],
      contextData.authHeader
    );

    console.log("refresh on tab click");
  }, [dateValue, flag]);

  console.log({ patientList_attended_bydate: patientList });

  return (
    <TabContext value={tabValue}>
      <Box sx={{ mb: 3 }}>
        <Grid container>
          <Grid item lg={9} md={10} sm={10} xs={12}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              sx={Style.tabList}
              variant="fullWidth"
            >
              <Tab
                onClick={tabClick}
                label="Live Calls"
                value="1"
                sx={Style.defaultTabStyle}
              />
              <Tab
                onClick={tabClick}
                label="Walk In"
                value="2"
                sx={Style.defaultTabStyle}
              />
              <Tab
                onClick={tabClick}
                label="Upcoming"
                value="3"
                sx={Style.defaultTabStyle}
              />
              <Tab
                onClick={tabClick}
                label="Attended"
                value="4"
                sx={Style.defaultTabStyle}
              />
              <Tab
                onClick={tabClick}
                label="Cancelled"
                value="5"
                sx={Style.defaultTabStyle}
              />
            </TabList>
          </Grid>

          <Grid
            item
            lg={3}
            md={2}
            sm={2}
            xs={12}
            display="flex"
            justifyContent="flex-end"
          >
            <DateComp dateValue={dateValue} setDateValue={setDateValue} />
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          background: "#fff",
          border: "none",
        }}
      >
        <TabPanel value="1" sx={{ p: 0 }}>
          <LiveDashboard
            patientList={patientList}
            dateValue={dateValue}
            setDateValue={setDateValue}
          />
        </TabPanel>
        <TabPanel value="2" sx={{ p: 0 }}>
          <WalkInDashboard patientList={patientList} />
        </TabPanel>
        <TabPanel value="3" sx={{ p: 0 }}>
          <Upcoming patientList={patientList} />
        </TabPanel>
        <TabPanel value="4" sx={{ p: 0 }}>
          <AttendedDashboard patientList={patientList} />
        </TabPanel>
        <TabPanel value="5" sx={{ p: 0 }}>
          <CancelledDashboard patientList={patientList} />
        </TabPanel>
      </Box>
    </TabContext>
  );
};

export default DashboardIndex;

const Style = {
  tabList: {
    border: "0.5px solid #979797",
    borderRadius: 6,
    //width: "80%",
    ".MuiTabs-indicator": {
      height: 0,
    },
  },
  defaultTabStyle: {
    fontWeight: "500",
    fontFamily: "Roboto",
    fontSize: 16,
    color: "black",
  },
};
