import React, { useState } from "react";
import { Avatar, Box, Card, CardContent, Grid, IconButton, Stack } from "@mui/material";
import { CustomParagraph1, CustomParagraphWhite1 } from "../../global/paragraphs/customParagraphs";
import { CustomHeadingWhiteCenter2 } from "../../global/headings/customHeadings";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";

const CustomCardCenter = ({ item, _nextSlide, _prevSlide }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        minWidth: {
          xs: "100%", // Screen size is xs (extra small)
          sm: "100%", // Screen size is sm (small)
          md: "100%", // Screen size is md (medium)
          lg: "100%", // Screen size is lg (large)
          xl: "100%", // Screen size is xl (extra large)
        },
        overflow: "visible",
        marginBottom: "30px",
      }}>
      <Grid container spacing={3} sx={{ marginBottom: "30px" }}>
        <Grid item lg={12} xs={12} display={"flex"} justifyContent={"center"}>
          <Stack direction="row" spacing={6}>
            <IconButton onClick={_prevSlide}>
              <WestIcon sx={{ color: "#0463FA" }} />
            </IconButton>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                height: 100,
                width: 100,
                borderRadius: 50,
                background: "#0463FA",
              }}>
              <Avatar
                sx={{
                  background: "#fff",
                  height: 80,
                  width: 80,
                  color: "#000",
                  fontWeight: 700,
                  fontSize: 60,
                }}>
                {item?.avatar}
              </Avatar>
            </Box>
            <IconButton onClick={_nextSlide}>
              <EastIcon sx={{ color: "#0463FA" }} />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
      <Card
        sx={{
          height: {
            xs: "auto", // Screen size is xs
            sm: "auto", // Screen size is sm
            md: "auto", // Screen size is md
            lg: 300, // Screen size is lg
            xl: 300, // Screen size is xl
          },
          background: "#0463FA",
          p: 3,
          width: "100%",
          overflow: "hidden",
        }}>
        <CardContent>
          <Grid container spacing={1} textAlign="center">
            <Grid item lg={12} xs={12}>
              <CustomParagraphWhite1>{item?.testimony}</CustomParagraphWhite1>
            </Grid>
            <Grid item lg={12} xs={12} display="flex" justifyContent="center" alignItems={"center"}>
              <CustomHeadingWhiteCenter2>{item?.name}</CustomHeadingWhiteCenter2>
            </Grid>
            <Grid item lg={12} xs={12}>
              <CustomParagraphWhite1>{item?.profession}</CustomParagraphWhite1>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
const CustomCardSides = ({ item }) => {
  return (
    <Box sx={{ marginBottom: "30px" }}>
      <Grid container spacing={3} sx={{ marginBottom: "30px" }}>
        <Grid item lg={12} display={"flex"} justifyContent={"center"}>
          <Stack direction="row" spacing={6}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                height: 100,
                width: 100,
                borderRadius: 50,
                background: "#0463FA",
              }}>
              <Avatar
                sx={{
                  background: "#fff",
                  height: 80,
                  width: 80,
                  color: "#000",
                  fontWeight: 700,
                  fontSize: 60,
                }}>
                {item?.avatar}
              </Avatar>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Card
        sx={{
          height: {
            xs: "auto", // Screen size is xs
            sm: "auto", // Screen size is sm
            md: "auto", // Screen size is md
            lg: 300, // Screen size is lg
            xl: 300, // Screen size is xl
          },
          background: "#0463FA",
          p: 3,
          width: "100%",
          overflow: "hidden",
        }}>
        <CardContent>
          <Grid container spacing={1} textAlign="center">
            <Grid item lg={12}>
              <CustomParagraphWhite1>{item?.testimony}</CustomParagraphWhite1>
            </Grid>
            <Grid item lg={12} display="flex" justifyContent="center" alignItems={"center"}>
              <CustomHeadingWhiteCenter2>{item?.name}</CustomHeadingWhiteCenter2>
            </Grid>
            <Grid item lg={12}>
              <CustomParagraphWhite1>{item?.profession}</CustomParagraphWhite1>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

const TestimonialCarousel = () => {
  const data = [
    {
      id: 1,
      avatar: "K",
      name: "Krishnanand Prajapati",
      testimony:
        "मैं अनुभव से बहुत खुश हूं। उनके कर्मचारी, डॉक्टर बहुत अच्छे हैं। 10 मिनट के भीतर मुझे ईएनटी डॉक्टर से जोड़ा गया और उन्होंने एक विशेष उपकरण का उपयोग करके मेरे कान को देखा और मुझे सही दवाएं दीं। मैं 3-4 दिनों में ठीक हो गया। इससे पहले मैंने कई झोलाछापों के साथ अपना पैसा बर्बाद किया। यह देखकर अच्छा लगता है कि हमारे छोटे शहरों में ऐसी सुविधाएं आ रही हैं। अब इलाज के लिए 50 किमी दूर नहीं जाना पड़ेगा।",
      // profession: "Profession 1",
    },
    {
      id: 2,
      avatar: "P",
      name: "Priyanka Saxena",
      testimony:
        "Mene recently online consultancy krwai hai dr. Sheetal mahajan mam se jo ki ek gaenecologist hai ye mera ek new experience tha. Without hesitation aap apne dr. Se bat kar skte ho, online consultancy kar sakte ho. Muje ye bhi mujhe accha laga ki sayd kisi ko kuch problem ho rhi ho thodi bhut lkin wo us problem ko ignor kar rha hai uske busy rhne ki vjah se to wo bina disturb hue apna proper treatment krwa skta hai .....overall mera experience bhut accha rha aur mai sbhi ko es chij ko try karne ke liye jrur suggests krungi ....",
      // profession: "Profession 2",
    },
    {
      id: 3,
      avatar: "A",
      name: "Ankit Mishra",
      testimony:
        "मुझे अपने ही किसी परिचित ने बताया था आपके क्लिनिक के बारे में … मुझे माहवारी से सम्बन्धी समस्या थी पर यहाँ कोई अच्छी मेडम नहीं बैठती है। मेडम को दिखाने के लिए mhow या फिर इंदौर जाना पड़ता है , इसलिए   मैं मेडम से पूरा treatment  नहीं  करवा पाती थी पर आपके क्लिनिक खुलने की वजह से अब मै पूरा इलाज करवा पाऊँगी . आपका स्टाफ़ सभी चिजोंको अच्छी तरह समझाता है ओर बाद में फ़ोन लगा कर हाल चाल भीपूछता है की आपको कितना फ़ायदा है ये चीज़ मेने इसके पहले कहीं भी नहीं देखी iska मतलब यह है कि आपको हमारी फ़िक्र है  😊 आपसे एक आग्रह है  की आपको पशुओं के लिए भी डॉक्टर की सुविधा करनी चाहिए इसकी hame सबसे jyada ज़रूरत है",
      // profession: "Profession 3",
    },
  ];
  const [item, setItem] = useState(data[0]);
  const [count, setCount] = useState(0);
  const itemCount = data.length; // Get the length of the data array

  const _nextSlide = () => {
    let temp = count + 1;
    setItem(temp >= itemCount ? data[0] : data[temp]);
    setCount(temp >= itemCount ? 0 : temp);
  };

  const _prevSlide = () => {
    let temp = count - 1;
    setItem(temp < 0 ? data[itemCount - 1] : data[temp]);
    setCount(temp < 0 ? itemCount - 1 : temp);
  };

  const previousItem = data[count === 0 ? itemCount - 1 : count - 1];
  const nextItem = data[count === itemCount - 1 ? 0 : count + 1];

  return (
    // <Grid container spacing={3} sx={{ marginBottom: "20px", paddingInline: "20px" }}>
    //   <Grid item lg={12}>
    //     <Box>
    <Grid container spacing={5}>
      <Grid item lg={3} sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <Box
          sx={{
            opacity: 0.1,
            // maxWidth: 288,
            overflow: "hidden",
          }}>
          <CustomCardSides item={previousItem} />
        </Box>
      </Grid>
      <Grid item lg={6}>
        <Box>
          <CustomCardCenter item={item} _nextSlide={_nextSlide} _prevSlide={_prevSlide} />
        </Box>
      </Grid>
      <Grid item lg={3} sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <Box
          sx={{
            opacity: 0.1,
            // maxWidth: 288,
            overflow: "hidden",
          }}>
          <CustomCardSides item={nextItem} />
        </Box>
      </Grid>
    </Grid>
    //     </Box>
    //   </Grid>
    // </Grid>
  );
};

export default TestimonialCarousel;
