import React, { Fragment, useState } from "react";
import { Box, Container, Grid, Stack, Typography, useMediaQuery } from "@mui/material";

import { getFormattedDDMonthYYYY } from "../../../../../assets/utils";
import { Carousel } from "react-responsive-carousel";
import forwardArrow from "../../../../assets/images/forwardArrow.png";
import backArrow from "../../../../assets/images/backArrow.png";

const SpecialitySlide = ({ data, isSelected }) => (
  <Grid container spacing={1}>
    <Grid item lg={12} md={12} sm={12} xs={12}>
      <Stack display="flex" justifyContent="center" alignItems={"center"}>
        <Box
          component="img"
          alt={data?.photoName}
          src={data?.photosUrl}
          sx={{
            alignSelf: "flex-end",
            display: "flex",
            background: "#fff",
            height: 400,
            width: 450,
            border: 1,
            px: 5,
          }}
        />
        <Box sx={{ padding: "20px" }}>
          {data?.date && <Typography>{getFormattedDDMonthYYYY(new Date(data?.date))}</Typography>}
        </Box>
      </Stack>
    </Grid>
  </Grid>
);

const ImageGallerySliderComp = ({ slideList }) => {
  const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;
  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePrevClick = () => {
    setCurrentSlide((prevSlide) => (prevSlide > 0 ? prevSlide - 1 : slideList.length - 1));
  };

  const handleNextClick = () => {
    setCurrentSlide((prevSlide) => (prevSlide < slideList.length - 1 ? prevSlide + 1 : 0));
  };

  const isXSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Fragment>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}>
        <Box>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "Bold",
              lineHeight: "24px",
            }}>
            All Past Healthcamps
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "120px",
            justifyContent: "space-between",
          }}>
          <Box
            component={"img"}
            src={backArrow}
            sx={{ height: "20px", cursor: "pointer" }}
            onClick={handlePrevClick}
          />
          <Box
            component={"img"}
            src={forwardArrow}
            sx={{ height: "20px", cursor: "pointer" }}
            onClick={handleNextClick}
          />
        </Box>
      </Stack>
      <Box sx={{ width: "100%", display: { xs: "block", lg: "flex" } }}>
        <Container maxWidth={false}>
          <Carousel
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            infiniteLoop={true}
            centerMode={true}
            centerSlidePercentage={isXSmallScreen ? "100" : "35"}
            interval={5000}
            showIndicators={false}
            selectedItem={currentSlide}
            renderItem={customRenderItem}>
            {slideList.map((item, index) => (
              <SpecialitySlide key={index} data={item} />
            ))}
          </Carousel>
        </Container>
      </Box>
    </Fragment>
  );
};

export default ImageGallerySliderComp;
