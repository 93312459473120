import { Box, Grid, IconButton, TextField, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { BASE_URL } from "../../../../assets/constantsFile";
import { saveData } from "../../../../services/api/postApi";
import saveIcon from "../../../../assets/images/svg/formEdit.svg";
import editIcon from "../../../../assets/images/svg/editIcon.svg";

const style = {
    row: {
        display: "flex",
        alignItems: "center",
        px: 1,
        py: 0.87,
        borderRadius: "16.39px",
        background: "#F4FBFF",
    },
    title: { fontFamily: "Roboto", fontWeight: "600", fontSize: "1rem", lineHeight: "19px", color: "#127DDD" },
    textRow: { display: "flex", alignItems: "center" },
    text: { fontFamily: "Roboto", fontWeight: "400", fontSize: "1rem", lineHeight: "19px", pl: 2 },
    border: { height: "36px", borderLeft: "1px solid #127ddd" },
    icon: { mt: 0.25, display: "flex", justifyContent: "center" },
};

const ChiefComplaintComp = ({ label = "Chief Complain", Key, chiefComplaintsValue, appointmentId }) => {
    const [editIssue, setEditIssue] = useState(chiefComplaintsValue ? true : false);
    const [issue, setIssue] = useState(chiefComplaintsValue);

    const updateIssue = (e) => {
        e.preventDefault();
        setEditIssue(!editIssue);

        if (editIssue === false) {
            let o = {
                chiefComplaints: issue,
                appointmentId: appointmentId,
            };

            updateIssueByDoc(o);
            console.log({ issue: o });
        }
    };

    const updateIssueByDoc = async (obj) => {
        const user = await saveData(BASE_URL + "doctor/prescription/chiefComplaints", obj, "");

        if (user.error) {
            console.log("error");
        } else {
            console.log("success");
        }
    };
    return (
        <Fragment>
            <Box>
                <form onSubmit={updateIssue}>
                    <Grid container sx={style.row}>
                        <Grid item lg={3}>
                            <Typography sx={style.title}>{label}</Typography>
                        </Grid>
                        <Grid item lg={8} sx={style.textRow}>
                            <Box sx={style.border}></Box>
                            {editIssue && <Typography sx={style.text}>{issue}</Typography>}
                            {!editIssue && (
                                <TextField
                                    label="To be written by Doctor"
                                    size="small"
                                    fullWidth
                                    value={issue || ""}
                                    sx={{
                                        py: 1,
                                        px: 0.5,
                                        "& .MuiOutlinedInput-root:hover": {
                                            "& > fieldset": {
                                                borderColor: "#127DDD",
                                            },
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            "&.Mui-focused fieldset": {
                                                borderColor: "#127DDD",
                                            },
                                        },
                                    }}
                                    onChange={(e) => {
                                        setIssue(e.target.value);
                                    }}
                                    inputProps={{
                                        style: { fontSize: ".875rem", fontWeight: "500" },
                                    }}
                                    InputLabelProps={{
                                        style: { fontSize: ".875rem", fontWeight: "500", color: "#127DDD" },
                                    }}
                                    disabled={editIssue}
                                />
                            )}
                        </Grid>
                        <Grid item lg={1} sx={style.icon}>
                            {editIssue && (
                                <IconButton aria-label="edit" size="small" type="submit">
                                    <img src={editIcon} sx={{ width: "100%" }} />
                                </IconButton>
                            )}
                            {!editIssue && (
                                <IconButton aria-label="edit" size="small" type="submit">
                                    <img src={saveIcon} sx={{ width: "100%" }} />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Fragment>
    );
};

export default ChiefComplaintComp;
