import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const PrivateRouter = ({ Page, Default, token = localStorage.getItem("authHeader_local") }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  let location = useLocation();

  useEffect(() => {
    //const token = localStorage.getItem("authHeader_local");
    //alert(token);
    setIsLoggedIn(token ? true : false);
  }, [token]);

  //console.log({ token, location });

  if (isLoggedIn) {
    return <Page />;
  }

  return <Default />;
};

export default PrivateRouter;
