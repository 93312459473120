import RootTemplate from "../../global/templates/rootTemplate";
import CSRPhotoGalleryMain from "../../modules/csr/photoGallery/csrPhotoGalleryMain";

const CSRPhotoGalleryIndex = () => {
  return (
    <RootTemplate>
      <CSRPhotoGalleryMain />
    </RootTemplate>
  );
};

export default CSRPhotoGalleryIndex;
