import React from "react";
import BookSessions from "../../modules/healthAwarenessSessions/bookSession/bookSessions";
import RootTemplate from "../../global/templates/rootTemplate";

const BookSessionIndex = () => {
  return (
    <RootTemplate>
      <BookSessions />
    </RootTemplate>
  );
};

export default BookSessionIndex;
