import axios from "axios";
import { BASE_URL } from "../../assets/constantsFile";
import { Resolver } from "../resolver/resolver";

export async function getData(URL, TOKEN) {
  let authHeader_local = localStorage.getItem("authHeader_local");
  const headers = {
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(axios.get(URL, { headers }).then((res) => res.data));
}

export async function getDataNew(URL) {
  let authHeader_local = localStorage.getItem("authHeader_local");
  const headers = {
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(axios.get(URL, { headers }).then((res) => res.data));
}

export async function getDataWithoutToken(URL) {
  let authHeader_local = localStorage.getItem("authHeader_local");
  const headers = {
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(axios.get(URL).then((res) => res.data));
}

export async function getPatientDetailsByPatientId(pId, docId) {
  let authHeader_local = localStorage.getItem("authHeader_local");

  const headers = {
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(
    axios
      .get(BASE_URL + "patient/detailed/" + pId + "?docId=" + docId, {
        headers,
      })
      .then((res) => res.data)
  );
}

export async function getVitalsByPatientId(pId) {
  let authHeader_local = localStorage.getItem("authHeader_local");

  const headers = {
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(
    axios.get(BASE_URL + "patient/vitals/" + pId, { headers }).then((res) => res.data)
  );
}

export async function getReportsByPatientId(pId) {
  let authHeader_local = localStorage.getItem("authHeader_local");

  const headers = {
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(
    axios.get(BASE_URL + "patient/reports/" + pId, { headers }).then((res) => res.data)
  );
}

export async function getListOfTest() {
  let authHeader_local = localStorage.getItem("authHeader_local");

  const headers = {
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(axios.get(BASE_URL + "lab/tests/all", { headers }).then((res) => res.data));
}

export async function getPListByDocIdAndDate(docId, date) {
  let authHeader_local = localStorage.getItem("authHeader_local");
  const headers = {
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(
    axios
      .get(BASE_URL + "doctor/basic/" + docId + "?date=" + date, { headers })
      .then((res) => res.data)
  );
}
