import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  Portal,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { Fragment, useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import DownloadCSV from "../../../../global/components/downloadCSV";
import MaskingButton from "../../../../global/components/maskingButton";
import StatusTrackerDetailGrid from "../../../../global/customDatagrids/statusTrackerDetailGrid";

const YearOnYearMultiBarChart = ({
  data,
  title,
  dialogData = [],
  testKeyName,
}) => {
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#FFF",
            borderRadius: "5px",
            paddingInline: 2,
          }}
        >
          <p className="label">{`${label}`}</p>
          {payload.map((entry, index) => (
            <p key={index} style={{ color: entry.color }}>
              {`${entry.name}: ${entry.payload[entry.dataKey]}% (${
                entry.payload[`${entry.dataKey.replace("_percent", "_value")}`]
              })`}
            </p>
          ))}
        </div>
      );
    }

    return null;
  };
  const formatXAxis = (value) => {
    return `${value}%`;
  };

  const generateCustomTicks = (data) => {
    const maxValue = Math.max(...data.map((item) => item.value));
    const ticks = [];
    for (let i = 0; i <= maxValue; i++) {
      ticks.push(i);
    }
    return ticks;
  };

  const [apiRef, setApiRef] = useState(null);
  const exportCSV = () => {
    apiRef.current.exportDataAsCsv();
  };
  const [open, setOpen] = useState(false);
  const [selectedChart, setSelectedChart] = useState("");
  const [selectedBarData, setSelectedBarData] = useState([]);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedChart("");
    setSelectedBarData([]);
  };

  console.log({ dialogData });

  useEffect(() => {
    if (dialogData) {
      const data = dialogData[selectedChart];

      if (data) {
        setSelectedBarData(
          data?.map((value, index) => ({
            id: `employee ${index}`,
            emp_id: value.empId || value.emp_id,
            name: value.name,
            department: value.department,
          }))
        );
      } else {
        console.log("Selected chart not found in dialogData");
      }
    }
  }, [selectedChart]);

  console.log({ selectedBarData });

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          border: "1px solid grey",
          paddingInline: "10px",
          pt: "10px",
          borderRadius: "10px",
        }}
      >
        <Typography
          textAlign="left"
          sx={{
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "120%",
            marginBottom: "20px",
            textTransform: "capitalize",
          }}
        >
          {title}
        </Typography>
        <Box sx={{ height: 290 }}>
          <ResponsiveContainer width="100%" height={"100%"}>
            <BarChart
              layout="vertical"
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 20,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" tickFormatter={formatXAxis}>
                <Label
                  value="% of employees"
                  offset={-10}
                  position="insideBottom"
                  style={{ fontSize: 13 }}
                />
              </XAxis>

              <YAxis
                type="category"
                dataKey="name"
                width={100}
                ticks={generateCustomTicks(data || [])}
                tickFormatter={(tick) => tick}
                style={{ fontSize: 12 }}
              />

              <Tooltip content={<CustomTooltip />} />
              <Legend verticalAlign="top" height={50} />
              {Object.keys(data?.[0] || {})
                .filter((key) => key.endsWith("_percent"))
                .map((key, index) => (
                  <Bar
                    barSize={
                      Object.keys(data?.[0] || {}).filter((key) =>
                        key.endsWith("_percent")
                      ).length <= 2
                        ? 25
                        : null
                    }
                    key={index}
                    dataKey={key}
                    name={key.replace("_percent", "")}
                    fill={index % 2 === 0 ? "#8884d8" : "#82ca9d"}
                    onClick={(bar) => {
                      console.log({
                        bar,
                        campName: bar.tooltipPayload?.[0].name,
                        testKeyName,
                        parameter: bar.payload.name,
                      });
                      handleOpen();
                      setSelectedBarData(
                        dialogData?.[bar?.tooltipPayload?.[0]?.name]?.[
                          testKeyName
                        ]?.[bar?.payload?.name]
                      );
                      setSelectedChart(
                        `${bar.tooltipPayload?.[0].name} ${bar.payload.name}`
                      );
                    }}
                  />
                ))}
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Box>

      <Portal>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={"xl"}
          fullWidth={true}
        >
          <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
            {title}
          </DialogTitle>
          <Typography
            variant="h6"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {selectedChart}
          </Typography>
          <Box sx={{ marginLeft: "22px" }}>
            <Stack direction="row" spacing={1}>
              <DownloadCSV exportCSV={exportCSV} type="ICON" />
              <MaskingButton type="ICON" />
            </Stack>
          </Box>
          <DialogActions sx={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
          <DialogContent>
            <StatusTrackerDetailGrid
              setApiRef={setApiRef}
              employeeList={selectedBarData || []}
            />
          </DialogContent>
        </Dialog>
      </Portal>
    </Fragment>
  );
};

export default YearOnYearMultiBarChart;
