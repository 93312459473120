import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { BASE_URL } from "../../assets/constantsFile";
import ShowNoticeMessage from "../../common/Notice/showNoticeMessage";
import { saveData } from "../../services/api/postApi";
import BookAppointmentMainComp from "../bookAppointment/bookAppointmentMainComp";
import PatientAge from "./formElements/patientAge";
import PatientEmailId from "./formElements/patientEmailId";
import PatientGender from "./formElements/patientGender";
import PatientName from "./formElements/patientName";
import PatientPhoneNumber from "./formElements/patientPhoneNumber";

const RegistrationContentMainComp = () => {
  const [isBooked, setIsBooked] = useState(false);
  const [noticeData, setNoticeData] = useState({
    severity: "info",
    message: "",
    openNotice: false,
  });
  const [key, setKey] = useState(0);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [patient, setPatient] = useState(null);
  const [formValues, setFormValues] = useState({ patientId: 0 });
  const [isError, setIsError] = useState(false);
  const [nameField, setNameField] = useState(null);
  const [selectedPatientId, setselectedPatientId] = useState(0);

  useEffect(() => {
    setselectedPatientId(patient ? patient.patientId : 0);
  }, [patient]);

  console.log({ nameField: nameField });

  const registerPatientHandler = () => {
    const OBJ = formValues;
    console.log({ OBJ: OBJ });
    if (!nameField) {
      setIsError(true);
    } else {
      setIsError(false);
      savePatient(OBJ);
    }
  };

  const savePatient = async (data) => {
    const url = BASE_URL + "patient";
    const regPatient = await saveData(url, data, "");

    if (regPatient.error) {
      console.log("falied");
      setNoticeData({
        severity: "error",
        message: "Failed.",
        openNotice: true,
      });
      setKey(key + 1);
    } else {
      console.log("success");
      console.log({ "regPatient.data": regPatient.data });
      setNoticeData({
        severity: "success",
        message: "Saved Successfully.",
        openNotice: true,
      });
      setKey(key + 1);
      setselectedPatientId(regPatient.data.patientId);
      handleClickOpen();
      setPatient(regPatient.data);
    }
  };

  console.log({ patient: patient });
  console.log({ selectedPatientId: selectedPatientId });

  if (isBooked) {
    return (
      <Fragment>
        <Box sx={{ pl: 8, mt: 15 }}>
          <Container
            maxWidth={"sm"}
            sx={{
              border: 2,
              borderRadius: 2,
              height: "50vh",
              borderColor: "#208F94",
              p: 5,
            }}
          >
            <Grid container spacing={2}>
              <Grid item lg={12} display="flex" justifyContent="center">
                <Typography>Appointment booked Successfully.</Typography>
              </Grid>

              <Grid item lg={12}>
                <Typography>
                  Patient Name: {patient ? patient.fullName : ""}
                </Typography>
              </Grid>

              <Grid item lg={12}>
                <Typography>Age: {patient ? patient.age : ""}</Typography>
              </Grid>

              <Grid item lg={12}>
                <Typography>Gender: {patient ? patient.gender : ""}</Typography>
              </Grid>

              <Grid
                item
                lg={12}
                display="flex"
                justifyContent="center"
                sx={{ mt: 3 }}
              >
                <Stack direction="row">
                  <Button variant="contained" size="small" href="/patients">
                    Go to dashboard
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <ShowNoticeMessage noticeData={noticeData} key={key} />

      <Fragment>
        <BookAppointmentMainComp
          open={open}
          handleClose={handleClose}
          selectedPatientId={selectedPatientId}
          setIsBooked={setIsBooked}
        />
      </Fragment>
      <Box>
        <Grid container spacing={1}>
          <Grid
            item
            lg={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              borderBottom: "3px solid #208F94",
            }}
          >
            <Typography variant="h6">Patient Registration</Typography>
          </Grid>
          <Grid item lg={12}>
            <Box sx={{ p: 2, minHeight: "50vh", mt: 7 }}>
              <Container maxWidth={"md"}>
                <Grid container spacing={2}>
                  <Grid item lg={12}>
                    <PatientName
                      patient={patient}
                      setPatient={setPatient}
                      isError={isError}
                      setNameField={setNameField}
                      formValues={formValues}
                    />
                  </Grid>
                  <Grid item lg={12}>
                    <PatientPhoneNumber
                      patient={patient}
                      setPatient={setPatient}
                      formValues={formValues}
                    />
                  </Grid>

                  <Grid item lg={12}>
                    <PatientEmailId
                      patient={patient}
                      setPatient={setPatient}
                      formValues={formValues}
                    />
                  </Grid>

                  <Grid item lg={6}>
                    <PatientGender
                      patient={patient}
                      setPatient={setPatient}
                      formValues={formValues}
                    />
                  </Grid>

                  <Grid item lg={6}>
                    <PatientAge
                      patient={patient}
                      setPatient={setPatient}
                      formValues={formValues}
                    />
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Grid>
          <Grid item lg={12}>
            <Box>
              <Container>
                <Grid container>
                  <Grid item lg={12} display="flex" justifyContent={"center"}>
                    {patient ? (
                      <Stack direction="row" spacing={4}>
                        <Button
                          sx={{ width: 280 }}
                          variant="contained"
                          onClick={() => registerPatientHandler()}
                        >
                          Update Patient Information
                        </Button>

                        <Button
                          sx={{ width: 280 }}
                          disabled={
                            selectedPatientId && selectedPatientId > 0
                              ? false
                              : true
                          }
                          variant="contained"
                          onClick={() => {
                            //registerPatientHandler();
                            handleClickOpen();
                          }}
                        >
                          Book Appointment
                        </Button>
                      </Stack>
                    ) : (
                      <Button
                        sx={{ width: 280 }}
                        variant="contained"
                        onClick={() => registerPatientHandler()}
                      >
                        Register New Patient
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default RegistrationContentMainComp;
