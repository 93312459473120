import { Box, Grid, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import NumberInputSimple from "../../../common/formComponents/numberInputSimple";

const PatientAge = ({ patient, setPatient, formValues }) => {
  const [ageYears, setAgeYears] = useState("");
  const [ageMonth, setAgeMonth] = useState("");

  useEffect(() => {
    setAgeYears(patient ? patient.age : "");
    setAgeMonth(patient ? patient.ageMonths : "");

    formValues["age"] = patient ? patient.age : "";
    formValues["ageMonths"] = patient ? patient.ageMonths : "";
  }, [patient]);
  return (
    <Fragment>
      <Box>
        <Grid container spacing={1}>
          <Grid item lg={4} sx={{ display: "flex", alignItems: "center" }}>
            <Typography>Age:</Typography>
          </Grid>
          <Grid item lg={4}>
            <NumberInputSimple
              label={"Years"}
              data={formValues}
              field={"age"}
              maxValue={100}
            />
            {/* <TextField
              fullWidth
              label="Years"
              variant="outlined"
              size="small"
              value={ageYears || ""}
              onChange={(e) => {
                if (!isNaN(e.target.value) && e.target.value <= 100) {
                  setAgeYears(e.target.value);
                  formValues["age"] = e.target.value;
                }
              }}
            /> */}
          </Grid>
          <Grid item lg={4}>
            <NumberInputSimple
              label={"Months"}
              data={formValues}
              field={"ageMonths"}
              maxValue={11}
            />
            {/* <TextField
              fullWidth
              label="Months"
              variant="outlined"
              size="small"
              value={ageMonth || ""}
              onChange={(e) => {
                if (!isNaN(e.target.value) && e.target.value < 12) {
                  setAgeMonth(e.target.value);
                  formValues["ageMonths"] = e.target.value;
                }
              }}
            /> */}
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default PatientAge;
