import { Autocomplete, Box, Popper, TextField } from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { getData } from "../../../../../services/api/getApi";

const PopperMy = function (props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

const styles = (theme) => ({
  popper: {
    width: "fit-content",
  },
});

const FrequencyComp = ({ element }) => {
  const [frequencyList, setFrequencyList] = useState([]);

  const fetchFrequencyListHandler = useCallback(async (docId) => {
    const user = await getData(BASE_URL + "doctor/frequency/" + docId, "");

    if (user.error) {
      console.log("error_freq");
    } else {
      const data = user.data;
      console.log("success_freq");
      setFrequencyList(data);
      console.log({ data: data });
    }
  }, []);

  useEffect(() => {
    fetchFrequencyListHandler(localStorage.getItem("DOCID"));
  }, []);

  const [fValue, setFValue] = useState(
    element.frequencyString ? element.frequencyString : null
  );
  return (
    <Fragment>
      <Box sx={{ width: "100%" }}>
        <Autocomplete
          fullWidth
          freeSolo
          disableClearable
          size="small"
          options={frequencyList}
          getOptionLabel={(option) =>
            option.frequency +
            (option.frequencyVernacular
              ? " (" + option.frequencyVernacular + ")"
              : "")
          }
          ListboxProps={{ sx: { fontSize: 11 } }}
          PopperComponent={PopperMy}
          onInputChange={(event, newInputValue) => {
            const myArray = newInputValue.split(" (");
            element["frequencyString"] = {
              id: null,
              frequency: myArray[0],
              frequencyVernacular:
                myArray.length > 1 ? myArray[1].split(")")[0] : null,
            };
          }}
          name="frequencyString"
          value={fValue}
          selectOnFocus
          onChange={(event, value) => {
            setFValue(value);
            element["frequencyString"] = value;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="eg 2 times a day"
              variant="standard"
              multiline
              size="small"
              InputProps={{
                ...params.InputProps,
                type: "search",
                style: { fontSize: 11 },
                disableUnderline: true,
              }}
              InputLabelProps={{ style: { fontSize: 11, color: "#127DDD" } }}
            />
          )}
        />
      </Box>
    </Fragment>
  );
};

export default FrequencyComp;
