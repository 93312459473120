import React from "react";
import { Fragment } from "react";
import Carousel from "../subComps/carouselComps/Carousel";
import carousel1 from "../../assets/images/carousel1.png";
import carousel2 from "../../assets/images/carousel2.png";
import carousel3 from "../../assets/images/carousel3.png";
import carousel4 from "../../assets/images/carousel4.png";
import { Box, Grid } from "@mui/material";

const RightBanner = () => {
  const images = [carousel1, carousel2, carousel3, carousel4];
  return (
    <Box sx={{ m: 0, p: 0 }}>
      <Carousel images={images} />
    </Box>
  );
};

export default RightBanner;
