import React, { Fragment, useEffect, useState } from "react";
import { Box, Button, CircularProgress, Container, Grid } from "@mui/material";
import dayjs from "dayjs";
import CustomAutocomplete from "../../../../../assets/customAutocomplete/customAutocomplete";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { getData } from "../../../../../services/api/getApi";
import { useSnackbar } from "notistack";
import { downloadCsv } from "../../../../../assets/utils";
import GlobalDateLayout from "../../../../../assets/globalDateLayout/globalDateLayout";
import {
  fetchMedicineAsProductName,
  fetchMedicineListInDashboard,
} from "../../../../services/inventory";
import { getNearToExpiry } from "../../../../services/pharmacyAnlyticsGraphs";

const Reports = ({ corpId = localStorage.getItem("CORPID") }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedReport, setSelectedReport] = useState("");
  const [medicineList, setMedicineList] = useState("");
  const [selectedProductName, setSelectedProductName] = useState("");
  const [selectedBrandName, setSelectedBrandName] = useState("");

  // Exirpy Report
  const [nearToExpiry, setNearToExpiry] = useState([]);
  const [nearToExpiryDetail, setNearToExpiryDetail] = useState([]);
  const [nearToExpiryConsolidatedData, setNearToExpiryConsolidatedData] =
    useState([]);

  useEffect(() => {
    getNearToExpiry(
      corpId,
      setNearToExpiry,
      setNearToExpiryDetail,
      setNearToExpiryConsolidatedData
    );
  }, []);

  const [filters, setFilters] = useState({
    fromDate: null,
    toDate: null,
    transactionType: "",
  });

  const fetchReportReorder = async () => {
    const url = BASE_URL + `inventory/reports/reorder/${corpId}`;
    const result = await getData(url);
    if (result.error) {
      enqueueSnackbar(`${result.error.response.data.message}`, {
        variant: "error",
      });
    } else {
      const temp = result.data.map((item, index) => ({
        productCode: item.productCode,
        productName: item?.productName,
        brandName: item.brandName,
        baseUnit: item.baseUnit,
        availableQuantity: item.availableQuantity,
        reorderPoint: item.reorderPoint,
      }));
      if (temp.length === 0) {
        enqueueSnackbar(`No Data Found`, {
          variant: "info",
        });
      } else {
        downloadCsv(temp, `Reorder_Report`);
      }
    }
  };

  const fetchReportPurchase = async () => {
    const url =
      BASE_URL +
      `inventory/reports/purchaseAndSales/${corpId}?transactionType=PROCUREMENT&fromDate=${
        filters.fromDate || ""
      }&toDate=${filters.toDate || ""}&productName=${
        selectedProductName?.productName || ""
      }&brandName=${selectedBrandName?.brand || ""}`;
    const result = await getData(url);
    if (result.error) {
      enqueueSnackbar(`${result.error.response.data.message}`, {
        variant: "error",
      });
    } else {
      if (result.data.length === 0) {
        enqueueSnackbar(`No Data Found`, {
          variant: "info",
        });
      } else {
        const temp = result.data.map((item, index) => ({
          productCode: item.productCode,
          productName: item.productName,
          brandName: item.brandName,
          batchCode: item.batchLabel,
          expiryDate: item.expiryDate,
          quantity: item.quantity,
          baseUnit: item.baseUnit,
          addedDate: item.addedDate,
          // remarks: item.remarks,
          // addedBy: item.addedBy,
        }));
        downloadCsv(temp, `Purchase_Report`);
      }
    }
  };

  const fetchReportConsumption = async () => {
    const url =
      BASE_URL +
      `inventory/reports/purchaseAndSales/${corpId}?transactionType=SALES&fromDate=${
        filters.fromDate || ""
      }&toDate=${filters.toDate || ""}&productName=${
        selectedProductName?.productName || ""
      }&brandName=${selectedBrandName?.brand || ""}`;
    const result = await getData(url);
    if (result.error) {
      enqueueSnackbar(`${result.error.response.data.message}`, {
        variant: "error",
      });
    } else {
      if (result.data.length === 0) {
        enqueueSnackbar(`No Data Found`, {
          variant: "info",
        });
      } else {
        const temp = result.data.map((item, index) => ({
          empId: item.empId,
          productCode: item.productCode,
          productName: item.productName,
          brandName: item.brandName,
          batchCode: item.batchLabel,
          expiryDate: item.expiryDate,
          quantity: item.quantity,
          baseUnit: item.baseUnit,
          depletedDate: item.depletedDate,
          // remarks: item.remarks,
          // depletedBy: item.depletedBy,
        }));
        downloadCsv(temp, `Consumption_Report`);
      }
    }
  };

  const fetchReportCurrentInventoryReport = async () => {
    const url = BASE_URL + `inventory/reports/currentInventory/${corpId}`;
    const result = await getData(url);
    if (result.error) {
      enqueueSnackbar(`${result.error.response.data.message}`, {
        variant: "error",
      });
    } else {
      const temp = result.data.map((item, index) => ({
        productCode: item.productCode,
        productName: item?.productName,
        brandName: item.brandName,
        batchCode: item.batchLabel,
        expiryDate: item.expiryDate,
        availableQuantity: item.availableQuantity,
        baseUnit: item.baseUnit,
      }));

      if (temp.length === 0) {
        enqueueSnackbar(`No Data Found`, {
          variant: "info",
        });
      } else {
        downloadCsv(temp, `Current_Inventory_Report`);
      }
    }
  };

  useEffect(() => {
    fetchMedicineAsProductName(corpId, setMedicineList);
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Fragment>
      <Container maxWidth={false}>
        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <CustomAutocomplete
              options={[
                "Reorder Report",
                "Purchase Report",
                "Consumption Report",
                "Expiry Report",
                "Current Inventory Report",
              ]}
              label="Type Of Report"
              placeholder={"Select Report Type"}
              value={selectedReport}
              onChange={(event, newValue) => {
                setSelectedReport(newValue);
              }}
              getOptionLabel={(option) => option}
            />
          </Grid>
          {(selectedReport === "Purchase Report" ||
            selectedReport === "Consumption Report") && (
            <>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <GlobalDateLayout
                  label={"From Date"}
                  initialDate={filters.fromDate}
                  setFormValues={setFilters}
                  formValues={filters}
                  property={"fromDate"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <GlobalDateLayout
                  label={"To Date"}
                  initialDate={filters.toDate}
                  setFormValues={setFilters}
                  formValues={filters}
                  property={"toDate"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomAutocomplete
                  options={medicineList}
                  label="Product Name"
                  placeholder={"Select Product Name"}
                  value={selectedProductName || ""}
                  onChange={(event, newValue) => {
                    setSelectedProductName(newValue);
                  }}
                  getOptionLabel={(option) => option?.productName || ""}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <CustomAutocomplete
                  options={medicineList}
                  label="Brand Name"
                  placeholder={"Select Brand Name"}
                  value={selectedBrandName || ""}
                  onChange={(event, newValue) => {
                    setSelectedBrandName(newValue);
                  }}
                  getOptionLabel={(option) => option.brand || ""}
                />
              </Grid>
            </>
          )}
        </Grid>

        <Box
          sx={{
            border: "1px solid lightgray",
            p: 2,
            backgroundColor: "#FFFFFF",
            display: "flex",
            borderRadius: "15px",
            marginBlock: 2,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            disabled={selectedReport === "" ? true : false}
            variant="contained"
            onClick={() => {
              if (selectedReport === "Reorder Report") {
                fetchReportReorder();
              } else if (selectedReport === "Purchase Report") {
                fetchReportPurchase();
              } else if (selectedReport === "Consumption Report") {
                fetchReportConsumption();
              } else if (selectedReport === "Expiry Report") {
                downloadCsv(nearToExpiryConsolidatedData, `Expiry_Report`);
              } else if (selectedReport === "Current Inventory Report") {
                fetchReportCurrentInventoryReport();
              }
            }}
            sx={{ textTransform: "capitalize" }}
          >
            Download Report
          </Button>
        </Box>
      </Container>
    </Fragment>
  );
};

export default Reports;
