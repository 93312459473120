import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Modal,
  Portal,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Fragment, useEffect, useState } from "react";
import { BASE_URL } from "../../../assets/constantsFile";
import { getDataWithoutToken } from "../../../services/api/getApi";
import { saveDataWithoutAuth } from "../../../services/api/postApi";
import notification from "../../../assets/images/notification.png";
import callIcon from "../../../assets/images/callIcon.png";
import rightIcon from "../../../assets/images/rightIcon.png";
import crossIcon from "../../../assets/images/crossIcon.png";

const branchId = "3fa85f64-5717-4562-b3fc-2c963f66afa6";

const VisitApprovalMain = ({ id }) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [visitData, setVisitData] = useState({});
  const _getVisitorData = async () => {
    const url = BASE_URL + `securityApp/visitor?visitId=${id}`;

    const visitorData = await getDataWithoutToken(url);

    if (visitorData.error) {
      console.log("error");
      setVisitData({});
    } else {
      console.log({ success: visitorData.data });
      setVisitData(visitorData.data);
      setOpen(visitorData.data?.approvalStatus !== "APPROVED" ? true : false);
    }
  };

  useEffect(() => {
    _getVisitorData();
  }, []);
  const _submitVisitorData = async (val) => {
    const url = BASE_URL + `securityApp/markApproval`;
    const payload = {
      visitId: id,
      branchId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      appApproval: val,
    };

    const approval = await saveDataWithoutAuth(url, payload);

    if (approval.error) {
      console.log("failed");
    } else {
      console.log("succeeded");
      _getVisitorData();
      handleClose();
    }
  };
  return (
    <Fragment>
      <Portal>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={open}
          onClose={handleClose}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(187, 187, 187, 1)",
            },
          }}
        >
          <Box
            sx={{
              outline: "none",
              backgroundColor: "#fff",
              boxShadow: "0px 1px 4px 1px rgba(0, 0, 0, 0.1)",
              borderRadius: "5px",
              padding: "15px",
              height: "170px",
              width: "330px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                style={{
                  marginTop: "-40px",
                  backgroundColor: "#FFFFFF",
                  padding: "5px",
                  height: "65px",
                  width: "65px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  style={{
                    backgroundColor: "#FF525B",
                    height: "60px",
                    width: "60px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    src={notification}
                    style={{ height: "45px", width: "45px" }}
                    resizeMode="contain"
                  />
                </Box>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Typography
              gutterBottom
              sx={{
                textAlign: "center",
                fontWeight: "600",
                fontSize: "17px",
                lineHeight: "15px",
                color: "#000000",
                marginTop: "-25px",
                marginBottom: "10px",
              }}
            >
              {`Confirm ${visitData?.visitorName}'s visit!`}
            </Typography>
            <Box sx={{ height: "70px" }}>
              <Typography
                sx={{
                  color: "#000000",
                  fontSize: "15px",
                  fontWeight: "400",
                  textAlign: "center",
                }}
              >
                Allow in room number {visitData?.roomNumber || "C-202"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "-200px",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Button
                    style={{
                      backgroundColor: "#FFFFFF",
                      height: "60px",
                      width: "60px",
                      borderRadius: "50%",
                      border: "1px solid red",
                      borderWidth: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => _submitVisitorData("REJECTED")}
                  >
                    <Box
                      component={"img"}
                      src={crossIcon}
                      style={{ height: 45, width: 45, color: "red" }}
                    />
                  </Button>
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "#FFFFFF",
                      fontWeight: "700",
                    }}
                  >
                    Reject
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Button
                    style={{
                      backgroundColor: "#57D08D",
                      height: "60px",
                      width: "60px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => _submitVisitorData("APPROVED")}
                  >
                    <Box
                      component={"img"}
                      src={rightIcon}
                      style={{ height: 45, width: 45, color: "red" }}
                    />
                  </Button>
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "#FFFFFF",
                      fontWeight: "700",
                    }}
                  >
                    Approve
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Portal>

      <Container
        sx={{
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography>
                {`${visitData?.visitorName}'s visit has been ${visitData?.approvalStatus}.`}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Fragment>
  );
};

export default VisitApprovalMain;
