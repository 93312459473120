import RootTemplate from "../../global/templates/rootTemplate";
import AHCReccommendedTestsMain from "../../modules/ahc/reccommendedTests/ahcReccommendedTestsMain";

const AHCRecommendedTestsIndex = () => {
  return (
    <RootTemplate>
      <AHCReccommendedTestsMain />
    </RootTemplate>
  );
};

export default AHCRecommendedTestsIndex;
