import RootTemplate from "../../global/templates/rootTemplate";
import EDComplianceRecordsMain from "../../modules/employeeDetails/complianceRecords/edComplianceRecordsMain";

const EDComplianceRecordsIndex = () => {
  return (
    <RootTemplate>
      <EDComplianceRecordsMain />
    </RootTemplate>
  );
};

export default EDComplianceRecordsIndex;
