import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TestAutoComplete from "../subComps/TestAutoComplete";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Tests = ({ patients }) => {
  console.log({ CONTEXT: patients });
  const patientId = patients?.patientInfoVMV2?.patientId;
  const appointmentId = patients?.appointmentId;
  const caseId = patients?.caseId;
  const docId = patients?.doctorVM?.docId;

  return (
    <Fragment>
      <Box sx={{ marginTop: "20px" }}>
        <TestAutoComplete patients={patients} />
      </Box>
    </Fragment>
  );
};

export default Tests;
