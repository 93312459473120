import { Box, Button, Divider, Grid, Snackbar, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { createSlotForDocId } from '../services/api/apiCalls';

const SlotForm = (props) => {


  const [slotValues, setSlotValues] = useState(props.slotValues);
  const [formats, setFormats] = useState(() => []);
  const [toggleActive, setToggleActive] = useState("inActive");

  useEffect(() => {
    slotValues.isActive ? setToggleActive("active") : setToggleActive("inActive");

    setFormats(slotValues.activeWeekDays);
  }, []);
  
  

    let handleChange = (e) => {

      setSlotValues({...slotValues, [e.target.name]: e.target.value});

      }

      const handleFormat = (event, newFormats) => {

        setSlotValues({...slotValues, activeWeekDays:newFormats});

        setFormats(newFormats);
       
      };

      
    const handleChangeToggleActive = (event, toggleActive) => {

      toggleActive==="active" ? setSlotValues({...slotValues, isActive: true}) :  setSlotValues({...slotValues, isActive: false});

      setToggleActive(toggleActive);
    };
  

     const submitSlotHandler = async(event) => {
        event.preventDefault();

        if(slotValues.slotName)  {
        
          const slotObj = {
          docId: localStorage.getItem("DOCID"),
          slots: [
            {
              slotId:slotValues.id,
              slotName: slotValues.slotName,
              noOfWeeks: slotValues.repeatForWeeks,
              startTime: slotValues.startTimeHour+":"+slotValues.startTimeMinute+" "+slotValues.startTimeAMPM,
              endTime: slotValues.endTimeHour+":"+slotValues.endTimeMinute+" "+slotValues.endTimeAMPM,
              daysOfWeek: formats,
              isActive: slotValues.isActive,
            },
          ],
         };
        
           const slot = await createSlotForDocId(slotObj); 
           props.onAddSlotForm(slotValues,slot);
    }
        
  }

return (
  <Box sx={{
    height:"100%",
    width:"100%",
    p:1,
    background:'#fff'
  }}>


<form id="mySlotForm" onSubmit={submitSlotHandler}>
    
    <Grid container spacing={3} >
  
  
    <Grid item xs={12} >
      <Grid container alignItems="center">
      
      <Grid item xs={3} >
      <Typography variant="body2" sx={{fontWeight:500}}>SLOT NAME</Typography>
      </Grid>
  
      <Grid item xs={9} >
  
      <TextField 
        fullWidth
        variant="standard"
        size="small"
        name="slotName"
        value={slotValues.slotName || ""}
        onChange={(e) => handleChange(e)}
        />
  
      </Grid>
  
      </Grid>
    </Grid>
  
    <Grid item xs={12} >
      <Grid container alignItems="center">
  
      <Grid item xs={3} >
      <Typography variant="body2" sx={{fontWeight:500}}>REPEAT FOR</Typography>
      </Grid>
  
      <Grid item xs={9} > 
      <Stack direction={'row'} alignItems="center">
  
      <TextField
          variant="outlined"
          size="small"
          type="number"
          inputProps={{ min:0, max:104 }}
          name="repeatForWeeks"
          value={slotValues.repeatForWeeks}
          onChange={(e) => handleChange(e)}              
        />
  
        <Typography variant="body2" sx={{fontWeight:500}}>&nbsp;Weeks</Typography>
        </Stack>
  
      </Grid>
  
      </Grid>
    </Grid>
  
    <Grid item xs={12} >
      <Grid container alignItems="center">
  
      <Grid item xs={3} >
      <Typography variant="body2" sx={{fontWeight:500}}>REPEAT ON</Typography>
      </Grid>
  
      <Grid item xs={9} >
  
      <ToggleButtonGroup
            size="small"
            color="primary"
            value={formats}
            onChange={handleFormat}
            aria-label="text formatting"
        >
          <ToggleButton value="0" aria-label="monday">MON</ToggleButton>
          <ToggleButton value="1" aria-label="tuesday">TUE</ToggleButton>
          <ToggleButton value="2" aria-label="wednesday">WED</ToggleButton>
          <ToggleButton value="3" aria-label="thursday">THU</ToggleButton>
          <ToggleButton value="4" aria-label="friday">FRI</ToggleButton>
          <ToggleButton value="5" aria-label="saturday">SAT</ToggleButton>
          <ToggleButton value="6" aria-label="sunday">SUN</ToggleButton>
  
        </ToggleButtonGroup>
  
      </Grid>
      </Grid>
    </Grid>
  
    <Grid item xs={12} >
      <Grid container alignItems="center">
  
      <Grid item xs={3} >
      <Typography variant="body2" sx={{fontWeight:500}}>START TIME</Typography>
      </Grid>
  
      <Grid item xs={9} >
  
      <Stack direction={'row'}>
  
       <TextField
          size="small"
          select
          label=""
          name="startTimeHour"
          value={slotValues.startTimeHour || ""}
          onChange={e => handleChange(e)}
          SelectProps={{ native: true, }} helperText=""
          >
      {[{ value: '00'},{ value: '01'},{ value: '02'},{ value: '03'},{ value: '04'},{ value: '05'},{ value: '06'},{ value: '07'},{ value: '08'},{ value: '09'},{ value: '10'},{ value: '11'},].map((option) => (
        <option key={option.value} value={option.value}>
          {option.value}
        </option>
      ))}
    </TextField>
  
    <TextField
      size="small"
      select
      label=""
      name="startTimeMinute"
      value={slotValues.startTimeMinute || ""}
      onChange={e => handleChange(e)}
      SelectProps={{ native: true, }}
      helperText=""
    >
      {[{ value: '00',},{ value: '15',},{ value: '30',},{ value: '45',},].map((option) => (
        <option key={option.value} value={option.value}>
          {option.value}
        </option>
      ))}
    </TextField>
    
    <TextField
      size="small"
      select
      label=""
      name="startTimeAMPM"
      value={slotValues.startTimeAMPM || ""}
      onChange={e => handleChange(e)}
      SelectProps={{ native: true, }}
      helperText=""
    >
      {[{ value: 'AM', label: 'AM', },{ value: 'PM', label: 'PM', }].map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </TextField>
    
    </Stack>
      
      </Grid>
      </Grid>
  
    </Grid>
  
    <Grid item xs={12} >
      <Grid container alignItems="center">
  
      <Grid item xs={3} >
      <Typography variant="body2" sx={{fontWeight:500}}>END TIME</Typography>
      </Grid>
  
      <Grid item xs={9} >
  
      <Stack direction={'row'}>
  
       <TextField
          size="small"
          select
          label=""
          name="endTimeHour"
          value={slotValues.endTimeHour || ""}
          onChange={e => handleChange(e)}
          SelectProps={{ native: true, }} helperText=""
          >
      {[{ value: '00'},{ value: '01'},{ value: '02'},{ value: '03'},{ value: '04'},{ value: '05'},{ value: '06'},{ value: '07'},{ value: '08'},{ value: '09'},{ value: '10'},{ value: '11'},].map((option) => (
        <option key={option.value} value={option.value}>
          {option.value}
        </option>
      ))}
    </TextField>
  
    <TextField
      size="small"
      select
      label=""
      name="endTimeMinute"
      value={slotValues.endTimeMinute || ""}
      onChange={e => handleChange(e)}
      SelectProps={{ native: true, }}
      helperText=""
    >
      {[{ value: '00',},{ value: '15',},{ value: '30',},{ value: '45',},].map((option) => (
        <option key={option.value} value={option.value}>
          {option.value}
        </option>
      ))}
    </TextField>
    
    <TextField
      size="small"
      select
      label=""
      name="endTimeAMPM"
      value={slotValues.endTimeAMPM || ""}
      onChange={e => handleChange(e)}
      SelectProps={{ native: true, }}
      helperText=""
    >
      {[{ value: 'AM', label: 'AM', },{ value: 'PM', label: 'PM', }].map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </TextField>
    
    </Stack>
      
      </Grid>
      </Grid>
  
    </Grid>
  
  
    <Grid item xs={12} >
      <Grid container alignItems="center">
  
      <Grid item xs={3} >
      <Typography variant="body2" sx={{fontWeight:500}}>SCHEDULING TYPE</Typography>
      </Grid>
  
      <Grid item xs={9} >
  
      <ToggleButtonGroup
          size='small'
          color="primary"
          name="isActive"
          value={toggleActive}
          exclusive
          onChange={handleChangeToggleActive}
        >
          <ToggleButton value="active">Enable</ToggleButton>
          <ToggleButton value="inActive" >Disable</ToggleButton>
        </ToggleButtonGroup>
  
      </Grid>
      </Grid>
  
    </Grid>
  
    </Grid>
  
  </form>

  </Box>
);
}
 
export default SlotForm;
