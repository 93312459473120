import { Box, Grid } from "@mui/material";
import { Fragment, useState } from "react";
import { TicketCategoryList } from "../../../../assets/corpConstants";
import NewFormComp from "../../preemployment/newRequestForm/comps/newFormComp";
import RaiseTicketDialogEmergency from "./raiseTicket/emergency/raiseTicketDialogEmergency";
import BookSession from "../../healthAwarenessSessions/comp/bookSession";
import RaiseTicketDialogGeneralQuery from "./raiseTicket/generalQuery/raiseTicketDialogGeneralQuery";

const RaiseNewTicketMain = ({ authId = localStorage.getItem("USER_ID_CORP_SALES") }) => {
  const fetchTicketList = async () => {};
  const [fetch, setFetch] = useState(false);
  let formData = new FormData();

  return (
    <Fragment>
      <Box>
        <Grid container spacing={3} display="flex" justifyContent="center" alignItems="center">
          <Grid item lg={4}>
            <BookSession
              setFetch={setFetch}
              source="TICKETSDASHBOARD"
              data={TicketCategoryList[0]}
            />
          </Grid>
          <Grid item lg={4}>
            <NewFormComp
              fetchTicketList={fetchTicketList}
              source="TICKETSDASHBOARD"
              value={TicketCategoryList[1]}
              formData={formData}
            />
          </Grid>

          <Grid item lg={4}>
            <RaiseTicketDialogGeneralQuery
              fetchTicketList={fetchTicketList}
              value={TicketCategoryList[5]}
              formData={formData}
            />
          </Grid>
          <Grid item lg={4}>
            <RaiseTicketDialogEmergency
              fetchTicketList={fetchTicketList}
              value={TicketCategoryList[4]}
              formData={formData}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default RaiseNewTicketMain;
