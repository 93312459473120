import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Fragment } from "react";

const StatusFilterComp = ({ status, setStatus }) => {
  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  return (
    <Fragment>
      <FormControl fullWidth size="small" sx={{ maxWidth: 200 }}>
        <InputLabel>Status</InputLabel>
        <Select value={status} label="Status" onChange={handleChange}>
          <MenuItem value={"ALL"}>All</MenuItem>
          <MenuItem value={"COMPLETED"}>Completed</MenuItem>
          <MenuItem value={"TICKET_RAISED"}>Pending</MenuItem>
          <MenuItem value={"CANCELLED"}>Cancelled</MenuItem>
        </Select>
      </FormControl>
    </Fragment>
  );
};

export default StatusFilterComp;
