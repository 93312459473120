import { Box, Container, Grid } from "@mui/material";
import RegistrationContentMainComp from "./registerPatient/registrationContentMainComp";

const PatientRegistrationMainComponent = (props) => {
  return (
    <Container maxWidth={false} disableGutters>
      <Grid container>
        <Grid item lg={12}>
          <Box
            sx={{
              mt: 1,
            }}
          >
            <RegistrationContentMainComp />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PatientRegistrationMainComponent;
