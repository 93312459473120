import RootTemplate from "../../global/templates/rootTemplate";
import HealthServiceMain from "../../modules/tickets/healthService/healthServiceMain";

const TicketsHealthServiceIndex = () => {
  return (
    <RootTemplate>
      <HealthServiceMain />
    </RootTemplate>
  );
};

export default TicketsHealthServiceIndex;
