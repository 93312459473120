import { Box, Grid, Typography } from "@mui/material";
import CustomTitles from "../../../global/titles/customTitles";
import EHRMain from "../../ahc/heathRecord/comps/ehrMain";
import { getDataGridConfig } from "../../../global/services/corporateServices";
import { useEffect, useState } from "react";

const EDComplianceRecordsMain = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [config, setConfig] = useState([]);

  useEffect(() => {
    getDataGridConfig(corpId, setConfig, "AHC_HEALTH_RECORDS_PAGE");
  }, []);
  return (
    <Box>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="Compliance Records" />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <EHRMain config={config} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EDComplianceRecordsMain;
