import RootTemplate from "../../global/templates/rootTemplate";
import TicketsMain from "../../modules/tickets/dashboard/ticketsMain";
import TicketingSystemMain from "../../modules/tickets/newDashboard/ticketingSystemMain";

const TicketsIndex = ({ corpId = localStorage.getItem("CORPID") }) => {
  return (
    <RootTemplate>
      {corpId === "872cd841-9f7a-432d-b8e9-422b780bca10" ? (
        <TicketingSystemMain />
      ) : (
        <TicketsMain />
      )}
    </RootTemplate>
  );
};

export default TicketsIndex;
