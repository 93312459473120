import axios from "axios";
import { BASE_URL } from "../../assets/constantsFile";
import { Resolver } from "../resolver/resolver";

///////...GET APIs...///////
export async function getPatientListByDocId(docId) {
  let authHeader_local = localStorage.getItem("authHeader_local");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(
    axios
      .get(BASE_URL + "doctor/basic/" + docId, { headers })
      .then((res) => res.data)
  );
}

export async function getTemplateListByDocId(docId) {
  let authHeader_local = localStorage.getItem("authHeader_local");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(
    axios
      .get(BASE_URL + "doctor/prescription/template/" + docId, { headers })
      .then((res) => res.data)
  );
}

export async function getSlotListByDocId(docId) {
  let authHeader_local = localStorage.getItem("authHeader_local");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(
    axios
      .get(BASE_URL + "doctor/slots/" + docId, { headers })
      .then((res) => res.data)
  );
}

export async function getMedicineListByName(medName) {
  let authHeader_local = localStorage.getItem("authHeader_local");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(
    axios
      .get(
        BASE_URL +
          "doctor/medicineSearchPaginated/" +
          medName +
          "?page=0&size=10&sort=name",
        {
          headers,
        }
      )
      .then((res) => res.data)
  );
}

///////...POST APIs...///////

export async function createSlotForDocId(obj) {
  let authHeader_local = localStorage.getItem("authHeader_local");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + authHeader_local,
  };
  return await Resolver(
    axios
      .post(BASE_URL + "doctor/slots", obj, { headers })
      .then((res) => res.data)
  );
}

export async function uploadVitals(obj) {
  let authHeader_local = localStorage.getItem("authHeader_local");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + authHeader_local,
  };

  return await Resolver(
    axios(BASE_URL + "patient/vitals", {
      method: "POST",
      data: obj,
      headers: headers,
    }).then((res) => res.data)
  );
}

export async function getUserToken(url, data) {
  return await Resolver(
    axios(url, {
      method: "POST",
      data: data,
    }).then((res) => res.data)
  );
}

export async function getUserTokenByMobile(url, data) {
  return await Resolver(
    axios(url, {
      method: "POST",
      data: data,
    }).then((res) => res.data)
  );
}
