import RootTemplate from "../../global/templates/rootTemplate";
import OHCMyOPDMain from "../../modules/ohc/myOPD/ohcMyOPDMain";
import OhcMyOpdMain from "../../modules/ohc/ohcMyOpd/ohcMyOpdMain";

const OHCMyOPDIndex = () => {
  return (
    <RootTemplate>
      <OhcMyOpdMain />
    </RootTemplate>
  );
};

export default OHCMyOPDIndex;
