import { Box } from "@mui/material";
import OHCMyOPDMain from "./ohcMyOPDMain";

const OPD = () => {
  return (
    <Box>
      <OHCMyOPDMain />
    </Box>
  );
};

export default OPD;
