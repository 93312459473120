import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { BASE_URL } from "../../assets/constantsFile";
import { saveData } from "../../services/api/postApi";
import { getData } from "../../services/api/getApi";
import { useNavigate } from "react-router";

const BookAppointmentMainComp = ({
  open,
  handleClose,
  selectedPatientId,
  setIsBooked,
}) => {
  let navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const [slotNumber, setSlotNumber] = useState("");
  const [docSlotList, setDocSlotList] = useState([]);

  const fetchSlotHandler = useCallback(async (docId, dateValue) => {
    const url =
      BASE_URL +
      "clinic/getOfflineAvailability/" +
      docId +
      " ?date=" +
      dateValue;

    const slots = await getData(url, "");

    if (slots.error) {
      console.log("error");
    } else {
      console.log("success");
      setDocSlotList(slots.data.slots);
    }
  }, []);

  useEffect(() => {
    fetchSlotHandler(
      localStorage.getItem("DOCID"),
      date.toISOString().split("T")[0]
    );
  }, [date]);

  console.log({ docSlotList: docSlotList });

  useEffect(() => {
    setSlotNumber(docSlotList[0]);
  }, [docSlotList]);

  const bookAppointmentHandler = () => {
    const OBJ = {
      docId: localStorage.getItem("DOCID"),
      patientId: selectedPatientId,
      startTime: slotNumber ? slotNumber.startTime : "",
      endTime: slotNumber ? slotNumber.endTime : "",
      date: date.toISOString().split("T")[0],
      slotNo: slotNumber ? slotNumber.slotNo : 0,
    };
    console.log({ OBJ: OBJ });
    saveAppointment(OBJ);
  };

  const saveAppointment = async (data) => {
    const url = BASE_URL + "doctor/cms/appointment";

    const response = await saveData(url, data, "");

    if (response.error) {
      console.log("error");
    } else {
      console.log("success");
      setIsBooked(true);
      handleClose();
      setTimeout(function () {
        navigate("/patients", { state: { id: 1, name: "sabaoon" } });
      }, 2000);
    }
  };
  return (
    <Dialog
      fullWidth
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" align="center">
        Book Appointment
      </DialogTitle>

      <DialogContent dividers>
        <Box>
          <Grid container>
            <Grid item lg={12}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item lg={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disablePast
                        inputFormat="dd MMM yyyy"
                        disableMaskedInput={true}
                        label="Date"
                        openTo="day"
                        views={["year", "month", "day"]}
                        value={date}
                        name="DOA"
                        onChange={(newValue) => {
                          setDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            sx={{ fontSize: 10 }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item lg={3}>
                    <Autocomplete
                      size={"small"}
                      freeSolo
                      id="slotBooking"
                      name="slotNumber"
                      value={slotNumber}
                      disableClearable
                      options={docSlotList}
                      getOptionLabel={(option) =>
                        option ? option.startTime + " to " + option.endTime : ""
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.startTime} to {option.endTime}
                        </Box>
                      )}
                      onChange={(event, value) => {
                        setSlotNumber(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          variant="outlined"
                          {...params}
                          label="Choose a slot"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item lg={3}>
                    {slotNumber && slotNumber.startTime ? (
                      <TextField
                        fullWidth
                        disabled
                        size="small"
                        label="Start Time"
                        variant="outlined"
                        value={slotNumber.startTime}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <AccessTimeIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item lg={3}>
                    {slotNumber && slotNumber.endTime ? (
                      <TextField
                        fullWidth
                        disabled
                        size="small"
                        label="End Time"
                        variant="outlined"
                        value={slotNumber.endTime}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <AccessTimeIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions>
        <Stack
          direction="row"
          spacing={4}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={bookAppointmentHandler}
          >
            Save
          </Button>

          <Button variant="contained" size="small" onClick={handleClose}>
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default BookAppointmentMainComp;
