import RootTemplate from "../../global/templates/rootTemplate";
import EDEmployeeLifeCycleMain from "../../modules/employeeDetails/employeeLifeCycle/edEmployeeLifeCycleMain";

const EDEmployeeLifecycleIndex = () => {
  return (
    <RootTemplate>
      <EDEmployeeLifeCycleMain />
    </RootTemplate>
  );
};

export default EDEmployeeLifecycleIndex;
