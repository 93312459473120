import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import {
  Autocomplete,
  Button,
  Divider,
  IconButton,
  Popper,
  Snackbar,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { BASE_URL } from "../../../../assets/constantsFile";
import { getData } from "../../../../services/api/getApi";
import {
  prescriptionContext,
  userContext,
} from "../../../../common/context/usercontext";
import AddIcon from "@mui/icons-material/Add";
import MuiAlert from "@mui/material/Alert";
import { saveData } from "../../../../services/api/postApi";
import {
  generateDoseList,
  generateDurationList,
} from "../../../../assets/utils";
import TimingComp from "./formElements/timingComp";
import FrequencyComp from "./formElements/frequencyComp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import deleteIcon from "../../../../assets/images/svg/deleteIcon.svg";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PopperMy = function (props) {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

const styles = (theme) => ({
  popper: {
    width: "fit-content",
  },
});

const style = {
  columnList: {
    height: "48px",
    background: "#E3F2FF",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    my: 1,
  },
  columnCell: {
    borderRight: "1px solid black",
    textAlign: "center",
  },
  columnName: {
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
    color: "#127DDD",
  },
};
const AddPrescriptionTemplateForDoc = (props) => {
  const prescriptionContextData = useContext(prescriptionContext);
  const [openNotice, setOpenNotice] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  //manage success/error message state
  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };

  //fetch template list
  const [prescriptionTemplateList, setPrescriptionTemplateList] = useState([]);
  const fetchPrescriptionsHandler = useCallback(async () => {
    const response = await getData(
      BASE_URL +
        "doctor/prescription/template/" +
        localStorage.getItem("DOCID"),
      props.authHeader
    );

    if (response.error) {
    } else {
      const data = await response.data;
      setPrescriptionTemplateList(data);
    }
  }, []);

  useEffect(() => {
    fetchPrescriptionsHandler();
  }, [fetchPrescriptionsHandler]);

  console.log({
    "prescriptionContextData.prescriptionData111":
      prescriptionContextData.prescriptionData,
  });

  const [formValues, setFormValues] = useState(
    prescriptionContextData.prescriptionData
  );
  const [selectedTemps, setSelectedTemps] = useState([...""]);
  const listOfTemplates = props.prescriptionTemplateList;

  console.log({ "props.prescriptionTemplateList": prescriptionTemplateList });

  let handleMyMultipleChange = (v) => {
    setSelectedTemps(v);
    let meds = [];
    let temp = v.map((data) => {
      meds = [...meds, ...data.medicines];
      return;
    });
    const tranfromedMeds = meds.map((value) => {
      return {
        medicineName: value.medicineName,
        dosage: value.dosage,
        m: value.m,
        a: value.a,
        e: value.e,
        n: value.n,
        comment: {
          id: null,
          instruction: value.comment,
          instructionVernacular: value.commentVernacular,
        },
        duration: value.duration,
        durationString: value.durationString,
        frequencyString: {
          id: null,
          frequency: value.frequencyString,
          frequencyVernacular: value.frequencyStringVernacular,
        },
        timing: value.timing,
      };
    });
    console.log({ tranfromedMeds: tranfromedMeds });

    if (Array.isArray(tranfromedMeds) && tranfromedMeds.length) {
      setFormValues(tranfromedMeds);
    } else {
      setFormValues([{ medicineName: "" }]);
    }
  };

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];

    if (e.target.type === "checkbox") {
      newFormValues[i][e.target.name] = e.target.checked;
    } else {
      newFormValues[i][e.target.name] = e.target.value;
    }

    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { medicineName: "", newField: true }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  function submitHandler(event) {
    event.preventDefault();

    let isValidated = false;

    isValidated = formValues.some(validateFields);

    if (!isValidated) {
      props.onAddPrescriptionTemplate(formValues);
      setIsEmpty(false);

      addCustomInstructions(formValues);
      addCustomMedicines(formValues);
    } else {
      setIsEmpty(true);
      setSeverity("error");
      setMessage("Fields can not be empty!");
      setOpenNotice(true);
    }

    console.log(formValues);
  }

  const [isEmpty, setIsEmpty] = useState(false);

  const validateFields = (data) => {
    let validate = 0;
    data.medicineName ? (validate = 1) : (validate = 0);
    return validate === 0;
  };

  const [medicineList, setMedicineList] = useState([]);
  const [state, setState] = useState();

  const contextData = useContext(userContext);

  const fetchMedicinesHandler = useCallback(async (medName) => {
    console.log({ medName: medName });

    const user = await getData(
      BASE_URL +
        "doctor/medicineSearchPaginated/" +
        medName +
        "?page=0&size=10&sort=name",
      contextData.authHeader
    );

    if (user.error) {
      setState({ error: user.error });
    } else {
      setState({ data: user.data });
    }

    const data = await user.data;
    const content = await data.content;
    setMedicineList(content);
  }, []);

  const [instructionList, setInstructionList] = useState([]);

  const fetchInstructionsHandler = useCallback(async (docId) => {
    const user = await getData(
      BASE_URL + "doctor/instruction?docId=" + docId,
      contextData.authHeader
    );

    if (user.error) {
    } else {
      const data = user.data;
      console.log("success");

      setInstructionList(
        data.filter(
          (tag, index, array) =>
            array.findIndex((t) => t.instruction == tag.instruction) == index
        )
      );
    }
  }, []);

  useEffect(() => {
    fetchInstructionsHandler(localStorage.getItem("DOCID"));
  }, [localStorage.getItem("DOCID")]);

  const [timingList, setTimingList] = useState([]);

  const fetchTimingListHandler = useCallback(async (docId) => {
    const user = await getData(
      BASE_URL + "doctor/timing/" + docId,
      contextData.authHeader
    );

    if (user.error) {
      console.log("error_timing");
    } else {
      console.log("success_timing");
      const data = user.data;
      console.log({ data: data });
      setTimingList(data);
    }
  }, []);

  useEffect(() => {
    fetchTimingListHandler(localStorage.getItem("DOCID"));
  }, [localStorage.getItem("DOCID")]);

  const [frequencyList, setFrequencyList] = useState([]);

  const fetchFrequencyListHandler = useCallback(async (docId) => {
    const user = await getData(
      BASE_URL + "doctor/frequency/" + docId,
      contextData.authHeader
    );

    if (user.error) {
      console.log("error_freq");
    } else {
      const data = user.data;
      console.log("success_freq");
      setFrequencyList(data);
      console.log({ data: data });
    }
  }, []);

  useEffect(() => {
    fetchFrequencyListHandler(localStorage.getItem("DOCID"));
  }, [localStorage.getItem("DOCID")]);

  console.log({ timingList: timingList });
  console.log({ frequencyList: frequencyList });

  const addCustomInstructions = (values) => {
    values.map((value) => {
      if (value.customInstruction && value.customInstruction.length > 0) {
        const obj = {
          docId: localStorage.getItem("DOCID"),
          instruction: value.customInstruction,
        };
        saveNewInstruction(obj);
      }
    });
  };
  const addCustomMedicines = (values) => {
    values.map((value) => {
      if (value.customMedicineName && value.customMedicineName.length > 0) {
        saveNewMedicines(value.customMedicineName);
      }
    });
  };

  const saveNewInstruction = async (a) => {
    const data = await saveData(BASE_URL + "doctor/instruction", a, "");

    if (data.error) {
    } else {
      console.log("new instruction added to database");
    }
  };

  const saveNewMedicines = async (a) => {
    const data = await saveData(BASE_URL + "doctor/saveMedicine/" + a, "", "");

    if (data.error) {
    } else {
      console.log("new medicine added to database");
    }
  };

  //set context

  useEffect(() => {
    prescriptionContextData.prescriptionData = formValues;
  }, [formValues]);

  console.log({ instructionList: instructionList });
  console.log({ formValues: formValues });

  const [doseList, setDoseList] = useState([]);
  const [durationList, setDurationList] = useState([]);

  const copyLastRxHandler = () => {
    const medicines = props.lastRx ? props.lastRx.medicines : [];
    const tranfromedMeds = medicines.map((value) => {
      return {
        medicineName: value.medicineName,
        dosage: value.dosage,
        m: value.m,
        a: value.a,
        e: value.e,
        n: value.n,
        comment: {
          id: null,
          instruction: value.comment,
          instructionVernacular: value.commentVernacular,
        },
        duration: value.duration,
        durationString: value.durationString,
        frequencyString: {
          id: null,
          frequency: value.frequencyString,
          frequencyVernacular: value.frequencyStringVernacular,
        },
        timing: value.timing,
      };
    });

    setFormValues(tranfromedMeds);
  };
  const addButtons = (
    <Box sx={{ borderRadius: 1, p: 1 }}>
      <Grid container columnSpacing={4}>
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            startIcon={<AddIcon />}
            onClick={addFormFields}
            variant="outlined"
            sx={{
              border: "1px solid #127DDD",
              height: 40.25,
              background: "#127DDD",
              color: "white",
              fontFamily: "Roboto",
              fontWeight: 600,
              lineHeight: "26px",
              "&:hover": { background: "#127DDD" },
            }}
          >
            Add Medicine
          </Button>
        </Grid>

        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Button
            startIcon={<ContentCopyIcon />}
            onClick={copyLastRxHandler}
            variant="outlined"
            sx={{
              border: "1px solid #127DDD",
              height: 40.25,
              color: "#127DDD",
              fontFamily: "Roboto",
              fontWeight: 600,
              lineHeight: "26px",
              background: "white",
            }}
          >
            Copy Last Rx
          </Button>
        </Grid>

        {/* <Grid item lg={4} md={12} sm={12} xs={12}>
              <Autocomplete
                size="small"
                fullWidth
                multiple
                id="tags-outlined-1"
                options={prescriptionTemplateList}
                getOptionLabel={(option) => option.templateName}
                defaultValue={[]}
                filterSelectedOptions
                value={selectedTemps || ""}
                ListboxProps={{ sx: { fontSize: 11 } }}
                onChange={(event, value) => handleMyMultipleChange(value)}
                renderInput={(params) => (
                  <TextField
                    sx={{ "& label": { fontSize: ".70rem" } }}
                    InputProps={{ style: { padding: 0, fontSize: 11 } }}
                    {...params}
                    label="Select from saved templates."
                    placeholder=""
                  />
                )}
              />
            </Grid> */}
      </Grid>
    </Box>
  );
  const columnNames = (
    <Grid container columns={36}>
      <Grid item lg={9} sx={style.columnCell}>
        <Typography variant="body2" sx={style.columnName}>
          Medicine Name
        </Typography>
      </Grid>

      <Grid item lg={4} sx={style.columnCell}>
        <Typography variant="body2" sx={style.columnName}>
          Dose
        </Typography>
      </Grid>

      <Grid item lg={5} sx={style.columnCell}>
        <Typography variant="body2" sx={style.columnName}>
          Frequency
        </Typography>
      </Grid>

      <Grid item lg={7} sx={style.columnCell}>
        <Typography variant="body2" sx={style.columnName}>
          Timing
        </Typography>
      </Grid>

      <Grid item lg={4} sx={style.columnCell}>
        <Typography variant="body2" sx={style.columnName}>
          Days
        </Typography>
      </Grid>

      <Grid item lg={5} sx={style.columnCell}>
        <Typography variant="body2" sx={style.columnName}>
          Instruction
        </Typography>
      </Grid>
      <Grid item lg={2}></Grid>
    </Grid>
  );

  return (
    <Box sx={{ flexGrow: 1, p: 0 }}>
      <Snackbar
        open={openNotice}
        autoHideDuration={6000}
        onClose={handleCloseNotice}
      >
        <Alert
          onClose={handleCloseNotice}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Box sx={{ borderRadius: 1 }}>
        {addButtons}

        <Box sx={style.columnList}>{columnNames}</Box>

        <Box>
          <form onSubmit={submitHandler} id="myForm1">
            {formValues.map((element, index) => (
              <div key={index}>
                <Grid container rowSpacing={1} columns={36}>
                  {/* medicine name */}
                  <Grid
                    item
                    lg={9}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    <Autocomplete
                      fullWidth
                      freeSolo
                      disableClearable
                      size="small"
                      options={medicineList.map((option) => option.name)}
                      ListboxProps={{ sx: { fontSize: 11 } }}
                      PopperComponent={PopperMy}
                      onInputChange={(event, newInputValue) => {
                        fetchMedicinesHandler(newInputValue.toUpperCase());
                        element["medicineName"] = newInputValue;
                        if (element.newField) {
                          element["customMedicineName"] = newInputValue;
                        }
                        console.log({ Medicine_value: newInputValue });
                      }}
                      name="medicineName"
                      value={element.medicineName || ""}
                      selectOnFocus
                      onChange={(event, value) => {
                        element["medicineName"] = value;
                        element["oldMedicineName"] = value;
                        console.log({ Medicine_value: value });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="select/write medicine..."
                          multiline
                          variant={
                            isEmpty && !element.medicineName
                              ? "outlined"
                              : "standard"
                          }
                          error={isEmpty && !element.medicineName}
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                            style: { fontSize: 11 },
                            disableUnderline:
                              isEmpty && !element.medicineName ? false : true,
                          }}
                          InputLabelProps={{
                            style: { fontSize: 11, color: "#127DDD" },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {/* dosage */}
                  <Grid
                    item
                    lg={4}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                      pl: 1,
                    }}
                  >
                    <Autocomplete
                      fullWidth
                      freeSolo
                      disableClearable
                      size="small"
                      options={doseList.map((option) => option)}
                      ListboxProps={{ sx: { fontSize: 11 } }}
                      PopperComponent={PopperMy}
                      onInputChange={(event, newInputValue) => {
                        element["dosage"] = newInputValue;
                        setDoseList(generateDoseList(newInputValue));
                      }}
                      name="dosage"
                      value={element.dosage || ""}
                      selectOnFocus
                      onChange={(event, value) => {
                        element["dosage"] = value;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="eg 1 tablet"
                          variant={
                            isEmpty && !element.dosage ? "outlined" : "standard"
                          }
                          error={isEmpty && !element.dosage}
                          multiline
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                            style: { fontSize: 11 },
                            disableUnderline: true,
                          }}
                          InputLabelProps={{
                            style: { fontSize: 11, color: "#127DDD" },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid
                    item
                    lg={5}
                    sx={{
                      pl: 1,
                      display: "flex",
                      alignItems: "center",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    <FrequencyComp element={element} />
                  </Grid>
                  {/* timings */}
                  <Grid
                    item
                    lg={7}
                    sx={{
                      pl: 1,
                      display: "flex",
                      alignItems: "center",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    <TimingComp element={element}></TimingComp>
                  </Grid>

                  {/* duration */}
                  <Grid
                    item
                    lg={4}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                      pl: 1,
                    }}
                  >
                    <Autocomplete
                      fullWidth
                      freeSolo
                      disableClearable
                      size="small"
                      options={durationList.map((option) => option)}
                      ListboxProps={{ sx: { fontSize: 11 } }}
                      PopperComponent={PopperMy}
                      onInputChange={(event, newInputValue) => {
                        element["durationString"] = newInputValue;
                        setDurationList(generateDurationList(newInputValue));
                      }}
                      name="durationString"
                      value={element.durationString || ""}
                      selectOnFocus
                      onChange={(event, value) => {
                        element["durationString"] = value;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="eg 3"
                          variant="standard"
                          multiline
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                            style: { fontSize: 11 },
                            disableUnderline: true,
                          }}
                          InputLabelProps={{
                            style: { fontSize: 11, color: "#127DDD" },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {/* instructions  */}
                  <Grid
                    item
                    lg={5}
                    sx={{
                      pl: 1,
                      display: "flex",
                      alignItems: "center",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    <Autocomplete
                      fullWidth
                      freeSolo
                      disableClearable
                      size="small"
                      options={instructionList}
                      getOptionLabel={(option) =>
                        option.instruction +
                        (option.instructionVernacular
                          ? " (" + option.instructionVernacular + ")"
                          : "")
                      }
                      ListboxProps={{ sx: { fontSize: 11 } }}
                      PopperComponent={PopperMy}
                      onInputChange={(event, newInputValue) => {
                        const myArray = newInputValue.split(" (");
                        element["comment"] = {
                          id: null,
                          instruction: myArray[0],
                          instructionVernacular:
                            myArray.length > 1
                              ? myArray[1].split(")")[0]
                              : null,
                        };
                        if (element.newField) {
                          element["customInstruction"] = newInputValue;
                        }
                      }}
                      name="comment"
                      value={
                        element.comment || {
                          id: null,
                          instruction: "",
                          instructionVernacular: null,
                        }
                      }
                      selectOnFocus
                      onChange={(event, value) => {
                        element["comment"] = value;
                        element["oldInstruction"] = value.instruction;
                        console.log({ Instruction_value_o: value });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="eg with food"
                          variant="standard"
                          multiline
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                            style: { fontSize: 11 },
                            disableUnderline: true,
                          }}
                          InputLabelProps={{
                            style: { fontSize: 11, color: "#127DDD" },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* delete icon */}
                  <Grid item xs={2}>
                    <Stack
                      direction="row"
                      spacing={0}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <IconButton
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        aria-label="remove"
                        onClick={() => removeFormFields(index)}
                      >
                        <img src={deleteIcon} />
                      </IconButton>
                    </Stack>
                  </Grid>
                  <Grid item xs={36}>
                    <Divider />
                  </Grid>
                </Grid>
              </div>
            ))}
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default AddPrescriptionTemplateForDoc;
