import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { getFormattedDDMonthYYYY } from "../../../../../assets/utils";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import { Fragment, useState } from "react";
import { PhotoViewer } from "../../../../../assets/photoViewer";

const CustomTypography = ({ children }) => {
  return (
    <Typography
      sx={{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: 13,
        lineHeightt: 17,
        color: "#8A8A8A",
        textTransform: "capitalize",
      }}
    >
      {children}
    </Typography>
  );
};

const DisplayHealthChampions = ({ healthChampionsList }) => {
  const [imageURL, setImageURL] = useState("");
  const [open, setOpen] = useState(false);

  const handleOpen = (url) => {
    setOpen(true);
    setImageURL(url);
  };
  const handleClose = (url) => {
    setOpen(true);
    setImageURL(url);
  };

  return (
    <Fragment>
      <Box sx={{ mb: 4 }}>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Stack
              direction="row"
              spacing={3}
              useFlexGap
              flexWrap="wrap"
              display="flex"
              justifyContent="center"
            >
              {healthChampionsList.map((item, index) => (
                <Card sx={{ px: 2, py: 2 }} key={index}>
                  <CardContent>
                    <Stack
                      spacing={3}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {item?.imageUrl ? (
                        <Fragment>
                          <IconButton
                            onClick={() => handleOpen(item?.imageUrl)}
                          >
                            <img
                              src={item?.imageUrl}
                              style={{ height: 250, width: 250 }}
                            />
                          </IconButton>
                          {/* <IconButton onClick={() => handleOpen(item?.imageUrl)}>
                            <Avatar
                              src={item?.imageUrl}
                              sx={{ width: 250, height: 250 }}
                              variant="square"
                            />
                          </IconButton> */}
                        </Fragment>
                      ) : (
                        <Avatar
                          src={""}
                          sx={{ width: 250, height: 250 }}
                          variant="square"
                        />
                      )}
                      <Stack
                        spacing={2}
                        direction="row"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography>{item?.name}</Typography>
                        <IconButton
                          onClick={() => handleOpen(item?.firstAidUrl)}
                        >
                          <MedicalInformationIcon color="primary" />
                        </IconButton>
                      </Stack>

                      <Stack spacing={1}>
                        <Stack direction="row" spacing={3}>
                          <CustomTypography
                            sx={{ textTransform: "capitalize" }}
                          >
                            {`empId: ${item?.empId || "N/A"}`}
                          </CustomTypography>

                          <CustomTypography
                            sx={{ textTransform: "capitalize" }}
                          >
                            {`mobile: ${item?.mobile || "N/A"}`}
                          </CustomTypography>
                          <CustomTypography
                            sx={{ textTransform: "capitalize" }}
                          >
                            {`Age: ${item?.age || "N/A"}`}
                          </CustomTypography>
                        </Stack>

                        <Stack direction="row" spacing={3}>
                          <CustomTypography
                            sx={{ textTransform: "capitalize" }}
                          >
                            {`gender: ${item?.gender || "N/A"},`}
                          </CustomTypography>
                          <CustomTypography
                            sx={{ textTransform: "capitalize" }}
                          >
                            {`dateOfBirth: ${
                              item?.dateOfBirth
                                ? getFormattedDDMonthYYYY(item?.dateOfBirth)
                                : "N/A"
                            }`}
                          </CustomTypography>
                        </Stack>
                        <Stack direction="row" spacing={3}>
                          <CustomTypography
                            sx={{ textTransform: "capitalize" }}
                          >
                            {`department: ${item?.department || "N/A"}`}
                          </CustomTypography>
                        </Stack>

                        <Stack direction="row" spacing={3}>
                          <CustomTypography
                            sx={{ textTransform: "capitalize" }}
                          >
                            {`address: ${item?.address || "N/A"}`}
                          </CustomTypography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <PhotoViewer url={imageURL} open={open} handleClose={handleClose} />
    </Fragment>
  );
};

export default DisplayHealthChampions;
