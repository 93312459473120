import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Link, Stack, Typography } from "@mui/material";
import LogoMainNew from "../../../common/logo/logoMainNew";

const DigitalMarketingHeader = () => {
  const pages = [
    { name: "About Us", url: "/digitalMarketing" },
    { name: "Locations", url: "/digitalMarketing" },
    { name: "Specifications", url: "/digitalMarketing" },
    { name: "Conatct Us", url: "/digitalMarketing" },
  ];

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: "flex", lg: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", lg: "none" },
              }}>
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Link
                    underline="none"
                    variant="button"
                    color="text.primary"
                    href={page.url}
                    sx={{ my: 1, mx: 1.5 }}>
                    {page.name}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              display: { xs: "flex", lg: "none" },
            }}>
            <Stack
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
              display="flex"
              alignItems="center">
              <Link to="/">
                <LogoMainNew width={250} />
              </Link>
              <Typography variant="caption">Mysticdoc Healthcare PVT LTD.</Typography>
            </Stack>
          </Box>

          <Box sx={{ display: { xs: "none", lg: "flex" } }}>
            <Stack display="flex" alignItems="center">
              <Link to="/">
                <LogoMainNew width={300} />
              </Link>
              <Typography sx={{ mt: -2, ml: 0 }} variant="inherit">
                Mysticdoc Healthcare PVT LTD.
              </Typography>
            </Stack>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", lg: "flex" },
              justifyContent: "flex-end",
              alignItems: "center",
              mr: 2,
            }}>
            {pages.map((page) => (
              <Button
                href={page.url}
                key={page.name}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "black", display: "block" }}>
                {page.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default DigitalMarketingHeader;
