import RootTemplate from "../../global/templates/rootTemplate";
import PharmacyConsumablesMain from "../../modules/pharmacy/consumables/pharmacyConsumablesMain";

const ConsumablesIndex = () => {
  return (
    <RootTemplate>
      <PharmacyConsumablesMain />
    </RootTemplate>
  );
};

export default ConsumablesIndex;
