import { Box, Grid, TextField } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { GENDERLIST } from "../../../assets/constantsFile";

const PatientGender = ({ patient, setPatient, formValues }) => {
  const [gender, setGender] = useState("MALE");

  useEffect(() => {
    setGender(patient ? patient.gender : "MALE");
    formValues["gender"] = patient ? patient.gender : "MALE";
  }, [patient]);
  return (
    <Fragment>
      <Box>
        <Grid container>
          <Grid item lg={12}>
            <TextField
              fullWidth
              size={"small"}
              select
              label="Sex"
              value={gender}
              onChange={(e) => {
                setGender(e.target.value);
                formValues["gender"] = e.target.value;
              }}
              SelectProps={{
                native: true,
              }}
            >
              {GENDERLIST.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default PatientGender;
