import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { BASE_URL } from "../../../assets/constantsFile";
import { saveData } from "../../../services/api/postApi";

const ReferDoctorForm = ({
  patientId,
  caseId,
  appointmentId,
  selectedTitle,
  setIsEdit,
}) => {
  const [referenceData, setReferenceData] = useState({
    docId: "",
    patientId: patientId,
    caseId: caseId,
    referredDoctorName: "",
    issue: "",
    speciality: selectedTitle,
    appointmentId: appointmentId,
  });

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    const url = BASE_URL + "doctor/refer/";
    const reference = await saveData(url, referenceData, "");

    if (reference.error) {
      console.log("failed");
    } else {
      setIsEdit(false);
      console.log({ success: reference.data });
      setReferenceData({
        docId: "",
        patientId: "",
        caseId: "",
        referredDoctorName: "",
        issue: "",
        speciality: "",
        appointmentId: "",
      });
    }
  };
  return (
    <Box>
      <form onSubmit={onSubmitHandler}>
        <Box>
          <Stack spacing={3}>
            <TextField
              value={selectedTitle}
              disabled
              size="small"
              variant="outlined"
              sx={{
                border: "0.5px solid #000000",
                borderRadius: 4,
                "& fieldset": { border: "none" },
              }}
            />

            <TextField
              required={true}
              label="Doctor's Name"
              variant="standard"
              value={referenceData.referredDoctorName}
              onChange={(e) => {
                setReferenceData({
                  ...referenceData,
                  referredDoctorName: e.target.value,
                });
              }}
            />

            <Typography>Notes For The Referred Doctor</Typography>
            <TextField
              value={referenceData.issue}
              onChange={(e) => {
                setReferenceData({
                  ...referenceData,
                  issue: e.target.value,
                });
              }}
              multiline
              maxRows={4}
              minRows={4}
            />
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => setIsEdit(false)}
                sx={{
                  width: "140px",
                  height: "40px",
                  borderRadius: "15px",
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  width: "140px",
                  height: "40px",
                  borderRadius: "15px",
                }}
              >
                Confirm
              </Button>
            </Stack>
          </Stack>
        </Box>
      </form>
    </Box>
  );
};
export default ReferDoctorForm;
