import { Fragment, useState } from "react";
import CustomDataPanels from "../../../../global/customDatagrids/customDataPanels";
import { transformDataNew } from "../../../../global/actions/actions";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Portal,
  SvgIcon,
  Typography,
} from "@mui/material";
import { Button } from "@mui/base";
import { getUrlExtension } from "../../../../../assets/utils";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  EncryptedTypography,
  EncryptedTypographyName,
} from "../../../../global/components/encryptedTypography";

const ShowToggleData = ({ data }) => {
  return (
    <Typography
      sx={{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: 12,
        lineHeightt: 15,
        color: "#383838",
        textTransform: "capitalize",
      }}>
      {data ? "Yes" : data === "false" ? "No" : ""}
    </Typography>
  );
};

const CustomTypography = ({ title }) => {
  return (
    <Typography
      sx={{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeightt: 17,
        color: "#A8A8BD",
        textTransform: "capitalize",
      }}>
      {title}
    </Typography>
  );
};

const CSRDataGrid = ({ corpId, data = [], columns = [], apiRef, setApiRef }) => {
  columns = [
    {
      field: "empId",
      renderHeader: (params) => <CustomTypography title="Emp. ID" />,
      width: 100,
      type: "number",
      align: "left",
      headerAlign: "left",
      renderCell: (cellValues) => <EncryptedTypography text={cellValues.value} />,
    },
    {
      field: "name",
      renderHeader: (params) => <CustomTypography title="Name" />,
      width: 200,
      valueFormatter: (value) => `${value.name}`,
      renderCell: (cellValues) => <EncryptedTypographyName data={cellValues} />,
    },
    {
      field: "bp",
      align: "center",
      headerAlign: "center",
      width: 100,
      renderHeader: (params) => <CustomTypography title="BP" />,
    },
    {
      field: "sugar",
      align: "center",
      headerAlign: "center",
      width: 100,
      renderHeader: (params) => <CustomTypography title="Sugar" />,
    },
    {
      field: "height",
      align: "center",
      headerAlign: "center",
      width: 100,
      renderHeader: (params) => <CustomTypography title="Height" />,
    },
    {
      field: "weight",
      align: "center",
      headerAlign: "center",
      width: 100,
      renderHeader: (params) => <CustomTypography title="Weight" />,
    },
    {
      field: "bmi",
      align: "center",
      headerAlign: "center",
      width: 100,
      renderHeader: (params) => <CustomTypography title="BMI" />,
    },

    {
      field: "eyeTestUrl",
      renderHeader: (params) => <CustomTypography title="Eye Report" />,
      width: 120,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <IconButton
            disabled={cellValues.value ? false : true}
            aria-label="delete"
            size="small"
            onClick={() => {
              handleClickOpen(cellValues.value);
              setSelectedImage(cellValues.value);
            }}>
            {cellValues.value ? (
              <SvgIcon
                component={VisibilityIcon}
                inheritViewBox
                fontSize="inherit"
                sx={{ color: "#127DDD" }}
              />
            ) : cellValues.value === false ? (
              <SvgIcon
                component={VisibilityIcon}
                inheritViewBox
                fontSize="inherit"
                sx={{ color: "#127DDD" }}
              />
            ) : (
              <SvgIcon
                component={VisibilityIcon}
                inheritViewBox
                fontSize="inherit"
                sx={{ color: "#000000" }}
              />
            )}
          </IconButton>
        );
      },
    },

    {
      field: "eyeTest",
      renderHeader: (params) => <CustomTypography title="Eye Test" />,
      renderCell: (cellValues) => <ShowToggleData data={cellValues.value} />,
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "eyeSightOk",
      renderHeader: (params) => <CustomTypography title="Eye Sight Ok" />,
      renderCell: (cellValues) => <ShowToggleData data={cellValues.value} />,
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "nearSighted",
      renderHeader: (params) => <CustomTypography title="Near Sighted" />,
      renderCell: (cellValues) => <ShowToggleData data={cellValues.value} />,
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "farSighted",
      renderHeader: (params) => <CustomTypography title="Far Sighted" />,
      renderCell: (cellValues) => <ShowToggleData data={cellValues.value} />,
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "nearLeftEyeSight",
      align: "center",
      headerAlign: "center",
      width: 160,
      renderHeader: (params) => <CustomTypography title="Near Left EyeSight" />,
    },
    {
      field: "nearRightEyeSight",
      align: "center",
      headerAlign: "center",
      width: 160,
      renderHeader: (params) => <CustomTypography title="Near Right EyeSight" />,
    },
    {
      field: "farLeftEyeSight",
      align: "center",
      headerAlign: "center",
      width: 160,
      renderHeader: (params) => <CustomTypography title="Far Left EyeSight" />,
    },
    {
      field: "farRightEyeSight",
      align: "center",
      headerAlign: "center",
      width: 160,
      renderHeader: (params) => <CustomTypography title="Far Right EyeSight" />,
    },
  ];

  const [open, setOpen] = useState(false);

  const handleClickOpen = (url) => {
    const ext = getUrlExtension(url).toLowerCase();
    if (ext === "pdf") {
      window.open(url);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [selectedImage, setSelectedImage] = useState("");
  return (
    <Fragment>
      <Portal>
        <Dialog open={open} onClose={handleClose} maxWidth={"xl"} fullWidth={true}>
          <DialogContent>
            <Box
              sx={{ minHeight: "70vh" }}
              display="flex"
              justifyContent="center"
              alignItems="center">
              {selectedImage ? <img width="100%" src={selectedImage} /> : "No Document."}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Portal>

      <Box
        style={{
          height: "63vh",
          width: "100%",
          padding: "2px",
          backgroundColor: "#FFFFFF",
          border: "0.5px solid grey",
          borderRadius: "16px",
          marginBlock: "10px",
        }}>
        <Box style={{ display: "flex", height: "100%" }}>
          <Box
            sx={{
              flexGrow: 1,
              width: "100%",
              "& .super-app.abnormal": {
                //backgroundColor: "rgba(157, 255, 118, 0.49)",
                color: "#FFA500",
                fontWeight: "600",
              },
              "& .super-app.normal": {
                //backgroundColor: "#d47483",
                color: "#383838",
                fontWeight: "400",
              },
            }}>
            <CustomDataPanels
              apiRef={apiRef}
              corpId={corpId}
              setApiRef={setApiRef}
              dataList={transformDataNew(data).map((item, index) => ({
                id: index,
                ...item,
              }))}
              columns={columns}
            />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default CSRDataGrid;
