import { Grid, Typography } from "@mui/material";

const style = {
    header: {
        borderRight: "1px solid black",
        textAlign: "center",
        color: "#127DDD",
    },
    font: {
        fontWeight: 600,
        display: "flex",
        justifyContent: "center",
        color: "#127DDD",
    },
};
const TableHeader = (props) => {
    return (
        <Grid container sx={{ background: "#E3F2FF", p: 1, borderRadius: "15px", my: 0.5 }}>
            <Grid item xs={3} sx={style.header}>
                <Typography variant="body2" sx={style.font}>
                    Medicine
                </Typography>
            </Grid>

            <Grid item xs={1} sx={style.header}>
                <Typography variant="body2" sx={style.font}>
                    Dose
                </Typography>
            </Grid>

            <Grid item xs={2} sx={style.header}>
                <Typography variant="body2" sx={style.font}>
                    Freuency
                </Typography>
            </Grid>
            <Grid item xs={3} sx={style.header}>
                <Typography variant="body2" sx={style.font}>
                    Timings
                </Typography>
            </Grid>

            <Grid item xs={1} sx={style.header}>
                <Typography variant="body2" sx={style.font}>
                    Days
                </Typography>
            </Grid>
            <Grid item xs={2} sx={style.header}>
                <Typography variant="body2" sx={style.font}>
                    Instructions
                </Typography>
            </Grid>
        </Grid>
    );
};

export default TableHeader;
