import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Tab, Typography } from "@mui/material";
import { useState } from "react";
import BodyStatsIndex from "../bodyStats/bodyStatsIndex";
import OldAilmentsIndex from "../oldAilments/oldAilmentsIndex";

const OldCaseTabs = ({
  setCaseId,
  patient,
  pDetails,
  patientId,
  setSelectedTab,
  defaultTab,
  setSelectedVital,
}) => {
  const [value, setValue] = useState(defaultTab ? defaultTab : "1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      <TabContext value={value}>
        <Box>
          <TabList
            centered
            onChange={handleChange}
            sx={{
              px: 3,
              width: "100%",
              border: "none",
              ".Mui-selected": {
                backgroundColor: "white !important",
                borderRadius: "5px",
                fontWeight: "500",
                height: 2,
                "& p": {
                  color: "#127DDD !important",
                },
              },
              ".MuiTabs-indicator": {
                height: 5,
                background: "#127DDD !important",
                borderRadius: "10px",
              },
            }}
          >
            <Tab
              label={
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: ".7rem",
                    fontWeight: 600,
                  }}
                >
                  Body Stats
                </Typography>
              }
              value="1"
            />
            <Tab
              label={
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: ".7rem",
                    fontWeight: 600,
                  }}
                >
                  Old Ailments
                </Typography>
              }
              value="2"
            />
          </TabList>
        </Box>

        <Box>
          <TabPanel value="1" sx={{ px: 0, py: 1 }}>
            <Box
              sx={{
                border: "1px solid black",
                borderRadius: "25px",
                px: 2,
                py: 2,
                height: "74vh",
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <BodyStatsIndex
                setSelectedTab={setSelectedTab}
                patientId={patientId}
                setSelectedVital={setSelectedVital}
              />
            </Box>
          </TabPanel>
          <TabPanel value="2" sx={{ px: 0, py: 1 }}>
            <Box
              sx={{
                border: "1px solid black",
                borderRadius: "25px",
                px: 2,
                py: 2,
                height: "74vh",
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <OldAilmentsIndex
                patientId={patientId}
                setCaseId={setCaseId}
                setSelectedTab={setSelectedTab}
              />
            </Box>
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
};

export default OldCaseTabs;
