import {
  Alert,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Icon,
  IconButton,
  Link,
  Portal,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiRef,
} from "@mui/x-data-grid";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { getUrlExtension } from "../../../../../assets/utils";
import { updateData } from "../../../../../services/api/patchApi";
import EDEnrollNewMain from "../../../employeeDetails/enrollNew/edEnrollNewMain";

const CustomTypography = ({ title }) => {
  return (
    <Typography
      sx={{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: 13,
        lineHeightt: 17,
        color: "#8A8A8A",
        textTransform: "capitalize",
      }}>
      {title}
    </Typography>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
    // remove padding at beginning of column header container so that text is inline with column text
    "& .paxton-table--header": {
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        padding: 0,
      },
      // set header font styling
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 600,
        textTransform: "uppercase",
        fontSize: "16px",
      },
    },
    // Datagrid Row Styling
    "& .paxton-table--row": {
      border: ".5px solid #6B6B6B",
      //borderBottom: "1px solid #6B6B6B",
      borderRadius: 5,
      margintop: 7,
      marginBottom: 7,
      backgroundColor: "#fff",
      paddingTop: 8,
      paddingBottom: 8,
      boxSizing: "content-box",
    },
    // remove borders and separators
    "& .paxton-table--cell": {
      borderRight: "1px solid #6B6B6B",
    },
    "& .MuiDataGrid-cell": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: 12,
      lineHeightt: 15,
      color: "##383838",
      textTransform: "capitalize",
      boxSizing: "none",
      borderBottom: "none ! important",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer": {
      border: "none",
    },
    // This is to fix the rows being cut off due to adding padding
    "& .MuiDataGrid-viewport": {
      maxHeight: "unset !important",
    },
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "670px !important", // <= manually setting the max height, but this is not responsive
    },
  },
}));

const EmployeeDataGrid = ({
  setApiRef,
  employeeList = [],
  corpId,
  columns = [],
  open,
  setOpen,
}) => {
  columns = [
    {
      field: "empId",
      renderHeader: (params) => <CustomTypography title="Emp. ID" />,
      width: 150,
      type: "number",
      align: "left",
      renderCell: (cellValues) => <CustomTypography title={cellValues.value} />,
    },
    {
      field: "name",
      renderHeader: (params) => <CustomTypography title="Name" />,
      width: 300,
      align: "left",
      //valueFormatter: ({ value }) => `${value.name}`,
      renderCell: (cellValues) => <CustomTypography title={cellValues.value} />,
    },
    {
      field: "department",
      renderHeader: (params) => <CustomTypography title="Department" />,
      width: 150,
      type: "number",
      align: "left",
      renderCell: (cellValues) => <CustomTypography title={cellValues.value} />,
    },
    {
      field: "preEmploymentStatus",
      renderHeader: (params) => <CustomTypography title="Pre Employement Status" />,
      width: 180,
      type: "number",
      align: "left",
      renderCell: (cellValues) => <CustomTypography title={cellValues.value} />,
    },
  ];
  console.log({ corpId });
  const navigate = useNavigate();

  const classes = useStyles();

  const apiRef = useGridApiRef();

  useEffect(() => {
    setApiRef(apiRef);
  }, [apiRef]);

  //const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    // const ext = getUrlExtension(url).toLowerCase();
    // if (ext === "pdf") {
    //   window.open(url);
    // } else {
    //   setOpen(true);
    // }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [rows, setRows] = useState(employeeList);

  useEffect(() => {
    setRows(employeeList);
  }, [employeeList]);

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const [selectedImage, setSelectedImage] = useState("");
  console.log({ rows });

  const _submitHandler = async () => {
    const url = BASE_URL + `org/preEmployed/AhcStatus?corpId=${corpId}`;

    const payload = rows
      ?.filter((obj) => obj.preEmploymentStatus === "PENDING")
      ?.map((item, index) => item.empId);

    console.log({ payload });

    const response = await updateData(url, payload);

    if (response.error) {
      console.log("failed");
      setSeverity("error");
      setMessage("An error Occured");
      setOpenNotice(true);
    } else {
      console.log("success");
      setSeverity("success");
      setMessage("Saved Successfully");
      setOpenNotice(true);
    }
  };

  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [openNotice, setOpenNotice] = useState(false);

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };

  const exportCSV = () => {
    apiRef.current.exportDataAsCsv();
  };
  return (
    <Fragment>
      <Portal>
        <Dialog open={open} onClose={handleClose} maxWidth={"xl"} fullWidth={true}>
          <DialogContent sx={{ marginTop: "-100px" }}>
            {/* <Box
              sx={{ minHeight: "70vh" }}
              display="flex"
              justifyContent="center"
              alignItems="center">
              {selectedImage ? <img width="100%" src={selectedImage} /> : "No Document."}
            </Box> */}

            <EDEnrollNewMain />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Portal>

      <Snackbar open={openNotice} autoHideDuration={1000} onClose={handleCloseNotice}>
        <Alert onClose={handleCloseNotice} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>

      <Box style={{ height: "55vh", width: "100%" }}>
        <Box style={{ display: "flex", height: "100%" }}>
          <Box
            sx={{
              flexGrow: 1,
              width: "100%",
              "& .super-app.negative": {
                backgroundColor: "rgba(157, 255, 118, 0.49)",
                color: "#1a3e72",
                fontWeight: "600",
              },
              "& .super-app.positive": {
                backgroundColor: "#d47483",
                color: "#1a3e72",
                fontWeight: "600",
              },
            }}>
            <DataGrid
              apiRef={apiRef}
              rowHeight={24}
              rows={employeeList}
              columns={columns}
              className={classes.root}
              getRowClassName={() => "paxton-table--row"}
              getCellClassName={() => "paxton-table--cell"}
              disableRowSelectionOnClick
              processRowUpdate={processRowUpdate}

              // initialState={{
              //   columns: {
              //     columnVisibilityModel: {
              //       // Hide columns status and traderName, the other columns will remain visible
              //       age: false,
              //     },
              //   },
              // }}
              // slots={{
              //   toolbar: GridToolbar,
              // }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        component={Stack}
        spacing={3}
        direction={"row"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}>
        <Button
          sx={{ height: 40, borderRadius: 4, px: 3 }}
          onClick={_submitHandler}
          variant="contained">
          Consider these employees for next AHC
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ height: 40, borderRadius: 4, px: 3 }}
          onClick={exportCSV}>
          Download CSV
        </Button>
      </Box>
    </Fragment>
  );
};

export default EmployeeDataGrid;
