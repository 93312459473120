import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Fragment } from "react";

const TitleMobile = ({ title, buttomTitle }) => {
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <Typography
            sx={{
              color: "#127DDD",
              textAlign: "center",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              textTransform: "uppercase",
            }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Typography
            sx={{
              color: "#00000",
              textAlign: "center",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
              textTransform: "uppercase",
            }}>
            {buttomTitle}
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default TitleMobile;
