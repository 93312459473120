import { Box, Grid, Typography } from "@mui/material";
import DashboardHeaderCancelled from "../components/dashboardHeaderCancelled";
import DashboardItemCancelled from "../components/dashboardItemCancelled";

const CancelledDashboard = ({ patientList }) => {
  const cancelledList = patientList.filter(
    (patient) => patient.appointmentStatus === "CANCELLED"
  );
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <DashboardHeaderCancelled />
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        {cancelledList && cancelledList.length > 0 ? (
          <Box sx={{ height: "68vh", overflow: "auto", px: 1 }}>
            <Grid container>
              {cancelledList.map((d, i) => (
                <Grid item lg={12} md={12} sm={12} xs={12} key={i}>
                  <DashboardItemCancelled data={d} />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <Grid container sx={{ height: "68vh" }}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography>No patient here.</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CancelledDashboard;
