import { Box, Container } from "@mui/material";
import React, { Fragment } from "react";
import MyAppBarBeforeLogin from "../common/header/myAppBarBeforeLoginNew";
import LoginIndex from "../components/login/loginIndex";

const Login = () => {
  return (
    <Fragment>
      <Container disableGutters maxWidth={false}>
        <Box>
          <LoginIndex />
        </Box>
      </Container>
    </Fragment>
  );
};

export default Login;
