import React, { useEffect, useState } from "react";
import WifiIndicator, { SignalStrength } from "react-wifi-indicator";

const SignalStrengthWiFi = ({ wifiSpeed }) => {
  useEffect(() => {
    if (wifiSpeed <= 0) {
      setContent(<WifiIndicator strength={SignalStrength.DISCONNECTED} />);
    } else if (wifiSpeed > 0 && wifiSpeed < 2) {
      setContent(<WifiIndicator strength={SignalStrength.UNUSABLE} />);
    } else if (wifiSpeed > 2 && wifiSpeed < 4) {
      setContent(<WifiIndicator strength={SignalStrength.WEAK} />);
    } else if (wifiSpeed > 4 && wifiSpeed < 6) {
      setContent(<WifiIndicator strength={SignalStrength.OKAY} />);
    } else if (wifiSpeed > 6 && wifiSpeed < 8) {
      setContent(<WifiIndicator strength={SignalStrength.GREAT} />);
    } else if (wifiSpeed > 8) {
      setContent(<WifiIndicator strength={SignalStrength.EXCELLENT} />);
    }
  }, [wifiSpeed]);

  const [content, setContent] = useState(
    <WifiIndicator strength={SignalStrength.DISCONNECTED} />
  );
  return <div>{content}</div>;
};
export default SignalStrengthWiFi;
