import React, { Fragment, useEffect, useState } from "react";
import {
  getNearToExpiry,
  getReorderProductCounts,
  getTopConsumedMedicines,
} from "../../../../services/pharmacyAnlyticsGraphs";
import { Container, Grid } from "@mui/material";
import CustomBarChart from "./comp/customBarChart";
import { downloadCsv } from "../../../../../assets/utils";
import dayjs from "dayjs";
import { getData } from "../../../../../services/api/getApi";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { useSnackbar } from "notistack";

import DoublePieChart from "./comp/doublePieChart";

const transformData = (data) => {
  return Object.entries(data).map(([key, value]) => ({
    productName: key,
    consumedQuantity: value,
  }));
};

const StatusTracker = ({ corpId = localStorage.getItem("CORPID") }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [topMedicineGraph, setTopMedicineGraph] = useState([]);
  const [nearToExpiry, setNearToExpiry] = useState([]);
  const [nearToExpiryDetail, setNearToExpiryDetail] = useState([]);
  const [nearToExpiryConsolidatedData, setNearToExpiryConsolidatedData] = useState([]);
  const [reOrderInVenn, setReOrderInVenn] = useState([]);
  const [reorderProductGraph, setReorderProductGraph] = useState([]);
  const [reorderProductGraphDetail, setReorderProductGraphDetail] = useState([]);
  const [duration, setDuration] = useState("LAST_WEEK");

  useEffect(() => {
    getTopConsumedMedicines(corpId, setTopMedicineGraph, duration);
  }, [duration]);

  useEffect(() => {
    getNearToExpiry(
      corpId,
      setNearToExpiry,
      setNearToExpiryDetail,
      setNearToExpiryConsolidatedData
    );
    getReorderProductCounts(corpId, setReorderProductGraph, setReorderProductGraphDetail);
  }, []);

  const fetchReportConsumption = async () => {
    const currentDate = dayjs();
    const fromDate =
      duration === "LAST_WEEK"
        ? currentDate.subtract(7, "day").format("YYYY-MM-DD")
        : duration === "LAST_MONTH"
        ? currentDate.subtract(30, "day").format("YYYY-MM-DD")
        : duration === "LAST_YEAR"
        ? currentDate.subtract(365, "day").format("YYYY-MM-DD")
        : currentDate.format("YYYY-MM-DD");

    const url =
      BASE_URL +
      `inventory/reports/purchaseAndSales/${corpId}?transactionType=SALES&fromDate=${fromDate}&toDate=${currentDate.format(
        "YYYY-MM-DD"
      )}`;

    const result = await getData(url);
    if (result.error) {
      enqueueSnackbar(`${result.error.response.data.message}`, {
        variant: "error",
      });
    } else {
      if (result.data.length === 0) {
        enqueueSnackbar(`No Data Found`, {
          variant: "info",
        });
      } else {
        const temp = result.data.map((item, index) => ({
          empId: item.empId,
          productCode: item.productCode,
          productName: item.productName,
          brandName: item.brandName,
          batchCode: item.batchCode,
          expiryDate: item.expiryDate,
          quantity: item.quantity,
          baseUnit: item.baseUnit,
          depletedDate: item.depletedDate,
          // remarks: item.remarks,
          // depletedBy: item.depletedBy,
        }));
        downloadCsv(
          temp,
          `Top_Medicine_Consumption_${
            duration === "LAST_WEEK"
              ? "THIS_WEEK"
              : duration === "LAST_MONTH"
              ? "THIS_MONTH"
              : "THIS_YEAR"
          }`
        );
      }
    }
  };

  const fetchReportReorder = async () => {
    const url = BASE_URL + `inventory/reports/reorder/${corpId}`;
    const result = await getData(url);
    if (result.error) {
      enqueueSnackbar(`${result.error.response.data.message}`, {
        variant: "error",
      });
    } else {
      const temp = result.data.map((item, index) => ({
        productCode: item.productCode,
        productName: item?.productName,
        brandName: item.brandName,
        availableQuantity: item.availableQuantity,
        reorderPoint: item.reorderPoint,
        baseUnit: item.baseUnit,
      }));
      if (temp.length === 0) {
        enqueueSnackbar(`No Data Found`, {
          variant: "info",
        });
      } else {
        downloadCsv(temp, `Reorder_Report`);
      }
    }
  };

  const fetchExpiryReport = async () => {
    const url = BASE_URL + `inventory/reports/expiryAndNearToExpiry/${corpId}`;
    const result = await getData(url);
    if (result.error) {
      enqueueSnackbar(`${result.error.response.data.message}`, {
        variant: "error",
      });
    } else {
      const temp = result.data.map((item, index) => ({
        productCode: item.productCode,
        productName: item?.productName,
        brandName: item.brandName,
        batchCode: item.batchCode,
        expiryDate: item.expiryDate,
        availableQuantity: item.availableQuantity,
        baseUnit: item.baseUnit,
      }));
      if (temp.length === 0) {
        enqueueSnackbar(`No Data Found`, {
          variant: "info",
        });
      } else {
        downloadCsv(temp, `Expiry_Report`);
      }
    }
  };

  console.log({ nearToExpiryDetail });

  return (
    <Fragment>
      <Container maxWidth={false}>
        <Grid container sx={{ mt: 1 }} spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <CustomBarChart
              title="Top medicine Consumption"
              data={topMedicineGraph}
              duration={duration}
              COLORS={["#42BD53", "#FFE066", "#ffA500", "#FF6600", "#F66", "#FF3300", "#FF0000"]}
              disableClickable={true}
              setDuration={setDuration}
              minHeight={500}
              downloadExternalData={true}
              handleDownloadExternalData={() => {
                fetchReportConsumption();
              }}
              xAxisLabel={"Count of medicines"}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <CustomBarChart
                  title="Expiry"
                  data={transformData(nearToExpiry)}
                  minHeight={200}
                  COLORS={["#F66", "#ffA500", "#FFE066", "#42BD53"]}
                  dialogData={nearToExpiryDetail}
                  downloadExternalData={true}
                  handleDownloadExternalData={() =>
                    downloadCsv(nearToExpiryConsolidatedData, `Expiry_Data`)
                  }
                  xAxisLabel={"Count of medicines"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DoublePieChart
                  title="Medicines you should reorder"
                  data={reorderProductGraph}
                  COLORS={["rgb(66, 189, 83)", "#F66", "#ffA500"]}
                  labelHeading="Total"
                  minHeight={200}
                  downloadExternalData={true}
                  handleDownloadExternalData={() => {
                    fetchReportReorder();
                  }}
                  dialogData={reorderProductGraphDetail}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default StatusTracker;
