import RootTemplate from "../../global/templates/rootTemplate";
import AdminServiceMain from "../../modules/tickets/adminService/adminServiceMain";

const TicketsAdminServiceIndex = () => {
  return (
    <RootTemplate>
      <AdminServiceMain />
    </RootTemplate>
  );
};

export default TicketsAdminServiceIndex;
