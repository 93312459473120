import { Fragment } from "react";
import VisitApprovalMain from "../modules/visitor/visitApprovalMain";
import { useParams } from "react-router-dom";

const SAIndex = () => {
  const { id } = useParams();
  console.log({ id });
  return (
    <Fragment>
      <VisitApprovalMain id={id} />
    </Fragment>
  );
};

export default SAIndex;
