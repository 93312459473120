import React, { useState, Fragment } from "react";
import Typography from "@mui/material/Typography";
import { Box, Grid, Stack } from "@mui/material";
import Paper from "@mui/material/Paper";
import { getFormattedDayAndDate } from "../../../assets/utils";
import Medicines from "./medicines";
import TableHeader from "./tableHeader";

const PrescriptionTemplate = (props) => {
  return (
    <Fragment>
      <Box sx={{ border: "1px solid rgba(0, 0, 0, 0.50)", borderRadius: 1 }}>
        <Paper
          component={Stack}
          justifyContent="center"
          align="center"
          sx={{
            height: 25,
            background: "#9AE7EB",
            borderBottom: "1px solid rgba(0, 0, 0, 0.50)",
          }}
        >
          <Typography
            variant="body2"
            sx={{ width: "100%", flexShrink: 0, fontWeight: "500" }}
          >
            {getFormattedDayAndDate(props.createdAt)}
          </Typography>
        </Paper>

        <TableHeader />
      </Box>
      <Box sx={{ background: "#F3F3F3" }}>
        <Grid container>
          {props.medicines
            ? props.medicines.map((medicine, index) => (
                <Grid item lg={12} key={index}>
                  <Medicines
                    key={index}
                    index={index + 1}
                    medicines={medicine}
                  />
                </Grid>
              ))
            : ""}
        </Grid>
      </Box>
    </Fragment>
  );
};
export default PrescriptionTemplate;
