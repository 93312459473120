import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import Rx from "./rx/Rx";
import Tests from "./tests/Tests";
import Notes from "./notes/Notes";

const WritePrescriptionTab = ({ tabvalue, setTabValue }) => {
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("WRITE_PRESCRIPTION_TAB_VALUES_CMS", newValue);
  };

  useEffect(() => {
    const savedTab =
      typeof localStorage !== "undefined"
        ? localStorage.getItem("WRITE_PRESCRIPTION_TAB_VALUES_CMS")
        : null;
    if (savedTab) {
      setValue(savedTab);
    }
  }, []);

  const [patients, setPatients] = useState(null);

  return (
    <Box>
      <Box sx={{ width: "100%" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab label="Rx" value="1" />
              <Tab label="Tests" value="2" />
              <Tab label="Notes" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ p: 0 }}>
            <Rx patients={patients} />
          </TabPanel>

          <TabPanel value="2" sx={{ p: 0 }}>
            <Tests patients={patients} />
          </TabPanel>
          <TabPanel value="3" sx={{ p: 0 }}>
            <Notes patients={patients} />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default WritePrescriptionTab;

const styles = {
  tab: {
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "15px",
    color: "#8A8A8A",
    textTransform: "none",
  },
};
