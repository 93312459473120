import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Fragment } from "react";
import multispecialities from "../../../../../assets/images/multispecialities.png";
import pharmacyIcon from "../../../../../assets/images/pharmacyIcon.png";
import VideoConsultationIcon from "../../../../../assets/images/VideoConsultationIcon.png";
import labTestIcon from "../../../../../assets/images/labTestIcon.png";

const SecondBanner = () => {
  return (
    <Fragment>
      <Grid
        container
        spacing={1}
        sx={{
          marginBlock: "10px",
        }}>
        <Grid
          item
          xs={12}
          lg={12}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Box sx={{ display: "flex" }}>
            <Box>
              <Box
                component="img"
                src={multispecialities}
                style={{ height: "50px", width: "50px" }}
              />
            </Box>
            <Box sx={{ marginLeft: "10px" }}>
              <Typography
                sx={{
                  color: "#404040",
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "normal",
                }}>
                Multi-Specialities
              </Typography>
              <Typography
                sx={{
                  color: "#404040",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "normal",
                }}>
                Highly specialized doctors to in various fields at service.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          lg={12}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Box sx={{ display: "flex" }}>
            <Box>
              <Box
                component="img"
                src={VideoConsultationIcon}
                style={{ height: "50px", width: "50px" }}
              />
            </Box>
            <Box sx={{ marginLeft: "10px" }}>
              <Typography
                sx={{
                  color: "#404040",
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "normal",
                }}>
                Video Consultation
              </Typography>
              <Typography
                sx={{
                  color: "#404040",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "normal",
                }}>
                Lorem ipsum dolor sit amet
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          lg={12}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Box sx={{ display: "flex" }}>
            <Box>
              <Box component="img" src={pharmacyIcon} style={{ height: "50px", width: "50px" }} />
            </Box>
            <Box sx={{ marginLeft: "10px" }}>
              <Typography
                sx={{
                  color: "#404040",
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "normal",
                }}>
                Pharmacy
              </Typography>
              <Typography
                sx={{
                  color: "#404040",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "normal",
                }}>
                Lorem ipsum dolor sit amet
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          lg={12}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Box sx={{ display: "flex" }}>
            <Box>
              <Box component="img" src={labTestIcon} style={{ height: "50px", width: "50px" }} />
            </Box>
            <Box sx={{ marginLeft: "10px" }}>
              <Typography
                sx={{
                  color: "#404040",
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "normal",
                }}>
                Lab Tests
              </Typography>
              <Typography
                sx={{
                  color: "#404040",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "normal",
                }}>
                Lorem ipsum dolor sit amet
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ marginBlock: "20px", textAlign: "center" }}>
        <Button variant="contained" sx={{ borderRadius: "10px" }}>
          <Typography
            sx={{
              color: "#FFFFFF",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "normal",
              textTransform: "capitalize",
              marginInline: "15px",
            }}>
            Explore All
          </Typography>
        </Button>
      </Box>
    </Fragment>
  );
};

export default SecondBanner;
