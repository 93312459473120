import { Box, Grid, Typography } from "@mui/material";

const EmergencyHandlingMain = () => {
  return (
    <Box sx={{ height: "80vh" }} display="flex" justifyContent="center" alignItems="center">
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display="flex"
          justifyContent="center"
          alignItems="center">
          <Typography variant="h6"> Please call at 1800 8890 189.</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmergencyHandlingMain;
