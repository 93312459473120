import { Fragment } from "react";
import PrivacyMain from "../Privacy/privacyMain";

const Privacy = () => {
  return (
    <Fragment>
      <PrivacyMain />
    </Fragment>
  );
};

export default Privacy;
