import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import SearchEmployeeAutocomplete from "../../../../global/components/searchEmployeeAutocomplete";
import HealthRecordDataGridNew from "../../../../global/customDatagrids/healthRecordDataGridNew";

import { BASE_URL } from "../../../../../assets/constantsFile";
import { getData } from "../../../../../services/api/getApi";
import { getDepartments } from "../../../../global/services/corporateServices";
import TableLegend from "../../../../global/components/tableLegend";
import MaskingButton from "../../../../global/components/maskingButton";
import HealthStatusFilter from "../../../../global/components/healthStatusFilter";
import EmploymentTypeFilter from "../../../../global/components/employmentTypeFilter";
import StatusFilter from "../../../../global/components/statusFilter";
import DownloadCSV from "../../../../global/components/downloadCSV";
import FilterMain from "./fliters/filterMain";
import SelectCamp from "../../../../global/selectCamp/selectCamp";
import DateRangeFilters from "./fliters/dateRangeFilters";
import dayjs from "dayjs";

const EHRMain = ({ corpId = localStorage.getItem("CORPID"), config }) => {
  const [apiRef, setApiRef] = useState(null);
  const exportCSV = () => {
    apiRef.current.exportDataAsCsv();
  };
  const [selectedCampId, setSelectedCampId] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeListStatic, setEmployeeListStatic] = useState([]);
  const [selectedEmploymentType, setSelectedEmploymentType] = useState("ALL");
  const [departmentList, setDepartmentList] = useState([]);

  const [selectDepartment, setSelectDepartment] = useState(null);
  const [selectStatus, setSelectStatus] = useState("ALL");
  const [healthStatus, setHealthStatus] = useState("ALL");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [searchText, setSearchText] = useState("");

  const fetchEmployeeList = async () => {
    if (selectedCampId !== "") {
      const url =
        BASE_URL +
        `org/detailed/all?corpId=${corpId}&campCycleId=${
          selectedCampId === "null" ? "" : selectedCampId
        }`;
      const employees = await getData(url, "");

      if (employees.error) {
      } else {
        console.log({ employees: employees.data });
        let list = employees.data;

        setEmployeeList(
          list.filter(
            (item) =>
              (selectDepartment ? item.department === selectDepartment : item) &&
              (selectStatus === "COMPLETED" || selectStatus === "PENDING"
                ? item.status === selectStatus
                : item)
          )
        );
        setEmployeeListStatic(employees.data);
      }
    }
  };

  useEffect(() => {
    getDepartments(corpId, setDepartmentList);
    fetchEmployeeList();
  }, [selectedCampId]);

  useEffect(() => {
    setEmployeeList(
      employeeListStatic.filter(
        (item) =>
          (selectDepartment ? item.department === selectDepartment : item) &&
          (selectedEmploymentType === "ALL"
            ? item
            : selectedEmploymentType === "ONROLL"
            ? item.employmentType === "ONROLL"
            : selectedEmploymentType === "CONTRACTOR"
            ? item.employmentType !== "ONROLL"
            : item) &&
          (selectStatus === "COMPLETED" || selectStatus === "PENDING"
            ? item.status === selectStatus
            : item) &&
          (healthStatus === "FIT"
            ? !item.healthStatus || item.healthStatus === "FIT"
            : healthStatus === "UNFIT"
            ? item.healthStatus === "UNFIT"
            : item) &&
          (startDate && endDate
            ? (dayjs(item.date).isAfter(dayjs(startDate), "day") ||
                dayjs(item.date).isSame(dayjs(startDate), "day")) &&
              (dayjs(item.date).isBefore(dayjs(endDate), "day") ||
                dayjs(item.date).isSame(dayjs(endDate), "day"))
            : startDate
            ? dayjs(item.date).isAfter(dayjs(startDate), "day") ||
              dayjs(item.date).isSame(dayjs(startDate), "day")
            : endDate
            ? dayjs(item.date).isBefore(dayjs(endDate), "day") ||
              dayjs(item.date).isSame(dayjs(endDate), "day")
            : true)
      )
    );
  }, [selectDepartment, selectStatus, healthStatus, selectedEmploymentType, startDate, endDate]);

  const clearSearch = () => {
    setSearchText("");
    setEmployeeList(employeeListStatic);
    setSelectDepartment(null);
    setSelectStatus("ALL");
    setSelectedEmploymentType("ALL");
    setHealthStatus("ALL");
  };

  console.log({ selectDepartment });

  console.log({ employeeListStatic, selectedEmploymentType, employeeList });

  const showAllHandler = () => {
    setEmployeeList(employeeListStatic);
    setSelectDepartment(null);
    setSelectStatus("ALL");
    setSelectedEmploymentType("ALL");
    setSearchText("");
    setHealthStatus("ALL");
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <Box sx={{ mt: 1 }}>
      <Grid container rowSpacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={4} sm={4} md={3} lg={3}>
                <Box sx={{ position: "relative", bottom: -17 }}>
                  <SelectCamp
                    selectedCampId={selectedCampId}
                    setSelectedCampId={setSelectedCampId}
                  />
                </Box>
              </Grid>
              <Grid item xs={8} sm={8} md={9} lg={9} display="flex" justifyContent="flex-end">
                <DateRangeFilters
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={7} lg={7}>
                <FilterMain
                  showAllHandler={showAllHandler}
                  selectedEmploymentType={selectedEmploymentType}
                  setSelectedEmploymentType={setSelectedEmploymentType}
                  healthStatus={healthStatus}
                  setHealthStatus={setHealthStatus}
                  selectStatus={selectStatus}
                  setSelectStatus={setSelectStatus}
                  selectDepartment={selectDepartment}
                  setSelectDepartment={setSelectDepartment}
                  departmentList={departmentList}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4}>
                <SearchEmployeeAutocomplete
                  employees={employeeListStatic}
                  employeeList={employeeList}
                  setEmployeeList={setEmployeeList}
                  clearSearch={clearSearch}
                  searchTerm={searchText}
                  setSearchTerm={setSearchText}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={1} lg={1} display="flex" justifyContent="flex-end">
                <Stack direction="row" spacing={1}>
                  <DownloadCSV exportCSV={exportCSV} type="ICON" />

                  <MaskingButton type="ICON" />
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 1 }}>
          <TableLegend />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <HealthRecordDataGridNew
            setApiRef={setApiRef}
            employeeList={employeeList}
            corpId={corpId}
            config={config}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EHRMain;
