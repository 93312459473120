import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import ViewReportComponent from "../../viewReport/viewReportComponent";
import DashboardHeaderAttended from "../components/dashboardHeaderAttended";
import DashboardItemAttended from "../components/dashboardItemAttended";

const AttendedDashboard = ({ patientList }) => {
  const attendedList = patientList.filter(
    (patient) => patient.appointmentStatus === "ATTENDED"
  );

  const [isViewPrescription, setIsViewPrescription] = useState(false);
  const [selectedItem, setselectedItem] = useState();

  const hidePrescription = () => {
    setIsViewPrescription(false);
  };

  if (isViewPrescription) {
    return (
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ height: "75vh", overflow: "auto", px: 1 }}>
            <ViewReportComponent
              patientDetails={selectedItem}
              onViewPres={hidePrescription}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <DashboardHeaderAttended />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        {attendedList && attendedList.length > 0 ? (
          <Box sx={{ height: "68vh", overflow: "auto", px: 1 }}>
            {attendedList.map((d, i) => (
              <DashboardItemAttended
                key={i}
                data={d}
                setIsViewPrescription={setIsViewPrescription}
                setselectedItem={setselectedItem}
              />
            ))}
          </Box>
        ) : (
          <Grid container sx={{ height: "68vh" }}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography>No patient here.</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default AttendedDashboard;
