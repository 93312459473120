import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Button,
  Typography,
  Box,
  Container,
  Grid,
  useMediaQuery,
  Slide,
  Card,
  TextField,
  CardContent,
  Stack,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import bannerImg from "../assets/bannerImg.png";
import count from "../assets/count.png";
import count1 from "../assets/count1.png";

import ContactForm from "./subComps/contactForm";
import ContactUsDialog from "./subComps/contactUsDialog";
import StarIcon from "@mui/icons-material/Star";
import useWindowDimensions from "../../../common/hooks/useWindowDimension";

const Banner = ({ scrollToSection, moveCursorToForm }) => {
  const theme = useTheme();

  // Define breakpoints for conditional rendering
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(true);
  }, []);

  const firstTextFieldRef = useRef(null);

  moveCursorToForm.current = () => {
    if (firstTextFieldRef.current) {
      firstTextFieldRef.current.focus();
    }
  };

  const handleButtonClick = () => {
    if (moveCursorToForm.current) {
      moveCursorToForm.current();
    }
  };

  const { width } = useWindowDimensions();
  return (
    <Fragment>
      <Box
        sx={{
          background:
            "linear-gradient(to left, rgba(218, 220, 255, 1) 70%, rgba(248, 249, 255, 1) 100%)",
          pb: { xs: 10, sm: 10, lg: 6 },
        }}>
        <Container maxWidth={false} sx={{ paddingTop: 5 }}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent={isXs || isSm ? "center" : "flex-start"}>
            <Slide timeout={1000} direction="right" in={checked} mountOnEnter unmountOnExit>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box
                  sx={{
                    marginLeft: { xs: 0, sm: 0, md: 6 },
                    marginBottom: 2,
                    textAlign: { xs: "center", sm: "center", md: "left" },
                  }}>
                  <Box>
                    <Typography
                      style={{
                        color: "#000",
                        fontSize: "48px",
                        fontWeight: "700",
                        lineHeight: "62.4px",
                        letterSpacing: "2.88px",
                      }}>
                      Comprehensive <br />
                      Healthcare Service & <br />
                      Related Needs!
                    </Typography>
                  </Box>
                  <Box sx={{ marginTop: 5, marginBottom: 5 }}>
                    <Typography
                      style={{
                        color: "#000",
                        fontSize: "28px",
                        fontWeight: "400",
                        lineHeight: "44.8px",
                        letterSpacing: "0.56px",
                      }}>
                      Aims to spark a healthcare revolution in hinterlands & suburbs of India.
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      marginBottom: 5,
                      justifyContent: {
                        xs: "center",
                        sm: "center",
                        md: "flex-start",
                      },
                    }}>
                    <Button
                      // onClick={() => scrollToSection("contactUs")}
                      onClick={() => handleButtonClick()}
                      style={{
                        width: 261,
                        height: 54,
                        paddingLeft: 28,
                        paddingRight: 28,
                        paddingTop: 16,
                        paddingBottom: 16,
                        backgroundColor: "#3E48EE",
                        borderRadius: 8,
                      }}>
                      <Typography
                        style={{
                          textAlign: "center",
                          color: "#FFF",
                          fontSize: "18px",
                          fontWeight: "600",
                          lineHeight: "21.6px",
                        }}>
                        Schedule a Meeting
                      </Typography>
                    </Button>
                    {/* <ContactUsDialog buttonSource={"Schedule a Meeting"} /> */}
                  </Box>

                  <Box>
                    {/* <Box component={"img"} src={count} /> */}
                    {/* <Box component={"img"} src={count1} /> */}
                    <Box>
                      <Grid container>
                        <Grid item lg={3.3}>
                          <Stack direction="row" spacing={1}>
                            <Box component={Stack} spacing={0}>
                              <Box
                                sx={{
                                  borderLeft: "6px solid #e1e3ff",
                                  height: 40,
                                  borderTopRightRadius: 100,
                                  borderBottomRightRadius: 100,
                                }}
                              />
                              <Box
                                sx={{
                                  borderLeft: "6px solid #000eb1",
                                  height: 40,
                                  borderTopLeftRadius: 100,
                                  borderBottomRightRadius: 100,
                                  marginTop: -1,
                                }}
                              />
                            </Box>

                            <Stack spacing={1}>
                              <Stack direction="row" display="flex" alignItems="center">
                                <Typography
                                  sx={{
                                    lineHeight: 1,
                                    letterSpacing: 1,
                                    color: "#000eb1",
                                    fontWeight: 600,
                                    fontSize: 38,
                                  }}>
                                  150+
                                </Typography>
                              </Stack>

                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontWeight: 500,
                                  fontSize: 18,
                                  lineHeight: 1.1,
                                  letterSpacing: 0.5,
                                  fontFamily: "Roboto",
                                }}>
                                Companies
                                <br /> trust us
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item lg={5}>
                          <Stack direction="row" spacing={1}>
                            <Box component={Stack} spacing={0}>
                              <Box
                                sx={{
                                  borderLeft: "6px solid #e1e3ff",
                                  height: 40,
                                  borderTopRightRadius: 100,
                                  borderBottomRightRadius: 100,
                                }}
                              />
                              <Box
                                sx={{
                                  borderLeft: "6px solid #000eb1",
                                  height: 40,
                                  borderTopLeftRadius: 100,
                                  borderBottomRightRadius: 100,
                                  marginTop: -1,
                                }}
                              />
                            </Box>
                            <Stack spacing={1}>
                              <Stack direction="row" display="flex" alignItems="center">
                                <Typography
                                  sx={{
                                    lineHeight: 1,
                                    letterSpacing: 1,
                                    color: "#000eb1",
                                    fontWeight: 600,
                                    fontSize: 38,
                                  }}>
                                  1.2 Lakh+
                                </Typography>
                              </Stack>

                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontWeight: 500,
                                  fontSize: 18,
                                  lineHeight: 1.1,
                                  letterSpacing: 0.5,
                                  fontFamily: "Roboto",
                                }}>
                                Lives
                                <br /> touched
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item lg={3.7}>
                          <Stack direction="row" spacing={1}>
                            <Box component={Stack} spacing={0}>
                              <Box
                                sx={{
                                  borderLeft: "6px solid #e1e3ff",
                                  height: 40,
                                  borderTopRightRadius: 100,
                                  borderBottomRightRadius: 100,
                                }}
                              />
                              <Box
                                sx={{
                                  borderLeft: "6px solid #000eb1",
                                  height: 40,
                                  borderTopLeftRadius: 100,
                                  borderBottomRightRadius: 100,
                                  marginTop: -1,
                                }}
                              />
                            </Box>
                            <Stack spacing={1}>
                              <Stack direction="row" display="flex" alignItems="center">
                                <Typography
                                  sx={{
                                    lineHeight: 1,
                                    letterSpacing: 1,
                                    color: "#000eb1",
                                    fontWeight: 600,
                                    fontSize: 38,
                                  }}>
                                  4.9
                                </Typography>
                                <Typography
                                  sx={{
                                    lineHeight: 1,
                                    letterSpacing: 1,
                                    color: "#000eb1",
                                    fontWeight: 600,
                                    fontSize: 26,
                                  }}>
                                  &#9733;
                                </Typography>
                              </Stack>

                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontWeight: 500,
                                  fontSize: 18,
                                  lineHeight: 1.1,
                                  letterSpacing: 0.5,
                                  fontFamily: "Roboto",
                                }}>
                                Rating by
                                <br /> employees
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                    {/* <Box sx={{ position: "relative", bottom: 88, left: width / 3 + 37 }}>
                      <StarIcon sx={{ color: "#000eb1" }} />
                    </Box> */}
                  </Box>
                </Box>
              </Grid>
            </Slide>
            <Slide timeout={1000} direction="left" in={checked} mountOnEnter unmountOnExit>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <ContactForm firstTextFieldRef={firstTextFieldRef} />
              </Grid>
            </Slide>
          </Grid>
        </Container>
      </Box>
    </Fragment>
  );
};

export default Banner;
