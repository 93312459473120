import { Container, Grid } from "@mui/material";
import DashboardIndex from "./dashboards/dashboardIndex";

const Index = () => {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{ p: 2, backgroundColor: "#F5F5F5", height: "89vh" }}
    >
      <Grid container>
        <Grid item sm={12} md={12} lg={12} xs={12}>
          <DashboardIndex />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Index;
