import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  Portal,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { Fragment, useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import DownloadCSV from "../../../../global/components/downloadCSV";
import MaskingButton from "../../../../global/components/maskingButton";
import StatusTrackerDetailGrid from "../../../../global/customDatagrids/statusTrackerDetailGrid";

const YearOnYearStackedBarChart = ({ data, title, dialogData = [] }) => {
  const [apiRef, setApiRef] = useState(null);
  const exportCSV = () => {
    apiRef.current.exportDataAsCsv();
  };
  const [open, setOpen] = useState(false);
  const [selectedChart, setSelectedChart] = useState("");
  const [selectedBarData, setSelectedBarData] = useState([]);
  const handleOpen = (title) => {
    setOpen(true);
    setSelectedChart(title);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedChart("");
    setSelectedBarData([]);
  };

  useEffect(() => {
    if (dialogData) {
      const data = dialogData[selectedChart];
      console.log({ dialogData, data72654239: data, selectedChart });
      if (data) {
        setSelectedBarData(
          data?.map((value, index) => ({
            id: `employee ${index}`,
            emp_id: value.empId || value.emp_id,
            name: value.name,
            department: value.department,
          }))
        );
      } else {
        console.log("Selected chart not found in dialogData");
      }
    }
  }, [selectedChart]);

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          border: "1px solid grey",
          paddingInline: "10px",
          pt: "10px",
          borderRadius: "10px",
        }}
      >
        <Typography
          textAlign="left"
          sx={{
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "120%",
            marginBottom: "20px",
          }}
        >
          {title}
        </Typography>
        <Box sx={{}}>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              layout="vertical"
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis
                dataKey="name"
                type="category"
                width={100}
                ticks={generateCustomTicks(data || [])}
                tickFormatter={(tick) => tick}
                style={{ fontSize: 12 }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend
                verticalAlign="top"
                height={50}
                payload={uniqueLegend}
                formatter={(value) => value}
              />

              {generateBars(data)}
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Box>

      <Portal>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={"xl"}
          fullWidth={true}
        >
          <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
            {title}
          </DialogTitle>
          <Typography
            variant="h6"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {selectedChart}
          </Typography>
          <Box sx={{ marginLeft: "22px" }}>
            <Stack direction="row" spacing={1}>
              <DownloadCSV exportCSV={exportCSV} type="ICON" />
              <MaskingButton type="ICON" />
            </Stack>
          </Box>
          <DialogActions sx={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
          <DialogContent>
            <StatusTrackerDetailGrid
              setApiRef={setApiRef}
              employeeList={selectedBarData || []}
            />
          </DialogContent>
        </Dialog>
      </Portal>
    </Fragment>
  );
};

export default YearOnYearStackedBarChart;

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#FFF",
          borderRadius: "5px",
          paddingInline: 2,
        }}
      >
        <p className="label">{`${label}`}</p>
        {payload.map((entry, index) => (
          <p key={index} style={{ color: entry.color }}>
            {`${entry.name}: ${entry.payload[entry.dataKey]}% (${
              entry.payload[`${entry.dataKey.replace("_percent", "_value")}`]
            })`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const generateCustomTicks = (data) => {
  const maxValue = Math.max(...data.map((item) => item.value));
  const ticks = [];
  for (let i = 0; i <= maxValue; i++) {
    ticks.push(i);
  }
  return ticks;
};

const generateBars = (data) => {
  const bars = [];

  data.forEach((item) => {
    // Create Normal bars
    bars.push(
      <Bar
        key={`${item.name}_Normal_percent`}
        dataKey={`${item.name}_Normal_percent`}
        stackId="a"
        fill="#82ca9d" // Green color for Normal
        name={`${item.name} - Normal`}
        barSize={30}
      />
    );

    // Create Medication Advised bars
    bars.push(
      <Bar
        key={`${item.name}_MedicationAdvised_percent`}
        dataKey={`${item.name}_MedicationAdvised_percent`}
        stackId="a"
        fill="#F66" // Red color for Medication Advised
        name={`${item.name} - Medication Advised`}
        barSize={30}
      />
    );
  });

  return bars;
};

const uniqueLegend = [
  { value: "Normal", color: "#82ca9d" },
  { value: "Medication Advised", color: "#F66" },
];
