import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";
import LandingHeaderNew from "../landingPageNew/global/header/landingHeaderNew";

const PageNotFound = () => {
  return (
    <Fragment>
      <LandingHeaderNew />
      <Box sx={{ height: "60vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Typography>Page does not exist.</Typography>
      </Box>
    </Fragment>
  );
};

export default PageNotFound;
