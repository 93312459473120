import { Box, Fab, Zoom } from "@mui/material";
import { Fragment } from "react";
import ChatIcon from "@mui/icons-material/Chat";
import { useTheme } from "@mui/material/styles";

const ChatFabButton = ({ setIsChatOpen }) => {
  const theme = useTheme();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <Fragment>
      <Box>
        <Zoom
          in={true}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${transitionDuration.exit}ms`,
          }}
          unmountOnExit>
          <Fab
            onClick={() => setIsChatOpen(true)}
            color="primary"
            variant="extended"
            sx={{
              position: "fixed",
              bottom: "32px",
              right: "100px",
              textTransform: "capitalize",
            }}>
            <ChatIcon sx={{ mr: 1 }} />
            UnoTalk
          </Fab>
        </Zoom>
      </Box>
    </Fragment>
  );
};

export default ChatFabButton;
