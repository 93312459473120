import { Container, Grid } from "@mui/material";
import LandingFeaturesLeft from "./landingFeaturesLeft";
import LandingFeaturesRight from "./landingFeaturesRight";

const LandingFeatures = () => {
  return (
    <Grid container>
      <Grid item lg={6}>
        <LandingFeaturesLeft />
      </Grid>
      <Grid item lg={6}>
        <LandingFeaturesRight />
      </Grid>
    </Grid>
  );
};
export default LandingFeatures;
