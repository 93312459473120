import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { useState } from "react";
import { getData } from "../../../../../services/api/getApi";
import StackedBarChartPe from "./stackedBarChartPe";
import PEDeatiledData from "./peDeatiledData";

const convertMapToObject = (data) => {
  const myMap = data ? new Map(Object.entries(data)) : null;

  const arr = myMap
    ? Array.from(myMap, function (item) {
        const [name, { pass, fail, pending }] = item;
        return { name, pass, fail, pending };
      })
    : [];

  return arr;
};

const sortDataByMonth = (dataArray) => {
  if (!dataArray || dataArray?.length === 0) {
    // Handle the case where dataArray is null or empty
    return [];
  }
  const monthOrder = {
    JANUARY: 1,
    FEBRUARY: 2,
    MARCH: 3,
    APRIL: 4,
    MAY: 5,
    JUNE: 6,
    JULY: 7,
    AUGUST: 8,
    SEPTEMBER: 9,
    OCTOBER: 10,
    NOVEMBER: 11,
    DECEMBER: 12,
  };

  // Sort the array based on the monthOrder values
  const sortedData = dataArray.sort((a, b) => monthOrder[a.name] - monthOrder[b.name]);

  return sortedData;
};

const PeStatusTracker = ({ corpId = localStorage.getItem("CORPID") }) => {
  const startDate = "2023-01-01";
  const endDate = "2024-12-31";

  const [monthlyData, setMonthlyData] = useState(null);

  const _fetchEmployeeAnalytics = async () => {
    const url =
      BASE_URL + `org/preEmployed/stats?corpId=${corpId}&startDate=${startDate}&endDate=${endDate}`;

    const response = await getData(url, "");

    if (response.error) {
      //console.warn("error");
    } else {
      const data = Object.entries(response.data)?.[0]?.[1];
      //setMonthlyData(Object.fromEntries(data));

      setMonthlyData(convertMapToObject(response.data?.["2024"]));
    }
  };

  useEffect(() => {
    _fetchEmployeeAnalytics();
  }, []);

  const [monthlyEmployeeData, setMonthlyEmployeeData] = useState([]);

  const _fetchEmployeeDetail = async () => {
    const url =
      BASE_URL + `org/preEmployed?corpId=${corpId}&startDate=${startDate}&endDate=${endDate}`;

    const response = await getData(url, "");

    if (response.error) {
      //console.warn("error");
    } else {
      //const data = Object.entries(response.data);
      setMonthlyEmployeeData(response.data?.["2024"]);
    }
  };

  useEffect(() => {
    _fetchEmployeeDetail();
  }, []);

  console.log({ monthlyEmployeeData });

  const [apiRef, setApiRef] = useState(null);

  console.log(monthlyData);

  const [isGraph, setIsGraph] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState("");

  console.log({ isGraph, selectedMonth });

  return (
    <Box>
      <Grid container rowSpacing={6}>
        {isGraph ? (
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}>
            <Typography variant="h6">Monthly Charts</Typography>
          </Grid>
        ) : null}
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}>
          {isGraph ? (
            <StackedBarChartPe
              data={sortDataByMonth(monthlyData)}
              setIsGraph={setIsGraph}
              setSelectedBar={setSelectedMonth}
              disablePopup={true}
              dialogData={monthlyEmployeeData}
              title={"Pre Employeement Data"}
            />
          ) : (
            <PEDeatiledData setIsGraph={setIsGraph} selectedMonth={selectedMonth} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PeStatusTracker;
