import img1 from "../images/awarenessSessionImgs/img1.png";
import img2 from "../images/awarenessSessionImgs/img2.png";
import img3 from "../images/awarenessSessionImgs/img3.png";
import img4 from "../images/awarenessSessionImgs/img4.png";
import img5 from "../images/awarenessSessionImgs/img5.png";
import img6 from "../images/awarenessSessionImgs/img6.png";
import img7 from "../images/awarenessSessionImgs/img7.png";
import img8 from "../images/awarenessSessionImgs/img8.png";
import img9 from "../images/awarenessSessionImgs/img9.png";
import noSessionIcon from "../images/awarenessSessionImgs/noSessionIcon.jpeg";

export default {
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  noSessionIcon,
};
