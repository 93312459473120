import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  ImageList,
  ImageListItem,
  Portal,
  Stack,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import BackHandIcon from "@mui/icons-material/BackHand";
import DateRangeIcon from "@mui/icons-material/DateRange";
import HorizontalSplitIcon from "@mui/icons-material/HorizontalSplit";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import dayjs from "dayjs";
import {
  DateIcon1,
  NumberIcon,
  TypeIcon,
} from "../../../../../../assets/customicons/customIcons";
import {
  StatusListForNonFilter,
  TicketTypeLabel,
} from "../../../../../../assets/corpConstants";
import ViewImageComp from "../../comps/viewImageComp";

const ViewTicketDetailsPreemployment = ({ data }) => {
  console.log({ data });
  return (
    <Fragment>
      <Box>
        <Card variant="outlined">
          <CardContent>
            <Grid container spacing={2} rowSpacing={3}>
              <Grid item lg={3}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <NumberIcon fontSize="10" />
                    <Typography sx={{ fontSize: 10 }}>Number</Typography>
                  </Stack>
                  <Typography sx={{ fontSize: 14 }}>{data.ticketId}</Typography>
                </Stack>
              </Grid>

              <Grid item lg={3}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <TypeIcon fontSize="10" />
                    <Typography sx={{ fontSize: 10 }}>Ticket Type</Typography>
                  </Stack>
                  <Typography sx={{ fontSize: 14 }}>
                    {TicketTypeLabel[data.ticketType] || "n/a"}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item lg={3}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <BackHandIcon fontSize="10" />
                    <Typography sx={{ fontSize: 10 }}>Raised By</Typography>
                  </Stack>
                  <Typography sx={{ fontSize: 14 }}>
                    {data.raisedBy || "n/a"}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item lg={3}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <DateIcon1 fontSize="10" />
                    <Typography sx={{ fontSize: 10 }}>Ticket Date</Typography>
                  </Stack>
                  <Typography sx={{ fontSize: 14 }}>
                    {data.date ? dayjs(data.date).format("LL") : ""}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item lg={3}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <SplitscreenIcon fontSize="10" />
                    <Typography sx={{ fontSize: 10 }}>Ticket Status</Typography>
                  </Stack>
                  <Typography sx={{ fontSize: 14 }}>
                    {StatusListForNonFilter.find(
                      (value) => value.value === data.status
                    )?.label || "n/a"}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item lg={3}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <SplitscreenIcon fontSize="10" />
                    <Typography sx={{ fontSize: 10 }}>Name</Typography>
                  </Stack>
                  <Typography sx={{ fontSize: 14 }}>
                    {data.ticketInfo.name || "n/a"}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item lg={3}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <SplitscreenIcon fontSize="10" />
                    <Typography sx={{ fontSize: 10 }}>Date</Typography>
                  </Stack>
                  <Typography sx={{ fontSize: 14 }}>
                    {data.ticketInfo?.date
                      ? dayjs(data.ticketInfo?.date).format("LL")
                      : ""}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item lg={3}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <SplitscreenIcon fontSize="10" />
                    <Typography sx={{ fontSize: 10 }}>Test type</Typography>
                  </Stack>
                  <Typography sx={{ fontSize: 14 }}>
                    {data.ticketInfo?.testType || "n/a"}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item lg={3}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <SplitscreenIcon fontSize="10" />
                    <Typography sx={{ fontSize: 10 }}>Department</Typography>
                  </Stack>
                  <Typography sx={{ fontSize: 14 }}>
                    {data.ticketInfo?.department || "n/a"}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item lg={3}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <SplitscreenIcon fontSize="10" />
                    <Typography sx={{ fontSize: 10 }}>
                      Employee/Temporary id
                    </Typography>
                  </Stack>
                  <Typography sx={{ fontSize: 14 }}>
                    {data.ticketInfo?.empId || "n/a"}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item lg={3}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <SplitscreenIcon fontSize="10" />
                    <Typography sx={{ fontSize: 10 }}>Place</Typography>
                  </Stack>
                  <Typography sx={{ fontSize: 14 }}>
                    {data.ticketInfo?.address || "n/a"}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item lg={3}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <SplitscreenIcon fontSize="10" />
                    <Typography sx={{ fontSize: 10 }}>
                      Employee Contact No.
                    </Typography>
                  </Stack>
                  <Typography sx={{ fontSize: 14 }}>
                    {data.ticketInfo?.mobile || "n/a"}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item lg={3}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <SplitscreenIcon fontSize="10" />
                    <Typography sx={{ fontSize: 10 }}>
                      HR Contact No.
                    </Typography>
                  </Stack>
                  <Typography sx={{ fontSize: 14 }}>
                    {data.ticketInfo?.hrmobile || "n/a"}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item lg={3}>
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <SplitscreenIcon fontSize="10" />
                    <Typography sx={{ fontSize: 10 }}>Package</Typography>
                  </Stack>
                  <Typography sx={{ fontSize: 14 }}>
                    {data.ticketInfo?.package || "n/a"}
                  </Typography>
                </Stack>
              </Grid>

              {data?.attachmentDetails &&
                data?.attachmentDetails.length > 0 && (
                  <ViewImageComp imageList={data?.attachmentDetails} />
                )}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Fragment>
  );
};

export default ViewTicketDetailsPreemployment;
