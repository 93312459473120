import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Typography } from "@mui/material";
import { useState } from "react";
import DiagnosisIndex from "../diagnosis/diagnosisIndex";
import Index from "../patientInfo";
import ReferADoctorIndex from "../referaDoctor/referADoctorIndex";
import ReportsIndex from "../reports/reportsIndex";
import MedicationIndex from "../rx/medicationIndex";

const CurrentCaseTabs = ({
  tabvalue,
  setTabValue,
  appointmentId,
  caseId,
  patient,
  pDetails,
  patientId,
}) => {
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <TabContext value={value}>
        <Box>
          <TabList
            centered
            onChange={handleChange}
            sx={{
              px: 3,
              width: "100%",
              border: "none",
              ".Mui-selected": {
                backgroundColor: "white !important",
                borderRadius: "5px",
                fontWeight: "500",
                height: 2,
                "& p": {
                  color: "#127DDD !important",
                },
              },
              ".MuiTabs-indicator": {
                height: 5,
                background: "#127DDD !important",
                borderRadius: "10px",
              },
            }}
          >
            <Tab
              label={
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: ".7rem",
                    fontWeight: 600,
                  }}
                >
                  Patient Info
                </Typography>
              }
              value="1"
              onClick={(e) => {
                setTabValue(!tabvalue);
              }}
            />
            <Tab
              label={
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: ".7rem",
                    fontWeight: 600,
                  }}
                >
                  Reports
                </Typography>
              }
              value="2"
              onClick={(e) => {
                setTabValue(!tabvalue);
              }}
            />
            <Tab
              label={
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: ".7rem",
                    fontWeight: 600,
                  }}
                >
                  Diagnosis/Tests
                </Typography>
              }
              value="3"
              onClick={(e) => {
                setTabValue(!tabvalue);
              }}
            />
            <Tab
              label={
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: ".7rem",
                    fontWeight: 600,
                  }}
                >
                  Rx
                </Typography>
              }
              value="4"
              onClick={(e) => {
                setTabValue(!tabvalue);
              }}
            />
            <Tab
              label={
                <Typography
                  variant="body1"
                  sx={{ fontSize: ".7rem", fontWeight: 600 }}
                >
                  Refer a Doctor
                </Typography>
              }
              value="5"
              onClick={(e) => {
                setTabValue(!tabvalue);
              }}
            />
          </TabList>
        </Box>

        <Box>
          <TabPanel value="1" sx={{ px: 0, py: 1 }}>
            <Box
              sx={{
                border: "1px solid black",
                borderRadius: "25px",
                px: 2,
                py: 2,
                height: "60vh",
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <Index
                patientDetails={pDetails}
                patient={patient}
                appointmentId={appointmentId}
                caseId={caseId}
              />
            </Box>
          </TabPanel>

          <TabPanel value="2" sx={{ px: 0, py: 1 }}>
            <Box
              sx={{
                border: "1px solid black",
                borderRadius: "25px",
                px: 2,
                py: 2,
                height: "60vh",
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <ReportsIndex
                patientDetails={pDetails}
                patient={patient}
                appointmentId={appointmentId}
                caseId={caseId}
              />
            </Box>
          </TabPanel>

          <TabPanel value="3" sx={{ px: 0, py: 1 }}>
            <Box
              sx={{
                border: "1px solid black",
                borderRadius: "25px",
                px: 2,
                py: 2,
                height: "60vh",
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <DiagnosisIndex
                patientDetails={pDetails}
                patient={patient}
                appointmentId={appointmentId}
                caseId={caseId}
                patientId={patientId}
              />
            </Box>
          </TabPanel>

          <TabPanel value="4" sx={{ px: 0, py: 1 }}>
            <Box
              sx={{
                border: "1px solid black",
                borderRadius: "25px",
                px: 2,
                py: 2,
                height: "60vh",
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <MedicationIndex
                patientDetails={pDetails}
                patient={patient}
                appointmentId={appointmentId}
                caseId={caseId}
                patientId={patientId}
              />
            </Box>
          </TabPanel>
          <TabPanel value="5" sx={{ px: 0, py: 1 }}>
            <Box
              sx={{
                border: "1px solid black",
                borderRadius: "25px",
                px: 2,
                py: 2,
                height: "60vh",
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <ReferADoctorIndex
                appointmentId={appointmentId}
                caseId={caseId}
                patientId={patientId}
              />
            </Box>
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
};

export default CurrentCaseTabs;
