import { Fragment } from "react";
import CustomSelect from "../../../../../global/components/customSelect";

const statusList = [
  { label: "ALL", value: "ALL" },
  { label: "COMPLETED", value: "COMPLETED" },
  { label: "PENDING", value: "PENDING" },
];

const StatusFilter = ({ selectStatus, setSelectStatus }) => {
  return (
    <Fragment>
      <CustomSelect
        value={selectStatus}
        setvalue={setSelectStatus}
        options={statusList}
        label="Select Status"
      />
    </Fragment>
  );
};

export default StatusFilter;
