import { useTheme } from "@emotion/react";
import { Box, Button, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { Fragment } from "react";
import aboutSectionImg from "../assets/aboutSectionImg.png";
import aboutUsTitle from "../assets/aboutUsTitle.png";
import dashboard2 from "../assets/dashboard2.png";
import ContactUsDialog from "./subComps/contactUsDialog";

const AboutUs = ({ scrollToSection }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Fragment>
      <Box
        sx={{
          background:
            "linear-gradient(to bottom, rgba(52, 63, 208, 1) 30%, rgba(98, 109, 255, 1) 100%)",
          //height: isSmallScreen || isExtraSmallScreen ? null : "38vh",
          //py: isSmallScreen || isExtraSmallScreen ? "20px" : 4,
          py: 6,
          px: 2,
        }}>
        <Grid container spacing={2} display="flex" justifyContent="center">
          <Grid item lg={12}>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "32px",
                fontWeight: 500,
                lineHeight: "37.5px",
                color: "#FFF",
              }}>
              Health isn't a destination; it's a way of life
            </Typography>
          </Grid>
          <Grid item lg={12}>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "400",
                lineHeight: "39.6px",
                color: "#FFF",
              }}>
              At UNO.care, we strive to ignite a healthcare revolution fueled by cutting-edge
              technology and forward-thinking innovation.
            </Typography>
          </Grid>
          <Grid item lg={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <ContactUsDialog buttonSource="Click For Demo" />
            </Box>
          </Grid>
        </Grid>
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <img src={aboutUsTitle} alt="title" style={{ height: 30 }} />
        </Box> */}
      </Box>
    </Fragment>
  );
};

export default AboutUs;
