import { Fragment } from "react";
import DigitalMarketingMain from "../components/digitalMarketing/digitalMarketingMain";
import DigitalMarketingHeader from "../global/headers/digitalMarketingHeader";
import DigitalMarketingFooter from "../global/footers/digitalMarketingFooter";

const DigitalMarketingIndex = () => {
  return (
    <Fragment>
      <DigitalMarketingHeader />
      <DigitalMarketingMain />
      <DigitalMarketingFooter />
    </Fragment>
  );
};
export default DigitalMarketingIndex;
