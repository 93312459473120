import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import React, { Fragment } from "react";
import docInBannerMobile from "../../../../../assets/images/docInBannerMobile.png";

import heartIcon from "../../../../../assets/images/heartIcon.png";
import boneIcon from "../../../../../assets/images/boneIcon.png";
import eyeIcon from "../../../../../assets/images/eyeIcon.png";

const MobileBanner = () => {
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} lg={12} sx={{ position: "relative" }}>
          <Box>
            <Box
              component="img"
              src={docInBannerMobile}
              style={{ height: "450px", objectFit: "contain" }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          lg={4}
          sx={{ position: "relative", marginLeft: "-220px", marginTop: "70px" }}>
          <Box
            sx={{
              // height: "20px",
              width: "220px",
              backgroundColor: "#FAFAFA",
              borderTopLeftRadius: "10px",
              padding: "15px",
            }}>
            <Grid container>
              <Grid item xs={12} lg={12}>
                <Typography
                  sx={{
                    color: "#404040",
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "normal",
                  }}>
                  Specialities Available
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography
                  sx={{
                    color: "#8A8A8A",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}>
                  Select Specialities
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Box sx={{ display: "flex", marginBlock: "5px" }}>
                  <Box
                    sx={{
                      height: "50px",
                      width: "50px",
                      backgroundColor: "#CEE8FF",
                      borderRadius: "10px",
                    }}>
                    <Box
                      component="img"
                      src={heartIcon}
                      style={{ height: "30px", width: "24px", margin: "10px" }}
                    />
                  </Box>
                  <Box sx={{ marginLeft: "10px" }}>
                    <Typography
                      sx={{
                        color: "#404040",
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "normal",
                        marginTop: "5px",
                      }}>
                      Cardiologist
                    </Typography>
                    <Typography
                      sx={{
                        color: "#8A8A8A",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }}>
                      5 Doctors Available
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Box sx={{ display: "flex", marginBlock: "5px" }}>
                  <Box
                    sx={{
                      height: "50px",
                      width: "50px",
                      backgroundColor: "#CEE8FF",
                      borderRadius: "10px",
                    }}>
                    <Box
                      component="img"
                      src={boneIcon}
                      style={{ height: "30px", width: "27px", margin: "10px" }}
                    />
                  </Box>
                  <Box sx={{ marginLeft: "10px" }}>
                    <Typography
                      sx={{
                        color: "#404040",
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "normal",
                        marginTop: "5px",
                      }}>
                      Orthopedist
                    </Typography>
                    <Typography
                      sx={{
                        color: "#8A8A8A",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }}>
                      5 Doctors Available
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Box sx={{ display: "flex", marginBlock: "5px" }}>
                  <Box
                    sx={{
                      height: "50px",
                      width: "50px",
                      backgroundColor: "#CEE8FF",
                      borderRadius: "10px",
                    }}>
                    <Box
                      component="img"
                      src={eyeIcon}
                      style={{ height: "30px", width: "27px", margin: "10px" }}
                    />
                  </Box>
                  <Box sx={{ marginLeft: "10px" }}>
                    <Typography
                      sx={{
                        color: "#404040",
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "normal",
                        marginTop: "5px",
                      }}>
                      ophthalmologist
                    </Typography>
                    <Typography
                      sx={{
                        color: "#8A8A8A",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }}>
                      5 Doctors Available
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={12} textAlign="center">
                <Button variant="outlined" sx={{ borderRadius: "10px", marginBlock: "10px" }}>
                  <Typography
                    sx={{
                      color: "#127DDD",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }}>
                    Explore All
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default MobileBanner;
