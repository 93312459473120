import { Fragment } from "react";
import TicketingTabs from "./comps/ticketingTabs";
import MainPageLayoutWithBackTitleLeft from "../../../global/templates/mainPageLayoutWithBackTitleLeft";

const TicketingSystemMain = () => {
  return (
    <Fragment>
      <MainPageLayoutWithBackTitleLeft title="Tickets">
        <TicketingTabs />
      </MainPageLayoutWithBackTitleLeft>
    </Fragment>
  );
};

export default TicketingSystemMain;
