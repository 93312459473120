import { Box, Button } from "@mui/material";
import { Fragment, useState } from "react";
import { ZoomRoom_Params } from "../../../assets/constantsFile";
import SignalStrengthWiFi from "../../../common/signalStrength/signalStrengthWiFi";
import { ReactInternetSpeedMeter } from "react-internet-meter";
import "react-internet-meter/dist/index.css";
import VideoMain from "../../../videoModule/videoMain";

const VideoSection = ({ patientDetails }) => {
  const [wifiSpeed, setwifiSpeed] = useState();
  let zoomURL = patientDetails.videoURL + ZoomRoom_Params;

  const [toggleCaller, setToggleCaller] = useState(false);

  return (
    <Box sx={{ backgroundColor: "#fff", borderRadius: 2 }}>
      {/* <Box style={{ position: "fixed", zIndex: 999 }}>
        {toggleCaller ? (
          <Button
            onClick={() => setToggleCaller(false)}
            style={{ color: "#fff" }}
          >
            100MS
          </Button>
        ) : (
          <Button
            onClick={() => setToggleCaller(true)}
            style={{ color: "#fff" }}
          >
            Whereby
          </Button>
        )}
      </Box> */}
      <Box sx={{ height: "78", background: "rgba(0, 23, 63, 0.76)" }}>
        {toggleCaller ? (
          <whereby-embed
            style={{ height: "calc(100vh - 116px)" }}
            minimal
            room={zoomURL}
          ></whereby-embed>
        ) : (
          <VideoMain
            name={patientDetails.doctorName}
            token={patientDetails.guestURLToken}
          />
        )}

        <Box sx={{ position: "absolute", bottom: 34, right: "47vw" }}>
          <ReactInternetSpeedMeter
            txtSubHeading="Internet is too slow"
            outputType="empty"
            customClassName={null}
            txtMainHeading="Opps..."
            pingInterval={3000} // milliseconds
            thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
            threshold={8}
            imageUrl="https://storage-echikitsalaya.s3.ap-south-1.amazonaws.com/test/test+doctor/young-doctor-16088825.jpg"
            downloadSize="48198" //bytes
            callbackFunctionOnNetworkDown={(speed) =>
              console.log(`Internet speed is down ${speed}`)
            }
            callbackFunctionOnNetworkTest={(speed) => setwifiSpeed(speed)}
          />
          <SignalStrengthWiFi wifiSpeed={wifiSpeed} />
        </Box>
      </Box>
    </Box>
  );
};

export default VideoSection;
