import { Box, Container, Grid, useMediaQuery } from "@mui/material";
import React, { useRef } from "react";
import LeftBanner from "./components/banner/LeftBanner";
import RightBanner from "./components/banner/RightBanner";
import AboutUs from "./modules/aboutUS/aboutUs";
import LandingService from "./modules/services/landingService";
import LandingFeatures from "./modules/features/landingFeatures";
import LandingAppointment from "./modules/appointment/landingAppointment";
import LandingTestimonial from "./modules/testimonials/landingTestimonial";
import OurClients from "./modules/ourClients/ourClients";
import WhyUs from "./modules/whyUs/whyUs";
import Locations from "./modules/locations/locations";
import ClientsTestimonials from "./modules/clientsTestimonials/clientsTestimonials";
import Team from "./modules/team/team";
import Counter from "./modules/counter/counter";

const CorpLandingPageMain = ({ topMarginValue = 13 }) => {
  const contactRef = useRef(null);

  // Function to scroll to the Contact section
  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const isLargeScreen = useMediaQuery("(min-width: 992px)");
  const marginTopValue = isLargeScreen ? topMarginValue : null;
  return (
    <Container maxWidth={false} disableGutters>
      <Grid container rowSpacing={2}>
        <Grid item xs={12} lg={6} sx={{ marginTop: marginTopValue }}>
          <LeftBanner scrollToContact={scrollToContact} />
        </Grid>
        <Grid item xs={12} lg={6} sx={{ marginTop: marginTopValue }}>
          <RightBanner />
        </Grid>
        {/* <Grid item xs={12} lg={12}>
          <Counter />
        </Grid> */}
        <Grid item xs={12} lg={12}>
          <AboutUs />
        </Grid>

        <Grid item xs={12} lg={12}>
          <LandingService />
        </Grid>
        <Grid item xs={12} lg={12}>
          <OurClients />
        </Grid>

        <Grid item xs={12} lg={12}>
          <WhyUs />
          {/* <LandingFeatures /> */}
        </Grid>

        <Grid item xs={12} lg={12}>
          <Locations />
        </Grid>

        <Grid item xs={12} lg={12}>
          <Box ref={contactRef}>
            <LandingAppointment />
          </Box>
        </Grid>

        <Grid item xs={12} lg={12}>
          <ClientsTestimonials />
        </Grid>
        <Grid item xs={12} lg={12}>
          <Team />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CorpLandingPageMain;
