import { Box, Button, Grid, Typography } from "@mui/material";
import InsuranceForm from "./comps/insuranceForm";
import CustomTitles from "../../../global/titles/customTitles";

const GroupInsuranceMain = () => {
  return (
    <Box sx={{ py: 2 }}>
      <Grid container spacing={1}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="Group Insurance" />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <InsuranceForm />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} textAlign="center">
          <Button variant="contained" sx={{ width: 200 }}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GroupInsuranceMain;
