import RootTemplate from "../../global/templates/rootTemplate";
import FAPhotoGalleryMain from "../../modules/firstAid/photoGallery/faPhotoGalleryMain";

const FAPhotoGalleryIndex = () => {
  return (
    <RootTemplate>
      <FAPhotoGalleryMain />
    </RootTemplate>
  );
};

export default FAPhotoGalleryIndex;
