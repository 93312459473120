import { Avatar, Box, Grid, Stack } from "@mui/material";
import { Fragment } from "react";
import {
  CustomTypographyHeading,
  CustomTypographyParagraph,
  CustomTypographyParagraphClient,
} from "./customTypography";
import ChatIcon from "@mui/icons-material/Chat";

const ChatArea = ({ chatStack }) => {
  return (
    <Fragment>
      {chatStack?.map((val, index) => (
        <Grid item lg={12} key={index}>
          <Grid container spacing={2}>
            {val?.client?.chat && (
              <Fragment>
                <Grid item lg={12} display="flex" justifyContent="flex-end">
                  <Box
                    sx={{
                      background: "#127DDD",
                      py: 2,
                      px: 3,
                      maxWidth: "100%",
                      borderRadius: 4,
                      borderBottomRightRadius: 0,
                      textOverflow: "ellipsis",
                    }}>
                    <CustomTypographyParagraphClient>
                      {val?.client?.chat}
                    </CustomTypographyParagraphClient>
                  </Box>
                </Grid>
                <Grid item lg={12} display="flex" justifyContent="flex-end">
                  <Stack direction="row" spacing={1} display="flex" alignItems="center">
                    <Avatar
                      src="/broken-image.jpg"
                      sx={{ height: 20, width: 20, background: "#127DDD", fontSize: 12 }}
                    />

                    <CustomTypographyHeading>You</CustomTypographyHeading>
                  </Stack>
                </Grid>
              </Fragment>
            )}
            {val?.bot?.chat && (
              <Fragment>
                <Grid item lg={12}>
                  <Stack direction="row" spacing={1} display="flex" alignItems="center">
                    <ChatIcon fontSize="8" color="primary" />
                    <CustomTypographyHeading>UnoTalk</CustomTypographyHeading>
                  </Stack>
                </Grid>
                <Grid item lg={12}>
                  <Box
                    sx={{
                      background: "rgb(255, 255, 255)",
                      py: 2,
                      px: 3,
                      maxWidth: "100%",
                      borderRadius: 4,
                      borderTopLeftRadius: 0,
                    }}>
                    {val?.bot?.chat}
                  </Box>
                </Grid>
              </Fragment>
            )}
          </Grid>
        </Grid>
      ))}
    </Fragment>
  );
};

export default ChatArea;
