import { Box, Grid, Stack, Typography } from "@mui/material";

const TableHeader = () => {
  return (
    <Box sx={{ mt: 1 }}>
      <Grid container display="flex" justifyContent="center">
        <Grid item lg={5}>
          {/* <Typography sx={{ fontWeight: 700 }}>Name</Typography> */}
        </Grid>
        <Grid item lg={4}>
          <Stack direction="row" spacing={10}>
            <Typography sx={{ fontWeight: 700 }}>View PDF</Typography>
            <Typography sx={{ fontWeight: 700 }}>Date</Typography>
          </Stack>
        </Grid>
        {/* <Grid item lg={3}>
          <Typography sx={{ fontWeight: 700 }}>Created Date</Typography>
        </Grid> */}
        <Grid item lg={3}></Grid>
      </Grid>
    </Box>
  );
};

export default TableHeader;
