import { Avatar, Box, Button, Card, CardActions, CardContent, Grid } from "@mui/material";
import { CustomHeadingLeft1 } from "../../global/headings/customHeadings";
import HeartBrokenRoundedIcon from "@mui/icons-material/HeartBrokenRounded";
import { CustomParagraph1 } from "../../global/paragraphs/customParagraphs";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
import { useState } from "react";
import ButtonReadMore from "../../components/subComps/ButtonReadMore";

import heartIcon from "../../assets/images/heartIcon.png";
import eyeIcon from "../../assets/images/eyeIcon.png";
import boneIcon from "../../assets/images/boneIcon.png";
import bacteriaIcon from "../../assets/images/bacteriaIcon.png";
import femaleSign from "../../assets/images/femaleSign.png";
import dentistIcon from "../../assets/images/dentistIcon.png";
import sethescopeIcon from "../../assets/images/sethescopeIcon.png";
import surgeryIcon from "../../assets/images/surgeryIcon.png";

const CustomCard = ({ data }) => {
  const [showHoverEffect, setShowHoverEffect] = useState(true);

  return (
    <Card
      onPointerEnter={() => setShowHoverEffect(false)}
      variant="outlined"
      onPointerLeave={() => setShowHoverEffect(true)}
      sx={{
        backgroundColor: "rgb(239, 245, 255)",
        border: "none",
        minHeight: 300,
        borderRadius: "10px",
        transition: "box-shadow 0.3s ease", // Add a smooth transition for the shadow
        "&:hover": {
          boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.1)", // Add shadow on hover
          transform: "translateY(-5px)", // Elevate the card on hover
        },
      }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <Box
              sx={{
                height: 70,
                width: 70,
                bgcolor: "#fff",
                borderRadius: 35,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Box component={"img"} src={data?.src} style={{ height: "40px" }} />
            </Box>
          </Grid>

          <Grid item lg={12}>
            <CustomHeadingLeft1>{data?.title}</CustomHeadingLeft1>
          </Grid>

          <Grid item lg={12} sx={{ height: 90 }}>
            <CustomParagraph1>{data?.para}</CustomParagraph1>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <ButtonReadMore />
      </CardActions>
    </Card>
  );
};
const ShowCards = () => {
  const cardData = [
    {
      src: heartIcon,
      title: "Cardiology",
      para: "Trust our cardiology experts for heart health solutions and compassionate care. Your heart is in good hands with our skilled cardiologists, ensuring a healthier future.",
    },
    {
      src: eyeIcon,
      title: "Ophthalmologist",
      para: "Our ophthalmologists offer precise eye care, preserving your vision with precision. Count on our eye specialists for comprehensive vision care and advanced treatments.",
    },
    {
      src: boneIcon,
      title: "Orthopedist ",
      para: "Get back to a pain-free life with our orthopedic care, specializing in joint and bone health. Our orthopedic team restores mobility and function through advanced treatments and surgery.",
    },
    {
      src: sethescopeIcon,
      title: "General Physician",
      para: "Experience holistic healthcare with our general physicians, providing thorough medical guidance. Trust our general practitioners for personalized health solutions and well-rounded care.",
    },
    {
      src: femaleSign,
      title: "Gynaecologist ",
      para: "Empowering women's health, our gynecologists offer expert care with sensitivity. Our gynecological services prioritize women's well-being and reproductive health.",
    },
    {
      src: surgeryIcon,
      title: "General Surgeon",
      para: "Skilled in surgical excellence, our general surgeons ensure safe and effective procedures. From routine surgeries to complex cases, our surgeons prioritize your well-being.",
    },
  ];
  return (
    <Grid container spacing={2} marginBlock={"20px"}>
      {cardData.map((item, index) => (
        <Grid item lg={4} key={index}>
          <CustomCard data={item} />
        </Grid>
      ))}
    </Grid>
  );
};
export default ShowCards;
