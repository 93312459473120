import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../assets/constantsFile";
import { getData } from "../../services/api/getApi";
import CurrentCaseTabs from "./components/currentCaseTabs";
import VitalsBar from "./components/vitalsBar";

const CurrentCaseIndex = ({ patient, patientDetails }) => {
  const patientId = patientDetails.patientId;
  const appointmentId = patientDetails.appointmentId;
  const caseId = patientDetails.caseId;
  const [tabvalue, setTabValue] = useState(false);

  const [pDetails, setPdetails] = useState();

  const getPDetails = async () => {
    const url =
      BASE_URL +
      "patient/detailed/" +
      patientId +
      "?appointmentId=" +
      appointmentId +
      "&caseId=" +
      caseId;

    const user = await getData(url, "");

    if (user.error) {
    } else {
      const data = user.data;
      setPdetails(data);
      console.log({ setPdetails: data });
    }
  };

  useEffect(() => {
    getPDetails();
  }, [patientDetails, tabvalue]);

  return (
    <Box>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <VitalsBar
            heartRate={patientDetails?.vitals?.heartRate}
            temperature={patientDetails?.vitals?.temperature}
            height={patientDetails?.vitals?.height}
            weight={patientDetails?.vitals?.weight}
            bloodPressure={patientDetails?.vitals?.bp}
            oxygen={patientDetails?.vitals?.spo2}
            sugar={patientDetails?.vitals?.sugar}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CurrentCaseTabs
            tabvalue={tabvalue}
            setTabValue={setTabValue}
            patient={patient}
            pDetails={pDetails}
            patientId={patientId}
            appointmentId={appointmentId}
            caseId={caseId}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CurrentCaseIndex;
