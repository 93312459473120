import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import trashIcon2 from "../../../../../assets/images/trashIcon2.png";
import editIcon2 from "../../../../../assets/images/editIcon2.png";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import plusIcon from "../../../../../assets/images/plusIcon.png";
import copyRx from "../../../../../assets/images/copyRx.png";
import commentIcon from "../../../../../assets/images/commentIcon.png";
import CloseIcon from "@mui/icons-material/Close";
// import { generateDoseList, generateDurationList } from "@/global/utils/utils";
import CommentModal from "./CommentModal";
import { getData } from "../../../../../../services/api/getApi";
import { deleteData, saveData } from "../../../../../../services/api/postApi";
import { BASE_URL } from "../../../../../../assets/constantsFile";
import { generateDoseList, generateDurationList } from "../../../../../../assets/utils";

const Rx = ({ patients }) => {
  console.log({ CONTEXT: patients });

  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [openNotice, setOpenNotice] = useState(false);

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const [openComment, setOpenComment] = useState(false);
  const handleOpenComment = () => {
    setOpenComment(true);
  };
  const handleCloseComment = () => {
    setOpenComment(false);
  };

  const [medicineIndex, setMedicineIndex] = useState(false);
  const [openDeleteModalMedicine, setOpenDeleteModalMedicine] = useState(false);
  const handleOpenDeleteModalMedicine = (index) => {
    setOpenDeleteModalMedicine(true);
    setMedicineIndex(index);
  };

  const handleCloseDeleteModalMedicine = () => {
    setOpenDeleteModalMedicine(false);
  };

  const appointmentId = patients?.appointmentId;
  const caseId = patients?.caseId;
  const patientId = patients?.patientInfoVMV2?.patientId;
  const [pDetails, setPDetails] = useState("");

  const [editRX, setEditRX] = useState("");
  const [medicineName, setMedicineName] = useState("");
  const [dosage, setDosage] = useState("");
  const [doseList, setDoseList] = useState([]);
  const [duration, setDuration] = useState("");
  const [durationList, setDurationList] = useState([]);
  const [frequency, setFrequency] = useState("");
  const [frequencyString, setFrequencyString] = useState("");
  const [selectedButton, setSelectedButton] = useState("");
  const [timesADay, setTimesADay] = useState("");
  const [instructions, setInstructions] = useState("");
  const [medicineListVM, setMedicineListVM] = useState([]);
  const [medicineList, setMedicineList] = useState([]);
  const [state, setState] = useState();
  const [rxComment, setRxComment] = useState("");

  const [isMorning, setIsMorning] = useState(false);
  const [isAfterNoon, setIsAfterNoon] = useState(false);
  const [isNight, setIsNight] = useState(false);

  const getPDetails = async () => {
    if (patientId && appointmentId && caseId) {
      const url =
        BASE_URL +
        "patient/detailed/" +
        patientId +
        "?appointmentId=" +
        appointmentId +
        "&caseId=" +
        caseId;

      const data = await getData(url);
      if (data && data.data) {
        console.log("SUCCESS GET", data.data);
        const data1 = data.data;
        const appointments = data1.appointments;
        const prescriptionVM = appointments ? appointments[0].prescriptionVM : null;
        const medicines =
          prescriptionVM && prescriptionVM.medicines ? prescriptionVM.medicines : [];
        const rxComment =
          prescriptionVM && prescriptionVM.rxComment ? prescriptionVM.rxComment : null;

        setRxComment(rxComment);
        setPDetails(data1);
        setMedicineListVM(medicines);
      }
    }
  };
  console.log({ MEDICINEVM: medicineListVM });
  console.log({ EDITRX: editRX });

  useEffect(() => {
    getPDetails();
  }, [patients]);

  const fetchMedicinesHandler = useCallback(async (medName) => {
    if (medName) {
      const url =
        BASE_URL +
        "doctor/medicineSearchPaginated/" +
        medName?.toUpperCase() +
        "?page=0&size=10&sort=name";

      const medicine = await getData(url);
      if (medicine && medicine.error) {
        console.log("error");
      } else if (medicine && medicine.data) {
        const data = medicine.data;
        const content = data.content;
        if (content && content.length > 0) {
          setMedicineList(
            content.map((item, index) => ({
              id: item?.id,
              title: item?.name,
              isActive: item?.isActive,
              name: item?.name,
              savedByDoctor: item?.savedByDoctor,
            }))
          );
        } else {
          setMedicineList([
            {
              id: "temp",
              name: medName ? medName.toUpperCase() : "",
              title: medName ? medName.toUpperCase() : "",
              savedByDoctor: null,
            },
          ]);
        }
      }
    }
  }, []);

  console.log({ MEDICINENAME: medicineName });
  console.log({ DOAGE: dosage });
  console.log({ DURATION: duration });
  console.log({ DOAGE: dosage });
  console.log({ INSTRUCTION: instructions });
  console.log({ TIMESADAY: timesADay });
  console.log({ SELECTEDBUTTON: selectedButton });
  console.log({ FREQUENCY: frequency });

  console.log({ IsMORNING: isMorning });
  console.log({ ISAFTERNOON: isAfterNoon });
  console.log({ ISNIGHT: isNight });

  const clearField = () => {
    setMedicineName("");
    setDosage("");
    setFrequency("");
    setDuration("");
    setFrequencyString("");
    setSelectedButton("");
    setTimesADay("");
    setInstructions("");
    setInstructions("");
    setIsMorning("");
    setIsAfterNoon("");
    setIsNight("");
  };

  const savePrescription = async () => {
    const url = BASE_URL + "doctor/prescription";

    const appointments = pDetails?.appointments;
    const prescriptionVM = appointments ? appointments[0].prescriptionVM : null;
    const medicines = prescriptionVM && prescriptionVM.medicines ? prescriptionVM.medicines : [];

    const data = {
      medicineName: medicineName,
      dosage: dosage,
      durationString: duration,
      frequencyString: frequencyString,
      comment: instructions,
      m: isMorning,
      a: isAfterNoon,
      n: isNight,
      timing:
        (isMorning ? "Morning" : "") +
        (isMorning && (isAfterNoon || isNight) ? "-" : "") +
        (isAfterNoon ? "Afternoon" : "") +
        (isAfterNoon && isNight ? "-" : "") +
        (isNight ? "Night" : ""),
    };

    const newMedicines = [...medicines, data];

    const Obj = {
      docId: patients?.doctorVM?.docId,
      patientId: patients?.patientInfoVMV2?.patientId,
      medicines: newMedicines,
      appointmentId: patients?.appointmentId,
      rxComment: rxComment,
    };

    console.log({ MYOBJECT: Obj });

    if (medicineName) {
      const response = await saveData(url, Obj);
      if (response && response.data) {
        console.log("SUCCESS POST", response.data);
        setSeverity("success");
        setMessage("Saved Successfully");
        setOpenNotice(true);
        clearField();
        getPDetails();
        handleClose();
      } else if (response && response.error) {
        console.log("ERROR POST", response.error);
        setSeverity("error");
        setMessage("Error Occured");
        setOpenNotice(true);
      }
    }
  };

  const deleteMedicines = async (index) => {
    const appointments = pDetails?.appointments;
    const prescriptionVM = appointments ? appointments[0].prescriptionVM : null;
    const prescriptionId = prescriptionVM ? prescriptionVM.prescriptionId : null;
    const medicines = prescriptionVM && prescriptionVM.medicines ? prescriptionVM.medicines : [];

    const newMedicines = medicines.splice(index, 1);

    const prescription = {
      docId: patients?.doctorVM?.docId,
      patientId: patientId,
      appointmentId: appointmentId,
      prescriptionId: prescriptionId,
      medicines: medicines,
      rxComment: rxComment,
    };
    const url = BASE_URL + "doctor/prescription";
    const result = await saveData(url, prescription);

    if (result.error) {
      console.error(result.error);
      setSeverity("error");
      setMessage("SError Occured");
      setOpenNotice(true);
    } else {
      console.log(result.data);
      setMedicineListVM([]);
      getPDetails();
      setSeverity("success");
      setMessage("Deleted Successfully");
      setOpenNotice(true);
      handleCloseDeleteModalMedicine();
    }
  };

  const [editData, setEditData] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  console.log({ EDITDATA: editData });

  useEffect(() => {
    if (editData?.frequencyString === "OD") {
      setSelectedButtonEdit("OD");
    } else if (editData?.frequencyString === "BD") {
      setSelectedButtonEdit("BD");
    } else if (editData?.frequencyString === "TD") {
      setSelectedButtonEdit("TD");
    } else if (editData?.frequencyString === "QD") {
      setSelectedButtonEdit("QD");
    } else if (editData?.frequencyString?.split(" ")[1] === "Times") {
      setSelectedButtonEdit("OTHER");
    }
  }, [openEdit]);

  const [medicineNameEdit, setMedicineNameEdit] = useState(
    editData?.medicineName ? editData?.medicineName : ""
  );
  const [dosageEdit, setDosageEdit] = useState(editData?.dosage ? editData?.dosage : "");
  const [doseListEdit, setDoseListEdit] = useState([]);
  const [durationEdit, setDurationEdit] = useState(
    editData?.durationString ? editData?.durationString : ""
  );
  const [durationListEdit, setDurationListEdit] = useState([]);
  const [frequencyEdit, setFrequencyEdit] = useState(editData.frequency ? editData?.frequency : "");
  const [frequencyStringEdit, setFrequencyStringEdit] = useState(
    editData?.frequencyString ? editData?.frequencyString : ""
  );
  const [selectedButtonEdit, setSelectedButtonEdit] = useState("");
  console.log({ HJJHJHBJK: selectedButton });
  const [timesADayEdit, setTimesADayEdit] = useState("");
  const [instructionsEdit, setInstructionsEdit] = useState(
    editData?.instructions ? editData?.instructions : ""
  );
  const [medicineListEdit, setMedicineListEdit] = useState([]);

  const [isMorningEdit, setIsMorningEdit] = useState(false);
  const [isAfterNoonEdit, setIsAfterNoonEdit] = useState(false);
  const [isNightEdit, setIsNightEdit] = useState(false);

  const applyEditValues = async (data, index) => {
    setMedicineNameEdit(data.medicineName);
    setDosageEdit(data.dosage);
    setFrequencyStringEdit(data.frequencyString);
    setTimesADayEdit(data.frequencyString.split(" ")[0]);
    setIsMorningEdit(data.m);
    setIsAfterNoonEdit(data.a);
    setIsNightEdit(data.n);
    setDurationEdit(data.durationString);
    setInstructionsEdit(data.comment);
  };

  const editPrescriptionHandler = async (
    medicineName,
    dosage,
    duration,
    frequencyString,
    instructions,
    isMorning,
    isAfterNoon,
    isNight,
    patientData,
    index
  ) => {
    const appointments = patientData?.appointments;
    const prescriptionVM = appointments ? appointments[0].prescriptionVM : null;
    const prescriptionId = prescriptionVM ? prescriptionVM.prescriptionId : null;
    const medicines = prescriptionVM && prescriptionVM.medicines ? prescriptionVM.medicines : [];

    const data = {
      medicineName: medicineName,
      dosage: dosage,
      durationString: duration,
      frequencyString: frequencyString,
      comment: instructions,
      m: isMorning,
      a: isAfterNoon,
      n: isNight,
      timing:
        (isMorning ? "Morning" : "") +
        (isMorning && (isAfterNoon || isNight) ? "-" : "") +
        (isAfterNoon ? "Afternoon" : "") +
        (isAfterNoon && isNight ? "-" : "") +
        (isNight ? "Night" : ""),
    };

    medicines[index] = data;
    const newMedicines = medicines;
    //setMedicineListView(newMedicines);

    if (medicineName) {
      const prescription = {
        docId: patients?.doctorVM?.docId,
        patientId: patientId,
        appointmentId: appointmentId,
        prescriptionId: prescriptionId,
        medicines: newMedicines,
        rxComment: rxComment,
      };
      console.log({ "datadatadata:": prescription });

      const url = BASE_URL + "doctor/prescription";
      const response = await saveData(url, prescription);

      if (response && response.data) {
        console.log("SUCCESS POST", response.data);
        setSeverity("success");
        setMessage("Saved Successfully");
        setOpenNotice(true);
        handleCloseEdit();
        getPDetails();
      } else if (response && response.error) {
        console.log("ERROR POST", response.error);
        setSeverity("error");
        setMessage("Error Occured");
        setOpenNotice(true);
      }
    }
  };

  const [openDeleteRxModal, setOpenDeleteRxModal] = useState(false);
  const handleOpenDeleteRxModal = () => {
    setOpenDeleteRxModal(true);
  };

  const handleCloseDeleteRxModal = () => {
    setOpenDeleteRxModal(false);
  };

  const deleteRxComment = async () => {
    const url = BASE_URL + "doctor/appointment/comment/" + appointmentId;
    const result = await deleteData(url);
    if (result && result.error) {
      console.error(result.error);
      setSeverity("error");
      setMessage("Error Occured");
      setOpenNotice(true);
    } else {
      console.log(result.data);
      getPDetails();
      setSeverity("success");
      setMessage("Saved Successfully");
      setOpenNotice(true);
      handleCloseDeleteRxModal();
    }
  };

  return (
    <Fragment>
      <Snackbar open={openNotice} autoHideDuration={1000} onClose={handleCloseNotice}>
        <Alert onClose={handleCloseNotice} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}>
        <IconButton onClick={handleOpen}>
          <Box
            sx={{
              background: "#F1F1F1",
              borderRadius: "25px",
              paddingInline: "20px",
              paddingBlock: "5px",
              marginInline: "5px",
            }}>
            <Box component={"img"} src={plusIcon} width={24} height={24} alt="Icon" />
          </Box>
        </IconButton>
        <IconButton>
          <Box
            sx={{
              background: "#F1F1F1",
              borderRadius: "25px",
              paddingInline: "20px",
              paddingBlock: "5px",
              marginInline: "5px",
            }}>
            <Box component={"img"} src={copyRx} width={24} height={24} alt="Icon" />
          </Box>
        </IconButton>
        <IconButton onClick={handleOpenComment}>
          <Box
            sx={{
              background: "#F1F1F1",
              borderRadius: "25px",
              paddingInline: "20px",
              paddingBlock: "5px",
              marginInline: "5px",
            }}>
            <Box component={"img"} src={commentIcon} width={24} height={24} alt="Icon" />
          </Box>
        </IconButton>
      </Box>

      <CommentModal
        open={openComment}
        handleClose={handleCloseComment}
        setRxComment={setRxComment}
        rxComment={rxComment}
        patients={patients}
        medicineListVM={medicineListVM}
      />
      {rxComment && (
        <Box
          sx={{
            backgroundColor: "#EEEAFF",
            borderRadius: "20px",
            padding: "10px",
            marginBlock: "10px",
          }}>
          <Grid container>
            <Grid item xs={1} lg={1}>
              <Box
                sx={{
                  backgroundColor: "#3300FF",
                  borderRadius: "5px",
                  width: "3px",
                  height: "100%",
                }}></Box>
            </Grid>
            <Grid item xs={8} lg={8}>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "19px",
                  color: "#3300FF",
                }}>
                Comment
              </Typography>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "13px",
                  lineHeight: "19px",
                  color: "#404040",
                }}>
                {rxComment}
              </Typography>
            </Grid>
            <Grid item xs={3} lg={3} sx={{ display: "flex" }}>
              <IconButton onClick={() => handleOpenComment()}>
                <Box component={"img"} src={editIcon2} width={14} height={14} alt="Icon" />
              </IconButton>
              <IconButton onClick={() => handleOpenDeleteRxModal()}>
                <Box component={"img"} src={trashIcon2} width={14} height={14} alt="Icon" />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      )}

      {medicineListVM?.map((med, index) => (
        <Box
          key={index}
          sx={{
            backgroundColor: "#EFF7FF",
            borderRadius: "20px",
            padding: "10px",
            marginBlock: "10px",
          }}>
          <Grid container>
            <Grid item xs={1} lg={1}>
              <Box
                sx={{
                  backgroundColor: "#127DDD",
                  borderRadius: "5px",
                  width: "3px",
                  height: "100%",
                }}></Box>
            </Grid>
            <Grid item xs={8} lg={8}>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "19px",
                  color: "#127DDD",
                }}>
                Rx
              </Typography>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "13px",
                  lineHeight: "19px",
                  color: "#404040",
                }}>
                {med.medicineName}
              </Typography>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "13px",
                      lineHeight: "19px",
                      color: "#404040",
                    }}>
                    {med.dosage}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "13px",
                      lineHeight: "19px",
                      color: "#404040",
                    }}>
                    {med.durationString}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "13px",
                      lineHeight: "19px",
                      color: "#404040",
                    }}>
                    {med.frequencyString}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "13px",
                      lineHeight: "19px",
                      color: "#404040",
                    }}>
                    {med.m === true ? "Morning" : ""}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "13px",
                      lineHeight: "19px",
                      color: "#404040",
                    }}>
                    {med.a === true ? "Afternoon" : ""}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "13px",
                      lineHeight: "19px",
                      color: "#404040",
                    }}>
                    {med.n === true ? "Night" : ""}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} lg={12}>
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "13px",
                    lineHeight: "19px",
                    color: "#404040",
                  }}>
                  {med.comment}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={3} lg={3} sx={{ display: "flex" }}>
              <IconButton
                onClick={() => {
                  handleOpenEdit();
                  applyEditValues(med, index);
                  setOpenEdit(true);
                  setEditData(med);
                  setEditIndex(index);
                }}
                style={{ marginLeft: "20px" }}>
                <Box component={"img"} src={editIcon2} width={14} height={14} alt="Icon" />
              </IconButton>
              <IconButton onClick={() => handleOpenDeleteModalMedicine(index)}>
                <Box component={"img"} src={trashIcon2} width={14} height={14} alt="Icon" />
              </IconButton>
            </Grid>
          </Grid>
          <Modal
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            open={openDeleteModalMedicine}
            onClose={handleCloseDeleteModalMedicine}
            sx={{
              "& .MuiBackdrop-root": {
                backgroundColor: "rgba(187, 187, 187, 0.1)",
              },
              marginTop: "-100px",
            }}>
            <Box
              sx={{
                backgroundColor: "#fff",
                boxShadow: "0px 1px 4px 1px rgba(0, 0, 0, 0.1)",
                borderRadius: "5px",
                padding: "20px",
                width: "350px",
                justifyContent: "center",
              }}>
              <Box display="flex" justifyContent="flex-end">
                <IconButton onClick={handleCloseDeleteModalMedicine}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#000000",
                  marginTop: "-20px",
                  textAlign: "center",
                }}>
                Do you want to delete this Medicine ?
              </Typography>
              <Grid container sx={{ marginTop: "20px" }}>
                <Grid item xs={6} lg={6} textAlign="right">
                  <Button
                    onClick={handleCloseDeleteModalMedicine}
                    variant="outlined"
                    style={{
                      borderRadius: "15px",
                      border: "1px solid",
                      textTransform: "none",
                      marginInline: "10px",
                    }}>
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "13px",
                        lineHeight: "15px",
                        color: "#127DDD",
                      }}>
                      No
                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs={6} lg={6}>
                  <Button
                    onClick={() => {
                      deleteMedicines(medicineIndex);
                      console.log({ DLETEDINDEX: index });
                    }}
                    variant="contained"
                    style={{
                      borderRadius: "15px",
                      textTransform: "none",
                      marginInline: "10px",
                    }}>
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "13px",
                        lineHeight: "15px",
                        color: "#FFFFFF",
                      }}>
                      Yes
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Box>
      ))}

      {/* ________________________________ADD NEW MEDICINE____________________________________________ */}

      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(187, 187, 187, 0.1)",
          },
          marginTop: "-100px",
        }}>
        <Box
          sx={{
            backgroundColor: "#fff",
            boxShadow: "0px 1px 4px 1px rgba(0, 0, 0, 0.1)",
            borderRadius: "5px",
            padding: "20px",
            width: "350px",
            justifyContent: "center",
          }}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid container spacing={1} sx={{ marginTop: "-35px" }}>
            <Grid item xs={12} lg={12}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#000000",
                  marginBottom: "10px",
                }}>
                Medicine
              </Typography>
              <Autocomplete
                fullWidth
                freeSolo
                disableClearable
                selectOnFocus
                size="small"
                options={medicineList?.map((option) => (option?.name ? option?.name : ""))}
                // ListboxProps={{ sx: { fontSize: 11 } }}
                onInputChange={(event, newInputValue) => {
                  setMedicineName(newInputValue);
                  fetchMedicinesHandler(newInputValue.toUpperCase());
                }}
                value={medicineName || ""}
                onChange={(event, value) => setMedicineName(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      background: "#fff",
                      color: "#127DDD",
                      fontWeight: "300",
                      fontSize: "13px",
                      lineHeight: " 15px",
                      "& input::placeholder": {
                        color: "#777777",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: " 15px",
                      },
                    }}
                    variant="outlined"
                    placeholder="Enter Medicine Name"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} lg={6}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#000000",
                  marginBlock: "10px",
                }}>
                Dose/Quantity
              </Typography>
              <Autocomplete
                fullWidth
                freeSolo
                disableClearable
                size="small"
                options={doseList.map((option) => option)}
                onInputChange={(event, newInputValue) => {
                  setDosage(newInputValue);
                  setDoseList(generateDoseList(newInputValue));
                }}
                value={dosage || ""}
                selectOnFocus
                onChange={(event, value) => {
                  setDosage(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      background: "#fff",
                      color: "#127DDD",
                      fontWeight: "300",
                      fontSize: "13px",
                      lineHeight: " 15px",
                      "& input::placeholder": {
                        color: "#777777",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: " 15px",
                      },
                    }}
                    variant="outlined"
                    placeholder="Enter Dose/Quantity"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} lg={6}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#000000",
                  marginBlock: "10px",
                }}>
                Duration
              </Typography>
              <Autocomplete
                fullWidth
                freeSolo
                disableClearable
                size="small"
                options={durationList.map((option) => option)}
                onInputChange={(event, newInputValue) => {
                  setDuration(newInputValue);
                  setDurationList(generateDurationList(newInputValue));
                }}
                value={duration || ""}
                selectOnFocus
                onChange={(event, value) => {
                  setDuration(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      background: "#fff",
                      color: "#127DDD",
                      fontWeight: "300",
                      fontSize: "13px",
                      lineHeight: " 15px",
                      "& input::placeholder": {
                        color: "#777777",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: " 15px",
                      },
                    }}
                    variant="outlined"
                    placeholder="Enter Duration"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#000000",
                  marginBottom: "10px",
                }}>
                Frequency
              </Typography>
              <Grid container sx={{ justifyContent: "space-between", flexWrap: "wrap" }}>
                <Grid item xs={3} lg={3}>
                  <IconButton
                    onClick={() => {
                      setSelectedButton("OD");
                      setFrequencyString("OD");

                      setTimesADay("");
                    }}>
                    <Box
                      sx={{
                        background: selectedButton === "OD" ? "#127DDD" : "#F1F1F1",
                        borderRadius: "25px",
                        paddingInline: "20px",
                        paddingBlock: "5px",
                        marginRight: "5px",
                      }}>
                      <Typography
                        sx={{
                          fontWeight: "400",
                          fontSize: "13px",
                          lineHeight: "15px",
                          color: selectedButton === "OD" ? "#FFFFFF" : "#000000",
                          margin: "3px",
                        }}>
                        OD
                      </Typography>
                    </Box>
                  </IconButton>
                </Grid>
                <Grid item xs={3} lg={3}>
                  <IconButton
                    onClick={() => {
                      setSelectedButton("BD");
                      setFrequencyString("BD");
                      setTimesADay("");
                    }}>
                    <Box
                      sx={{
                        background: selectedButton === "BD" ? "#127DDD" : "#F1F1F1",
                        borderRadius: "25px",
                        paddingInline: "20px",
                        paddingBlock: "5px",
                        marginInline: "5px",
                      }}>
                      <Typography
                        sx={{
                          fontWeight: "400",
                          fontSize: "13px",
                          lineHeight: "15px",
                          color: selectedButton === "BD" ? "#FFFFFF" : "#000000",
                          margin: "3px",
                        }}>
                        BD
                      </Typography>
                    </Box>
                  </IconButton>
                </Grid>
                <Grid item xs={3} lg={3}>
                  <IconButton
                    onClick={() => {
                      setSelectedButton("TD");
                      setFrequencyString("TD");
                      setTimesADay("");
                      setIsAfterNoon("");
                      setIsNight("");
                    }}>
                    <Box
                      sx={{
                        background: selectedButton === "TD" ? "#127DDD" : "#F1F1F1",
                        borderRadius: "25px",
                        paddingInline: "20px",
                        paddingBlock: "5px",
                        marginInline: "5px",
                      }}>
                      <Typography
                        sx={{
                          fontWeight: "400",
                          fontSize: "13px",
                          lineHeight: "15px",
                          color: selectedButton === "TD" ? "#FFFFFF" : "#000000",
                          margin: "3px",
                        }}>
                        TD
                      </Typography>
                    </Box>
                  </IconButton>
                </Grid>
                <Grid item xs={3} lg={3}>
                  <IconButton
                    onClick={() => {
                      setSelectedButton("QD");
                      setFrequencyString("QD");
                      setTimesADay("");
                    }}>
                    <Box
                      sx={{
                        background: selectedButton === "QD" ? "#127DDD" : "#F1F1F1",
                        borderRadius: "25px",
                        paddingInline: "20px",
                        paddingBlock: "5px",
                        marginInline: "5px",
                      }}>
                      <Typography
                        sx={{
                          fontWeight: "400",
                          fontSize: "13px",
                          lineHeight: "15px",
                          color: selectedButton === "QD" ? "#FFFFFF" : "#000000",
                          margin: "3px",
                        }}>
                        QD
                      </Typography>
                    </Box>
                  </IconButton>
                </Grid>
                <Grid item xs={3} lg={3}>
                  <IconButton
                    onClick={() => {
                      setSelectedButton("OTHER");
                    }}>
                    <Box
                      sx={{
                        background: selectedButton === "OTHER" ? "#127DDD" : "#F1F1F1",
                        borderRadius: "25px",
                        paddingInline: "20px",
                        paddingBlock: "5px",
                        marginRight: "5px",
                      }}>
                      <Typography
                        sx={{
                          fontWeight: "400",
                          fontSize: "13px",
                          lineHeight: "15px",
                          color: selectedButton === "OTHER" ? "#FFFFFF" : "#000000",
                          margin: "3px",
                        }}>
                        OTHER
                      </Typography>
                    </Box>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            {(selectedButton === "OD" || selectedButton === "BD") && (
              <Grid container spacing={2}>
                <Grid item xs={4} lg={4}>
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        size="medium"
                        checked={isMorning}
                        onChange={(event) => setIsMorning(event.target.checked)}
                      />
                    }
                    label="Morning"
                  />
                </Grid>
                <Grid item xs={4} lg={4}>
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        size="medium"
                        checked={isAfterNoon}
                        onChange={(event) => setIsAfterNoon(event.target.checked)}
                      />
                    }
                    label="AfterNoon"
                  />
                </Grid>
                <Grid item xs={4} lg={4}>
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        size="medium"
                        checked={isNight}
                        onChange={(event) => setIsNight(event.target.checked)}
                      />
                    }
                    label="Night"
                  />
                </Grid>
              </Grid>
            )}
            {selectedButton === "OTHER" && (
              <Grid container>
                <Grid item xs={2.5} lg={2.5}>
                  <TextField
                    value={timesADay}
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        setTimesADay(e.target.value);
                        setFrequencyString(`${e.target.value} Times a Day`);
                      }
                    }}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      maxLength: 3,
                      height: "20px",
                    }}
                  />
                </Grid>
                <Grid item xs={9.5} lg={9.5}>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "13px",
                      lineHeight: "15px",
                      color: "#000000",
                      marginInline: "10px",
                      marginTop: "10px",
                    }}>
                    Times a Day
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} lg={12}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#000000",
                  marginBottom: "10px",
                }}>
                Instructions
              </Typography>
              <TextField
                multiline
                sx={{
                  background: "#fff",
                  color: "#127DDD",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                  "& input::placeholder": {
                    color: "#777777",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                  },
                }}
                size="small"
                fullWidth
                variant="outlined"
                placeholder="Enter Instructions"
                value={instructions || ""}
                onChange={(e) => setInstructions(e.target.value)}
                inputProps={{
                  style: {
                    height: 70,
                    color: "#777777",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} lg={6} textAlign="right">
              <Button
                onClick={handleClose}
                variant="outlined"
                style={{
                  borderRadius: "15px",
                  border: "1px solid",
                  textTransform: "none",
                  marginInline: "10px",
                }}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "15px",
                    color: "#127DDD",
                  }}>
                  Cancel
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={6} lg={6}>
              <Button
                onClick={() => {
                  savePrescription();
                }}
                variant="contained"
                style={{
                  borderRadius: "15px",
                  textTransform: "none",
                  marginInline: "10px",
                }}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "15px",
                    color: "#FFFFFF",
                  }}>
                  Save
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {/* ___________________________________________EDIT  MEDICINE__________________________________________ */}
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={openEdit}
        onClose={handleCloseEdit}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(187, 187, 187, 0.1)",
          },
          marginTop: "-100px",
        }}>
        <Box
          sx={{
            backgroundColor: "#fff",
            boxShadow: "0px 1px 4px 1px rgba(0, 0, 0, 0.1)",
            borderRadius: "5px",
            padding: "20px",
            width: "350px",
            justifyContent: "center",
          }}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={handleCloseEdit}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid container spacing={1} sx={{ marginTop: "-35px" }}>
            <Grid item xs={12} lg={12}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#000000",
                  marginBottom: "10px",
                }}>
                Medicine
              </Typography>
              <Autocomplete
                fullWidth
                freeSolo
                disableClearable
                selectOnFocus
                size="small"
                options={medicineList?.map((option) => (option?.name ? option?.name : ""))}
                // ListboxProps={{ sx: { fontSize: 11 } }}
                onInputChange={(event, newInputValue) => {
                  setMedicineNameEdit(newInputValue);
                  fetchMedicinesHandler(newInputValue.toUpperCase());
                }}
                value={medicineNameEdit || ""}
                onChange={(event, value) => setMedicineNameEdit(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      background: "#fff",
                      color: "#127DDD",
                      fontWeight: "300",
                      fontSize: "13px",
                      lineHeight: " 15px",
                      "& input::placeholder": {
                        color: "#777777",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: " 15px",
                      },
                    }}
                    variant="outlined"
                    placeholder="Enter Medicine Name"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} lg={6}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#000000",
                  marginBlock: "10px",
                }}>
                Dose/Quantity
              </Typography>
              <Autocomplete
                fullWidth
                freeSolo
                disableClearable
                size="small"
                options={doseListEdit.map((option) => option)}
                onInputChange={(event, newInputValue) => {
                  setDosageEdit(newInputValue);
                  setDoseListEdit(generateDoseList(newInputValue));
                }}
                value={dosageEdit || ""}
                selectOnFocus
                onChange={(event, value) => {
                  setDosageEdit(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      background: "#fff",
                      color: "#127DDD",
                      fontWeight: "300",
                      fontSize: "13px",
                      lineHeight: " 15px",
                      "& input::placeholder": {
                        color: "#777777",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: " 15px",
                      },
                    }}
                    variant="outlined"
                    placeholder="Enter Dose/Quantity"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} lg={6}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#000000",
                  marginBlock: "10px",
                }}>
                Duration
              </Typography>
              <Autocomplete
                fullWidth
                freeSolo
                disableClearable
                size="small"
                options={durationListEdit.map((option) => option)}
                onInputChange={(event, newInputValue) => {
                  setDurationEdit(newInputValue);
                  setDurationListEdit(generateDurationList(newInputValue));
                }}
                value={durationEdit || ""}
                selectOnFocus
                onChange={(event, value) => {
                  setDurationEdit(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      background: "#fff",
                      color: "#127DDD",
                      fontWeight: "300",
                      fontSize: "13px",
                      lineHeight: " 15px",
                      "& input::placeholder": {
                        color: "#777777",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: " 15px",
                      },
                    }}
                    variant="outlined"
                    placeholder="Enter Duration"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#000000",
                  marginBottom: "10px",
                }}>
                Frequency
              </Typography>
              <Grid container sx={{ justifyContent: "space-between", flexWrap: "wrap" }}>
                <Grid item xs={3} lg={3}>
                  <IconButton
                    onClick={() => {
                      setSelectedButtonEdit("OD");
                      setFrequencyStringEdit("OD");
                      setTimesADayEdit("");
                    }}>
                    <Box
                      sx={{
                        background: selectedButtonEdit === "OD" ? "#127DDD" : "#F1F1F1",
                        borderRadius: "25px",
                        paddingInline: "20px",
                        paddingBlock: "5px",
                        marginRight: "5px",
                      }}>
                      <Typography
                        sx={{
                          fontWeight: "400",
                          fontSize: "13px",
                          lineHeight: "15px",
                          color: selectedButtonEdit === "OD" ? "#FFFFFF" : "#000000",
                          margin: "3px",
                        }}>
                        OD
                      </Typography>
                    </Box>
                  </IconButton>
                </Grid>
                <Grid item xs={3} lg={3}>
                  <IconButton
                    onClick={() => {
                      setSelectedButtonEdit("BD");
                      setFrequencyStringEdit("BD");
                      setTimesADayEdit("");
                    }}>
                    <Box
                      sx={{
                        background: selectedButtonEdit === "BD" ? "#127DDD" : "#F1F1F1",
                        borderRadius: "25px",
                        paddingInline: "20px",
                        paddingBlock: "5px",
                        marginInline: "5px",
                      }}>
                      <Typography
                        sx={{
                          fontWeight: "400",
                          fontSize: "13px",
                          lineHeight: "15px",
                          color: selectedButtonEdit === "BD" ? "#FFFFFF" : "#000000",
                          margin: "3px",
                        }}>
                        BD
                      </Typography>
                    </Box>
                  </IconButton>
                </Grid>
                <Grid item xs={3} lg={3}>
                  <IconButton
                    onClick={() => {
                      setSelectedButtonEdit("TD");
                      setFrequencyStringEdit("TD");
                      setTimesADayEdit("");
                      setIsMorningEdit("");
                      setIsAfterNoonEdit("");
                      setIsNightEdit("");
                    }}>
                    <Box
                      sx={{
                        background: selectedButtonEdit === "TD" ? "#127DDD" : "#F1F1F1",
                        borderRadius: "25px",
                        paddingInline: "20px",
                        paddingBlock: "5px",
                        marginInline: "5px",
                      }}>
                      <Typography
                        sx={{
                          fontWeight: "400",
                          fontSize: "13px",
                          lineHeight: "15px",
                          color: selectedButtonEdit === "TD" ? "#FFFFFF" : "#000000",
                          margin: "3px",
                        }}>
                        TD
                      </Typography>
                    </Box>
                  </IconButton>
                </Grid>
                <Grid item xs={3} lg={3}>
                  <IconButton
                    onClick={() => {
                      setSelectedButtonEdit("QD");
                      setFrequencyStringEdit("QD");
                      setTimesADayEdit("");
                      setIsMorningEdit("");
                      setIsAfterNoonEdit("");
                      setIsNightEdit("");
                    }}>
                    <Box
                      sx={{
                        background: selectedButtonEdit === "QD" ? "#127DDD" : "#F1F1F1",
                        borderRadius: "25px",
                        paddingInline: "20px",
                        paddingBlock: "5px",
                        marginInline: "5px",
                      }}>
                      <Typography
                        sx={{
                          fontWeight: "400",
                          fontSize: "13px",
                          lineHeight: "15px",
                          color: selectedButtonEdit === "QD" ? "#FFFFFF" : "#000000",
                          margin: "3px",
                        }}>
                        QD
                      </Typography>
                    </Box>
                  </IconButton>
                </Grid>
                <Grid item xs={3} lg={3}>
                  <IconButton
                    onClick={() => {
                      setSelectedButtonEdit("OTHER");
                    }}>
                    <Box
                      sx={{
                        background: selectedButtonEdit === "OTHER" ? "#127DDD" : "#F1F1F1",
                        borderRadius: "25px",
                        paddingInline: "20px",
                        paddingBlock: "5px",
                        marginRight: "5px",
                      }}>
                      <Typography
                        sx={{
                          fontWeight: "400",
                          fontSize: "13px",
                          lineHeight: "15px",
                          color: selectedButtonEdit === "OTHER" ? "#FFFFFF" : "#000000",
                          margin: "3px",
                        }}>
                        OTHER
                      </Typography>
                    </Box>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            {(selectedButtonEdit === "OD" || selectedButtonEdit === "BD") && (
              <Grid container spacing={2}>
                <Grid item xs={4} lg={4}>
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        size="medium"
                        checked={isMorningEdit}
                        onChange={(event) => setIsMorningEdit(event.target.checked)}
                      />
                    }
                    label="Morning"
                  />
                </Grid>
                <Grid item xs={4} lg={4}>
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        size="medium"
                        checked={isAfterNoonEdit}
                        onChange={(event) => setIsAfterNoonEdit(event.target.checked)}
                      />
                    }
                    label="AfterNoon"
                  />
                </Grid>
                <Grid item xs={4} lg={4}>
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        size="medium"
                        checked={isNightEdit}
                        onChange={(event) => setIsNightEdit(event.target.checked)}
                      />
                    }
                    label="Night"
                  />
                </Grid>
              </Grid>
            )}
            {selectedButtonEdit === "OTHER" && (
              <Grid container>
                <Grid item xs={2.5} lg={2.5}>
                  <TextField
                    value={
                      timesADayEdit === "OD" ||
                      timesADayEdit === "BD" ||
                      timesADayEdit === "TD" ||
                      timesADayEdit === "QD"
                        ? ""
                        : timesADayEdit
                    }
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        setTimesADayEdit(e.target.value);
                        setFrequencyStringEdit(`${e.target.value} Times a Day`);
                      }
                    }}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      maxLength: 3,
                      height: "20px",
                    }}
                  />
                </Grid>
                <Grid item xs={9.5} lg={9.5}>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "13px",
                      lineHeight: "15px",
                      color: "#000000",
                      marginInline: "10px",
                      marginTop: "10px",
                    }}>
                    Times a Day
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} lg={12}>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#000000",
                  marginBottom: "10px",
                }}>
                Instructions
              </Typography>
              <TextField
                multiline
                sx={{
                  background: "#fff",
                  color: "#127DDD",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                  "& input::placeholder": {
                    color: "#777777",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                  },
                }}
                size="small"
                fullWidth
                variant="outlined"
                placeholder="Enter Instructions"
                value={instructionsEdit || ""}
                onChange={(e) => setInstructionsEdit(e.target.value)}
                inputProps={{
                  style: {
                    height: 70,
                    color: "#777777",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={6} lg={6} textAlign="right">
              <Button
                onClick={handleCloseEdit}
                variant="outlined"
                style={{
                  borderRadius: "15px",
                  border: "1px solid",
                  textTransform: "none",
                  marginInline: "10px",
                }}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "15px",
                    color: "#127DDD",
                  }}>
                  Cancel
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={6} lg={6}>
              <Button
                onClick={() => {
                  editPrescriptionHandler(
                    medicineNameEdit,
                    dosageEdit,
                    durationEdit,
                    frequencyStringEdit,
                    instructionsEdit,
                    isMorningEdit,
                    isAfterNoonEdit,
                    isNightEdit,
                    pDetails,
                    editIndex
                  );
                }}
                variant="contained"
                style={{
                  borderRadius: "15px",
                  textTransform: "none",
                  marginInline: "10px",
                }}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "15px",
                    color: "#FFFFFF",
                  }}>
                  Save
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={openDeleteRxModal}
        onClose={handleCloseDeleteRxModal}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(187, 187, 187, 0.1)",
          },
          marginTop: "-100px",
        }}>
        <Box
          sx={{
            backgroundColor: "#fff",
            boxShadow: "0px 1px 4px 1px rgba(0, 0, 0, 0.1)",
            borderRadius: "5px",
            padding: "20px",
            width: "350px",
            justifyContent: "center",
          }}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={handleCloseDeleteRxModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#000000",
              marginTop: "-20px",
              textAlign: "center",
            }}>
            Do you want to delete this Rx Comment ?
          </Typography>
          <Grid container sx={{ marginTop: "20px" }}>
            <Grid item xs={6} lg={6} textAlign="right">
              <Button
                onClick={handleCloseDeleteRxModal}
                variant="outlined"
                style={{
                  borderRadius: "15px",
                  border: "1px solid",
                  textTransform: "none",
                  marginInline: "10px",
                }}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "15px",
                    color: "#127DDD",
                  }}>
                  No
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={6} lg={6}>
              <Button
                onClick={() => {
                  deleteRxComment();
                }}
                variant="contained"
                style={{
                  borderRadius: "15px",
                  textTransform: "none",
                  marginInline: "10px",
                }}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "13px",
                    lineHeight: "15px",
                    color: "#FFFFFF",
                  }}>
                  Yes
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default Rx;
