import { Container, CssBaseline } from "@mui/material";
import { Fragment } from "react";
import DefaultMain from "../modules/corpDefault/defaultIndex";

const DefaultIndex = () => {
  return (
    <Fragment>
      <CssBaseline />
      <Container
        disableGutters
        maxWidth={false}
        sx={{ height: "84vh", background: "#F5F5F5", mt: 14 }}
      >
        <DefaultMain />
      </Container>
    </Fragment>
  );
};

export default DefaultIndex;
