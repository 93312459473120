export const ISSUEREPORTED_TEXT = "Issue Reported";
export const PREEXISTINGCONDITION_TEXT = "Disease History";
export const DRUGHISTORY_TEXT = "Drug History";
export const DRUGALLERGY_TEXT = "Drug Allergy";
export const HABITS_TEXT = "Habits";
export const OCCUPATION_TEXT = "Occupation";
export const PARENTALHISTORY_TEXT = "Parental History";

//vitals label

export const VITALS_SUGAR = "RBS";
