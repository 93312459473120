import { Avatar, Box, Card, CardContent, CardMedia, Grid, Stack, Typography } from "@mui/material";
import Nurse from "../../../assets/images/staffing/nurse.jpg";
import NurseMale from "../../../assets/images/staffing/nursemale.jpg";
import CustomTitles from "../../../global/titles/customTitles";
import DilipkumarRathore from "../../../assets/images/staffing/dilipkumatrathore.png";
import HemrajChouriya from "../../../assets/images/staffing/hemrajchouriya.png";
import PrshantChouhan from "../../../assets/images/staffing/prsahantchouhan.png";
import Hazargo_Ankit from "../../../assets/images/staffing/Ankit Solanki Hazargo Industry.jpeg";
import Hazargo_Abhishek from "../../../assets/images/staffing/Abhishek Solanki Hazargo Industry.jpg";

import pawankumarrathore_profile from "../../../assets/images/staffing/glenmarkpharmaceuticals/pawankumarrathore_profile.jpg";
import bhagirathyadav_profile from "../../../assets/images/staffing/glenmarkpharmaceuticals/bhagirathyadav_profile.jpg";
import nehaverma_profile from "../../../assets/images/staffing/glenmarkpharmaceuticals/nehaverma_profile.jpg";

const stafData = (corpId) => {
  let staff = [];

  switch (corpId) {
    case "bbb269e5-b020-4257-ad8f-4da8c811801a": //case
      staff = [
        {
          id: 1,
          name: "Arvind Prasad",
          gender: "MALE",
          imageURl: NurseMale,
        },
        {
          id: 2,
          name: "Rahul Solanki",
          gender: "MALE",
          imageURl: NurseMale,
        },
        {
          id: 3,
          name: "Jay Vishyarthi",
          gender: "MALE",
          imageURl: NurseMale,
        },
      ];
      break;
    case "13a4b8c6-750f-442a-8414-f5465481e5d9": //volvo
      staff = [
        {
          id: 1,
          name: "Dilip Kumar Rathore",
          gender: "MALE",
          imageURl: DilipkumarRathore,
        },
        {
          id: 2,
          name: "Prashant Chouhan",
          gender: "MALE",
          imageURl: PrshantChouhan,
        },
        {
          id: 3,
          name: "Hemraj Chouriya ",
          gender: "MALE",
          imageURl: HemrajChouriya,
        },
      ];
      break;
    case "1f7b5e98-04ee-47e5-b6cd-300f0dc98281": //novelle
      staff = [
        {
          id: 1,
          name: "Rohit Rathore",
          gender: "MALE",
          imageURl: NurseMale,
        },
        {
          id: 2,
          name: "Virendra Thakur",
          gender: "MALE",
          imageURl: NurseMale,
        },
        {
          id: 3,
          name: "Santosh Ahirwal",
          gender: "MALE",
          imageURl: NurseMale,
        },
      ];
      break;
    case "701b58c4-02f2-43fa-9940-6ace06e38c0b": // Hazargo industries Pvt Ltd
      staff = [
        {
          id: 1,
          name: "Abhishek Solanki",
          gender: "MALE",
          imageURl: Hazargo_Ankit,
        },
        {
          id: 2,
          name: "Ankit Solanki",
          gender: "MALE",
          imageURl: Hazargo_Abhishek,
        },
      ];
      break;

    case "323d16b9-709b-4b83-9826-c1917e7114a6": // Glenmark
      staff = [
        {
          id: 1,
          name: "Pawan Kumar Rathor",
          gender: "MALE",
          imageURl: pawankumarrathore_profile,
        },
        {
          id: 2,
          name: "Bhagirath Yadav",
          gender: "MALE",
          imageURl: bhagirathyadav_profile,
        },
        {
          id: 2,
          name: "Neha Verma",
          gender: "FEMALE",
          imageURl: nehaverma_profile,
        },
      ];
      break;

    default:
      staff = [
        {
          id: 1,
          name: "",
          gender: "MALE",
          imageURl: NurseMale,
        },
        {
          id: 2,
          name: "",
          gender: "MALE",
          imageURl: NurseMale,
        },
        {
          id: 3,
          name: "",
          gender: "FEMALE",
          imageURl: Nurse,
        },
      ];
  }
  return staff;
};
const StaffingNursesMain = ({ corpId = localStorage.getItem("CORPID") }) => {
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="Nurses" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display="flex"
          justifyContent="center"
          alignItems="center">
          <Typography variant="h6">Meet our qualified and experienced staff.</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          display="flex"
          justifyContent="center"
          alignItems="center">
          <Stack direction="row" spacing={3}>
            {stafData(corpId).map((item, index) => (
              <Card sx={{ px: 5, py: 2 }} key={index}>
                <CardContent>
                  <Stack spacing={3} display="flex" justifyContent="center" alignItems="center">
                    <Avatar src={item?.imageURl} sx={{ width: 200, height: 200 }} />
                    <Typography variant="button">{item?.name}</Typography>
                  </Stack>
                </CardContent>
              </Card>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StaffingNursesMain;
