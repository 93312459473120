import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  Portal,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { Fragment, useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Label,
} from "recharts";
import zoomIcon from "../../../../../assets/images/zoomIcon.png";
import StatusTrackerDetailGrid from "../../../../global/customDatagrids/statusTrackerDetailGrid";
import DownloadCSV from "../../../../global/components/downloadCSV";
import MaskingButton from "../../../../global/components/maskingButton";
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#127DDD", "#D45FFF", "#DDFFDF"];

let renderLabel = function (entry) {
  return `${entry.name}(${entry.value}%)`;
};

const CustomizedAxisTick = (props) => {
  const { x, y, stroke, payload } = props;

  console.log({ props });

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={10} textAnchor="end" fill="#666" transform="rotate(-20)" fontSize={8}>
        {payload?.value?.toUpperCase()}
      </text>
    </g>
  );
};

const CustomTooltipContent = ({ active, payload, label, tooltipTitle }) => {
  if (active) {
    const data = payload?.[0]?.payload; // Get the data of the hovered bar

    return (
      // <Tooltip>
      <Box sx={{ backgroundColor: "#FFFFFF", padding: "10px" }}>
        {label ? <Typography>{`${label}`}</Typography> : null}
        {data?.value ? <Typography>{`${"Count"}: ${data?.value}`}</Typography> : null}
        {data?.percent ? (
          <Typography>{`${tooltipTitle ? tooltipTitle : "Percentage"}: ${
            data?.percent
          }`}</Typography>
        ) : null}
        {data?.count ? <Typography>{`Count: ${data?.count}`}</Typography> : null}
      </Box>
      // </Tooltip>
    );
  }

  return null;
};

const CustomBarGraphOhc = ({
  data,
  title,
  dialogData,
  barsName,
  barColor,
  popupData,
  setSelectedBar,
  disableClickable,
  drawerData,
  minWidth,
  xAxisLabel,
  boxHeight,
  legendHeight = 40,
  graphHeight = 190,
}) => {
  console.log({ dialogData });
  const [apiRef, setApiRef] = useState(null);
  const exportCSV = () => {
    apiRef.current.exportDataAsCsv();
  };
  const [open, setOpen] = useState(false);
  const [selectedChart, setSelectedChart] = useState("");
  const [selectedBarData, setSelectedBarData] = useState([]);
  const handleOpen = (title) => {
    setOpen(true);
    setSelectedChart(title);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedChart("");
    setSelectedBarData([]);
  };

  useEffect(() => {
    if (dialogData) {
      const data = dialogData[selectedChart];
      console.log({ dialogData, data72654239: data, selectedChart });
      if (data) {
        setSelectedBarData(
          data?.map((value, index) => ({
            id: `employee ${index}`,
            emp_id: value.empId || value.emp_id,
            name: value.name,
            department: value.department,
          }))
        );
      } else {
        console.log("Selected chart not found in dialogData");
      }
    }
  }, [selectedChart]);
  console.log({ popupData });

  useEffect(() => {
    if (popupData) {
      setSelectedBarData(popupData);
    }
  }, [popupData]);

  const [graphTitle, setGraphTitle] = useState("");
  const [graphData, setGraphData] = useState([]);
  const [graphFill, setGraphFill] = useState("");

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const generateCustomTicks = (data) => {
    const maxValue = Math.max(...data.map((item) => item.value));
    const ticks = [];
    for (let i = 0; i <= maxValue; i++) {
      ticks.push(i);
    }
    return ticks;
  };

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          border: "1px solid grey",
          height: boxHeight || "300px",
          paddingInline: "10px",
          pt: "10px",
          borderRadius: "10px",
          minWidth: minWidth,
        }}>
        <Typography
          textAlign="left"
          sx={{
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "120%",
            marginBottom: "20px",
          }}>
          {title}
        </Typography>
        <Box sx={{ height: graphHeight }}>
          {data.every((item) => item.value === 0) ? (
            <Box
              width={"100%"}
              height={290}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography sx={{ textAlign: "center" }}>No Data Found</Typography>
            </Box>
          ) : (
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                barSize={20}
                // width={730}
                // height={250}
                data={data}
                onClick={(bar) => {
                  console.log({ bar });
                  if (disableClickable) {
                    return;
                  } else {
                    handleOpen(bar?.activeLabel);
                    if (setSelectedBar) {
                      setSelectedBar(bar?.activeLabel);
                    }
                  }
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  style={{ fontSize: 8 }}
                  tick={<CustomizedAxisTick />}
                  interval={0}
                  height={legendHeight}>
                  <Label
                    value={xAxisLabel}
                    offset={0}
                    position="insideBottom"
                    style={{ fontSize: 10, fontWeight: 600 }}
                  />
                </XAxis>
                <YAxis
                  ticks={generateCustomTicks(data)}
                  tickFormatter={(tick) => tick}
                  width={18}
                  style={{ fontSize: 12 }}
                />
                <Tooltip itemStyle={{ fontSize: 12 }} />
                <Legend
                  verticalAlign="top"
                  height={20}
                  formatter={(value) => (
                    <span style={{ color: barColor ? barColor : "#82ca9d", fontSize: 12 }}>
                      Count
                    </span>
                  )}
                />
                <Bar dataKey="value" fill={barColor ? barColor : "#82ca9d"} name={barsName}>
                  <LabelList dataKey="value" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )}
        </Box>
      </Box>

      <Portal>
        <Dialog open={open} onClose={handleClose} maxWidth={"xl"} fullWidth={true}>
          <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>{title}</DialogTitle>
          <Typography variant="h6" sx={{ display: "flex", justifyContent: "center" }}>
            {selectedChart}
          </Typography>
          <Box sx={{ marginLeft: "22px" }}>
            <Stack direction="row" spacing={1}>
              <DownloadCSV exportCSV={exportCSV} type="ICON" />
              <MaskingButton type="ICON" />
            </Stack>
          </Box>
          <DialogActions sx={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
          <DialogContent>
            <StatusTrackerDetailGrid setApiRef={setApiRef} employeeList={selectedBarData || []} />
          </DialogContent>
        </Dialog>
      </Portal>

      <Portal>
        <Drawer anchor="bottom" variant="temporary" open={openDrawer} onClose={handleDrawerToggle}>
          <Box
            sx={{
              height: "70vh",
              width: "100%",
              mb: 10,
            }}>
            <Box sx={{ height: "300px" }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart width={730} height={250} data={graphData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    style={{ fontSize: 12 }}
                    tick={<CustomizedAxisTick />}
                    // interval={4}
                    height={100}
                  />
                  <YAxis />
                  <Tooltip />
                  <Legend verticalAlign="top" height={36} />
                  <Bar dataKey="value" fill={graphFill} barSize={130} name="Number of Employees">
                    <LabelList dataKey="value" position="top" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Box>
            <Stack
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center">
              <Stack width={"55%"} display={"flex"} alignItems="flex-end">
                <Typography textAlign="center">{graphTitle}</Typography>
              </Stack>
              <Stack>
                <Button onClick={handleDrawerToggle}>Close</Button>
              </Stack>
            </Stack>
          </Box>
        </Drawer>
      </Portal>
    </Fragment>
  );
};

export default CustomBarGraphOhc;
