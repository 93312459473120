import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import multispecialities from "../../assets/images/multispecialities.png";
import pharmacyIcon from "../../assets/images/pharmacyIcon.png";
import VideoConsultationIcon from "../../assets/images/VideoConsultationIcon.png";
import labTestIcon from "../../assets/images/labTestIcon.png";

import { useState } from "react";

const textcolor = "white";

const LeftBanner = ({ scrollToContact }) => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const fontSize = isSmallScreen ? "40px" : "70px";
  const height = isSmallScreen ? "450px" : "664px";
  return (
    <Box
      sx={{
        backgroundColor: "#0463FA",
        paddingBlock: "100px",
        paddingInline: "50px",
        height: height,
        marginottom: 0,
      }}>
      <Grid container sx={{ justifyContent: "center", alignItems: "center" }}>
        <Grid item xs={12} lg={12}>
          <Typography
            sx={{
              color: textcolor,
              fontSize: fontSize,
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
              marginBlock: "5px",
              textTransform: "capitalize",
            }}>
            Your One-stop Partner For All Healthcare Related Needs
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ marginBlock: "10px" }}>
        <Button
          variant="outlined"
          sx={{
            borderRadius: "6px",
            border: "1.4px solid #0463FA",
            backgroundColor: "#FFFFFF",
            color: "#0045B9",
            "&:hover": {
              color: "#0045B9",
              backgroundColor: "#FFFFFF",
              border: "1.4px solid #0045B9",
            },
          }}
          onClick={() => {
            if (typeof scrollToContact === "function") {
              scrollToContact();
              console.log({ hii: scrollToContact() });
            }
          }}>
          <Typography
            sx={{
              color: "#0045B9",
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "normal",
              textTransform: "capitalize",
              marginInline: "15px",
            }}>
            LETS CONNECT
          </Typography>
        </Button>
      </Box>
      {/* <LetsConnect open={open} handleClose={handleClose} /> */}
    </Box>
  );
};

export default LeftBanner;
