import RootTemplate from "../../global/templates/rootTemplate";
import OHCUpcomingMain from "../../modules/ohc/upcoming/ohcUpcomingMain";

const OHCUpcomingIndex = () => {
  return (
    <RootTemplate>
      <OHCUpcomingMain />
    </RootTemplate>
  );
};

export default OHCUpcomingIndex;
