import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { Download } from "@mui/icons-material";
import { useGridApiRef } from "@mui/x-data-grid";
import CustomAutocomplete from "../../../../../assets/customAutocomplete/customAutocomplete";
import CustomDataGridLayout from "../../../../../assets/customDataGridLayout/customDataGridLayout";
import { fetchMedicineListInDashboard } from "../../../../services/inventory";
import RenderExpandableCells from "../../../../../assets/customDataGridLayout/renderExpandableCells";

const MedicinesDashboard = ({ corpId = localStorage.getItem("CORPID") }) => {
  const columns = [
    {
      field: "productCode",
      headerName: "Product Code",
      width: 130,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "productName",
      headerName: "Product Name",
      width: 280,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <RenderExpandableCells {...params} />,
    },
    {
      field: "brand",
      headerName: "Brand",
      width: 280,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <RenderExpandableCells {...params} />,
    },
    {
      field: "batchLabel",
      headerName: "Batch Label",
      headerClassName: "super-app-theme--header",
      width: 130,
    },
    {
      field: "expiryDate",
      headerName: "Expire date",
      headerClassName: "super-app-theme--header",
      width: 130,
    },
    {
      field: "baseUnit",
      headerName: "Base unit",
      headerClassName: "super-app-theme--header",
      width: 160,
    },
    {
      field: "quantity",
      headerName: "Available Quantity",
      width: 160,
      headerClassName: "super-app-theme--header",
      align: "center",
    },
    {
      field: "tag",
      headerName: "Tag",
      width: 200,
      headerClassName: "super-app-theme--header",
      align: "left",
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [medicineList, setMedicineList] = useState([]);
  const [medicineNameList, setMedicineNameList] = useState([]);
  const [brandNameList, setBrandNameList] = useState([]);
  const [selectedMedicineName, setSelectedMedicineName] = useState("");
  const [selectedBrandName, setSelecteBrandName] = useState("");

  useEffect(() => {
    fetchMedicineListInDashboard(
      corpId,
      setMedicineList,
      setIsLoading,
      setMedicineNameList,
      setBrandNameList
    );
  }, []);

  const apiRef = useGridApiRef();
  const handleExportCSV = () => {
    if (apiRef.current) {
      apiRef.current.exportDataAsCsv();
    }
  };

  useEffect(() => {
    if (selectedMedicineName) {
      const brandsForSelectedMedicine = medicineList
        .filter((item) => item.productName === selectedMedicineName)
        .map((item) => item.brand);
      setBrandNameList([...new Set(brandsForSelectedMedicine)]);
    } else {
      const allBrands = medicineList.map((item) => item.brand);
      setBrandNameList([...new Set(allBrands)]);
    }
  }, [selectedMedicineName, medicineList]);

  useEffect(() => {
    if (selectedBrandName) {
      const medicinesForSelectedBrand = medicineList
        .filter((item) => item.brand === selectedBrandName)
        .map((item) => item.productName);
      setMedicineNameList([...new Set(medicinesForSelectedBrand)]);
    } else {
      const allMedicines = medicineList.map((item) => item.productName);
      setMedicineNameList([...new Set(allMedicines)]);
    }
  }, [selectedBrandName, medicineList]);

  const filteredData = useMemo(() => {
    return medicineList
      .filter((item) => item.quantity)
      .filter((item) => (selectedMedicineName ? item.productName === selectedMedicineName : true))
      .filter((item) => (selectedBrandName ? item.brand === selectedBrandName : true));
  }, [selectedBrandName, selectedMedicineName, medicineList]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Fragment>
      <Grid container spacing={2} sx={{ mt: 0.5 }}>
        <Grid item xs={3} lg={3}>
          <CustomAutocomplete
            label="Search By Medicine Name"
            placeholder="Search By Medicine Name"
            options={medicineNameList}
            value={selectedMedicineName}
            onChange={(event, newValue) => {
              setSelectedMedicineName(newValue?.trim());
              setSelecteBrandName("");
            }}
            getOptionLabel={(option) => option}
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <CustomAutocomplete
            label="Search By Brand Name"
            placeholder="Search By Brand Name"
            options={brandNameList}
            value={selectedBrandName}
            onChange={(event, newValue) => {
              setSelecteBrandName(newValue?.trim());
              setSelectedMedicineName("");
            }}
            getOptionLabel={(option) => option}
          />
        </Grid>
        <Grid item xs={3} lg={3}></Grid>
        <Grid item xs={3} lg={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() => handleExportCSV()}
            variant="contained"
            startIcon={<Download sx={{ color: "#FFF" }} />}>
            Download
          </Button>
        </Grid>
      </Grid>
      <CustomDataGridLayout
        rows={filteredData}
        columns={columns}
        dataGridHeight={"53vh"}
        density="compact"
        apiRef={apiRef}
      />
    </Fragment>
  );
};

export default MedicinesDashboard;
