import { Box, Grid } from "@mui/material";
import React from "react";
import CustomTitles from "../../../global/titles/customTitles";
import CompletedSessionMain from "../comp/completedSessionMain";

const CompletedSessions = () => {
  return (
    <Box>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="Completed Sessions" />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CompletedSessionMain />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompletedSessions;
