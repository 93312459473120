import { Alert, Box, Button, Snackbar } from "@mui/material";
import { useState } from "react";
import Papa from "papaparse";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { saveData } from "../../../../../services/api/postApi";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { getEmployeeListToBeTested } from "../../../../global/services/corporateServices";
import { updateData } from "../../../../../services/api/patchApi";

const allowedExtensions = ["csv"];

const strToBool = (str) => {
  return str === "YES" ? true : str === "NO" ? false : null;
};

const ParseCSV = ({ setEmployeeList, corpId }) => {
  const isValidUUID = (id) => {
    const uuidRegex =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return uuidRegex.test(id);
  };
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [file, setFile] = useState("");

  const handleFileChange = (e) => {
    setError("");

    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setError("Please input a csv file");
        return;
      }

      // If input type is correct set the state
      setFile(inputFile);
    }
  };
  const handleParse = () => {
    // If user clicks the parse button without
    // a file we show a error
    if (!file) return alert("Enter a valid file");

    // Initialize a reader which allows user
    // to read any file or blob.
    const reader = new FileReader();

    // Event listener on reader when the file
    // loads, we parse it and set the data.
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, {
        header: true,
      });
      const parsedData = csv?.data;
      const rows = Object.keys(parsedData[0]);

      const columns = Object.values(parsedData[0]);
      const res = rows.reduce((acc, e, i) => {
        return [...acc, [[e], columns[i]]];
      }, []);
      console.log(res, parsedData);
      setData(parsedData);
      _uploadData(parsedData);
    };
    reader.readAsText(file);
  };

  console.log({ DATA: data });

  const _uploadData = async (values) => {
    const url = BASE_URL + `org/employee/tests/update`;

    const payload = values
      .filter((item) => isValidUUID(item.id))
      .map((item, index) => ({
        id: item.id,
        corpId: corpId,
        // bloodPressure: item.bloodPressure,
        // coveredInThisAHC: item.coveredInThisAHC,
        // empId: item.empId,
        // employeename: item.employeename,
        // height: item.height,
        // tests
        isAudiometry: strToBool(item.isAudiometry),
        isBloodGroup: strToBool(item.isBloodGroup),
        isCbc: strToBool(item.isCbc),
        isCreatinine: strToBool(item.isCreatinine),
        isECG: strToBool(item.isECG),
        isESR: strToBool(item.isESR),
        isEye: strToBool(item.isEye),
        isHBSAG: strToBool(item.isHBSAG),
        isHIV: strToBool(item.isHIV),
        isHb1ac: strToBool(item.isHb1ac),
        isLipidProfile: strToBool(item.isLipidProfile),
        isLiverProfile: strToBool(item.isLiverProfile),
        isPft: strToBool(item.isPft),
        isSGOT: strToBool(item.isSGOT),
        isSGPT: strToBool(item.isSGPT),
        isStoolSample: strToBool(item.isStoolSample),
        isSugarTest: strToBool(item.isSugarTest),
        isTSH: strToBool(item.isTSH),
        isUrine: strToBool(item.isUrine),
        isVDRL: strToBool(item.isVDRL),
        isWidal: strToBool(item.isWidal),
        isXRay: strToBool(item.isXRay),
        issBilirubin: strToBool(item.issBilirubin),
        //tests end
        // lastCheckup: item.lastCheckup,
        // weight: item.weight,
      }));

    const response = await updateData(url, payload);

    if (response.error) {
      console.log("error");
      setSeverity("error");
      setMessage("An error Occured");
      setOpenNotice(true);
    } else {
      console.log("success");
      setSeverity("success");
      setMessage("Saved Successfully");
      setOpenNotice(true);
      getEmployeeListToBeTested(corpId, setEmployeeList);
    }
  };

  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [openNotice, setOpenNotice] = useState(false);

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };
  return (
    <Box>
      <Snackbar open={openNotice} autoHideDuration={1000} onClose={handleCloseNotice}>
        <Alert onClose={handleCloseNotice} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      {/* <Button
        sx={{
          fontWeight: "400",
          fontSize: "13px",
          lineHeight: "15px",
          color: "#FFFFFF",
          marginTop: "5px",
          textTransform: "capitalize",
        }}
        variant="contained"
        startIcon={<AttachFileIcon style={{ color: "#FFFFFF" }} />}>
        Upload <input onChange={handleFileChange} id="csvInput" name="file" type="File" />
      </Button> */}
      <Button variant="contained" component="label">
        Upload File&nbsp;
        <input onChange={handleFileChange} id="csvInput" name="file" type="File" />
      </Button>
      {/* <input onChange={handleFileChange} id="csvInput" name="file" type="File" /> */}
      <Button onClick={handleParse}>Upload CSV</Button>

      {/* <Box>
        {error
          ? error
          : data.map((e, i) => (
              <div key={i} className="item">
                {e[0]}:{e[1]}
              </div>
            ))}
      </Box> */}
    </Box>
  );
};

export default ParseCSV;
