import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Portal,
  TextField,
  Typography,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import React, { Fragment, useEffect, useState } from "react";
import CustomDataGridLayout from "../../../../../assets/customDataGridLayout/customDataGridLayout";
import { Close, Edit } from "@mui/icons-material";
import CustomAutocomplete from "../../../../../assets/customAutocomplete/customAutocomplete";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { getData } from "../../../../../services/api/getApi";
import { useSnackbar } from "notistack";
import { updateData } from "../../../../../services/api/patchApi";
import CustomButtonBlue from "../../../ohc/ohcMyOpd/comps/recordOpd/subComp/writePrescription/subComps/customButton/CustomButtonBlue";
import DefineReorderPointBulkUpload from "./defineReorderPointBulkUpload.jsx";
import { downloadCsv } from "../../../../../assets/utils.jsx";

const sortData = (data) => {
  return [...data].sort((a, b) => {
    if (
      a.availableQuantity <= a.reorderPoint &&
      b.availableQuantity > b.reorderPoint
    ) {
      return -1;
    }
    if (
      a.availableQuantity > a.reorderPoint &&
      b.availableQuantity <= b.reorderPoint
    ) {
      return 1;
    }
    return 0;
  });
};

const DefineReorderPoint = ({ corpId = localStorage.getItem("CORPID") }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedproductName, setSelectedproductName] = useState("");
  const [selectedbrand, setSelectedbrand] = useState("");
  const [open, setOpen] = useState(false);

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const [formValues, setFormValues] = useState({
    productCode: "",
    productName: "",
    brand: "",
    availableQuantity: "",
    reorderPoint: "",
  });

  const columns = [
    {
      field: "productName",
      headerName: "Product Name",
      width: 300,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "brand",
      headerName: "Brand Name",
      width: 460,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "availableQuantity",
      headerName: "Available Quantity",
      width: 170,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "reorderPoint",
      headerName: "Reorder Point",
      width: 170,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 140,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              onClick={() => {
                setOpen(true);
                setFormValues({
                  productCode: params.row.productCode,
                  productName: params.row.productName,
                  brand: params.row.brand,
                  availableQuantity: params.row.availableQuantity,
                  reorderPoint: params.row.reorderPoint,
                });
              }}
            >
              <Edit />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const [inventoryData, setInventoryData] = useState([]);
  const getInventoryDetails = async () => {
    const url = BASE_URL + `inventory/getInventoryDetails/${corpId}`;
    const result = await getData(url);
    if (result.error) {
      setInventoryData(result.error);
    } else {
      const temp = result.data.map((item, index) => ({
        id: index,
        ...item,
      }));
      setInventoryData(temp);
    }
  };

  useEffect(() => {
    getInventoryDetails();
  }, [open2]);

  const handleUpdate = async () => {
    const payload = [
      {
        productCode: formValues.productCode,
        minimumUnit: formValues.reorderPoint,
      },
    ];
    const url = BASE_URL + `inventory/updateReorderPoint/${corpId}`;
    const result = await updateData(url, payload);
    if (result.error) {
      enqueueSnackbar(`An Error Occured`, {
        variant: "error",
      });
    } else {
      enqueueSnackbar(`Successfully Updated`, {
        variant: "success",
      });
      setOpen(false);
      getInventoryDetails();
    }
  };

  return (
    <Fragment>
      <Grid container spacing={2} sx={{ mt: 0.5 }}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <CustomAutocomplete
            label={"Medicine Name"}
            placeholder={"Medicine Name"}
            options={[]}
            value={selectedproductName}
            onChange={(event, newValue) => {
              setSelectedproductName(newValue);
            }}
            getOptionLabel={(option) => option}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <CustomAutocomplete
            label={"Brand Name"}
            placeholder={"Brand Name"}
            options={[]}
            value={selectedbrand}
            onChange={(event, newValue) => {
              selectedbrand(newValue);
            }}
            getOptionLabel={(option) => option}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Button
            variant="contained"
            onClick={() => {
              handleOpen2();
            }}
          >
            Define Rorder Point (Bulk Upload)
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box style={{ height: "50vh", width: "100%" }}>
            <CustomDataGridLayout
              rows={sortData(inventoryData)}
              styles={{
                ".error": {
                  backgroundColor: "#FF0000",
                  "&:hover": {
                    backgroundColor: "#FF4D4D",
                  },
                },
              }}
              density="compact"
              columns={columns}
              checkboxSelection={false}
              disableSelectionOnClick={true}
              disableRowSelectionOnClick={true}
              getRowClassName={(params) => {
                return params.row.availableQuantity <= params.row.reorderPoint
                  ? "error"
                  : "";
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Portal>
        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
            Edit Reorder Point
          </DialogTitle>

          <DialogActions sx={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton
              onClick={() => {
                setOpen(false);
              }}
            >
              <Close />
            </IconButton>
          </DialogActions>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  disabled
                  label="Medicine Name"
                  placeholder="Medicine Name"
                  size="small"
                  fullWidth
                  value={formValues.productName}
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      productName: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  disabled
                  label="Brand Name"
                  placeholder="Brand Name"
                  size="small"
                  fullWidth
                  value={formValues.brand}
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      brand: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  disabled
                  label="Available Quantity"
                  placeholder="Available Quantity"
                  size="small"
                  fullWidth
                  value={formValues.availableQuantity}
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      availableQuantity: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  label="Reorder Point"
                  placeholder="Reorder Point"
                  size="small"
                  fullWidth
                  value={formValues.reorderPoint}
                  onChange={(e) => {
                    const isInputValid = /^\d*$/.test(e.target.value);
                    if (isInputValid) {
                      setFormValues({
                        ...formValues,
                        reorderPoint: e.target.value,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => {
                    handleUpdate();
                  }}
                  variant="contained"
                  sx={{ textTransform: "capitalize" }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Portal>

      <DefineReorderPointBulkUpload
        open={open2}
        handleClose={handleClose2}
        requiredFile={sortData(inventoryData)}
      />
    </Fragment>
  );
};

export default DefineReorderPoint;
