import { Fragment } from "react";
import RootTemplate from "../../global/templates/rootTemplate";
import InvoicesMain from "../../modules/tickets/invoices/InvoicesMain";

const InvoicesIndex = () => {
  return (
    <Fragment>
      <RootTemplate>
        <InvoicesMain />
      </RootTemplate>
    </Fragment>
  );
};

export default InvoicesIndex;
