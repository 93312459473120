import { Box, Container, Grid, Typography } from "@mui/material";
import { Fragment } from "react";

const CustomTypographySubHeading = ({ children }) => {
  return (
    <Typography variant="h6" sx={{ color: "#000000" }}>
      {children}
    </Typography>
  );
};

const PrivacyDetails = () => {
  return (
    <Fragment>
      <Container maxWidth={false} sx={{ mt: 12, mb: 10 }}>
        <Box>
          <Grid container spacing={2}>
            <Grid item lg={12} display="flex" justifyContent="center" alignItems="center">
              <Typography variant="h5" sx={{ color: "#000000" }}>
                Privacy Policy
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                Read our policy outlines below to learn more about your rights and responsibilities
                as a Uno.care user.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                MYSTICDOC HEALTHCARE PRIVATE LIMITED built the Uno.care app as a Commercial app.
                This SERVICE is provided by MYSTICDOC HEALTHCARE PRIVATE LIMITED and is intended for
                use as is.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <Typography>Effective from: 1 July, 2022</Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>WE CARE ABOUT YOUR PRIVACY</CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                uno.care., including its affiliates “uno.care”, is committed to respect your privacy
                and to comply with applicable data protection and privacy laws. This privacy policy
                “Policy”, describes how we collect and use personal data where uno.care is the data
                controller or where we refer to the applicability of this Policy. “Personal data”,
                means information relating to you or another identifiable individual.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                We will give you additional privacy information that is specific to a product or
                service in Supplements to this Policy and other notices you may see while using our
                products or services. If there is a difference between such notices and this Policy,
                the notices should be considered first.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                Software on your device may access your information. Our products or services may
                contain links to, or may be embedded within, other companies’ websites and services
                that have privacy policies of their own. Where our products or services are embedded
                to products and services of our customers, we require our customers to provide
                necessary transparency to you. This might include linking to this Policy and the
                relevant Supplements, or providing the transparency in integrated and embedded
                notices which identify us as the service provider or controller.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                IF YOU DO NOT AGREE WITH THIS POLICY, DO NOT USE OUR PRODUCTS AND SERVICE OR PROVIDE
                uno.care WITH YOUR PERSONAL DATA.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>What information we collect?</CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                We collect your personal data and other information when you use or register into
                our products and services (including uno.care, operated by us), take part in
                interacting with us. This includes following categories:
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>
                Product and service activations
              </CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                uno.care products and services may require electronic activation, where your device
                and application type, as well as unique device, application, network and
                subscription identifiers are sent to uno.care.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>Use of products and services</CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                Our applications may contact our servers periodically, for example to check for
                updates or to send us information relating to service usage. See Supplements to this
                Policy for more details.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>Information you provide to us</CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                When you create an account, request services, participate in research or campaigns
                or otherwise interact with us, we may ask for information such as your name, email
                address, phone number, street address, usernames and passwords, feedback,
                information relating to your devices, age, gender, and language etc.,We also
                maintain records of your consents, preferences and settings relating to, for
                example, location data, marketing and sharing of personal data.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>Camera and Gallery Access</CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                Some of our services require us to collect images, audio, video, file and digital
                information from your device's camera and gallery. You won't be able to share photos
                of prescriptions or medical reports with the doctor if we don't have access. Only
                your doctor has access to them, besides you.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>
                WHY DO WE PROCESS PERSONAL DATA?
              </CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                uno.care may process your personal data for the following purposes. One or more
                purposes may apply simultaneously.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>
                Providing products and services
              </CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                We may use your personal data to provide you with our products and services, to
                process your requests or as otherwise may be necessary to perform the contract
                between you and uno.care, to ensure the functionality and security of our products
                and services, to identify you as well as to prevent and investigate fraud and other
                misuses.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>Accounts</CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                Some services may require an account to help you manage your content and
                preferences. Depending on the service, an account creation may be either required or
                voluntary. Account creation requires you to provide us with basic contact details
                about yourself, such as name, email address, country of residence and date of birth.
                You may also be able to voluntarily provide more information about yourself while
                creating a profile, such as a photo or avatar of your choice.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>
                Developing and managing products and services
              </CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                We may use your personal data to develop and manage our products, services, customer
                care, sales and marketing. We may combine personal data collected in connection with
                your use of a particular uno.care product and/or service with other personal data we
                may have about you, unless such personal data was collected for a purpose, where the
                original purpose is incompatible with this purpose.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>Communicating with you</CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                We may use your personal data to communicate with you, for example to inform you
                that our services have changed or to send you critical alerts and other such notices
                relating to our products and/or services and to contact you for customer care
                related purposes.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>
                Marketing, advertising and making recommendations
              </CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                We may contact you to inform you of new products, services or promotions we may
                offer and to conduct market research when we have your consent or it is otherwise
                allowed. We may use your personal data to personalise our offering and to provide
                you with more relevant services, for example, to make recommendations and to display
                customised content and advertising in our services. This may include displaying
                uno.care and third party content.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>
                What is our legal basis for processing your Personal Data?
              </CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                Our legal basis for processing your personal data is dependent on the purpose for
                processing and may vary as described in the Supplement applicable to the product or
                service you are using. In general, we process your personal data under the following
                legal bases:
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>
                Performance of a contract with you
              </CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                We process your personal data to perform our obligations under the Service Terms
                applicable to the product or service you are using, provided by us or our customers.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>Legal obligations</CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                We process your personal data as needed to comply with laws and regulations.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>Legitimate interests</CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                We process your personal data to further our legitimate interests, such as in
                connection with managing, developing, testing, securing, and in limited
                circumstances marketing, advertising, and making recommendations regarding our
                products and services. Any such processing is conducted subject to appropriate
                measures to protect your fundamental rights and freedoms related to your personal
                data, and in any event will be subject to the restrictions provided in this Policy.
                Further information or specification of our legitimate interests may be provided in
                relevant Supplements applicable to the product or service.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>
                How long do we retain Personal Data?
              </CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                We endeavour to only collect personal data that are necessary for the purposes for
                which they are collected, and to retain such data for no longer than is necessary
                for such purposes. The length of time personal data is retained, and criteria for
                determining that time, are dependent on the nature of the personal data and the
                purpose for which it was provided. For example, your personal data related to
                managing your account (such as name, email address, and account content and
                preferences) are maintained for as long as they are retained by you within your
                account. You may contact the uno.care Privacy Officer at privacy@uno.care to obtain
                additional information about retention of your personal data.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>Do we share Personal Data?</CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                We do not sell, lease, rent or otherwise disclose your personal data to third
                parties unless otherwise stated below.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>
                Uno.care companies and authorised third parties
              </CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                We may share your personal data with other uno.care companies or authorised third
                parties who process personal data for uno.care for the purposes described in this
                Policy. This may include for example billing through your network service provider
                or otherwise, delivery of your purchases, providing services including customer
                service, managing and analysing consumer data, conducting research and managing
                marketing and other such campaigns. When you purchase a uno.care product from us
                with a network service provider plan, we may need to exchange information with your
                network service provider to provide you with such service.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                These authorised third parties are not permitted to use your personal data for any
                other purposes. We bind them contractually, require them to act consistently with
                this Policy and to use appropriate security measures to protect your personal data.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>Mandatory disclosures</CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                We may be obligated by mandatory law to disclose your personal data to certain
                authorities or other third parties, for example, to law enforcement agencies in the
                countries where we or third parties acting on our behalf operate. We may also
                disclose and otherwise process your personal data in accordance with applicable law
                to defend uno.care’s legitimate interests, for example, in legal proceedings or in
                connection with governmental requests and filings.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>Mergers and Acquisitions</CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                If we decide to sell, buy, merge or otherwise reorganise our businesses in certain
                countries, this may involve us disclosing personal data to prospective or actual
                purchasers and their advisers, or receiving personal data from sellers and their
                advisers.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>
                How do we address Data Quality?
              </CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                We take reasonable steps to keep the personal data we possess accurate and to delete
                incorrect or unnecessary personal data. We encourage you to access your personal
                data through your account from time to time to ensure that it is up to date.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>
                What steps are taken to safeguard Personal Data?
              </CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                Privacy and security are key considerations in the creation and delivery of our
                products and services. We have assigned specific responsibilities to address privacy
                and security related matters. We enforce our internal policies and guidelines
                through an appropriate selection of activities, including proactive and reactive
                risk management, security and privacy engineering, training and assessments. We take
                appropriate steps to address online security, physical security, risk of data loss
                and other such risks taking into consideration the risk represented by the
                processing and the nature of the data being protected. Also, we limit access to our
                databases containing personal data to authorised persons having a justified need to
                access such information.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>What are your rights?</CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                You have a right to know what personal data we hold about you, and to You may
                exercise your rights by contacting us. In some cases, especially if you wish us to
                delete or stop processing your personal data, this may also mean that we may not be
                able to continue to provide the services to you. Applicable data protection law may
                provide certain restrictions on the extent to which these rights may be exercised.
                If a restriction applies, we will respond to your request with an explanation of
                what action will be taken, to the extent required under applicable data protection
                law.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>Data Security</CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                You agree and accept that your Information may be stored in third-party cloud
                service infrastructure providers. While all reasonable attempts have been taken from
                our end to ensure the safe and secure storage of your data, we shall not be liable
                for any data breach on the part of the third-party cloud service infrastructure
                provider that was beyond our control. In addition to the security measures put in
                place by the third-party closure provider for safe and secure storage of your
                Information, we use certain physical, md service infrastructanagerial, technical, or
                operational safeguards as per industry standards and established best practices to
                protect the Information we collect. We use reasonable security practices and
                procedures and use secure servers as mandated under applicable laws for the
                protection of your Information. We review our information collection, storage, and
                processing practices, including physical security measures to guard against
                unauthorised access to systems. However, as effective as these measures are, no
                security system is impenetrable. We cannot guarantee the security of our database,
                nor can we guarantee that the information you supply will not be intercepted while
                being transmitted to us over the internet. You accept the inherent security
                implications of data transmission over the internet and the internet cannot always
                be guaranteed as completely secure.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>
                Who is the controller of your Personal Data?
              </CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                Uno.care (MYSTICDOC HEALTHCARE PRIVATE LIMITED ,Radha Rukmani Garden, Ground Floor,
                253, shri krishna avenue phase 1, lombodi khandwa road indore, Radio Colony, Indore,
                Indore, Madhya Pradesh, 452001 ) is the controller of your personal data. In
                addition, the uno.care affiliate providing the product or service may be a
                controller of your personal data. You may find the identity of the controller and
                its contact details by reviewing the terms and conditions of such a product or
                service or by using contact information provided in the applicable uno.care
                websites.
              </Typography>
            </Grid>

            <Grid item lg={12}>
              <Typography>
                In matters pertaining to uno.care’s privacy practices you may contact our Data
                Protection Officer, at:privacy@uno.care
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>Grievance Redressal Mechanism</CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                In furtherance of the Information Technology Act, 2000 (“IT Act”) and the
                Information Technology (Intermediary Guidelines and Digital Media Ethics Code)
                Rules, 2021 (“Intermediary Guidelines”) a grievance officer is appointed to ensure
                compliance with the IT Act and the Intermediary guidelines.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                Any discrepancies or grievances with regard to content and or comment or breach of
                the Terms of Service shall be taken up with the designated grievance officer as
                mentioned below in writing or through email signed with the electronic signature to:
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <Typography>Attention: Vardhan Srivastava</Typography>
            </Grid>
            <Grid item lg={12}>
              <Typography>Email ID: privacy@uno.care</Typography>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                The grievance officer shall revert to every complaint within 24 hours of receipt of
                the complaint. Further, the Company shall take the best possible efforts to redress
                the complaint within 15 days of receipt of the complaint. Any suggestions by the
                Company regarding the use of the Services shall not be construed as a warranty.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                Please feel free to reach out to us by e-mail at privacy@uno.care in case of any
                concerns, grievances, or questions relating to our privacy or data related practices
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                By continuing to use uno.care, you consent to the use of cookies as described above.
                However, you can control and manage cookies, as most web browsers allow you to
                adjust your cookie preferences through their settings. Please note that disabling
                certain cookies may impact the functionality and performance of uno.care website.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <CustomTypographySubHeading>
                Changes to This Privacy Statement
              </CustomTypographySubHeading>
            </Grid>
            <Grid item lg={12}>
              <Typography>
                We reserve the right to change or update this Privacy Statement at any time. Changes
                to the Privacy Statement will be posted on this website and links to the Privacy
                Statement will indicate that the statement has been changed or updated. We encourage
                you to periodically review this Privacy Statement for any changes. For new users,
                changes or updates are effective upon posting. For existing users, changes or
                updates are effective 30 days after posting.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Fragment>
  );
};

export default PrivacyDetails;
