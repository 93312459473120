import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Popover,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  forwardRef,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { BASE_URL } from "../../../assets/constantsFile";
import {
  prescriptionContext,
  userContext,
} from "../../../common/context/usercontext";
import AddPrescriptionTemplateForDoc from "./prescriptionForms/addPrescriptionTemplateForDoc";
import MuiAlert from "@mui/material/Alert";
import { saveData } from "../../../services/api/postApi";
import { getData } from "../../../services/api/getApi";
import ViewCurrentPrescriptionsForm from "./prescriptionForms/viewCurrentPrescriptionsForm";
import PreviousPrescriptions from "./previousPrescriptions";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  button: {
    height: 40,
    width: 110,
    fontSize: 13,
    background: "#127DDD",
    borderRadius: "15px",
    lineHeight: "14px",
    color: "#fff",
    fontWeight: 600,
    "&:hover": {
      background: "#127DDD",
    },
  },
  buttonWhite: {
    height: 40,
    width: 110,
    fontSize: 13,
    background: "#fff",
    border: "1px solid #127DDD",
    borderRadius: "15px",
    lineHeight: "14px",
    color: "#127DDD",
    fontWeight: 600,
    "&:hover": {
      background: "#fff",
    },
  },
};

const CurrentPrescription = (props) => {
  const prescriptionContextData = useContext(prescriptionContext);
  console.log({ prescriptionContextData: prescriptionContextData });
  let patientDetails = "";
  let prescriptions = [];
  let appointments = [];
  let prescriptionVM = null;
  let oldPrescriptions = [];
  if (props.patientDetails) {
    patientDetails = props.patientDetails;
    prescriptions = patientDetails.prescriptions;
    appointments = patientDetails.appointments;
    prescriptionVM = appointments ? appointments[0].prescriptionVM : null;

    oldPrescriptions = prescriptions
      ? prescriptions.filter(
          (item) => item.appointmentId !== props.appointmentId
        )
      : [];
  }

  console.log({ patientDetails: patientDetails });

  const contextData = useContext(userContext);

  const [openNotice, setOpenNotice] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");

  const [isEdit, setIsEdit] = useState(false);
  const [isNewPrescription, setIsNewPrescription] = useState(true);
  const [isNewFiledAdded, setIsNewFiledAdded] = useState(false);

  const [templateName, setTemplateName] = useState("");

  const [prescriptionTemplateList, setPrescriptionTemplateList] = useState([]);
  const [medicineList, setMedicineList] = useState([]);
  const [currentPrescription, setCurrentPrescription] = useState(null);
  const [prescriptionId, setPrescriptionId] = useState(null);

  useEffect(() => {
    if (
      prescriptionVM &&
      prescriptionVM.medicines &&
      prescriptionContextData.prescriptionEditMode
    ) {
      setIsNewPrescription(false);
      setCurrentPrescription(prescriptionVM);
      setMedicineList(prescriptionVM.medicines ? prescriptionVM.medicines : []);
      setPrescriptionId(prescriptionVM.prescriptionId);
      prescriptionContextData.prescriptionVM = prescriptionVM;
      prescriptionContextData.prescriptionId = prescriptionVM.prescriptionId;

      const tranfromedMeds = prescriptionVM.medicines
        ? prescriptionVM.medicines.map((value) => {
            return {
              medicineName: value.medicineName,
              dosage: value.dosage,
              m: value.m,
              a: value.a,
              e: value.e,
              n: value.n,
              comment: {
                id: null,
                instruction: value.comment,
                instructionVernacular: value.commentVernacular,
              },
              duration: value.duration,
              durationString: value.durationString,
              frequencyString: {
                id: null,
                frequency: value.frequencyString,
                frequencyVernacular: value.frequencyStringVernacular,
              },
              timing: value.timing,
            };
          })
        : [];

      prescriptionContextData.prescriptionData = tranfromedMeds;
      setAddNewPres(
        <ViewCurrentPrescriptionsForm medicines={prescriptionVM.medicines} />
      );
      // prescriptionContextData.prescriptionEditMode = true;
    }
  }, [prescriptionVM]);

  const [lastRx, setLastRx] = useState(
    oldPrescriptions && oldPrescriptions.length > 0 ? oldPrescriptions[0] : null
  );
  //manage success/error message state
  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };

  //manage popover state
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const tName = useRef();
  console.log({ tName: tName });

  // save presciptions main call function
  const addNewPrescriptionHandler = async (data) => {
    if (data && data.length > 0) {
      const transformedData = data.map((value, i) => {
        return {
          medicineName: value.medicineName,
          dosage: value.dosage,
          m: value.m,
          a: value.a,
          e: value.e,
          n: value.n,

          duration: value.duration,
          durationString: value.durationString,

          frequencyString: value.frequencyString
            ? value.frequencyString.frequency
            : "",
          frequencyStringVernacular: value.frequencyString
            ? value.frequencyString.frequencyVernacular
            : "",
          comment: value.comment ? value.comment.instruction : "",
          commentVernacular: value.comment
            ? value.comment.instructionVernacular
            : "",

          timing:
            (value.m ? "Morning" : "") +
            (value.m && (value.a || value.e || value.n) ? "-" : "") +
            (value.a ? "Afternoon" : "") +
            (value.a && (value.e || value.n) ? "-" : "") +
            (value.e ? "Evening" : "") +
            (value.e && value.n ? "-" : "") +
            (value.n ? "Night" : ""),
        };
      });

      console.log({ transformedData: transformedData });
      let isValidated = false;

      isValidated = transformedData.some(validateFields);
      console.log({ validation: isValidated });

      if (!isValidated) {
        let lTname = "";
        if (tName.current) {
          lTname = tName.current.value;
        }
        setMedicineList(transformedData);
        console.log({ newPrescriptions_templateName: lTname });

        if (lTname) {
          const prescriptionAsTemplate = {
            docId: localStorage.getItem("DOCID"),
            templateName: lTname,
            medicines: transformedData,
          };
          saveAsPrescriptionTemplate(prescriptionAsTemplate);
        }

        const prescription = {
          docId: localStorage.getItem("DOCID"),
          patientId: props.patientId,
          medicines: transformedData,
          appointmentId: props.appointmentId,
          prescriptionId: prescriptionId,
          advice: prescriptionVM ? prescriptionVM.advice : "",
        };

        savePrescription(prescription);
        prescriptionContextData.prescriptionEditMode = true;

        setAddNewPres(
          <ViewCurrentPrescriptionsForm medicines={transformedData} />
        );
      } else {
        setSeverity("error");
        setMessage("Fields can not be empty!");
        setOpenNotice(true);
      }
    }
  };

  // save pres as template call to function
  const saveAsPrescriptionTemplate = async (p) => {
    setIsNewPrescription(false);
    const response = await saveData(
      BASE_URL + "doctor/prescription/template",
      p,
      props.authHeader
    );

    if (response.error) {
      setIsNewPrescription(true);
      setSeverity("error");
      setMessage("prescription save as template failed!");
      setOpenNotice(true);
    } else {
      console.log({ "prescription saved as template": response.data });
      setSeverity("success");
      setMessage("prescription as template  saved successfully!");
      setOpenNotice(true);
    }
  };

  // save pres call
  const savePrescription = async (p) => {
    const response = await saveData(
      BASE_URL + "doctor/prescription",
      p,
      props.authHeader
    );

    if (response.error) {
      setSeverity("error");
      setMessage("prescription save failed!");
      setOpenNotice(true);
    } else {
      setSeverity("success");
      setMessage("prescription saved successfully!");
      setOpenNotice(true);
    }
  };

  //edit pres

  const editMyPrescription = () => {
    prescriptionContextData.prescriptionEditMode = false;

    const tranfromedMeds = medicineList.map((value) => {
      return {
        medicineName: value.medicineName,
        dosage: value.dosage,
        m: value.m,
        a: value.a,
        e: value.e,
        n: value.n,
        comment: {
          id: null,
          instruction: value.comment,
          instructionVernacular: value.commentVernacular,
        },
        duration: value.duration,
        durationString: value.durationString,
        frequencyString: {
          id: null,
          frequency: value.frequencyString,
          frequencyVernacular: value.frequencyStringVernacular,
        },
        timing: value.timing,
      };
    });
    prescriptionContextData.prescriptionData = tranfromedMeds;
    setAddNewPres(
      <AddPrescriptionTemplateForDoc
        onAddPrescriptionTemplate={addNewPrescriptionHandler}
        prescriptionTemplateList={prescriptionTemplateList}
        medicineList={medicineList}
        lastRx={lastRx}
      />
    );
  };

  //fetch template list

  const fetchPrescriptionsHandler = useCallback(async () => {
    const response = await getData(
      BASE_URL +
        "doctor/prescription/template/" +
        localStorage.getItem("DOCID"),
      props.authHeader
    );

    if (response.error) {
    } else {
      const data = await response.data;
      setPrescriptionTemplateList(data);
    }
  }, []);

  useEffect(() => {
    fetchPrescriptionsHandler();
  }, [fetchPrescriptionsHandler]);

  const cancleEdit = () => {
    prescriptionContextData.prescriptionData = medicineList;
    prescriptionContextData.prescriptionEditMode = true;
    setIsNewPrescription(false);
    setAddNewPres(<ViewCurrentPrescriptionsForm medicines={medicineList} />);
  };

  const [addNewPres, setAddNewPres] = useState(
    <AddPrescriptionTemplateForDoc
      onAddPrescriptionTemplate={addNewPrescriptionHandler}
      prescriptionTemplateList={prescriptionTemplateList}
      medicineList={medicineList}
      lastRx={lastRx}
    />
  );

  const validateFields = (data) => {
    let validate = 0;
    data.medicineName ? (validate = 1) : (validate = 0);
    return validate === 0;
  };

  console.log({
    oldPrescriptions: oldPrescriptions,
  });
  console.log({
    lastRx: lastRx,
  });

  return (
    <Fragment>
      <Snackbar
        open={openNotice}
        autoHideDuration={6000}
        onClose={handleCloseNotice}
      >
        <Alert
          onClose={handleCloseNotice}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Box>
        <Grid container rowSpacing={1}>
          <Grid item lg={12}>
            <Box>
              {addNewPres}

              <Stack
                direction="row"
                spacing={2}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: "100%",
                  mt: 1,
                  background: "#fff",
                  border: "none",
                }}
              >
                {prescriptionContextData.prescriptionEditMode &&
                !isNewPrescription ? (
                  <Fragment>
                    <Stack direction="row">
                      <Button
                        size="small"
                        variant="contained"
                        sx={style.button}
                        onClick={editMyPrescription}
                      >
                        Edit
                      </Button>
                    </Stack>
                  </Fragment>
                ) : (
                  <Fragment>
                    {!isEdit && (
                      <Button
                        size="small"
                        variant="contained"
                        sx={style.buttonWhite}
                        onClick={cancleEdit}
                      >
                        Cancel
                      </Button>
                    )}

                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Box
                        component={Stack}
                        sx={{
                          width: 280,
                          p: 2,
                          background: "#9AE7EB",
                        }}
                      >
                        <Paper
                          component={Stack}
                          justifyContent="center"
                          elevation={0}
                          sx={{ p: 1 }}
                        >
                          <TextField
                            size="small"
                            fullWidth
                            inputRef={tName}
                            label="Enter Template Name Here..."
                            value={templateName}
                            onChange={(e) => {
                              setTemplateName(e.target.value);
                            }}
                          />
                        </Paper>

                        <Paper
                          component={Stack}
                          direction="row"
                          spacing={2}
                          justifyContent="center"
                          sx={{ p: 1 }}
                          elevation={0}
                        >
                          <Button
                            fullWidth
                            size="small"
                            variant="contained"
                            sx={style.butttonWhite}
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                          <Button
                            fullWidth
                            size="small"
                            variant="contained"
                            sx={style.button}
                            onClick={handleClose}
                            autoFocus
                            form="myForm1"
                            type="submit"
                          >
                            Save
                          </Button>
                        </Paper>
                      </Box>
                    </Popover>

                    <Button
                      size="small"
                      variant="contained"
                      sx={style.button}
                      form="myForm1"
                      type="submit"
                    >
                      Save
                    </Button>
                  </Fragment>
                )}
              </Stack>
            </Box>
          </Grid>
        </Grid>
        {prescriptionContextData.prescriptionEditMode && (
          <PreviousPrescriptions
            patientDetails={props.patientDetails}
            oldPrescriptions={oldPrescriptions}
          />
        )}
      </Box>
    </Fragment>
  );
};

export default CurrentPrescription;
