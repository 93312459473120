import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import DoctorMobile from "../../../../../assets/images/DoctorMobile.png";
import AboutUsDocIcon from "../../../../../assets/images/AboutUsDocIcon.png";
import BackgroundImage from "../../../../../assets/images/BackgroundImage.png";
import { Fragment } from "react";

const AboutUsMobile = () => {
  return (
    <Fragment>
      <Box sx={{ textAlign: "right" }}>
        <Typography
          sx={{
            color: "#127DDD",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "normal",
            textTransform: "uppercase",
          }}>
          About Us
        </Typography>
        <Typography
          sx={{
            color: "#000000",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "normal",
            textTransform: "uppercase",
          }}>
          Why UNO.care?
        </Typography>
      </Box>
      <Grid container sx={{ marginBlock: "20px" }}>
        <Grid item xs={12} lg={12}>
          <Box>
            {/* <Box
              component="img"
              src={BackgroundImage}
              style={{ height: "100%", width: "100%", position: "relative", rotate: "-10deg" }}
            /> */}
            <Box textAlign="center">
              {/* <Box textAlign="center" sx={{ marginTop: "-230px" }}> */}
              <Box
                component="img"
                src={DoctorMobile}
                style={{
                  height: "100%",
                  width: "90%",
                  position: "relative",
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Typography
            sx={{
              color: "#404040",
              textAlign: "justify",
              fontSize: "13px",
              fontWeight: "400",
              lineHeight: "normal",
              textTransform: "capitalize",
              marginBlock: "20px",
            }}>
            At UNO.CARE we ensure best medical practices, best doctors, best pathologies at
            affordable prices.
            <br />
            <br />
            Our aim is to simplify a patient's journey to recovery. To achieve this, we have set-up
            labs with the best lab equipments, stores with all kinds of medicines, clinics with
            best-in industry doctors along with trained staff to assist patients.
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Button variant="outlined" sx={{ borderRadius: "10px" }}>
            <Typography
              sx={{
                color: "#127DDD",
                textAlign: "center",
                fontSize: "13px",
                fontWeight: "400",
                lineHeight: "normal",
              }}>
              Know More
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AboutUsMobile;
