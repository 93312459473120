import RootTemplate from "../../global/templates/rootTemplate";
import OHCStatusTrackerMain from "../../modules/ohc/statusTracker/ohcStatusTrackerMain";

const OHCStatusTrackerIndex = () => {
  return (
    <RootTemplate>
      <OHCStatusTrackerMain />
    </RootTemplate>
  );
};

export default OHCStatusTrackerIndex;
