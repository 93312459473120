import { Box, Grid } from "@mui/material";
import { Fragment, useState } from "react";
import RaiseTicketForm from "../comps/raiseTicketForm";
import CustomTitles from "../../../global/titles/customTitles";
import { BASE_URL } from "../../../../assets/constantsFile";
import { useSnackbar } from "notistack";
import { saveData } from "../../../../services/api/postApi";
import { useNavigate } from "react-router-dom";

const HealthServiceMain = ({
  corpId = localStorage.getItem("CORPID"),
  raisedById = localStorage.getItem("ORGUSERIDAUTH"),
  raisedByMobileNo = localStorage.getItem("ORGUSERMOBILE"),
  raisedBy = localStorage.getItem("ORGUSERNAME"),
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleRaiseTicket = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url = BASE_URL + "org/v2/raiseTicket";

    const payload = {
      empId: formValues.empId,
      name: formValues.name,
      mobile: formValues.mobile,
      age: formValues.age,
      gender: formValues.gender,
      remarks: formValues.remark,
      status: "TICKET_RAISED",
      ticketType: "HEALTH_TICKET",
      ticketMode: "WEB",
      ticketCategory: "CORP",
      corpId: corpId,
      raisedBy: raisedBy,
      raisedById: raisedById,
      raisedByMobileNo: raisedByMobileNo,
    };

    const response = await saveData(url, payload, "");

    if (response.error) {
      setIsLoading(false);

      enqueueSnackbar("An error Occurred!", {
        variant: "error",
      });
    } else {
      setFormValues({});
      setIsLoading(false);
      enqueueSnackbar("Saved Successfully!", {
        variant: "success",
      });
      navigate("/corporatehome/tickets/dashboard");
    }
  };
  return (
    <Fragment>
      <Box>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CustomTitles title="Health Service" />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <RaiseTicketForm
              formValues={formValues}
              setFormValues={setFormValues}
              handleRaiseTicket={handleRaiseTicket}
              ticketType="HEALTH_TICKET"
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default HealthServiceMain;
