import { Box, Button, Stack, TextField } from "@mui/material";
import { Fragment, useContext, useState } from "react";
import { BASE_URL } from "../../assets/constantsFile";
import { saveData } from "../../services/api/postApi";
import { ChatContext } from "../../common/context/usercontext";
const defaultText = "Thank you for your query. we will shorlty connect with you! 👋";

const EmailComp = ({
  corpId = localStorage.getItem("CORPID"),
  userID = localStorage.getItem("ORGUSERIDAUTH"),
}) => {
  const contextData = useContext(ChatContext);

  let { chatStack, setChatStack } = contextData;
  const [emailText, setEmailText] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(true);

  const sendMailHandler = async () => {
    const url = BASE_URL + `org/logs/corpSupport`;

    const payload = {
      corpId: corpId || "872cd841-9f7a-432d-b8e9-422b780bca10",
      loggedUserId: userID || 345,
      raisedQuery: emailText,
      corpLogsType: "EMAIL",
    };

    const response = await saveData(url, payload, "");

    if (response.error) {
      console.log({ error: response.error });
      let bot = {
        id: 1,
        chat: "Failed to send. Try again later.",
      };

      let newChatStack = [...chatStack, ...[{ bot: bot }]];
      setChatStack(newChatStack);
      setDisableSubmit(false);
    } else {
      console.log({ success: response.data });
      setDisableSubmit(true);
      let bot = {
        id: 1,
        chat: defaultText,
      };

      let newChatStack = [...chatStack, ...[{ bot: bot }]];
      setChatStack(newChatStack);
    }
  };
  return (
    <Fragment>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: 200 }}>
        <Stack flex={1} spacing={2}>
          <TextField
            sx={{
              "& fieldset": { border: "none" },
            }}
            fullWidth
            placeholder="Write your query here..."
            multiline
            rows={4}
            //maxRows={10}
            value={emailText}
            onChange={(e) => {
              setDisableSubmit(e.target.value ? false : true);
              setEmailText(e.target.value);
            }}
          />
          <Button variant="contained" onClick={sendMailHandler} disabled={disableSubmit}>
            Sumbit
          </Button>
        </Stack>
      </Box>
    </Fragment>
  );
};

export default EmailComp;
