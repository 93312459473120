import React from "react";
import { makeStyles } from "@mui/styles";
import {
  PieChart,
  Pie,
  Cell,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Portal,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import MaskingButton from "../../../../global/components/maskingButton";
import StatusTrackerDetailGrid from "../../../../global/customDatagrids/statusTrackerDetailGrid";
import DownloadCSV from "../../../../global/components/downloadCSV";

const useStyles = makeStyles((theme) => ({
  legend: {
    marginLeft: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  legendItem: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  colorSwatch: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1),
    backgroundColor: "#ccc",
    borderRadius: "50%",
  },
  label: {
    fontSize: theme.typography.fontSizeMedium,
    width: "100px",
  },
  value: {
    fontSize: theme.typography.fontSizeSmall,
    marginLeft: theme.spacing(1),
  },
}));

// const COLORS = ;

const CustomLegend = ({ payload, COLORS, labelWidth, isMobile }) => {
  const classes = useStyles();

  if (isMobile) {
    return null; // Don't render the legend in mobile view
  }

  return (
    <Box className={classes.legend}>
      {payload.map((item, index) => (
        <div key={item.name} className={classes.legendItem}>
          <Box sx={{ display: "flex" }}>
            <Box
              className={classes.colorSwatch}
              style={{ backgroundColor: COLORS[index] }}
            />
            <Typography
              variant="body2"
              className={classes.label}
              style={{ width: labelWidth }}
            >
              {item.legendLabel || item.name}
            </Typography>
          </Box>
          <Typography variant="body2" className={classes.value}>
            {item.value}%
          </Typography>
        </div>
      ))}
    </Box>
  );
};

const CustomTooltipContent = ({ active, payload, label }) => {
  if (active) {
    const data = payload[0].payload; // Get the data of the hovered bar

    return (
      <Box sx={{ backgroundColor: "#FFFFFF", padding: "10px" }}>
        <Typography>{`${data.name}`}</Typography>
        <Typography>{`Percentage: ${data.value}`}</Typography>
        <Typography>{`Count: ${data.count}`}</Typography>
      </Box>
    );
  }

  return null;
};

const CustomDoughnutChartNew = ({
  data,
  title,
  dialogData,
  labelHeading,
  COLORS,
  selectedDepartment,
  labelWidth,
  disabled = false,
  shaktipumpCheck,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  // console.log({ dialogData });
  const [apiRef, setApiRef] = useState(null);
  const exportCSV = () => {
    apiRef.current.exportDataAsCsv();
  };

  const downloadCSV = () => {
    const csvContent = Object.entries(dialogData)
      .map(
        ([category, data]) =>
          `${category}\n` +
          data
            .map(
              (entry) =>
                `${entry.emp_id},${entry.name},${entry.department},${entry.data},${entry.parameter}`
            )
            .join("\n")
      )
      .join("\n\n");

    const csvData = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    const downloadLink = document.createElement("a");
    downloadLink.setAttribute("href", csvData);
    downloadLink.setAttribute("download", "dialog_data.csv");
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  const [open, setOpen] = useState(false);
  const [selectedChart, setSelectedChart] = useState("");
  const [selectedBarData, setSelectedBarData] = useState([]);
  const handleOpen = (title) => {
    setOpen(true);
    setSelectedChart(title);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedChart("");
    setSelectedBarData([]);
  };

  useEffect(() => {
    if (dialogData) {
      let data = dialogData[selectedChart];
      const tempData = data?.filter((obj) =>
        selectedDepartment === null ||
        selectedDepartment === "" ||
        selectedDepartment === undefined
          ? true
          : obj.department === selectedDepartment
      );
      // const tempData = data?.filter((obj) => obj.department === selectedDepartment);
      console.log({ SELECTED_DIALOG_DATA: data });
      console.log({ SELECTED_DEPARTMENTDATA: tempData });
      console.log({ SELECTED_CHART: selectedChart });
      console.log({ selectedDepartment });
      if (tempData) {
        setSelectedBarData(tempData);
      } else {
        console.log("Selected chart not found in dialogData");
      }
    }
  }, [selectedChart, selectedDepartment]);

  // console.log({ selectedBarData });
  console.log({ dialogData });

  const total = shaktipumpCheck
    ? 458
    : data?.reduce((acc, entry) => acc + entry.count, 0);

  console.log({ selectedBarData, data });

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        border: "1px solid grey",
        height: isMobile ? "460px" : "300px",
        paddingInline: "10px",
        paddingBlock: "20px",
        borderRadius: "10px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          textAlign="left"
          sx={{
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "120%",
          }}
        >
          {title}
        </Typography>
        {dialogData === null ||
        dialogData === undefined ||
        dialogData.length < 1 ? null : (
          <Button
            onClick={() => {
              if (dialogData !== null || dialogData !== undefined) {
                downloadCSV();
              }
            }}
          >
            Export CSV
          </Button>
        )}
      </Box>
      <ResponsiveContainer width="100%" height="100%">
        {data.every((item) => item.value === 0) ? (
          <Box
            width={"100%"}
            height={250}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ textAlign: "center" }}>No Data Found</Typography>
          </Box>
        ) : (
          <PieChart width={"100%"} height={isMobile ? "100%" : 250}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={70}
              outerRadius={90}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
              legendType="none"
              onClick={(pie) => {
                // console.log({ pie });
                if (disabled === false) {
                  handleOpen(pie?.name);
                }
              }}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index]} />
              ))}
              <Label
                fontSize={"14px"}
                fontStyle={"normal"}
                fontWeight={"700"}
                colorProfile={"#16161D"}
                value={`${labelHeading}: ${total}`}
                position="center"
              />
            </Pie>
            <Tooltip content={<CustomTooltipContent />} />
            <Legend
              payload={data}
              COLORS={COLORS}
              labelWidth={labelWidth}
              content={CustomLegend}
              layout="vertical"
              width={isMobile && "100%"}
              align={isMobile ? "center" : "right"}
              verticalAlign={isMobile ? "bottom" : "middle"}
            />
          </PieChart>
        )}
      </ResponsiveContainer>

      <Portal>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={"xl"}
          fullWidth={true}
        >
          <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
            {title}
          </DialogTitle>
          <Typography
            variant="h6"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {selectedChart}
          </Typography>
          <Box sx={{ marginLeft: "22px" }}>
            <Stack direction="row" spacing={1}>
              <DownloadCSV exportCSV={exportCSV} type="ICON" />
              <MaskingButton type="ICON" />
            </Stack>
          </Box>
          <DialogActions sx={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
          <DialogContent>
            <StatusTrackerDetailGrid
              setApiRef={setApiRef}
              employeeList={selectedBarData}
            />
          </DialogContent>
        </Dialog>
      </Portal>
    </Box>
  );
};

export default CustomDoughnutChartNew;
