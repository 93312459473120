import { useTheme } from "@emotion/react";
import { Box, Button, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { Fragment, useState } from "react";
import bgWhatWeOffer from "../assets/bgWhatWeOffer.png";
import doc1 from "../assets/doc1.png";
import fullbody from "../assets/fullbody.png";
import whatWeOfferTitle from "../assets/whatWeOfferTitle.png";
import lab from "../assets/lab.png";
import labs from "../assets/labs.png";
import checkup from "../assets/checkup.png";
import advice from "../assets/advice.png";

import Handpick from "../assets/Handpick employee.png";
import SaveHours from "../assets/Save hours.png";
import SmartHealthRecommendations from "../assets/Smart Health Recommendations.png";
import Dashboards from "../assets/Dashboards.png";
import DataSecurity from "../assets/Data Security.png";

const WhatWeOffer = ({ scrollToSection }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const CardBox = ({ data }) => {
    return (
      <Box
        sx={{
          width: "380px",
          minHeight: 250,
          position: "relative",
          boxShadow: "0px 0px 15px 2px rgba(0, 0, 0, 0.10)",
          borderRadius: "16px",
          overflow: "hidden",
          backgroundColor: "#FFFFFF",
          px: isExtraSmallScreen || isSmallScreen ? 4 : 2,
          pt: 2,
          pb: 3,
          transition: "all 0.4s ease-in-out",
          "&:hover": {
            "& img": {
              width: "54px",
              height: "54px",
              transform: "rotate(-10deg)",
            },
          },
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <Typography
            sx={{
              color: "#000",
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: "110%",
            }}>
            {data?.title.split("\n").map((line, index) => (
              <Fragment key={index}>
                {line}
                <br />
              </Fragment>
            ))}
          </Typography>
          <Box
            sx={{
              height: "110px",
              width: "110px",
              backgroundColor: data.bgColor,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
            }}>
            <Box
              className="hover"
              component="img"
              src={data?.icon}
              alt="Doctor"
              sx={{
                width: "50px",
                height: "50px",
                position: "absolute",
                transition: "all 0.3s ease-in-out",
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}>
          <Typography
            sx={{
              mt: 2,
              color: "#000",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "160%",
              letterSpacing: "-0.304px",
            }}>
            {data?.description}
          </Typography>

          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
            }}>
            <Button
              onClick={() => scrollToSection("contactUs")}
              variant="contained"
              sx={{
                backgroundColor: "#3E48EE",
                ":hover": {
                  backgroundColor: "#3E48EE",
                },
                fontWeight: "bold",
                textTransform: "none",
                height: "29px",
                padding: "6px 12px",
              }}>
              <Typography
                sx={{
                  color: "#FFF",
                  textAlign: "center",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "120%",
                  letterSpacing: "-0.266px",
                }}>
                Know More
              </Typography>
            </Button>
          </Box> */}
        </Box>
      </Box>
    );
  };

  const temp = [
    {
      icon: Handpick,
      title: "Handpick\nemployee benefits\nyou want",
      description:
        "Choose ideal healthcare benefits\nfor your employees with a catalogue\nof choices.",
      bgColor: "#D7EBFF",
    },
    {
      icon: SaveHours,
      title: "Save hours with\nUNO.care’s digital\nhealth care platform",
      description:
        "No more paper work. Manage\nemployee benefits digitally, saving hours\nevery month.",
      bgColor: "#DAFFE9",
    },
    {
      icon: DataSecurity,
      title: "Data\nSecurity",
      description: "We understand your concern for\ncompany's health care data.",
      bgColor: "#DEF3FF",
    },
    {
      icon: labs,
      title: "Top Accredited\nLabs & Accurate\nReports",
      description: "With advance technology & expert\ntechnicians, we ensure precise\nresults.",
      bgColor: "#DEF3FF",
    },
    {
      icon: SmartHealthRecommendations,
      title: "Smart Health\nRecommendations",
      description: "Get expert advice from our network of\nhighly qualified professionals.",
      bgColor: "#DEF3FF",
    },
    {
      icon: Dashboards,
      title: "Employee/Employer\nDashboards",
      description: "Customized analytics as per your\nneed.",
      bgColor: "#ECEBFF",
    },
  ];
  return (
    <Fragment>
      <Box
        sx={{
          // height: isSmallScreen || isExtraSmallScreen ? null : "74vh",
          paddingBlock: isSmallScreen || isExtraSmallScreen ? "20px" : "35px",
          paddingLeft: isSmallScreen || isExtraSmallScreen ? null : "118px",
          paddingRight: isSmallScreen || isExtraSmallScreen ? null : "118px",
          background:
            "linear-gradient(rgba(248, 249, 255, 0.6) 50%,rgba(248, 249, 255, 0.6) 50%, rgba(218, 220, 255, 0.6) 100%), rgba(248, 249, 255, 0.6) 50%",
        }}>
        <Container maxWidth={false}>
          <Grid container columnSpacing={4}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                marginBlock: 3,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img src={whatWeOfferTitle} style={{ height: 30 }} />
            </Grid>

            {temp.map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                sx={{
                  marginTop: isSmallScreen || isExtraSmallScreen ? 2 : 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                }}>
                <CardBox data={item} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Fragment>
  );
};

export default WhatWeOffer;
