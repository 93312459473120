import { Box, Container, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import {
  CustomHeadingCenter1,
  CustomHeadingChipCenter,
  CustomHeadingChipLeft,
  CustomHeadingSubTitleCenter,
} from "../../global/headings/customHeadings";

const WhyUs = () => {
  return (
    <Fragment>
      <CustomHeadingChipCenter>Why Us</CustomHeadingChipCenter>
      <Typography
        sx={{
          textAlign: "center",
          fontFamily: "Roboto,sans-serif",
          fontSize: "40px",
          fontWeight: "600",
          lineHeight: "40px",
          marginBlock: "20px",
        }}>
        Our technology helps employers create better jobs, lives.
      </Typography>
      <Box>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <Box
                sx={{
                  backgroundColor: "#0463FA",
                  paddingBlock: "20px",
                  borderRadius: "10px",
                  padding: "20px",
                }}>
                <Typography
                  sx={{
                    textAlign: "justify",
                    fontFamily: "Roboto,sans-serif",
                    fontSize: "30px",
                    fontWeight: "600",
                    lineHeight: "40px",
                    color: "#FFFFFF",
                  }}>
                  Our Commitment to Corporate Services
                </Typography>
                <Typography
                  sx={{
                    textAlign: "justify",
                    fontFamily: "Roboto,sans-serif",
                    fontSize: "20px",
                    fontWeight: "400",
                    lineHeight: "40px",
                    color: "#FFFFFF",
                  }}>
                  At UNO.Care, we prioritise corporate services as a cornerstone of our mission. Our
                  commitment is to extend high-quality healthcare services to every corner,
                  democratising health and well-being across India while ensuring accessibility and
                  a􀈋ordability for all. We proudly serve both individual and institutional clients,
                  tailoring our solutions to meet diverse needs.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Box
                sx={{
                  backgroundColor: "#0463FA",
                  paddingBlock: "20px",
                  borderRadius: "10px",
                  padding: "20px",
                }}>
                <Typography
                  sx={{
                    textAlign: "justify",
                    fontFamily: "Roboto,sans-serif",
                    fontSize: "30px",
                    fontWeight: "600",
                    lineHeight: "40px",
                    color: "#FFFFFF",
                  }}>
                  Our Expanding Presence
                </Typography>
                <Typography
                  sx={{
                    textAlign: "justify",
                    fontFamily: "Roboto,sans-serif",
                    fontSize: "20px",
                    fontWeight: "400",
                    lineHeight: "40px",
                    color: "#FFFFFF",
                  }}>
                  We have already achieved significant milestones, having established five clinics
                  in towns across Madhya Pradesh and acquiring a prestigious multispeciality
                  hospital in Indore. On the corporate front, we have become trusted partners to
                  over 50 prestigious organisations in Madhya Pradesh's tier 2 towns, addressing
                  their statutory and routine healthcare requirements as well as those of their
                  valued employees.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Box
                sx={{
                  backgroundColor: "#0463FA",
                  paddingBlock: "20px",
                  borderRadius: "10px",
                  padding: "20px",
                }}>
                <Typography
                  sx={{
                    textAlign: "justify",
                    fontFamily: "Roboto,sans-serif",
                    fontSize: "30px",
                    fontWeight: "600",
                    lineHeight: "40px",
                    color: "#FFFFFF",
                  }}>
                  A Network of Excellence
                </Typography>
                <Typography
                  sx={{
                    textAlign: "justify",
                    fontFamily: "Roboto,sans-serif",
                    fontSize: "20px",
                    fontWeight: "400",
                    lineHeight: "40px",
                    color: "#FFFFFF",
                  }}>
                  Our strength lies in a robust network of highly qualified doctors and healthcare
                  professionals who share our vision. Leveraging our digital competence, we amplify
                  our reach and impact, ensuring that quality healthcare is not just a promise but a
                  tangible reality for all.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Fragment>
  );
};

export default WhyUs;
