import { Container, Grid } from "@mui/material";
import DetailIndex from "./consulationDetail/detailIndex";
import VideoIndex from "./videoCall/videoIndex";

const ConsultationIndex = ({ patient, patientDetails, buttonLabel }) => {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{ p: 2, backgroundColor: "#F5F5F5" }}
    >
      <Grid container columns={18} columnSpacing={1}>
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <VideoIndex patient={patient} patientDetails={patientDetails} />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <DetailIndex
            patient={patient}
            patientDetails={patientDetails}
            buttonLabel={buttonLabel}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ConsultationIndex;
