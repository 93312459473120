import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Link,
  Portal,
  Stack,
  Typography,
} from "@mui/material";
import { useRef, Fragment, useState } from "react";
import { useReactToPrint } from "react-to-print";
import PlaceHolder from "../../../../assets/images/samplePrescription.png";
import Morehorizontal from "../../../../assets/images/morehorizontalwhite.png";
import PDFViewer from "pdf-viewer-reactjs";

const Report = ({ data }) => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  console.log({ reportdata: data });
  return (
    <Fragment>
      <Box component={"iframe"} sx={{ width: 0, height: 0, display: "none" }}>
        <Box
          ref={componentRef}
          sx={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }}
        >
          {/* <PrintReportComponent patientDetails={data} /> */}
        </Box>
      </Box>
      <Box sx={{ height: "44vh" }}>
        <Grid container>
          <Grid item lg={12}>
            {data.reportURL && data.reportFileType ? (
              data.reportFileType === "IMAGE" ? (
                <img width="516" height="290" src={data.reportURL} />
              ) : (
                <img width="100%" src={PlaceHolder} />
              )
            ) : (
              <img width="100%" src={PlaceHolder} />
            )}

            <Box
              sx={{
                position: "relative",
                top: "-70px",
                background: "rgba(64, 64, 64, 0.95)",
                display: "flex",
                alignItems: "center",
                py: 1,
              }}
            >
              <Stack
                sx={{ width: "100%", px: 2 }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: 16,
                      color: "#FFFFFF",
                    }}
                  >
                    Report
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: 16,
                      color: "#FFFFFF",
                    }}
                  >
                    Date Created: 01-01-2023
                  </Typography>
                </Stack>

                <Box>
                  {data.reportURL ? (
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleClickOpen()}
                    >
                      <img height="36" width="36" src={Morehorizontal} />
                    </Box>
                  ) : (
                    <Box>
                      <img height="36" width="36" src={Morehorizontal} />
                    </Box>
                  )}
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Portal>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={"lg"}
          fullWidth={true}
        >
          <DialogContent>
            <Box display="flex" justifyContent="center" alignItems="center">
              {data.reportFileType &&
              data.reportFileType === "IMAGE" &&
              data.reportURL ? (
                <img src={data.reportURL} />
              ) : (
                <PDFViewer
                  hideNavbar
                  document={{
                    url: data.reportURL,
                  }}
                />
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </Portal>
    </Fragment>
  );
};

export default Report;
