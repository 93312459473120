import { Box, Typography } from "@mui/material";
import React from "react";
import CountUp from "react-countup";

const CounterCard = ({ count, title, description }) => {
  return (
    <Box p={2} textAlign="center" lineHeight={1}>
      <Typography variant="h1" color="#127DDD" textGradient>
        <CountUp end={count} duration={5} />
      </Typography>
      {title && (
        <Typography variant="h5" mt={2} mb={1}>
          {title}
        </Typography>
      )}
      {description && (
        <Typography variant="body2" color="text">
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default CounterCard;
