import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  ImageList,
  ImageListItem,
  Portal,
} from "@mui/material";
import { Fragment, useState } from "react";

const ViewImageComp = ({ imageList }) => {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(null);
  };
  return (
    <Fragment>
      <Grid item lg={12}>
        <ImageList sx={{ width: "100%", height: 200 }} cols={6} rowHeight={164}>
          {imageList.map((item, index) => (
            <Box
              key={index}
              sx={{
                p: 1,
                cursor: "pointer",
                height: 200,
                width: 200,
                border: 1,
                borderColor: "gray",
                borderRadius: 3,
              }}
              onClick={() => {
                setOpen(true);
                setSelectedValue(item.attachmentUrl);
              }}>
              <ImageListItem>
                <img
                  srcSet={`${item.attachmentUrl}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.attachmentUrl}?w=164&h=164&fit=crop&auto=format`}
                  alt={item.name}
                  loading="lazy"
                  style={{ objectFit: "contain", height: 150, width: 150 }}
                />
              </ImageListItem>
            </Box>
          ))}
        </ImageList>
      </Grid>

      <Portal>
        <Dialog fullWidth={true} maxWidth={false} open={open} onClose={handleClose}>
          <DialogContent>
            <img src={selectedValue} alt="image" width="100%" />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Portal>
    </Fragment>
  );
};

export default ViewImageComp;
