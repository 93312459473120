import { Box, Grid, Stack, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { getData } from "../../../../../services/api/getApi";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { getFormattedDDMonthYYYY } from "../../../../../assets/utils";
import { makeStyles } from "@mui/styles";
import MuiPagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";

// function CustomPagination() {
//   const apiRef = useGridApiContext();
//   const page = useGridSelector(apiRef, gridPageSelector);
//   const pageCount = useGridSelector(apiRef, gridPageCountSelector);

//   return (
//     <Stack spacing={2} display="flex"
//     justifyContent="space-between"
//     alignItems="center"
//     sx={{ flexGrow: 1 }}>
//     <Pagination
//       color="primary"
//       variant="outlined"
//       shape="rounded"
//       page={page + 1}
//       count={pageCount}
//       // @ts-expect-error
//       renderItem={(item) => (
//         <PaginationItem
//           slots={{
//             previous: () => (
//               <Box sx={{ display: 'flex', alignItems: 'center'}}>
//                 <ArrowBackIcon />
//                 <Typography sx={{ marginLeft: '10px' }}>Previous</Typography>
//               </Box>
//             ),
//             next: () => (
//               <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                 <Typography sx={{ marginRight: '10px' }}>Next</Typography>
//                 <ArrowForwardIcon />
//               </Box>
//             ),
//           }}
//           {...item}
//         />
//       )}
//       onChange={(event, value) => apiRef.current.setPage(value - 1)}
//     />
//     </Stack>
//   );
// }

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
      renderItem={(item) => (
        <PaginationItem
          type="last"
          slots={{
            previous: () => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ArrowBackIcon />
                <Typography sx={{ marginLeft: "10px" }}>Previous</Typography>
              </Box>
            ),
            next: () => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ marginRight: "10px" }}>Next</Typography>
                <ArrowForwardIcon />
              </Box>
            ),
          }}
          {...item}
        />
      )}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
    "& .paxton-table--header": {
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        padding: 0,
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 600,
        textTransform: "uppercase",
        fontSize: "16px",
      },
    },
    "& .paxton-table--row": {
      borderBottom: "0.5px solid #C5C5D3",
      margintop: 7,
      marginBottom: 7,
      backgroundColor: "#fff",
      paddingTop: 15,
      paddingBottom: 10,
      boxSizing: "content-box",
    },
    "& .MuiDataGrid-cell": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: 12,
      lineHeightt: 15,
      color: "##383838",
      textTransform: "capitalize",
      boxSizing: "none",
      borderBottom: "none ! important",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer": {
      border: "none",
    },
    "& .MuiDataGrid-viewport": {
      maxHeight: "unset !important",
    },
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "670px !important",
    },
    //'& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {display: 'none' }
  },
}));

const OPDDashboard = ({ corpId = localStorage.getItem("CORPID"), date }) => {
  console.log({ date });

  const [opdList, setOpdList] = useState({
    columns: [
      { field: "id", hide: false },
      { field: "name", headerName: "Name" },
    ],
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
        },
      },
    },
    rows: [{ id: 1, name: "" }],
  });
  const fetchLODData = async () => {
    const url =
      BASE_URL + `org/opd/${corpId}?date=${date.toISOString().split("T")[0]}`;

    const opd = await getData(url, "");

    if (opd.error) {
    } else {
      const columns = [
        { field: "id", hide: false },
        {
          field: "name",
          headerName: "Name",
          width: 300,
        },
        {
          field: "empId",
          headerName: "Emp ID",
          headerAlign: "center",
          align: "center",
        },
        {
          field: "department",
          headerName: "Department",
          headerAlign: "center",
          align: "center",
        },
        {
          field: "date",
          headerName: "Date",
          width: 150,
          headerAlign: "center",
          align: "center",
        },
        {
          field: "bp",
          headerName: "BP",
          headerAlign: "center",
          align: "center",
        },
        {
          field: "sugar",
          headerName: "Sugar",
          headerAlign: "center",
          align: "center",
        },
        {
          field: "height",
          headerName: "Height",
          headerAlign: "center",
          align: "center",
        },
        {
          field: "weight",
          headerName: "Weight",
          headerAlign: "center",
          align: "center",
        },

        {
          field: "complaint",
          headerName: "Complaint",
          headerAlign: "center",
          align: "center",
        },
        {
          field: "medicine",
          headerName: "Medicine",
          headerAlign: "center",
          align: "center",
        },
        {
          field: "rest",
          headerName: "Rest",
          headerAlign: "center",
          align: "center",
        },
        {
          field: "fitToWork",
          headerName: "Fit to Work",
          headerAlign: "center",
          align: "center",
        },
        {
          field: "underMedication",
          headerName: "Under Medication",
          headerAlign: "center",
          align: "center",
        },
        {
          field: "ohcType",
          headerName: "Ohc Type",
          headerAlign: "center",
          align: "center",
        },
      ];

      const initialState = {
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      };

      const rows = opd.data
        .filter((item) => item.ohcType === "OPD")
        .map((item, index) => ({
          isActive: item.isActive,
          id: item.id,
          empId: item.empId,
          department: item.department,
          name: item.name,
          bp: item.bp,
          sugar: item.sugar,
          height: item.height,
          weight: item.weight,
          date: getFormattedDDMonthYYYY(item.date),
          corpId: item.corpId,
          empSystemId: item.empSystemId,
          rest: item.rest ? "Yes" : "No",
          underMedication: item.underMedication ? "Yes" : "No",
          fitToWork: item.fitToWork ? "Yes" : "No",
          complaint: item.complaint,
          medicine: item.medicine,
          ohcType: item.ohcType,
        }));

      const tableData = {
        columns: columns,
        initialState: initialState,
        rows: rows,
      };

      setOpdList(tableData);

      console.log({ opd: opd.data });
    }
  };

  useEffect(() => {
    fetchLODData();
  }, [date]);

  const classes = useStyles();

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item lg={12}>
          <Box
            style={{
              height: "75vh",
              width: "100%",
              padding: "20px",
              backgroundColor: "#FFFFFF",
              border: "0.5px solid grey",
              borderRadius: "16px",
              marginBlock: "20px",
            }}
          >
            <Box style={{ display: "flex", height: "100%" }}>
              <Box
                sx={{
                  flexGrow: 1,
                  width: "100%",
                }}
              >
                <DataGrid
                  sx={{ border: "none" }}
                  {...opdList}
                  rowHeight={24}
                  disableRowSelectionOnClick
                  className={classes.root}
                  getRowClassName={() => "paxton-table--row"}
                  getCellClassName={() => "paxton-table--cell"}
                  slots={{
                    pagination: CustomPagination,
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default OPDDashboard;
