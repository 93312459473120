import { Fragment, useEffect, useState } from "react";
import MainPageLayoutWithBackTitleLeft from "../../../global/templates/mainPageLayoutWithBackTitleLeft";
import { Button, Grid, Stack, TextField } from "@mui/material";
import DataTable from "./comps/DataTable";
import RaiseNewTicket from "./comps/RaiseNewTicket";
import { BASE_URL } from "../../../../assets/constantsFile";
import { getDataNew } from "../../../../services/api/getApi";
import CustomSelect from "../../../global/components/customSelect";

const InvoicesMain = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceListFiltered, setInvoiceListFiltered] = useState([]);

  const getInvoiceListByCorpId = async () => {
    const url = BASE_URL + `invoice/corp/getInvoice/${corpId}`;
    const res = await getDataNew(url);

    if (res.error) {
      setInvoiceList([]);
    } else {
      setInvoiceList(res.data);
    }
  };

  useEffect(() => {
    getInvoiceListByCorpId();
  }, []);

  const [serviceDetails, setServiceDetails] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");

  useEffect(() => {
    setInvoiceListFiltered(
      invoiceList.filter(
        (v) =>
          (serviceDetails
            ? v.serviceDetails?.toLowerCase().includes(serviceDetails?.toLowerCase())
            : true) && (paymentStatus ? v.paymentStatus === paymentStatus : true)
      )
    );
  }, [invoiceList, serviceDetails, paymentStatus]);
  return (
    <Fragment>
      <MainPageLayoutWithBackTitleLeft title="Invoices">
        <Grid container spacing={1}>
          <Grid item lg={12} display="flex" justifyContent="flex-end">
            <Stack direction="row" spacing={2}>
              <TextField
                size="small"
                label="Service Details"
                value={serviceDetails || ""}
                onChange={(e) => setServiceDetails(e.target.value)}
              />

              <CustomSelect
                value={paymentStatus}
                setvalue={setPaymentStatus}
                options={PaymentStatusList}
                height={40}
                width={220}
              />
              <RaiseNewTicket disabled={true} />
            </Stack>
          </Grid>
          <Grid item lg={12}>
            <DataTable data={invoiceListFiltered} />
          </Grid>
        </Grid>
      </MainPageLayoutWithBackTitleLeft>
    </Fragment>
  );
};

export default InvoicesMain;

const PaymentStatusList = [
  { label: "Full Payment Pending", value: "FULL_PAYMENT_PENDING" },
  { label: "Partial Payment Received", value: "PARTIAL_PAYMENT_RECEIVED" },
  { label: "Full Payment Received", value: "FULL_PAYMENT_RECEIVED" },
];
