import { Box, Grid } from "@mui/material";
import CustomTitles from "../../../global/titles/customTitles";
import { useEffect, useState } from "react";
import { getDataGridConfig } from "../../../global/services/corporateServices";
import PEHealthRecords from "./comps/peHealthRecords";
import NewRequestformMain from "../newRequestForm/newRequestformMain";

const PreEmpHealthRecordsMain = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [config, setConfig] = useState([]);

  useEffect(() => {
    getDataGridConfig(corpId, setConfig, "PRE_EMPLOYMENT_HEALTH_RECORDS_PAGE");
  }, []);

  console.log({
    config321: config,
  });

  // "Exception for Case Construction"
  if (corpId === "bbb269e5-b020-4257-ad8f-4da8c811801a") {
    corpId = "872cd841-9f7a-432d-b8e9-422b780bca10";
  }

  return (
    <Box>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="Health Records" />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <PEHealthRecords config={config} corpId={corpId} />

          {/* {corpId === "872cd841-9f7a-432d-b8e9-422b780bca10" ? (
            <NewRequestformMain config={config} corpId={corpId} />
          ) : (
            <PEHealthRecords config={config} />
          )} */}

          {/* <PEHealthRecords config={config} corpId={corpId} /> */}

          {/* <PEHealthRecordsMain /> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PreEmpHealthRecordsMain;
