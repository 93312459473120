import {
  Autocomplete,
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApi";

const PatientName = ({
  patient,
  setPatient,
  isError,
  setNameField,
  formValues,
}) => {
  const [oldPatientList, setOldPatientList] = useState([]);

  const [name, setName] = useState(patient ? patient : "");

  useEffect(() => {
    setName(patient ? patient : "");
  }, [patient]);

  const fetchOldPatient = useCallback(async (nameValue) => {
    const url = BASE_URL + "patient/cms/search?name=" + nameValue;

    const oldPatient = await getData(url, "");

    if (oldPatient.error) {
      console.log("error");
    } else {
      console.log("success");
      setOldPatientList(oldPatient.data);
    }
  }, []);

  console.log({ oldPatientList: oldPatientList });

  return (
    <Fragment>
      <Box>
        <Grid container>
          <Grid item lg={12}>
            <Autocomplete
              fullWidth
              freeSolo
              disableClearable
              size="small"
              options={oldPatientList}
              getOptionLabel={(option) => (option ? option["fullName"] : "")}
              renderOption={(props, option) => (
                <li {...props} key={option.patientId}>
                  <Stack
                    direction="row"
                    width="50%"
                    display="flex"
                    justifyContent={"flex-start"}
                  >
                    <Typography sx={{ fontWeight: 600 }}>
                      {option["fullName"]}
                    </Typography>
                    <Typography sx={{ fontSize: 11, fontWeight: 600, mt: 0.7 }}>
                      {option.mobile ? ", " + option.mobile : ""}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    width="50%"
                    display="flex"
                    justifyContent={"flex-end"}
                  >
                    <Box
                      component={Stack}
                      display="flex"
                      justifyContent="flex-end"
                      sx={{ fontSize: 10, fontWeight: 600, mt: 1 }}
                    >
                      {option?.age === 0 ? "" : option?.age}
                      {option?.age === 0 ? option?.ageMonths + "m" : ""}
                      {option.gender ? ", " + option.gender : ""}
                    </Box>
                  </Stack>
                </li>
              )}
              onInputChange={(event, newInputValue) => {
                if (newInputValue) {
                  fetchOldPatient(newInputValue);

                  //setPatient(null);
                }
                setNameField(newInputValue);
                formValues["fullName"] = newInputValue;
                formValues["firstName"] = newInputValue;
                formValues["patientId"] = 0;
                setPatient(null);
              }}
              name="name"
              value={name}
              selectOnFocus
              onChange={(event, value) => {
                setName(value);
                setPatient(value);
                setNameField(value.fullName);
                formValues["patientId"] = value.patientId;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Name*"
                  variant="outlined"
                  size="small"
                  error={isError}
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default PatientName;
