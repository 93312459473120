import { Grid, Typography } from "@mui/material";
import { getFormattedDDMonthYYYY } from "../../assets/utils";

const NextAppointmentDate = (props) => {
    return (
        <Grid container justify="flex-end" alignItems="flex-end" sx={{ marginTop: "10px"}}>
            <Grid item sx={{ display: "flex", }}>
                <Typography variant="body2"  align="right" sx={{  fontWeight: 700,lineHeight: "13px",color: "#404040",  marginRight: "20px" }}>
                    NEXT APPOINTMENT DATE
                </Typography>
                <Typography variant="body2"  align="right" sx={{  fontWeight: 700,lineHeight: "13px",  marginRight: "20px" }}>{getFormattedDDMonthYYYY(props.nextAppointmentDate)}</Typography>
            </Grid>

            
        </Grid>
    );
};

export default NextAppointmentDate;
