import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const PasswordComp = ({ password, setPassword }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
    setShowPassword((show) => !show);
  };

  return (
    <TextField
      size="medium"
      fullWidth
      label="Password"
      type={showPassword ? "text" : "password"}
      autoComplete="current-password"
      value={password || ""}
      onChange={(e) => {
        setPassword(e.target.value);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <IconButton
              aria-label="toggle password visibility"
              // onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        style: { fontSize: ".875rem", color: "#127DDD" },
      }}
    />
  );
};

export default PasswordComp;
