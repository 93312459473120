import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Portal,
  Typography,
  colors,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useState } from "react";
import { useEffect } from "react";
import DataGridEmployeePE from "./dataGridEmployeePE";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#127DDD", "#D45FFF", "#DDFFDF"];

let renderLabel = function (entry) {
  return `${entry.name}(${entry.value}%)`;
};

const CustomizedAxisTick = (props) => {
  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-20)" fontSize={12}>
        {payload.value}
      </text>
    </g>
  );
};

const CustomTooltipContent = ({ active, payload, label, tooltipTitle }) => {
  if (active) {
    const data = payload?.[0]?.payload; // Get the data of the hovered bar

    return (
      // <Tooltip>
      <Box sx={{ backgroundColor: "#FFFFFF", padding: "10px" }}>
        {label ? <Typography>{`${label}`}</Typography> : null}
        {data?.pass ? <Typography>{`Pass: ${data?.pass}`}</Typography> : null}
        {data?.fail ? <Typography>{`Fail: ${data?.fail}`}</Typography> : null}
        {data?.pending ? <Typography>{`Pending: ${data?.pending}`}</Typography> : null}
      </Box>
      // </Tooltip>
    );
  }

  return null;
};

const StackedBarChartPe = ({
  setIsGraph,
  disablePopup,
  data,
  title,
  dialogData,
  tooltipTitle,
  popupData,
  setSelectedBar,
  disableClickable,
}) => {
  const newData = data;
  console.log({ dialogData, newData });
  const [apiRef, setApiRef] = useState(null);
  const exportCSV = () => {
    apiRef.current.exportDataAsCsv();
  };
  const [open, setOpen] = useState(false);
  const [selectedChart, setSelectedChart] = useState("");
  const [selectedBarData, setSelectedBarData] = useState([]);
  const handleOpen = (title) => {
    setOpen(true);
    setSelectedChart(title);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedChart("");
    setSelectedBarData([]);
  };
  console.log({ HLELLLELE: newData });
  console.log({ mmmmmm: dialogData?.[selectedChart] });
  useEffect(() => {
    if (dialogData) {
      const newData = dialogData[selectedChart];
      console.log({ newData });
      if (newData) {
        setSelectedBarData(newData);
      } else {
        console.log("Selected chart not found in dialogData");
      }
    }
  }, [selectedChart]);
  console.log({ popupData });

  useEffect(() => {
    if (popupData) {
      setSelectedBarData(popupData);
    }
  }, [popupData]);

  console.log({ selectedBarData });
  console.log({ selectedChart });
  console.log({ newData });

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        border: "1px solid grey",
        paddingInline: "10px",
        paddingBlock: "20px",
        borderRadius: "10px",
        width: 800,
      }}>
      <Box sx={{ height: "50vh" }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={730}
            height={300}
            data={newData}
            onClick={(bar) => {
              setIsGraph(false);
              setSelectedBar(bar?.activeLabel);
            }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              style={{ fontSize: 12 }}
              interval={0}
              tick={CustomizedAxisTick}
              height={100}
            />
            <YAxis />
            <Tooltip content={<CustomTooltipContent tooltipTitle={tooltipTitle} />} />

            <Legend verticalAlign="top" height={36} />
            <Bar stackId="1" fill="#42BD53" dataKey="pass" name="Pass" />
            <Bar stackId="1" fill="#F66" dataKey="fail" name="Fail" />
            <Bar stackId="1" fill="#FFE066" dataKey="pending" name="Pending" />
          </BarChart>
        </ResponsiveContainer>
      </Box>
      <Typography textAlign="center">{title}</Typography>
      <Portal>
        <Dialog open={open} onClose={handleClose} maxWidth={"xl"} fullWidth={true}>
          <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>{title}</DialogTitle>
          <Typography variant="h6" sx={{ display: "flex", justifyContent: "center" }}>
            {selectedChart}
          </Typography>
          <Box sx={{ marginLeft: "20px" }}>
            <Button
              variant="contained"
              size="small"
              sx={{ height: 40, borderRadius: 4 }}
              onClick={exportCSV}>
              Download CSV
            </Button>
          </Box>
          <DialogActions sx={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
          <DialogContent>
            <DataGridEmployeePE setApiRef={setApiRef} employeeList={selectedBarData || []} />
          </DialogContent>
        </Dialog>
      </Portal>
    </Box>
  );
};

export default StackedBarChartPe;
