import { Box, Container, ThemeProvider } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import CorpCustomTheme from "../../../assets/themes/corpCustomTheme";

const RootTemplate = ({ children }) => {
  const theme = useTheme();
  //const isExtraSmallSize = useMediaQuery(theme.breakpoints.up("xs"));

  const matches = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  //console.log({ matches });

  return (
    <ThemeProvider theme={CorpCustomTheme}>
      <Container maxWidth={false} disableGutters={!matches}>
        <Box
          sx={{
            mt: { xs: 8, lg: 13 },
            mb: 0,
            height: "70vh",
            background: "#fff",
          }}>
          {children}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

//display: { xs: "flex", lg: "none" }

export default RootTemplate;
