import { Grid, IconButton, Typography, Box } from "@mui/material";
import { Fragment } from "react";
import editIcon from "../../../../assets/images/svg/editIcon.svg";

const style = {
  row: {
    display: "flex",
    alignItems: "center",
    px: 1,
    py: 0.87,
    borderRadius: "16.39px",
    background: "#F4FBFF",
  },
  title: {
    fontFamily: "Roboto",
    fontWeight: "600",
    fontSize: "1rem",
    lineHeight: "19px",
    color: "#127DDD",
  },
  textRow: {
    display: "flex",
    alignItems: "center",
  },
  text: {
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "19px",
    pl: 2,
  },
  border: {
    height: "36px",
    borderLeft: "1px solid #127ddd",
  },
  icon: { mt: 0.25 },
};

const DisplayDetail = ({ patientDetails, setIsEdit, label, keyValue }) => {
  return (
    <Fragment>
      <Grid container sx={style.row}>
        <Grid item lg={3}>
          <Typography sx={style.title}> {label} </Typography>
        </Grid>
        <Grid item lg={8} sx={style.textRow}>
          <Box sx={style.border}></Box>
          <Typography sx={style.text}>
            {patientDetails ? patientDetails[keyValue] : ""}
          </Typography>
        </Grid>
        <Grid item lg={1} sx={style.icon}>
          <IconButton
            aria-label="edit"
            size="small"
            onClick={() => setIsEdit(true)}
          >
            <img src={editIcon} sx={{ width: "100%" }} />
          </IconButton>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default DisplayDetail;
