import { useState, forwardRef, Fragment } from "react";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import LoginLeftPane from "./loginLeftPane";
import LoginRightPane from "./loginRightPane";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LoginIndex = () => {
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [openNotice, setOpenNotice] = useState(false);

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };

  return (
    <Fragment>
      <Snackbar
        open={openNotice}
        autoHideDuration={6000}
        onClose={handleCloseNotice}
      >
        <Alert
          onClose={handleCloseNotice}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Grid container>
        <Grid lg={6} md={6} sm={12} xs={12}>
          <LoginLeftPane />
        </Grid>
        <Grid lg={6} md={6} sm={12} xs={12}>
          <LoginRightPane
            setSeverity={setSeverity}
            setMessage={setMessage}
            setOpenNotice={setOpenNotice}
            handleCloseNotice={handleCloseNotice}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default LoginIndex;
