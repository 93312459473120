import { Box, Chip, Grid, Typography } from "@mui/material";

export const CustomHeadingChipLeft = ({ children }) => {
  return (
    <Grid container>
      <Grid item>
        <Box
          sx={{ px: 4, py: 0.75, m: 1, border: "1px solid rgb(199, 220, 251)", borderRadius: 5 }}
          display="flex"
          justifyContent="center"
          alignItems="center">
          <Typography
            sx={{
              boxSizing: "border-box",
              color: "rgb(141, 142, 146)",
              display: "inline-block",
              fontFamily: "Open Sans, sans-serif",
              fontsize: "16px",
              fontWeight: "400",
              lineHeight: "24px",
              textAlign: "center",
            }}>
            {children}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
export const CustomHeadingChipCenter = ({ children }) => {
  return (
    <Grid container display="flex" justifyContent="center">
      <Grid item>
        <Box
          sx={{ px: 4, py: 0.75, m: 1, border: "1px solid rgb(199, 220, 251)", borderRadius: 5 }}
          display="flex"
          justifyContent="center"
          alignItems="center">
          <Typography
            sx={{
              boxSizing: "border-box",
              color: "rgb(141, 142, 146)",
              display: "inline-block",
              fontFamily: "Open Sans, sans-serif",
              fontsize: "16px",
              fontWeight: "400",
              lineHeight: "24px",
              textAlign: "center",
            }}>
            {children}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export const CustomHeadingWhiteChipLeft = ({ children }) => {
  return (
    <Grid container>
      <Grid item>
        <Box
          sx={{ px: 4, py: 0.75, m: 1, border: "1px solid rgb(199, 220, 251)", borderRadius: 5 }}
          display="flex"
          justifyContent="center"
          alignItems="center">
          <Typography
            sx={{
              boxSizing: "border-box",
              color: "#EFF5FF",
              display: "inline-block",
              fontFamily: "Open Sans, sans-serif",
              fontsize: "16px",
              fontWeight: "400",
              lineHeight: "24px",
              textAlign: "center",
            }}>
            {children}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export const CustomHeadingSubTitleLeft = ({ children }) => {
  return (
    <Grid container>
      <Grid item>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography
            variant="h3"
            sx={{
              boxSizing: "border-box",
              fontFamily: "Roboto,sans-serif",
              fontsize: "40px",
              fontWeight: "700",
              lineHeight: "48px",
            }}>
            {children}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export const CustomHeadingSubTitleCenter = ({ children }) => {
  return (
    <Grid container display="flex" justifyContent="center">
      <Grid item>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography
            variant="h3"
            sx={{
              boxSizing: "border-box",
              fontFamily: "Roboto,sans-serif",
              fontsize: "40px",
              fontWeight: "700",
              lineHeight: "48px",
            }}>
            {children}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export const CustomHeadingSubTitleWhiteLeft = ({ children }) => {
  return (
    <Grid container>
      <Grid item>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography
            variant="h3"
            sx={{
              boxSizing: "border-box",
              fontFamily: "Roboto,sans-serif",
              fontsize: "40px",
              fontWeight: "700",
              lineHeight: "48px",
              color: "#fff",
            }}>
            {children}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export const CustomHeadingLeft1 = ({ children }) => {
  return (
    <Grid container>
      <Grid item>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography
            variant="h5"
            sx={{
              boxSizing: "border-box",
              fontFamily: "Roboto,sans-serif",
              fontsize: "20px",
              fontWeight: "700",
              lineHeight: "28px",
            }}>
            {children}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export const CustomHeadingCenter1 = ({ children }) => {
  return (
    <Grid container display="flex" justifyContent="center">
      <Grid item>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography
            variant="h5"
            sx={{
              boxSizing: "border-box",
              fontFamily: "Roboto,sans-serif",
              fontsize: "20px",
              fontWeight: "700",
              lineHeight: "28px",
            }}>
            {children}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export const CustomHeadingLeft2 = ({ children }) => {
  return (
    <Grid container>
      <Grid item>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography
            variant="h6"
            sx={{
              boxSizing: "border-box",
              fontFamily: "Roboto,sans-serif",
              fontsize: "20px",
              fontWeight: "700",
              lineHeight: "28px",
              color: "#1B2C51",
            }}>
            {children}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
export const CustomHeadingWhiteLeft2 = ({ children }) => {
  return (
    <Grid container>
      <Grid item>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography
            variant="h6"
            sx={{
              boxSizing: "border-box",
              fontFamily: "Roboto,sans-serif",
              fontsize: "20px",
              fontWeight: "700",
              lineHeight: "28px",
              color: "#fff",
            }}>
            {children}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export const CustomHeadingWhiteCenter2 = ({ children }) => {
  return (
    <Grid container display="flex" justifyContent="center">
      <Grid item>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography
            variant="h6"
            sx={{
              boxSizing: "border-box",
              fontFamily: "Roboto,sans-serif",
              fontsize: "20px",
              fontWeight: "700",
              lineHeight: "28px",
              color: "#fff",
            }}>
            {children}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
