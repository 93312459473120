import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Portal,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiRef,
} from "@mui/x-data-grid";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { getUrlExtension, maskText } from "../../../../../assets/utils";

const CustomTypography = ({ title }) => {
  return (
    <Typography
      sx={{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: 13,
        lineHeightt: 17,
        color: "#8A8A8A",
        textTransform: "capitalize",
      }}>
      {title}
    </Typography>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
    // remove padding at beginning of column header container so that text is inline with column text
    "& .paxton-table--header": {
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        padding: 0,
      },
      // set header font styling
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 600,
        textTransform: "uppercase",
        fontSize: "16px",
      },
    },
    // Datagrid Row Styling
    "& .paxton-table--row": {
      border: ".5px solid #6B6B6B",
      //borderBottom: "1px solid #6B6B6B",
      borderRadius: 5,
      margintop: 7,
      marginBottom: 7,
      backgroundColor: "#fff",
      paddingTop: 8,
      paddingBottom: 8,
      boxSizing: "content-box",
    },
    // remove borders and separators
    "& .paxton-table--cell": {
      borderRight: "1px solid #6B6B6B",
    },
    "& .MuiDataGrid-cell": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: 12,
      lineHeightt: 15,
      color: "##383838",
      textTransform: "capitalize",
      boxSizing: "none",
      borderBottom: "none ! important",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer": {
      border: "none",
    },
    // This is to fix the rows being cut off due to adding padding
    "& .MuiDataGrid-viewport": {
      maxHeight: "unset !important",
    },
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "670px !important", // <= manually setting the max height, but this is not responsive
    },
  },
}));

const DataGridEmployeePE = ({ setApiRef, employeeList }) => {
  const navigate = useNavigate();

  console.log({ employeeList });

  const EmployeeIDText = ({ text }) => {
    const [isDisplay, setIsDisplay] = useState(true);

    return (
      <IconButton onDoubleClick={() => setIsDisplay(!isDisplay)}>
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: 12,
            lineHeightt: 15,
            color: "##383838",
            textTransform: "capitalize",
          }}>
          {isDisplay ? maskText(text) : text}
        </Typography>
      </IconButton>
    );
  };

  const MobileText = ({ text }) => {
    const [isDisplay, setIsDisplay] = useState(true);

    return (
      <IconButton onDoubleClick={() => setIsDisplay(!isDisplay)}>
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: 12,
            lineHeightt: 15,
            color: "##383838",
            textTransform: "capitalize",
          }}>
          {isDisplay ? maskText(text) : text}
        </Typography>
      </IconButton>
    );
  };

  const EmployeeNameText = ({ data }) => {
    const [isDisplay, setIsDisplay] = useState(true);
    console.log({ dataAAAA: data });
    return (
      <Stack direction="row">
        <Box>
          <Stack direction="row" alignItems="center" spacing={1}>
            {isDisplay ? (
              <Avatar sx={{ width: 25, height: 25 }} alt={"*"} />
            ) : (
              <IconButton
                sx={{ p: 0 }}
                onClick={() =>
                  navigate("/corporatehome/employeeprofile", {
                    state: {
                      employeeId: data.id,
                    },
                  })
                }>
                <Avatar
                  sx={{ width: 25, height: 25 }}
                  alt={data?.value?.name}
                  src={data?.value?.imageUrl}
                />
              </IconButton>
            )}
            <IconButton onDoubleClick={() => setIsDisplay(!isDisplay)}>
              <Typography
                noWrap
                sx={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: 12,
                  lineHeightt: 15,
                  color: "##383838",
                  textTransform: "capitalize",
                }}>
                {isDisplay ? maskText(data.value) : data.value}
              </Typography>
            </IconButton>
          </Stack>
        </Box>
      </Stack>
    );
  };

  const colLeft = [
    {
      field: "empId",
      renderHeader: (params) => <CustomTypography title="Emp. ID" />,
      width: 100,
      type: "number",
      align: "left",
      renderCell: (cellValues) => <EmployeeIDText text={cellValues?.value} />,
    },
    {
      field: "name",
      renderHeader: (params) => <CustomTypography title="Name" />,
      width: 200,
      valueFormatter: ({ value }) => `${value?.name || value}`,
      renderCell: (cellValues) => <EmployeeNameText data={cellValues} />,
    },

    {
      field: "department",
      renderHeader: (params) => <CustomTypography title="Department" />,
      width: 100,
    },

    // {
    //   field: "mobile",
    //   renderHeader: (params) => <CustomTypography title="Contact" />,
    //   renderCell: (cellValues) => <MobileText text={cellValues.value} />,
    //   width: 100,
    // },

    // {
    //   field: "age",
    //   renderHeader: (params) => <CustomTypography title="Age" />,
    //   width: 100,
    // cellClassName: (params) => {
    //   if (params.value == null) {
    //     return "";
    //   }

    //   return clsx("super-app", {
    //     negative: params.value < 0,
    //     positive: params.value > 0,
    //   });
    // },
    // },
  ];

  const columns = [...colLeft];

  const classes = useStyles();

  const apiRef = useGridApiRef();

  useEffect(() => {
    setApiRef(apiRef);
  }, [apiRef]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = (url) => {
    const ext = getUrlExtension(url).toLowerCase();
    if (ext === "pdf") {
      window.open(url);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [selectedImage, setSelectedImage] = useState("");
  return (
    <Fragment>
      <Portal>
        <Dialog open={open} onClose={handleClose} maxWidth={"xl"} fullWidth={true}>
          <DialogContent>
            <Box
              sx={{ minHeight: "70vh" }}
              display="flex"
              justifyContent="center"
              alignItems="center">
              {selectedImage ? <img width="100%" src={selectedImage} /> : "No Document."}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Portal>

      <Box style={{ height: "69vh", width: "100%" }}>
        <Box style={{ display: "flex", height: "100%" }}>
          <Box
            sx={{
              flexGrow: 1,
              width: "100%",
              "& .super-app.negative": {
                backgroundColor: "rgba(157, 255, 118, 0.49)",
                color: "#1a3e72",
                fontWeight: "600",
              },
              "& .super-app.positive": {
                backgroundColor: "#d47483",
                color: "#1a3e72",
                fontWeight: "600",
              },
            }}>
            <DataGrid
              getRowId={(row) => row.empId}
              apiRef={apiRef}
              rowHeight={24}
              rows={employeeList}
              columns={columns}
              className={classes.root}
              getRowClassName={() => "paxton-table--row"}
              getCellClassName={() => "paxton-table--cell"}
              disableRowSelectionOnClick
            />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default DataGridEmployeePE;
