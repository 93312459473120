import React, { Fragment, useEffect } from "react";
import {
  useHMSActions,
  useHMSStore,
  selectCameraStreamByPeerID,
  useAVToggle,
} from "@100mslive/react-sdk";
import { Avatar, Box } from "@mui/material";

const VideoTilePeers = ({ peer }) => {
  const hmsActions = useHMSActions();
  const videoRef = React.useRef(null);
  const videoTrack = useHMSStore(selectCameraStreamByPeerID(peer.id));

  console.log({ videoTrack });

  React.useEffect(() => {
    (async () => {
      console.log(videoRef.current);
      console.log(videoTrack);
      if (videoRef.current && videoTrack) {
        if (videoTrack.enabled) {
          await hmsActions.attachVideo(videoTrack.id, videoRef.current);
        } else {
          await hmsActions.detachVideo(videoTrack.id, videoRef.current);
        }
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoTrack]);

  console.log({ peer: peer });
  return (
    <Fragment>
      <Box>
        <Box sx={{ width: "54vw", height: "80vh", mt: 0, ml: 0 }}>
          {videoTrack && videoTrack.displayEnabled ? (
            <video
              style={{
                width: "99.5%",
                //minHeight: "100%",
                height: "83vh",
                border: "1px solid black",
                borderRadius: 10,
              }}
              ref={videoRef}
              autoPlay={true}
              playsInline
              muted={true}
            ></video>
          ) : (
            <Box
              style={{
                width: "54vw",
                height: "80vh",
                mt: 0.5,
                ml: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid black",
                borderRadius: 10,
                background: "lightblue",
              }}
            >
              <Avatar
                sx={{
                  height: 100,
                  width: 100,
                  p: 5,
                  color: "#fff",
                  backgroundColor: "#208F94",
                }}
              >
                {peer.name}
              </Avatar>
            </Box>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

export default VideoTilePeers;
