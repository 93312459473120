import { Box, Container, Grid } from "@mui/material";
import {
  CustomHeadingSubTitleWhiteLeft,
  CustomHeadingWhiteChipLeft,
} from "../../global/headings/customHeadings";
import { CustomParagraphWhite1 } from "../../global/paragraphs/customParagraphs";
import FeatureBullets from "./featureBullets";

const LandingFeaturesLeft = () => {
  return (
    // <Box sx={{}}>
    <Container disableGutters sx={{ backgroundColor: "#0463FA", minHeight: 500, p: 4 }}>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <CustomHeadingWhiteChipLeft>Features</CustomHeadingWhiteChipLeft>
        </Grid>
        <Grid item lg={12}>
          <CustomHeadingSubTitleWhiteLeft>Why Choose Us</CustomHeadingSubTitleWhiteLeft>
        </Grid>
        <Grid item lg={12}>
          <CustomParagraphWhite1>
            We offer comprehensive healthcare services all under one roof. With our expert team of
            healthcare professionals, state-of-the-art facilities, and a commitment to
            patient-centric care, we stand out as your preferred healthcare destination.
          </CustomParagraphWhite1>
        </Grid>
        <Grid item lg={12}>
          <FeatureBullets />
        </Grid>
      </Grid>
    </Container>
    // </Box>
  );
};

export default LandingFeaturesLeft;
