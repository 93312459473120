import { Box } from "@mui/material";
import { Fragment } from "react";
import ReportContentComp from "./reportContentComp";

const ReportsIndex = ({
  patientDetails,
  patient,
  appointmentId,
  caseId,
  tabValue,
}) => {
  return (
    <Fragment>
      <Box>
        <ReportContentComp
          patientDetails={patientDetails}
          patient={patient}
          appointmentId={appointmentId}
          caseId={caseId}
        />
      </Box>
    </Fragment>
  );
};

export default ReportsIndex;
