import { Fragment } from "react";
import CustomSelect from "../../../../../global/components/customSelect";

const statusList = [
  {
    id: 1,
    label: "ALL",
    value: "ALL",
  },
  {
    id: 2,
    label: "Fit",
    value: "FIT",
  },
  {
    id: 3,
    label: "Medical Attention Required",
    value: "UNFIT",
  },
];

const HealthStatusFilter = ({ healthStatus, setHealthStatus }) => {
  return (
    <Fragment>
      <CustomSelect
        value={healthStatus}
        setvalue={setHealthStatus}
        options={statusList}
        label="Health Status"
      />
    </Fragment>
  );
};

export default HealthStatusFilter;
