import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PrescriptionTemplate from "./prescriptionTemplate";

const PreviousPrescriptions = (props) => {
    const [flag, setFlag] = useState(false);
    const [updatedPrescription, setUpdatedPrescription] = useState([]);

    let patientDetails = "";
    let prescriptions = [];
    let appointments = [];
    let prescriptionVM = null;
    let newpres = "";

    useEffect(() => {
        if (props.patientDetails) {
            patientDetails = props.patientDetails;
            prescriptions = patientDetails.prescriptions;
            appointments = patientDetails.appointments;
            prescriptionVM = appointments ? appointments[0].prescriptionVM : null;
        }
        if (prescriptionVM) {
            newpres = prescriptions; //prescriptions.splice(0, 1);

            setFlag(!flag);
            console.log("aaaaaa");
        } else {
            newpres = prescriptions;

            console.log("nnnnnnn");
        }

        setUpdatedPrescription(props.oldPrescriptions);
    }, [props.patientDetails]);

    return (
        <Grid container rowSpacing={1}>
            <Grid item lg={12}>
                <Typography
                    variant="body2"
                    sx={{ width: "100%", flexShrink: 0, fontWeight: "500", textAlign: "center", pt: 2 }}
                >
                    All Prescriptions
                </Typography>

                {updatedPrescription
                    ? updatedPrescription.map((medication, index) => (
                          <PrescriptionTemplate
                              key={index}
                              medicines={medication.medicines}
                              createdAt={medication.createdAt}
                          />
                      ))
                    : ""}
            </Grid>
        </Grid>
    );
};

export default PreviousPrescriptions;
