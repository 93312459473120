import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Cell,
  Label,
} from "recharts";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Portal,
  Select,
  Typography,
} from "@mui/material";
import { downloadCsv, formatColumnName } from "../../../../../../assets/utils";
import CustomDataGridLayout from "../../../../../../assets/customDataGridLayout/customDataGridLayout";
import { useGridApiContext, useGridApiRef } from "@mui/x-data-grid";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

const CustomBarChart = ({
  data,
  title,
  duration,
  setDuration,
  COLORS = [],
  minHeight,
  disableClickable,
  downloadExternalData = false,
  handleDownloadExternalData,
  dialogData,
  xAxisLabel,
}) => {
  const handleDurationChange = (event) => {
    setDuration(event.target.value);
    // Fetch or filter the data based on the selected duration
  };

  const handleDownload = () => {
    downloadCsv(data, `${title}_${duration || ""}`);
  };
  const apiRef = useGridApiRef(null);
  const exportCSV = () => {
    apiRef.current.exportDataAsCsv();
  };
  const [open, setOpen] = useState(false);

  const [selectedChart, setSelectedChart] = useState("");
  const [selectedBarData, setSelectedBarData] = useState([]);

  useEffect(() => {
    if (dialogData) {
      const data = dialogData[selectedChart];
      console.log({ data });
      if (data) {
        setSelectedBarData(
          data
            ?.filter((obj) => obj.emp_id !== null)
            .map((item, index) => ({ index: index, ...item }))
        );
      } else {
        console.log("Selected chart not found in dialogData");
      }
    }
  }, [selectedChart]);

  console.log({ dialogData, selectedBarData });

  const columns =
    selectedBarData.length > 0
      ? Object.keys(selectedBarData[0])
          .filter((key) => !["index"].includes(key))
          .map((key) => {
            return {
              field: key,
              headerName: formatColumnName(key),
              width: key === "productName" || key === "brandName" ? 200 : 170,
              align: "left",
              headerAlign: "left",
            };
          })
      : [];

  const [cursor, setCursor] = useState("default");
  const handleMouseEnter = (bar) => {
    console.log(dialogData?.[bar?.productName]?.length);
    if (
      dialogData?.[bar?.productName]?.length !== undefined &&
      dialogData?.[bar?.productName]?.length > 0
    ) {
      setCursor("pointer");
    }
  };

  const handleMouseLeave = () => {
    setCursor("default");
  };

  console.log({ cursor });

  return (
    <Box
      style={{
        width: "100%",
        border: "1px solid #0463FA",
        borderRadius: 5,
        padding: 10,
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          {title}
        </Typography>
        {duration && (
          <FormControl
            variant="outlined"
            size="small"
            style={{
              minWidth: 120,
              borderRadius: "10px",
            }}
          >
            <InputLabel>Duration</InputLabel>
            <Select
              value={duration}
              onChange={handleDurationChange}
              label="Duration"
              style={{ borderRadius: "10px" }}
            >
              <MenuItem value="LAST_WEEK">This week</MenuItem>
              <MenuItem value="LAST_MONTH">This month</MenuItem>
              <MenuItem value="LAST_YEAR">This Year</MenuItem>
            </Select>
          </FormControl>
        )}
        <Button
          disabled={
            data.every((item) => item.consumedQuantity === 0) ? true : false
          }
          variant="contained"
          color="primary"
          onClick={() => {
            if (downloadExternalData === true && handleDownloadExternalData) {
              handleDownloadExternalData();
            } else {
              handleDownload();
            }
          }}
        >
          Download data
        </Button>
      </Box>
      {data.every((item) => item.consumedQuantity === 0) ? (
        <Box
          width={"100%"}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: minHeight || 350,
          }}
        >
          <Typography sx={{ textAlign: "center" }}>No Data Found</Typography>
        </Box>
      ) : (
        <ResponsiveContainer width="100%" minHeight={minHeight || 350}>
          <BarChart
            layout="vertical"
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            barSize={20}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number">
              <Label
                style={{ marginBlock: 1 }}
                value="Count of Medicines"
                offset={-5}
                position="insideBottom"
              />
            </XAxis>
            <YAxis dataKey="productName" type="category" width={150} />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              dataKey="consumedQuantity"
              background={{ fill: "#eee" }}
              radius={5}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={(bar) => {
                console.log({ bar });
                if (disableClickable) {
                  return;
                } else {
                  if (dialogData?.[bar?.productName].length > 0) {
                    setOpen(true);
                  }
                  setSelectedChart(bar?.productName);
                }
              }}
            >
              {data.map((entry, index) => (
                <Cell
                  cursor={cursor}
                  fill={
                    title === "Expiry"
                      ? COLORS[index]
                      : COLORS[index % COLORS.length]
                    //  "url(#colorUv)"
                  }
                  key={`cell-${index}`}
                />
              ))}
            </Bar>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="1" y2="1">
                <stop offset="5%" stopColor="#0463FA" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#0463FA" stopOpacity={0.4} />
              </linearGradient>
            </defs>
          </BarChart>
        </ResponsiveContainer>
      )}

      <Portal>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          maxWidth={"xl"}
          fullWidth={true}
        >
          <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
            {title}
          </DialogTitle>
          <Typography
            variant="h6"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {selectedChart}
          </Typography>
          <Box sx={{ marginLeft: "20px" }}>
            <Button
              variant="contained"
              size="small"
              sx={{ height: 40, borderRadius: 4 }}
              onClick={exportCSV}
            >
              Download CSV
            </Button>
          </Box>
          <DialogActions sx={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>
          <DialogContent>
            <CustomDataGridLayout
              apiRef={apiRef}
              rows={selectedBarData}
              columns={columns}
              getRowId={(row) => row.index}
              dataGridHeight={"50vh"}
              density="compact"
            />
          </DialogContent>
        </Dialog>
      </Portal>
    </Box>
  );
};

export default CustomBarChart;

// import {
//   Box,
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Drawer,
//   FormControl,
//   IconButton,
//   InputLabel,
//   MenuItem,
//   Portal,
//   Select,
//   Stack,
//   Typography,
// } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
// import React, { Fragment, useEffect, useState } from "react";
// import {
//   Bar,
//   BarChart,
//   CartesianGrid,
//   LabelList,
//   Legend,
//   Tooltip,
//   ResponsiveContainer,
//   XAxis,
//   YAxis,
//   Label,
// } from "recharts";
// import CustomDataGridLayout from "../../../../../../assets/customDataGridLayout/customDataGridLayout";
// import { downloadCsv } from "../../../../../../assets/utils";

// const CustomBarChart = ({
//   duration,
//   setDuration,
//   data,
//   title,
//   dialogData,
//   popupData,
//   setSelectedBar,
//   disableClickable,
//   minWidth,
//   boxHeight,
// }) => {
//   console.log({ dialogData });
//   const [apiRef, setApiRef] = useState(null);
//   const exportCSV = () => {
//     apiRef.current.exportDataAsCsv();
//   };
//   const [open, setOpen] = useState(false);
//   const [selectedChart, setSelectedChart] = useState("");
//   const [selectedBarData, setSelectedBarData] = useState([]);
//   const handleOpen = (title) => {
//     setOpen(true);
//     setSelectedChart(title);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setSelectedChart("");
//     setSelectedBarData([]);
//   };

//   useEffect(() => {
//     if (dialogData) {
//       const data = dialogData[selectedChart];
//       console.log({ data });
//       if (data) {
//         setSelectedBarData(data?.filter((obj) => obj.emp_id !== null));
//       } else {
//         console.log("Selected chart not found in dialogData");
//       }
//     }
//   }, [selectedChart]);
//   console.log({ popupData });

//   useEffect(() => {
//     if (popupData) {
//       setSelectedBarData(popupData);
//     }
//   }, [popupData]);

//   const CustomTooltip = ({ active, payload, label }) => {
//     if (active && payload && payload.length) {
//       return (
//         <div className="custom-tooltip">
//           <p className="label">{`${label} : ${payload[0].value}`}</p>
//         </div>
//       );
//     }
//     return null;
//   };

//   const handleDurationChange = (event) => {
//     setDuration(event.target.value);
//   };

//   const handleDownload = () => {
//     downloadCsv(data, `${title}_${duration}`);
//   };

//   return (
//     <Fragment>
//       <Box
//         sx={{
//           backgroundColor: "#FFFFFF",
//           border: "1px solid #0463FA",
//           height: boxHeight || "300px",
//           paddingInline: "10px",
//           paddingBlock: "20px",
//           borderRadius: "10px",
//           minWidth: minWidth,
//         }}
//       >
//         <Box
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//             marginBottom: "20px",
//           }}
//         >
//           <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
//             {title}
//           </Typography>
//           <FormControl
//             variant="outlined"
//             size="small"
//             style={{
//               minWidth: 120,
//               borderRadius: "10px",
//             }}
//           >
//             <InputLabel>Duration</InputLabel>
//             <Select
//               value={duration}
//               onChange={handleDurationChange}
//               label="Duration"
//               style={{ borderRadius: "10px" }}
//             >
//               <MenuItem value="LAST_WEEK">This week</MenuItem>
//               <MenuItem value="LAST_MONTH">This month</MenuItem>
//               <MenuItem value="LAST_YEAR">This Year</MenuItem>
//             </Select>
//           </FormControl>
//           <Button variant="contained" color="primary" onClick={handleDownload}>
//             Download data
//           </Button>
//         </Box>
//         <Box sx={{ height: "300px" }}>
//           {data.length === 0 ? (
//             <Box
//               width={"100%"}
//               height={290}
//               sx={{
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//               }}
//             >
//               <Typography sx={{ textAlign: "center" }}>
//                 No Data Found
//               </Typography>
//             </Box>
//           ) : (
//             <ResponsiveContainer width="100%" height="100%">
//               <BarChart
//                 layout="vertical"
//                 data={data}
//                 margin={{
//                   top: 20,
//                   right: 30,
//                   left: 20,
//                   bottom: 5,
//                 }}
//                 barSize={20}
//                 onClick={(bar) => {
//                   console.log({ bar });
//                   if (disableClickable) {
//                     return;
//                   } else {
//                     handleOpen(bar?.activeLabel);
//                     if (setSelectedBar) {
//                       setSelectedBar(bar?.activeLabel);
//                     }
//                   }
//                 }}
//               >
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <XAxis type="number" />
//                 <YAxis dataKey="productName" type="category" width={150} />
//                 <Tooltip content={<CustomTooltip />} />
//                 <Bar
//                   dataKey="consumedQuantity"
//                   fill="url(#colorUv)"
//                   background={{ fill: "#eee" }}
//                   radius={5}
//                 />
//                 <defs>
//                   <linearGradient id="colorUv" x1="0" y1="0" x2="1" y2="1">
//                     <stop offset="5%" stopColor="#0463FA" stopOpacity={0.8} />
//                     <stop offset="95%" stopColor="#0463FA" stopOpacity={0.4} />
//                   </linearGradient>
//                 </defs>
//               </BarChart>
//             </ResponsiveContainer>
//           )}
//         </Box>
//       </Box>

// <Portal>
//   <Dialog
//     open={open}
//     onClose={handleClose}
//     maxWidth={"xl"}
//     fullWidth={true}
//   >
//     <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
//       {title}
//     </DialogTitle>
//     <Typography
//       variant="h6"
//       sx={{ display: "flex", justifyContent: "center" }}
//     >
//       {selectedChart}
//     </Typography>
//     <Box sx={{ marginLeft: "20px" }}>
//       <Button
//         variant="contained"
//         size="small"
//         sx={{ height: 40, borderRadius: 4 }}
//         onClick={exportCSV}
//       >
//         Download CSV
//       </Button>
//     </Box>
//     <DialogActions sx={{ position: "absolute", top: 0, right: 0 }}>
//       <IconButton onClick={handleClose}>
//         <CloseIcon />
//       </IconButton>
//     </DialogActions>
//     <DialogContent>
//       <CustomDataGridLayout
//         apiRef={apiRef}
//         employeeList={selectedBarData || []}
//       />
//     </DialogContent>
//   </Dialog>
// </Portal>
//     </Fragment>
//   );
// };

// export default CustomBarChart;
