import dayjs from "dayjs";
import Papa from "papaparse";

export function getCurrentDate(separator = "") {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date}`;
}

export function getCurrentDateFormatted(separator = "") {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  let m = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let monthStr = m[month - 1];

  return `${date}${separator}${monthStr}${separator}${year}`;
}

export function getCurrentDateAndTimeFormatted(separator = "") {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let hour = newDate.getHours();
  let minutes = newDate.getMinutes();
  let seconds = newDate.getSeconds();

  let m = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let monthStr = m[month - 1];

  return `${date}${separator}${monthStr}${separator}${year}${separator}${hour}${":"}${minutes}`;
}

export function getDelay(time) {
  let str = "";

  if (time !== null && typeof time !== "undefined") {
    let delay = 0;
    let newDate_current = new Date();
    let hour_current = newDate_current.getHours();
    let minutes_current = newDate_current.getMinutes();

    let d = time.split(":");
    let d1 = d[1].split(" ");

    let th = parseInt(d[0]);
    let tm = parseInt(d1[0]);
    let tp = d1[1];

    if (tp === "PM") {
      th = th + 12;
    }

    delay = (hour_current - th) * 60 + (minutes_current - tm);

    if (delay < 0) {
      delay = (th - hour_current) * 60 + (tm - minutes_current);
      const h = Math.floor(delay / 60);
      const m = delay % 60;
      str = "-" + h + "h " + m + "m";
    } else {
      delay = (hour_current - th) * 60 + (minutes_current - tm);
      const h = Math.floor(delay / 60);
      const m = delay % 60;
      str = "+" + h + "h " + m + "m";
    }
  }

  return str;
}

export const getFormattedDayAndDate = (date) => {
  const d = new Date(date).toUTCString().split(" ");
  const nd = d[0] + " " + d[1] + " " + d[2] + " " + d[3];
  return nd;
};

export const getFormattedDDMonthYYYY = (date) => {
  const d = new Date(date).toUTCString().split(" ");
  const nd = d[1] + " " + d[2] + " " + d[3];
  return nd;
};

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

// a and b are javascript Date objects
export function dateDiffInDays(a, b) {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export const getNextDate = (interval) => {
  const int = interval ? interval : 0;
  const cDay = new Date();
  let nextDate = new Date(cDay);
  nextDate.setDate(cDay.getDate() + parseInt(int));

  return nextDate;
};

export const generateDoseList = (n) => {
  if (n && !isNaN(n)) {
    if (n === "1") {
      const a = [" TAB", " INJ", "ML"];
      return a.map((item) => n + item);
    } else {
      const a = [" TABS", " INJS"];
      return a.map((item) => n + item);
    }
  }
  return [];
};

export const generateDurationList = (n) => {
  if (n && !isNaN(n)) {
    if (n === "1") {
      const a = [" DAY", " WEEK", " MONTH"];
      return a.map((item) => n + item);
    } else {
      const a = [" DAYS", " WEEKS", " MONTHS"];
      return a.map((item) => n + item);
    }
  }

  return [];
};

export const getUrlExtension = (url) => {
  try {
    return url.match(/^https?:\/\/.*[\\\/][^\?#]*\.([a-zA-Z0-9]+)\??#?/)[1];
  } catch (ignored) {
    return "";
  }
};

export const maskText = (text) => {
  const charList = text ? [...text] : [];
  return text ? charList.map((it) => "*") : "";
};

export const getMonthName = (monthIndex) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames[monthIndex];
};

export const calculateSessionDuration = (sessionStartDate, sessionEndDate) => {
  if (!sessionStartDate || !sessionEndDate) {
    return null;
  }

  const start = dayjs(sessionStartDate);
  const end = dayjs(sessionEndDate);

  if (!start.isValid() || !end.isValid()) {
    return null;
  }

  const durationInHours = end.diff(start, "hour", true);

  return `${durationInHours} Hour`;
};

export const formatCustomDateTime = (dateString) => {
  if (!dateString) {
    return null;
  }

  const date = dayjs(dateString);

  if (!date.isValid()) {
    return null;
  }

  const day = dayjs(dateString).format("Do");
  const month = date.format("MMMM"); // Full month name
  const year = date.format("YY"); // Last two digits of the year
  const time = date.format("hh:mm A"); // 12-hour time with AM/PM

  return `${day} ${month} ${year}, ${time}`;
};

export const downloadCsv = (jsonData, csvname) => {
  const csv = Papa.unparse(jsonData);
  const csvData = new Blob([csv], { type: "text/csv" });
  const csvUrl = window.URL.createObjectURL(csvData);
  const hiddenElement = document.createElement("a");
  hiddenElement.href = csvUrl;
  hiddenElement.target = "_blank";
  hiddenElement.download = csvname;
  hiddenElement.click();
};

export const isEveryKeyEmptyExcluding = (obj, excludedKeys) => {
  return Object.entries(obj)
    .filter(([key]) => !excludedKeys.includes(key))
    .every(([, value]) => value === "");
};

export const formatColumnName = (columnName) => {
  return columnName?.replace(/([A-Z])/g, " $1")?.toUpperCase();
};

export const getTestTypeList = (selectedTicketType) => {
  switch (selectedTicketType) {
    case "PRE_EMPLOYMENT":
      return [{ id: 1, label: "PRE EMPLOYMENT", value: "PRE_EMPLOYMENT" }];
    default:
      return [
        { id: 1, label: "ONROLL", value: "ONROLL" },
        { id: 2, label: "OFFROLL", value: "OFFROLL" },
        { id: 3, label: "PRE EMPLOYMENT", value: "PRE_EMPLOYMENT" },
      ];
  }
};

export const showMedicineComp = (corpId) => {
  const validCorpIds = new Set([
    "13a4b8c6-750f-442a-8414-f5465481e5d9",
    "1ab48481-e6f5-4dd6-8b50-983c94d763a1",
    "820df4bf-eb31-4bc9-9cd1-789b36f47883",
    "3e875c62-9ecb-49b7-9fda-067379425f75",
    "dfe08099-3324-4d60-807a-b04fe8b4a373",
    "656f54f4-e8c0-42f5-afc1-31e158fe8f6f",
    "6d5ce94f-6b9b-4efa-a650-1298481cb413",
    "bbb269e5-b020-4257-ad8f-4da8c811801a",
    "872cd841-9f7a-432d-b8e9-422b780bca10",
    "e78bd9d1-06f0-4701-9c0d-8e1d1a79fe69", //Jalpa DEVI
    "cd86fc1d-ca50-42f2-9a53-1003aea3993e", // Pinnacle
    "ee825bad-ed64-46ed-a3a2-67a17f81ac7f", // Innovative Clad Solutions Pvt Ltd
    "9b7e33eb-d085-4df5-bdc1-146d930c2387", // Aperam Alloys India Private Limited
    "c59a2fbc-bc3d-453c-aae4-313581793890", //Indore Composite
  ]);

  return validCorpIds.has(corpId);
};
