import { Box, Fab } from "@mui/material";
import React, { Fragment } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const QuickActionBtns = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Fragment>
      <Fab
        aria-label="add"
        onClick={handleScrollToTop}
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          backgroundColor: "#0045B2",
          ":hover": {
            backgroundColor: "#0045B2",
          },
        }}
      >
        <KeyboardArrowUpIcon sx={{ color: "#FFF" }} />
      </Fab>
    </Fragment>
  );
};

export default QuickActionBtns;
