import React, { Fragment, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { BASE_URL } from "../../../../../../assets/constantsFile";
import { getData } from "../../../../../../services/api/getApi";

const CustomAutocompleteEmpId = ({
  corpId = localStorage.getItem("CORPID"),
  item,
  setFormValues,
  formValues,
  required = true,
  setIsLoading,
}) => {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [empList, setEmplist] = useState([]);

  const fetchEmployees = async () => {
    setIsLoading(true);
    const url = BASE_URL + `org/detailed/all?corpId=${corpId}`;
    const result = await getData(url);
    if (result.error) {
      setEmplist([]);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setEmplist(
        result.data.filter(
          (employee, index, self) =>
            employee.empId !== null &&
            employee.empId !== "" &&
            self.findIndex((e) => e?.empId === employee?.empId) === index
        )
      );
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  return (
    <Fragment>
      <Typography
        sx={{
          fontFamily: "Roboto",
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: 13,
          lineHeightt: 17,
          color: "#000",
          textTransform: "capitalize",
        }}
      >
        {"Employee ID"}
        {required && <span style={{ color: "red" }}> *</span>}
      </Typography>
      <Autocomplete
        options={empList}
        getOptionLabel={(option) => option?.empId || ""}
        freeSolo
        size="small"
        fullWidth
        value={selectedEmployee}
        onChange={(event, newValue, reason) => {
          if (reason === "clear") {
            setSelectedEmployee(null);
            setFormValues({
              corpId: corpId,
              ohcType: "OPD",
            });
          } else {
            setSelectedEmployee(newValue);
            const newFormValues = {
              ...formValues,
              corpId: corpId || null,
              empId: newValue.empId || null,
              name: newValue.name || null,
              age: newValue.age || null,
              gender: newValue.gender || null,
              ohcType: "OPD",
              mobile: newValue.mobile || null,
              bp: newValue.bp || null,
              bloodGroup: newValue.bloodGroup || null,
              sugar: newValue.sugar || null,
              height: newValue.height || null,
              department: newValue.department || null,
              complaint: newValue.complaint || null,
              medicine: newValue.medicine || null,
              rest: newValue.rest || false,
              fitToWork: newValue.fitToWork || false,
              underMedication: newValue.underMedication || false,
            };
            setFormValues(newFormValues);
          }
        }}
        onInputChange={(event, newInputValue) => {
          let newFormValues = { ...formValues, empId: newInputValue };
          setFormValues(newFormValues);
        }}
        renderInput={(params) => (
          <TextField {...params} placeholder={"Search Employee"} size="small" />
        )}
        renderOption={(props, employee) => (
          <li {...props}>
            <div>{`${employee?.empId}  ${employee?.name}  ${
              employee?.mobileNo ? employee?.mobileNo : ""
            }`}</div>
          </li>
        )}
      />
    </Fragment>
  );
};

export default CustomAutocompleteEmpId;
