import RootTemplate from "../../global/templates/rootTemplate";
import PharmacyEquipmentsMain from "../../modules/pharmacy/equipments/pharmacyEquipmentsMain";

const EquipmentsIndex = () => {
  return (
    <RootTemplate>
      <PharmacyEquipmentsMain />
    </RootTemplate>
  );
};

export default EquipmentsIndex;
