import React from "react";
import RootTemplate from "../../global/templates/rootTemplate";
import CompletedSessions from "../../modules/healthAwarenessSessions/completedSessions/completedSessions";

const SessionCompletedIndex = () => {
  return (
    <RootTemplate>
      <CompletedSessions />
    </RootTemplate>
  );
};

export default SessionCompletedIndex;
