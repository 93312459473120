import React, { Fragment, useEffect, useState } from "react";
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { getData } from "../../../../../services/api/getApi";

const EmployeeSearch = ({
  corpId = localStorage.getItem("CORPID"),
  item,
  setFormValues,
  formValues,
  required,
}) => {
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [empList, setEmplist] = useState([]);

  const fetchEmployees = async () => {
    const url = BASE_URL + `org/detailed/all?corpId=${corpId}`;
    const result = await getData(url);
    if (result.error) {
      setEmplist([]);
    } else {
      setEmplist(
        result.data.filter(
          (employee, index, self) =>
            employee.empId !== null &&
            employee.empId !== "" &&
            self.findIndex((e) => e?.empId === employee?.empId) === index
        )
      );
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  return (
    <Fragment>
      <Autocomplete
        options={empList}
        getOptionLabel={(option) => option?.empId || ""}
        freeSolo
        size="small"
        fullWidth
        value={selectedEmployee}
        onChange={(event, newValue, reason) => {
          if (reason === "clear") {
            setSelectedEmployee(null);
            setFormValues({
              corpId: corpId,
              ohcType: "INJURY",
            });
          } else {
            setSelectedEmployee(newValue);
            const newFormValues = {
              ...formValues,
              name: newValue?.name,
              empId: newValue?.empId,
              corpId: corpId,
              ohcType: "INJURY",
              age: newValue?.age,
              gender: newValue?.gender,
              mobile: newValue?.mobile,
            };
            setFormValues(newFormValues);
          }
        }}
        onInputChange={(event, newValue) => {
          let newFormValues = { ...formValues };
          newFormValues[item.property] = newValue;
          setFormValues(newFormValues);
        }}
        renderInput={(params) => (
          <TextField {...params} placeholder={"Search Employee"} size="small" />
        )}
        renderOption={(props, employee) => (
          <li {...props}>
            <div>{`${employee?.empId}  ${employee?.name}  ${
              employee?.mobileNo ? employee?.mobileNo : ""
            }`}</div>
          </li>
        )}
      />
    </Fragment>
  );
};

export default EmployeeSearch;
