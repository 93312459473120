export const nabarURLList = {
  ahc: "#",
  ahc_statustracker: "/corporatehome/ahc/statustracker",
  ahc_healthrecords: "/corporatehome/ahc/healthrecords",
  ahc_recommendedtests: "/corporatehome/ahc/recommendedtests",
  ahc_photogallery: "/corporatehome/ahc/gallery",
  ahc_form21: "/corporatehome/ahc/form21",
  ahc_form24: "/corporatehome/ahc/form24",

  preemployment: "#",
  preemployment_statustracker: "/corporatehome/preemployment/statustracker",
  preemployment_healthrecords: "/corporatehome/preemployment/healthrecords",
  preemployment_newrequestform: "/corporatehome/preemployment/newrequestform",

  ohc: "#",
  ohc_statustracker: "/corporatehome/ohc/analytics",
  ohc_healthrecords: "/corporatehome/ohc/ohcrecords",
  ohc_myopd: "/corporatehome/ohc/myopd",
  ohc_upcoming: "/corporatehome/ohc/upcoming",
  ohc_injuries: "/corporatehome/ohc/injuries",

  employeedetails: "#",
  employeedetails_compliancerecords: "/corporatehome/employeedetails/compliancerecords",
  employeedetails_employeelifecycles: "/corporatehome/employeedetails/employeelifecycle",
  employeedetails_enrollnew: "/corporatehome/employeedetails/enrollnew",
  employeedetails_analytics: "/corporatehome/employeedetails/analytics",
  employeedetails_form21: "/corporatehome/employeedetails/form21",
  employeedetails_form24: "/corporatehome/employeedetails/form24",

  staffing: "#",
  staffing_mbbs: "/corporatehome/staffing/doctors",
  staffing_nursing: "/corporatehome/staffing/nurses",

  pharmacy: "#",
  pharmacy_analytics: "/corporatehome/pharmacy/analytics",
  pharmacy_medicines: "/corporatehome/pharmacy/inventory",
  pharmacy_equipments: "/corporatehome/pharmacy/equipments",
  pharmacy_consumables: "/corporatehome/pharmacy/consumables",

  insurance: "#",
  insurance_groupinsurance: "/corporatehome/insurance/groupinsurance",

  tickets: "#",
  tickets_dashboard: "/corporatehome/tickets/dashboard",
  adminservice: "/corporatehome/tickets/adminservice",
  healthservice: "/corporatehome/tickets/healthservice",

  firstaid: "#",
  firstaid_healthchampions: "/corporatehome/firstaid/healthchampions",
  firstaid_gallery: "/corporatehome/firstaid/photogallery",

  csr: "#",
  csr_statustracker: "/corporatehome/csr/statustracker",
  csr_healthrecords: "/corporatehome/csr/healthrecords",
  csr_gallery: "/corporatehome/csr/photogallery",

  healthawarenesssessions: "#",
  healthawarenesssessions_safetysessions:
    "/corporatehome/healthawarenesssessions/completedsessions",
  healthawarenesssessions_healthsessions: "/corporatehome/healthawarenesssessions/booksessions",

  noaccess: "/corporatehome/noaccess",
};

export const documentUrlList = (data) => {
  const val = [
    {
      title: "Eye Report",
      url: data?.eyeTestUrl,
      display: true,
    },
    {
      title: "X-Ray Report",
      url: data?.xrayUrl,
      display: true,
    },
    {
      title: "X-Ray Film",
      url: data?.xrayFilmUrl,
      display: true,
    },
    {
      title: "Audiometry Report",
      url: data?.audiometryUrl,
      display: true,
    },
    {
      title: "Blood Test Report",
      url: data?.bloodTestUrl,
      display: true,
    },
    {
      title: "Additional Report",
      url: data?.miscellaneousUrl,
      display: true,
    },
    {
      title: "Prescription",
      url: data?.prescriptionUrl,
      display: true,
    },
    {
      title: "Urine Test Report",
      url: data?.urineTestUrl,
      display: true,
    },
    {
      title: "PFT Report",
      url: data?.pftUrl,
      display: true,
    },
    {
      title: "CBC Report",
      url: data?.cbcUrl,
      display: true,
    },

    {
      title: "Serum bilirubin",
      url: data?.serumBilirubinUrl,
      display: true,
    },

    {
      title: "ECG",
      url: data?.ecgUrl,
      display: true,
    },

    {
      title: "Food Certificate",
      url: data?.medicalFitnessFoodUrl,
      display: true,
    },

    {
      title: "Stool Test report",
      url: data?.stoolTestUrl,
      display: true,
    },

    {
      title: "Summary report",
      url: data?.summaryTestUrl,
      display: true,
    },

    {
      title: "Sonography report",
      url: data?.sonographyUrl,
      display: true,
    },

    {
      title: "hbsAgPF report",
      url: data?.hbsAgPFTestUrl,
      display: true,
    },

    {
      title: "Form 32",
      url: data?.form32Url || data?.physicalFitnessTestUrl,
      display: true,
    },

    {
      title: "Form 35",
      url: data?.form35Url,
      display: true,
    },

    {
      title: "Physical Fitness Form",
      url: data?.physicalFitnessFormUrl,
      display: true,
    },
    {
      title: "Fitness Certificate",
      url: data?.fitnessCertificateUrl,
      display: true,
    },
    {
      title: "Annexure",
      url:
        localStorage.getItem("CORPID") === "80d6e65b-dc26-4162-ad2d-019990e1633c"
          ? data?.consolidatedReportUrl
          : data?.annexureUrl,
      display: true,
    },

    {
      title: "Vaccination Certificate",
      url: data?.vaccinationCertificateUrl,
      display: true,
    },

    {
      title: "All Reports",
      url: "allreports",
      display: true,
    },
  ];
  return val;
};

export const documentUrlListNew = (data, ahcConfig, corpId) => {
  const val = [
    {
      title: "Eye Report",
      url: data?.eyeTestUrl,
      display: true,
    },
    {
      title: "X-Ray Report",
      url: data?.xrayUrl,
      display: true,
    },
    {
      title: "X-Ray Film",
      url: data?.xrayFilmUrl,
      display: true,
    },
    {
      title: "Audiometry Report",
      url: data?.audiometryUrl,
      display: true,
    },
    {
      title: "Blood Test Report",
      url: data?.bloodTestUrl,
      display: ahcConfig.find((v) => v.nameId === "bloodTestUrl")?.access,
    },
    {
      title: "Additional Report",
      url: data?.miscellaneousUrl,
      display: true,
    },
    {
      title: "Prescription",
      url: data?.prescriptionUrl,
      display: true,
    },
    {
      title: "Urine Test Report",
      url: data?.urineTestUrl,
      display: true,
    },
    {
      title: "PFT Report",
      url: data?.pftUrl,
      display: true,
    },
    {
      title: "CBC Report",
      url: data?.cbcUrl,
      display: true,
    },

    {
      title: "Serum bilirubin",
      url: data?.serumBilirubinUrl,
      display: true,
    },

    {
      title: "ECG",
      url: data?.ecgUrl,
      display: true,
    },

    {
      title: "Food Certificate",
      url: data?.medicalFitnessFoodUrl,
      display: true,
    },

    {
      title: "Stool Test report",
      url: data?.stoolTestUrl,
      display: true,
    },

    {
      title: "Summary report",
      url: data?.summaryTestUrl,
      display: true,
    },

    {
      title: "Sonography report",
      url: data?.sonographyUrl,
      display: true,
    },

    {
      title: "hbsAgPF report",
      url: data?.hbsAgPFTestUrl,
      display: true,
    },

    {
      title: "Form 32",
      url: data?.form32Url || data?.physicalFitnessTestUrl,
      display: true,
    },

    {
      title: "Form 35",
      url: data?.form35Url,
      display: true,
    },

    {
      title: "Physical Fitness Form",
      url: data?.physicalFitnessFormUrl,
      display: true,
    },
    {
      title: "Fitness Certificate",
      url: data?.fitnessCertificateUrl,
      display: true,
    },
    {
      title: "Annexure",
      url:
        localStorage.getItem("CORPID") === "80d6e65b-dc26-4162-ad2d-019990e1633c"
          ? data?.consolidatedReportUrl
          : data?.annexureUrl,
      display: true,
    },

    {
      title: "Vaccination Certificate",
      url: data?.vaccinationCertificateUrl,
      display: true,
    },

    {
      title: "All Reports",
      url: "allreports",
      display: corpId === "86870741-5de9-4891-bd1a-1837ef673b66" ? false : true,
    },
  ];
  return val;
};
