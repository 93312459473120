import { InputAdornment, TextField } from "@mui/material";
import flag from "../../../assets/images/svg/flag.svg";

const MobileComp = ({ mobileNumber, setMobileNumber }) => {
  return (
    <TextField
      fullWidth
      size="medium"
      placeholder="Phone Number"
      required
      autoComplete="mobileNumber"
      label="Mobile Number"
      value={mobileNumber || ""}
      onChange={(e) => {
        if (!isNaN(e.target.value) && e.target.value.length < 11) {
          setMobileNumber(e.target.value);
        }
      }}
      InputLabelProps={{
        style: { fontSize: ".875rem", color: "#127DDD" },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            sx={{ borderRight: "1px solid black" }}
          >
            <img
              src={flag}
              style={{
                borderRight: "1px solid #127DDD",
                paddingRight: 10,
              }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default MobileComp;
