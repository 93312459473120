import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const DoctorBasicInfo = (props) => {
  const [profileInfo, setProfileInfo] = useState({ l: "" });

  useEffect(() => {
    if (props.profileData) {
      setProfileInfo(props.profileData.profileInfo);
    }
  }, [props.profileData]);

  console.log({ profileInfo: profileInfo });
  return (
    <Box sx={{ p: 2 }}>
      <Grid container>
        <Grid item lg={12}>
          <Typography variant="h5">{profileInfo.fullName}</Typography>
        </Grid>

        <Grid item lg={12}>
          <Typography variant="subtitle1">
            {profileInfo.degree} <br />
            {profileInfo.specialization} <br />
            PHONE:&nbsp;
            {profileInfo.mobile},&nbsp;EMAIL:&nbsp;{profileInfo.email}
            <br />
            Reg No:&nbsp;{profileInfo.rmpNo}
          </Typography>
        </Grid>

        <Grid item lg={12}>
          <Box>
            <Grid container columnSpacing={6} sx={{ mt: 3 }}>
              <Grid item lg={6}>
                <Typography variant="h6">DEPARTMENT</Typography>
                <Typography variant="subtitle1">
                  {profileInfo.department}
                </Typography>
              </Grid>
              <Grid item lg={6}>
                <Typography variant="h6">EXPERIENCE</Typography>
                <Typography variant="subtitle1">
                  {profileInfo.experience} Years
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item lg={12}>
          <Box>
            <Grid container columnSpacing={6} sx={{ mt: 3 }}>
              <Grid item lg={6}>
                <Typography variant="h6">UNIT</Typography>
                <Typography variant="subtitle1">{profileInfo.unit}</Typography>
              </Grid>
              <Grid item lg={6}>
                <Typography variant="h6">LANGUAGES</Typography>
                <Typography variant="subtitle1">
                  {profileInfo.languages}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item lg={12}>
          <Box>
            <Grid container columnSpacing={6} sx={{ mt: 3 }}>
              <Grid item lg={6}>
                <Typography variant="h6">Working Time</Typography>
                <Typography variant="subtitle1">
                  {profileInfo.workingTime}
                </Typography>
              </Grid>
              <Grid item lg={6}>
                <Typography variant="h6">Qualification Details</Typography>
                <Typography variant="subtitle1">
                  {profileInfo.degree}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DoctorBasicInfo;
