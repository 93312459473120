import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Chip, Divider, Typography } from "@mui/material";

const style = {
    header: {
        // borderRight: "1px solid black",
        textAlign: "center",
        color: "#127DDD",
    },
    item: {
        borderRight: "0.5px solid black",
    },
    font: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        py: 0.7,
    },
};

const Medicines = (props) => {
    const dosage = props.medicines.dosage;
    const m = props.medicines.m ? dosage : 0;
    const a = props.medicines.a ? dosage : 0;
    const n = props.medicines.n ? dosage : 0;

    console.log(dosage);
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container border={0.3}>
                    <Grid item lg={3} sx={style.item}>
                        <Typography variant="caption" sx={style.font}>
                            {props.medicines.medicineName}
                        </Typography>
                    </Grid>

                    <Grid item lg={1} sx={{ pl: 0.5, borderRight: "0.5px solid black" }}>
                        <Typography variant="caption" sx={style.font}>
                            {props.medicines.dosage}
                        </Typography>
                    </Grid>

                    <Grid item lg={2} sx={style.item}>
                        <Typography variant="caption" sx={style.font}>
                            {props.medicines.frequencyString}
                            {props.medicines.frequencyStringVernacular
                                ? " (" + props.medicines.frequencyStringVernacular + ")"
                                : ""}
                        </Typography>
                    </Grid>

                    <Grid item lg={3} sx={style.item}>
                        <Typography variant="caption" sx={style.font}>
                            {props.medicines.timing}
                        </Typography>
                    </Grid>

                    <Grid item lg={1} sx={style.item}>
                        <Typography variant="caption" sx={style.font}>
                            {props.medicines.durationString}
                        </Typography>
                    </Grid>

                    <Grid item lg={2} sx={style.item}>
                        <Typography variant="caption" sx={style.font}>
                            {props.medicines.comment}
                            {props.medicines.commentVernacular ? " (" + props.medicines.commentVernacular + ")" : ""}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default Medicines;
