import { Box, Grid, Stack } from "@mui/material";
import { Fragment } from "react";
import { CustomTypographyHeading, CustomTypographyParagraph } from "./customTypography";
import ChatIcon from "@mui/icons-material/Chat";

const ChatGreetings = () => {
  return (
    <Fragment>
      <Grid item lg={12}>
        <Stack direction="row" spacing={1} display="flex" alignItems="center" sx={{ pt: 2 }}>
          <ChatIcon fontSize="8px" color="primary" />
          <CustomTypographyHeading>UnoTalk</CustomTypographyHeading>
        </Stack>
      </Grid>
      <Grid item lg={12}>
        <Box
          sx={{
            background: "rgb(255, 255, 255)",
            py: 2,
            px: 3,
            maxWidth: "100%",
            borderRadius: 4,
            borderTopLeftRadius: 0,
          }}>
          <CustomTypographyParagraph>Hi, it's great to see you! 👋</CustomTypographyParagraph>
        </Box>
      </Grid>
      <Grid item lg={12}>
        <Box
          sx={{
            background: "rgb(255, 255, 255)",
            py: 2,
            px: 3,
            maxWidth: "100%",
            borderRadius: 4,
            //borderTopLeftRadius: 0,
            textOverflow: "ellipsis",
          }}>
          <CustomTypographyParagraph>
            What information are you looking for? Please use the navigation below or ask me anything
            about UnoBot product. 🪄
          </CustomTypographyParagraph>
        </Box>
      </Grid>
    </Fragment>
  );
};

export default ChatGreetings;
