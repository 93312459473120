import RootTemplate from "../../global/templates/rootTemplate";
import OhcRecordsMain from "../../modules/ohc/ohcRecords/ohcRecordsMain";

const OHCHealthRecordsIndex = ({ corpId = localStorage.getItem("CORPID") }) => {
  return (
    <RootTemplate>
      <OhcRecordsMain />
    </RootTemplate>
  );
};

export default OHCHealthRecordsIndex;
