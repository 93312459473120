import { Grid, Typography } from "@mui/material";

const PersonalVisitRequired = (props) => {
    return (
        <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ alignItems: "center" }}>
                <Typography
                    sx={{
                        marginTop: "20px",
                        fontWeight: 700,
                        lineHeight: "13px",
                        color: "#404040",
                    }}>
                    PERSONAL VISIT NEEDED
                </Typography>
                <Typography sx={{ marginTop: "10px", borderBottom: "1px solid #000000" }}>{props.personalVisitNeeded ? "YES" : "NO"}</Typography>
            </Grid>
        </Grid>
    );
};

export default PersonalVisitRequired;
