import { Button, Container, Grid, TextField } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import CustomAutocomplete from "../../../../../assets/customAutocomplete/customAutocomplete";
import UploadMedicinesModal from "./uploadMedicinesModal";
import GlobalDateLayout from "../../../../../assets/globalDateLayout/globalDateLayout";
import dayjs from "dayjs";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { useSnackbar } from "notistack";
import { downloadCsv } from "../../../../../assets/utils";
import { saveData } from "../../../../../services/api/postApi";
import { handleFetchMasterData } from "../../../../services/inventory";
const determineQuantityField = (unit, quantity) => {
  if (unit.startsWith("Base Unit")) {
    return {
      inwardBaseUnitQuantity: quantity,
      inwardPrimaryPackageQuantity: null,
      inwardSecondaryPackageQuantity: null,
    };
  } else if (unit.startsWith("PKU")) {
    return {
      inwardBaseUnitQuantity: null,
      inwardPrimaryPackageQuantity: quantity,
      inwardSecondaryPackageQuantity: null,
    };
  } else if (unit.startsWith("SKU")) {
    return {
      inwardBaseUnitQuantity: null,
      inwardPrimaryPackageQuantity: null,
      inwardSecondaryPackageQuantity: quantity,
    };
  } else {
    return {
      inwardBaseUnitQuantity: quantity,
      inwardPrimaryPackageQuantity: null,
      inwardSecondaryPackageQuantity: null,
    };
  }
};

const AddInventory = ({
  corpId = localStorage.getItem("CORPID"),
  userAuthID = localStorage.getItem("ORGUSERIDAUTH"),
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState({
    productNameWithBrand: "",
    productName: "",
    productCode: "",
    brandName: "",
    batchNo: "",
    expiryDate: null,
    unit: "",
    quantity: "",
    batchFlag: "",
  });
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    const url = BASE_URL + `inventory/saveTransaction?transactionType=PROCUREMENT&corpId=${corpId}`;

    const quantityFields = determineQuantityField(formValues.unit, formValues.quantity);

    const payload = [
      {
        batchCode: formValues.batchNo,
        batchExpiryDate: formValues.expiryDate,
        productCode: formValues.productCode,
        ...quantityFields,
        transactionBy: userAuthID,
        transactionDate: dayjs()?.format("YYYY-MM-DD"),
      },
    ];
    const result = await saveData(url, payload);
    if (result.error) {
      enqueueSnackbar(`${result.error.response.data.message}`, {
        variant: "error",
      });
    } else {
      enqueueSnackbar("Successfully Saved", {
        variant: "success",
      });

      setFormValues({
        productNameWithBrand: "",
        productName: "",
        productCode: "",
        brandName: "",
        batchNo: "",
        expiryDate: null,
        unit: "",
        quantity: "",
        batchFlag: "",
      });

      if (result?.data?.nonValidProductCodeList?.length > 0) {
        downloadCsv(
          result?.data?.nonValidProductCodeList,
          "Error_From_data_NonValidProductCodeList"
        );
      }
    }
  };

  const [allProducts, setAllProducts] = useState([]);
  const [unitOptions, setUnitOption] = useState([]);

  useEffect(() => {
    handleFetchMasterData(setAllProducts);
  }, []);

  const allProductsWithBrandName = allProducts.map((item, index) => ({
    id: index,
    productNameWithBrand: item.productName + " Brand Name - " + item.brand,
    ...item,
  }));

  const handleProductNameChange = (event, newValue, reason) => {
    const selectedProduct = allProductsWithBrandName.filter(
      (product) => product?.productNameWithBrand === newValue
    );
    setFormValues({
      ...formValues,
      productNameWithBrand: newValue,
      productName: selectedProduct[0]?.productName,
      brandName: selectedProduct[0]?.brand,
      productCode: selectedProduct[0]?.productCode,
      batchFlag: selectedProduct[0]?.batchFlag,
    });

    const tempUnitOptions = [
      `Base Unit - ${selectedProduct[0]?.baseUnit || ""}`,
      `PKU - ${selectedProduct[0]?.primaryPackagingUnitName || ""}`,
      `SKU - ${selectedProduct[0]?.secondaryPackagingUnitName || ""}`,
    ];

    setUnitOption(tempUnitOptions);

    if (reason === "clear") {
      setFormValues({
        productNameWithBrand: "",
        productName: "",
        productCode: "",
        brandName: "",
        batchNo: "",
        expiryDate: null,
        unit: "",
        quantity: "",
      });
    }
  };

  console.log({ formValues });

  return (
    <Fragment>
      <Grid container spacing={2} sx={{ mt: 0.5 }}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <CustomAutocomplete
            options={[
              ...new Set(allProductsWithBrandName.map((product) => product.productNameWithBrand)),
            ]}
            required={true}
            label={"Product Name"}
            placeholder={"Enter Product Name"}
            value={formValues.productNameWithBrand}
            onChange={handleProductNameChange}
            getOptionLabel={(option) => option}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            value={formValues.brandName}
            onChange={(e) => {
              setFormValues({ ...formValues, brandName: e.target.value });
            }}
            size="small"
            fullWidth
            label={"Brand Name"}
            placeholder={"Enter Brand Name"}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            required={formValues.batchFlag === "ENABLE" ? true : false}
            size="small"
            fullWidth
            label={"Batch No"}
            placeholder={"Enter Batch No"}
            value={formValues.batchNo}
            onChange={(e) => {
              setFormValues({ ...formValues, batchNo: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <GlobalDateLayout
            required={
              formValues.batchFlag === "ENABLE"
                ? true
                : formValues.batchFlag === "DISABLE"
                ? false
                : true
            }
            initialDate={formValues.expiryDate}
            setFormValues={setFormValues}
            property={"expiryDate"}
            disablePast={true}
            formValues={formValues}
            label={"Expiry Date"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <CustomAutocomplete
            options={unitOptions}
            required={true}
            value={formValues.unit}
            onChange={(event, newValue) => {
              setFormValues({
                ...formValues,
                unit: newValue,
              });
            }}
            label={"Unit"}
            placeholder={"Select Unit"}
            getOptionLabel={(option) => option}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <TextField
            required={true}
            value={formValues.quantity}
            onChange={(e) => {
              setFormValues({
                ...formValues,
                quantity: e.target.value,
              });
            }}
            size="small"
            fullWidth
            label={"Quantity"}
            placeholder={"Enter Quantity"}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10vh",
          }}>
          <Button
            disabled={
              formValues.batchFlag === "ENABLE"
                ? formValues.batchNo &&
                  formValues.expiryDate &&
                  formValues.quantity &&
                  formValues.productName &&
                  formValues.unit
                  ? false
                  : true
                : formValues.batchFlag === "DISABLE"
                ? formValues.quantity && formValues.productName && formValues.unit
                  ? false
                  : true
                : false
            }
            onClick={handleSubmit}
            variant="contained"
            sx={{ textTransform: "capitalize" }}>
            Submit
          </Button>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        sx={{ textTransform: "capitalize", mt: 20, marginInline: 2 }}
        onClick={handleOpen}>
        Bulk Upload
      </Button>
      <UploadMedicinesModal open={open} handleClose={handleClose} />
    </Fragment>
  );
};

export default AddInventory;

{
  /* <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              required={true}
              size="small"
              fullWidth
              label={"Product Code"}
              placeholder={"Enter Product Name"}
              value={formValues.productCode}
              onChange={(e) => {
                setFormValues({ ...formValues, productCode: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              required={true}
              size="small"
              fullWidth
              label={"Batch No"}
              placeholder={"Enter Batch No"}
              value={formValues.batchCode}
              onChange={(e) => {
                setFormValues({ ...formValues, batchCode: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              size="small"
              fullWidth
              label={"Batch Remarks"}
              placeholder={"Enter Batch Remarks"}
              value={formValues.batchRemarks}
              onChange={(e) => {
                setFormValues({ ...formValues, batchRemarks: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <GlobalDateLayout
              required={true}
              initialDate={formValues.batchExpiryDate}
              setFormValues={setFormValues}
              property={"batchExpiryDate"}
              disablePast={true}
              formValues={formValues}
              label={"Expiry Date"}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              required={true}
              value={formValues.inwardPrimaryPackageQuantity}
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  inwardPrimaryPackageQuantity: e.target.value,
                });
              }}
              size="small"
              fullWidth
              label={"Primary Package Quantity"}
              placeholder={"Enter Primary Package Quantity"}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextField
              required={true}
              value={formValues.inwardSecondaryPackageQuantity}
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  inwardSecondaryPackageQuantity: e.target.value,
                });
              }}
              size="small"
              fullWidth
              label={"Secondary Package Quantity"}
              placeholder={"Enter Secondary Package Quantity"}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <CustomAutocomplete
              required={true}
              options={["BOX", "TABLET", "INJECTION", "SPRAY"]}
              value={formValues.inwardBaseUnitQuantity}
              onChange={(event, newValue) => {
                setFormValues({
                  ...formValues,
                  inwardBaseUnitQuantity: newValue,
                });
              }}
              label={"Select Base Unit"}
              placeholder={"Select Base Unit"}
              getOptionLabel={(option) => option}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10vh",
            }}
          >
            <Button
              disabled={
                formValues.productCode &&
                formValues.batchCode &&
                // formValues.batchRemarks &&
                formValues.batchExpiryDate &&
                formValues.inwardPrimaryPackageQuantity &&
                formValues.inwardSecondaryPackageQuantity &&
                formValues.inwardBaseUnitQuantity
                  ? false
                  : true
              }
              onClick={handleSubmit}
              variant="contained"
              sx={{ textTransform: "capitalize" }}
            >
              Submit
            </Button>
          </Grid>

          <Button
            variant="contained"
            sx={{ textTransform: "capitalize", mt: 20, marginInline: 2 }}
            onClick={handleOpen}
          >
            Bulk Upload
          </Button>
        </Grid> */
}

// productCode: "",
// batchCode: "",
// batchExpiryDate: null,
// batchRemarks: "",
// inwardSecondaryPackageQuantity: "",
// inwardPrimaryPackageQuantity: "",
// inwardBaseUnitQuantity: "",
// transactionBy: userAuthID,
// transactionDate: dayjs()?.format("YYYY-MM-DD"),
