import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  CustomHeadingChipLeft,
  CustomHeadingSubTitleLeft,
} from "../../global/headings/customHeadings";
import { CustomParagraph1 } from "../../global/paragraphs/customParagraphs";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const AboutUsRight = () => {
  const singlineLineText = ["Quality health care", "Only Qualified Doctors", "Quality health care"];

  return (
    <Grid container>
      <Grid item lg={12}>
        <CustomHeadingChipLeft>About Us</CustomHeadingChipLeft>
      </Grid>

      <Grid item lg={12} sx={{ marginTop: "10px" }}>
        <CustomParagraph1>
          UNO.care is a cutting-edge healthcare start-up that aims to spark a healthcare revolution
          in the hinterlands and suburbs of India, powered by the latest technology and
          forward-thinking innovation.
        </CustomParagraph1>
      </Grid>
      <Grid item lg={12} sx={{ marginTop: "10px" }}>
        <CustomParagraph1>
          UNO.care envisions a future where accessible and innovative healthcare solutions empower
          individuals and organisations to thrive.
        </CustomParagraph1>
      </Grid>
      <Grid item lg={12} sx={{ marginTop: "10px" }}>
        <CustomParagraph1>
          At UNO.care, our mission is to revolutionise healthcare by o􀈋ering accessible, highquality
          services to individuals and corporate partners. We aim to make health and wellness
          available to all, nurturing well-being, and instilling the confidence that with UNO.Care,
          'Sab Theek Ho Jayega'.
        </CustomParagraph1>
      </Grid>
      {/* <Grid item lg={12} sx={{ marginTop: "10px" }}>
        <CustomParagraph1>
          Our aim is to simplify a patient's journey to recovery. To achieve this, we have set-up
          labs with the best lab equipments, stores with all kinds of medicines, clinics with
          best-in industry doctors along with trained staff to assist patients.
        </CustomParagraph1>
      </Grid> */}
      <Grid item lg={12}>
        <List>
          {singlineLineText?.map((item, index) => (
            <ListItem key={index} dense={true}>
              <ListItemIcon>
                <CheckCircleIcon sx={{ color: "rgb(4, 99, 250)" }} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      boxSizing: "border-box",
                      color: "rgb(141, 142, 146)",
                      display: "block",
                      fontFamily: "Open Sans, sans-serif",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "24px",
                    }}>
                    {item}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item lg={12}>
        <Button
          variant="contained"
          size="large"
          sx={{
            borderRadius: 9,
            p: 2,
            width: 180,
            backgroundColor: "#0045B9",
            ":hover": {
              backgroundColor: "#0045B9",
            },
          }}>
          Read More
        </Button>
      </Grid>
    </Grid>
  );
};

export default AboutUsRight;
