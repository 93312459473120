import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import { Fragment, useCallback, useEffect, useState } from "react";
import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApi";

const PatientEmailId = ({ patient, setPatient, formValues }) => {
  const [oldPatientList, setOldPatientList] = useState([]);

  const [emailId, setEmailId] = useState("");

  useEffect(() => {
    setEmailId(patient ? patient : "");
  }, [patient]);

  const fetchOldPatient = useCallback(async (emailValue) => {
    const url = BASE_URL + "patient/cms/search?email=" + emailValue;

    const oldPatient = await getData(url, "");

    if (oldPatient.error) {
      console.log("error");
    } else {
      console.log("success");
      setOldPatientList(oldPatient.data);
    }
  }, []);

  console.log({ oldPatientList: oldPatientList });

  return (
    <Fragment>
      <Box>
        <Grid container>
          <Grid item lg={12}>
            <Autocomplete
              fullWidth
              freeSolo
              disableClearable
              size="small"
              options={oldPatientList}
              getOptionLabel={(option) => (option ? option.email : "")}
              renderOption={(props, option) => (
                <li {...props} key={option.patientId}>
                  {option.email}
                </li>
              )}
              onInputChange={(event, newInputValue) => {
                if (newInputValue) {
                  fetchOldPatient(newInputValue);
                }
                formValues["email"] = newInputValue;
                formValues["patientId"] = 0;
              }}
              name="emailId"
              value={emailId}
              selectOnFocus
              onChange={(event, value) => {
                setEmailId(value);
                setPatient(value);
                formValues["patientId"] = value.patientId;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Email ID"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default PatientEmailId;
