import { Chip } from "@mui/material";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import { useNavigate } from "react-router";

const GoToConsultationPage = (props) => {
    let patientDeatils = "";
    let patient = "";

    if (props.patientDeatils) {
        patientDeatils = props.patientDeatils;
        patient = patientDeatils.patient;
    }

    const navigate = useNavigate();
    let startConsultation = () => {
        navigate("/consultation", {
            state: {
                patient: patient,
                patientDeatils: patientDeatils,
                tabValue: "medication",
            },
        });
    };

    return (
        <Chip
            size="small"
            sx={{
                height: "2rem",
                minWidth: "6rem",
                border: "1.2px solid #127DDD",
                backgroundColor: "white",
                borderRadius: "0.7rem",
                "& .MuiChip-label": {
                    fontFamily: "Roboto",
                    color: "#127DDD",
                    fontWeight: "500",
                    // fontSize: "22px",
                    // lineHeight: "26px",
                },
                "&:hover": {
                    backgroundColor: "white",
                },
            }}
            // icon={<AnalyticsIcon />}
            label={props.label}
            color="primary"
            onClick={startConsultation}
            clickable
        />
    );
};

export default GoToConsultationPage;
