import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import BodyStats from "./bodyStats";
import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApi";

const BodyStatsIndex = ({ setSelectedTab, patientId, setSelectedVital }) => {
  const [vitalList, setVitalList] = useState([]);

  const fetchVitallistHandler = () => {
    const url = BASE_URL + "patient/vitalsList/" + patientId;

    const vitals = getData(url, "");
    if (vitals.error) {
      console.log("error");
    } else {
      console.log("success");
      setVitalList(vitals.data);
    }
  };

  useEffect(() => {
    fetchVitallistHandler();
  }, []);

  const handleChange = (value) => {
    setSelectedVital(value);
    setSelectedTab("bodystats");
  };
  console.log({ vitalList });

  const data = [
    {
      label: "BP",
      imagesrc: require("../../../assets/images/bloodpressuregauge1.png"),
      vitalList: vitalList,
      key: "bp",
    },
    {
      label: "Height & Weight",
      imagesrc: require("../../../assets/images/height2.png"),
      vitalList: vitalList,
      key: "height",
    },
    {
      label: "Eye Sight",
      imagesrc: require("../../../assets/images/eye1.png"),
      vitalList: vitalList,
      key: "eyesight",
    },
    {
      label: "Blood Sugar",
      imagesrc: require("../../../assets/images/diabetestest1.png"),
      vitalList: vitalList,
      key: "sugar",
    },
    {
      label: "Other Stats",
      imagesrc: require("../../../assets/images/healthcheck1.png"),
      vitalList: vitalList,
      key: "temperature",
    },
  ];
  return (
    <Fragment>
      <Box>
        <Grid container spacing={1}>
          {data.map((item, index) => (
            <Grid item lg={4} md={4} sm={4} xs={4} key={index}>
              <BodyStats
                label={item.label}
                image={item.imagesrc}
                onClick={() => handleChange(item)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Fragment>
  );
};

export default BodyStatsIndex;
