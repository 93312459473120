import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import LogoMain from "../logo/logoMainNew";
//import { Link } from "react-router-dom";
import { Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { HashLink as Link } from "react-router-hash-link";
import LogoMainNew from "../logo/logoMainNew";

const pages = [
  { name: "About Us", url: "/#aboutUs" },
  { name: "Locations", url: "/#locations" },
  { name: "Specialities", url: "/#specialities" },
  { name: "Contact Us", url: "/#contactUs" },
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const MyAppBarBeforeLogin = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar sx={{ boxShadow: "none" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", lg: "none" },
              }}>
              {pages.map((page, index) => (
                <MenuItem key={index} onClick={handleCloseNavMenu}>
                  <Link to={page.url} sx={{ my: 1, mx: 1.5 }}>
                    <Typography textAlign="center" sx={{ color: "black" }}>
                      {page.name}
                    </Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ width: "30", display: { xs: "flex", lg: "none" } }}>
            <Stack display="flex" alignItems="center">
              <Link to="/">
                <LogoMainNew width={200} />
              </Link>
              <Typography sx={{ mt: -1, fontSize: 8.5, color: "#127DDD" }} variant="caption">
                Sab theek ho jayega - सब ठीक हो जायेगा
              </Typography>
              <Typography sx={{ fontSize: 8.5 }} variant="caption">
                Mysticdoc Healthcare PVT LTD.
              </Typography>
            </Stack>
          </Box>

          <Box sx={{ display: { xs: "none", lg: "flex" } }}>
            <Stack display="flex" alignItems="center">
              <Link to="/">
                <LogoMainNew width={300} />
              </Link>
              <Typography sx={{ mt: -2, ml: 1, color: "#127DDD" }} variant="inherit">
                Sab theek ho jayega - सब ठीक हो जायेगा
              </Typography>
              <Typography sx={{ mt: 0, ml: 1 }} variant="inherit">
                Mysticdoc Healthcare PVT LTD.
              </Typography>
            </Stack>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", lg: "flex" } }}>
            {pages.map((page, index) => (
              <Button
                key={index}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 6,
                  ml: 3,
                  display: "block",
                  //fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 300,
                  fontSize: "12px",
                  lineHeight: "14px",
                  color: "#02155A",
                }}
                href={page.url}>
                {page.name}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Grid container>
              <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                <Stack direction="row">
                  <Button
                    variant="outlined"
                    sx={{
                      my: 6,
                      ml: 3,
                      display: "block",
                      fontStyle: "normal",
                      fontWeight: 300,
                      fontSize: "12px",
                      lineHeight: "14px",
                      color: "#FFFFFF",

                      background: "#127DDD",
                    }}
                    href={"/orglogin"}>
                    Corp Login
                  </Button>

                  <Button
                    variant="outlined"
                    sx={{
                      my: 6,
                      ml: 3,
                      display: "block",
                      //fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: 300,
                      fontSize: "12px",
                      lineHeight: "14px",
                      color: "#FFFFFF",

                      background: "#127DDD",
                    }}
                    href={"/login"}>
                    Doctor Login
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default MyAppBarBeforeLogin;
