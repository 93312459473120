import { Fragment } from "react";
import ProfileComp from "./comps/profileComp";

const EmployeeProfileMain = ({ employeeID, isBack }) => {
  console.log({ employeeID });
  return (
    <Fragment>
      <ProfileComp employeeID={employeeID} isBack={isBack} />
    </Fragment>
  );
};

export default EmployeeProfileMain;
