import { Container, Grid } from "@mui/material";
import DetailIndex from "./consulationDetail/detailIndex";

const ConsultationWithoutVideoIndex = ({ patient, patientDetails }) => {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{ p: 2, backgroundColor: "#F5F5F5" }}
    >
      <Grid container>
        {/* <Grid item xs={12} sm={12} md={12} lg={12}>
          <PatientInfoBar patient={patient} />
        </Grid> */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DetailIndex
            patient={patient}
            patientDetails={patientDetails}
            source="CONSULTATIONWITHOUTVIDEO"
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ConsultationWithoutVideoIndex;
