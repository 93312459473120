import { useCallback, useState, useContext } from "react";
import { Box, Button, Container } from "@mui/material";
import { getUserToken } from "../../../services/api/apiCalls";
import jwt_decode from "jwt-decode";
import { userContext } from "../../../common/context/usercontext";
import { useNavigate } from "react-router";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import UserNameComp from "./components/userNameComp";
import PasswordComp from "./components/passwordComp";
import { BASE_URL, BASE_URL_AUTH } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApi";
import { nabarURLList } from "../../../corporatePortal/global/constants";

const UserNameLoginForm = ({
  path,
  setSeverity,
  setMessage,
  setOpenNotice,
  setShowOTPForm,
}) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isRememberMe, setIsRememberMe] = useState(
    localStorage.getItem("SAVEDTOKEN") ? true : false
  );
  const contextData = useContext(userContext);
  let navigate = useNavigate();

  const handleSubmit = (event, name) => {
    event.preventDefault();
    const url = BASE_URL_AUTH + `authenticate`;

    const dataObj = {
      username: userName.trim(),
      password: password.trim(),
    };

    fetchOrgDataHandler(url, dataObj, isRememberMe, name);
  };

  const fetchOrgDataHandler = useCallback(
    async (url, data, rememberMe, name) => {
      const user = await getUserToken(url, data);
      if (user.error) {
        setSeverity("error");
        setMessage("Login failed! Enter Correct Credentials!");
        setOpenNotice(true);
        console.log({ "Error while authenticating user": user.error });
      } else {
        const data = user.data;

        const token = await data.token;
        contextData.authHeader = token;
        const dData = await jwt_decode(token);
        contextData.val = dData;
        localStorage.setItem("authHeader_local", token);
        console.log({ token });

        console.log({ "Success while authenticating user": dData });

        const role = await dData.role;
        const sub = await dData.sub;
        const roles = await dData.roles;

        const isRoles = roles ? roles.includes("ORG_ADMIN") : true;

        let userIDAuth = await dData.id;
        let userMobile = await dData.mobile;
        let userName = await dData.name;
        localStorage.setItem("ORGUSERIDAUTH", userIDAuth);
        localStorage.setItem("ORGUSERMOBILE", userMobile);
        localStorage.setItem("ORGUSERNAME", userName);
        localStorage.setItem("CORPROLE", isRoles);

        console.log({ role });
        console.log({ sub });

        if (dData && role === "ORG_ADMIN" && sub === name.trim()) {
          getCorpData(dData.userID);
        } else {
          setSeverity("error");
          setMessage("Login failed! Enter Correct Credentials!");
          setOpenNotice(true);
        }
        if (rememberMe) {
          localStorage.setItem("SAVEDTOKEN", token);
        } else {
          localStorage.removeItem("SAVEDTOKEN");
        }
      }
    },
    []
  );

  const getCorpData = async (userId) => {
    const url =
      BASE_URL +
      `org/corp/user/${userId}?role=ORG_SUPERADMIN&orgConfigType=UI_LINE_ITEMS`;
    const corp = await getData(url, "");

    if (corp.error) {
      console.log("error");
      setSeverity("error");
      setMessage("Login failed!");
      setOpenNotice(true);
    } else {
      console.log({ successCorpId: corp?.data });

      let permissions = corp.data?.orgRolePermissionsV2?.parentRoles;

      permissions = permissions?.map((item, index) => ({
        ...item,
        subMenu: item?.childRoles.map((val, ind) => ({
          ...val,
          url: val?.access
            ? nabarURLList[val.nameId] || nabarURLList["noaccess"]
            : nabarURLList["noaccess"],
        })),
      }));

      console.log({ permissions });

      localStorage.setItem("NAVBAR_PERMISSIONS", JSON.stringify(permissions));

      const tempData = permissions[0]?.childRoles[0]?.childRoles;
      localStorage.setItem("STATUSTRACKER_CONFIG", JSON.stringify(tempData));

      localStorage.setItem("CORPID", corp.data.corpId);
      localStorage.setItem("CORP_NAME", corp.data.orgName);
      localStorage.setItem("CORPURL", corp.data.orgLogoUrl);
      localStorage.setItem("CORPURLMOBILE", corp.data.orgLogoUrlMobile);
      localStorage.setItem("REFRESH_TIMER", corp.data.refreshTimer);
      localStorage.setItem("MASKEMPLOYEES", true);

      setSeverity("success");
      setMessage("Login successfull");
      setOpenNotice(true);
      navigate(path, { replace: true });
    }
  };

  return (
    <Box component="form" onSubmit={(e) => handleSubmit(e, userName)}>
      <Container maxWidth={"xs"}>
        <Grid container rowSpacing={3}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <UserNameComp userName={userName} setUserName={setUserName} />
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <PasswordComp password={password} setPassword={setPassword} />
          </Grid>

          <Grid
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
          >
            <Button
              size="large"
              sx={{ borderRadius: 3 }}
              fullWidth
              variant="contained"
              type="submit"
            >
              Login
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default UserNameLoginForm;
