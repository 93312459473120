import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import dayjs from "dayjs";
import corpResources from "../../../../assets/resourceUtils/corpResources";
import { BASE_URL } from "../../../../assets/constantsFile";
import { getData } from "../../../../services/api/getApi";

const SessionCard = ({ data }) => {
  return (
    <Box sx={{ marginBlock: 4 }}>
      <Box
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "380px",
          borderRadius: "10px",
          border: "1px solid #C1C1FF",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            width: 90,
            height: 90,
            borderRadius: "50%",
            zIndex: 999,
            marginTop: -8,
            alignContent: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={data.icon} style={{ width: 56, height: 56 }} />
        </Paper>

        <Typography
          variant="h6"
          sx={{ marginTop: 2, textAlign: "center", fontStyle: "Poppins" }}
        >
          {data.title}
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontStyle: "Poppins",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {data?.sessionDate
            ? `Session Date : ${dayjs(data?.sessionDate).format("DD MMM YYYY")}`
            : ""}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            marginTop: 1,
            padding: "4px 12px",
            backgroundColor:
              data.status === "TICKET_RAISED"
                ? "grey"
                : data.status === "PENDING"
                ? "red"
                : "#e0f7e9",
            color:
              data.status === "TICKET_RAISED"
                ? "#FFFFFF"
                : data.status === "PENDING"
                ? "#FFFFFF"
                : "#000000",
            borderRadius: 1,
            textAlign: "center",
            fontStyle: "Poppins",
          }}
        >
          {data.status === "TICKET_RAISED"
            ? "Pending For Confirmation"
            : data.status === "COMPLETED"
            ? "Completed"
            : data.status === "BOOKING_CONFIRMED"
            ? "Booking Confirmed"
            : ""}
        </Typography>
      </Box>
    </Box>
  );
};

const NoSessionCard = () => {
  return (
    <Box sx={{ marginBlock: 4 }}>
      <Box
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "380px",
          borderRadius: "10px",
          border: "1px solid #C1C1FF",
          height: 180,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            width: 90,
            height: 90,
            borderRadius: "50%",
            zIndex: 999,
            marginTop: -8,
            alignContent: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={corpResources.noSessionIcon}
            style={{ width: 56, height: 56 }}
          />
        </Paper>

        <Typography
          variant="h6"
          sx={{ marginTop: 2, textAlign: "center", fontStyle: "Poppins" }}
        >
          You missed to plan session for this month. Click on Book session right
          now to check available slots
        </Typography>
      </Box>
    </Box>
  );
};

const SessionList = ({ selectedMonthSessions }) => {
  const [sessionListData, setSessionListData] = useState([]);
  const getSessionList = async () => {
    const url = BASE_URL + `org/awarenessSessions/list`;
    const result = await getData(url);
    if (result.error) {
      console.log(result.error);
    } else {
      console.log({ BUUT: result.data });
      const temp = result.data
        .filter((item) =>
          selectedMonthSessions.some(
            (session) =>
              session?.sessionIdTicket?.toString() === item?.id?.toString()
          )
        )
        .map((obj) => ({
          ...obj,
          icon: obj.imageUrl,
          title: obj.sessionName,
          status: selectedMonthSessions.find(
            (session) =>
              session.sessionIdTicket?.toString() === obj?.id?.toString()
          ).status,
          sessionDate: selectedMonthSessions.find(
            (session) =>
              session.sessionIdTicket?.toString() === obj?.id?.toString()
          ).sessionDate,
        }));

      setSessionListData(temp);
    }
  };
  useEffect(() => {
    getSessionList();
  }, [selectedMonthSessions]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 1
        ? prevIndex - 2
        : Math.max(
            sessionListData.length - (sessionListData.length % 2 === 0 ? 2 : 1),
            0
          )
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < sessionListData.length - 2 ? prevIndex + 2 : 0
    );
  };

  useEffect(() => {
    setCurrentIndex(0);
  }, [selectedMonthSessions]);

  console.log({ sessionListData, selectedMonthSessions });

  return (
    <Fragment>
      <Box
        sx={{
          height: "65vh",
          maxWidth: "400px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <IconButton
          disabled={
            sessionListData.length === 2 ||
            sessionListData.length === 1 ||
            sessionListData.length === 0
              ? true
              : false
          }
          onClick={handlePrevClick}
          sx={{
            border: "0.5px solid #2D309F",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 1,
            marginBottom: 5,
            ":disabled": {
              border: "0.5px solid lightgrey",
            },
          }}
        >
          <ArrowForwardIosIcon
            sx={{
              transform: "rotate(270deg)",
              color:
                sessionListData.length === 2 ||
                sessionListData.length === 1 ||
                sessionListData.length === 0
                  ? "lightgrey"
                  : "#2D309F",
              strokeWidth: 0.5,
            }}
          />
        </IconButton>

        <Box
          sx={{
            minHeight: "57vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          {sessionListData
            .slice(currentIndex, currentIndex + 2)
            .map((item, index) => (
              <SessionCard data={item} key={index} />
            ))}

          {sessionListData.length === 0 && <NoSessionCard />}
        </Box>
        <IconButton
          disabled={
            sessionListData.length === 2 ||
            sessionListData.length === 1 ||
            sessionListData.length === 0
              ? true
              : false
          }
          onClick={handleNextClick}
          sx={{
            border: "0.5px solid #2D309F",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 1,
            ":disabled": {
              border: "0.5px solid lightgrey",
            },
          }}
        >
          <ArrowForwardIosIcon
            sx={{
              transform: "rotate(90deg)",
              color:
                sessionListData.length === 2 ||
                sessionListData.length === 1 ||
                sessionListData.length === 0
                  ? "lightgrey"
                  : "#2D309F",
              strokeWidth: 0.5,
            }}
          />
        </IconButton>
      </Box>
    </Fragment>
  );
};

export default SessionList;
