import { Fragment } from "react";
import ViewTicketDetailsHealthAwareness from "./awarenessSession/viewTicketDetailsHealthAwareness";
import ViewTicketDetailsPreemployment from "./preEmployment/viewTicketDetailsPreemployment";
import ViewTicketDetailsNewService from "./newServices/viewTicketDetailsNewService";
import ViewTicketDetailsServiceIssue from "./serviceIssue/viewTicketDetailsServiceIssue";
import ViewTicketDetailsEmergency from "./emergency/viewTicketDetailsEmergency";
import ViewTicketDetailsCorpAdmin from "./generalQuery/viewTicketDetailsCorpAdmin";
import ViewTicketDetails from "./defaultView/viewTicketDetails";

const ViewTicketDetailsMain = ({ data }) => {
  return (
    <Fragment>
      {data.ticketType === "HEALTH_AWARENESS" ? (
        <ViewTicketDetailsHealthAwareness data={data} />
      ) : data.ticketType === "PRE_EMPLOYMENT" ? (
        <ViewTicketDetailsPreemployment data={data} />
      ) : data.ticketType === "NEW_SERVICE_INQUIRY" ? (
        <ViewTicketDetailsNewService data={data} />
      ) : data.ticketType === "SERVICE_ISSUE" ? (
        <ViewTicketDetailsServiceIssue data={data} />
      ) : data.ticketType === "EMERGENCY" ? (
        <ViewTicketDetailsEmergency data={data} />
      ) : data.ticketType === "CORP_ADMIN" ? (
        <ViewTicketDetailsCorpAdmin data={data} />
      ) : (
        <ViewTicketDetails data={data} />
      )}
    </Fragment>
  );
};

export default ViewTicketDetailsMain;
