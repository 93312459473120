import { Box, Typography } from "@mui/material";

const ShowTitle = ({ title }) => {
  return (
    <Box>
      <Typography
        variant="h5"
        sx={{ marginBlock: "20px", fontWeight: "400", lineHeight: "20px", color: "#000000" }}>
        {title}
      </Typography>
    </Box>
  );
};

export default ShowTitle;
