import React from "react";
import { Fragment } from "react";
import TopBar from "./subComps/topBar";
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import LogoMainNew from "../../../common/logo/logoMainNew";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import CallIcon from "@mui/icons-material/Call";

const LandingHeader = ({ window, scrollToContact, scrollToAbout, scrollToSpecification }) => {
  const navItems = [
    { name: "About Us", onClick: scrollToAbout },
    { name: "Locations", url: "/doctor" },
    { name: "Specifications", onClick: scrollToSpecification },
    { name: "Contact Us", onClick: scrollToContact },
  ];
  // const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box>
      <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
        <List>
          {navItems.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton href={item.url} sx={{ textAlign: "center" }}>
                <ListItemText
                  onClick={item.onClick}
                  primary={item.name}
                  sx={{
                    color: "#000000",
                    "&:hover": {
                      color: "#0045B9", // Text color on hover
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}

          {/* Manually added buttons */}
          <ListItemButton href="/orglogin" sx={{ textAlign: "center" }}>
            <ListItemText
              primary="Corporate Login"
              sx={{
                color: "#000000",
                "&:hover": {
                  color: "#0045B9", // Text color on hover
                },
              }}
            />
          </ListItemButton>

          <ListItemButton href="/login" sx={{ textAlign: "center" }}>
            <ListItemText
              primary="Doctor Login"
              sx={{
                color: "#000000",
                "&:hover": {
                  color: "#0045B9", // Text color on hover
                },
              }}
            />
          </ListItemButton>
        </List>
      </Box>
    </Box>
  );
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Fragment>
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: "none", sm: "none", md: "none", lg: "block", xl: "block" },
        }}>
        <TopBar />
      </Box>
      <AppBar position="sticky">
        <Container maxWidth={false}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, display: { xs: "block", sm: "block" } }}>
              <Link to="/">
                <LogoMainNew width={200} />
              </Link>
            </Box>

            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "flex", marginRight: "5px", marginBlock: "5px" }}>
                <CallIcon sx={{ color: "#0463FA", height: "20px", marginRight: "5px" }} />
                <Typography variant="body2" gutterBottom>
                  1800 8890 189
                </Typography>
              </Box>
              <Box sx={{ display: "flex", marginRight: "5px", marginTop: "-2px" }}>
                <IconButton
                  sx={{ cursor: "pointer", display: "flex" }}
                  underline="none"
                  href="https://www.facebook.com/profile.php?id=100088725383722&mibextid=LQQJ4d"
                  target={"_blank"}>
                  <FacebookIcon
                    sx={{
                      color: "#0463FA",
                      height: "20px",
                      marginRight: "5px",
                    }}
                  />
                </IconButton>
                <IconButton href="">
                  <TwitterIcon sx={{ color: "#0463FA", height: "20px", marginRight: "5px" }} />
                </IconButton>
                <IconButton href="">
                  <LinkedInIcon sx={{ color: "#0463FA", height: "20px", marginRight: "5px" }} />
                </IconButton>
                <IconButton
                  sx={{ cursor: "pointer", display: "flex" }}
                  underline="none"
                  href="https://instagram.com/uno.care?igshid=YmMyMTA2M2Y="
                  target={"_blank"}>
                  <InstagramIcon sx={{ color: "#0463FA", height: "20px", marginRight: "5px" }} />
                </IconButton>
              </Box>
            </Box>

            <Box sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block", xl: "block" } }}>
              {navItems.map((item, index) => (
                <Button
                  onClick={item.onClick}
                  key={index}
                  href={item.url}
                  sx={{
                    color: "#000000",
                    "&:hover": {
                      color: "#0045B9", // Text color on hover
                    },
                  }}>
                  {item.name}
                </Button>
              ))}
              <Button
                href="/orglogin"
                sx={{
                  mx: 2,
                  borderRadius: "6px",
                  border: "1.4px solid #0463FA",
                  color: "#0045B9",

                  "&:hover": {
                    color: "#0045B9",
                    backgroundColor: "#FFFFFF",

                    border: "1.4px solid #0045B9",
                  },
                }}>
                Corporate Login
              </Button>
              <Button
                href="/login"
                variant="contained"
                sx={{
                  mx: 2,
                  borderRadius: "6px",
                  backgroundColor: "#0463FA",
                  color: "#FFFFFF",
                  "&:hover": {
                    color: "#FFFFFF",
                    backgroundColor: "#0463FA",
                    border: "1.4px solid #0045B9",
                  },
                }}>
                Doctor Login
              </Button>
            </Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                display: { xs: "block", sm: "block", md: "block", lg: "none", xl: "none" },
              }}>
              <MenuIcon sx={{ color: "black" }} />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        anchor="right"
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
        }}>
        {drawer}
      </Drawer>
    </Fragment>
  );
};

export default LandingHeader;
