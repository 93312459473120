import { Chip, Box } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import CallIcon from "../../../assets/images/calloutgoing.svg";
import CallingIconBlack from "../../../assets/images/svg/callBlack.svg";

const GoToVideoCallComponent = (props) => {
  const [isCallUrl, setIsCallUrl] = useState(false);

  let patientDeatils = "";
  let patient = "";

  if (props.patientDeatils) {
    patientDeatils = props.patientDeatils;
    patient = patientDeatils.patient;
  }

  useEffect(() => {
    if (patientDeatils.videoURL) {
      setIsCallUrl(true);
    }
  }, []);

  const navigate = useNavigate();

  let startVideoCall = () => {
    navigate("/call", {
      state: {
        patient: patient,
        patientDeatils: patientDeatils,
        tabValue: "vital",
      },
    });
  };

  let startConsultation = () => {
    navigate("/consultation", {
      state: {
        patient: patient,
        patientDeatils: patientDeatils,
        tabValue: "medication",
      },
    });
  };

  return (
    <Fragment>
      {patientDeatils.consultationType === "WALKIN" && (
        <Chip
          size="small"
          icon={<Box component="img" alt="card" src={CallingIconBlack} />}
          onClick={startConsultation}
          clickable
          sx={
            ({
              color: "white",
              "& .MuiChip-label": { color: "white" },
            },
            {
              "& .MuiChip-icon": { marginLeft: "35%" },
            })
          }
          style={{
            backgroundColor: "#D9D9D9",
            height: "2rem",
            width: "2rem",
            borderRadius: "10px",
          }}
        />
      )}
      {patientDeatils.consultationType !== "WALKIN" && (
        <Chip
          size="small"
          icon={<Box component="img" alt="card" src={CallIcon} />}
          clickable
          onClick={startVideoCall}
          disabled={!isCallUrl}
          sx={{
            "& .MuiChip-icon": { marginLeft: "35%" },
          }}
          style={{
            backgroundColor: "#CFFFCE",
            height: "2rem",
            width: "2rem",
            borderRadius: "10px",
          }}
        />
      )}
    </Fragment>
  );
};

export default GoToVideoCallComponent;
