import { Box, Divider, Grid, Stack, Typography } from "@mui/material";

const PrintPrescriptions = (props) => {
  const medicines = props.prescriptionList;
  return (
    
    <Grid container>
        <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{minHeight: "250px", marginTop: "20px" }}
        >
            <Box sx={{ minHeight: "3vh", background: "#C0E5FF", p: 1, borderRadius: "5px" }}>
                <Grid container sx={{ mb: 1 }} columns={36}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Typography  variant="body2" sx={{ fontWeight: 600 }}>
                            Medicine
                        </Typography>
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={4}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            Dose
                        </Typography>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            Frequency
                        </Typography>
                    </Grid>

                    <Grid item lg={7} md={7} sm={7} xs={7}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            Timings
                        </Typography>
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={4}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            Days
                        </Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={9} xs={9}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            Instructions
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            {/* -------------------MEDICINE PRESCRIPTION-------------------*/}

            {medicines.map((medicine, index) => (
                <Box
                    sx={{ minHeight: "3vh", background: "#F1F8FF", borderRadius: "5px", p: 1, my: "5px" }}
                    key={index}
                >
                    <Grid container columns={36}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Typography variant="caption">{medicine.medicineName}</Typography>
                        </Grid>

                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            {<Typography variant="caption">{medicine.dosage}</Typography>}
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Stack>
                                <Typography variant="caption">{medicine.frequencyString}</Typography>
                                <Typography variant="caption">
                                    {medicine.frequencyStringVernacular
                                        ? "(" + medicine.frequencyStringVernacular + ")"
                                        : ""}
                                </Typography>
                            </Stack>
                        </Grid>

                        <Grid item lg={7} md={7} sm={7} xs={7}>
                            <Stack>
                                <Typography variant="caption"> {medicine.timing} </Typography>

                                <Typography variant="caption">
                                    {" "}
                                    {(medicine.m ? "सुबह" : "") +
                                        (medicine.m && (medicine.a || medicine.e || medicine.n) ? "-" : "") +
                                        (medicine.a ? "दोपहर बाद" : "") +
                                        (medicine.a && (medicine.e || medicine.n) ? "-" : "") +
                                        (medicine.e ? "शाम" : "") +
                                        (medicine.e && medicine.n ? "-" : "") +
                                        (medicine.n ? "रात" : "")}{" "}
                                </Typography>
                            </Stack>
                        </Grid>

                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            <Typography variant="caption">{medicine.durationString}</Typography>
                        </Grid>
                        <Grid item lg={9} md={9} sm={9} xs={9}>
                            <Stack>
                                <Typography variant="caption">{medicine.comment}</Typography>
                                <Typography variant="caption">
                                    {medicine.commentVernacular ? "(" + medicine.commentVernacular + ")" : ""}
                                </Typography>
                            </Stack>
                        </Grid>
                        {/* <Grid item lg={36} md={36} sm={36} xs={36}>
                            <Divider />
                        </Grid> */}
                    </Grid>
                </Box>
            ))}
        </Grid>
    </Grid>
  );
};

export default PrintPrescriptions;
