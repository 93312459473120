import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Portal,
  Typography,
  darken,
  lighten,
  styled,
} from "@mui/material";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  DataGrid,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
  useGridApiRef,
  GridPagination,
  GridFooterContainer,
  GridFooter,
} from "@mui/x-data-grid";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router";
import MuiPagination from "@mui/material/Pagination";
import { getUrlExtension } from "../../../../../assets/utils";
import { StyledDataGrid } from "../../../../global/customDatagrids/CustomStyledDatagrid";

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
      renderItem={(item) => (
        <PaginationItem
          type="last"
          slots={{
            previous: () => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ArrowBackIcon />
                <Typography sx={{ marginLeft: "10px" }}>Previous</Typography>
              </Box>
            ),
            next: () => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ marginRight: "10px" }}>Next</Typography>
                <ArrowForwardIcon />
              </Box>
            ),
          }}
          {...item}
        />
      )}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.7) : "rgb(179, 219, 241)";

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

const CustomDataPanels = ({
  dataList,
  columns,
  enableCheckbox = false,
  disableMultipleRowSelection = false,
}) => {
  console.log({ columns });
  const navigate = useNavigate();

  const apiRef = useGridApiRef();

  const [open, setOpen] = useState(false);

  const handleClickOpen = (url) => {
    const ext = getUrlExtension(url).toLowerCase();
    if (ext === "pdf") {
      window.open(url);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [selectedImage, setSelectedImage] = useState("");
  return (
    <Fragment>
      <Portal>
        <Dialog open={open} onClose={handleClose} maxWidth={"xl"} fullWidth={true}>
          <DialogContent>
            <Box
              sx={{ minHeight: "70vh" }}
              display="flex"
              justifyContent="center"
              alignItems="center">
              {selectedImage ? <img width="100%" src={selectedImage} /> : "No Document."}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Portal>

      <StyledDataGrid
        sx={{
          "&, [class^=MuiDataGrid]": { border: "none" },
          border: "none",
          borderRadius: 3,
          boxShadow: "0px 0px 6px 0px rgba(108, 120, 221, 0.40);",
        }}
        columnHeaderHeight={45}
        apiRef={apiRef}
        rowHeight={39}
        rows={dataList}
        columns={columns}
        getRowClassName={(params) => `super-app-theme--Normal`}
        disableRowSelectionOnClick
        pagination
        checkboxSelection={enableCheckbox}
        disableMultipleRowSelection={disableMultipleRowSelection}
        onRowSelectionModelChange={(itm) => console.log(itm)}
        slots={{
          pagination: CustomPagination,
        }}
      />
    </Fragment>
  );
};

export default CustomDataPanels;

export function CustomFooterStatusComponent(props) {
  return (
    <GridFooterContainer>
      <Grid container>
        <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="center">
          <GridFooter
            sx={{
              border: "none", // To delete double border.
              height: 70,
            }}
          />
        </Grid>
      </Grid>
    </GridFooterContainer>
  );
}
