import { Box, Divider, Grid, Typography } from "@mui/material";
import CustomBreadcrumbs from "../breadcrumbs/customBreadcrumbs";

const CustomTitles = ({ title = "Corporate" }) => {
  return (
    <Box sx={{ pb: 0.5, px: 2 }}>
      <Grid container>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          //justifyContent="center"
          alignItems="center"
        >
          <CustomBreadcrumbs />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6"> {title} </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomTitles;
