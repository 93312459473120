import { Box, Grid, Typography } from "@mui/material";
import CustomTitles from "../../../global/titles/customTitles";
import { useEffect, useState } from "react";
import { fetchForm21Data } from "../../../global/services/corporateServices";
import MonthSelector from "./comps/monthSelector";
import CustomPagination from "./comps/customPagination";
import CustomPDFViewer from "../../../../assets/customPDFViewer";
import TableHeader from "./comps/   tableHeader";
import TableData from "./comps/   tableData";

const AHCForm21Main = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [form21Data, setForm21Data] = useState([]);
  const [month, setMonth] = useState("JANUARY");
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchForm21Data(corpId, setForm21Data);
  }, []);

  console.log({ form21Data });

  return (
    <Box>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="" />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          {/* <TableHeader /> */}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {form21Data && form21Data.length > 0 ? (
            <TableData form21Data={form21Data} />
          ) : (
            <Box
              sx={{
                height: "60vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography>No Reports Available.</Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AHCForm21Main;
