import { Box, Stack, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Fragment, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const DateRangeFilters = ({ startDate = null, endDate = null, setStartDate, setEndDate }) => {
  console.log({ startDate, endDate });
  return (
    <Fragment>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  sx={{
                    width: 140,
                    "& fieldset": {
                      fontSize: 11,
                      height: 41,
                      borderRadius: 3,
                    },
                    "& .MuiOutlinedInput-input": {
                      fontFamily: "Roboto",
                      fontSize: 11,
                      fontWeight: 600,
                      color: "#000",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: 11,
                      color: "#404040",
                    },
                  }}
                />
              )}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  sx={{
                    width: 140,
                    "& fieldset": {
                      fontSize: 11,
                      height: 41,
                      borderRadius: 3,
                    },
                    "& .MuiOutlinedInput-input": {
                      fontFamily: "Roboto",
                      fontSize: 11,
                      fontWeight: 600,
                      color: "#000",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: 11,
                      color: "#404040",
                    },
                  }}
                />
              )}
            />
          </Stack>
        </LocalizationProvider>
      </Box>
    </Fragment>
  );
};

export default DateRangeFilters;
