import React, { Fragment } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import MyAppBarAfterLogin from "../common/header/myAppBarAfterLogin";
import { useLocation } from "react-router";
import Index from "../components/appointments";

const Patients = (props) => {
  const location = useLocation();

  console.log({
    "location.state.name":
      location && location.state ? location.state.name : "",
  });

  return (
    <Fragment>
      <CssBaseline />
      <MyAppBarAfterLogin />
      <Index />
    </Fragment>
  );
};

export default Patients;
