import { Alert, Box, Button, Snackbar } from "@mui/material";
import { useState } from "react";
import Papa from "papaparse";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { saveData, uploadFile } from "../../../../../services/api/postApi";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { getEmployeeListToBeTested } from "../../../../global/services/corporateServices";
import { updateData } from "../../../../../services/api/patchApi";

const allowedExtensions = ["csv"];

const strToBool = (str) => {
  return str === "YES" ? true : str === "NO" ? false : null;
};

const ParseCSV = ({ setEmployeeList, corpId }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [file, setFile] = useState("");

  const handleFileChange = (e) => {
    setError("");

    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setError("Please input a csv file");
        return;
      }

      // If input type is correct set the state
      setFile(inputFile);
    }
  };
  const handleParse = () => {
    // If user clicks the parse button without
    // a file we show a error
    if (!file) return alert("Enter a valid file");

    // Initialize a reader which allows user
    // to read any file or blob.
    const reader = new FileReader();

    // Event listener on reader when the file
    // loads, we parse it and set the data.
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, {
        header: true,
      });
      const parsedData = csv?.data;
      const rows = Object.keys(parsedData[0]);

      const columns = Object.values(parsedData[0]);
      const res = rows.reduce((acc, e, i) => {
        return [...acc, [[e], columns[i]]];
      }, []);
      console.log(res, parsedData);
      setData(parsedData);
      _uploadData(parsedData);
    };
    reader.readAsText(file);

    console.log({ file });
  };

  console.log({ data });

  const _uploadData = async (values) => {
    const url = BASE_URL + `org/register/bulk`;

    const payload = values
      .filter((obj) => obj.empId !== "")
      .map((item, index) => ({
        //id: "",
        name: item?.name,
        empId: item?.empId,
        imageUrl: item?.imageUrl,
        department: item?.department,
        mobile: item?.mobile,
        dateOfBirth: item?.dateOfBirth,
        gender: item?.gender,
        orgId: item?.orgId,
        age: item?.age,
        designation: item?.designation,
        city: item?.city,
        plant: item?.plant,
        corpId: corpId,
        bloodGroup: item?.bloodGroup,
        employmentType: item?.employmentType,
        preEmploymentStatus: item?.preEmploymentStatus,
        dateOfJoining: new Date(item?.dateOfJoining),
      }));

    console.log({ payload });

    const _finalPayload = {
      orgEmployeeVMS: payload,
      corpId: corpId,
    };
    const response = await saveData(url, _finalPayload);

    if (response.error) {
      console.log("error");
      setSeverity("error");
      setMessage("An error Occured");
      setOpenNotice(true);
    } else {
      console.log({ success: response.data });
      setSeverity("success");
      setMessage("Saved Successfully");
      setOpenNotice(true);
      getEmployeeListToBeTested(corpId, setEmployeeList);
      saveImage(response.data);
    }
  };

  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [openNotice, setOpenNotice] = useState(false);

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };

  const saveImage = async (id) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);

    const url = BASE_URL + `org/register/bulk/upload?id=${id}&corpId=${corpId}`;
    const csvFile = await uploadFile(url, formData);

    if (csvFile.error) {
      console.log("error");
    } else {
      console.log("success112File");
    }
  };

  return (
    <Box>
      <Snackbar open={openNotice} autoHideDuration={1000} onClose={handleCloseNotice}>
        <Alert onClose={handleCloseNotice} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Button variant="contained" component="label">
        Upload File&nbsp;
        <input onChange={handleFileChange} id="csvInput" name="file" type="File" />
      </Button>
      <Button onClick={handleParse}>Bulk Register</Button>
    </Box>
  );
};

export default ParseCSV;
