import React, { Fragment } from "react";
import MyAppBarAfterLogin from "../common/header/myAppBarAfterLogin";
import ProfileMainComponent from "../profile/profileMainComponent";

const Proflies = (props) => {
  return (
    <Fragment>
      <MyAppBarAfterLogin />
      <ProfileMainComponent />
    </Fragment>
  );
};

export default Proflies;
