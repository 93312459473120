import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Icon,
  IconButton,
  Link,
  Portal,
  Stack,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import MuiPagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiRef,
  GridPagination,
} from "@mui/x-data-grid";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getUrlExtension } from "../../../assets/utils";
import { EncryptedTypography, EncryptedTypographyName } from "../components/encryptedTypography";
import { transformDataDialog, transformDataNew } from "../actions/actions";
import { StyledDataGrid } from "./CustomStyledDatagrid";

const CustomTypography = ({ title }) => {
  return (
    <Typography
      sx={{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: 13,
        lineHeightt: 17,
        color: "#8A8A8A",
        textTransform: "capitalize",
      }}>
      {title}
    </Typography>
  );
};

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
      renderItem={(item) => (
        <PaginationItem
          type="last"
          slots={{
            previous: () => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ArrowBackIcon />
                <Typography sx={{ marginLeft: "10px" }}>Previous</Typography>
              </Box>
            ),
            next: () => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ marginRight: "10px" }}>Next</Typography>
                <ArrowForwardIcon />
              </Box>
            ),
          }}
          {...item}
        />
      )}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
    "& .paxton-table--header": {
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        padding: 0,
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 600,
        textTransform: "uppercase",
        fontSize: "16px",
      },
    },
    "& .paxton-table--row": {
      borderBottom: "0.5px solid #C5C5D3",
      margintop: 7,
      marginBottom: 7,
      backgroundColor: "#fff",
      paddingTop: 15,
      paddingBottom: 10,
      boxSizing: "content-box",
    },
    "& .MuiDataGrid-cell": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: 12,
      lineHeightt: 15,
      color: "##383838",
      textTransform: "capitalize",
      boxSizing: "none",
      borderBottom: "none ! important",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer": {
      border: "none",
    },
    "& .MuiDataGrid-viewport": {
      maxHeight: "unset !important",
    },
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "670px !important",
    },
    //'& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {display: 'none' }
  },
}));

const StatusTrackerDetailGrid = ({ setApiRef, employeeList }) => {
  const navigate = useNavigate();

  const colLeft = [
    {
      field: "emp_id",
      renderHeader: (params) => <CustomTypography title="Emp. ID" />,
      width: 100,
      type: "number",
      align: "left",
      headerAlign: "left",
      renderCell: (cellValues) => <EncryptedTypography text={cellValues?.value} />,
    },
    {
      field: "name",
      renderHeader: (params) => <CustomTypography title="Name" />,
      width: 400,
      valueFormatter: (value) => `${value.name}`,
      renderCell: (cellValues) => <EncryptedTypographyName data={cellValues} />,
    },

    {
      field: "department",
      renderHeader: (params) => <CustomTypography title="Department" />,
      width: 400,
    },
  ];

  const columns = [...colLeft];

  const classes = useStyles();

  const apiRef = useGridApiRef();

  useEffect(() => {
    setApiRef(apiRef);
  }, [apiRef]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = (url) => {
    const ext = getUrlExtension(url).toLowerCase();
    if (ext === "pdf") {
      window.open(url);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [selectedImage, setSelectedImage] = useState("");
  return (
    <Fragment>
      <Portal>
        <Dialog open={open} onClose={handleClose} maxWidth={"xl"} fullWidth={true}>
          <DialogContent>
            <Box
              sx={{ minHeight: "70vh" }}
              display="flex"
              justifyContent="center"
              alignItems="center">
              {selectedImage ? <img width="100%" src={selectedImage} /> : "No Document."}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Portal>

      <Box
        style={{
          height: "63vh",
          width: "100%",
          padding: "20px",
          backgroundColor: "#FFFFFF",
          border: "0.5px solid grey",
          borderRadius: "16px",
          marginBlock: "20px",
        }}>
        <Box style={{ display: "flex", height: "100%" }}>
          <Box
            sx={{
              flexGrow: 1,
              width: "100%",
              "& .super-app.negative": {
                backgroundColor: "rgba(157, 255, 118, 0.49)",
                color: "#1a3e72",
                fontWeight: "600",
              },
              "& .super-app.positive": {
                backgroundColor: "#d47483",
                color: "#1a3e72",
                fontWeight: "600",
              },
            }}>
            <StyledDataGrid
              sx={{ border: "none" }}
              getRowId={(row) => row.emp_id}
              apiRef={apiRef}
              rowHeight={40}
              rows={transformDataDialog(employeeList)}
              columns={columns}
              // className={classes.root}
              // getRowClassName={() => "paxton-table--row"}
              // getCellClassName={() => "paxton-table--cell"}
              disableRowSelectionOnClick
              slots={{
                pagination: CustomPagination,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default StatusTrackerDetailGrid;
