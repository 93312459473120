import { Box, Grid, Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import { VITALS_SUGAR } from "../../assets/constantsText";

const PrintVitals = (props) => {
    const vitalInfo = props.vitalInfo;
    return (
        <Fragment>
            
            {vitalInfo &&
            (vitalInfo.bp ||
                vitalInfo.weight ||
                vitalInfo.height ||
                vitalInfo.heartRate ||
                vitalInfo.spo2 ||
                vitalInfo.temperature ||
                vitalInfo.sugar) ? (
      <Grid>
        <Grid item lg={12} xs={12} md={12} sm={12}>
          <Typography sx={{ marginTop: "20px" , fontWeight: 700,lineHeight: "13px",color: "#404040", }}>VITALS</Typography>
        </Grid>
        <Grid container spacing={2}>
          {vitalInfo.height && vitalInfo.height > 0 ? (
            <Grid item lg={4} xs={4} md={4} sm={4} flexDirection="column">
              <Typography sx={{ marginTop: "10px" , fontWeight: 700,lineHeight: "13px",color: "#404040", }}>Height</Typography>
              <Typography
                sx={{
                  marginTop: "10px",
                  width: "100%",
                  height: "25px",
                  background: "#FFFFFF",
                  border: "0.5px solid #000000",
                }}
              >
                {" "}
                {vitalInfo.height} {" cm"}{" "}
              </Typography>
            </Grid>
          ) : (
            ""
          )}

          {vitalInfo.weight && vitalInfo.weight > 0 ? (
            <Grid item lg={4} xs={4} md={4} sm={4} flexDirection="column">
              <Typography sx={{ marginTop: "10px" , fontWeight: 700,lineHeight: "13px",color: "#404040", }}>Weight</Typography>
              <Typography
                sx={{
                  marginTop: "10px",
                  width: "100%",
                  height: "25px",
                  background: "#FFFFFF",
                  border: "0.5px solid #000000",
                }}
              >
                {" "}
                {vitalInfo.weight} {" kg"}{" "}
              </Typography>
            </Grid>
         ) : (
            ""
          )}

          {vitalInfo.bp && vitalInfo.bp.length > 1 ? (
            <Grid item lg={4} xs={4} md={4} sm={4} flexDirection="column">
              <Typography sx={{ marginTop: "10px" , fontWeight: 700,lineHeight: "13px",color: "#404040", }}>BP</Typography>
              <Typography
                sx={{
                  marginTop: "10px",
                  width: "100%",
                  height: "25px",
                  background: "#FFFFFF",
                  border: "0.5px solid #000000",
                }}
              >
                {" "}
                {vitalInfo.bp} {" mmHg"}
              </Typography>
            </Grid>
         ) : (
            ""
          )}

          {vitalInfo.heartRate && vitalInfo.heartRate > 0 ? (
            <Grid item lg={4} xs={4} md={4} sm={4} flexDirection="column">
              <Typography sx={{ marginTop: "10px" , fontWeight: 700,lineHeight: "13px",color: "#404040", }}>HeartRate</Typography>
              <Typography
                sx={{
                  marginTop: "10px",
                  width: "100%",
                  height: "25px",
                  background: "#FFFFFF",
                  border: "0.5px solid #000000",
                }}
              >
                {" "}
                {vitalInfo.heartRate} {" bpm"}{" "}
              </Typography>
            </Grid>
         ) : (
            ""
          )}

          {vitalInfo.spo2 && vitalInfo.spo2 > 0 ? (
            <Grid item lg={4} xs={4} md={4} sm={4} flexDirection="column">
              <Typography sx={{ marginTop: "10px" , fontWeight: 700,lineHeight: "13px",color: "#404040", }}>SpO2</Typography>
              <Typography
                sx={{
                  marginTop: "10px",
                  width: "100%",
                  height: "25px",
                  background: "#FFFFFF",
                  border: "0.5px solid #000000",
                }}
              >
                {" "}
                {vitalInfo.spo2} {" %"}{" "}
              </Typography>
            </Grid>
         ) : (
            ""
          )}

          {vitalInfo.temperature && vitalInfo.temperature > 0 ? (
            <Grid item lg={4} xs={4} md={4} sm={4} flexDirection="column">
              <Typography sx={{ marginTop: "10px" , fontWeight: 700,lineHeight: "13px",color: "#404040", }}>Temp</Typography>
              <Typography
                sx={{
                  marginTop: "10px",
                  width: "100%",
                  height: "25px",
                  background: "#FFFFFF",
                  border: "0.5px solid #000000",
                }}
              >
                {" "}
                {vitalInfo.temperature} &#8457;{" "}
              </Typography>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        </Grid>
         ) : (
        <Grid >
            <Grid item lg={12} xs={12} md={12} sm={12}>
                <Typography sx={{ marginTop: "20px" , fontWeight: 700,lineHeight: "13px",color: "#404040", }}>VITALS</Typography>
            </Grid>
            <Grid container spacing={2}>
                <Grid item lg={4} xs={4} md={4} sm={4} flexDirection="column">
                    <Typography sx={{ marginTop: "10px" }}>No Data Available</Typography>
                </Grid>
            </Grid>
        </Grid>    
    )}
      </Fragment>



            /* {vitalInfo &&
            (vitalInfo.bp ||
                vitalInfo.weight ||
                vitalInfo.height ||
                vitalInfo.heartRate ||
                vitalInfo.spo2 ||
                vitalInfo.temperature ||
                vitalInfo.sugar) ? (
                <Grid container columns={13}>
                    <Grid item lg={12} md={1} sm={1} xs={1} sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography variant="body2" sx={{ fontWeight: 600, fontSize: "16px", color: "#127DDD" }}>
                            VITALS{" "}
                        </Typography>
                    </Grid>

                    {vitalInfo.bp && vitalInfo.bp.length > 1 ? (
                        <Grid item lg={2} md={2} sm={2} xs={2}>
                            <Stack direction={"row"}>
                                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                                    BP:&nbsp;
                                </Typography>
                                <Typography variant="caption" sx={{ mt: "1px" }}>
                                    {vitalInfo.bp} {" mmHg"}
                                </Typography>
                            </Stack>
                        </Grid>
                    ) : (
                        ""
                    )}

                    {vitalInfo.weight && vitalInfo.weight > 0 ? (
                        <Grid item lg={2} md={2} sm={2} xs={2}>
                            <Stack direction={"row"}>
                                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                                    WEIGHT:&nbsp;
                                </Typography>
                                <Typography variant="caption" sx={{ mt: "1px" }}>
                                    {vitalInfo.weight} {" kg"}{" "}
                                </Typography>
                            </Stack>
                        </Grid>
                    ) : (
                        ""
                    )}

                    {vitalInfo.height && vitalInfo.height > 0 ? (
                        <Grid item lg={2} md={2} sm={2} xs={2}>
                            <Stack direction={"row"}>
                                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                                    HEIGHT:&nbsp;
                                </Typography>
                                <Typography variant="caption" sx={{ mt: "1px" }}>
                                    {vitalInfo.height} {" cm"}{" "}
                                </Typography>
                            </Stack>
                        </Grid>
                    ) : (
                        ""
                    )}

                    {vitalInfo.heartRate && vitalInfo.heartRate > 0 ? (
                        <Grid item lg={2} md={2} sm={2} xs={2}>
                            <Stack direction={"row"}>
                                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                                    HR:&nbsp;
                                </Typography>
                                <Typography variant="caption" sx={{ mt: "1px" }}>
                                    {vitalInfo.heartRate} {" bpm"}{" "}
                                </Typography>
                            </Stack>
                        </Grid>
                    ) : (
                        ""
                    )}

                    {vitalInfo.spo2 && vitalInfo.spo2 > 0 ? (
                        <Grid item lg={2} md={2} sm={2} xs={2}>
                            <Stack direction={"row"}>
                                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                                    SpO2:&nbsp;
                                </Typography>
                                <Typography variant="caption" sx={{ mt: "1px" }}>
                                    {vitalInfo.spo2} {" %"}{" "}
                                </Typography>
                            </Stack>
                        </Grid>
                    ) : (
                        ""
                    )}

                    {vitalInfo.temperature && vitalInfo.temperature > 0 ? (
                        <Grid item lg={2} md={2} sm={2} xs={2}>
                            <Stack direction={"row"}>
                                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                                    TEMP:&nbsp;
                                </Typography>
                                <Typography variant="caption" sx={{ mt: "1px" }}>
                                    {vitalInfo.temperature} &#8457;{" "}
                                </Typography>
                            </Stack>
                        </Grid>
                    ) : (
                        ""
                    )}

                    {vitalInfo.sugar && vitalInfo.sugar > 0 ? (
                        <Grid item lg={2} md={2} sm={2} xs={2}>
                            <Stack direction={"row"}>
                                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                                    {VITALS_SUGAR}:&nbsp;
                                </Typography>
                                <Typography variant="caption" sx={{ mt: "1px" }}>
                                    {vitalInfo.sugar} mmol/L
                                </Typography>
                            </Stack>
                        </Grid>
                    ) : (
                        ""
                    )}
                </Grid>
            ) : (
                <Grid container columns={13} sx={{}}>
                    <Grid item lg={1} md={1} sm={1} xs={1} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            VITALS{" "}
                        </Typography>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="caption" sx={{ mt: "1px" }}>
                            Vital data not available.
                        </Typography>
                    </Grid>
                </Grid>
            )} */
        

    );
};

export default PrintVitals;
