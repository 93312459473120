import { Box, Grid, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useState } from "react";
import SlotCardComponent from "./slotCardComponent";

const InActiveSlots = (props) => {
    const [view, setView] = useState(999);

    const handleChange = (event, nextView) => {
      setView(nextView);
    };
    async function myHandler2(value) {
        props.addMyHandler(value);
    }
    let content = <Typography sx={{color:"red"}}>no inactive slots...</Typography> ;
    if(props.slotList !== null && props.slotList.length>0) {

        content = 
        <Grid container> <Grid item lg={12}>
 <ToggleButtonGroup 
      orientation="vertical"
      value={view}
      exclusive
      onChange={handleChange}
      color="primary"
    >
        {props.slotList.filter((slot, index) => (slot.isActive === false || slot.isActive === null)).map((slot, index) => (

            <ToggleButton   key={index} value={index} aria-label="list" sx={{ width:350, height:150, mb:3,pl:.5,pr:.5 }}>
            <SlotCardComponent addMyHandler2={myHandler2}
 
              slot={slot}
         
            />
            </ToggleButton>
          ))}
</ToggleButtonGroup>
          </Grid>    </Grid>;
    }

    return (

        <Box sx={{maxHeight:'65vh', overflow:'auto', p:1, }}>
        <Box sx={{height:'100%', background:'#fff' }}>
        {content}
        
        </Box>
        </Box>
    );
}
 
export default InActiveSlots;