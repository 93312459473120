import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";
import tickCircleIcon from "../../../../../assets/images/tickCircleIcon.png";
import Background from "../../appoinmentForm/Background";
import BookForm from "./BookForm";

const MobileForm = () => {
  return (
    <Fragment>
      <Box
        sx={{
          opacity: 1,
          background: "linear-gradient(180deg, #003B9E 0%, #409FFF 48.96%, #9ACFFF 98.96%)",
          padding: "20px",
        }}>
        <Typography
          sx={{
            color: "#FFF",
            fontSize: "24px",
            fontWeight: "700",
            lineHeight: "normal",
            marginBlock: "10px",
          }}>
          Welcome To Our Healthcare Center
        </Typography>
        {/* <Typography
          sx={{
            color: "#FFF",
            fontSize: "13px",
            fontWeight: "500",
            lineHeight: "normal",
            marginBlock: "10px",
          }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </Typography>
        <Box sx={{ display: "flex", marginBlock: "15px" }}>
          <Box component="img" src={tickCircleIcon} style={{ height: "20px", width: "20px" }} />
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "13px",
              fontWeight: "400",
              lineHeight: "normal",
              marginLeft: "10px",
              marginTop: "1px",
            }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </Typography>
        </Box>
        <Box sx={{ display: "flex", marginBlock: "15px" }}>
          <Box component="img" src={tickCircleIcon} style={{ height: "20px", width: "20px" }} />
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "13px",
              fontWeight: "400",
              lineHeight: "normal",
              marginLeft: "10px",
              marginTop: "1px",
            }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </Typography>
        </Box>
        <Box sx={{ display: "flex", marginBlock: "15px" }}>
          <Box component="img" src={tickCircleIcon} style={{ height: "20px", width: "20px" }} />
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "13px",
              fontWeight: "400",
              lineHeight: "normal",
              marginLeft: "10px",
              marginTop: "1px",
            }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </Typography>
        </Box>
        <Box sx={{ display: "flex", marginBlock: "15px" }}>
          <Box component="img" src={tickCircleIcon} style={{ height: "20px", width: "20px" }} />
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "13px",
              fontWeight: "400",
              lineHeight: "normal",
              marginLeft: "10px",
              marginTop: "1px",
            }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </Typography>
        </Box> */}

        <BookForm />
      </Box>
    </Fragment>
  );
};

export default MobileForm;
