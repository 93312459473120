import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { BASE_URL } from "../../assets/constantsFile";
import { useEffect, useState } from "react";
import { getData } from "../../services/api/getApi";
import PrintHeader from "../../common/printComps/printHeader";
import PrintPatientDetail from "../../common/printComps/printPatientDetail";
import PrintVitals from "../../common/printComps/printVitals";
import PrintIssue from "../../common/printComps/printIssue";
import PrintDiagnosis from "../../common/printComps/printDiagnosis";
import PrintPrescribedTests from "../../common/printComps/printPrescribedTests";
import PrintPrescriptions from "../../common/printComps/printPrescriptions";
import PrintFooter from "../../common/printComps/printFooter";
import PatientMedicalHistory from "../../common/printComps/patientMedicalHistory";
import NextAppointmentDate from "../../common/printComps/nextAppointmentDate";
import PersonalVisitRequired from "../../common/printComps/personalVisitRequired";
import PrintChiefComplaint from "../../common/printComps/printChiefComplaint";
import telephone1 from "../../assets/images/telephone1.png";
import companyLogo from "../../assets/images/svg/unoLogo.svg";
import printHeader from "../../assets/images/svg/printHeader.svg";
import printFooter from "../../assets/images/svg/printFooter.svg";
import qrcode from "../../assets/images/qrcode.svg";

const PrintReportComponent = (props) => {
  const styles = {
    mybox: {
      marginLeft: "10px",
      width: "0px",
      height: "800px",
      marginTop: "10px",
      border: "0.5px solid #000000",
    },
  };

  const [docDetail, setDocDetail] = useState("");
  console.log({ "props.patientDetails123": props.patientDetails });

  let patientDetails = "";
  let doctorId = "";

  let patientInfo = "";
  let vitalInfo = "";
  let issueInfo = "";
  let observationInfo = "";
  let reportList = [];
  let prescriptionList = [];
  let patientMedicalHistoryInfo = "";
  let nextAppointmentDate = "";
  let personalVisitNeeded = "";
  let advice = "";
  let chiefComplaintInfo = "";

  console.log({ printHere: props.patientDetails });

  if (props.patientDetails) {
    patientDetails = props.patientDetails;
    doctorId = patientDetails.docId;
    nextAppointmentDate = props.patientDetails.nextAppointmentDate;
    personalVisitNeeded = props.patientDetails.personalVisitNeeded;

    patientInfo = {
      patientId: patientDetails.patientId,
      name: patientDetails.patientName,
      age:
        (patientDetails.patientInfoVM && patientDetails.patientInfoVM.age
          ? patientDetails.patientInfoVM.age + "Y "
          : "") +
        (patientDetails.patientInfoVM && patientDetails.patientInfoVM.ageMonths
          ? patientDetails.patientInfoVM.ageMonths + "M"
          : ""),
      sex: patientDetails.gender,
      issue: patientDetails.issue,
    };
    chiefComplaintInfo = patientDetails.chiefComplaints;
    issueInfo = patientDetails.issue;
    vitalInfo = patientDetails.vitals ? patientDetails.vitals : "";
    observationInfo = patientDetails.observation
      ? patientDetails.observation
      : "";
    reportList = patientDetails.reports ? patientDetails.reports : [];
    let prescriptionVM = patientDetails.prescriptionVM;

    if (prescriptionVM) {
      prescriptionList = prescriptionVM.medicines;
    }
    advice = patientDetails.advice;
    const patientInfoVM = patientDetails.patientInfoVM;
    patientMedicalHistoryInfo = {
      preExistingConditions: patientInfoVM.preExistingConditions,
      drugHistory: patientInfoVM.drugHistory,
      drugAllergy: patientInfoVM.drugAllergy,
      habits: patientInfoVM.habits,
      occupation: patientInfoVM.occupation,
      parentalHistory: patientInfoVM.parentalHistory,
    };
  }

  const getDocInfo = async (dId) => {
    if (dId) {
      const user = await getData(BASE_URL + "doctor/" + dId);

      if (user.error) {
      } else {
        const data = user.data;
        setDocDetail(data);
      }
    }
  };

  useEffect(() => {
    getDocInfo(doctorId);
  }, [doctorId]);

  console.log({ patientDetails: patientDetails });

  return (
    <Grid
      container
      sx={{
        display: "flex",
        height: "100vh",
        alignContent: "space-between",
        m: 0,
      }}
    >
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex" }}>
        <img src={printHeader} style={{ width: "100%" }} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Container maxWidth={false} sx={{ mr: 0.5, ml: 1, px: 10 }}>
          <Box sx={{ minHeight: "70vh" }}>
            <Grid container>
              <Grid
                item
                container
                lg={7}
                xs={7}
                md={7}
                sm={7}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  p: 0,
                }}
              >
                <Grid item lg={12}>
                  <img src={companyLogo} style={{ width: "70%" }} />
                </Grid>

                <Grid item lg={2} sx={{ marginTop: "-10px" }}>
                  <img
                    src={telephone1}
                    style={{ width: "40px", height: "40px" }}
                  />
                </Grid>

                <Grid item lg={3}>
                  <Typography color="#6B6B6B">
                    {docDetail.unoPhoneNumber}
                  </Typography>
                </Grid>
                <Grid item lg={3}>
                  <Typography
                    color="#6B6B6B"
                    style={{ alignItems: "flex-start" }}
                  >
                    {docDetail.unoEmail}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                lg={5}
                xs={5}
                md={5}
                sm={5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <PrintHeader
                  docDetail={docDetail}
                  patientDetails={patientDetails}
                />
              </Grid>
              <Grid
                item
                lg={12}
                xs={12}
                md={12}
                sm={12}
                sx={{ borderBottom: "1px solid black", py: 0.7 }}
              ></Grid>
              <Grid item lg={4} xs={4} md={4} sm={4}>
                <PrintVitals vitalInfo={vitalInfo} />

                {chiefComplaintInfo ? (
                  <PrintChiefComplaint
                    chiefComplaintInfo={chiefComplaintInfo}
                  />
                ) : (
                  ""
                )}
                {issueInfo ? <PrintIssue issueInfo={issueInfo} /> : ""}
                {patientMedicalHistoryInfo ? (
                  <PatientMedicalHistory
                    patientMedicalHistoryInfo={patientMedicalHistoryInfo}
                  />
                ) : (
                  ""
                )}
                {reportList && reportList.length > 0 ? (
                  <PrintPrescribedTests reportList={reportList} />
                ) : (
                  ""
                )}
                {personalVisitNeeded ? (
                  <PersonalVisitRequired
                    personalVisitNeeded={personalVisitNeeded}
                  />
                ) : (
                  ""
                )}
              </Grid>

              <Box sx={styles.mybox}></Box>

              <Grid
                item
                lg={7}
                xs={7}
                md={7}
                sm={7}
                sx={{ marginLeft: "30px" }}
              >
                <PrintPatientDetail patientInfo={patientInfo} />
                {observationInfo ? (
                  <PrintDiagnosis diagnosisInfo={observationInfo} />
                ) : (
                  ""
                )}
                {prescriptionList && prescriptionList.length > 0 ? (
                  <PrintPrescriptions prescriptionList={prescriptionList} />
                ) : (
                  ""
                )}
                {nextAppointmentDate ? (
                  <NextAppointmentDate
                    nextAppointmentDate={nextAppointmentDate}
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Grid container>
              {docDetail ? (
                <Grid item lg={12} xs={12} md={12} sm={12}>
                  <PrintFooter docDetail={docDetail} />
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Box>
        </Container>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex" }}>
        <img
          src={printFooter}
          style={{ width: "100%", position: "absolute", bottom: 0 }}
        />
      </Grid>
    </Grid>
  );
};

export default PrintReportComponent;
