import { Box, Grid, Typography } from "@mui/material";
import CustomBarChart from "../../../../global/customCharts/customBarChart";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { getData } from "../../../../../services/api/getApi";
const data = [
  {
    name: "",
    pass: 106,
    fail: 10,
  },
];
const PEMonthlyCharts = ({
  setIsGraph,
  setSelectedMonth,
  corpId = localStorage.getItem("CORPID"),
}) => {
  const startDate = "2023-01-01";
  const endDate = "2023-11-30";

  const [monthlyData, setMonthlyData] = useState(null);

  const _fetchEmployeeAnalytics = async () => {
    const url =
      BASE_URL + `org/preEmployed/stats?corpId=${corpId}&startDate=${startDate}&endDate=${endDate}`;

    const response = await getData(url, "");

    if (response.error) {
      //console.warn("error");
    } else {
      const data = Object.entries(response.data)[0][1];
      //setMonthlyData(Object.fromEntries(data));

      setMonthlyData(response.data?.["2023"]);
    }
  };

  useEffect(() => {
    _fetchEmployeeAnalytics();
  }, []);

  console.log({ monthlyData321: monthlyData });
  return (
    <Box>
      <Grid container rowSpacing={6}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}>
          <Typography variant="h6">Monthly Charts</Typography>
        </Grid>
        {[
          { id: 1, title: "January", value: "JANUARY" },
          { id: 2, title: "February", value: "FEBRUARY" },
          { id: 3, title: "March", value: "MARCH" },
          { id: 4, title: "April", value: "APRIL" },
          { id: 5, title: "May", value: "MAY" },
          { id: 6, title: "June", value: "JUNE" },
          { id: 7, title: "July", value: "JULY" },
          { id: 8, title: "August", value: "AUGUST" },
          { id: 9, title: "September", value: "SEPTEMBER" },
          { id: 10, title: "October", value: "OCTOBER" },
          { id: 11, title: "November", value: "NOVEMBER" },
          { id: 12, title: "December", value: "DECEMBER" },
        ].map((item, index) => (
          <Grid
            key={index}
            item
            lg={4}
            md={4}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            columnSpacing={2}
            rowSpacing={10}>
            <CustomBarChart
              title={item.title}
              chartData={[monthlyData?.[item.value]]}
              setIsGraph={setIsGraph}
              setSelectedMonth={setSelectedMonth}
              month={item.value}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PEMonthlyCharts;
