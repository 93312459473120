import { Box, Button, Grid } from "@mui/material";

const BulkUpload = () => {
  return (
    <Box>
      <Grid container>
        <Grid item xs>
          <Button size="large" variant="contained" sx={{ px: 3 }}>
            Bulk Register
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BulkUpload;
