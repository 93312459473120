import { Box, Grid, Stack, Typography } from "@mui/material";
import { getCurrentDateFormatted } from "../../assets/utils";
import qrcode from "../../assets/images/qrcode.svg";

const PrintPatientDetail = (props) => {
    const patientInfo = props.patientInfo;
    return (
        <Grid container>
            <Grid item lg={12} xs={12} md={12} sm={12}>
                <Typography
                    sx={{
                        marginTop: "20px",
                        fontWeight: 700,

                        lineHeight: "13px",

                        color: "#404040",
                    }}>
                    Patient Details
                </Typography>
            </Grid>
            <Grid container spacing={2}>
                <Grid item lg={4} xs={4} md={4} sm={4} flexDirection="column" sx={{ marginTop: "20px" }}>
                    <img src={qrcode} style={{ width: "120px", height: "120px" }} />
                </Grid>
                <Grid item lg={3} xs={3} md={3} sm={3} flexDirection="column">
                    <Typography
                        sx={{
                            marginTop: "10px",
                            marginTop: "10px",
                            fontWeight: 700,

                            lineHeight: "13px",

                            color: "#404040",
                        }}>
                        Patient ID
                    </Typography>
                    <Typography sx={{ marginTop: "10px", borderBottom: "1px solid #000000" }}>{patientInfo.patientId}</Typography>
                    <Typography
                        sx={{
                            marginTop: "10px",
                            marginTop: "10px",
                            fontWeight: 700,

                            lineHeight: "13px",

                            color: "#404040",
                        }}>
                        Age
                    </Typography>
                    <Typography sx={{ marginTop: "10px", borderBottom: "1px solid #000000" }}>{patientInfo.age}</Typography>
                </Grid>
                <Grid item lg={5} xs={5} md={5} sm={5} flexDirection="column">
                    <Typography
                        sx={{
                            marginTop: "10px",
                            marginTop: "10px",
                            fontWeight: 700,

                            lineHeight: "13px",

                            color: "#404040",
                        }}>
                        Name
                    </Typography>
                    <Typography sx={{ marginTop: "10px", borderBottom: "1px solid #000000" }}> {patientInfo.name}</Typography>
                    <Typography
                        sx={{
                            marginTop: "10px",
                            marginTop: "10px",
                            fontWeight: 700,

                            lineHeight: "13px",

                            color: "#404040",
                        }}>
                        Gender
                    </Typography>
                    <Typography sx={{ marginTop: "10px", borderBottom: "1px solid #000000" }}> {patientInfo.sex}</Typography>
                </Grid>
            </Grid>
        </Grid>

        // <Grid container>
        //     <Grid
        //         item
        //         lg={8}
        //         md={8}
        //         sm={8}
        //         xs={8}
        //         sx={{ display: "flex", justifyContent: "flex-end", py: 1, borderBottom: "1px solid black" }}
        //     ></Grid>
        //     <Grid
        //         item
        //         lg={2}
        //         md={2}
        //         sm={2}
        //         xs={2}
        //         sx={{
        //             display: "flex",
        //             justifyContent: "center",
        //             py: 1,
        //             borderBottom: "1px solid black",
        //             alignItems: "center",
        //         }}
        //     >
        //         <Stack direction={"row"}>
        //             <Typography variant="body2" sx={{ fontWeight: 600 }}>
        //                 DATE:&nbsp;
        //             </Typography>
        //             <Typography variant="caption" sx={{ mt: "1px" }}>
        //                 {getCurrentDateFormatted(" ")}
        //             </Typography>
        //         </Stack>
        //     </Grid>
        //     <Grid
        //         item
        //         lg={2}
        //         md={2}
        //         sm={2}
        //         xs={2}
        //         sx={{ display: "flex", justifyContent: "flex-end", py: 1, borderBottom: "1px solid black" }}
        //     >
        //         <img src={qrLogo} />
        //     </Grid>
        //     {/* <Grid item lg={12} md={12} sm={12} xs={12} sx={{ borderBottom: "1px solid black" }}></Grid> */}
        //     <Grid item lg={2} md={2} sm={2} xs={2} sx={{ py: 1.5, display: "flex", alignItems: "center" }}>
        //         <Stack direction={"row"} display="flex" alignItems={"center"}>
        //             <Typography variant="body2" sx={{ fontWeight: 600 }}>
        //                 PATIENT ID:&nbsp;
        //             </Typography>
        //             <Typography variant="caption" sx={{}}>
        //                 {patientInfo.patientId}
        //             </Typography>
        //         </Stack>
        //     </Grid>
        //     <Grid item lg={4} md={4} sm={4} xs={4} sx={{ py: 1.5, display: "flex", alignItems: "center" }}>
        //         <Stack direction={"row"}>
        //             <Typography variant="body2" sx={{ fontWeight: 600 }}>
        //                 NAME:&nbsp;
        //             </Typography>
        //             <Typography variant="caption" sx={{ mt: "1px" }}>
        //                 {patientInfo.name}
        //             </Typography>
        //         </Stack>
        //     </Grid>
        //     <Grid item lg={2} md={2} sm={2} xs={2} sx={{ py: 1.5, display: "flex", alignItems: "center" }}>
        //         <Stack direction={"row"}>
        //             <Typography variant="body2" sx={{ fontWeight: 600 }}>
        //                 AGE:&nbsp;
        //             </Typography>
        //             <Typography variant="caption" sx={{ mt: "1px" }}>
        //                 {patientInfo.age}
        //             </Typography>
        //         </Stack>
        //     </Grid>
        //     <Grid item lg={2} md={2} sm={2} xs={2} sx={{ py: 1.5, display: "flex", alignItems: "center" }}>
        //         <Stack direction={"row"}>
        //             <Typography variant="body2" sx={{ fontWeight: 600 }}>
        //                 GENDER:&nbsp;
        //             </Typography>
        //             <Typography variant="caption" sx={{ mt: "1px" }}>
        //                 {patientInfo.sex}
        //             </Typography>
        //         </Stack>
        //     </Grid>
        // </Grid>
    );
};

export default PrintPatientDetail;
