import * as React from "react";
import {
  Menu,
  IconButton,
  Toolbar,
  Box,
  Stack,
  MenuItem,
  Tooltip,
  Button,
  Avatar,
  Container,
  AppBar,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Drawer,
  Link,
  useScrollTrigger,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { userContext } from "../../../common/context/usercontext";
import { Link as Link2, Outlet, useNavigate } from "react-router-dom";
import { colors } from "../../../assets/color";
import { useState } from "react";
import { Fragment } from "react";
import unoCareLogoCorp from "../../../assets/images/unoCareLogoCorp.png";
import kamIcon from "../../../corporatePortal/assets/images/kamIcon.png";
import opportunity from "../../../corporatePortal/assets/images/opportunity.png";
import ShivaniandunoLogo from "../../../corporatePortal/assets/images/dawat/shivanianduno.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import CallIcon from "@mui/icons-material/Call";
import AppBarSubMenuNew from "./comps/appBarSubMenuNew";
import KamDetailModal from "./comps/kamDetailModal";
import logo from "../../../landing/design3/assets/logo.png";
import caseLogo from "../../../landing/design3/assets/caseLogo.png";

const NavBarAfterLoginODNew = ({
  window,
  unauthenticated,
  navbarPermissions = JSON.parse(localStorage.getItem("NAVBAR_PERMISSIONS")),
}) => {
  const corpLogoUrl = localStorage.getItem("CORPURL");
  const corpLogoUrlMobile = localStorage.getItem("CORPURLMOBILE");
  const corpId = localStorage.getItem("CORPID");
  const profileData = "";
  const settings = [{ name: "Logout", url: "/logout" }];
  // const { window } = props;
  console.log({ unauthenticated });
  const contextData = React.useContext(userContext);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  let navigate = useNavigate();

  React.useEffect(() => {}, [navigate]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [kamOpen, setkamOpen] = useState(false);
  const handleKamToggle = () => {
    setkamOpen((prevState) => !prevState);
  };

  const trigger = useScrollTrigger();

  console.log({ navbarPermissions });

  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const drawer = (
    <Box>
      <Box onClick={handleDrawerToggle}>
        <List component={"nav"} disablePadding>
          {navbarPermissions?.map((item, index) => (
            <Fragment key={index}>
              <ListItem disablePadding>
                <ListItemButton href={item.url}>
                  <ListItemText
                    onClick={item.onClick}
                    primary={item.name}
                    sx={{
                      color: "#000000",
                      "&:hover": {
                        color: "#0045B9", // Text color on hover
                        fontSize: 10,
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <List component={"nav"} dense disablePadding>
                  {item.subMenu
                    ?.filter((v) => v.access)
                    .map((it, ind) => (
                      <ListItem key={ind} disablePadding>
                        <ListItemButton href={it.url}>
                          <ListItemText
                            onClick={it.onClick}
                            primary={it.name}
                            sx={{
                              color: "#000000",
                              "&:hover": {
                                color: "#0045B9", // Text color on hover
                              },
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                </List>
              </ListItem>
            </Fragment>
          ))}
        </List>
      </Box>
    </Box>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Fragment>
      {corpId === "bbb269e5-b020-4257-ad8f-4da8c811801a" ? (
        <AppBar position="fixed" elevation={0}>
          <Container maxWidth={false}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerToggle}
                sx={{
                  mr: 2,
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "block",
                    lg: "none",
                    xl: "none",
                  },
                }}
              >
                <MenuIcon sx={{ color: "black" }} />
              </IconButton>

              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {unauthenticated ? (
                  <Link2 to="/corplanding">
                    <img src={logo} style={{ height: 30 }} />
                  </Link2>
                ) : (
                  <Link2 to="/corporatehome">
                    <img src={logo} style={{ height: 30 }} />
                  </Link2>
                )}

                <Box
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "none",
                      lg: "block",
                      xl: "block",
                    },
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "5px",
                        marginBlock: "5px",
                        color: "#000",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          px: 2,
                          borderRight: "1px solid black",
                          mr: 1.5,
                        }}
                      >
                        <Box
                          onClick={() =>
                            navigate("/corporatehome/tickets/healthservice")
                          }
                          sx={{ display: "flex", cursor: "pointer" }}
                        >
                          <Typography>Control Room</Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              fontSize: 8,
                              color: "red",
                              top: -3,
                              position: "relative",
                            }}
                          >
                            24/7
                          </Typography>
                        </Box>
                        <Tooltip
                          title="Key Account Manager"
                          style={{
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                            marginLeft: "10px",
                          }}
                        >
                          <IconButton onClick={handleKamToggle}>
                            <Box
                              component={"img"}
                              src={opportunity}
                              style={{ height: "27px", width: "27px" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <CallIcon
                        sx={{
                          color: "#0463FA",
                          height: "20px",
                          marginRight: "5px",
                        }}
                      />
                      <Typography variant="body2">1800 8890 189</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        marginRight: "5px",
                        marginTop: "-2px",
                      }}
                    >
                      <IconButton
                        sx={{ cursor: "pointer", display: "flex" }}
                        underline="none"
                        href="https://www.facebook.com/profile.php?id=100088725383722&mibextid=LQQJ4d"
                        target={"_blank"}
                      >
                        <FacebookIcon
                          sx={{
                            color: "#0463FA",
                            height: "20px",
                            marginRight: "5px",
                          }}
                        />
                      </IconButton>
                      <IconButton href="">
                        <TwitterIcon
                          sx={{
                            color: "#0463FA",
                            height: "20px",
                            marginRight: "5px",
                          }}
                        />
                      </IconButton>
                      <IconButton href="">
                        <LinkedInIcon
                          sx={{
                            color: "#0463FA",
                            height: "20px",
                            marginRight: "5px",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        sx={{ cursor: "pointer", display: "flex" }}
                        underline="none"
                        href="https://instagram.com/uno.care?igshid=YmMyMTA2M2Y="
                        target={"_blank"}
                      >
                        <InstagramIcon
                          sx={{
                            color: "#0463FA",
                            height: "20px",
                            marginRight: "5px",
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
                <Tooltip title="Open settings">
                  <Box>
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <img src={caseLogo} style={{ height: 40 }} />
                    </IconButton>
                    <Menu
                      sx={{ mt: "45px" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {settings?.map((setting, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => {
                            navigate(setting.url);
                            handleCloseUserMenu();
                          }}
                        >
                          <Link2
                            style={{
                              textDecoration: "none",
                              color: colors.primary,
                            }}
                            //to={setting.url}
                          >
                            {setting.name}
                          </Link2>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                </Tooltip>
              </Box>
            </Toolbar>
          </Container>
          <Box sx={{ flex: 1, display: { xs: "none", lg: "block" } }}>
            {unauthenticated ? null : <AppBarSubMenuNew />}
          </Box>
        </AppBar>
      ) : (
        <AppBar position="fixed">
          <Container maxWidth={false}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerToggle}
                sx={{
                  mr: 2,
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "block",
                    lg: "none",
                    xl: "none",
                  },
                }}
              >
                <MenuIcon sx={{ color: "black" }} />
              </IconButton>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "block", sm: "block" },
                  pointerEvents: "auto",
                }}
              >
                {unauthenticated ? (
                  <Link2 to="/corplanding">
                    <Box>
                      <Grid container>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: isSmallScreen ? "center" : null,
                          }}
                        >
                          <Stack
                            direction={"row"}
                            alignItems="center"
                            sx={{ ml: 0, paddingBlock: "5px" }}
                          >
                            <img
                              src={unoCareLogoCorp}
                              alt="logo"
                              height={"70"}
                              width={200}
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Link2>
                ) : (
                  <Link2 to="/corporatehome">
                    <Box>
                      <Grid container>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: isSmallScreen ? "center" : null,
                          }}
                        >
                          <img
                            src={
                              isSmallScreen ? corpLogoUrlMobile : corpLogoUrl
                            }
                            style={{
                              height: 60,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Link2>
                )}
              </Box>

              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "block",
                    xl: "block",
                  },
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "5px",
                      marginBlock: "5px",
                      color: "#000",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        px: 2,
                        borderRight: "1px solid black",
                        mr: 2,
                      }}
                    >
                      <Box
                        onClick={() =>
                          navigate("/corporatehome/tickets/healthservice")
                        }
                        sx={{ display: "flex", cursor: "pointer" }}
                      >
                        <Typography>Control Room</Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            fontSize: 8,
                            color: "red",
                            top: -3,
                            position: "relative",
                          }}
                        >
                          24/7
                        </Typography>
                      </Box>
                      <Tooltip
                        title="Key Account Manager"
                        style={{
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                          marginLeft: "10px",
                        }}
                      >
                        <IconButton onClick={handleKamToggle}>
                          <Box
                            component={"img"}
                            src={opportunity}
                            style={{ height: "30px", width: "30px" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <CallIcon
                      sx={{
                        color: "#0463FA",
                        height: "20px",
                        marginRight: "5px",
                      }}
                    />
                    <Typography variant="body2">1800 8890 189</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      marginRight: "5px",
                      marginTop: "-2px",
                    }}
                  >
                    <IconButton
                      sx={{ cursor: "pointer", display: "flex" }}
                      underline="none"
                      href="https://www.facebook.com/profile.php?id=100088725383722&mibextid=LQQJ4d"
                      target={"_blank"}
                    >
                      <FacebookIcon
                        sx={{
                          color: "#0463FA",
                          height: "20px",
                          marginRight: "5px",
                        }}
                      />
                    </IconButton>
                    <IconButton href="">
                      <TwitterIcon
                        sx={{
                          color: "#0463FA",
                          height: "20px",
                          marginRight: "5px",
                        }}
                      />
                    </IconButton>
                    <IconButton href="">
                      <LinkedInIcon
                        sx={{
                          color: "#0463FA",
                          height: "20px",
                          marginRight: "5px",
                        }}
                      />
                    </IconButton>
                    <IconButton
                      sx={{ cursor: "pointer", display: "flex" }}
                      underline="none"
                      href="https://instagram.com/uno.care?igshid=YmMyMTA2M2Y="
                      target={"_blank"}
                    >
                      <InstagramIcon
                        sx={{
                          color: "#0463FA",
                          height: "20px",
                          marginRight: "5px",
                        }}
                      />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              {unauthenticated ? null : (
                <Fragment>
                  <Box>
                    <Tooltip title="Open settings">
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar
                          sx={{ background: "#208F94" }}
                          src={profileData.imageURL}
                        ></Avatar>
                      </IconButton>
                    </Tooltip>
                    <Menu
                      sx={{ mt: "45px" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {settings?.map((setting, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => {
                            navigate(setting.url);
                            handleCloseUserMenu();
                          }}
                        >
                          <Link2
                            style={{
                              textDecoration: "none",
                              color: colors.primary,
                            }}
                            //to={setting.url}
                          >
                            {setting.name}
                          </Link2>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                </Fragment>
              )}
            </Toolbar>
          </Container>
          <Box sx={{ flex: 1, display: { xs: "none", lg: "block" } }}>
            {unauthenticated ? null : <AppBarSubMenuNew />}
          </Box>
        </AppBar>
      )}

      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        anchor="left"
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
        }}
      >
        {drawer}
      </Drawer>
      <Outlet />
      <KamDetailModal open={kamOpen} onClose={handleKamToggle} />
    </Fragment>
  );
};

export default NavBarAfterLoginODNew;
