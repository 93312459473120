import { Box, Grid, Typography } from "@mui/material";
import HeartIcon from "../../../assets/images/heart.png";
import TempIcon from "../../../assets/images/thermometer.png";
import HeightIcon from "../../../assets/images/height.png";
import bpIcon from "../../../assets/images/bp.png";
import WeightIcon from "../../../assets/images/scale.png";
import OxygenIcon from "../../../assets/images/oximeter.png";

const VitalsBar = ({
  heartRate = "N/A",
  temperature = "N/A",
  height = "N/A",
  bloodPressure = "N/A",
  weight = "N/A",
  oxygen = "N/A",
  sugar = "N/A",
}) => {
  return (
    <Grid
      container
      sx={{ p: 1, mt: 1, background: "#DCF3FF", borderRadius: 2 }}
      rowSpacing={2}
    >
      <Grid item lg={3} sx={style.gridItem}>
        <Box sx={style.box}>
          <Typography sx={style.font}>Heart Rate</Typography>
          <img style={style.icon} src={HeartIcon}></img>
        </Box>
        <Box sx={style.stat}>
          <Typography sx={style.font}>{heartRate}</Typography>
        </Box>
      </Grid>

      <Grid item lg={3} sx={style.gridItem}>
        <Box sx={style.box}>
          <Typography sx={style.font}>Temp.</Typography>
          <img style={style.icon} src={TempIcon}></img>
        </Box>
        <Box sx={style.stat}>
          <Typography sx={style.font}>{temperature}</Typography>
        </Box>
      </Grid>

      <Grid item lg={3} xs={3} sx={style.gridItem}>
        <Box sx={style.box}>
          <Typography sx={style.font}>Height</Typography>
          <img style={style.icon} src={HeightIcon}></img>
        </Box>
        <Box sx={style.stat}>
          <Typography sx={style.font}>{height}</Typography>
        </Box>
      </Grid>

      <Grid item lg={3} xs={4} sx={style.gridItem}>
        <Box sx={style.box}>
          <Typography sx={style.font}>BP</Typography>
          <img style={style.icon} src={bpIcon}></img>
        </Box>
        <Box sx={style.stat}>
          <Typography sx={style.font}>{bloodPressure}</Typography>
        </Box>
      </Grid>

      <Grid item lg={3} xs={4} sx={style.gridItem}>
        <Box sx={style.box}>
          <Typography sx={style.font}>Weight</Typography>
          <img style={style.icon} src={WeightIcon}></img>
        </Box>
        <Box sx={style.stat}>
          <Typography sx={style.font}>{weight}</Typography>
        </Box>
      </Grid>

      <Grid item lg={3} xs={4} sx={style.gridItem}>
        <Box sx={style.box}>
          <Typography sx={style.font}>RBS</Typography>
          <img style={style.icon} src={OxygenIcon}></img>
        </Box>
        <Box sx={style.stat}>
          <Typography sx={style.font}>{sugar}</Typography>
        </Box>
      </Grid>

      <Grid item lg={6} xs={4} sx={style.gridItem}>
        <Box sx={style.box}>
          <Typography sx={style.font}>Oxygen Levels</Typography>
          <img style={style.icon} src={OxygenIcon}></img>
        </Box>
        <Box sx={style.stat}>
          <Typography sx={style.font}>{oxygen}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default VitalsBar;

const style = {
  gridContainer: {
    background: "#DCF3FF",
    borderRadius: "10px",
    px: 1,
    py: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  gridItem: {
    display: "flex",
    justifyContent: "flex-start",
    height: "2.5rem",
  },
  box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#EEF9FF",
    borderRadius: "10px",
    px: 1,
  },
  stat: {
    display: "flex",
    alignItems: "center",
    pl: 0.6,
  },
  icon: {
    paddingLeft: 3,
  },
  font: {
    fontFamily: "Roboto",
    fontSize: 12,
  },
};
