import RootTemplate from "../../global/templates/rootTemplate";
import AHCPhotoGalleryMain from "../../modules/ahc/photoGallery/ahcPhotoGalleryMain";

const AHCGalleryIndex = () => {
  return (
    <RootTemplate>
      <AHCPhotoGalleryMain />
    </RootTemplate>
  );
};

export default AHCGalleryIndex;
