import {
  Box,
  Button,
  Card,
  CardContent,
  Fab,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { enqueueSnackbar } from "notistack";
import { BASE_URL } from "../../../../assets/constantsFile";
import { saveDataWithoutAuth } from "../../../../services/api/postApi";

const ContactForm = ({ firstTextFieldRef, source, handleClose }) => {
  const [mobile, setMobile] = useState("");
  const [formValues, setFormValues] = useState({});

  const submitHandler = async (e) => {
    e.preventDefault();
    const url = BASE_URL + `healthcamp/contactUs`;
    if (formValues.email) {
      const res = await saveDataWithoutAuth(url, formValues);
      if (res.error) {
        enqueueSnackbar("We have failed raise your request. Try later.", {
          variant: "error",
        });
      } else {
        enqueueSnackbar(
          "We have received your request. Our executive will reach out to you shortly.",
          {
            variant: "success",
          }
        );
        setFormValues({});
        setMobile("");
      }
    }
  };

  return (
    <Fragment>
      <Card
        style={{
          width: "390px",
          borderRadius: "16px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}>
        <CardContent sx={{ p: 0 }}>
          <Box>
            <Typography
              variant="h5"
              component="div"
              align="center"
              sx={{
                backgroundColor: "#3E48EE",
                color: "#FFF",
                padding: "10px 0",
                borderRadius: "8px 8px 0 0",
              }}>
              Sign up to care
            </Typography>
            {source === "DIALOG" && (
              <Fab
                size="small"
                onClick={handleClose}
                aria-label="add"
                color="secondary"
                sx={{
                  //color: "#3E48EE",
                  boxShadow: "none",
                  background: "transparent",
                  position: "absolute",
                  top: 27,
                  right: 40,
                }}>
                <CloseIcon fontSize="small" />
              </Fab>
            )}
          </Box>

          <form autoComplete="off" onSubmit={submitHandler}>
            <Grid container rowSpacing={1} sx={{ paddingInline: 2, paddingBlock: 2 }}>
              <Grid item xs={12} lg={12}>
                <Typography
                  sx={{
                    color: "#999999",
                    marginBottom: "10px",
                    fontSize: "14px",
                  }}>
                  Business email{" "}
                  <span
                    style={{
                      color: "red",
                    }}>
                    *
                  </span>
                </Typography>
                <TextField
                  inputRef={firstTextFieldRef}
                  size="small"
                  fullWidth
                  placeholder="eg : myname@abccorporation"
                  type="email"
                  required
                  sx={{
                    "& .MuiFormLabel-asterisk": {
                      color: "red",
                    },
                  }}
                  value={formValues.email || ""}
                  onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography
                  sx={{
                    color: "#999999",
                    marginBottom: "10px",
                    fontSize: "14px",
                  }}>
                  Enter your name or company name
                </Typography>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="eg : ABC Corporation"
                  sx={{
                    "& .MuiFormLabel-asterisk": {
                      color: "red",
                    },
                  }}
                  value={formValues.name || ""}
                  onChange={(e) => setFormValues({ ...formValues, name: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography
                  sx={{
                    color: "#999999",
                    marginBottom: "10px",
                    fontSize: "14px",
                  }}>
                  Contact number
                </Typography>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="eg : 9999988888"
                  type="tel"
                  value={mobile || ""}
                  onChange={(e) => {
                    if (!isNaN(e.target.value) && e.target.value.length < 11) {
                      setMobile(e.target.value);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography
                  sx={{
                    color: "#999999",
                    marginBottom: "10px",
                    fontSize: "14px",
                  }}>
                  Count of employee
                </Typography>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="eg : 150"
                  type="number"
                  value={formValues.employeeCount || ""}
                  onChange={(e) => setFormValues({ ...formValues, employeeCount: e.target.value })}
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Button
                  // disabled={!formValues.corpEmail}
                  type="submit"
                  size="small"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{
                    padding: "10px 0",
                    backgroundColor: "#3E48EE",
                    width: "170px",
                    fontSize: "20px",
                    height: "45px",
                    marginTop: "10px",
                  }}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default ContactForm;
