// import { Breadcrumbs, Typography } from "@mui/material";
// import React from "react";
// import { Link } from "react-router-dom";
// import { useLocation, Link as RouterLink } from "react-router-dom";

// function toTitleCase(str) {
//   return str.replace(/\b\w+/g, function (s) {
//     return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase();
//   });
// }

// export default function CustomBreadcrumbs() {
//   let location = useLocation();
//   const pathnames = location.pathname.split("/").filter((x) => x);

//   return (
//     <Breadcrumbs
//       aria-label="Breadcrumb"
//       sx={{ fontFamily: "roboto", fontSize: 13, marginTop: 1 }}
//     >
//       <Link color="inherit" component={RouterLink} to="/">
//         Home
//       </Link>
//       {pathnames.map((value, index) => {
//         const last = index === pathnames.length - 1;
//         const to = `/${pathnames.slice(0, index + 1).join("/")}`;

//         return last ? (
//           <Typography
//             color="textPrimary"
//             key={to}
//             sx={{ fontSize: 13, textTransform: "capitalize" }}
//           >
//             {toTitleCase(value)}
//           </Typography>
//         ) : (
//           <Link color="inherit" component={RouterLink} to={to} key={to}>
//             {toTitleCase(value)}
//           </Link>
//         );
//       })}
//     </Breadcrumbs>
//   );
// }

import { Breadcrumbs, Typography } from "@mui/material";
import React from "react";
import { Link, Link as RouterLink, useLocation } from "react-router-dom";

function toTitleCase(str) {
  return str.replace(/\b\w+/g, function (s) {
    return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase();
  });
}

export default function CustomBreadcrumbs() {
  let location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <Breadcrumbs aria-label="Breadcrumb" sx={{ fontFamily: "roboto", fontSize: 13, marginTop: 1 }}>
      {/* <LinkItem to="/" isLast={false}>
        Home
      </LinkItem> */}
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isCorporateHome = value.toLowerCase() === "corporatehome";
        const shouldLink = !isCorporateHome || last;

        return last ? (
          <Typography
            color="textPrimary"
            key={to}
            sx={{ fontSize: 13, textTransform: "capitalize", fontWeight: 600 }}>
            {listOfTtiles[value] || toTitleCase(value)}
          </Typography>
        ) : (
          <LinkItem to={to} isLast={shouldLink} key={to}>
            {listOfTtiles[value] || toTitleCase(value)}
          </LinkItem>
        );
      })}
    </Breadcrumbs>
  );
}

// Custom LinkItem component to conditionally render a Link or Typography
function LinkItem({ to, isLast, children }) {
  if (isLast) {
    return (
      <Typography color="textPrimary" sx={{ fontSize: 13, textTransform: "capitalize" }}>
        {children}
      </Typography>
    );
  } else {
    return (
      <Link color="inherit" component={RouterLink} to={to}>
        {children}
      </Link>
    );
  }
}

const listOfTtiles = {
  corporatehome: "Corporate Home",
  preemployment: "Pre Employement",
  healthrecords: "Health Records",
  statustracker: "Status Tracker",
  form21: "Form 21",
  form24: "Form 24",
};
