import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Portal,
  TextField,
  Typography,
  Stack,
  Button,
  ButtonGroup,
  Snackbar,
  Alert,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useEffect } from "react";
import { BASE_URL } from "../../../../assets/constantsFile";
import { getData } from "../../../../services/api/getApi";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { saveDataWithMultipartForm } from "../../../../services/api/postApi";
import { useFileUpload } from "use-file-upload";
import CustomTitles from "../../../global/titles/customTitles";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CustomButtonBlue from "../../../global/components/CustomButtonBlue";
import CustomBarChart from "./comps/customBarChart";
import { convertMapToObject } from "../../../global/actions/actions";
import EmployeeSearch from "./comps/employeeSearch";
import { useSnackbar } from "notistack";

const yearList = ["2024"];

const CustomTextField = ({
  item,
  formValues,
  setFormValues,
  required = false,
  asterickColor,
}) => {
  const [files, selectFiles] = useFileUpload();

  const handleUpload = () => {
    selectFiles({ multiple: true }, (files) => {
      const propertyName = item.property;
      const currentFiles = formValues[propertyName] || [];

      const updatedSelectedFiles = [...currentFiles];

      files.forEach(({ source, name, size, file }) => {
        updatedSelectedFiles.push({ source, name, size, file });
      });
      setFormValues({ ...formValues, [propertyName]: updatedSelectedFiles });
    });
  };

  if (item.type === "number") {
    return (
      <Box>
        <Stack>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: 13,
              lineHeightt: 17,
              color: "#8A8A8A",
              textTransform: "capitalize",
            }}
          >
            {item.title}
            {required && <span style={{ color: asterickColor }}> *</span>}
          </Typography>

          <TextField
            variant="outlined"
            size="small"
            label={""}
            placeholder={`Enter ${item.title}`}
            value={formValues[item.property] || ""}
            onChange={(e) => {
              if (
                !isNaN(e.target.value) &&
                e.target.value.length < item.length
              ) {
                let newFormValues = { ...formValues };
                newFormValues[item.property] = e.target.value;
                setFormValues(newFormValues);
              }
            }}
          />
        </Stack>
      </Box>
    );
  }

  if (item.type === "gender") {
    return (
      <Box>
        <Stack>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: 13,
              lineHeightt: 17,
              color: "#8A8A8A",
              textTransform: "capitalize",
            }}
          >
            {item.title}
            {required && <span style={{ color: asterickColor }}> *</span>}
          </Typography>

          <Autocomplete
            value={formValues[item.property] || null}
            onChange={(event, newValue) => {
              let newFormValues = { ...formValues };
              newFormValues[item.property] = newValue;
              setFormValues(newFormValues);
            }}
            sx={{ width: 200, mt: 0 }}
            size="small"
            disablePortal
            options={["MALE", "FEMALE", "DONOTDISCLOSE", "OTHER"]}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Gender"
                size="small"
                sx={{
                  backgroundColor: "#fff",
                  "& .MuiInputLabel-root": { color: "#D4D4D4 ! important" },
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { borderColor: "#D4D4D4 ! important" },
                  },
                }}
              />
            )}
          />
        </Stack>
      </Box>
    );
  }
  if (item.type === "autoComplete") {
    return (
      <Box>
        <Stack>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: 13,
              lineHeightt: 17,
              color: "#8A8A8A",
              textTransform: "capitalize",
            }}
          >
            {item.title}
            {required && <span style={{ color: asterickColor }}> *</span>}
          </Typography>

          <Autocomplete
            value={formValues[item.property] || null}
            onChange={(event, newValue) => {
              let newFormValues = { ...formValues };
              newFormValues[item.property] = newValue;
              setFormValues(newFormValues);
            }}
            onInputChange={(event, newValue) => {
              let newFormValues = { ...formValues };
              newFormValues[item.property] = newValue;
              setFormValues(newFormValues);
            }}
            size="small"
            disablePortal
            freeSolo
            options={item.optionList}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={item.placeholder}
                size="small"
                required={true}
                sx={{
                  MuiFormLabel: {
                    asterisk: {
                      color: "red",
                    },
                  },
                }}
              />
            )}
          />
        </Stack>
      </Box>
    );
  }

  if (item.type === "upload") {
    return (
      <Box>
        <Stack>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: 13,
              lineHeightt: 17,
              color: "#8A8A8A",
              textTransform: "capitalize",
            }}
          >
            {item.title}
          </Typography>
          <Button
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#FFFFFF",
              marginTop: "5px",
              textTransform: "capitalize",
            }}
            variant="contained"
            startIcon={<AttachFileIcon style={{ color: "#FFFFFF" }} />}
            onClick={handleUpload}
          >
            Upload
          </Button>
        </Stack>
      </Box>
    );
  }
  if (item.type === "empAutocomplete") {
    return (
      <Box>
        <Stack>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: 13,
              lineHeightt: 17,
              color: "#8A8A8A",
              textTransform: "capitalize",
            }}
          >
            {item.title}
            {required && <span style={{ color: asterickColor }}> *</span>}
          </Typography>
          <EmployeeSearch
            item={item}
            formValues={formValues}
            setFormValues={setFormValues}
            required={required}
          />
        </Stack>
      </Box>
    );
  }

  return (
    <Box>
      <Stack>
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: 13,
            lineHeightt: 17,
            color: "#8A8A8A",
            textTransform: "capitalize",
          }}
        >
          {item.title}
          {required && <span style={{ color: asterickColor }}> *</span>}
        </Typography>

        <TextField
          sx={{
            "& .MuiInputLabel-asterisk": {
              color: asterickColor,
            },
          }}
          variant="outlined"
          size="small"
          required={required}
          placeholder={`Enter ${item.title}`}
          value={formValues[item.property] || ""}
          onChange={(e) => {
            let newFormValues = { ...formValues };
            newFormValues[item.property] = e.target.value;
            setFormValues(newFormValues);
          }}
        />
      </Stack>
    </Box>
  );
};

const injuryList = [
  "ELECTROCUTION",
  "FIRE_INJURY",
  "BURN_INJURY",
  "MACHINE_INJURY",
  "AMPUTATION",
  "ACID_INJURY",
  "HEART_ATTACK",
];

const sortByChronologicalOrder = (dataArray) => {
  const order = [
    "totalInuriestillDate",
    "totalInuriesThisYear",
    "totalInuriesThisMonth",
    "totalInuriesThisWeek",
    "totalInuriesToday",
  ];

  const sortedData = dataArray.sort(
    (a, b) => order.indexOf(a.name) - order.indexOf(b.name)
  );

  return sortedData;
};

const transformData = (originalData) => {
  const newData = originalData.map((item) => {
    switch (item.name) {
      case "totalInuriestillDate":
        return { name: "Till Date", value: item.value };
      case "totalInuriesThisYear":
        return { name: "This Year", value: item.value };
      case "totalInuriesThisMonth":
        return { name: "This Month", value: item.value };
      case "totalInuriesThisWeek":
        return { name: "This Week", value: item.value };
      case "totalInuriesToday":
        return { name: "Today", value: item.value };
      default:
        return item;
    }
  });

  return newData;
};
const convertDataToArray = (data) => {
  if (!data || typeof data !== "object") {
    console.log("Invalid data object");
    return [];
  }

  const dataArray = Object.keys(data).map((key) => ({
    name: key,
    sequence: data[key].sequence,
    percent: data[key].percent,
    value: data[key].value,
  }));

  // Sort the array based on the "name" property
  dataArray.sort((a, b) => {
    if (a.name === "TOTAL OPD") {
      return -1; // "TOTAL OPD" comes first
    } else if (b.name === "TOTAL OPD") {
      return 1; // "TOTAL OPD" comes first
    } else {
      return a.sequence - b.sequence; // Sort by sequence for other items
    }
  });

  return dataArray;
};

const fields = (val) => [
  {
    id: 1,
    title: "Employee ID",
    property: "empId",
    type: "empAutocomplete",
  },
  {
    id: 2,
    title: "Name",
    property: "name",
  },
  {
    id: 3,
    title: "Age",
    property: "age",
    type: "number",
    length: 3,
  },
  {
    id: 4,
    title: "Gender",
    property: "gender",
    type: "gender",
  },
  {
    id: 5,
    title: "Mobile Number",
    property: "mobile",
    type: "number",
    length: 11,
  },
  {
    id: 10,
    title: "Details of Injury/Accident",
    property: "injuryRemarks",
  },
  {
    id: 11,
    title: "Injury/Accident Type",
    property: "injuryType",
    type: "autoComplete",
    optionList: injuryList,
    placeholder: "Select/Write Injury Type",
  },
  {
    id: 12,
    title: "Injury/Accident Location",
    property: "injuryLocation",
    type: "autoComplete",
    optionList: ["INDUSTRIAL", "NON_INDUSTRIAL"],
    placeholder: "Select Injury/Accident Location",
  },
  {
    id: 13,
    title: "Treatment Done At",
    property: "treatmentDoneAt",
    type: "autoComplete",
    optionList: ["REFERRED_OUTSIDE", "TREATED_IN_OHC"],
    placeholder: "Select Injury/Accident Location",
  },
  {
    id: 14,
    title: "Injury Severity",
    property: "injurySeverity",
    type: "autoComplete",
    optionList: ["MILD", "SEVERE", "CRITICAL"],
    placeholder: "Select Injury/Accident Severity",
  },
  {
    id: 15,
    title: "Upload Images/PDF",
    property: "files",
    type: "upload",
  },
];

const CustomTooltip = ({ active, payload, label }) => {
  console.log({ payload });
  if (active && payload && payload.length) {
    const data = payload[0].payload; // Extracting the entire payload
    const excludedFields = ["name", "sequence", "month"];

    return (
      <div
        style={{
          background: "#fff",
          border: "1px solid #ccc",
          padding: "10px",
        }}
      >
        <p>
          <strong>{label}</strong>
        </p>
        {Object.keys(data)
          .filter((key) => !excludedFields.includes(key))
          .map((key, index) => (
            <p key={index}>
              <strong>{key}:</strong> {data[key]}
            </p>
          ))}
      </div>
    );
  }

  return null;
};

const OHCInjuriesStatusTracker = ({
  corpId = localStorage.getItem("CORPID"),
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [openNotice, setOpenNotice] = useState(false);

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };

  const [formValues, setFormValues] = useState({
    corpId: corpId,
    empId: "",
    ohcType: "INJURY",
  });
  const [selectDepartment, setSelectDepartment] = useState(null);
  const [selectedInjuryLocation, setSelectedInjuryLocation] = useState(null);
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedYear, setSelectedYear] = useState(yearList[0]);

  const fetchDepartmentList = async () => {
    const url = BASE_URL + `org/departments?corpId=${corpId}`;
    const departments = await getData(url, "");
    if (departments.error) {
    } else {
      setDepartmentList(departments.data);
    }
  };

  // console.log({ departmentList });

  useEffect(() => {
    fetchDepartmentList();
  }, []);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [noticeData, setNoticeData] = useState({
    severity: "info",
    message: "",
    openNotice: false,
  });
  const submitHandler = (e) => {
    e.preventDefault();
    const url = BASE_URL + `org/daily/stats`;
    if (formValues.name && formValues.injuryLocation) {
      const data = saveDataWithMultipartForm(url, formValues);

      if (data.error) {
        enqueueSnackbar(`${data.error.response.data.message}`, {
          variant: "error",
        });
      } else {
        console.log("success");
        enqueueSnackbar(`Successfully Saved`, {
          variant: "success",
        });
        setFormValues({});
        setTimeout(() => {
          handleClose();
          getInjuryListData();
        }, 500);
      }
    }
  };

  // useEffect(() => {
  //   const getData = setTimeout(() => {
  //     fetchEmployeeData(formValues);
  //   }, 2000);

  //   return () => clearTimeout(getData);
  // }, [formValues.empId]);

  // const fetchEmployeeData = async (fv) => {
  //   if (formValues.empId) {
  //     const url =
  //       BASE_URL + `org/detailed/${formValues.empId}?corpId=${corpId}`;

  //     const emp = await getData(url, "");

  //     if (emp.error) {
  //       setFormValues({ corpId: corpId, empId: formValues.empId });
  //     } else {
  //       console.log({ success: emp.data });
  //       setFormValues({
  //         corpId: emp.data.corpId || null,
  //         empId: emp.data.empId || null,
  //         name: emp.data.name || null,
  //         age: emp.data.age || null,
  //         gender: emp.data.gender || null,
  //         mobile: emp.data.mobile || null,
  //         ohcType: "INJURY",
  //         injuryType: emp.data.injuryType || null,
  //       });
  //     }
  //   }
  // };

  console.log({ formValues });

  const handleRemove = (index) => {
    console.log("Removing file at index:", index);
    const updatedFiles = formValues?.files?.filter((_, i) => i !== index);
    setFormValues({ ...formValues, files: updatedFiles });
  };
  const [durationList, setDurationList] = useState([
    {
      id: 1,
      value: "THIS_WEEK",
      label: "This Week",
      interval: "THIS WEEK",
    },
    {
      id: 3,
      value: "THIS_MONTH",
      label: "This Month",
      interval: "THIS MONTH",
    },
    {
      id: 5,
      value: "THIS_YEAR",
      label: "This Year",
      interval: "THIS YEAR",
    },
  ]);
  const [injuryList, setInjuryList] = useState([]);
  const [injuryData, setInjuryData] = useState([]);
  const [duration, setDuration] = useState(durationList[0]);
  const [injuryDataByDepartment, setInjuryDataByDepartment] = useState([]);
  const [selectedGraph, setSelectedGraph] = useState([]);

  const getInjuryListData = async () => {
    const url =
      BASE_URL +
      "org/injuryStatusTracker?corpId=" +
      corpId +
      (selectDepartment ? "&department=" + selectDepartment : "") +
      (selectedInjuryLocation
        ? "&injuryLocation=" + selectedInjuryLocation
        : "");

    const result = await getData(url, "");
    if (result.error) {
      setInjuryList([]);
    } else {
      setInjuryList(result.data);
    }
  };

  console.log({ injuryList });

  useEffect(() => {
    getInjuryListData();
  }, [selectDepartment, selectedInjuryLocation]);

  useEffect(() => {
    if (selectedYear) {
      const data = injuryList?.mapOfYearWiseInjuries?.[selectedYear];
      console.log({ data });
      console.log({ HELLO: convertDataToArray(data) });
      if (data) {
        setSelectedGraph(convertDataToArray(data));
      } else {
        console.log("Selected chart not found in dialogData");
        setSelectedGraph([]);
      }
    }
  }, [selectedYear, injuryList]);

  console.log({ hiii: convertMapToObject(injuryList?.mapOfInjuriesCount) });

  console.log({ formValues });

  const CustomizedAxisTick = (props) => {
    const { x, y, stroke, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-60)"
          fontSize={10}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  function transformData(inputData) {
    if (!inputData || typeof inputData !== "object") {
      console.error("Invalid input data. Please provide a valid object.");
      return [];
    }

    const transformedData = Object?.keys(inputData)?.map((month) => {
      const monthlyData = { month };

      if (inputData[month] && typeof inputData[month] === "object") {
        Object.keys(inputData[month]).forEach((incidentType) => {
          monthlyData[incidentType] = inputData[month][incidentType];
        });
      }

      return monthlyData;
    });

    return transformedData || [];
  }

  const kkmkm = transformData(injuryList?.mapOfMonthAndTop5Illness);
  console.log({ kkmkm });

  const MergedArray = selectedGraph.map((firstMonth) => {
    const correspondingSecondMonth = kkmkm.find((secondMonth) => {
      // Match the months by name (case-insensitive)
      return secondMonth.month.toUpperCase() === firstMonth.name;
    });

    return {
      ...firstMonth,
      // Add properties from the second array
      ...(correspondingSecondMonth || {}),
    };
  });

  console.log({ MergedArray });

  const keys = [];

  // Extract all unique keys from data
  kkmkm.forEach((entry) => {
    Object.keys(entry).forEach((key) => {
      if (key !== "month" && !keys.includes(key)) {
        keys.push(key);
      }
    });
  });

  console.log({ keys });

  console.log({ duration });

  const handleDurationChange = (event, newValue, reason) => {
    setDuration(newValue);
    console.log({ newValue });
    if (reason === "clear") {
      setDuration({
        id: 1,
        value: "THIS_WEEK",
        label: "This Week",
        interval: "THIS WEEK",
      });
    }
  };

  useEffect(() => {
    if (duration) {
      console.log({ durationLLLLLLL: duration });
      const currentMonth = new Date().toLocaleString("default", {
        month: "long",
      });
      const currentYear = new Date().getFullYear();
      let data;
      let data2;
      if (duration.value === "THIS_WEEK") {
        data = injuryList.mapOfWeekAndTop10Illness;
        data2 = injuryList.mapOfTop10DepartmentsThisWeek;
      } else if (duration.value === "THIS_MONTH") {
        data = injuryList.mapOfMonthAndTop5Illness[currentMonth];
        data2 = injuryList.mapOfTop10DepartmentsThisMonth;
      } else if (duration.value === "THIS_YEAR") {
        data = injuryList.mapOfYearAndTop10Illness[currentYear];
        data2 = injuryList.mapOfTop10DepartmentsThisYear;
      }
      setInjuryData(convertMapToObject(data));
      setInjuryDataByDepartment(data2);
    }
  }, [duration, selectedInjuryLocation, injuryList]);

  return (
    <Box sx={{ backgroundColor: "#FFFFFF" }}>
      <Snackbar
        open={openNotice}
        autoHideDuration={1000}
        onClose={handleCloseNotice}
      >
        <Alert
          onClose={handleCloseNotice}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="Injuries/Accidents Status Tracker" />
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Autocomplete
            value={duration}
            onChange={handleDurationChange}
            size="small"
            fullWidth
            disablePortal
            options={durationList}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  background: "#fff",
                  color: "#127DDD",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                  "& input::placeholder": {
                    color: "#777777",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                  },
                }}
                variant="outlined"
                size="small"
                label="Select Duration"
                InputLabelProps={{
                  style: {
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: 13,
                    color: "#404040",
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Autocomplete
            value={selectDepartment}
            onChange={(event, newValue) => {
              setSelectDepartment(newValue);
              console.log({ newValue });
            }}
            size="small"
            fullWidth
            disablePortal
            options={departmentList}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  background: "#fff",
                  color: "#127DDD",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                  "& input::placeholder": {
                    color: "#777777",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                  },
                }}
                variant="outlined"
                size="small"
                label="Select Department"
                InputLabelProps={{
                  style: {
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: 13,
                    color: "#404040",
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Autocomplete
            value={selectedInjuryLocation}
            onChange={(event, newValue) => {
              setSelectedInjuryLocation(newValue);
              console.log({ newValue });
            }}
            size="small"
            fullWidth
            disablePortal
            options={["INDUSTRIAL", "NON_INDUSTRIAL"]}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  background: "#fff",
                  color: "#127DDD",
                  fontWeight: "300",
                  fontSize: "13px",
                  lineHeight: " 15px",
                  "& input::placeholder": {
                    color: "#777777",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: " 15px",
                  },
                }}
                variant="outlined"
                size="small"
                label="Select Injury Location"
                InputLabelProps={{
                  style: {
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: 13,
                    color: "#404040",
                  },
                }}
              />
            )}
          />
        </Grid>

        <Grid item lg={3} md={3} sm={12} xs={12}>
          <CustomButtonBlue
            title="Record Injuries"
            marginBlock={"0px"}
            onClick={() => handleOpen()}
          />
        </Grid>
        {corpId === "13a4b8c6-750f-442a-8414-f5465481e5d9" ? null : (
          <>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomBarChart
                height={"380px"}
                barColor={"#F66"}
                disableClickable={true}
                data={transformData(
                  sortByChronologicalOrder(
                    convertMapToObject(injuryList?.mapOfInjuriesCount)
                  )
                )}
                title={"Injuries Count"}
                tooltipTitle={"Count"}
                dialogData={injuryList?.mapOfYearWiseInjuries?.selectedYear}
              />
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12} marginBottom={5}>
              <Box
                sx={{
                  backgroundColor: "#FFFFFF",
                  border: "1px solid grey",
                  height: "380px",
                  paddingInline: "10px",
                  paddingBlock: "20px",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  textAlign="left"
                  sx={{
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "120%",
                  }}
                >
                  {`For Year - ${selectedYear}`}
                </Typography>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={730}
                    height={250}
                    data={MergedArray}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="name"
                      style={{ fontSize: 12 }}
                      interval={0}
                      tick={CustomizedAxisTick}
                      height={70}
                    />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Bar
                      dataKey="value"
                      fill="#F66"
                      name="Total No of Injuries"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Grid>
          </>
        )}
        <Grid item lg={6} md={6} sm={12} xs={12} marginBottom={5}>
          <CustomBarChart
            height={"380px"}
            barColor={"#F66"}
            disableClickable={true}
            data={transformData(sortByChronologicalOrder(injuryData))}
            title={`Top 10 Injuries`}
            tooltipTitle={"Count"}
            dialogData={injuryList?.mapOfYearWiseInjuries?.selectedYear}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} marginBottom={5}>
          <CustomBarChart
            height={"380px"}
            barColor={"#F66"}
            disableClickable={true}
            data={transformData(convertMapToObject(injuryDataByDepartment))}
            title={`Top 10 Injuries Count By Department`}
            tooltipTitle={"Count"}
            dialogData={injuryList?.mapOfInjuryStats?.[`${duration.interval}`]}
          />
        </Grid>
      </Grid>

      <Portal>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={"xl"}
          fullWidth={true}
        >
          <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
            Record Injury/Accident
          </DialogTitle>

          <DialogActions sx={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ height: "75vh" }}>
            <form onSubmit={submitHandler}>
              <Grid container rowSpacing={2} columnSpacing={4}>
                {fields(formValues).map((item, index) => (
                  <Grid item lg={4} key={index}>
                    <CustomTextField
                      item={item}
                      formValues={formValues}
                      setFormValues={setFormValues}
                      required={
                        item.property === "name" ||
                        item.property === "injuryLocation" ||
                        item.property === "gender" ||
                        item.property === "injuryType" ||
                        item.property === "treatmentDoneAt" ||
                        item.property === "injurySeverity" ||
                        item.property === "age"
                          ? true
                          : false
                      }
                      asterickColor={"red"}
                    />
                  </Grid>
                ))}

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ display: "flex", flexWrap: "wrap" }}
                >
                  {formValues?.files?.length
                    ? formValues?.files.map((file, index) => (
                        <Paper
                          key={index}
                          elevation={3}
                          style={{
                            maxWidth: "150px",
                            marginInline: "10px",
                            marginBlock: "5px",
                          }}
                        >
                          <Box position="relative">
                            {file.name.toLowerCase().endsWith(".pdf") ? (
                              <Box
                                sx={{
                                  height: "200px",
                                  width: "150px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <PictureAsPdfIcon
                                  style={{ fontSize: 100, color: "#127DDD" }}
                                />
                              </Box>
                            ) : (
                              <img
                                src={file.source}
                                alt={file.source}
                                style={{ maxWidth: "130px", height: "130px" }}
                              />
                            )}
                            <IconButton
                              size="small"
                              color="secondary"
                              onClick={() => handleRemove(index)}
                              style={{
                                position: "absolute",
                                top: 5,
                                right: 5,
                                color: "red",
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <Typography variant="body2" align="center">
                              {file.name}
                            </Typography>
                          </Box>
                        </Paper>
                      ))
                    : null}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    mt: 2,
                  }}
                >
                  <Button
                    disabled={
                      formValues.name &&
                      formValues.injuryLocation &&
                      formValues.gender &&
                      formValues.injuryType &&
                      formValues.treatmentDoneAt &&
                      formValues.injurySeverity &&
                      formValues.age
                        ? false
                        : true
                    }
                    variant="contained"
                    size="large"
                    type="submit"
                    sx={{
                      width: 140,
                      display: "flex",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: 13,
                        lineHeightt: 17,
                        color: "#FFFFFF",
                      }}
                    >
                      Submit
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      </Portal>
    </Box>
  );
};

export default OHCInjuriesStatusTracker;
