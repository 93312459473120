import RootTemplate from "../../global/templates/rootTemplate";
import NewRequestformMain from "../../modules/preemployment/newRequestForm/newRequestformMain";

const NewRequestFormIndex = ({ corpId = localStorage.getItem("CORPID") }) => {
  // "Exception for Case Construction"
  if (corpId === "bbb269e5-b020-4257-ad8f-4da8c811801a") {
    corpId = "872cd841-9f7a-432d-b8e9-422b780bca10";
  }
  return (
    <RootTemplate>
      <NewRequestformMain />
    </RootTemplate>
  );
};

export default NewRequestFormIndex;
