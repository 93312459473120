import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";

const statusList = [
  {
    id: 1,
    label: "ALL",
    value: "ALL",
  },
  {
    id: 2,
    label: "Fit",
    value: "FIT",
  },
  {
    id: 3,
    label: "Mecical Attention Required",
    value: "UNFIT",
  },
];

const HealthStatusFilter = ({ healthStatus, setHealthStatus }) => {
  const handleChange = (event) => {
    setHealthStatus(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl
        fullWidth
        size="small"
        sx={{
          "& fieldset": { border: "none" },
        }}>
        <InputLabel sx={{ fontSize: 11 }}>Health Status</InputLabel>
        <Select
          sx={{ fontSize: 11, fontWeight: 600 }}
          value={healthStatus}
          label="Health Status"
          onChange={handleChange}>
          {statusList.map((it, index) => (
            <MenuItem value={it?.value} key={index}>
              {it?.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
export default HealthStatusFilter;
