import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Portal,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useState } from "react";
import { useEffect } from "react";
import StatusTrackerDetailGrid from "../../../../global/customDatagrids/statusTrackerDetailGrid";
import MaskingButton from "../../../../global/components/maskingButton";
import DownloadCSV from "../../../../global/components/downloadCSV";

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#127DDD",
  "#D45FFF",
  "#DDFFDF",
];

function sortArrayByCountDescending(array) {
  return array.sort((a, b) => b.totalCount - a.totalCount);
}

const modifyDataForStack = (data) => {
  return data?.map((item) => {
    return {
      name: item.name,
      medicationAdvicedPercentage: parseFloat(item.value.toFixed(2)),
      healthyPercentage: parseFloat((100 - item.value).toFixed(2)),
      Medication_Advised: Math.floor(item.count),
      sequence: Math.floor(item.sequence),
      totalCount: Math.round((item.count * 100) / item.value),
      Healthy: Math.round((item.count * 100) / item.value - item.count),
    };
  });
};

let renderLabel = function (entry) {
  return `${entry.name}(${entry.value}%)`;
};

const CustomizedAxisTick = (props) => {
  const { x, y, stroke, payload, isMobile } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={isMobile ? -15 : 0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform={`rotate(${isMobile ? -90 : -20})`}
        fontSize={12}
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomTooltipContent = ({ active, payload, label, tooltipTitle }) => {
  if (active) {
    const data = payload?.[0]?.payload; // Get the data of the hovered bar

    return (
      // <Tooltip>
      <Box sx={{ backgroundColor: "#FFFFFF", padding: "10px" }}>
        {label ? <Typography>{`${label}`}</Typography> : null}

        {data?.totalCount ? (
          <Typography>{`Total Count: ${data?.totalCount}`}</Typography>
        ) : null}
        {data?.Medication_Advised ? (
          <Typography>{`Medication Advised: ${data?.Medication_Advised}`}</Typography>
        ) : null}
        {data?.Healthy ? (
          <Typography>{`Healthy: ${data?.Healthy}`}</Typography>
        ) : null}
      </Box>
      // </Tooltip>
    );
  }

  return null;
};

const StackedBarChart = ({
  mapName,
  barWidth,
  data,
  title,
  dialogData,
  tooltipTitle,
  popupData,
  setSelectedBar,
  disableClickable,
  dialogDataHealthy,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const newData = modifyDataForStack(data);
  const [employeeType, setEmployeeType] = useState("");

  // console.log({ data, newData });

  const [newDialogData, setNewDialogData] = useState([]);

  const [apiRef, setApiRef] = useState(null);
  const exportCSV = () => {
    apiRef.current.exportDataAsCsv();
  };

  const downloadCSV = () => {
    const csvContent = Object?.entries(dialogData)
      .map(
        ([category, data]) =>
          `${category}\n` +
          data
            .map(
              (entry) =>
                `${entry.emp_id},${entry.name},${entry.department},${entry.data},${entry.parameter}`
            )
            .join("\n")
      )
      .join("\n\n");

    const csvData = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    const downloadLink = document.createElement("a");
    downloadLink.setAttribute("href", csvData);
    downloadLink.setAttribute("download", "dialog_data.csv");
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  const [open, setOpen] = useState(false);
  const [selectedChart, setSelectedChart] = useState("");
  const [selectedBarData, setSelectedBarData] = useState([]);
  const handleOpen = (title) => {
    setOpen(true);
    setSelectedChart(title);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedChart("");
    setSelectedBarData([]);
  };

  useEffect(() => {
    if (newDialogData) {
      console.log({ newDialogData });

      const newData = newDialogData[selectedChart];
      console.log({ newData });
      if (newData) {
        setSelectedBarData(newData);
      } else {
      }
    }
  }, [selectedChart]);

  useEffect(() => {
    if (popupData) {
      setSelectedBarData(popupData);
    }
  }, [popupData]);

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        border: "1px solid grey",
        paddingInline: "10px",
        paddingBlock: "20px",
        borderRadius: "10px",
      }}
    >
      <Box sx={{ height: "60vh" }}>
        {dialogData === null || dialogData === undefined ? null : (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => {
                if (dialogData !== null || dialogData !== undefined) {
                  downloadCSV();
                }
              }}
            >
              Export CSV
            </Button>
          </Box>
        )}

        <ResponsiveContainer width="100%" height="100%">
          {newData.every((item) => item.value === 0) ? (
            <Box
              width={730}
              height={300}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ textAlign: "center" }}>
                No Data Found
              </Typography>
            </Box>
          ) : (
            <BarChart
              barSize={barWidth}
              width={730}
              height={isMobile ? 400 : 300}
              data={sortArrayByCountDescending(newData)}
              onClick={(bar) => {
                console.log({ bar });
                if (disableClickable) {
                  return;
                } else {
                  handleOpen(bar?.activeLabel);
                  if (setSelectedBar) {
                    setSelectedBar(bar?.activeLabel);
                  }
                }
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                style={{ fontSize: 12 }}
                interval={0}
                tick={<CustomizedAxisTick isMobile={isMobile} />}
                height={isMobile ? 180 : 150}
              />
              <YAxis dataKey="totalCount" />
              <Tooltip
                content={<CustomTooltipContent tooltipTitle={tooltipTitle} />}
              />

              <Legend verticalAlign="top" height={36} />
              <Bar
                stackId="1"
                fill="#F66"
                dataKey="Medication_Advised"
                name="Medication Advised"
                onClick={() => {
                  setNewDialogData(dialogData);
                  setEmployeeType(" MEDICATION ADVISED");
                  console.log({ "MEDICATION ADVISED CLICKED": dialogData });
                }}
              />
              <Bar
                stackId="1"
                fill="#42BD53"
                dataKey="Healthy"
                name="Healthy"
                onClick={() => {
                  setNewDialogData(dialogDataHealthy);
                  setEmployeeType(" HEALTHY");
                  console.log({ "HEALTHY CLICKED": dialogDataHealthy });
                }}
              />
            </BarChart>
          )}
        </ResponsiveContainer>
      </Box>
      <Typography textAlign="center">{title}</Typography>
      <Portal>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={"xl"}
          fullWidth={true}
        >
          <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
            {title}
          </DialogTitle>
          <Typography
            variant="h6"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {selectedChart + employeeType}
          </Typography>
          <Box sx={{ marginLeft: "22px" }}>
            <Stack direction="row" spacing={1}>
              <DownloadCSV exportCSV={exportCSV} type="ICON" />
              <MaskingButton type="ICON" />
            </Stack>
          </Box>
          <DialogActions sx={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
          <DialogContent>
            <StatusTrackerDetailGrid
              setApiRef={setApiRef}
              employeeList={selectedBarData || []}
            />
          </DialogContent>
        </Dialog>
      </Portal>
    </Box>
  );
};

export default StackedBarChart;
