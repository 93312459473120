import { Box, Grid, Typography } from "@mui/material";

const PrintPrescribedTests = (props) => {
  const reportList = props.reportList;
  return (
    <Grid container>
      <Grid item lg={12} xs={12} md={12} sm={12}>
        <Typography sx={{marginTop: "20px", fontWeight: 700,lineHeight: "13px",color: "#404040", }}>
          PRESCRIBED TESTS
        </Typography>
        <Typography sx={{ marginTop: "20px" , borderBottom: "1px solid #000000" }}>
          {reportList
            .filter((report, index) => !report.broughtByPatient)
            .map((report, index) => (
              <span key={index}>
                {report.reportName} {index < reportList.length - 1 ? "," : ""}
              </span>
            ))}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PrintPrescribedTests;
