import { Box, Grid, Typography } from "@mui/material";
import { Fragment } from "react";

const BodyStats = ({ image, label, onClick }) => {
  return (
    <Box
      sx={{
        width: 160,
        height: 90,
        border: "1px solid #000000",
        borderRadius: 5,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={6}>
          <Box
            sx={{
              backgroundColor: "#E7F0FF",
              width: "55px",
              height: "55px",
              mx: 2,
              my: 2,
              borderRadius: "15px",
            }}
          >
            <img
              src={image}
              style={{
                width: "40px",
                height: "40px",
                marginLeft: 6,
                marginTop: 6,
              }}
            />
          </Box>
        </Grid>
        <Grid item lg={6}>
          <Typography>{label}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BodyStats;
