import { Box, Button, Grid, Typography } from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import UpcomingHealthDays from "./upcomingHealthDays";
import CarouselMonth from "./carouselMonth";
import SessionList from "./sessionList";
import BookSession from "./bookSession";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
import { getData } from "../../../../services/api/getApi";
import { BASE_URL } from "../../../../assets/constantsFile";
import CustomTitles from "../../../global/titles/customTitles";

const HealthSessionMain2 = ({ corpId = localStorage.getItem("CORPID") }) => {
  const navigate = useNavigate();

  const [fetch, setFetch] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");

  const [startDate, setStartDate] = useState(
    dayjs().startOf("year").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    dayjs().endOf("year").format("YYYY-MM-DD")
  );

  const [awarenessSessionTicket, setAwarenessSessionTickets] = useState([]);
  const getAwarenessSessionTickets = async () => {
    const url =
      BASE_URL +
      `org/ticket/all?corpId=${corpId}&startDate=${startDate}&endDate=${endDate}`;
    const result = await getData(url);
    if (result.error) {
      console.log(result.error);
      setAwarenessSessionTickets([]);
    } else {
      setFetch(false);
      const temp = result?.data?.filter(
        (item) => item.ticketType === "HEALTH_AWARENESS"
      );
      setAwarenessSessionTickets(temp);
    }
  };

  useEffect(() => {
    getAwarenessSessionTickets();
  }, [fetch]);

  const filterTicketsBasedonMonth = useMemo(() => {
    return (
      awarenessSessionTicket
        .filter(
          (item) =>
            dayjs(item?.ticketInfo?.sessionDate).month() + 1 ===
            selectedMonth.id
        )
        .map((obj) => ({
          sessionIdTicket: obj?.ticketInfo?.sessionId,
          sessionDate: obj?.ticketInfo?.sessionDate,
          status: obj?.status,
          title: obj?.sessionName,
        })) || []
    );
  }, [selectedMonth, fetch]);

  console.log({
    filterTicketsBasedonMonth: awarenessSessionTicket.filter(
      (item) =>
        dayjs(item?.ticketInfo?.sessionDate).month() + 1 === selectedMonth.id
    ),
  });

  return (
    <Fragment>
      <Grid container spacing={1} sx={{ backgroundColor: "#FFFFFF" }}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}
        >
          <BookSession setFetch={setFetch} />
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4} sx={{ marginTop: -5 }}>
          <UpcomingHealthDays selectedMonth={selectedMonth} />
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4}>
          <CarouselMonth setMonth={setSelectedMonth} />
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4} sx={{ marginTop: -3 }}>
          <SessionList selectedMonthSessions={filterTicketsBasedonMonth} />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ textAlign: "right" }}>
          <Button
            onClick={() => {
              navigate(
                "/corporatehome/healthawarenesssessions/completedsessions"
              );
            }}
            variant="contained"
            sx={{
              paddingBlock: "10px",
              paddingInline: "20px",
              borderRadius: "10px",
              backgroundColor: "#0363FA",
              textTransform: "capitalize",
              marginTop: -15,
              zIndex: 999,
              padding: 1.5,
            }}
          >
            <Typography
              sx={{
                color: "#FFF",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "110%",
                letterSpacing: "-0.38px",
              }}
            >
              Completed Sessions
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default HealthSessionMain2;
