import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Portal,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";

import { useState } from "react";
import { useFileUpload } from "use-file-upload";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"; // Import PDF icon
import axios from "axios";
import { BASE_URL } from "../../../assets/constantsFile";
import { _fetchEmployeeById } from "../../global/services/corporateServices";
import { BrowserView, MobileView } from "react-device-detect";
import SearchByIdName from "./subComps/searchByIdName";

const Tickets = ({ setFetch, handleClose, open }) => {
  const corpId = localStorage.getItem("CORPID");
  const [files, selectFiles] = useFileUpload();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [remark, setRemark] = useState("");
  const [empId, setEmpId] = useState("");

  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [openNotice, setOpenNotice] = useState(false);

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };

  const [formValues, setFormValues] = useState({
    empId: "",
    name: "",
    mobile: "",
    age: "",
    gender: "",
    issue: "",
    subTicketType: "HEALTH_TICKET",
    remark: "",
  });

  const fetchEmployee = async (e) => {
    e.preventDefault();

    _fetchEmployeeById(
      corpId,
      setSeverity,
      setMessage,
      setOpenNotice,
      setEmpId,
      setFormValues,
      empId
    );
  };

  const [isLoading, setIsLoading] = useState(false);
  const [subTicketType, setSubTicketType] = useState("HEALTH_TICKET");

  const handleRemove = (index) => {
    console.log("Removing file at index:", index);
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
  };

  const handleUpload = () => {
    selectFiles({ multiple: true }, (files) => {
      // Note callback returns an array
      const updatedSelectedFiles = [...selectedFiles];
      files.map(({ source, name, size, file }) => {
        updatedSelectedFiles.push({ source, name, size, file });
      });
      setSelectedFiles(updatedSelectedFiles);
    });
  };

  console.log({ selectedFiles });

  const handleRaiseTicket = async () => {
    setIsLoading(true);

    let authHeader_local = localStorage.getItem("authHeader_local");
    let userAuthID = localStorage.getItem("ORGUSERIDAUTH");
    let userMobile = localStorage.getItem("ORGUSERMOBILE");
    let userName = localStorage.getItem("ORGUSERNAME");

    const Obj = {
      empId: formValues.empId,
      name: formValues.name,
      mobile: formValues.mobile,
      age: formValues.age,
      gender: formValues.gender,
      remarks: formValues.remark,
      status: "TICKET_RAISED",
      ticketType: subTicketType,
      ticketMode: "WEB",
      ticketCategory: "CORP",
      corpId: corpId,
      raisedBy: userName,
      raisedById: userAuthID,
      raisedByMobileNo: userMobile,
    };

    const url = BASE_URL + "org/v2/raiseTicket";

    try {
      const result = await axios({
        method: "post",
        url: url,
        data: Obj,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authHeader_local}`,
        },
      });

      if (result?.data) {
        console.log("SUCCESS", result.data);
        setSeverity("success");
        setMessage("Saved Successfully");
        setOpenNotice(true);
        setRemark("");
        setEmpId("");
        setSelectedFiles([]);
        setIsLoading(false);
        handleClose();
        setFetch(true);
        setFormValues({
          empId: "",
          name: "",
          mobile: "",
          age: "",
          gender: "",
          issue: "",
          subTicketType: "HEALTH_TICKET",
          remark: "",
        });
        setSubTicketType("HEALTH_TICKET");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setSeverity("error");
        setMessage("Employee Id Not Found");
        setOpenNotice(true);
      } else {
        setSeverity("error");
        setMessage("An error Occurred");
        setOpenNotice(true);
        setFormValues({
          empId: "",
          name: "",
          mobile: "",
          age: "",
          gender: "",
          issue: "",
          subTicketType: "HEALTH_TICKET",
          remark: "",
        });
        setSubTicketType("HEALTH_TICKET");
      }
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Snackbar
        open={openNotice}
        autoHideDuration={1000}
        onClose={handleCloseNotice}
      >
        <Alert
          onClose={handleCloseNotice}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Portal>
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth={"xl"}
          fullWidth={true}
        >
          <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
            Raise Ticket
          </DialogTitle>
          <Typography
            variant="h6"
            sx={{ display: "flex", justifyContent: "center" }}
          ></Typography>
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <DialogActions sx={{ position: "absolute", top: 0, right: 0 }}>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </DialogActions>
              <DialogContent sx={{ height: "68vh" }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={3}>
                    <Typography
                      sx={{
                        fontSize: "17px",
                        fontWeight: "400",
                        lineHeight: "14px",
                        color: "#000000",
                        marginBlock: "10px",
                      }}
                    >
                      Emp. ID
                    </Typography>
                    <SearchByIdName
                      formValues={formValues}
                      setFormValues={setFormValues}
                      corpId={corpId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Typography
                      sx={{
                        fontSize: "17px",
                        fontWeight: "400",
                        lineHeight: "14px",
                        color: "#000000",
                        marginBlock: "10px",
                      }}
                    >
                      Name
                    </Typography>
                    <TextField
                      sx={{
                        color: "#127DDD",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: " 15px",
                        "& input::placeholder": {
                          color: "#777777",
                          fontWeight: "300",
                          fontSize: "13px",
                          lineHeight: " 15px",
                        },
                      }}
                      size="small"
                      fullWidth
                      placeholder="Enter Name"
                      value={formValues.name}
                      onChange={(e) => {
                        setFormValues({ ...formValues, name: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3}>
                    <Typography
                      sx={{
                        fontSize: "17px",
                        fontWeight: "400",
                        lineHeight: "14px",
                        color: "#000000",
                        marginBlock: "10px",
                      }}
                    >
                      Mobile
                    </Typography>
                    <TextField
                      sx={{
                        color: "#127DDD",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: " 15px",
                        "& input::placeholder": {
                          color: "#777777",
                          fontWeight: "300",
                          fontSize: "13px",
                          lineHeight: " 15px",
                        },
                      }}
                      size="small"
                      fullWidth
                      placeholder="Enter Mobile"
                      value={formValues.mobile}
                      onChange={(e) => {
                        if (
                          !isNaN(e.target.value) &&
                          e.target.value.length < 11
                        ) {
                          setFormValues({
                            ...formValues,
                            mobile: e.target.value,
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} sm={12} md={12} lg={3}>
                    <Typography
                      sx={{
                        fontSize: "17px",
                        fontWeight: "400",
                        lineHeight: "14px",
                        color: "#000000",
                        marginBlock: "10px",
                      }}
                    >
                      Age
                    </Typography>
                    <TextField
                      sx={{
                        color: "#127DDD",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: " 15px",
                        "& input::placeholder": {
                          color: "#777777",
                          fontWeight: "300",
                          fontSize: "13px",
                          lineHeight: " 15px",
                        },
                      }}
                      size="small"
                      fullWidth
                      placeholder="Enter Age"
                      value={formValues.age}
                      onChange={(e) => {
                        if (
                          !isNaN(e.target.value) &&
                          e.target.value.length < 4
                        ) {
                          setFormValues({
                            ...formValues,
                            age: e.target.value,
                          });
                        }
                      }}
                    />
                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        openTo="day"
                        views={["year", "month", "day"]}
                        disableFuture
                        format="yyyy-mm-dd"
                        value={
                          formValues.dateOfBirth
                            ? new Date(formValues.dateOfBirth)
                            : null
                        }
                        onChange={(newValue) => {
                          setFormValues({
                            ...formValues,
                            dateOfBirth: format(newValue, "yyyy-MM-dd"), // Formatting date using date-fns
                            age: calculateAge(newValue),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                        )}
                      />
                    </LocalizationProvider> */}
                  </Grid>
                  <Grid item xs={8} sm={12} md={12} lg={3}>
                    <Typography
                      sx={{
                        fontSize: "17px",
                        fontWeight: "400",
                        lineHeight: "14px",
                        color: "#000000",
                        marginBlock: "10px",
                      }}
                    >
                      Gender
                    </Typography>

                    <Autocomplete
                      fullWidth
                      value={formValues.gender || null}
                      onChange={(event, newValue) => {
                        let newFormValues = { ...formValues };
                        newFormValues["gender"] = newValue;
                        setFormValues(newFormValues);
                      }}
                      size="small"
                      disablePortal
                      options={["MALE", "FEMALE", "DONOTDISCLOSE", "OTHER"]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          placeholder="Select Gender"
                          sx={{
                            color: "#127DDD",
                            fontWeight: "300",
                            fontSize: "13px",
                            lineHeight: " 15px",
                            "& input::placeholder": {
                              color: "#777777",
                              fontWeight: "300",
                              fontSize: "13px",
                              lineHeight: " 15px",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Typography
                      sx={{
                        fontSize: "17px",
                        fontWeight: "400",
                        lineHeight: "14px",
                        color: "#000000",
                        marginBlock: "10px",
                      }}
                    >
                      Issue
                    </Typography>
                    <TextField
                      sx={{
                        color: "#127DDD",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: " 15px",
                        "& input::placeholder": {
                          color: "#777777",
                          fontWeight: "300",
                          fontSize: "13px",
                          lineHeight: " 15px",
                        },
                      }}
                      size="small"
                      fullWidth
                      placeholder="Enter Issue"
                      value={formValues.issue}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          issue: e.target.value,
                        });
                      }}
                    />
                  </Grid> */}

                  <Grid item xs={8} sm={12} md={12} lg={3}>
                    <Typography
                      sx={{
                        fontSize: "17px",
                        fontWeight: "400",
                        lineHeight: "14px",
                        color: "#000000",
                        marginBlock: "10px",
                      }}
                    >
                      Ticket Type
                    </Typography>

                    <Autocomplete
                      fullWidth
                      value={subTicketType || null}
                      onChange={(event, newValue) => {
                        // let newFormValues = { ...formValues };
                        // newFormValues["subTicketType"] = newValue;
                        // setFormValues(newFormValues);
                        setSubTicketType(newValue);
                      }}
                      size="small"
                      disablePortal
                      options={["HEALTH_TICKET", "ADMIN_TICKET"]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          placeholder="Select Gender"
                          sx={{
                            color: "#127DDD",
                            fontWeight: "300",
                            fontSize: "13px",
                            lineHeight: " 15px",
                            "& input::placeholder": {
                              color: "#777777",
                              fontWeight: "300",
                              fontSize: "13px",
                              lineHeight: " 15px",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Typography
                      sx={{
                        fontSize: "17px",
                        fontWeight: "400",
                        lineHeight: "14px",
                        color: "#000000",
                        marginBlock: "10px",
                      }}
                    >
                      Remarks
                    </Typography>

                    <TextField
                      multiline
                      sx={{
                        color: "#127DDD",
                        fontWeight: "300",
                        fontSize: "13px",
                        lineHeight: " 15px",
                        "& input::placeholder": {
                          color: "#777777",
                          fontWeight: "300",
                          fontSize: "13px",
                          lineHeight: " 15px",
                        },
                      }}
                      size="small"
                      fullWidth
                      placeholder="Enter Remarks"
                      value={formValues.remark}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          remark: e.target.value,
                        });
                      }}
                      inputProps={{
                        style: {
                          height: 80,
                          color: "#080808",
                          fontWeight: "300",
                          fontSize: "13px",
                          lineHeight: " 15px",
                        },
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      sx={{
                        fontSize: "17px",
                        fontWeight: "400",
                        lineHeight: "14px",
                        color: "#000000",
                        marginBlock: "10px",
                      }}
                    >
                      Upload Images
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      <Button
                        sx={{
                          fontWeight: "400",
                          fontSize: "13px",
                          lineHeight: "15px",
                          color: "#FFFFFF",
                          textTransform: "capitalize",
                        }}
                        variant="contained"
                        startIcon={
                          <AttachFileIcon style={{ color: "#FFFFFF" }} />
                        }
                        onClick={handleUpload}
                      >
                        Upload
                      </Button>

                      {selectedFiles.length === 0 ? (
                        <Typography sx={{ marginLeft: "20px" }}>
                          No Selected Files
                        </Typography>
                      ) : null}
                    </Box>
                  </Grid>
                  <BrowserView>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      sx={{ display: "flex" }}
                    >
                      {selectedFiles.length > 0
                        ? selectedFiles.map((file, index) => (
                            <Paper
                              key={index}
                              elevation={3}
                              style={{
                                maxWidth: "200px",
                                marginInline: "10px",
                                marginBlock: "5px",
                                display: "flex",
                                flexWrap: "wrap",
                              }}
                            >
                              <Box position="relative">
                                {file.name.toLowerCase().endsWith(".pdf") ? (
                                  <Box
                                    sx={{
                                      height: "200px",
                                      width: "150px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <PictureAsPdfIcon
                                      style={{
                                        fontSize: 100,
                                        color: "#127DDD",
                                      }}
                                    />
                                  </Box>
                                ) : (
                                  <img
                                    src={file.source}
                                    alt={file.source}
                                    style={{
                                      maxWidth: "200px",
                                      height: "200px",
                                    }}
                                  />
                                )}
                                <IconButton
                                  size="small"
                                  color="secondary"
                                  onClick={() => handleRemove(index)}
                                  style={{
                                    position: "absolute",
                                    top: 5,
                                    right: 5,
                                    color: "red",
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                                <Typography variant="body2" align="center">
                                  {file.name}
                                </Typography>
                              </Box>
                            </Paper>
                          ))
                        : null}
                    </Grid>
                  </BrowserView>
                  <MobileView>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {selectedFiles.length ? (
                        selectedFiles.map((file, index) => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "4px",
                              borderBottom: "1px solid #000",
                            }}
                          >
                            <Typography variant="body2">{file.name}</Typography>
                            <IconButton
                              size="small"
                              color="secondary"
                              onClick={() => handleRemove(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        ))
                      ) : (
                        <Typography>No Selected Files</Typography>
                      )}
                    </Grid>
                  </MobileView> */}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ marginBlock: "10px" }}
                  >
                    <Button variant="contained" onClick={handleRaiseTicket}>
                      Raise Request
                    </Button>
                  </Grid>
                </Grid>
              </DialogContent>
            </>
          )}
        </Dialog>
      </Portal>
    </Container>
  );
};

export default Tickets;
