import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { getListOfTest } from "../../../services/api/getApi";
import { saveData } from "../../../services/api/postApi";
import { BASE_URL } from "../../../assets/constantsFile";
import { userContext } from "../../../common/context/usercontext";
import ReportHeaderComp from "./reportComps/reportHeaderComp";
import ReportListComp from "./reportComps/reportListComp";

const ReportContentComp = (props) => {
  let patientDetails = "";

  if (props.patientDetails) {
    patientDetails = props.patientDetails;
  }

  const contextData = useContext(userContext);
  const [listOfTests, setListOfTests] = useState([]);

  useEffect(() => {
    getTests();
    async function getTests() {
      const user = await getListOfTest();

      if (user.error) {
      } else {
        const data1 = user.data;
        setListOfTests(data1);
      }
    }
  }, []);

  const [values, setValues] = useState("");
  const [testData, setTestData] = useState();
  const [reportItems, setReportItems] = useState(patientDetails.reports);

  let newReportItems = [];

  const dataHandler = (value) => {
    console.log({ values: value });

    setValues(value);

    setTestData({
      reportName: value,
      prescribedDate: new Date(),
      reportURL: null,
    });
  };

  const addReports = () => {
    newReportItems = [...reportItems, testData];
    setReportItems(newReportItems);
    setValues("");

    console.log({ newReportItems_11: newReportItems });

    let o = {
      reports: newReportItems,
      patientId: patientDetails.patientId,
      docId: localStorage.getItem("DOCID"),
      appointmentId: props.appointmentId,
      caseId: props.caseId,
    };

    console.log({ "report object to be saved": o });
    saveTest(o);
  };

  const saveTest = async (obj) => {
    const user = await saveData(
      BASE_URL + "doctor/prescription/tests",
      obj,
      contextData.authHeader
    );

    if (user.error) {
      console.log({ "error saving": user.error });
    } else {
      console.log({ " saved successfully": user.data });
    }
  };

  console.log({ newReportItems: newReportItems });
  console.log({ testData: testData });

  return (
    <Box>
      <Grid container>
        <Grid item lg={12} sx={{ display: "none" }}>
          <Stack direction={"row"} spacing={2}>
            <Autocomplete
              freeSolo
              size="small"
              fullWidth
              id="newTest"
              options={listOfTests.map((option) => option.reportName)}
              defaultValue={""}
              value={values}
              filterSelectedOptions
              ListboxProps={{ sx: { fontSize: 11 } }}
              onInputChange={(event, newInputValue) => {
                dataHandler(newInputValue);
                console.log({ Custom_report_Name: newInputValue });
              }}
              onChange={(event, value) => {}}
              renderInput={(params) => (
                <TextField
                  sx={{ "& label": { fontSize: ".70rem" } }}
                  InputProps={{ style: { padding: 0 } }}
                  InputLabelProps={{ style: { fontSize: ".65rem" } }}
                  {...params}
                  label="Prescribe new test here..."
                  placeholder=""
                />
              )}
            />
            <Button
              variant="contained"
              onClick={addReports}
              sx={{ fontSize: 11, height: 30 }}
            >
              Submit
            </Button>
          </Stack>
        </Grid>

        <Grid item lg={12}>
          {/* <ReportHeaderComp /> */}
        </Grid>
        <Grid item lg={12}>
          {/* <ReportHeaderComp /> */}
        </Grid>

        <Grid item lg={12}>
          <ReportListComp
            reportItems={reportItems}
            patientId={patientDetails.patientId}
            appointmentId={props.appointmentId}
            caseId={props.caseId}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReportContentComp;
