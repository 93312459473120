import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import { BASE_URL } from "../../../../assets/constantsFile";
import { getCurrentDateAndTimeFormatted } from "../../../../assets/utils";
import { saveData } from "../../../../services/api/postApi";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import saveIcon from "../../../../assets/images/svg/formEdit.svg";

const style = {
    row: {
        display: "flex",
        alignItems: "center",
        px: 1,
        py: 0.87,
        borderRadius: "16.39px",
        background: "#F4FBFF",
    },
    title: { fontFamily: "Roboto", fontWeight: "600", fontSize: "1rem", lineHeight: "19px", color: "#127DDD" },
    textRow: { display: "flex", alignItems: "center" },
    text: { fontFamily: "Roboto", fontWeight: "400", fontSize: "1rem", lineHeight: "19px", pl: 2 },
    border: { height: "36px", borderLeft: "1px solid #127ddd" },
    icon: { mt: 0.25, display: "flex", justifyContent: "center" },
};

const DiagnosisComp = ({ observationContentValue, caseId }) => {
    const [observationContent, setObservationContent] = useState(observationContentValue);
    const [observation, setObservation] = useState("");

    const addObservation = (observation) => {
        if (observation) {
            let upObs = "[" + getCurrentDateAndTimeFormatted(" ") + "]" + ": " + observation;

            let saveObjs = upObs + "\n" + observationContent;

            setObservationContent(saveObjs);

            let o = {
                caseId: caseId,
                observations: saveObjs,
            };
            saveObs(o);
            console.log({ observation: observation });
        }
    };

    const saveObs = async (obj) => {
        const user = await saveData(BASE_URL + "doctor/prescription/observations", obj, "");

        if (user.error) {
        } else {
            setObservation("");
            setObservationContent(user.data.observations);
            console.log({ "observation_user.data": user.data.observations });
        }
    };

    return (
        <Fragment>
            <Grid container sx={style.row}>
                <Grid item lg={3}>
                    <Typography sx={style.title}>On Examination(O/E)</Typography>
                </Grid>
                <Grid item lg={8} sx={style.textRow}>
                    <Stack direction="column" spacing={1} sx={{ width: "100%" }}>
                        <TextField
                            size="small"
                            fullWidth
                            label="Write your diagnosis here..."
                            id="fullWidth"
                            multiline
                            value={observation}
                            sx={{
                                py: 1,
                                px: 0.5,
                                "& .MuiOutlinedInput-root:hover": {
                                    "& > fieldset": {
                                        borderColor: "#127DDD",
                                    },
                                },
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#127DDD",
                                    },
                                },
                            }}
                            onChange={(e) => {
                                setObservation(e.target.value);
                            }}
                            rows={1}
                            variant="outlined"
                            inputProps={{
                                style: { fontSize: ".875rem", fontWeight: "500" },
                            }}
                            InputLabelProps={{
                                style: { fontSize: ".875rem", fontWeight: "500", color: "#127DDD" },
                            }}
                        />
                        {observationContent && (
                            <Box sx={{ maxHeight: "20vh", background: "#fff", overflow: "auto" }}>
                                <Stack justifyContent={"flex-end"} sx={{ m: 1 }}>
                                    <Typography variant="body2" sx={{ fontWeight: "300", whiteSpace: "pre-line" }}>
                                        {observationContent}
                                    </Typography>
                                </Stack>
                            </Box>
                        )}
                    </Stack>
                </Grid>
                <Grid item lg={1} sx={{ display: "flex", justifyContent: "center" }}>
                    <IconButton aria-label="add" size="small" onClick={() => addObservation(observation)} type="submit">
                        <img src={saveIcon} sx={{ width: "100%" }} />
                    </IconButton>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default DiagnosisComp;
