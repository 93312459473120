import { Box, Grid } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import TicketCardView from "./ticketCardView";
import { getAllTickets } from "../../services/genericTicketingSystem";
import dayjs from "dayjs";
import useWindowDimensions from "../../../../../assets/customhooks";

const TicketListView = ({
  date = dayjs(),
  userId = localStorage.getItem("USER_ID_CORP_SALES"),
  corpId = localStorage.getItem("CORPID"),
  startDate,
  endDate,
  status,
  selectedCompany,
  ticketType,
  searchText,
}) => {
  console.log({
    startDate,
    endDate,
    status,
    selectedCompany,
    ticketType,
    searchText,
  });
  const [ticketList, setTicketList] = useState([]);

  const [filteredTicketList, setFfilteredTicketList] = useState([]);

  useEffect(() => {
    getAllTickets(
      startDate.toISOString().split("T")[0],
      endDate.toISOString().split("T")[0],
      corpId,
      setTicketList,
      setFfilteredTicketList
    );
  }, [startDate, endDate]);

  useEffect(() => {
    setFfilteredTicketList(
      ticketList.filter(
        (value) =>
          (!ticketType?.ticketType ? true : value?.ticketType === ticketType?.ticketType) &&
          (!status?.value || status?.value === "ALL" ? true : value.status === status?.value) &&
          (!searchText
            ? true
            : value?.raisedBy?.toUpperCase()?.includes(searchText?.trim()?.toUpperCase()))
      )
    );
  }, [ticketList, status, corpId, ticketType, searchText]);

  const { height, width } = useWindowDimensions();

  return (
    <Fragment>
      <Box sx={{ maxHeight: height - 400, overflow: "auto" }}>
        <Grid container spacing={2}>
          {filteredTicketList.map((value, index) => (
            <Grid item lg={12} key={index}>
              <TicketCardView ticket={value} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Fragment>
  );
};

export default TicketListView;
