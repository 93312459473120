import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";

const statusList = [
  { label: "ALL", value: "ALL" },
  { label: "COMPLETED", value: "COMPLETED" },
  { label: "PENDING", value: "PENDING" },
];

const StatusFilter = ({ selectStatus, setSelectStatus }) => {
  const handleChange = (event) => {
    setSelectStatus(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl
        fullWidth
        size="small"
        sx={{
          "& fieldset": { border: "none" },
        }}>
        <InputLabel sx={{ fontSize: 11 }}>Select Status</InputLabel>
        <Select
          sx={{ fontSize: 11, fontWeight: 600 }}
          value={selectStatus}
          label="Select Status"
          onChange={handleChange}>
          {statusList.map((it, index) => (
            <MenuItem value={it?.value} key={index}>
              {it?.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
export default StatusFilter;
