import { Box, Button, Grid, TextField } from "@mui/material";

const LandingAppointmentRight = () => {
  return (
    <Box sx={{ backgroundColor: "#EFF5FF", p: 4, borderRadius: "10px" }}>
      <Grid container spacing={4} sx={{ paddingBlock: "60px" }}>
        <Grid item lg={6} xs={12}>
          <TextField
            fullWidth
            color="customLight"
            placeholder="Your Name"
            sx={{
              fieldset: { border: "none ! important" },
              input: { background: "#fff", borderRadius: 2 },
            }}
            inputProps={{ style: { color: "#4a4a4a" } }}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <TextField
            fullWidth
            placeholder="Your Email"
            sx={{
              fieldset: { border: "none ! important" },
              input: { background: "#fff", borderRadius: 2 },
            }}
            inputProps={{ style: { color: "#4a4a4a" } }}
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <TextField
            fullWidth
            placeholder="Your Mobile"
            sx={{
              fieldset: { border: "none ! important" },
              input: { background: "#fff", borderRadius: 2 },
            }}
            inputProps={{ style: { color: "#4a4a4a" } }}
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <TextField
            multiline
            fullWidth
            rows={4}
            placeholder="Describe your problem"
            sx={{
              background: "#fff",
              fieldset: { border: "none ! important" },
              input: { backgroundc: "#fff", borderRadius: 2 },
            }}
            inputProps={{ style: { color: "#4a4a4a" } }}
          />
        </Grid>
        {/* <Grid item lg={6} xs={12}>
          <TextField
            fullWidth
            placeholder="Choose Date"
            sx={{
              fieldset: { border: "none ! important" },
              input: { background: "#fff", borderRadius: 2 },
            }}
            inputProps={{ style: { color: "#4a4a4a" } }}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <TextField
            fullWidth
            placeholder="Choose Date"
            sx={{
              fieldset: { border: "none ! important" },
              input: { background: "#fff", borderRadius: 2 },
            }}
            inputProps={{ style: { color: "#4a4a4a" } }}
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <TextField
            multiline
            fullWidth
            rows={4}
            placeholder="Describe your problem"
            sx={{
              background: "#fff",
              fieldset: { border: "none ! important" },
              input: { backgroundc: "#fff" },
            }}
            inputProps={{ style: { color: "#4a4a4a" } }}
          />
        </Grid> */}
        <Grid item lg={12} xs={12}>
          <Button variant="contained" size="large" fullWidth sx={{ py: 2 }}>
            Send Message
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
export default LandingAppointmentRight;
