import { Box, Button, Card, CardActions, CardContent, CardHeader, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Paper, Slide, Snackbar, Stack, TextField, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React, { forwardRef, Fragment, useEffect, useState } from "react";
import TableHeader from "./templateComponents/tableHeader";
import PrescriptionTemplateView from "./templateForms/prescriptionTemplateFormView";
import PrescriptionTemplateEdit from "./templateForms/prescriptionTemplateFormEdit";
import { BASE_URL } from "../assets/constantsFile";
import MuiAlert from '@mui/material/Alert';
import { deleteData, saveData } from "../services/api/postApi";
import DeleteIcon from '@mui/icons-material/Delete';
import TableHeaderView from "./templateComponents/tableHeaderView";


const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const PrescriptionTemplateCard = (props) => {


  const [openNotice, setOpenNotice] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenNotice(false);
  };


    const [newPrescription, setNewPrescription] = useState(props.prescription.medicines);
    const [templateNameEdit, setTemplateNameEdit] = useState(props.prescription.templateName);
    const [prescription, setPrescription] = useState(props.prescription);
    //view saved template
    const [openView, setOpenView] = useState(false);
    const handleClickOpenView = () => {
        setOpenView(true);
      };
    const handleCloseView = () => {
        setOpenView(false);
      };

      //edit saved template
      const [openEdit, setOpenEdit] = useState(false);
      const handleClickOpenEdit = () => {
          setOpenEdit(true);
        };
      const handleCloseEdit = () => {
          setOpenEdit(false);
        };


        //delete sved template
        const [openDelete, setOpenDelete] = useState(false);
        const handleClickOpenDelete = () => {
          setOpenDelete(true);
          };
        const handleCloseDelete = () => {
          setOpenDelete(false);
          };



        async function editPrescriptionHandler(pres, templateNameEdit) {


        if(templateNameEdit){
    
          const presObj = {
                        docId: localStorage.getItem("DOCID"),
                        templateName: templateNameEdit,
                        medicines: pres,
                        templateId: props.prescription.templateId,
                       };
      
      
      
          const response = await saveData(BASE_URL+"doctor/prescription/template",presObj);

          if (response.error) {
            setSeverity("error");
            setMessage("Template update failed!")
            setOpenNotice(true);
          } else {
            setSeverity("success");
            setMessage("Template updated successfully!")
            setOpenNotice(true);
    
          }
       
          const data = await response.data;
          setPrescription(data);
         
        }


        console.log({"edit data":pres})
      }

      const [headerContent, setHeaderContent] = useState(<CardHeader sx={{ height:40, background:'#208F94' }} title="" subheader={<Typography variant="body2" sx= {{ fontWeight:600, color:"#fff" }}>{prescription.templateName.toUpperCase()}</Typography>} align="center">

      </CardHeader>);

const [bodyContent, setBodyContent] = useState(
  <Stack direction="column" spacing={1}>
  {prescription.medicines.filter((medicine, index) => (index < 3)).map((medicine,index) => (
      (medicine.medicineName !==null && medicine.medicineName !== "") ? <Paper key = {index} sx={{ border: '1px solid #D9D9D9',  width:"100%", pl:1, pr:1 }} align="center">
      <Typography variant="caption" noWrap>
          {medicine.medicineName}
      </Typography>
  </Paper> : ""
      ))}
  
  </Stack>
);

      useEffect(() => {      
        setNewPrescription(props.prescription.medicines);
      }, []);
      

      useEffect(() => {      
        setHeaderContent(<CardHeader sx={{ height:40, background:'#208F94' }} title="" subheader={<Typography variant="body2" sx={{fontWeight: 600, color:"#fff"}}>{prescription.templateName.toUpperCase()}</Typography>} align="center">

        </CardHeader>);

        setBodyContent(<Stack direction="column" spacing={1}>
  {prescription.medicines.filter((medicine, index) => (index < 3)).map((medicine,index) => (
      (medicine.medicineName !==null && medicine.medicineName !== "") ? <Paper component={Stack} key = {index} sx={{ border: '1px solid #D9D9D9', width:"100%", p:.5 }} align="center" >
      <Typography variant="caption" noWrap>
          {medicine.medicineName}
      </Typography>
  </Paper> : ""
      ))}
  
  </Stack>);

  setNewPrescription(prescription.medicines)
      }, [prescription]);




   const deleteTemplate = () => {


    deleteTemp(props.prescription.templateId);

handleCloseDelete();


   }   

   const deleteTemp = async(templateId) => {

     const template = await deleteData(BASE_URL + "doctor/deletePrescriptionTemplate/"+ templateId );

     if(template.error) {


console.log({"template.error":template.error})

     } else {
      const data = await template.data;
      props.onDelelePrescription(data);
      console.log({"template.data":template.data})
     }
   }


    return (

        <Grid item lg={3} md={15} sm={15} xs={15} sx={{height:250 }}>


<Snackbar open={openNotice} autoHideDuration={6000} onClose={handleCloseNotice}>
        <Alert onClose={handleCloseNotice} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>

        <Box sx={{height:250, p:1,  }}>

            <Card sx={{height:230,border: '1px solid #D9D9D9',  }}>
            {headerContent}
                
                <Box sx={{height:150, background:"#F3F3F3"}}>
                <CardContent sx={{height:117, borderRadius:1}} >

                {bodyContent}
               
                </CardContent>
                </Box>

                
                <CardActions sx={{ height:35, pb:2 }} >

<Box sx={{ height:35, width:"100%"}}>
                <Grid container>


                <Grid container>
                <Grid item lg={4} md={4} sm={4} xs={4}  display="flex" justifyContent="flex-start">
                <IconButton onClick={handleClickOpenView} aria-label="view" >

                <VisibilityIcon color="default" />

                </IconButton>
                </Grid>
               
               
               
                <Grid item lg={4} md={4} sm={4} xs={4}  display="flex" justifyContent="center">
                <IconButton aria-label="delete" onClick={handleClickOpenDelete}>
                  
                <DeleteIcon color="default"  />
                
                </IconButton>


                <Fragment>
          <Dialog  maxWidth={'md'} open={openDelete} onClose={handleCloseDelete} TransitionComponent={Transition} >
          
          <DialogTitle>
          <Box sx={{ flexGrow: 1 ,maxHeight:'16vh'}}>
          </Box>
          </DialogTitle>

        <DialogContent>
          <Box sx={{ flexGrow: 1 ,ml:2}}>
          <Typography>Do you want to delete the template? </Typography>
          </Box> 
          </DialogContent>

          <DialogActions>
          <Box sx={{width:"100%",p:3}}>
          <Stack direction={"row"} display="flex" justifyContent={"space-between"} alignItems="center">
          
          <Button autoFocus onClick={handleCloseDelete} variant="outlined" size="small">cancel</Button>
          <Button color="error" onClick={deleteTemplate}  variant="outlined" size="small">
            Delete
          </Button>
          </Stack>
          </Box>
        </DialogActions>
           
          </Dialog>
        </Fragment>
                </Grid>

            

                <Grid item lg={4} md={4} sm={4} xs={4}  display="flex" justifyContent="flex-end">
                <IconButton aria-label="edit" onClick={handleClickOpenEdit} >

               <EditIcon color="default" />

                </IconButton>
                </Grid>

                </Grid>


                <Fragment>
          <Dialog  maxWidth={'md'} open={openView} onClose={handleCloseView} TransitionComponent={Transition} >
          
          <DialogTitle  sx={{ background:"#D9D9D9" }} id="alert-dialog-title" >
          {prescription.templateName}
        </DialogTitle>

        <DialogContent dividers >
          <Box sx={{ flexGrow: 1 }}>
            <PrescriptionTemplateView newPrescription={newPrescription}/>
          </Box> 
          </DialogContent>
          <DialogActions>
          <Button onClick={handleCloseView} variant="outlined" size="small">cancel</Button>
        </DialogActions>
           
          </Dialog>
        </Fragment>



        <Fragment>
          <Dialog  maxWidth={'md'} open={openEdit} onClose={handleCloseEdit} TransitionComponent={Transition} >
          
          <DialogTitle  sx={{ background:"#D9D9D9" }} id="alert-dialog-title" >
          Edit Template
        </DialogTitle>

        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} >
  
            <PrescriptionTemplateEdit newPrescription={newPrescription} templateName = {props.prescription.templateName}
                onEditPrescriptionTemplate={editPrescriptionHandler}
              />
          </Box> </DialogContent>
          <DialogActions>
          <Button onClick={handleCloseEdit} variant="outlined" size="small">cancel</Button>
          <Button autoFocus onClick={handleCloseEdit} form='myFormEdit' type="submit"  variant="outlined" size="small">
            Update
          </Button>
        </DialogActions>
           
          </Dialog>
        </Fragment>
                
                </Grid>
                   
                </Box>            
                    
                </CardActions>
            </Card>
            </Box>
        </Grid>
    );
}
 
export default PrescriptionTemplateCard;