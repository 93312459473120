import { Fragment, useEffect, useState } from "react";

import { Box, Chip, Grid, Tab, ThemeProvider } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import LineChartComponent from "./lineChartComponent";
import { customThemeTabs } from "../../../../assets/themes/customTheme";
import { getFormattedDDMonthYYYY } from "../../../../assets/utils";

const parserBp = (value) => {
  let val = value?.split("/")[0];
  return val;
};
const VitalsGraphs = ({ employeeData }) => {
  const [graphData, setGraphData] = useState([]);
  useEffect(() => {
    let employeeDataTransformed = employeeData
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .map((item) => ({
        name: getFormattedDDMonthYYYY(item?.date),
        bp:
          parseInt(
            item?.orgEmployeesVitalsEntity
              ? parserBp(item?.orgEmployeesVitalsEntity.bp)
              : parserBp(item?.orgDailyTrendsEntity?.bp)
          ) || null,
        sugar:
          parseInt(
            item?.orgEmployeesVitalsEntity
              ? item?.orgEmployeesVitalsEntity.sugar
              : item?.orgDailyTrendsEntity?.sugar
          ) || null,
        weight:
          parseInt(
            item?.orgEmployeesVitalsEntity
              ? item?.orgEmployeesVitalsEntity.weight
              : item?.orgDailyTrendsEntity?.weight
          ) || null,
      }));
    setGraphData(employeeDataTransformed);
    console.log({ employeeDataTransformed });
  }, [employeeData]);
  console.log({ employeeData, graphData });
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <ThemeProvider theme={customThemeTabs}>
      <Fragment>
        <Box>
          <Grid container>
            <Grid item lg={12}>
              <Box sx={{ width: "100%", typography: "body1", color: "#000" }}>
                <TabContext value={value}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      color: "#000",
                    }}>
                    <TabList
                      textColor="primary"
                      sx={{ height: 10, p: 0, color: "#000" }}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto">
                      <Tab
                        sx={{ color: "#000" }}
                        label={
                          <Chip
                            sx={{
                              "& .MuiChip-label": {
                                color: value === "1" ? "#fff" : "#000",
                              },
                            }}
                            label="Blood Pressure"
                            variant={value === "1" ? "filled" : "outlined"}
                            color="primary"
                          />
                        }
                        value="1"
                      />
                      <Tab
                        label={
                          <Chip
                            sx={{
                              "& .MuiChip-label": {
                                color: value === "2" ? "#fff" : "#000",
                              },
                            }}
                            label="Sugar"
                            variant={value === "2" ? "filled" : "outlined"}
                            color="primary"
                          />
                        }
                        value="2"
                      />
                      <Tab
                        label={
                          <Chip
                            sx={{
                              "& .MuiChip-label": {
                                color: value === "3" ? "#fff" : "#000",
                              },
                            }}
                            label="Weight"
                            variant={value === "3" ? "filled" : "outlined"}
                            color="primary"
                          />
                        }
                        value="3"
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    {graphData?.filter((item) => item.bp).length > 1 && (
                      <LineChartComponent
                        data={graphData.filter((item) => item.bp)}
                        graphType="bp"
                        unit="in mmHg"
                      />
                    )}
                  </TabPanel>
                  <TabPanel value="2">
                    {graphData?.filter((item) => item.sugar).length > 1 && (
                      <LineChartComponent
                        data={graphData.filter((item) => item.sugar)}
                        graphType="sugar"
                        unit="in mg/dL"
                      />
                    )}
                  </TabPanel>
                  <TabPanel value="3">
                    {graphData?.filter((item) => item.weight).length > 1 && (
                      <LineChartComponent
                        data={graphData.filter((item) => item.weight)}
                        graphType="weight"
                        unit="in kg"
                      />
                    )}
                  </TabPanel>
                </TabContext>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Fragment>
    </ThemeProvider>
  );
};

export default VitalsGraphs;
