import { Box, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomTextField from "./customTextField";

export const CustomDate = ({ date, setDate, label }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        disableMaskedInput
        label={label}
        value={date}
        onChange={(newValue) => setDate(newValue)}
        slotProps={{ textField: { size: "small", fullWidth: true } }}
        format="LL"
        inputFormat="DD MMM YYYY"
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label={label}
            placeholder="DD/MM/YYY"
            size="small"
            fullWidth
            sx={{
              "& fieldset": {
                fontSize: 11,
                height: 41,
                borderRadius: 3,
              },
              input: {
                fontSize: 11,
                fontWeight: 600,
                color: "#000",
                fontFamily: "Poppins",
                fontStyle: "normal",
                mt: 0.1,
              },
            }}
            InputLabelProps={{
              style: {
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: 10,
                color: "#000",
                lineHeight: "normal",
                opacity: 0.8,
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
