import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApi";

export const getDepartments = async (corpId, setDepartmentList) => {
  const url = BASE_URL + `org/departments?corpId=${corpId}`;
  let data = [];
  const departments = await getData(url, "");
  if (departments.error) {
    data = [];
  } else {
    data = departments.data;
  }
  setDepartmentList(data);
  return data;
};

export const getTestList = async (corpId, selectedDepartment, setTestList) => {
  let dep = "";

  selectedDepartment ? (dep = selectedDepartment) : (dep = "");
  const url = BASE_URL + `org/analytics/${corpId}?department=${dep}`;

  const analytics = await getData(url, "");

  if (analytics.error) {
    setTestList([]);
  } else {
    const testList = [{ name: "ALL", value: null }];
    if (analytics.data) {
      for (const [key, value] of Object.entries(analytics.data)) {
        testList.push({ name: key, value: key });
      }
    }
    setTestList(testList);
  }
};

export const getEmployeeDetailsAll = async (
  corpId,
  employementType,
  setEmployeeList,
  setEmployeeListFiltered
) => {
  const url =
    BASE_URL +
    `org/detailed/all?corpId=${corpId}&employmentType=${employementType}`;
  let data = [];
  const departments = await getData(url, "");
  if (departments.error) {
    data = [];
  } else {
    data = departments.data;
  }
  setEmployeeList(data);
  setEmployeeListFiltered(data);
  return data;
};

export const getEmployeeListToBeTested = async (corpId, setEmployeeList) => {
  const url = BASE_URL + `org/employee/tests?corpId=${corpId}`;
  let data = [];
  const departments = await getData(url, "");
  if (departments.error) {
    data = [];
  } else {
    data = departments.data;
  }
  setEmployeeList(data);
  return data;
};

export const searchByEmpId = async (e, corpId, searchText, setEmployeeList) => {
  e.preventDefault();
  if (searchText) {
    const url = BASE_URL + `org/detailed/${searchText}?corpId=${corpId}`;

    const employee = await getData(url, "");

    if (employee.error) {
    } else {
      const list = [employee.data];
      setEmployeeList(list);
    }
  }
};

export const getEmployeeLifeCycleData = async (
  corpId,
  empId,
  startDate,
  endDate,
  setEmployeeList
) => {
  // const url =
  //   BASE_URL +
  //   `org/employeeLifecycle?corpId=${corpId}&empId=${empId}&startDate=${startDate}&endDate=${endDate}`;
  const url =
    BASE_URL + `org/employeeLifecycle?corpId=${corpId}&empId=${empId}`;
  let data = [];
  const departments = await getData(url, "");
  if (departments.error) {
    data = [];
  } else {
    data = departments.data;
  }
  setEmployeeList(data);
  return data;
};

export const getCorpMedicineList = async (
  corpId,
  medInitials,
  setMedicineList
) => {
  const url =
    BASE_URL + `doctor/medicineSearch/${medInitials}?corpId=${corpId}`;
  let data = [];
  const meds = await getData(url, "");
  if (meds.error) {
    data = [];
  } else {
    data = meds.data;
  }
  setMedicineList(data);
  return data;
};

export const getDataGridConfig = async (userId, setGridConfig, page) => {
  const url =
    BASE_URL +
    `org/config/items?corpId=${userId}&orgConfigType=HEALTH_RECORDS_ITEMS&page=${page}`;

  const corp = await getData(url, "");

  if (corp.error) {
    //console.log({ configerror: corp?.error });
    setGridConfig([]);
  } else {
    let config = corp?.data?.permissions?.parentRoles?.filter(
      (item) => item.access
    );

    console.log({ config12345: config });
    setGridConfig(config);
    // setGridConfig(
    //   Object.fromEntries(
    //     config?.map((e) => [
    //       e.nameId.toLowerCase().trim(),
    //       { access: e.access, sequence: e.sequence },
    //     ])
    //   )
    // );
  }
};

export const searchByEmpIdPreEmployment = async (
  e,
  searchText,
  employeeList,
  setEmployeeList
) => {
  e.preventDefault();
  console.log({ searchText });
  if (searchText !== "") {
    const firstSpaceIndex = searchText?.indexOf("-");

    console.log({ firstSpaceIndex });

    // If there is a space, split at the first space; otherwise, use the whole string as empId
    const empIdSplitted =
      firstSpaceIndex !== -1
        ? searchText.slice(0, firstSpaceIndex).trim()
        : searchText;
    const nameSplitted =
      firstSpaceIndex !== -1
        ? searchText.slice(firstSpaceIndex + 1).trim()
        : searchText;

    console.log({ empIdSplitted, nameSplitted });

    const filteredDataContractor = employeeList.filter((obj) => {
      const empIdMatch =
        obj.empId?.toLowerCase() === empIdSplitted?.toLowerCase();
      const nameMatch = obj?.name
        ?.toLowerCase()
        .includes(nameSplitted?.toLowerCase());

      // Check for matches based on empId and at least a part of the name
      return empIdMatch || nameMatch;
    });

    console.log({ filteredDataContractor });
    setEmployeeList(filteredDataContractor || []);
  }
};

export const fetchEmployeeList = async (
  corpId,
  setEmployeeList,
  setEmployeeListStatic
) => {
  const url =
    BASE_URL +
    `org/detailed/all?corpId=${corpId}&employmentType=PRE_EMPLOYMENT`;
  const employees = await getData(url, "");

  if (employees.error) {
    //console.log({ error: employees.error });
  } else {
    //console.log({ employees: employees.data });
    let list = employees.data;

    setEmployeeList(list);
    setEmployeeListStatic(list);
  }
};

export const fetchForm21Data = async (corpId, setEmpList) => {
  const url =
    BASE_URL +
    `org/corpUploadStatusTracker?corpId=${corpId}&corpUploadType=FORM_21`;
  const employees = await getData(url, "");

  if (employees.error) {
    //console.log({ error: employees.error });
    setEmpList([]);
  } else {
    //console.log({ employees: employees.data });
    setEmpList(employees.data?.listOfForm21);
  }
};

export const fetchVendorRanges = async (setVendorConfigList) => {
  const url = BASE_URL + `org/config/vendor`;
  const employees = await getData(url, "");

  if (employees.error) {
    //console.log({ error: employees.error });
  } else {
    //console.log({ employees: employees.data });
    setVendorConfigList(employees.data?.Jupiter);
  }
};

export const _fetchEmployeeById = async (
  corpId,
  setSeverity,
  setMessage,
  setOpenNotice,
  setSearchedEmployeeId,
  setFormValues,
  employeeId
) => {
  const url = BASE_URL + `org/detailed/${employeeId}?corpId=${corpId}`;

  const empData = await getData(url);
  if (empData.error) {
    setSeverity("error");
    setMessage(empData.error?.response?.data?.message);
    setOpenNotice(true);
    setSearchedEmployeeId(null);
  } else {
    setFormValues(empData.data);
    setSearchedEmployeeId(empData.data.empId);
  }
};

export const _getPhotos = async (corpId, corpUploadSubType, setImageList) => {
  const url =
    BASE_URL +
    `org/photos?corpId=${corpId}&corpUploadSubType=${corpUploadSubType}`;

  const images = await getData(url, "");

  if (images.error) {
    console.log({ error: images.error });
    setImageList([]);
  } else {
    console.log({ "successfullt get images": images.data });
    setImageList(images.data);
  }
};

export const _getHealthChampionsList = async (
  corpId,
  setHealthChampionsList
) => {
  const url = BASE_URL + `org/firstaid/healthchamp?corpId=${corpId}`;

  const response = await getData(url, "");

  if (response.error) {
    console.log({ error: response.error });
    setHealthChampionsList([]);
  } else {
    console.log({ success: response.data });
    setHealthChampionsList(response.data);
  }
};

export const _getCSREmployeeList = async (
  corpId,
  setEmployeeList,
  setEmployeeListStatic
) => {
  const url =
    BASE_URL + `org/csr/healthrecords?corpId=${corpId}&employmentType=CSR`;
  const response = await getData(url, "");

  if (response.error) {
    setEmployeeList([]);
    setEmployeeListStatic([]);
  } else {
    setEmployeeList(response.data);
    setEmployeeListStatic(response.data);
  }
};
