import { useEffect, useState } from "react";
import { Container } from "@mui/material";

import { BASE_URL } from "../../../../../../assets/constantsFile";
import { getData } from "../../../../../../services/api/getApi";
import OpdMain from "./subComp/opdMain";

const RecordOpd = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [analytics, setAnalytics] = useState([]);
  const [analyticsStatic, setAnalyticsStatic] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedTest, setTelectedTest] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [testList, setTestList] = useState([{ name: "ALL", value: null }]);

  const fetchDepartmentList = async () => {
    const url = BASE_URL + `org/departments?corpId=${corpId}`;
    const departments = await getData(url, "");
    if (departments.error) {
    } else {
      setDepartmentList(departments.data);
    }
  };

  const getAnalytics = async () => {
    let dep = "";

    selectedDepartment ? (dep = selectedDepartment) : (dep = "");
    const url = BASE_URL + `org/analytics/${corpId}?department=${dep}`;

    const analytics = await getData(url, "");

    if (analytics.error) {
      console.log("error");
    } else {
      console.log({ data: analytics.data });
      const list = [];
      const testList = [{ name: "ALL", value: null }];
      if (analytics.data) {
        for (const [key, value] of Object.entries(analytics.data)) {
          console.log(key, value);
          list.push(value);
          testList.push({ name: key, value: key });
        }
      }

      setTestList(testList);
      setAnalytics(list);
      setAnalyticsStatic(list);
    }
  };

  useEffect(() => {
    fetchDepartmentList();
  }, []);

  useEffect(() => {
    getAnalytics();
    setTelectedTest(null);
  }, [selectedDepartment]);

  console.log({ analytics });

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [date, setDate] = useState(new Date());

  return (
    <Container maxWidth={false} sx={{ minHeight: "78vh", mt: 2 }}>
      <OpdMain />
    </Container>
  );
};
export default RecordOpd;
