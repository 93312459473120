import { Typography } from "@mui/material";

export const CustomParagraph1 = ({ children }) => {
  return (
    <Typography
      sx={{
        boxSizing: "border-box",
        color: "rgb(141, 142, 146)",
        display: "block",
        fontFamily: "Open Sans, sans-serif",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        textAlign: "justify",
      }}>
      {children}
    </Typography>
  );
};

export const CustomParagraphWhite1 = ({ children }) => {
  return (
    <Typography
      sx={{
        boxSizing: "border-box",
        color: "#fff",
        display: "block",
        fontFamily: "Open Sans, sans-serif",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
      }}>
      {children}
    </Typography>
  );
};
