import RootTemplate from "../../global/templates/rootTemplate";
import StaffingNursesMain from "../../modules/staffing/nurses/staffingNursesMain";

const NursesIndex = () => {
  return (
    <RootTemplate>
      <StaffingNursesMain />
    </RootTemplate>
  );
};

export default NursesIndex;
