import { Fragment } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Pagination, PaginationItem, Typography } from "@mui/material";
const CustomPagination = ({ page, setPage, count = 0 }) => {
  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <Fragment>
      <Box
        sx={
          {
            //margin: "auto",
            //width: "fit-content",
            //alignItems: "center",
          }
        }>
        <Pagination
          color="primary"
          page={page}
          onChange={handleChange}
          count={count}
          renderItem={(item) => (
            <PaginationItem
              slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
              {...{ ...item, page: `Pdf ${item.page}` }}
            />
          )}
        />
      </Box>
    </Fragment>
  );
};

export default CustomPagination;
