import { Box, Grid, Tab } from "@mui/material";
import React, { Fragment, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CustomTitles from "../../../global/titles/customTitles";
import StatusTracker from "./statusTracker/statusTracker";
import Reports from "./reports/reports";

const AnalyticsMain = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // "Exception for Case Construction"
  if (corpId === "bbb269e5-b020-4257-ad8f-4da8c811801a") {
    corpId = "13a4b8c6-750f-442a-8414-f5465481e5d9";
  }
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomTitles title="Analytics" />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box sx={{ width: "100%" }}>
            <TabContext value={value}>
              <Box sx={{ borderColor: "divider" }}>
                <TabList
                  variant="scrollable"
                  scrollButtons="auto"
                  onChange={handleChange}
                  aria-label="lab API tabs example">
                  <Tab label="Analytics" value="1" />
                  <Tab label="Reports" value="2" />
                  {/* <Tab label="Bulk Record" value="3" /> */}
                </TabList>
              </Box>

              <TabPanel value="1" sx={{ p: 0 }}>
                <StatusTracker corpId={corpId} />
              </TabPanel>

              <TabPanel value="2" sx={{ p: 0 }}>
                <Reports corpId={corpId} />
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AnalyticsMain;
