import { Box, IconButton, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useEffect, useState } from "react";
import { format } from "date-fns";

const CustomDateNew = ({
  date,
  setDate,
  initialDate,
  disablePast,
  label,
  disableFuture,
}) => {
  const [dateValue, setDateValue] = useState(initialDate || null);
  useEffect(() => {
    setDateValue(date ? new Date(date) : null);
  }, [date]);

  const handleDateChange = (newValue) => {
    setDateValue(newValue);
    const formattedDate = format(newValue, "yyyy-MM-dd");
    setDate(formattedDate);
  };
  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          disablePast={disablePast}
          disableFuture={disableFuture}
          label={label}
          openTo="day"
          views={["year", "month", "day"]}
          value={dateValue}
          onChange={handleDateChange}
          format="YYYY MM DD"
          renderInput={(params) => <TextField {...params} size="small" />}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default CustomDateNew;
