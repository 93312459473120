import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApi";
import OldAilmentComponent from "./oldAilmentComponent";

const OldAilmentsIndex = ({ setSelectedTab, patientId, setCaseId }) => {
  const [caseList, setCaseList] = useState([]);

  // let patientId = "5401"
  const fetchCaseList = async () => {
    const url = BASE_URL + "patient/cases/" + patientId;
    const data = await getData(url, "");

    if (data.error) {
      console.log({ "error getting case list": data.error });
    } else {
      setCaseList(data.data);
    }
  };

  console.log({ caseList });
  console.log({ patientId });

  useEffect(() => {
    fetchCaseList();
  }, []);

  return (
    <Box>
      {caseList.map((item, index) => (
        <OldAilmentComponent
          diseaseName={item?.issue?.trim()}
          docName={item.doctorName}
          date={item.lastConsultationDate}
          onClick={() => {
            setSelectedTab("oldailments");
            setCaseId(item.caseId);
          }}
          visible={index === caseList?.length - 1 ? true : false}
          key={index}
        />
      ))}
    </Box>
  );
};

export default OldAilmentsIndex;
