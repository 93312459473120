import { Fragment, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import {
  fetchVendorRanges,
  getDepartments,
  getEmployeeDetailsAll,
  getTestList,
} from "../../../global/services/corporateServices";
import { DepartmetsAndtests } from "../../../global/components/customAutoCompletes";
import { CSVLink } from "react-csv";
import { transformData, transformDataDownLoadReport } from "../../../global/actions/actions";

const employementType = "";

const DownlaodReports = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [vendorConfigList, setVendorConfigList] = useState();
  useEffect(() => {
    fetchVendorRanges(setVendorConfigList);
  }, []);
  const [age, setAge] = useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [statusList, setStatusList] = useState([
    { name: "ALL", value: null },
    { name: "COMPLETED", value: "COMPLETED" },
    { name: "PENDING", value: "PENDING" },
  ]);
  const [departmentList, setDepartmentList] = useState([]);
  const [testList, setTestList] = useState([]);
  const [selectedTest, setTelectedTest] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectStatus, setSelectStatus] = useState(null);

  const [analyticsStatic, setAnalyticsStatic] = useState([]);
  const [analytics, setAnalytics] = useState([]);

  const [employeeList, setEmployeeList] = useState([]);
  const [employeeListFiltered, setEmployeeListFiltered] = useState([]);

  useEffect(() => {
    getDepartments(corpId, setDepartmentList);
    getTestList(corpId, "", setTestList);
    getEmployeeDetailsAll(corpId, employementType, setEmployeeList, setEmployeeListFiltered);
  }, []);

  useEffect(() => {
    setEmployeeListFiltered(
      employeeList.filter(
        (item) =>
          (selectedDepartment ? item.department === selectedDepartment : item) &&
          (selectStatus?.value ? item.status === selectStatus?.value : item)
      )
    );
  }, [selectedTest, selectedDepartment, selectStatus]);
  console.log({ departmentList, testList, employeeList, employeeListFiltered });
  return (
    <Fragment>
      <Box>
        <Grid container spacing={5}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <DepartmetsAndtests
              selectedTest={selectedTest}
              setTelectedTest={setTelectedTest}
              testList={testList}
              selectedDepartment={selectedDepartment}
              setSelectedDepartment={setSelectedDepartment}
              departmentList={departmentList}
              analyticsStatic={analyticsStatic}
              setAnalytics={setAnalytics}
              selectStatus={selectStatus}
              setSelectStatus={setSelectStatus}
              statusList={statusList}
            />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center">
            <CSVLink
              style={{
                padding: 10,
                height: 50,
                background: "#127DDD",
                color: "white",
                borderRadius: 10,
                fontWeight: 600,
              }}
              data={transformDataDownLoadReport(employeeListFiltered)}
              //headers={[]}
            >
              Download CSV
            </CSVLink>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default DownlaodReports;
