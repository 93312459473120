import { BASE_URL } from "../../assets/constantsFile";
import { getData } from "../../services/api/getApi";

export const getTopConsumedMedicines = async (
  corpId,
  setGraphData,
  duration
) => {
  const url =
    BASE_URL +
    `inventory/getTopConsumedMedicines/${corpId}?timePeriod=${duration}`;
  const result = await getData(url);
  if (result.error) {
    setGraphData([]);
  } else {
    setGraphData(result?.data);
  }
};

export const getNearToExpiry = async (
  corpId,
  setNearToExpiry,
  setNearToExpiryDetail,
  setConsolidatedData
) => {
  const url = BASE_URL + `inventory/getNearExpiryCount?corpId=${corpId}`;
  const result = await getData(url);
  if (result.error) {
    setNearToExpiry([]);
    setNearToExpiryDetail([]);
  } else {
    const temp = {
      "< 3 months": result?.data.lessThan3Months.count,
      "3-6 months": result?.data.between3And6Months.count,
      "6-12 months": result?.data.between6And12Months.count,
      "> 12 months": result?.data.moreThan12Months.count,
    };
    const tempDetails = {
      "< 3 months": result?.data.lessThan3Months.details,
      "3-6 months": result?.data.between3And6Months.details,
      "6-12 months": result?.data.between6And12Months.details,
      "> 12 months": result?.data.moreThan12Months.details,
    };
    setNearToExpiry(temp);
    setNearToExpiryDetail(tempDetails);
    const tempConsolidatedData = [
      ...result?.data.lessThan3Months.details,
      ...result?.data.between3And6Months.details,
      ...result?.data.between6And12Months.details,
      ...result?.data.moreThan12Months.details,
    ]
      .map((item) => ({
        batchCode: item.batchLabel,
        ...item,
      }))
      .map(({ batchLabel, ...rest }) => rest);

    setConsolidatedData(tempConsolidatedData);
  }
};

export const getReorderProductCounts = async (
  corpId,
  setReorderProductGraph,
  setReorderProductGraphDetail
) => {
  const url = BASE_URL + `inventory/getReorderProductCounts/${corpId}`;
  const result = await getData(url);
  if (result.error) {
    setReorderProductGraph([]);
  } else {
    // const temp1 = {
    //   name: "Below Reorder Point",
    //   sequence: 1,
    //   value: result.data.reorderCount.count,
    // };
    // const temp2 = {
    //   name: "Below reorder point Reorder",
    //   sequence: 2,
    //   value: result.data.below30PercentCount.count,
    // };

    const below30PercentProductCodes =
      result.data.below30PercentCount.details.map(
        (detail) => detail.productCode
      );

    // Filter out non-critical details from reorderCount
    const filteredReorderDetails = result.data.reorderCount.details.filter(
      (detail) => !below30PercentProductCodes.includes(detail.productCode)
    );

    const tempDetail = {
      "Above reorder point": result.data.aboveReorderPointCount?.details,
      "Below reorder point": result.data.reorderCount?.details,
    };

    const temp = [
      {
        name: "Above reorder point",
        value: result.data.aboveReorderPointCount?.count,
      },
      // {
      //   name: "Below 30% of reorder point",
      //   value: result.data.below30PercentCount.count,
      // },
      {
        name: "Below reorder point",
        value: result.data.reorderCount?.count,
      },
    ];

    setReorderProductGraph(temp);
    setReorderProductGraphDetail(tempDetail);
  }
};
