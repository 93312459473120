import React from "react";
import RootTemplate from "../../global/templates/rootTemplate";
import OhcRecordsMain from "../../modules/ohc/ohcRecords/ohcRecordsMain";

const OhcRecordsIndex = () => {
  return (
    <RootTemplate>
      <OhcRecordsMain />
    </RootTemplate>
  );
};

export default OhcRecordsIndex;
