import { Box, Grid, Typography } from "@mui/material";
import CustomTitles from "../../../global/titles/customTitles";
import Index from "./comps";

const OHCUpcomingMain = () => {
  return (
    <Box>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="OHC Upcoming" />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Index />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OHCUpcomingMain;
