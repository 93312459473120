import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import { documentUrlList, documentUrlListNew } from "../../../global/constants";
import PrintConsolidated from "../../employeeDetails/employeeLifeCycle/comps/printConsolidated";

const CustomHeading = ({ children }) => {
  return (
    <Typography
      sx={{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: 12,
        textTransform: "capitalize",
        color: "#8A8A8A",
      }}>
      {children}
    </Typography>
  );
};

const CustomParagraph = ({ children }) => {
  return (
    <Typography
      sx={{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: 12,
        textTransform: "capitalize",
        color: "#000000",
      }}>
      {children}
    </Typography>
  );
};

const VitalCompAHC = ({
  data = {},
  handleClickOpen,
  setSelectedImage,
  employeeID,
  ahcConfig,
  corpId = localStorage.getItem("CORPID"),
}) => {
  console.log({ data21231231: data, ahcConfig });
  return (
    <Fragment>
      <Box sx={{ py: 2 }}>
        <Fragment>
          <Grid container columns={4} spacing={1}>
            {/* *******Vitals ******** */}
            {data?.height && (
              <Grid item lg={1}>
                <Stack display="flex" direction="row">
                  <CustomHeading>Height: </CustomHeading>
                  <CustomParagraph> {data?.height}</CustomParagraph>
                </Stack>
              </Grid>
            )}
            {data?.weight && (
              <Grid item lg={1}>
                <Stack display="flex" alignItems="center" direction="row">
                  <CustomHeading>Weight: </CustomHeading>
                  <CustomParagraph> {data?.weight}</CustomParagraph>
                </Stack>
              </Grid>
            )}
            {data?.bp && (
              <Grid item lg={1}>
                <Stack display="flex" alignItems="center" direction="row">
                  <CustomHeading>BP: </CustomHeading>
                  <CustomParagraph> {data?.bp}</CustomParagraph>
                </Stack>
              </Grid>
            )}
            {data?.sugar && (
              <Grid item lg={1}>
                <Stack display="flex" alignItems="center" direction="row">
                  <CustomHeading>Sugar: </CustomHeading>
                  <CustomParagraph> {data?.sugar}</CustomParagraph>
                </Stack>
              </Grid>
            )}

            {data?.bmi && (
              <Grid item lg={1}>
                <Stack display="flex" alignItems="center" direction="row">
                  <CustomHeading>BMI: </CustomHeading>
                  <CustomParagraph> {data?.bmi}</CustomParagraph>
                </Stack>
              </Grid>
            )}

            {/* *******Flags ******** */}
            {data?.ohcType && (
              <Grid item lg={1}>
                <Stack display="flex" alignItems="center" direction="row">
                  <CustomHeading>OHC Type: </CustomHeading>
                  <CustomParagraph> {data?.ohcType}</CustomParagraph>
                </Stack>
              </Grid>
            )}

            {data?.fitToWork && (
              <Grid item lg={1}>
                <Stack display="flex" alignItems="center" direction="row">
                  <CustomHeading>Fit to Work: </CustomHeading>
                  <CustomParagraph> {data?.fitToWork ? "YES" : "NO"}</CustomParagraph>
                </Stack>
              </Grid>
            )}
            {data?.underMedication && (
              <Grid item lg={1}>
                <Stack display="flex" alignItems="center" direction="row">
                  <CustomHeading>Under Medication: </CustomHeading>
                  <CustomParagraph> {data?.underMedication ? "YES" : "NO"}</CustomParagraph>
                </Stack>
              </Grid>
            )}

            {data?.rest && (
              <Grid item lg={1}>
                <Stack display="flex" alignItems="center" direction="row">
                  <CustomHeading>Rest: </CustomHeading>
                  <CustomParagraph> {data?.rest}</CustomParagraph>
                </Stack>
              </Grid>
            )}

            {data?.injuryRemarks && (
              <Grid item lg={1}>
                <Stack display="flex" alignItems="center" direction="row">
                  <CustomHeading>Injury Remarks: </CustomHeading>
                  <CustomParagraph> {data?.injuryRemarks}</CustomParagraph>
                </Stack>
              </Grid>
            )}

            {data?.nextAppointmentDate && (
              <Grid item lg={1}>
                <Stack display="flex" alignItems="center" direction="row">
                  <CustomHeading>Next Appointment Date: </CustomHeading>
                  <CustomParagraph> {data?.nextAppointmentDate}</CustomParagraph>
                </Stack>
              </Grid>
            )}

            {/* *******Vision Start ******** */}

            <Grid item lg={4}>
              <Grid container columns={4}>
                {data?.farRightEyeSight && (
                  <Grid item lg={1}>
                    <Stack display="flex" alignItems="center" direction="row">
                      <CustomHeading>Eye Far Right: </CustomHeading>
                      <CustomParagraph> {data?.farRightEyeSight}</CustomParagraph>
                    </Stack>
                  </Grid>
                )}

                {data?.farLeftEyeSight && (
                  <Grid item lg={1}>
                    <Stack display="flex" alignItems="center" direction="row">
                      <CustomHeading>Eye Far Left: </CustomHeading>
                      <CustomParagraph> {data?.farLeftEyeSight}</CustomParagraph>
                    </Stack>
                  </Grid>
                )}

                {data?.nearRightEyeSight && (
                  <Grid item lg={1}>
                    <Stack display="flex" alignItems="center" direction="row">
                      <CustomHeading>Eye Near Right: </CustomHeading>
                      <CustomParagraph> {data?.nearRightEyeSight}</CustomParagraph>
                    </Stack>
                  </Grid>
                )}

                {data?.nearLeftEyeSight && (
                  <Grid item lg={1}>
                    <Stack display="flex" alignItems="center" direction="row">
                      <CustomHeading>Eye Near Left: </CustomHeading>
                      <CustomParagraph> {data?.nearLeftEyeSight}</CustomParagraph>
                    </Stack>
                  </Grid>
                )}
              </Grid>
            </Grid>

            {/* *******Vision End  ******** */}
          </Grid>
        </Fragment>
      </Box>

      <Box sx={{ py: 3 }}>
        <Grid container columnSpacing={3} rowSpacing={3}>
          {documentUrlListNew(data, ahcConfig, corpId)
            .filter((item) => item.display && item.url)
            .map((item, index) => (
              <Grid item lg={3} key={index}>
                {item.url === "allreports" ? (
                  <PrintConsolidated employeeID={employeeID} title="All Reports" />
                ) : (
                  <Button
                    fullWidth
                    size="small"
                    variant="contained"
                    sx={{ borderRadius: 2 }}
                    onClick={() => {
                      handleClickOpen(item.url);
                      setSelectedImage(item.url);
                    }}>
                    {item.title}
                  </Button>
                )}
              </Grid>
            ))}
        </Grid>
      </Box>
    </Fragment>
  );
};

export default VitalCompAHC;
