import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import maan from "../assets/maan.png";
import gufic from "../assets/gufic.png";
import tata from "../assets/tata.png";
import sayaji from "../assets/sayaji.png";
import mahindra from "../assets/mahindra.png";
import testimonialClients from "../assets/testimonialClients.png";
import casee from "../assets/case.png";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

const Testimonials = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [direction, setDirection] = useState("");

  const data = [
    {
      logo: "", //sayaji,
      review: `"The overall quality was wonderful and the digitisation you implemented is amazing. Your qualified staff ensured a smooth process throughout. We truly appreciated the hassle free experience. Thank you for enhancing our operations!"`,
      rating: 5,
      customerName: "", //"Vinay Patodiya",
      designation: "HR, Leading Hotel Chain", // "Assistant Manager HR, Sayaji Hotels",
    },
    {
      logo: "", // gufic,
      review: `"This year, we have brought UNO.care on board to organise the annual health camp in our factory. They have delivered high-quality medical check ups, an experience our workers have never had before."`,
      rating: 5,
      customerName: "", // "Anishka Darera",
      designation: "HR, Leading Pharmaceuticals Company", // "Head HR, Gufic Biosciences Private Limited",
    },
    {
      logo: "", // mahindra,
      review: `"UNO.care's professionalism and efficiency are unmatched. From quick test results to easy to understand health analytics through their digital portal, UNO.care has made managing the health of our employees a breeze. I am also impressed with their mobile application for our employees."`,
      rating: 5,
      customerName: "", // "Dr. Rishika Rajput",
      designation: "HR, Leading Automobile Company", //"Factory Medical Officer, Mahindra Two Wheelers Limited",
    },
    {
      logo: "", // tata,
      review:
        "They come equipped with portable machines and deliver reports in exceptional manner. The staff is highly qualified and their professionalism is truly remarkable.",
      rating: 5,
      customerName: "", // "Bharat Bhusan Singh",
      designation: "HR, Leading MNC", //"Head Health Safety and Fire Services, Tata International",
    },
  ];

  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setStartIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const CarouselCard = ({ data, index }) => {
    return (
      <Fragment>
        {data.map((item, idx) => (
          <Box
            key={idx}
            sx={{
              width: { xs: "90%", md: "31vw" },
              minHeight: 360,
              flexShrink: 0,
              marginBlock: 1,
              marginInline: 2,
              borderRadius: 5,
              border: "1px solid #8688F7",
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
              backgroundColor: "#FFF",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              padding: { xs: 2, md: 3 },
            }}>
            <Grid container sx={{ alignItems: "flex-start" }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  sx={{
                    color: "#292A71",
                    fontFamily: "Poppins",
                    fontSize: { xs: "16px", md: "18px" },
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "160%",
                  }}>
                  {item.review}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              columnSpacing={2}
              sx={{
                alignItems: "center",
                marginTop: 2,
              }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    component={"img"}
                    src={item.logo}
                    style={{ width: 64, alignContent: "center" }}
                  />
                  <Box sx={{ marginLeft: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}>
                      <Typography
                        sx={{
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "600",
                          lineHeight: "20.8px",
                          marginBlock: 1,
                        }}>
                        {item.customerName}
                        <br />
                      </Typography>
                      <Box>{renderStars(item.rating)}</Box>
                    </Box>
                    <Typography
                      sx={{
                        color: "#000",
                        fontSize: "12px",
                        fontWeight: "600",
                        lineHeight: "20.8px",
                      }}>
                      {item.designation}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Fragment>
    );
  };

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;

    return (
      <Box sx={{ display: "flex" }}>
        {[...Array(fullStars)].map((_, index) => (
          <Box
            key={index}
            sx={{
              width: "20px",
              height: "20px",
              backgroundColor: "#FFD700",
              clipPath:
                "polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)",
              marginRight: "4px",
            }}
          />
        ))}
        {halfStar && (
          <Box
            sx={{
              width: "20px",
              height: "20px",
              backgroundColor: "#FFD700",
              clipPath:
                "polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)",
              marginRight: "4px",
              position: "relative",
              overflow: "hidden",
            }}>
            <Box
              sx={{
                width: "10px",
                height: "20px",
                backgroundColor: "white",
                position: "absolute",
                top: 0,
                left: "50%",
                transform: "translateX(-50%)",
              }}
            />
          </Box>
        )}
      </Box>
    );
  };

  const handleNext = () => {
    setStartIndex((prevIndex) => (prevIndex + 1) % data.length);
    setDirection("left");
  };

  const handlePrev = () => {
    setStartIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length);
    setDirection("right");
  };

  return (
    <Fragment>
      <Box
        sx={{
          background:
            "linear-gradient(to bottom, rgba(52, 63, 208, 1) 30%, rgba(98, 109, 255, 1) 100%)",
          paddingBlock: isSmallScreen || isExtraSmallScreen ? "20px" : "35px",
          paddingLeft: isSmallScreen || isExtraSmallScreen ? 0 : "128px",
          paddingRight: isSmallScreen || isExtraSmallScreen ? 0 : "128px",
        }}>
        <Container maxWidth={false}>
          <Grid container spacing={4} display="flex" justifyContent="center">
            <Grid item xs={12} sm={12} md={12} lg={12} display="flex" justifyContent="center">
              <img src={testimonialClients} style={{ height: 30 }} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: isSmallScreen || isExtraSmallScreen ? "24px" : "30px",
                  fontWeight: "500",
                  lineHeight: "37.5px",
                  color: "#FFF",
                }}>
                Discover what our satisfied customers have to say about their <br />
                experiences with our products/services.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              display="flex"
              justifyContent="center"
              alignItems="center">
              <IconButton
                onClick={handlePrev}
                sx={{
                  display: { xs: "none", md: "block", lg: "block" },
                  position: "absolute",
                  left: "4px",
                  zIndex: 1,
                  backgroundColor: "#0045B2",
                  height: 40,
                  width: 40,
                  "&:hover": {
                    backgroundColor: "#0045B2",
                  },
                }}>
                <ArrowBack sx={{ color: "#FFF" }} />
              </IconButton>
              <Box sx={{ display: { xs: "none", lg: "flex" } }}>
                <CarouselCard
                  data={[
                    data[startIndex],
                    data[(startIndex + 1) % data.length],
                    data[(startIndex + 2) % data.length],
                  ]}
                />
              </Box>
              <Box sx={{ display: { xs: "flex", lg: "none" } }}>
                <CarouselCard data={[data[startIndex]]} />
              </Box>
              <IconButton
                onClick={handleNext}
                sx={{
                  display: { xs: "none", md: "block" },
                  position: "absolute",
                  right: "4px",
                  height: 40,
                  width: 40,
                  zIndex: 1,
                  backgroundColor: "#0045B2",
                  "&:hover": {
                    backgroundColor: "#0045B2",
                  },
                }}>
                <ArrowForward sx={{ color: "#FFF" }} />
              </IconButton>
            </Grid>
            {/* <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <Button
                sx={{
                  border: "1px solid #FFF",
                  color: "#FFF",
                  backgroundColor: "#626DFF",
                  width: "150px",
                  ":hover": {
                    backgroundColor: "#626DFF",
                    border: "1px solid #FFF",
                  },
                }}
              >
                Know More
              </Button>
            </Grid> */}
          </Grid>
        </Container>
      </Box>
    </Fragment>
  );
};

export default Testimonials;
