import { Box } from "@mui/material";
import CurrentPrescription from "./currentPrescription";

const MedicationIndex = (props) => {
  return (
    <Box
      sx={{
        background: "#fff",
        borderRadius: 1,
      }}
    >
      <CurrentPrescription
        patientDetails={props.patientDetails}
        appointmentId={props.appointmentId}
        caseId={props.caseId}
        patientId={props.patientId}
      />
    </Box>
  );
};

export default MedicationIndex;
