import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Portal,
  Stack,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import { getFormattedDDMonthYYYY } from "../../../../../assets/utils";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CustomPDFViewer from "../../../../../assets/customPDFViewer";

const TableData = ({ form21Data }) => {
  const [selectedPDF, setSelectedPDF] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Fragment>
      <Portal>
        <Dialog fullWidth={true} maxWidth={false} open={open} onClose={handleClose}>
          <DialogTitle>Optional sizes</DialogTitle>
          <DialogContent>
            <Box>
              <CustomPDFViewer pdfUrl={selectedPDF} />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Portal>

      <Box sx={{ mt: 1 }}>
        <Grid container rowSpacing={2} columnSpacing={1}>
          {form21Data?.map((value, index) => (
            <Fragment key={index}>
              <Grid item lg={2}>
                <Stack
                  direction="column"
                  spacing={0}
                  display="flex"
                  justifyContent="center"
                  alignItems="center">
                  <IconButton
                    onClick={() => {
                      setSelectedPDF(value?.reportURL);
                      console.log(value?.reportURL);
                      setOpen(true);
                    }}>
                    <PictureAsPdfIcon color="primary" sx={{ fontSize: 50 }} />
                  </IconButton>
                  <Typography>{getFormattedDDMonthYYYY(value?.date)}</Typography>
                </Stack>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </Box>
    </Fragment>
  );
};

export default TableData;
