import { Box, Grid } from "@mui/material";
import PatientInfoBar from "../components/patientInfoBar";
import VideoSection from "./videoSection";

const VideoIndex = ({ patient, toggleCaller = true, patientDetails }) => {
  return (
    <Box>
      <Grid container rowSpacing={1}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <PatientInfoBar patient={patient} />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <VideoSection
            toggleCaller={toggleCaller}
            patient={patient}
            patientDetails={patientDetails}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default VideoIndex;
