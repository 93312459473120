import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Snackbar,
  Stack,
  Tab,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useState, useEffect, useCallback, forwardRef } from "react";
import SlotForm from "./slotForm";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ActiveSlots from "./activeSlots";
import InActiveSlots from "./inActiveSlots";
import SlotFormView from "./slotFormView";
import { getSlotListByDocId } from "../services/api/apiCalls";
import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SlotMainComponent = (props) => {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [state, setState] = useState();
  const [slotList, setSlotList] = useState([]);
  const [docId, setDocId] = useState("");
  const [slotValues, setSlotValues] = useState({
    id: null,
    slotName: "",
    repeatForWeeks: 1,
    startTimeHour: "07",
    startTimeMinute: "30",
    startTimeAMPM: "AM",
    endTimeHour: "08",
    endTimeMinute: "30",
    endTimeAMPM: "AM",
    activeWeekDays: [],
    isActive: false,
  });
  const [value, setValue] = useState("1");
  const [isEdit, setIsEdit] = useState(false);

  const [formContent, setFormContent] = useState(
    <SlotForm onAddSlotForm={addSlotHandler} slotValues={slotValues} />
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateSlots = (event) => {
    event.preventDefault();
    setFormContent(
      <SlotForm onAddSlotForm={addSlotHandler} slotValues={slotValues} />
    );
    setIsEdit(false);
  };

  async function addSlotHandler(values, i) {
    setSlotValues(values);

    const newValue = {
      isActive: values.isActive,
      daysOfWeek: values.activeWeekDays,
      endTime:
        values.endTimeHour +
        ":" +
        values.endTimeMinute +
        " " +
        values.endTimeAMPM,
      noOfWeeks: values.repeatForWeeks,
      slotName: values.slotName,
      startTime:
        values.startTimeHour +
        ":" +
        values.startTimeMinute +
        " " +
        values.startTimeAMPM,
    };
    if (i.error) {
      setSeverity("error");
      setMessage("Slot save failed!");
      setOpen(true);
    } else {
      setSeverity("success");
      setMessage("Slot saved successfully!");
      setOpen(true);
    }
    console.log({ bbbvalues: i });

    setFormContent(<SlotFormView slotData={newValue} />);
    setIsEdit(true);
    fetchSlotsHandler();
  }

  const createSlot = (event) => {
    event.preventDefault();

    setFormContent(
      <SlotForm
        slotValues={{
          id: null,
          slotName: "",
          repeatForWeeks: 1,
          startTimeHour: "07",
          startTimeMinute: "30",
          startTimeAMPM: "AM",
          endTimeHour: "08",
          endTimeMinute: "30",
          endTimeAMPM: "AM",
          activeWeekDays: [],
          isActive: false,
        }}
        onAddSlotForm={addSlotHandler}
      />
    );
    setIsEdit(false);
  };

  async function myViewOnClickHandler(v) {
    //console.log(v);

    const myArray1 = v.startTime.split(":");
    const myArray11 = myArray1[1].split(" ");

    const myArray2 = v.endTime.split(":");
    const myArray22 = myArray2[1].split(" ");

    const newV = {
      activeWeekDays: v.daysOfWeek,
      startTimeHour: myArray1[0],
      startTimeMinute: myArray11[0],
      startTimeAMPM: myArray11[1],
      isActive: v.isActive,
      repeatForWeeks: v.noOfWeeks,
      slotName: v.slotName,
      endTimeHour: myArray2[0],
      endTimeMinute: myArray22[0],
      endTimeAMPM: myArray22[1],
      id: v.slotId,
    };
    setSlotValues(newV);

    setFormContent(<SlotFormView key={v.slotId} slotData={v} />);

    setIsEdit(true);
  }

  const fetchSlotsHandler = useCallback(async () => {
    try {
      const slotsData = await getSlotListByDocId(localStorage.getItem("DOCID"));

      if (slotsData.error) {
        setState({ error: slotsData.error });
      } else {
        setState({ data: slotsData.data });
        const mdata = await slotsData.data;
        const sList = await mdata.slots;
        setSlotList(sList);
        setDocId(await mdata.docId);
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    fetchSlotsHandler();
  }, [isEdit]);

  const [activeSlotContent, setActiveSlotContent] = useState();
  const [inActiveSlotContent, setInActiveSlotContent] = useState();

  useEffect(() => {
    setActiveSlotContent(
      <ActiveSlots
        key={slotList.length}
        addMyHandler={myViewOnClickHandler}
        slotList={slotList}
        docId={docId}
      />
    );
    setInActiveSlotContent(
      <InActiveSlots
        key={slotList.length}
        addMyHandler={myViewOnClickHandler}
        slotList={slotList}
        docId={docId}
      />
    );
  }, [slotList]);

  return (
    <Box
      sx={{
        position: "fixed",
        width: "100vw",
        height: "89vh",
        zIndex: -5,
      }}
    >
      <Container component="main" maxWidth={false}>
        <Box
          sx={{
            position: "fixed",
            height: "80vh",
            width: "97vw",
            mt: 3,
            filter: "drop-shadow(1px 1px 7px rgba(32, 143, 148, 0.95))",
          }}
        >
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity={severity}
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>

          <Grid container spacing={0}>
            <Grid item lg={12}>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      height: "8vh",
                      width: "100%",
                    }}
                  >
                    <Grid container spacing={0}>
                      <Grid item lg={8}>
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                        >
                          <Tab
                            label="Active Slots"
                            value="1"
                            sx={{
                              width: 206,
                              height: 51,
                              borderTopLeftRadius: 10,
                              borderTopRightRadius: 10,
                              backgroundColor: "#9AE7EB",
                            }}
                          />
                          <Tab
                            label="Inactive Slots"
                            value="2"
                            sx={{
                              width: 207,
                              height: 51,
                              borderTopLeftRadius: 10,
                              borderTopRightRadius: 10,
                              ml: 2,
                              backgroundColor: "#9AE7EB",
                            }}
                          />
                        </TabList>
                      </Grid>
                      <Grid item lg={4}>
                        <Stack
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Button
                            startIcon={<AddIcon />}
                            variant="contained"
                            onClick={createSlot}
                          >
                            Create New Slot
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      height: "75vh",
                      background: "#fff",
                      boxShadow: "1px 1px 7px 3px rgba(32, 143, 148, 0.33)",
                      borderRadius: 2,
                    }}
                  >
                    <Container maxWidth={false} disableGutters>
                      <Box sx={{ height: "75vh", width: "100%", p: 1 }}>
                        <Grid container>
                          <Grid item lg={4}>
                            <Box
                              sx={{
                                background: "#9AE7EB",
                                height: "72.5vh",
                                width: "98%",
                                borderRadius: 2,
                              }}
                            >
                              <TabPanel value="1">{activeSlotContent}</TabPanel>
                              <TabPanel value="2">
                                {inActiveSlotContent}
                              </TabPanel>
                            </Box>
                          </Grid>
                          <Grid item lg={8}>
                            <Box
                              sx={{
                                background: "#9AE7EB",
                                height: "72.5vh",
                                borderRadius: 2,
                                p: 2,
                              }}
                            >
                              <Box sx={{ height: "58vh" }}>
                                <div id="mycomponentbox"></div>
                                {formContent}
                              </Box>
                              <Box
                                sx={{ height: "8vh", background: "#fff" }}
                                justifyContent="center"
                              >
                                <Divider color="#208F94" />
                                <Stack
                                  direction={"row-reverse"}
                                  alignItems="center"
                                  justifyContent={"center"}
                                  sx={{ height: "8vh" }}
                                >
                                  {isEdit ? (
                                    <Button
                                      variant="contained"
                                      size="small"
                                      onClick={updateSlots}
                                    >
                                      edit
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      size="small"
                                      type="submit"
                                      form="mySlotForm"
                                    >
                                      save
                                    </Button>
                                  )}
                                </Stack>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Container>
                  </Box>
                </TabContext>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default SlotMainComponent;
