import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";

const CustomButtonBlue = ({ type, title, icon, onClick, disabled, buttonWidth, marginBlock }) => {
  return (
    <Grid item xs={12} textAlign="center" marginBlock={marginBlock ? marginBlock : "30px"}>
      <Button
        disabled={disabled}
        onClick={onClick}
        variant="contained"
        style={{
          borderRadius: "15px",
          height: "40px",
          width: buttonWidth ? buttonWidth : "200px",
          textTransform: "none",
        }}>
        {icon ? (
          <Box
            component={"img"}
            src={icon}
            width={15}
            height={15}
            alt="Icon"
            style={{ marginInline: "10px" }}
          />
        ) : null}
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "13px",
            lineHeight: "15px",
            color: "#FFFFFF",
          }}>
          {title}
        </Typography>
      </Button>
    </Grid>
  );
};

export default CustomButtonBlue;
