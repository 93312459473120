import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, Divider, Grid, Paper, Stack, ThemeProvider, Typography } from "@mui/material";
import { useState } from "react";



const weekDays = ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"];

const SlotCardComponent = (props) => {

    const [selectedSlot, setSelectedSlot] = useState(0);

    const openSlot = (event) => {
        event.preventDefault();
        props.addMyHandler2(props.slot);
        setSelectedSlot(props.slot.slotId);
    } 

    console.log({"props.slot":selectedSlot})


    return (

<Grid container >
        <Grid item xs={12} sx={{height:140, }}>

        <Box sx={{height:140,boxShadow:'0px 1px 4px #208F94',}} >

            <Card onClick={openSlot} className={selectedSlot === props.slot.slotId ? 'myCustomClass' : "myCustomClass1"} sx={{height:140, boxShadow: "1px 0px 6px 1px #BEBEBE", }}>
           
                <CardHeader sx={{height:40, background:'#208F94', boxShadow:'0px 1px 4px #208F94',}} title="" subheader={props.slot.slotName.toUpperCase()} align="center">

                </CardHeader>
                
                <Box sx={{height:130, background:''}}>
                <CardContent sx={{height:120, background: '#FFFFFF', borderRadius:1}} >
                <Stack direction="column" spacing={1} >
                <Paper sx={{height:30, background: '#FFFFFF', border: '0.5px solid #000000', boxSizing: 'order-box'}} align="center" >
                <Stack direction={'row'} justifyContent="center">
                    <Typography variant="caption" sx={{mt:.6}}>
                    {props.slot.startTime} to {props.slot.endTime}
                    </Typography>
                    </Stack>
                </Paper>
                <Paper sx={{height:30, background: '#FFFFFF', border: '0.5px solid #000000', boxSizing: 'order-box'}} align="center">

                <Stack direction={'row'} justifyContent="center">
                    {(props.slot.daysOfWeek).map( (day) => (
                            <Typography key={day.trim()} variant="caption" sx={{mt:.6}}>
                        {weekDays[day.trim()]}&nbsp;</Typography>

                        ))}
                </Stack>
                    
                </Paper>
                
                </Stack>
                </CardContent>
                </Box>

            </Card>
            </Box>
        </Grid>
        </Grid>
    );
}
 
export default SlotCardComponent;