import { Fragment } from "react";
import CustomSelect from "../../../../global/components/customSelect";

const TestTypeList = [
  { label: "ONROLL", value: "ONROLL" },
  { label: "OFFROLL", value: "OFFROLL" },
  { label: "PRE EMPLOYMENT", value: "PRE_EMPLOYMENT" },
];

const TestTypeFilter = ({ testType, setTestType, label }) => {
  return (
    <Fragment>
      <CustomSelect value={testType} setvalue={setTestType} options={TestTypeList} label={label} />
    </Fragment>
  );
};

export default TestTypeFilter;
