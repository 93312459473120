import { BASE_URL } from "../../assets/constantsFile";
import { downloadCsv } from "../../assets/utils";
import { getData } from "../../services/api/getApi";

export const fetchMedicineListForMyOpd = async (
  corpId,
  setMedicineList,
  setIsLoading
) => {
  setIsLoading(true);
  const url = BASE_URL + `inventory/getProductWithBatch/${corpId}`;
  const result = await getData(url);
  if (result.error) {
    setIsLoading(false);
    setMedicineList([]);
  } else {
    setIsLoading(false);
    setMedicineList(result?.data?.detailsVM);
  }
};

export const fetchMedicineListInDashboard = async (
  corpId,
  setMedicineList,
  setIsLoading,
  setMedicineNameList,
  setBrandNameList
) => {
  setIsLoading(true);
  const url = BASE_URL + `inventory/getProducts/${corpId}`;
  const result = await getData(url);
  if (result.error) {
    setIsLoading(false);
    setMedicineList([]);
  } else {
    setIsLoading(false);
    setMedicineList(
      result?.data.map((item, index) => ({ id: index, ...item }))
    );

    const uniqueBrands = [...new Set(result.data.map((item) => item.brand))];
    setBrandNameList(uniqueBrands);

    const uniqueMedicineNames = [
      ...new Set(result.data.map((item) => item.productName)),
    ];
    setMedicineNameList(uniqueMedicineNames);
  }
};

export const fetchMedicineAsProductName = async (corpId, setMedicineList) => {
  const url = BASE_URL + `inventory/getProducts/${corpId}`;
  const result = await getData(url);
  if (result.error) {
    setMedicineList([]);
  } else {
    setMedicineList(result.data);
  }
};
export const handleFetchMasterData = async (setAllProducts) => {
  const url = BASE_URL + `inventory/getAllProducts`;
  const result = await getData(url);
  if (result.error) {
    setAllProducts([]);
  } else {
    setAllProducts(result.data.map((item, index) => ({ id: index, ...item })));
  }
};

export const handleDownloadMasterData = async () => {
  const url = BASE_URL + `inventory/getAllProducts`;
  const result = await getData(url);
  if (result.error) {
  } else {
    const temp = result.data.map(({ dosage, medicineUsage, ...rest }) => rest);
    downloadCsv(temp, "Master Data");
  }
};
