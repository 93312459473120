import { Box, Button, Grid, IconButton, Tab, Typography } from "@mui/material";
import { useState } from "react";
import TestWise from "./testWise";
import DownlaodReports from "./downlaodReports";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomTitles from "../../../global/titles/customTitles";
import SelectCamp from "../../../global/selectCamp/selectCamp";

const Analytics = () => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isDownLoadReports, setIsDownLoadReports] = useState(false);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item lg={2} md={2} sm={12} xs={12}>
          {isDownLoadReports && (
            <IconButton
              onClick={() => {
                setIsDownLoadReports(!isDownLoadReports);
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
        </Grid>

        <Grid item lg={8} md={8} sm={12} xs={12}></Grid>

        <Grid item lg={2} md={2} sm={12} xs={12}>
          {!isDownLoadReports && (
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                setIsDownLoadReports(!isDownLoadReports);
              }}
              sx={{
                height: 48,
                px: 3,
                borderTopLeftRadius: 20,
                borderBottomLeftRadius: 20,
                borderTopRightRadius: 20,
                borderBottomRightRadius: 20,
              }}
            >
              Download Reports
            </Button>
          )}
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          {isDownLoadReports ? <DownlaodReports /> : <TestWise />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Analytics;
