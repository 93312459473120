import React, { Fragment } from "react";
import RaiseTicketMain from "../modules/ticket/RaiseTicketMain";

function SaRaiseTicketIndex() {
  return (
    <Fragment>
      <RaiseTicketMain />
    </Fragment>
  );
}

export default SaRaiseTicketIndex;
