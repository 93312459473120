import { Box, Grid } from "@mui/material";
import DisplayImages from "./comps/displayImages";
import CustomTitles from "../../../global/titles/customTitles";

const CSRPhotoGalleryMain = () => {
  return (
    <Box>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="CSR Gallery" />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DisplayImages />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CSRPhotoGalleryMain;
