import { Divider, Grid, Stack, Typography } from "@mui/material";
import { Fragment } from "react";

const PatientMedicalHistory = (props) => {
  const patientMedicalHistoryInfo = props.patientMedicalHistoryInfo
    ? props.patientMedicalHistoryInfo
    : "";

  let preExistingConditions = "";
  let drugHistory = "";
  let drugAllergy = "";
  let habits = "";
  let occupation = "";
  let parentalHistory = "";

  if (patientMedicalHistoryInfo) {
    preExistingConditions = patientMedicalHistoryInfo.preExistingConditions;
    drugHistory = patientMedicalHistoryInfo.drugHistory;
    drugAllergy = patientMedicalHistoryInfo.drugAllergy;
    habits = patientMedicalHistoryInfo.habits;
    occupation = patientMedicalHistoryInfo.occupation;
    parentalHistory = patientMedicalHistoryInfo.parentalHistory;
  }

  return (
    <Fragment>
      <Grid container>
        {preExistingConditions ? (
          <Grid item lg={12} xs={12} md={12} sm={12}>
            <Typography sx={{ marginTop: "20px" , fontWeight: 700,lineHeight: "13px",color: "#404040",  }}>
              PRE-EXISTING CONDITIONS
            </Typography>
            <Typography sx={{ marginTop: "10px" , borderBottom: "1px solid #000000" }}>{preExistingConditions}</Typography>  
          </Grid>
        ) : (
          ""
        )}

        {drugHistory ? (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography sx={{ marginTop: "20px", fontWeight: 700,lineHeight: "13px",color: "#404040", }}>DRUG HISTORY</Typography>
            <Typography sx={{ marginTop: "10px" , borderBottom: "1px solid #000000" }}> {drugHistory} </Typography>
          </Grid>
        ) : (
          ""
        )}

        {drugAllergy ? (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography sx={{ marginTop: "20px" , fontWeight: 700,lineHeight: "13px",color: "#404040", }}>DRUG ALLERGY</Typography>
            <Typography sx={{ marginTop: "10px" , borderBottom: "1px solid #000000" }}> {drugAllergy} </Typography>
          </Grid>
        ) : (
          ""
        )}

        {habits ? (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography sx={{ marginTop: "20px", fontWeight: 700,lineHeight: "13px",color: "#404040",  }}>HABITS:&nbsp;</Typography>
            <Typography sx={{ marginTop: "10px" , borderBottom: "1px solid #000000" }}> {habits} </Typography>
          </Grid>
        ) : (
          ""
        )}

        {occupation ? (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography sx={{ marginTop: "20px" , fontWeight: 700,lineHeight: "13px",color: "#404040", }}>
              OCCUPATION:&nbsp;
            </Typography>
            <Typography sx={{ marginTop: "10px" , borderBottom: "1px solid #000000" }}> {occupation} </Typography>
          </Grid>
        ) : (
          ""
        )}

        {parentalHistory ? (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography sx={{ marginTop: "20px" , fontWeight: 700,lineHeight: "13px",color: "#404040", }}>
              PARENTAL HISTORY:&nbsp;
            </Typography>
            <Typography sx={{ marginTop: "10px" , borderBottom: "1px solid #000000" }}> {parentalHistory} </Typography>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </Fragment>
  );
};

export default PatientMedicalHistory;
