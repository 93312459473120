import { Box, Grid, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import DashboardHeader from "../components/dashboardHeader";
import DashboardItem from "../components/dashboardItem";
import UseInterval from "../../../common/hooks/useInterval";
import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApi";
import { getDelay } from "../../../assets/utils";

const LiveDashboard = ({ dateValue, setDateValue }) => {
  const [patientList, setPatientList] = useState([]);

  const fetchPataientsHandler = async (docId, date, token) => {
    const patients = await getData(
      BASE_URL + "doctor/appointment/live/" + docId + "?date=" + date,
      token
    );

    if (patients.error) {
    } else {
      const data = patients.data;
      const transformedPatients = data.map((patientData, index) => {
        return {
          index: index,
          advice: patientData.advice,
          docId: patientData.docId,
          clinicId: patientData.clinicId,
          patientId: patientData.patientId,
          noOfVisits: patientData.noOfVisits,
          lastVisit: patientData.lastVisit,
          reasonOfVisit: "N/A",
          newTime: new Date(),
          canceledBy: "N/A",

          patient: {
            patientId: patientData.patientId,
            name: patientData.patientName,
            gender: patientData.gender,
            age:
              (patientData.patientInfoVM.age
                ? patientData.patientInfoVM.age + "Y "
                : "") +
              (patientData.patientInfoVM.ageMonths
                ? patientData.patientInfoVM.ageMonths + "M"
                : ""),
          },
          name: patientData.patientName,
          gender: patientData.gender,
          age:
            (patientData.patientInfoVM.age
              ? patientData.patientInfoVM.age + "Y "
              : "") +
            (patientData.patientInfoVM.ageMonths
              ? patientData.patientInfoVM.ageMonths + "M"
              : ""),
          attended: patientData.attended, /////to be added
          issue: patientData.issue, /////to be added

          paymentRequired: patientData.paymentRequired,
          symptom: patientData.symptom,
          startTime: patientData.startTime,
          appointmentDelay: getDelay(patientData.startTime),
          endTime: patientData.endTime,
          date: patientData.date,
          appointmentId: patientData.appointmentId,
          observation: patientData.observation,
          slotNo: patientData.slotNo,
          consultationStartTime: patientData.consultationStartTime,
          consultationEndTime: patientData.consultationEndTime,
          consultationType: patientData.consultationType,
          caseId: patientData.caseId,
          doctorName: patientData.doctorName,
          doctorSpeciality: patientData.doctorSpeciality,
          amountPayable: patientData.amountPayable,
          amountCollected: patientData.amountCollected,
          modeOfPayment: patientData.modeOfPayment,
          patientName: patientData.patientName,
          firstConsultationDate: patientData.firstConsultationDate,
          lastConsultationDate: patientData.lastConsultationDate,

          firstName: patientData.firstName,
          middleName: patientData.middleName,
          lastName: patientData.lastName,
          fatherName: patientData.fatherName,
          dateOfBirth: patientData.dateOfBirth,

          email: patientData.email,
          mobile: patientData.mobile,
          countryCode: patientData.countryCode,

          guardianFirstName: patientData.guardianFirstName,
          guardianLastName: patientData.guardianLastName,
          guardianContactNumber: patientData.guardianContactNumber,
          source: patientData.source,

          videoURL: patientData.videoURL,
          vitals: patientData.vitals,
          prescriptionVM: patientData.prescriptionVM,
          reports: patientData.reports,
          paymentVM: patientData.paymentVM,
          personalVisitNeeded: patientData.personalVisitNeeded,
          nextAppointmentDate: patientData.nextAppointmentDate,

          patientDeatils: {
            advice: patientData.advice,
            personalVisitNeeded: patientData.personalVisitNeeded,
            nextAppointmentDate: patientData.nextAppointmentDate,
            patientData: patientData,
            appointmentId: patientData.appointmentId,
            patientId: patientData.patientId,
            caseId: patientData.caseId,
            docId: patientData.docId,
            videoURL: patientData.videoURL,
            name: patientData.patientName,
            guestURLToken: patientData.guestURLToken,
            hostURLToken: patientData.hostURLToken,
            doctorName: patientData.doctorFullName,

            patient: {
              patientId: patientData.patientId,
              name: patientData.patientName,
              gender: patientData.gender,
              age:
                (patientData.patientInfoVM.age
                  ? patientData.patientInfoVM.age + "Y "
                  : "") +
                (patientData.patientInfoVM.ageMonths
                  ? patientData.patientInfoVM.ageMonths + "M"
                  : ""),
              issue: patientData.issue,
            },
            vitals: patientData.vitals,
            reports: patientData.reports,
            prescriptionVM: patientData.prescriptionVM,
            observation: patientData.observation ? patientData.observation : "",
            issue: patientData.issue,
            patientMedicalHistory: {
              patientName: patientData.patientName,
              habits: patientData.habits,
              preExistingConditions: patientData.preExistingConditions,
              drugHistory: patientData.drugHistory,
              drugAllergy: patientData.drugAllergy,
              parentalHistory: patientData.parentalHistory,
              occupation: patientData.occupation,
            },
          },
          patientDeatilsV2: {
            advice: patientData.advice,
            personalVisitNeeded: patientData.personalVisitNeeded,
            nextAppointmentDate: patientData.nextAppointmentDate,
            patientData: patientData,
            appointmentId: patientData.appointmentId,
            patientId: patientData.patientId,
            caseId: patientData.caseId,
            docId: patientData.docId,
            videoURL: patientData.videoURL,
            name: patientData.patientName,
            guestURLToken: patientData.guestURLToken,
            hostURLToken: patientData.hostURLToken,
            doctorName: patientData.doctorFullName,

            patient: {
              patientId: patientData.patientId,
              name: patientData.patientName,
              gender: patientData.gender,
              age:
                (patientData.patientInfoVM.age
                  ? patientData.patientInfoVM.age + "Y "
                  : "") +
                (patientData.patientInfoVM.ageMonths
                  ? patientData.patientInfoVM.ageMonths + "M"
                  : ""),
              issue: patientData.issue,
            },
            vitals: patientData.vitals,
            reports: patientData.reports,
            prescriptionVM: patientData.prescriptionVM,
            observation: patientData.observation ? patientData.observation : "",
            issue: patientData.issue,
            patientMedicalHistory: {
              patientName: patientData.patientName,
              habits: patientData.habits,
              preExistingConditions: patientData.preExistingConditions,
              drugHistory: patientData.drugHistory,
              drugAllergy: patientData.drugAllergy,
              parentalHistory: patientData.parentalHistory,
              occupation: patientData.occupation,
            },
          },

          printPrescription: patientData,
        };
      });
      setPatientList(transformedPatients);
      console.log("1111");
    }
  };

  useEffect(() => {
    fetchPataientsHandler(
      localStorage.getItem("DOCID"),
      dateValue.toISOString().split("T")[0],
      ""
    );

    console.log("refresh on tab click");
  }, [dateValue]);

  UseInterval(() => {
    fetchPataientsHandler(
      localStorage.getItem("DOCID"),
      dateValue.toISOString().split("T")[0],
      ""
    );
  }, 30000);
  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <DashboardHeader />
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        {patientList && patientList.length > 0 ? (
          <Box sx={{ height: "68vh", overflow: "auto", px: 1 }}>
            <Grid container>
              {patientList.map((d, i) => (
                <Grid item lg={12} md={12} sm={12} xs={12} key={i}>
                  <DashboardItem data={d} />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <Grid container sx={{ height: "68vh" }}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography>No patient here.</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default LiveDashboard;
