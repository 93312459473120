import { Route } from "react-router-dom";
import PrivateRouter from "../../common/privateRouter/privateRouter";
import CorporateHomeIndex from "../pages/corporateHomeIndex";
import AHCStatusTrackerIndex from "../pages/ahc/ahcStatusTrackerIndex";
import AHCHealthRecordIndex from "../pages/ahc/ahcHealthRecordIndex";
import AHCGalleryIndex from "../pages/ahc/ahcGalleryIndex";
import AHCRecommendedTestsIndex from "../pages/ahc/ahcRecommendedTestsIndex";
import PreEmpStatusTrackerIndex from "../pages/preEmployment/preEmpStatusTrackerIndex";
import PreEmpHealthRecordIndex from "../pages/preEmployment/preEmpHealthRecordIndex";
import EDAnalyticsIndex from "../pages/employeeDetails/edAnalyticsIndex";
import EDComplianceRecordsIndex from "../pages/employeeDetails/edComplianceRecordsIndex";
import EDEmployeeLifecycleIndex from "../pages/employeeDetails/edEmployeeLifecycleIndex";
import EDEnrollNewIndex from "../pages/employeeDetails/edEnrollNewIndex";
import OHCStatusTrackerIndex from "../pages/ohc/ohcStatusTrackerIndex";
import OHCHealthRecordsIndex from "../pages/ohc/ohcHealthRecordsIndex";
import OHCMyOPDIndex from "../pages/ohc/ohcMyOPDIndex";
import OHCUpcomingIndex from "../pages/ohc/ohcUpcomingIndex";
import OHCInjuriesIndex from "../pages/ohc/ohcInjuriesIndex";
import NursesIndex from "../pages/staffing/nursesIndex";
import DoctorsIndex from "../pages/staffing/doctorsIndex";
import ConsumablesIndex from "../pages/pharmacy/consumablesIndex";
import EquipmentsIndex from "../pages/pharmacy/equipmentsIndex";
import MedicinesIndex from "../pages/pharmacy/medicinesIndex";
import GroupInsuranceIndex from "../pages/insurance/groupInsuranceIndex";
import EmergencyHandlingIndex from "../pages/controllRoom/emergencyHandlingIndex";
import TicketsIndex from "../pages/tickets/ticketsIndex";
import AHCForm21Index from "../pages/ahc/ahcForm21Index";
import EmployeeProfileIndex from "../pages/employeeprofile/employeeProfileIndex";
import DefaultIndex from "../pages/defaultIndex";
import OrgLogin from "../pages/orgLogin";
import CorpHome from "../modules/home/corpHome";
import TicketsAdminServiceIndex from "../pages/tickets/ticketsAdminServiceIndex";
import TicketsHealthServiceIndex from "../pages/tickets/ticketsHealthServiceIndex";
import CSRStatusTrackerIndex from "../pages/csr/csrStatusTrackerIndex";
import CSRPhotoGalleryIndex from "../pages/csr/csrPhotoGalleryIndex";
import CSRHealthRecordsIndex from "../pages/csr/csrHealthRecordsIndex";
import FAPhotoGalleryIndex from "../pages/firstAid/faPhotoGalleryIndex";
import FAHealthChampionsIndex from "../pages/firstAid/faHealthChampionsIndex";
import TicketViewIndex from "../pages/tickets/ticketViewIndex";
import BookSessionIndex from "../pages/healthAwarenessSessions/bookSessionIndex";
import SessionCompletedIndex from "../pages/healthAwarenessSessions/sessionCompletedIndex";
import OhcRecordsIndex from "../pages/ohc/ohcRecordsIndex";
import AnalyticsIndex from "../pages/pharmacy/analyticsIndex";
import InvoicesIndex from "../pages/tickets/InvoicesIndex";
import NewRequestFormIndex from "../pages/preEmployment/newRequestFormIndex";

export const CorporateRoutes = (
  <Route
    path="corporatehome"
    element={<PrivateRouter Page={CorporateHomeIndex} Default={OrgLogin} />}>
    <Route index element={<PrivateRouter Page={CorpHome} Default={OrgLogin} />} />

    {/*********** routes for ahc  ***************/}

    <Route
      path="ahc/statustracker"
      element={<PrivateRouter Page={AHCStatusTrackerIndex} Default={OrgLogin} />}
    />

    <Route
      path="ahc/healthrecords"
      element={<PrivateRouter Page={AHCHealthRecordIndex} Default={OrgLogin} />}
    />

    <Route
      path="ahc/recommendedtests"
      element={<PrivateRouter Page={AHCRecommendedTestsIndex} Default={OrgLogin} />}
    />

    <Route
      path="ahc/gallery"
      element={<PrivateRouter Page={AHCGalleryIndex} Default={OrgLogin} />}
    />

    <Route path="ahc/form21" element={<PrivateRouter Page={AHCForm21Index} Default={OrgLogin} />} />
    <Route path="ahc/form24" element={<PrivateRouter Page={AHCForm21Index} Default={OrgLogin} />} />
    {/* pre employment  */}
    <Route
      path="preemployment/statustracker"
      element={<PrivateRouter Page={PreEmpStatusTrackerIndex} Default={OrgLogin} />}
    />
    <Route
      path="preemployment/healthrecords"
      element={<PrivateRouter Page={PreEmpHealthRecordIndex} Default={OrgLogin} />}
    />
    <Route
      path="preemployment/newrequestform"
      element={<PrivateRouter Page={NewRequestFormIndex} Default={OrgLogin} />}
    />
    {/* ohc  */}
    {/* 
    <Route
      path="ohc/statustracker"
      element={
        <PrivateRouter Page={OHCStatusTrackerIndex} Default={OrgLogin} />
      } 
    />*/}
    <Route
      path="ohc/analytics"
      element={<PrivateRouter Page={OHCStatusTrackerIndex} Default={OrgLogin} />}
    />
    <Route
      path="ohc/ohcrecords"
      element={<PrivateRouter Page={OhcRecordsIndex} Default={OrgLogin} />}
    />

    {/* <Route
      path="ohc/healthrecords"
      element={
        <PrivateRouter Page={OHCHealthRecordsIndex} Default={OrgLogin} />
      }
    /> */}

    <Route path="ohc/myopd" element={<PrivateRouter Page={OHCMyOPDIndex} Default={OrgLogin} />} />

    <Route
      path="ohc/upcoming"
      element={<PrivateRouter Page={OHCUpcomingIndex} Default={OrgLogin} />}
    />

    <Route
      path="ohc/injuries"
      element={<PrivateRouter Page={OHCInjuriesIndex} Default={OrgLogin} />}
    />
    {/* employeedetails  */}

    <Route
      path="employeedetails/compliancerecords"
      element={<PrivateRouter Page={EDComplianceRecordsIndex} Default={OrgLogin} />}
    />
    <Route
      path="employeedetails/employeelifecycle"
      element={<PrivateRouter Page={EDEmployeeLifecycleIndex} Default={OrgLogin} />}
    />
    <Route
      path="employeedetails/enrollnew"
      element={<PrivateRouter Page={EDEnrollNewIndex} Default={OrgLogin} />}
    />

    <Route
      path="employeedetails/form21"
      element={<PrivateRouter Page={AHCForm21Index} Default={OrgLogin} />}
    />
    <Route
      path="employeedetails/form24"
      element={<PrivateRouter Page={AHCForm21Index} Default={OrgLogin} />}
    />
    {/* EDAnalyticsIndex */}
    <Route
      path="employeedetails/analytics"
      element={<PrivateRouter Page={EDAnalyticsIndex} Default={OrgLogin} />}
    />
    {/* staffing  */}

    <Route
      path="staffing/doctors"
      element={<PrivateRouter Page={DoctorsIndex} Default={OrgLogin} />}
    />

    <Route
      path="staffing/nurses"
      element={<PrivateRouter Page={NursesIndex} Default={OrgLogin} />}
    />

    {/* pharmacy  */}

    <Route
      path="pharmacy/analytics"
      element={<PrivateRouter Page={AnalyticsIndex} Default={OrgLogin} />}
    />

    <Route
      path="pharmacy/inventory"
      element={<PrivateRouter Page={MedicinesIndex} Default={OrgLogin} />}
    />

    <Route
      path="pharmacy/equipments"
      element={<PrivateRouter Page={EquipmentsIndex} Default={OrgLogin} />}
    />

    <Route
      path="pharmacy/consumables"
      element={<PrivateRouter Page={ConsumablesIndex} Default={OrgLogin} />}
    />

    {/* Insurance  */}

    <Route
      path="insurance/groupinsurance"
      element={<PrivateRouter Page={GroupInsuranceIndex} Default={OrgLogin} />}
    />

    {/* Cotroll Room  */}

    <Route
      path="controllroom/emergencyhandling"
      element={<PrivateRouter Page={EmergencyHandlingIndex} Default={OrgLogin} />}
    />

    {/* First Aid Start  */}

    <Route
      path="firstaid/healthchampions"
      element={<PrivateRouter Page={FAHealthChampionsIndex} Default={OrgLogin} />}
    />
    <Route
      path="firstaid/photogallery"
      element={<PrivateRouter Page={FAPhotoGalleryIndex} Default={OrgLogin} />}
    />

    {/* First Aid End  */}
    {/* CSR  */}

    <Route
      path="csr/statustracker"
      element={<PrivateRouter Page={CSRStatusTrackerIndex} Default={OrgLogin} />}
    />
    <Route
      path="csr/healthrecords"
      element={<PrivateRouter Page={CSRHealthRecordsIndex} Default={OrgLogin} />}
    />
    <Route
      path="csr/photogallery"
      element={<PrivateRouter Page={CSRPhotoGalleryIndex} Default={OrgLogin} />}
    />

    <Route
      path="healthawarenesssessions/completedsessions"
      element={<PrivateRouter Page={SessionCompletedIndex} Default={OrgLogin} />}
    />
    <Route
      path="healthawarenesssessions/booksessions"
      element={<PrivateRouter Page={BookSessionIndex} Default={OrgLogin} />}
    />

    {/* Tickets  */}

    <Route
      path="tickets/dashboard"
      element={<PrivateRouter Page={TicketsIndex} Default={OrgLogin} />}
    />

    <Route
      path="tickets/invoices"
      element={<PrivateRouter Page={InvoicesIndex} Default={OrgLogin} />}
    />

    <Route path="tickets/ticketview/:ticketId" element={<TicketViewIndex />} />

    <Route
      path="tickets/adminservice"
      element={<PrivateRouter Page={TicketsAdminServiceIndex} Default={OrgLogin} />}
    />
    <Route
      path="tickets/healthservice"
      element={<PrivateRouter Page={TicketsHealthServiceIndex} Default={OrgLogin} />}
    />

    {/* Tickets  */}

    <Route path="noaccess" element={<PrivateRouter Page={DefaultIndex} Default={OrgLogin} />} />

    {/* Profile  */}
    <Route
      path="employeeprofile/:employeeID"
      element={<PrivateRouter Page={EmployeeProfileIndex} Default={OrgLogin} />}
    />
  </Route>
);
