import { Box, Button, CircularProgress } from "@mui/material";
import { Fragment, useState } from "react";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { saveData } from "../../../../../services/api/postApi";
import { useSnackbar } from "notistack";

const PrintConsolidated = ({
  corpId = localStorage.getItem("CORPID"),
  employeeID,
  title = "print!",
}) => {
  const [isLoadng, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const _handleSubmit = async () => {
    const url = BASE_URL + `org/print/tests`;
    setIsLoading(true);
    const payload = {
      corpId: corpId,
      empId: employeeID,
    };

    const printData = await saveData(url, payload, "");

    if (printData.error) {
      enqueueSnackbar(`No tests found for this employee.`, {
        variant: "error",
      });
      console.log({ error: printData.error });
      setIsLoading(false);
    } else {
      // enqueueSnackbar("Success.", {
      //     variant: "success",
      //   });
      console.log({ success: printData.data });
      setIsLoading(false);
      window.open(printData.data, "_blank", "popup,width=1300,height=700,top=200,left=340");
    }
  };
  return (
    <Fragment>
      <Button
        disabled={employeeID ? false : true}
        variant="contained"
        size="small"
        onClick={_handleSubmit}
        sx={{ borderRadius: 2 }}>
        {title}
        {isLoadng && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
            }}>
            <CircularProgress size={30} sx={{ color: "red" }} />
          </Box>
        )}
      </Button>
    </Fragment>
  );
};

export default PrintConsolidated;
