import React, { Fragment, useContext, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import DoneIcon from "@mui/icons-material/Done";
import RadioButtonUncheckedSharpIcon from "@mui/icons-material/RadioButtonUncheckedSharp";
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CustomButtonBlue from "./customButton/CustomButtonBlue";
import { BASE_URL } from "../../../../../../../../../assets/constantsFile";
import { getData } from "../../../../../../../../../services/api/getApi";
import { saveData } from "../../../../../../../../../services/api/postApi";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const TestAutoComplete = ({ patients }) => {
  const appointmentId = patients?.appointmentId || null;
  const caseId = patients?.caseId || null;
  const patientId = patients?.patientInfoVMV2?.patientId || null;
  const docId = patients?.doctorVM?.docId || null;

  console.log({ patientId, appointmentId, caseId, docId });

  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [openNotice, setOpenNotice] = useState(false);

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotice(false);
  };

  const [reportItems, setReportItems] = useState();
  const [tests, setTests] = useState(reportItems ? reportItems : []);

  const getPDetails = async () => {
    if (patientId && appointmentId && caseId) {
      const url =
        BASE_URL +
        "patient/detailed/" +
        patientId +
        "?appointmentId=" +
        appointmentId +
        "&caseId=" +
        caseId;

      const data = await getData(url);
      if (data && data.data) {
        console.log("SUCCESS GET", data.data);
        const data1 = data?.data?.reports;

        const filterData = data1.filter(
          (Object) => Object.broughtByPatient !== true
        );
        setReportItems(filterData);
        setTests(filterData);
      } else if (data && data.error) {
        console.log("SUCCESS GET", data.error);
      }
    }
  };

  useEffect(() => {
    getPDetails();
  }, []);

  const [rawListOfTests, setRawListOfTests] = useState([]);
  const [listOfTests, setListOfTests] = useState([]);

  useEffect(() => {
    getAllTests();
  }, []);

  const getAllTests = async () => {
    const url = BASE_URL + "lab/tests/all";
    const tests = await getData(url, "");

    if (tests?.error) {
    } else {
      const data = tests?.data;

      setListOfTests(data);
    }
  };

  console.log({ ListOfTests: listOfTests });

  const addReports = (value) => {
    let o = {
      reports: value,
      patientId: patientId,
      docId: docId,
      appointmentId: appointmentId,
      caseId: caseId,
    };

    console.log({ "report object to be saved": o });
    saveTest(o);
  };

  const saveTest = async (obj) => {
    const user = await saveData(
      BASE_URL + "doctor/prescription/tests",
      obj,
      ""
    );

    if (user?.error) {
      console.log({ "error saving": user?.error });
    } else {
      setisEdit(false);
      console.log({ " saved successfully": user?.data });
      setSeverity("success");
      setMessage("Saved Successfully");
      setOpenNotice(true);
      getPDetails();
    }
  };

  const [isEdit, setisEdit] = useState(
    reportItems && reportItems.length > 0 ? false : true
  );

  const handleClearClick = () => {
    setTests([]); // Clear the selected value
  };
  return (
    <Fragment>
      <Snackbar
        open={openNotice}
        autoHideDuration={1000}
        onClose={handleCloseNotice}
      >
        <Alert
          onClose={handleCloseNotice}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Autocomplete
        size="small"
        freeSolo
        selectOnFocus
        disableClearable
        multiple
        value={tests}
        options={listOfTests}
        disableCloseOnSelect
        onChange={(event, selectedOptions) => {
          console.log("Selected options:", selectedOptions);
          setTests(selectedOptions);
        }}
        onInputChange={(event, newValue) => {
          console.log(newValue);
          const customTest = [
            {
              reportName: newValue ? newValue : "custom",
              prescribedDate: new Date(),
              reportURL: null,
            },
          ];
          setListOfTests([...rawListOfTests, ...customTest]);
        }}
        getOptionLabel={(option) =>
          option.reportName ? option.reportName : ""
        }
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.reportName}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search Tests"
            sx={{
              background: "#fff",
              color: "#000000",
              fontWeight: "300",
              fontSize: "13px",
              lineHeight: " 15px",
              "& input::placeholder": {
                color: "#777777",
                fontWeight: "300",
                fontSize: "13px",
                lineHeight: " 15px",
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {tests.length > 0 && (
                    <IconButton onClick={handleClearClick} edge="end">
                      <ClearOutlinedIcon />
                    </IconButton>
                  )}
                </>
              ),
            }}
          />
        )}
      />

      <CustomButtonBlue title="Save" onClick={() => addReports(tests)} />

      {tests?.length > 0 ? (
        <Box
          sx={{
            marginTop: "100px",
            background: "#E8E2FF",
            boxShadow: "0px 1px 6px 1px rgba(51, 0, 255, 0.25)",
            borderRadius: "15px",
            padding: "10px",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#3300FF",
              marginInline: "5px",
              width: "100%",
            }}
          >
            Tests Added: {tests.length}
          </Typography>
          {tests.map((item, index) => (
            <Typography
              key={index}
              sx={{
                fontWeight: "600",
                fontSize: "13px",
                lineHeight: "15px",
                color: "#3300FF",
                marginInline: "5px",
              }}
            >
              {item.reportName}
            </Typography>
          ))}
        </Box>
      ) : null}
    </Fragment>
  );
};

export default TestAutoComplete;
