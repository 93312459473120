import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { saveData, uploadFile } from "../../../../services/api/postApi";
import { BASE_URL } from "../../../../assets/constantsFile";
import { getData } from "../../../../services/api/getApi";
import corpResources from "../../../../assets/resourceUtils/corpResources";
import GlobalDateLayout from "../../../../assets/globalDateLayout/globalDateLayout";
import { Link } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const CustomButtonBlue = ({
  onClick,
  title,
  disabled,
  styles,
  fullWidth,
  startIcon,
  endIcon,
  backgroundColor,
  hoverColor,
}) => {
  return (
    <Button
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      onClick={onClick}
      variant="contained"
      size="small"
      fullWidth={fullWidth}
      sx={{
        paddingBlock: "10px",
        paddingInline: "20px",
        borderRadius: "10px",
        ...styles,
        backgroundColor: backgroundColor || "#0363FA",
        textTransform: "capitalize",
        ":hover": {
          backgroundColor: hoverColor || null,
        },
      }}>
      <Typography
        sx={{
          color: "#FFF",
          fontFamily: "Poppins",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "110%",
          letterSpacing: "-0.38px",
        }}>
        {title}
      </Typography>
    </Button>
  );
};

const BookSession = ({
  corpId = localStorage.getItem("CORPID"),
  userIdAuth = localStorage.getItem("ORGUSERIDAUTH"),
  userMobile = localStorage.getItem("ORGUSERMOBILE"),
  userName = localStorage.getItem("ORGUSERNAME"),
  setFetch,
  source,
  data,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [sessionDate, setSessionDate] = useState(null);
  const [sessionDetail, setSessionDetail] = useState(null);

  const [openBookSessionDialog, setOpenBookSessionDialog] = useState(false);
  const [openChooseDateDialog, setOpenChooseDateDialog] = useState(false);
  const [openSuccessMessageDialog, setOpenSuccessMessageDialog] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);

  const handleOpenBookSessionDialog = () => {
    setOpenBookSessionDialog(true);
  };

  const handleCloseBookSessionDialog = () => {
    setOpenBookSessionDialog(false);
  };

  const handleOpenChooseDateDialog = () => {
    setOpenChooseDateDialog(true);
  };

  const handleCloseChooseDateDialog = () => {
    setOpenChooseDateDialog(false);
  };

  const handleConfirmBooking = async () => {
    // const Obj = {
    //   date: dayjs().format("YYYY-MM-DD"),
    //   status: "TICKET_RAISED",
    //   ticketType: "HEALTH_AWARENESS",
    //   ticketCategory: "CORP",
    //   corpId: corpId,
    //   raisedBy: userName,
    //   raisedById: userIdAuth,
    //   raisedByMobileNo: userMobile,
    //   ticketInfo: {
    //     sessionId: selectedSession,
    //     sessionDate: sessionDate,
    //     sessionName: sessionDetail.sessionName,
    //   },
    // };

    let formData = new FormData();

    // Append each key-value pair to the FormData object
    formData.append("userAuthId", userIdAuth);
    formData.append("status", "TICKET_RAISED");
    formData.append("ticketType", "HEALTH_AWARENESS");
    formData.append("ticketMode", "WEB");
    formData.append("ticketCategory", "CORP");
    formData.append("corpId", corpId);
    formData.append("raisedBy", userName); // Consider sending an empty string or null explicitly if supported
    formData.append("raisedById", userIdAuth);
    formData.append("raisedByMobileNo", userMobile);

    // Add the nested object as a JSON string
    formData.append(
      "ticketInfo",
      JSON.stringify({
        corpId: corpId,
        sessionId: selectedSession,
        sessionDate: sessionDate,
        sessionName: sessionDetail.sessionName,
      })
    );
    const url = BASE_URL + `org/v2/raiseTicket`;
    const result = await uploadFile(url, formData);
    if (result.error) {
      enqueueSnackbar(`${result.error.response.data.message}`, {
        variant: "error",
      });
    } else {
      console.log("Booking session for:", selectedSession);
      handleCloseChooseDateDialog();
      setOpenSuccessMessageDialog(true);
      setTimeout(() => {
        setOpenSuccessMessageDialog(false);
      }, 4000);
      setFetch(true);
      setSessionDetail(null);
    }
  };

  const handleChange = (event) => {
    setSelectedSession(event.target.value);
  };

  const [sessionListData, setSessionListData] = useState([]);
  const getSessionList = async () => {
    const url = BASE_URL + `org/awarenessSessions/list`;
    const result = await getData(url);
    if (result.error) {
      console.log(result.error);
    } else {
      const temp = result.data.map((item, index) => ({
        ...item,
        title: `Session ${index + 1}`,
      }));
      setSessionListData(temp);
    }
  };
  useEffect(() => {
    getSessionList();
  }, []);

  const [expandedSession, setExpandedSession] = useState(null);

  const toggleSessionDetail = (sessionId) => {
    if (expandedSession === sessionId) {
      setExpandedSession(null);
    } else {
      setExpandedSession(sessionId);
    }
  };

  return (
    <Fragment>
      {source === "TICKETSDASHBOARD" ? (
        <Card
          sx={{ minWidth: 275, background: "#efefff", height: 170 }}
          onClick={() => {
            setOpenBookSessionDialog(true);
          }}>
          <CardActionArea>
            <CardContent>
              <Stack direction="row" spacing={2}>
                <img height={120} width={200} src={data?.imageUrl} />

                <Box
                  sx={{
                    position: "absolute ",
                    top: 20,
                    right: -20,
                    height: 180,
                    width: 180,
                    borderRadius: 100,
                    mt: 100,
                    background: "#fff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <Stack
                    direction="row"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      {data?.title}
                    </Typography>
                    <Box
                      sx={{
                        height: 30,
                        width: 30,
                        borderRadius: 15,
                        background: "#efefff",
                      }}
                      display="flex"
                      alignItems="center"
                      justifyContent="center">
                      <ArrowRightAltIcon fontSize="10" />
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
      ) : (
        <CustomButtonBlue
          onClick={handleOpenBookSessionDialog}
          startIcon={
            <Box
              sx={{
                p: 0.1,
                backgroundColor: "#FFFFFF",
                borderRadius: 1,
                height: 22,
                width: 22,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <AddIcon sx={{ color: "#FF0000" }} />
            </Box>
          }
          backgroundColor={"#FF0000"}
          hoverColor={"#B20000"}
          title="Book Session"
        />
      )}

      <Dialog open={openBookSessionDialog} onClose={handleCloseBookSessionDialog} maxWidth={"md"}>
        <DialogTitle sx={{ textAlign: "center" }}>Session Details</DialogTitle>
        <IconButton
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            margin: 1,
          }}
          onClick={handleCloseBookSessionDialog}>
          <CloseIcon />
        </IconButton>
        <Divider variant="fullWidth" sx={{ marginBlock: 1 }} />
        <DialogContent
          sx={{
            maxHeight: "80vh",
            minWidth: "md",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}>
          {sessionListData.map((item, index) => (
            <Grid container key={index}>
              <Grid item lg={2}>
                <FormControl>
                  <RadioGroup
                    sx={{ fontWeight: "600" }}
                    value={selectedSession}
                    onChange={(event) => {
                      handleChange(event);
                      setSessionDetail(item);
                    }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel value={item.id} control={<Radio />} />
                      <img
                        src={item.imageUrl}
                        style={{
                          height: 90,
                          width: 90,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleChange({ target: { value: item.id } });
                        }}
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item lg={10} sx={{ p: 1 }}>
                <Typography sx={{ fontWeight: "600" }}>{item.sessionName}</Typography>
                <Typography>
                  {expandedSession === item?.id
                    ? item?.description
                    : `${item?.description?.substring(0, 100)}...`}
                  <Link
                    onClick={() => toggleSessionDetail(item.id)}
                    size="small"
                    sx={{ textTransform: "none", color: "blue" }}>
                    {expandedSession === item?.id ? "See less" : "See more"}
                  </Link>
                </Typography>

                <Typography sx={{ display: "flex", alignItems: "center", marginBlock: 1 }}>
                  <AccessTimeIcon sx={{ marginRight: 1 }} /> {item.duration}
                </Typography>
              </Grid>
              <Grid item lg={12}>
                <Divider variant="fullWidth" sx={{ marginBlock: 1 }} />
              </Grid>
            </Grid>
          ))}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <CustomButtonBlue
            disabled={selectedSession ? false : true}
            title="Choose Date"
            onClick={() => {
              handleCloseBookSessionDialog();
              handleOpenChooseDateDialog();
            }}
            styles={{ width: 200 }}
          />
        </DialogActions>
      </Dialog>

      <Dialog open={openChooseDateDialog} onClose={handleCloseChooseDateDialog} maxWidth={"xs"}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          Choose your preferred date for session{" "}
          <IconButton aria-label="close" onClick={handleCloseChooseDateDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          <GlobalDateLayout
            initialDate={sessionDate}
            setDate={setSessionDate}
            label="Choose Session Date"
            disablePast={true}
          />
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
          <CustomButtonBlue
            title="Back"
            styles={{ width: 120 }}
            onClick={() => {
              handleCloseChooseDateDialog();
              handleOpenBookSessionDialog();
            }}
          />

          <CustomButtonBlue
            title="Confirm"
            styles={{ width: 120 }}
            onClick={handleConfirmBooking}
          />
        </DialogActions>
      </Dialog>
      <Dialog open={openSuccessMessageDialog} maxWidth={"xs"}>
        <DialogContent>
          <Box
            sx={{
              alignContent: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <img src={corpResources.img9} style={{ width: 56, height: 56 }} />
          </Box>

          <DialogContentText
            sx={{
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "600",
              color: "#000000",
            }}>
            Session requested successfully
          </DialogContentText>
          <Typography sx={{ textAlign: "center" }}>
            Uno.care team will reach out to you for booking confirmation.
          </Typography>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default BookSession;
