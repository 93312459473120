import React from "react";
import { makeStyles } from "@mui/styles";
import { PieChart, Pie, Cell, Label, Legend, ResponsiveContainer, Tooltip } from "recharts";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Portal,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import { useEffect } from "react";
import StatusTrackerDetailGrid from "../../../../global/customDatagrids/statusTrackerDetailGrid";
import { isMobile } from "react-device-detect";
import DownloadCSV from "../../../../global/components/downloadCSV";
import MaskingButton from "../../../../global/components/maskingButton";

const useStyles = makeStyles((theme) => ({
  legend: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // padding: "10px",
    // backgroundColor: theme.palette.background.paper,
    // border: `1px solid ${theme.palette.divider}`,
    // borderRadius: theme.spacing(1),
  },
  legendItem: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  colorSwatch: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1),
    backgroundColor: "#ccc",
    borderRadius: "50%",
  },
  label: {
    fontSize: 10,
    marginRight: "10px",
  },
  value: {
    fontSize: "1px",
    marginLeft: theme.spacing(1),
  },
}));

const CustomLegend = ({ payload, COLORS }) => {
  const classes = useStyles();

  return (
    <Box className={classes.legend}>
      {payload.map((item, index) => (
        <div key={item.name} className={classes.legendItem}>
          <Box sx={{ display: "flex" }}>
            <Box className={classes.colorSwatch} style={{ backgroundColor: COLORS[index] }} />
            <Typography variant="body2" className={classes.label}>
              {item.name}
            </Typography>
          </Box>
          <Typography variant="body2" className={classes.percent} sx={{ fontSize: 10 }}>
            {item.percent}%
          </Typography>
        </div>
      ))}
    </Box>
  );
};

const CustomTooltipContent = ({ active, payload, label }) => {
  if (active) {
    const data = payload?.[0]?.payload; // Get the data of the hovered bar

    return (
      <Box sx={{ backgroundColor: "#FFFFFF", padding: "10px" }}>
        <Typography>{`${data.name}`}</Typography>
        <Typography>{`Percentage: ${data.percent}`}</Typography>
        <Typography>{`Value: ${data.value}`}</Typography>
      </Box>
    );
  }

  return null;
};

const OhcDoughnutChart = ({
  data,
  title,
  dialogData,
  labelHeading,
  boxWidth,
  boxHeight = 300,
  totalOpds,
  disableClickable,
  minWidth,
  COLORS = [],
  legendAlignment,
  layoutAlignmnment,
  innerRadius,
  outerRadius,
}) => {
  console.log({ dialogData, data });
  const [apiRef, setApiRef] = useState(null);
  const exportCSV = () => {
    apiRef.current.exportDataAsCsv();
  };
  const [open, setOpen] = useState(false);
  const [selectedChart, setSelectedChart] = useState("");
  const [selectedBarData, setSelectedBarData] = useState([]);
  const handleOpen = (title) => {
    setOpen(true);
    setSelectedChart(title);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedChart("");
    setSelectedBarData([]);
  };

  useEffect(() => {
    if (dialogData) {
      console.log(dialogData, selectedChart);
      const data = dialogData[selectedChart];
      console.log({ kokoko: dialogData[selectedChart] });
      if (data) {
        setSelectedBarData(
          data.map((value, index) => ({
            id: `employee ${index}`,
            emp_id: value.empId || value.emp_id,
            name: value.name,
            department: value.department,
          }))
        );
      } else {
        console.log("Selected chart not found in dialogData");
      }
    }
  }, [selectedChart]);

  console.log({ selectedBarData });
  console.log({ selectedChart });

  const total = data.reduce((acc, entry) => acc + entry.value, 0);

  console.log({ title, data, total });

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        border: "1px solid grey",
        height: 250,
        paddingInline: "10px",
        paddingBlock: "10px",
        borderRadius: "10px",
        minWidth: minWidth,
      }}>
      <Typography
        textAlign="left"
        sx={{
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "120%",
        }}>
        {title}
      </Typography>

      <ResponsiveContainer width="100%" height="100%">
        {data.every((item) => item.value === 0) ? (
          <Box
            width={"100%"}
            height={"100%"}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#FFFFFF",
            }}>
            <Typography sx={{ textAlign: "center" }}>No Data Found</Typography>
          </Box>
        ) : (
          <PieChart width={"100%"} height={"100%"}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={innerRadius || 45}
              outerRadius={outerRadius || 75}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
              legendType="none"
              onClick={(pie) => {
                if (disableClickable) {
                  return;
                } else {
                  console.log({ pie });
                  handleOpen(pie?.name);
                }
              }}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index]} />
              ))}
              <Label
                fontSize={"12px"}
                fontStyle={"normal"}
                fontWeight={"700"}
                colorProfile={"#16161D"}
                value={`${"#"}: ${total}`}
                position="center"
              />
            </Pie>
            <Tooltip content={<CustomTooltipContent />} />
            <Legend
              payload={data}
              COLORS={COLORS}
              content={CustomLegend}
              layout={layoutAlignmnment || "horizontal"}
              width={isMobile && "100%"}
              align={isMobile ? "center" : legendAlignment || "bottom"}
              verticalAlign={isMobile ? "bottom" : legendAlignment || "bottom"}
            />
          </PieChart>
        )}
      </ResponsiveContainer>

      <Portal>
        <Dialog open={open} onClose={handleClose} maxWidth={"xl"} fullWidth={true}>
          <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>{title}</DialogTitle>
          <Typography variant="h6" sx={{ display: "flex", justifyContent: "center" }}>
            {selectedChart}
          </Typography>
          <Box sx={{ marginLeft: "22px" }}>
            <Stack direction="row" spacing={1}>
              <DownloadCSV exportCSV={exportCSV} type="ICON" />
              <MaskingButton type="ICON" />
            </Stack>
          </Box>
          <DialogActions sx={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
          <DialogContent>
            <StatusTrackerDetailGrid setApiRef={setApiRef} employeeList={selectedBarData} />
          </DialogContent>
        </Dialog>
      </Portal>
    </Box>
  );
};

export default OhcDoughnutChart;
