import { Box, Container, FormControlLabel, FormGroup, Grid, TextField } from "@mui/material";
import Switch from "@mui/material/Switch";
import { useCallback, useContext, useEffect, useState } from "react";
import DoctorBasicInfo from "./profileComponents/doctorBasicInfo";

import { userContext } from "../common/context/usercontext";
import { BASE_URL } from "../assets/constantsFile";
import { getData } from "../services/api/getApi";
import ProfileImage from "./profileComponents/profileImage";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";

const ProfileMainComponent = (props) => {
  const [isActive, setIsActive] = useState(true);

  const [profileData, setProfileData] = useState("");

  const [startDateValue, setStartDateValue] = useState(new Date());
  const [endDateValue, setEndDateValue] = useState(new Date());

  const createDnd = () => {
    setIsActive(!isActive);
  };

  const contextData = useContext(userContext);

  const fetchProfileData = useCallback(async (docId, token) => {
    const docData = await getData(BASE_URL + "doctor/" + docId, token);
    console.log({ error: docData.error });
    if (docData.error) {
      console.log({ error: docData.error });
    } else {
      const data = await docData.data;

      console.log({ data: data });
      const transformedData = {
        docId: data.docId,
        profileInfo: data,
      };

      setProfileData(transformedData);
      console.log({ profile_data: transformedData });
    }
  }, []);

  useEffect(() => {
    fetchProfileData(localStorage.getItem("DOCID"), contextData.authHeader);
  }, [fetchProfileData]);

  console.log({ profile_data: profileData });
  console.log({
    "localStorage.getItem('DOCID')": localStorage.getItem("DOCID"),
  });

  return (
    <Box
      sx={{
        position: "fixed",
        width: "100vw",
        borderRadius: 2,
        zIndex: -5,
      }}>
      <Container component="main" maxWidth={false} sx={{ overflow: "auto" }}>
        <Box
          component="div"
          sx={{
            position: "absolute",
            height: "70vh",

            width: "97vw",
            mt: 2,
            filter: "drop-shadow(1px 1px 7px rgba(32, 143, 148, 0.95))",
            borderRadius: 2,
          }}>
          <Grid container spacing={0}>
            <Grid item lg={12}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  height: "9vh",
                  width: "100%",
                }}>
                <Grid container spacing={0}>
                  <Grid item lg={7}></Grid>
                  <Grid item lg={5} display="flex" justifyContent="flex-end">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        background: "#fff",
                        height: 45,
                        borderRadius: 2,
                        pl: 2,
                      }}>
                      <FormGroup>
                        <FormControlLabel
                          control={<Switch color="primary" size="small" onChange={createDnd} />}
                          label="DND"
                        />
                      </FormGroup>

                      {isActive ? (
                        <Box>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              disablePast
                              InputProps={{ style: { fontSize: 11 } }}
                              label="Start Date"
                              openTo="day"
                              views={["year", "month", "day"]}
                              disableMaskedInput={true}
                              inputFormat="dd MMM yyyy"
                              value={startDateValue}
                              onChange={(newValue) => {
                                setStartDateValue(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputLabelProps={{ style: { fontSize: 12 } }}
                                  sx={{ mr: 1, height: 25, width: 125 }}
                                  size="small"
                                />
                              )}
                            />
                            <DatePicker
                              disablePast
                              InputProps={{ style: { fontSize: 11 } }}
                              label="End Date"
                              openTo="day"
                              views={["year", "month", "day"]}
                              disableMaskedInput={true}
                              inputFormat="dd MMM yyyy"
                              value={endDateValue}
                              onChange={(newValue) => {
                                setEndDateValue(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputLabelProps={{ style: { fontSize: 12 } }}
                                  sx={{ mr: 1, height: 25, width: 125 }}
                                  size="small"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Box>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item lg={12}>
              <Box sx={{ background: "#fff", p: 2, borderRadius: 2 }}>
                <Grid container>
                  <Grid item lg={4}>
                    <ProfileImage profileData={profileData} />
                  </Grid>
                  <Grid item lg={8}>
                    <DoctorBasicInfo profileData={profileData} />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item lg={12}>
              <br />
              <br />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default ProfileMainComponent;
