import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Grid, Link, Stack } from "@mui/material";
import EChokitsalayaLogo from "../../logoNew.svg";
import { userContext } from "../context/usercontext";
import { BASE_URL } from "../../assets/constantsFile";
import { getData } from "../../services/api/getApi";
import LogoMain from "../logo/logoMain";
import { matchRoutes, useLocation } from "react-router-dom";

const pages = [
  { name: "patients", url: "/patients" },
  { name: "templates", url: "/templates" },
  { name: "slots", url: "/slots" },
  { name: "Patient Registration", url: "/registration" },
];
const settings = [
  { name: "Profile", url: "/profiles" },
  { name: "Logout", url: "/logout" },
];

const MyAppBarAfterLogin = () => {
  const contextData = React.useContext(userContext);
  const [profileData, setProfileData] = React.useState("");
  const [fullName, setFullName] = React.useState("");
  const [imageURL, seImageURL] = React.useState("");

  const fetchProfileData = React.useCallback(async (docId, token) => {
    const docData = await getData(BASE_URL + "doctor/" + docId, token);
    console.log({ error: docData.error });
    if (docData.error) {
      console.log({ error: docData.error });
    } else {
      const data = await docData.data;

      console.log({ data: data });

      setProfileData(data);
    }
  }, []);

  React.useEffect(() => {
    fetchProfileData(localStorage.getItem("DOCID"), contextData.authHeader);
  }, [fetchProfileData]);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const nonActiveButton = {
    my: 2,
    display: "block",
    fontSize: "1rem",
    fontWeight: "600",
    fontFamily: "Roboto",
    textAlign: "center",
    color: "black",
  };

  const activeButton = {
    my: 2,
    display: "block",
    fontSize: "1rem",
    fontWeight: "600",
    fontFamily: "Roboto",
    textAlign: "center",
    backgroundColor: "white",
    color: "#127DDD",
    borderBottom: "5px solid #127DDD",
    borderRadius: "0px",
  };
  let path = window.location.href.split("/");
  path = "/" + path[path.length - 1];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flex: 1, display: { xs: "none", lg: "flex" } }}>
            <Grid container>
              <Grid
                item
                lg={3}
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Link underline="none" href="/patients">
                  <LogoMain />
                </Link>
              </Grid>
              <Grid
                item
                lg={8}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box>
                  <Stack
                    direction="row"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                  >
                    {pages.map((page) => (
                      <Button
                        href={page.url}
                        key={page.name}
                        onClick={handleCloseNavMenu}
                        sx={page.url === path ? activeButton : nonActiveButton}
                      >
                        {page.name}
                      </Button>
                    ))}
                  </Stack>
                </Box>
              </Grid>
              <Grid
                item
                lg={1}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Box>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar
                        sx={{ background: "#208F94" }}
                        src={profileData.imageURL}
                      ></Avatar>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {settings.map((setting) => (
                      <MenuItem
                        key={setting.name}
                        onClick={handleCloseUserMenu}
                      >
                        <Link
                          underline="none"
                          href={setting.url}
                          textAlign="center"
                        >
                          {setting.name}
                        </Link>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ flex: 1, display: { xs: "flex", lg: "none" } }}>
            <Grid container>
              <Grid
                item
                xs={2}
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Box>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="primary"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: "block", lg: "none" },
                    }}
                  >
                    {pages.map((page) => (
                      <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                        <Link
                          underline="none"
                          variant="button"
                          color="text.primary"
                          href={page.url}
                          sx={{ my: 1, mx: 1.5 }}
                        >
                          {page.name}
                        </Link>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </Grid>
              <Grid
                item
                xs={8}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Link href="/patients" underline="none">
                  <LogoMain />
                </Link>
              </Grid>
              <Grid
                item
                xs={2}
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Box>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar
                        sx={{ background: "#208F94" }}
                        src={profileData.imageURL}
                      ></Avatar>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {settings.map((setting) => (
                      <MenuItem
                        key={setting.name}
                        onClick={handleCloseUserMenu}
                      >
                        <Link
                          underline="none"
                          href={setting.url}
                          textAlign="center"
                        >
                          {setting.name}
                        </Link>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default MyAppBarAfterLogin;
