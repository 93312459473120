import React, { Component } from 'react';
import MyAppBarBeforeLogin from '../common/header/myAppBarBeforeLogin';

const Career = () => {

        return (
            <MyAppBarBeforeLogin/>
        );

}
 
export default Career;