import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { useFileUpload } from "use-file-upload";
import { useSnackbar } from "notistack";
import { BASE_URL } from "../../../../../../../assets/constantsFile";
import {
  saveData,
  saveDataWithMultipartForm,
} from "../../../../../../../services/api/postApi";
import CustomAutocompleteEmpId from "./customAutocompleteEmpId";
import CustomAutocompleteComplaints from "./customAutocompleteComplaints";
import CustomTextFieldsOpd from "./customTextFieldsOpd";
import WritePrescriptionModal from "./writePrescription/writePrescriptionModal";
import CustomAutocomplete from "../../../../../../../assets/customAutocomplete/customAutocomplete";
import { Add, Edit, Visibility } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import AddMedicine from "./addMedicine/addMedicine";
import dayjs from "dayjs";
import { showMedicineComp } from "../../../../../../../assets/utils";

const fields = (val, corpId) => [
  {
    id: 2,
    title: "Name",
    property: "name",
    gridwidth: 4,
    gridwidthxs: 12,
  },
  {
    id: 3,
    title: "Age",
    property: "age",
    type: "number",
    length: 3,
    gridwidth: 2,
    gridwidthxs: 12,
  },
  {
    id: 4,
    title: "Gender",
    property: "gender",
    type: "gender",
    gridwidth: 2,
    gridwidthxs: 12,
  },
  {
    id: 5,
    title: "Mobile Number",
    property: "mobile",
    type: "number",
    length: 11,
    gridwidth: 2,
    gridwidthxs: 12,
  },
  {
    id: 6,
    title: "BP",
    property: "bp",
    gridwidth: 2,
    gridwidthxs: 12,
  },
  {
    id: 6,
    title: "Blood Group",
    property: "bloodGroup",
    gridwidth: 2,
    gridwidthxs: 12,
  },
  {
    id: 7,
    title: "Sugar Level",
    property: "sugar",
    gridwidth: 2,
    gridwidthxs: 12,
  },
  {
    id: 8,
    title: "Height",
    property: "height",
    gridwidth: 2,
    gridwidthxs: 12,
  },
  {
    id: 9,
    title: "Weight",
    property: "weight",
    gridwidth: 2,
    gridwidthxs: 12,
  },
  {
    id: 13,
    title: "Department",
    property: "department",
    gridwidth: 4,
    gridwidthxs: 12,
  },
  {
    id: 10,
    title: "Complaint",
    property: "complaint",
    gridwidth: 4,
    gridwidthxs: 12,
  },
  // ...(showMedicineComp(corpId)
  //   ? []
  //   : [
  // {
  //   id: 13,
  //   title: "Medicine",
  //   property: "medicine",
  //   gridwidth: 4,
  //   gridwidthxs: 12,
  // },
  // ]),
  {
    id: 12,
    title: "Rest",
    property: "rest",
    type: "toggle",
    disabled: val["fitToWork"] ? true : false,
    gridwidth: 1,
    gridwidthxs: 12,
  },
  {
    id: 12,
    title: "Fit to Work",
    property: "fitToWork",
    type: "toggle",
    disabled: val["rest"] ? true : false,
    gridwidth: 1,
    gridwidthxs: 12,
  },
  {
    id: 12,
    title: "Under Medication",
    property: "underMedication",
    type: "toggle",
    gridwidth: 2,
    gridwidthxs: 12,
  },
  {
    id: 12,
    title: "Next Appointment Date",
    property: "nextAppointmentDate",
    type: "date",
    gridwidth: 4,
    gridwidthxs: 12,
  },
  {
    id: 12,
    title: "Upload Images/PDF",
    property: "files",
    type: "upload",
    gridwidth: 4,
    gridwidthxs: 12,
  },
];
const OpdMain = ({
  corpId = localStorage.getItem("CORPID"),
  userAuthID = localStorage.getItem("ORGUSERIDAUTH"),
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [medicines, setMedicines] = useState([]);
  const [formValues, setFormValues] = useState({ corpId: corpId, empId: "" });
  const handleRemove = (index) => {
    console.log("Removing file at index:", index);
    const updatedFiles = formValues?.files?.filter((_, i) => i !== index);
    setFormValues({ ...formValues, files: updatedFiles });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    const url = BASE_URL + `org/daily/stats`;
    if (formValues.name) {
      const result = saveDataWithMultipartForm(url, {
        ...formValues,
        medicine: medicines.map((item) => item.medicineName).join(", ") || "",
        ohcType: "OPD",
        nextAppointmentDate: formValues?.nextAppointmentDate || "",
      });
      console.log({ result });
      if (result.error) {
        console.log("error");
        enqueueSnackbar("An error occured", { variant: "error" });
      } else {
        console.log("success");

        setFormValues({ empId: "" });
        if (medicines.length > 0) {
          handleSaveMedicines();
        } else {
          enqueueSnackbar("Saved Successfully.", { variant: "success" });
        }
      }
    }
  };

  console.log({ medicines });

  const [isLoading, setIsLoading] = useState(false);
  // const [open, setOpen] = useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  const [fetch, setFetch] = useState(false);
  const handleSaveMedicines = async () => {
    const Obj = medicines.map((item, index) => ({
      batchCode: item.batchNo,
      batchExpiryDate: item.batchExpiryDate,
      productCode: item.productCode,
      outwardBaseUnitQuantity: item.quantity,
      customerId: formValues.empId,
      transactionBy: userAuthID,
      transactionDate: dayjs().format("YYYY-MM-DD"),
    }));
    setFetch(false);
    console.log({ medicines });
    const url =
      BASE_URL +
      `inventory/saveTransaction?transactionType=SALES&corpId=${corpId}`;
    const result = await saveData(url, Obj);
    if (result.error) {
      console.log("error");
      enqueueSnackbar(`${result.error.response.data.message}`, {
        variant: "error",
      });
    } else {
      console.log("success");
      enqueueSnackbar("Saved Successfully.", { variant: "success" });
      setMedicines([]);
      setFetch(true);
    }
  };

  return (
    <Fragment>
      <form onSubmit={submitHandler}>
        <Box>
          <Grid container rowSpacing={2} columnSpacing={{ lg: 4, xs: 1 }}>
            <Grid item lg={4} xs={4}>
              <CustomAutocompleteEmpId
                formValues={formValues}
                setFormValues={setFormValues}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
              />
            </Grid>

            {fields(formValues, corpId).map((item, index) =>
              item.property === "complaint" ? (
                <Grid
                  item
                  lg={item?.gridwidth || 4}
                  xs={item?.gridwidthxs || 4}
                  key={index}
                >
                  <CustomAutocompleteComplaints
                    formValues={formValues}
                    setFormValues={setFormValues}
                    property={item.property}
                  />
                </Grid>
              ) : (
                <Grid
                  item
                  lg={item?.gridwidth || 4}
                  xs={item?.gridwidthxs || 4}
                  key={index}
                >
                  <CustomTextFieldsOpd
                    item={item}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    required={
                      item.property === "gender" ||
                      item.property === "age" ||
                      item.property === "name"
                        ? true
                        : false
                    }
                    asterickColor={"red"}
                  />
                </Grid>
              )
            )}

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ display: "flex", flexWrap: "wrap" }}
            >
              {formValues?.files?.length
                ? formValues?.files.map((file, index) => (
                    <Paper
                      key={index}
                      elevation={3}
                      style={{
                        maxWidth: "150px",
                        marginInline: "10px",
                        marginBlock: "5px",
                      }}
                    >
                      <Box position="relative">
                        {file.name.toLowerCase().endsWith(".pdf") ? (
                          <Box
                            sx={{
                              height: "130px",
                              width: "130px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <PictureAsPdfIcon
                              style={{ fontSize: 100, color: "#127DDD" }}
                            />
                          </Box>
                        ) : (
                          <img
                            src={file.source}
                            alt={file.source}
                            style={{ maxHeight: "130px", width: "130px" }}
                          />
                        )}
                        <IconButton
                          size="small"
                          color="secondary"
                          onClick={() => handleRemove(index)}
                          style={{
                            position: "absolute",
                            top: 5,
                            right: 5,
                            color: "red",
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <Typography variant="body2" align="center">
                          {file.name}
                        </Typography>
                      </Box>
                    </Paper>
                  ))
                : null}
            </Grid>
          </Grid>
        </Box>

        {showMedicineComp(corpId) && (
          <AddMedicine
            medicines={medicines}
            setMedicines={setMedicines}
            fetch={fetch}
          />
        )}
        {/* {(corpId === "13a4b8c6-750f-442a-8414-f5465481e5d9" ||
          corpId === "1ab48481-e6f5-4dd6-8b50-983c94d763a1" ||
          corpId === "820df4bf-eb31-4bc9-9cd1-789b36f47883" ||
          corpId === "3e875c62-9ecb-49b7-9fda-067379425f75" ||
          corpId === "dfe08099-3324-4d60-807a-b04fe8b4a373" ||
          corpId === "656f54f4-e8c0-42f5-afc1-31e158fe8f6f" ||
          corpId === "6d5ce94f-6b9b-4efa-a650-1298481cb413" ||
          corpId === "bbb269e5-b020-4257-ad8f-4da8c811801a" ||
          corpId === "872cd841-9f7a-432d-b8e9-422b780bca10") && (
          <AddMedicine
            medicines={medicines}
            setMedicines={setMedicines}
            fetch={fetch}
          />
        )} */}

        <Button
          variant="contained"
          size="large"
          type="submit"
          sx={{ width: 140, mt: 2 }}
          disabled={
            formValues.gender && formValues.age && formValues.name
              ? false
              : true
          }
        >
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: 13,
              lineHeightt: 17,
              color: "#FFFFFF",
            }}
          >
            Submit
          </Typography>
        </Button>
      </form>

      {/* <WritePrescriptionModal open={open} handleClose={handleClose} /> */}
    </Fragment>
  );
};

export default OpdMain;
