import { Box, Container, Grid, useMediaQuery } from "@mui/material";
import React from "react";
import LeftBanner from "./components/banner/LeftBanner";
import RightBanner from "./components/banner/RightBanner";
import AboutUs from "./modules/aboutUS/aboutUs";
import LandingService from "./modules/services/landingService";
import LandingFeatures from "./modules/features/landingFeatures";
import LandingAppointment from "./modules/appointment/landingAppointment";
import LandingTestimonial from "./modules/testimonials/landingTestimonial";
import { useRef } from "react";
import UpArrowButton from "./components/upArrow/UpArrowButton";

const LandingPageMain = ({ contactRef, scrollToContact, aboutRef, specificationRef }) => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  return (
    <Container maxWidth={false} disableGutters>
      <Grid container rowSpacing={2}>
        <Grid item xs={12} lg={6} sx={{ marginTop: isSmallScreen ? null : "140px" }}>
          <LeftBanner scrollToContact={scrollToContact} />
        </Grid>
        <Grid item xs={12} lg={6} sx={{ marginTop: isSmallScreen ? null : "140px" }}>
          <RightBanner />
        </Grid>
        <Grid item xs={12} lg={12}>
          <Box
            ref={aboutRef}
            sx={{
              display: "block",
              height: 70,
              marginTop: -10,
              visibility: "hidden",
            }}></Box>
          <AboutUs />
        </Grid>

        <Grid item xs={12} lg={12}>
          <LandingService />
        </Grid>

        <Grid item xs={12} lg={12}>
          <Box ref={specificationRef} sx={{ paddingBlock: 5 }}></Box>
          <LandingFeatures />
        </Grid>

        <Grid item xs={12} lg={12}>
          <Box ref={contactRef} sx={{ paddingBlock: 5 }}>
            <LandingAppointment />
          </Box>
        </Grid>

        <Grid item xs={12} lg={12}>
          {/* <Box sx={{ paddingBlock: 10 }}> */}
          <LandingTestimonial />
          {/* </Box> */}
        </Grid>
      </Grid>
      <UpArrowButton />
    </Container>
  );
};

export default LandingPageMain;
