import { Box, Button, Drawer, Grid, IconButton, Portal, Stack, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import HorizontalSplitIcon from "@mui/icons-material/HorizontalSplit";
const CustomizedLabel = (props) => {
  const { x, y, stroke, value } = props;

  return (
    <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
      {value}
    </text>
  );
};

const CustomizedAxisTick = (props) => {
  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-60)" fontSize={10}>
        {payload.value}
      </text>
    </g>
  );
};

const CustomBarChart = ({ chartData, title, setIsGraph, setSelectedMonth, month }) => {
  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              height: "50vh",
              width: "100%",
            }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart width={730} height={250} data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" style={{ fontSize: 12 }} interval={0} />

                <YAxis />
                <Tooltip />
                <Legend verticalAlign="top" height={36} />
                <Bar dataKey="pass" fill="#82ca9d" name="pass" barSize={70}>
                  <LabelList dataKey="pass" position="top" />
                </Bar>
                <Bar dataKey="fail" fill="#FF817E" barSize={70}>
                  <LabelList dataKey="fail" position="top" />
                </Bar>
                <Bar dataKey="pending" fill="#127DDD" barSize={70}>
                  <LabelList dataKey="pending" position="top" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
            <Stack direction="row" display="flex" justifyContent="center" alignItems="center">
              <Typography textAlign="center">{title}</Typography>
              <IconButton
                onClick={() => {
                  console.log("hi");
                  setIsGraph(false);
                  setSelectedMonth(month);
                }}>
                <HorizontalSplitIcon color="primary" />
              </IconButton>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default CustomBarChart;
