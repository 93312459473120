import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useRef, Fragment, useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import PlaceHolder from "../../../../assets/images/samplePrescription.png";
import Morehorizontal from "../../../../assets/images/morehorizontalwhite.png";
import { BASE_URL } from "../../../../assets/constantsFile";
import { getData } from "../../../../services/api/getApi";
import PrintReportComponent from "../../../viewReport/printReportComponent";
import { getDelay } from "../../../../assets/utils";

const Prescription = ({ data }) => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const caseId = data.caseId;
  const appointmentId = data.appointmentId;
  const patientId = data.patientId;

  const [pDetails, setPdetails] = useState();

  const getPDetails = async () => {
    const url = BASE_URL + "doctor/appointment/details/" + appointmentId;

    const user = await getData(url, "");

    if (user.error) {
    } else {
      const data = user.data;

      const transformedPatients = data.map((patientData, index) => {
        return {
          index: index,
          advice: patientData.advice,
          docId: patientData.docId,
          clinicId: patientData.clinicId,
          patientId: patientData.patientId,

          patient: {
            patientId: patientData.patientId,
            name: patientData.patientName,
            gender: patientData.gender,
            age: patientData.age,
          },
          name: patientData.patientName,
          gender: patientData.gender,
          age: patientData.age,
          attended: patientData.attended, /////to be added
          issue: patientData.issue, /////to be added
          chiefComplaints: patientData.chiefComplaints,

          paymentRequired: patientData.paymentRequired,
          symptom: patientData.symptom,
          startTime: patientData.startTime,
          appointmentDelay: getDelay(patientData.startTime),
          endTime: patientData.endTime,
          date: patientData.date,
          appointmentId: patientData.appointmentId,
          observation: patientData.observation,
          slotNo: patientData.slotNo,
          consultationStartTime: patientData.consultationStartTime,
          consultationEndTime: patientData.consultationEndTime,
          consultationType: patientData.consultationType,
          caseId: patientData.caseId,
          doctorName: patientData.doctorName,
          doctorSpeciality: patientData.doctorSpeciality,
          amountPayable: patientData.amountPayable,
          amountCollected: patientData.amountCollected,
          modeOfPayment: patientData.modeOfPayment,
          patientName: patientData.patientName,
          firstConsultationDate: patientData.firstConsultationDate,
          lastConsultationDate: patientData.lastConsultationDate,

          firstName: patientData.firstName,
          middleName: patientData.middleName,
          lastName: patientData.lastName,
          fatherName: patientData.fatherName,
          dateOfBirth: patientData.dateOfBirth,

          email: patientData.email,
          mobile: patientData.mobile,
          countryCode: patientData.countryCode,

          guardianFirstName: patientData.guardianFirstName,
          guardianLastName: patientData.guardianLastName,
          guardianContactNumber: patientData.guardianContactNumber,
          source: patientData.source,

          videoURL: patientData.videoURL,
          vitals: patientData.vitals,
          prescriptionVM: patientData.prescriptionVM,
          reports: patientData.reports,
          paymentVM: patientData.paymentVM,
          personalVisitNeeded: patientData.personalVisitNeeded,
          nextAppointmentDate: patientData.nextAppointmentDate,
          patientInfoVM: patientData.patientInfoVM,

          patientDeatils: {
            advice: patientData.advice,
            patientData: patientData,
            appointmentId: patientData.appointmentId,
            patientId: patientData.patientId,
            caseId: patientData.caseId,
            docId: patientData.docId,
            videoURL: patientData.videoURL,

            patient: {
              patientId: patientData.patientId,
              name: patientData.patientName,
              gender: patientData.gender,
              age: patientData.age,
              issue: patientData.issue,
            },
            vitals: patientData.vitals,
            reports: patientData.reports,
            prescriptionVM: patientData.prescriptionVM,
            observation: patientData.observation ? patientData.observation : "",
            issue: patientData.issue,
            patientMedicalHistory: {
              patientName: patientData.patientName,
              habits: patientData.habits,
              preExistingConditions: patientData.preExistingConditions,
              drugHistory: patientData.drugHistory,
              drugAllergy: patientData.drugAllergy,
              parentalHistory: patientData.parentalHistory,
              occupation: patientData.occupation,
            },
          },

          printPrescription: patientData,
        };
      });

      setPdetails(transformedPatients[0]);
      console.log({ transformedPatients: transformedPatients });
    }
  };

  useEffect(() => {
    getPDetails();
  }, []);

  console.log({ data });
  return (
    <Fragment>
      <Box component={"iframe"} sx={{ width: 0, height: 0, display: "none" }}>
        <Box
          ref={componentRef}
          sx={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }}
        >
          <PrintReportComponent patientDetails={pDetails} />
        </Box>
      </Box>
      <Box sx={{ height: "44vh" }}>
        <Grid container sx={{ p: 0, m: 0 }}>
          <Grid item lg={12}>
            <img width="100%" src={PlaceHolder} />
            <Box
              sx={{
                position: "relative",
                top: "-70px",
                background: "rgba(64, 64, 64, 0.95)",
                display: "flex",
                alignItems: "center",
                py: 1,
              }}
            >
              <Stack
                sx={{ width: "100%", px: 2 }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: 16,
                      color: "#FFFFFF",
                    }}
                  >
                    Prescription
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: 16,
                      color: "#FFFFFF",
                    }}
                  >
                    Date Created: 01-01-2023
                  </Typography>
                </Stack>
                <Box sx={{ cursor: "pointer" }} onClick={() => handlePrint()}>
                  <img height="36" width="36" src={Morehorizontal} />
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default Prescription;
