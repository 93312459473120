import React, { useState } from "react";
import Button from "@mui/material/Button";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
import { IconButton } from "@mui/material";

const ButtonReadMore = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const buttonStyle = {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    color: isHovered ? "#0463FA" : "#FFFFFF",
    borderRadius: isHovered ? "10px" : "50%",
    transition: "background-color 0.3s, color 0.3s, border-radius 0.3s",
  };

  const iconStyle = {
    marginRight: isHovered ? "8px" : 0,
    color: "0463FA",
  };

  return (
    <IconButton onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={buttonStyle}>
      <ControlPointRoundedIcon style={iconStyle} />
      {isHovered && <span style={{ fontSize: "14px", fontWeight: "500" }}>Read More</span>}
    </IconButton>
  );
};

export default ButtonReadMore;
