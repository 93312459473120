import { Fragment, useState } from "react";
import { CssBaseline } from "@mui/material";
import { CorprateContext } from "../../common/context/usercontext";
import NavBarAfterLoginODNew from "../global/headers/navBarAfterLoginODNew";

const CorporateHomeIndex = ({ isMasked = JSON.parse(localStorage.getItem("MASKEMPLOYEES")) }) => {
  const [isMaskEnabled, setIsMaskEnabled] = useState(isMasked);
  return (
    <Fragment>
      <CorprateContext.Provider
        value={{ isMaskEnabled: isMaskEnabled, setIsMaskEnabled: setIsMaskEnabled }}>
        <CssBaseline />
        <NavBarAfterLoginODNew />
      </CorprateContext.Provider>
    </Fragment>
  );
};

export default CorporateHomeIndex;
