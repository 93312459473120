import React from "react";
import {
  Container,
  Typography,
  Grid,
  Link,
  Button,
  InputBase,
  IconButton,
  Box,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SendIcon from "@mui/icons-material/Send";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const LandingFooter = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#1B2C51",
        color: "#FFFFFF",
        paddingBlock: "40px",
        paddingInline: "30px",
      }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item lg={3} md={6} xs={12}>
            <Typography variant="h5" gutterBottom>
              Address
            </Typography>
            <Box sx={{ display: "flex", marginBlock: "10px" }}>
              <LocationOnIcon sx={{ marginRight: "10px" }} />
              <Typography sx={{ color: "#FFFFFF" }}>Indore, Madhya Pradesh</Typography>
            </Box>
            <Box sx={{ display: "flex", marginBottom: "10px" }}>
              <PhoneIcon sx={{ marginRight: "10px" }} />
              <Typography sx={{ color: "#FFFFFF" }}>1800 8890 189</Typography>
            </Box>
            <Box sx={{ display: "flex", marginBottom: "10px" }}>
              <MailOutlineIcon sx={{ marginRight: "10px" }} />
              <Typography sx={{ color: "#FFFFFF" }}>Support@Uno.Care</Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <IconButton
                sx={{ cursor: "pointer", display: "flex", ...styles.iconButton }}
                underline="none"
                href="https://www.facebook.com/profile.php?id=100088725383722&mibextid=LQQJ4d"
                target={"_blank"}>
                <FacebookIcon sx={{ color: "#0463FA" }} />
              </IconButton>
              <IconButton sx={styles.iconButton}>
                <TwitterIcon sx={{ color: "#0463FA" }} />
              </IconButton>
              <IconButton
                sx={{ cursor: "pointer", display: "flex", ...styles.iconButton }}
                underline="none"
                href="https://instagram.com/uno.care?igshid=YmMyMTA2M2Y="
                target={"_blank"}>
                <InstagramIcon sx={{ color: "#0463FA" }} />
              </IconButton>
              <IconButton sx={styles.iconButton}>
                <LinkedInIcon sx={{ color: "#0463FA" }} />
              </IconButton>
            </Box>
          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            <Typography variant="h5" gutterBottom>
              Services
            </Typography>
            <Box sx={{ display: "block", marginBlock: "10px" }}>
              <Typography sx={{ color: "#FFFFFF" }}>
                <Link sx={styles.link}>Consultation</Link>
              </Typography>

              <Typography sx={{ color: "#FFFFFF" }}>
                <Link sx={styles.link}>Lab Test</Link>
              </Typography>

              <Typography sx={{ color: "#FFFFFF" }}>
                <Link sx={styles.link}>Pharmacy</Link>
              </Typography>

              <Typography sx={{ color: "#FFFFFF" }}>
                <Link sx={styles.link}>Surgery</Link>
              </Typography>

              <Typography sx={{ color: "#FFFFFF" }}>
                <Link sx={styles.link}>Physiotherapy</Link>
              </Typography>
              <Typography sx={{ color: "#FFFFFF" }}>
                <Link sx={styles.link}>Imaging</Link>
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            <Typography variant="h5" gutterBottom>
              Quick Links
            </Typography>
            <Box sx={{ display: "block", marginBlock: "10px" }}>
              <Typography sx={{ color: "#FFFFFF" }}>
                <Link sx={styles.link}>About Us</Link>
              </Typography>

              <Typography sx={{ color: "#FFFFFF" }}>
                <Link sx={styles.link}>Locations</Link>
              </Typography>

              <Typography sx={{ color: "#FFFFFF" }}>
                <Link sx={styles.link}>Specifications</Link>
              </Typography>

              <Typography sx={{ color: "#FFFFFF" }}>
                <Link sx={styles.link}>Contact Us</Link>
              </Typography>

              <Typography sx={{ color: "#FFFFFF" }}>
                <Link href="/orglogin" sx={styles.link}>
                  Corporate Login
                </Link>
              </Typography>
              <Typography
                sx={{
                  color: "#FFFFFF",
                }}>
                <Link href="/login" sx={styles.link}>
                  Doctor Login
                </Link>
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            {/* <Typography variant="h5" gutterBottom>
              Newsletter
            </Typography>
            <Box sx={{ display: "block", marginBlock: "10px" }}>
              <Typography sx={{ color: "#FFFFFF" }}>
                Dolor amet sit justo amet elitr clita ipsum elitr est.
              </Typography>
            </Box> */}
            {/* <Box sx={{ display: "block", marginBlock: "10px" }}>
              <InputBase
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "5px",
                  padding: "5px",
                  width: "100%",
                  paddingRight: "10px",
                }}
                placeholder="Your email"
                inputProps={{ "aria-label": "Your email" }}
                endAdornment={
                  <IconButton edge="end" aria-label="email">
                    <SendIcon />
                  </IconButton>
                }
              />
            </Box> */}
            <Box>
              <Typography sx={{ color: "#FFFFFF" }}>
                <Link href="/terms" sx={styles.link}>
                  Terms & condition
                </Link>
              </Typography>
              <Typography sx={{ color: "#FFFFFF" }}>
                <Link href="/privacy" sx={styles.link}>
                  Privacy Policy
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            borderTop: "1px solid rgba(256, 256, 256, .1)",
            marginBlock: "10px",
            display: {
              xs: "block",
              sm: "block",
              md: "block",
              lg: "flex",
              xl: "flex",
            },
            justifyContent: "space-between",
          }}>
          <Typography
            sx={{
              color: "#FFFFFF",
              textAlign: { xs: "center", sm: "center", md: "center" },
              marginBlock: "10px",
            }}>
            © Uno.care, All Right Reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default LandingFooter;

const styles = {
  link: {
    textDecoration: "none",
    color: "#FFFFFF",
    ":hover": {
      color: "#0463FA",
      fontSize: "110%", // Increase font size on hover
      transform: "translateX(-5px)", // Slide to the left on hover
      transition: "all 0.2s", // Add a smooth transition effect
    },
  },
  iconButton: {
    backgroundColor: "#FFFFFF",
    marginRight: "5px",
    "&:hover": {
      backgroundColor: "#0463FA",
    },
    "&:hover .MuiSvgIcon-root": {
      color: "#FFFFFF", // White icon color on hover
    },
  },
};
