import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useState } from "react";

const employmentTypeOptions = [
  { label: "ALL", value: "ALL" },
  { label: "OFFICER", value: "ONROLL" },
  { label: "WORKER", value: "CONTRACTOR" },
];

const EmploymentTypeMahindra = ({
  selectedEmploymentType,
  setSelectedEmploymentType,
}) => {
  const handleChange = (event) => {
    setSelectedEmploymentType(event.target.value);
  };

  return (
    <Box>
      <FormControl
        fullWidth
        size="small"
        sx={{
          width: 110,
          "& fieldset": { border: "none" },
        }}
      >
        <InputLabel
          sx={{
            fontWeight: "500",
            fontSize: 13,
            color: "#404040",
            "&.Mui-focused": {
              color: "#404040",
            },
          }}
        >
          Select Employment Type
        </InputLabel>
        <Select
          sx={{ fontWeight: "500", fontSize: 13, color: "#404040" }}
          value={selectedEmploymentType} // Use "" instead of null
          label="Select Employment Type"
          onChange={handleChange}
        >
          {employmentTypeOptions.map((it, index) => (
            <MenuItem value={it?.value} key={index}>
              {it?.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
export default EmploymentTypeMahindra;
