import { Container, Grid } from "@mui/material";
import {
  CustomHeadingChipCenter,
  CustomHeadingSubTitleCenter,
} from "../../global/headings/customHeadings";
import ShowCards from "./showCards";

const LandingService = () => {
  return (
    <Container>
      <Grid container>
        <Grid item lg={12}>
          <CustomHeadingChipCenter>Services</CustomHeadingChipCenter>
        </Grid>
        <Grid item lg={12}>
          <CustomHeadingSubTitleCenter>Services We Offer</CustomHeadingSubTitleCenter>
        </Grid>
        <Grid item lg={12}>
          <ShowCards />
        </Grid>
      </Grid>
    </Container>
  );
};

export default LandingService;
