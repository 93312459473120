import { Box, Divider, Grid, Stack, Typography } from "@mui/material";

const PrintChiefComplaint = (props) => {
  const chiefComplaintInfo = props.chiefComplaintInfo;
  return (
    <Grid container sx={{ marginTop: "10px" }}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography
          sx={{
            marginTop: "20px",
            fontWeight: 700,
            lineHeight: "13px",
            color: "#404040",
          }}
        >
          CHIEF COMPLAINT
        </Typography>
        <Typography
         
          sx={{ marginTop: "10px", borderBottom: "1px solid #000000" }}
        >
          {chiefComplaintInfo}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PrintChiefComplaint;
