import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Rating,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";

const ClientsTestimonialCard = ({ data }) => {
  const [showHoverEffect, setShowHoverEffect] = useState(true);
  const [value, setValue] = useState(5);

  return (
    <Box
      onPointerEnter={() => setShowHoverEffect(false)}
      onPointerLeave={() => setShowHoverEffect(true)}
      sx={{
        marginBlock: "5px",
        maxWidth: 345,

        transition: "box-shadow 0.3s ease",
        boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.1)",
        "&:hover": {
          boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.1)",
          transform: "translateY(-5px)",
        },
      }}>
      <Card
        sx={{
          // backgroundColor: "#127DDD",

          backgroundColor: "#0463FA",
        }}>
        {/* <CardMedia sx={{ height: 140 }} image={data?.src} title="green iguana" /> */}
        <CardContent sx={{ height: 150, padding: "20px" }}>
          <Typography variant="body2" sx={{ color: "#FFFFFF", fontSize: "15px" }}>
            {data?.text}
          </Typography>
        </CardContent>
        <PersonIcon style={{ color: "#FFFFFF" }} />
        <Box sx={{ backgroundColor: "#FFFFFF", padding: "10px" }}>
          <Rating
            readOnly
            value={5}
            sx={{
              "& .MuiRating-iconFilled": {
                color: "#FAAF00",
              },
            }}
          />

          <Typography variant="body2" sx={{ color: "#000000" }}>
            Client Testimonial
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

export default ClientsTestimonialCard;
