import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Tab } from "@mui/material";
import React, { Fragment, useState } from "react";
import OhcInjuryRecords from "./comps/ohcInjuryRecords";
import OhcOpdRecords from "./comps/ohcOpdRecords";
import CustomTitles from "../../../global/titles/customTitles";

const OhcRecordsMain = () => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Fragment>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="OHC Records" />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ width: "100%" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  variant="scrollable"
                  scrollButtons="auto"
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="OPD Cases" value="1" />

                  <Tab label="Injury Cases" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ p: 0 }}>
                <OhcOpdRecords />
              </TabPanel>

              <TabPanel value="2" sx={{ p: 0 }}>
                <OhcInjuryRecords />
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default OhcRecordsMain;
