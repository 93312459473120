import {
  Autocomplete,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
import { CustomIosSwitch } from "./customIosSwitch";
import { useFileUpload } from "use-file-upload";
import { format } from "date-fns";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import GlobalDateLayout from "../../../../../../../assets/globalDateLayout/globalDateLayout";

const CustomTextFieldsOpd = ({
  item,
  formValues,
  setFormValues,
  required,
  asterickColor,
}) => {
  const [files, selectFiles] = useFileUpload();

  const handleUpload = () => {
    selectFiles({ multiple: true }, (files) => {
      const propertyName = item.property;
      const currentFiles = formValues[propertyName] || [];

      const updatedSelectedFiles = [...currentFiles];

      files.forEach(({ source, name, size, file }) => {
        updatedSelectedFiles.push({ source, name, size, file });
      });
      setFormValues({ ...formValues, [propertyName]: updatedSelectedFiles });
    });
  };

  if (item.type === "number") {
    return (
      <Box>
        <Stack>
          {/* <Typography
            sx={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: 13,
              lineHeightt: 17,
              color: "#000",
              textTransform: "capitalize",
            }}
          >
            {item.title}
            {required && <span style={{ color: asterickColor }}> *</span>}
          </Typography> */}

          <TextField
            variant="outlined"
            size="small"
            sx={{
              "& .MuiFormLabel-asterisk": {
                color: asterickColor,
              },
            }}
            label={item.title}
            required={required}
            placeholder={`Enter ${item.title}`}
            value={formValues[item.property] || ""}
            onChange={(e) => {
              if (
                !isNaN(e.target.value) &&
                e.target.value.length < item.length
              ) {
                let newFormValues = { ...formValues };
                newFormValues[item.property] = e.target.value;
                setFormValues(newFormValues);
              }
            }}
          />
        </Stack>
      </Box>
    );
  }

  if (item.type === "gender") {
    return (
      <Box>
        <Stack>
          {/* <Typography
            sx={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: 13,
              lineHeightt: 17,
              color: "#000",
              textTransform: "capitalize",
            }}
          >
            {item.title}
            {required && <span style={{ color: asterickColor }}> *</span>}
          </Typography> */}

          <Autocomplete
            fullWidth
            value={formValues[item.property] || ""}
            onChange={(event, newValue) => {
              let newFormValues = { ...formValues };
              newFormValues[item.property] = newValue;
              setFormValues(newFormValues);
            }}
            sx={{
              height: 10,
              mt: 0,
              fontSize: 10,
            }}
            size="small"
            disablePortal
            options={["MALE", "FEMALE", "DONOTDISCLOSE", "OTHER"]}
            ListboxProps={{ sx: { fontSize: 11.5 } }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Gender"
                label={item.title}
                size="small"
                sx={{
                  "& .MuiFormLabel-asterisk": {
                    color: asterickColor,
                  },
                }}
                required={required}
              />
            )}
          />
        </Stack>
      </Box>
    );
  }

  if (item.type === "date") {
    return (
      <Box>
        <Stack>
          {/* <Typography
            sx={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: 13,
              lineHeight: "17px",

              color: "#000",
              textTransform: "capitalize",
            }}
          >
            {item.title}
            {required && <span style={{ color: asterickColor }}> *</span>}
          </Typography> */}
          {/* <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disablePast={true}
                label=""
                openTo="day"
                views={["year", "month", "day"]}
                value={formValues[item.property] || null}
                onChange={(date) => {
                  setFormValues({
                    ...formValues,
                    [item.property]: format(date, "yyyy-MM-dd"),
                  });
                }}
                format="MMMM dd, yyyy"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      "& .MuiFormLabel-asterisk": {
                        color: asterickColor,
                      },
                    }}
                    label={item.title}
                  />
                )}
              />
            </LocalizationProvider>
          </Box> */}

          <GlobalDateLayout
            label={item.title}
            initialDate={formValues?.[item.property] || null}
            formValues={formValues}
            setFormValues={setFormValues}
            property={item.property}
          />
        </Stack>
      </Box>
    );
  }

  if (item.type === "upload") {
    return (
      <Box>
        <Stack>
          {/* <Typography
            sx={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: 13,
              lineHeightt: 17,
              color: "#000",
              textTransform: "capitalize",
            }}
          >
            {item.title}
          </Typography> */}
          <Button
            sx={{
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "15px",
              color: "#FFFFFF",
              textTransform: "capitalize",
            }}
            variant="contained"
            startIcon={<AttachFileIcon style={{ color: "#FFFFFF" }} />}
            onClick={handleUpload}
          >
            Upload
          </Button>
        </Stack>
      </Box>
    );
  }
  if (item.type === "toggle") {
    return (
      <Box>
        <Stack>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: 13,
              color: "#000",
              textTransform: "capitalize",
            }}
          >
            {item.title}
          </Typography>
          <CustomIosSwitch
            property={item.property}
            formValues={formValues}
            setFormValues={setFormValues}
            disabled={item.disabled}
          />
        </Stack>
      </Box>
    );
  }

  return (
    <Box>
      <Stack>
        {/* <Typography
          sx={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: 13,
            lineHeightt: 17,
            color: "#000",
            textTransform: "capitalize",
          }}
        >
          {item.title}
          {required && <span style={{ color: asterickColor }}> *</span>}
        </Typography> */}

        <TextField
          variant="outlined"
          size="small"
          placeholder={`Enter ${item.title}`}
          value={formValues[item.property] || ""}
          onChange={(e) => {
            let newFormValues = { ...formValues };
            newFormValues[item.property] = e.target.value;
            setFormValues(newFormValues);
          }}
          sx={{
            "& .MuiFormLabel-asterisk": {
              color: asterickColor,
            },
          }}
          label={item.title}
        />
      </Stack>
    </Box>
  );
};

export default CustomTextFieldsOpd;
