import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Fragment } from "react";
import callUs from "../../../../../assets/images/callUs.png";
import mail from "../../../../../assets/images/mail.png";
import instagram from "../../../../../assets/images/instagram.png";
import facebook from "../../../../../assets/images/facebook.png";
import location from "../../../../../assets/images/location.png";

const SocialsMobile = () => {
  return (
    <Fragment>
      <Box
        sx={{
          position: "relative",
          marginTop: "40px",
          borderRadius: "20px",
          background: "#FFF",
          marginBlock: "30px",
        }}>
        <Grid
          container
          spacing={2}
          sx={{ paddingBlock: "20px", justifyContent: "space-between", paddingInline: "20px" }}>
          <Grid item xs={6} lg={6} sx={{ display: "flex" }}>
            <Box component="img" src={callUs} style={{ height: "46px", width: "46px" }} />
            <Box sx={{ marginLeft: "10px" }}>
              <Typography
                sx={{
                  color: "#404040",
                  textAlign: "justify",
                  fontSize: "13px",
                  fontWeight: "700",
                  lineHeight: "normal",
                  textTransform: "capitalize",
                }}>
                Contact Number
              </Typography>
              <Typography
                sx={{
                  color: "#127DDD",
                  textAlign: "justify",
                  fontSize: "13px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  textTransform: "capitalize",
                }}>
                1800-180-200-3014
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} lg={6} sx={{ display: "flex" }}>
            <Box component="img" src={mail} style={{ height: "46px", width: "46px" }} />
            <Box sx={{ marginLeft: "10px" }}>
              <Typography
                sx={{
                  color: "#404040",
                  textAlign: "justify",
                  fontSize: "13px",
                  fontWeight: "700",
                  lineHeight: "normal",
                  textTransform: "capitalize",
                  marginBlock: "7px",
                }}>
                Email Address
              </Typography>
              <Typography
                sx={{
                  color: "#127DDD",
                  textAlign: "justify",
                  fontSize: "13px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  textTransform: "capitalize",
                }}>
                uno.care@support
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} lg={6} sx={{ display: "flex" }}>
            <Box component="img" src={instagram} style={{ height: "46px", width: "46px" }} />
            <Box sx={{ marginLeft: "10px" }}>
              <Typography
                sx={{
                  color: "#404040",
                  textAlign: "justify",
                  fontSize: "13px",
                  fontWeight: "700",
                  lineHeight: "normal",
                  textTransform: "capitalize",
                  marginBlock: "7px",
                }}>
                Instagram Handle
              </Typography>
              <Typography
                sx={{
                  color: "#127DDD",
                  textAlign: "justify",
                  fontSize: "13px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  textTransform: "capitalize",
                }}>
                @uno.care
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} lg={6} sx={{ display: "flex" }}>
            <Box component="img" src={facebook} style={{ height: "46px", width: "46px" }} />
            <Box sx={{ marginLeft: "10px" }}>
              <Typography
                sx={{
                  color: "#404040",
                  textAlign: "justify",
                  fontSize: "13px",
                  fontWeight: "700",
                  lineHeight: "normal",
                  textTransform: "capitalize",
                  marginBlock: "7px",
                }}>
                Facebook Handle
              </Typography>
              <Typography
                sx={{
                  color: "#127DDD",
                  textAlign: "justify",
                  fontSize: "13px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  textTransform: "capitalize",
                }}>
                uno.care
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} lg={6} sx={{ display: "flex" }}>
            <Box component="img" src={location} style={{ height: "46px", width: "46px" }} />
            <Box sx={{ marginLeft: "10px" }}>
              <Typography
                sx={{
                  color: "#404040",
                  textAlign: "justify",
                  fontSize: "13px",
                  fontWeight: "700",
                  lineHeight: "normal",
                  textTransform: "capitalize",
                  marginBlock: "7px",
                }}>
                Based In
              </Typography>
              <Typography
                sx={{
                  color: "#127DDD",

                  fontSize: "13px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  textTransform: "capitalize",
                }}>
                Indore, Madhya Pradesh
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default SocialsMobile;
