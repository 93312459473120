import { Box, Grid, Stack } from "@mui/material";
import EChokitsalayaLogo from "../../logo.svg";
import UnocareLogo from "../../assets/images/UnocareLogo.png";
import logoNew from "../../landingPageNew/assets/images/logoNew.png";

const LogoMain = ({ height, width }) => {
  return (
    <Box>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Stack direction={"row"} alignItems="center" sx={{ ml: 0, paddingBlock: "5px" }}>
            <img
              src={logoNew}
              alt="logo"
              height={height ? height : "70"}
              width={width ? width : "100%"}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LogoMain;
