import { Fragment, useEffect, useState } from "react";
import HealthRecordDataGridNew from "../../../../global/customDatagrids/healthRecordDataGridNew";
import { Autocomplete, Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { fetchEmployeeList, getDepartments } from "../../../../global/services/corporateServices";
import SearchEmployeeAutocomplete from "../../../../global/components/searchEmployeeAutocomplete";
import TableLegend from "../../../../global/components/tableLegend";
import DownloadCSV from "../../../../global/components/downloadCSV";
import MaskingButton from "../../../../global/components/maskingButton";

const statusList = [
  { name: "ALL", value: null },
  { name: "COMPLETED", value: "COMPLETED" },
  { name: "PENDING", value: "PENDING" },
];

const PEHealthRecords = ({ corpId = localStorage.getItem("CORPID"), config }) => {
  const [apiRef, setApiRef] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectDepartment, setSelectDepartment] = useState(null);
  const [selectStatus, setSelectStatus] = useState(null);
  const [departmentList, setDepartmentList] = useState([]);

  const [employeeList, setEmployeeList] = useState([]);
  const [employeeListStatic, setEmployeeListStatic] = useState([]);

  const exportCSV = () => {
    apiRef.current.exportDataAsCsv();
  };

  const clearSearch = () => {
    setSearchText("");
    setEmployeeList(employeeListStatic);
    setSelectDepartment(null);
    setSelectStatus(null);
  };

  useEffect(() => {
    getDepartments(corpId, setDepartmentList);
    fetchEmployeeList(corpId, setEmployeeList, setEmployeeListStatic);
  }, []);

  useEffect(() => {
    setEmployeeList(
      employeeListStatic.filter(
        (item) =>
          (selectDepartment ? item.department === selectDepartment : item) &&
          (selectStatus?.value ? item.status === selectStatus?.value : item)
      )
    );
  }, [selectDepartment, selectStatus]);

  return (
    <Fragment>
      <Box>
        <Grid container rowSpacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <Box
                    sx={{
                      boxSizing: "border-box",
                      background: "#FFFFFF",
                      border: "0.5px solid #979797",
                      borderRadius: 5,
                      px: 3,
                      py: 0.5,
                    }}>
                    <Stack
                      direction="row"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center">
                      <Button
                        size="small"
                        onClick={() => {
                          setEmployeeList(employeeListStatic);
                          setSelectDepartment(null);
                          setSelectStatus(null);
                        }}>
                        <Typography
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: 13,
                            color: "#404040",
                            textTransform: "capitalize",
                          }}>
                          Show All
                        </Typography>
                      </Button>
                      <Box
                        sx={{
                          width: 0,
                          height: 30,
                          border: "0.5px solid #6B6B6B",
                          //transform: "rotate(90deg)",
                        }}
                      />
                      <Autocomplete
                        value={selectDepartment}
                        onChange={(event, newValue) => {
                          setSelectDepartment(newValue);
                          // console.log({ newValue });
                        }}
                        sx={{ width: 600 }}
                        size="small"
                        fullWidth
                        disablePortal
                        options={departmentList}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ "& fieldset": { border: "none" } }}
                            label="Select Department"
                            InputLabelProps={{
                              style: {
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: "500",
                                fontSize: 13,
                                color: "#404040",
                              },
                            }}
                          />
                        )}
                      />

                      <Box
                        sx={{
                          width: 0,
                          height: 30,
                          border: "0.5px solid #6B6B6B",
                          //transform: "rotate(90deg)",
                        }}
                      />

                      <Autocomplete
                        value={selectStatus}
                        onChange={(event, newValue) => {
                          setSelectStatus(newValue);
                        }}
                        sx={{ width: 600 }}
                        getOptionLabel={(item) => item.name}
                        size="small"
                        fullWidth
                        disablePortal
                        options={statusList}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ "& fieldset": { border: "none" } }}
                            label="Select Status"
                            InputLabelProps={{
                              style: {
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: "500",
                                fontSize: 13,
                                color: "#404040",
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <SearchEmployeeAutocomplete
                    employees={employeeListStatic}
                    employeeList={employeeList}
                    setEmployeeList={setEmployeeList}
                    clearSearch={clearSearch}
                    searchTerm={searchText}
                    setSearchTerm={setSearchText}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} display="flex" justifyContent="flex-end">
                  <Stack direction="row" spacing={1}>
                    <DownloadCSV exportCSV={exportCSV} type="ICON" />

                    <MaskingButton type="ICON" />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                  <TableLegend />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <HealthRecordDataGridNew
              setApiRef={setApiRef}
              employeeList={employeeList}
              corpId={corpId}
              config={config}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default PEHealthRecords;
