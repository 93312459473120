import { Avatar, Box, Button, Card, CardActions, CardContent, Grid } from "@mui/material";
import { CustomHeadingCenter1, CustomHeadingLeft1 } from "../../global/headings/customHeadings";
import HeartBrokenRoundedIcon from "@mui/icons-material/HeartBrokenRounded";
import { CustomParagraph1 } from "../../global/paragraphs/customParagraphs";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
import { useState } from "react";
import ButtonReadMore from "../../components/subComps/ButtonReadMore";

import ahc from "../../assets/images/ahc.png";
import dr from "../../assets/images/dr.png";
import medicine from "../../assets/images/medicine.png";
import staff from "../../assets/images/staff.png";
import ohcMgmt from "../../assets/images/ohcMgmt.png";
import ohcEquip from "../../assets/images/ohcEquip.png";
import preEmployement from "../../assets/images/preEmployement.png";
import gi from "../../assets/images/gi.png";
import healthInsurance from "../../assets/images/healthInsurance.png";
import digitisation from "../../assets/images/digitisation.png";
import csr from "../../assets/images/csr.png";

const CustomCard = ({ data }) => {
  const [showHoverEffect, setShowHoverEffect] = useState(true);

  return (
    <Box
      onPointerEnter={() => setShowHoverEffect(false)}
      variant="outlined"
      onPointerLeave={() => setShowHoverEffect(true)}
      sx={{
        height: "250px",
        padding: "10px",
        borderRadius: "23px",
        transition: "box-shadow 0.3s ease",
        "&:hover": {
          boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.1)",
          transform: "translateY(-5px)",
        },
      }}>
      <Box component={"img"} src={data?.src} style={{ height: "100%" }} />
    </Box>
  );
};
const ShowCards = () => {
  const cardData = [
    {
      src: preEmployement,
    },

    {
      src: ahc,
    },
    {
      src: ohcEquip,
    },
    {
      src: ohcMgmt,
    },
    {
      src: dr,
    },
    {
      src: staff,
    },
    {
      src: gi,
    },
    {
      src: healthInsurance,
    },
    {
      src: digitisation,
    },

    {
      src: medicine,
    },
    {
      src: csr,
    },
  ];
  return (
    <Grid
      container
      spacing={2}
      sx={{ marginBlock: "10px", justifyContent: "center", alignItems: "center" }}>
      {cardData.map((item, index) => (
        <Grid item lg={2} key={index}>
          <CustomCard data={item} />
        </Grid>
      ))}
    </Grid>
  );
};
export default ShowCards;
