import { Box } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const CustomizedDot = (props) => {
  const { cx, cy, stroke, payload, value } = props;

  return (
    <svg
      x={cx - 15}
      y={cy - 15}
      width={30}
      height={30}
      fill="green"
      //viewBox="0 0 1024 1024"
      //width="64px"
      //height="64px"
      viewBox="-72 -72 216.00 216.00"
      id="emoji"
      xmlns="http://www.w3.org/2000/svg"
      //fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <g id="color">
          <path
            fill="#ea5a47"
            d="M59.0349,60h-46.07A.9679.9679,0,0,1,12,59.0349v-46.07A.9679.9679,0,0,1,12.9651,12h46.07A.9679.9679,0,0,1,60,12.9651v46.07A.9679.9679,0,0,1,59.0349,60Z"></path>{" "}
        </g>
        <g id="line">
          <path
            fill="none"
            stroke="#000000"
            strokeLinejoin="round"
            strokeWidth="0.00072"
            d="M59.0349,60h-46.07A.9679.9679,0,0,1,12,59.0349v-46.07A.9679.9679,0,0,1,12.9651,12h46.07A.9679.9679,0,0,1,60,12.9651v46.07A.9679.9679,0,0,1,59.0349,60Z"></path>{" "}
        </g>
      </g>
    </svg>
  );
};
const LineChartComponent = ({ data, graphType, unit }) => {
  return (
    <Box sx={{ height: 300, width: "100%", border: 1, borderRadius: 10, borderColor: "divider" }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={"100%"}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" padding={{ left: 35, right: 35 }} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey={graphType}
            stroke="#8884d8"
            activeDot={{ r: 8 }}
            name={unit}
            dot={<CustomizedDot />}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default LineChartComponent;
