import { Box, Grid, Stack, Typography } from "@mui/material";
import Expired from "../../../../assets/images/expired.png";
import Check from "../../../../assets/images/check.png";
import Workinprogress from "../../../../assets/images/workinprogress.png";
import Calendar from "../../../../assets/images/calendar.png";
import Arrowdown from "../../../../assets/images/arrowdown.png";
import { getFormattedDDMonthYYYY } from "../../../../../assets/utils";

const DashboardStats = ({ totalStats, hideCalendar, shaktiPumpCheck }) => {
  return (
    <Grid container columnSpacing={2} alignItems="center" rowSpacing={1}>
      <Grid item xs={9} sm={9} md={4} lg={4}>
        <Box
          sx={{
            boxSizing: "border-box",
            background: "#FFFFFF",
            border: "0.5px solid #8A8A8A",
            borderRadius: 5,
            px: 2,
            py: 1,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack ction="column" justifyContent="space-between">
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: 13,
                  lineHeightt: 16,
                  color: "#8A8A8A",
                }}
              >
                Total Pending
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: 20,
                  lineHeightt: 27,
                  color: "#FC1C5C",
                }}
              >
                {shaktiPumpCheck
                  ? 0
                  : (
                      totalStats?.total -
                      totalStats?.completed -
                      totalStats?.inProgress
                    ).toString()}
              </Typography>
            </Stack>
            <Box>
              <Box
                component="img"
                alt="Expired"
                src={Expired}
                sx={{ height: 50, width: 50 }}
              />
            </Box>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={9} sm={9} md={4} lg={4}>
        <Box
          sx={{
            boxSizing: "border-box",
            background: "#FFFFFF",
            border: "0.5px solid #8A8A8A",
            borderRadius: 5,
            px: 2,
            py: 1,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack ction="column" justifyContent="space-between">
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: 13,
                  lineHeightt: 16,
                  color: "#8A8A8A",
                }}
              >
                Total In Progress
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: 20,
                  lineHeightt: 20,
                  color: "#FF9F00",
                }}
              >
                {totalStats?.inProgress}
              </Typography>
            </Stack>
            <Box>
              <Box
                component="img"
                alt="Expired"
                src={Workinprogress}
                sx={{ height: 50, width: 50 }}
              />
            </Box>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={9} sm={9} md={4} lg={4}>
        <Box
          sx={{
            boxSizing: "border-box",
            background: "#FFFFFF",
            border: "0.5px solid #8A8A8A",
            borderRadius: 5,
            px: 2,
            py: 1,
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack ction="column" justifyContent="space-between">
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: 13,
                  lineHeightt: 16,
                  color: "#8A8A8A",
                }}
              >
                Total Completed
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: 20,
                  lineHeightt: 27,
                  color: "#20945C",
                }}
              >
                {totalStats?.completed}
              </Typography>
            </Stack>
            <Box>
              <Box
                component="img"
                alt="Expired"
                src={Check}
                sx={{ height: 50, width: 50 }}
              />
            </Box>
          </Stack>
        </Box>
      </Grid>

      <Grid
        item
        xs={9}
        sm={9}
        md={6}
        lg={6}
        display={"flex"}
        justifyContent={"flex-end"}
      >
        {!hideCalendar && (
          <Box
            sx={{
              boxSizing: "border-box",
              background: "#FFFFFF",
              border: "0.5px solid #979797",
              borderRadius: 3,
              px: 2,
              py: 1.2,
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                component="img"
                alt="Expired"
                src={Calendar}
                sx={{ height: 20, width: 20 }}
              />
              <Typography>{getFormattedDDMonthYYYY(new Date())}</Typography>
              <Box
                component="img"
                alt="Expired"
                src={Arrowdown}
                sx={{ height: 12, width: 12 }}
              />
            </Stack>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default DashboardStats;
