import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import { Fragment, useRef, useState } from "react";
import ContactForm from "./contactForm";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const ContactUsDialog = ({ buttonSource }) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const firstTextFieldRef = useRef(null);

  return (
    <Fragment>
      {buttonSource === "GETQUOTE" ? (
        <Button
          onClick={handleClickOpen}
          sx={{
            borderRadius: "7px",
            border: "1px solid #3E48EE",
            color: "#3E48EE",
            backgroundColor: "#FFF",
            ":hover": {
              backgroundColor: "#FFF",
            },
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "110%",
            letterSpacing: "-0.304px",
          }}
          variant="contained">
          Get Quote
        </Button>
      ) : buttonSource === "Schedule a Meeting" ? (
        <Button
          onClick={handleClickOpen}
          style={{
            width: 261,
            height: 54,
            paddingLeft: 28,
            paddingRight: 28,
            paddingTop: 16,
            paddingBottom: 16,
            backgroundColor: "#3E48EE",
            borderRadius: 8,
          }}>
          <Typography
            style={{
              textAlign: "center",
              color: "#FFF",
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "21.6px",
            }}>
            Schedule a Meeting
          </Typography>
        </Button>
      ) : buttonSource === "Click For Demo" ? (
        <Button
          onClick={handleClickOpen}
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "19.2px",
            letterSpacing: "0.019em",
            backgroundColor: "#FFF",
            height: "40px",
            color: "#0045BA",
            ":hover": {
              backgroundColor: "#FFF",
            },
          }}>
          Click For Demo
        </Button>
      ) : buttonSource === "Schedule now" ? (
        <Button
          onClick={handleClickOpen}
          variant="contained"
          sx={{
            backgroundColor: "#F4F5F9",
            ":hover": {
              backgroundColor: "#F4F5F9",
            },
            fontWeight: "bold",
            textTransform: "none",
            height: "29px",
            padding: "6px 12px",
          }}
          endIcon={<ArrowRightAltIcon sx={{ color: "#004ECA" }} />}>
          <Typography
            sx={{
              color: "#004ECA",
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "120%",
              letterSpacing: "-0.266px",
            }}>
            Schedule now
          </Typography>
        </Button>
      ) : (
        <Button
          onClick={handleClickOpen}
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "19.2px",
            letterSpacing: "0.019em",
            backgroundColor: "#FFF",
            height: "40px",
            color: "#0045BA",
            ":hover": {
              backgroundColor: "#FFF",
            },
          }}>
          Contact Us
        </Button>
      )}

      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}>
        <DialogContent>
          <ContactForm
            firstTextFieldRef={firstTextFieldRef}
            source="DIALOG"
            handleClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default ContactUsDialog;
