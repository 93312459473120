import { Box, Grid, Typography } from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { BASE_URL } from "../../../../assets/constantsFile";
import { getData } from "../../../../services/api/getApi";
import advancedFormat from "dayjs/plugin/advancedFormat.js";
dayjs.extend(advancedFormat);

const extractFirstDate = (dateRange) => {
  if (!dateRange) {
    return null;
  }
  if (dateRange.includes(" to ")) {
    const dates = dateRange.split(" to ");
    return dates[0];
  }
  return dateRange;
};

const formatToMonthDay = (dateString) => {
  return dayjs(dateString).format("MMMM Do");
};

const sortEventsByDate = (events) => {
  return events.slice().sort((a, b) => {
    const dateA = extractFirstDate(a.date);
    const dateB = extractFirstDate(b.date);

    if (!dateA || !dateB) {
      return 0;
    }

    return new Date(dateA).getTime() - new Date(dateB).getTime();
  });
};

const Card = ({ data, highlight }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ zIndex: 999 }}>
        <img style={{ height: 80, width: 80 }} src={data.icon} alt="" />
      </Box>
      <Box
        height={60}
        display="flex"
        alignItems="center"
        sx={{
          ml: -5,
          backgroundColor: highlight ? "#EFEFFF" : "#FFFFFF",
          p: 2,
          paddingLeft: 7,
          display: "flex",
          borderRadius: "45px",
        }}
      >
        <Typography sx={styles.date}>{data.date}</Typography>
        <Typography sx={styles.day}>{data.dayName}</Typography>
      </Box>
    </Box>
  );
};

const UpcomingHealthDays = ({ selectedMonth }) => {
  const [calendarList, setCalendarList] = useState([]);
  const originalListRef = useRef([]);
  const scrollContainerRef = useRef(null);

  const getCalendarList = async () => {
    const url = BASE_URL + `org/orgconfig/list?orgConfigType=HEALTH_DAYS`;
    const result = await getData(url);
    if (result.error) {
      setCalendarList([]);
    } else {
      const temp = sortEventsByDate(result.data).map((item) => ({
        icon: item.fileUrl,
        date: extractFirstDate(item?.date)
          ? formatToMonthDay(extractFirstDate(item?.date))
          : null,
        dayName: item.name,
      }));
      setCalendarList(temp);
      originalListRef.current = temp;
    }
  };

  useEffect(() => {
    getCalendarList();
  }, []);

  const handleScroll = () => {
    const container = scrollContainerRef.current;
    if (
      container.scrollTop + container.clientHeight >=
      container.scrollHeight - 10
    ) {
      // Append the original list to the current list
      setCalendarList((prevList) => [...prevList, ...originalListRef.current]);
    }
  };

  const checkHighlight = (date) => {
    const month = dayjs(date, "MMMM D").format("MMMM");
    return month === selectedMonth.name;
  };

  // Scroll to the first occurrence of the selected month and center it
  useEffect(() => {
    const container = scrollContainerRef.current;
    const firstOccurrenceIndex = calendarList.findIndex(
      (item) => dayjs(item.date, "MMMM D").format("MMMM") === selectedMonth.name
    );

    if (firstOccurrenceIndex !== -1) {
      const element = container.children[firstOccurrenceIndex];
      const elementOffset = element.offsetTop;
      const containerHeight = container.clientHeight;
      const elementHeight = element.clientHeight;
      container.scrollTop =
        elementOffset - containerHeight / 2 + elementHeight / 2;
    }
  }, [selectedMonth, calendarList]);

  console.log({ calendarList });

  return (
    <Fragment>
      <Box
        sx={{
          marginTop: 2,
          ml: 3,
        }}
      >
        <Typography style={styles.heading}>Upcoming Health Days</Typography>
        <Grid
          container
          spacing={1}
          sx={{
            marginTop: 5,
            height: "65vh",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
          ref={scrollContainerRef}
          onScroll={handleScroll}
        >
          <Grid item lg={12}></Grid>
          {calendarList.map((item, index) => (
            <Grid item lg={12} key={index}>
              <Card data={item} highlight={checkHighlight(item.date)} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Fragment>
  );
};

export default UpcomingHealthDays;

const styles = {
  heading: {
    marginRight: "5px",
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "110%",
    letterSpacing: "-0.304px",
    marginLeft: 20,
  },
  date: {
    marginRight: "5px",
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "110%",
    letterSpacing: "-0.285px",
  },
  day: {
    marginRight: "5px",
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "110%",
    letterSpacing: "-0.285px",
  },
};
