import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { getData } from "../../../../../services/api/getApi";
import CustomAutoComplete from "../../../../global/components/customAutoComplete";

const PackageAutocomplete = ({
  corpId = localStorage.getItem("CORPID"),
  formValues,
  setFormValues,
  testType,
}) => {
  const [listOfPackage, setListOfPackage] = useState([]);

  const fetchPackages = async () => {
    const url = BASE_URL + `org/getPackageDetails/${corpId}?employmentType=${testType}`;
    const packages = await getData(url);
    if (packages.error) {
      console.log("error");
      setListOfPackage([]);
    } else {
      let data = packages.data || [];
      setListOfPackage(data?.map((value) => ({ ...value, label: value?.packageName })));
      console.log({ success321: packages.data });
    }
  };

  useEffect(() => {
    fetchPackages();
  }, [testType]);

  const [value, setValue] = useState(
    listOfPackage.find((a) => a.label === formValues?.packageName) || null
  );
  //const [inputValue, setInputValue] = useState("");

  console.log({ value });

  useEffect(() => {
    setValue(listOfPackage.find((a) => a.label === formValues?.packageName) || null);
  }, [formValues]);

  return (
    <Box>
      <Grid container>
        <Grid item lg={12} xs={12}>
          <CustomAutoComplete
            size="small"
            fullWidth
            disablePortal
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
              setFormValues({
                ...formValues,
                packageName: newValue?.label || "",
              });
            }}
            //inputValue={inputValue}
            // onInputChange={(event, newInputValue) => {
            //   setInputValue(newInputValue);
            // }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={listOfPackage}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Package*"
                sx={{
                  "& fieldset": {
                    fontSize: 11,
                    height: 41,
                    borderRadius: 3,
                  },
                }}
                InputLabelProps={{
                  style: {
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: 11,
                    color: "#404040",
                  },
                }}
              />
            )}
            sx={{
              "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                fontSize: 11,
                fontWeight: 600,
                color: "#000",
              },
            }}
            ListboxProps={{
              sx: { fontSize: 11, fontWeight: 600 },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PackageAutocomplete;
