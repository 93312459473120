import { Box, Grid } from "@mui/material";
import CustomTitles from "../../../global/titles/customTitles";
import { useEffect, useState } from "react";
import { getDataGridConfig } from "../../../global/services/corporateServices";
import EHRMain from "./comps/ehrMain";

const AHCHealthRecordMain = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [config, setConfig] = useState([]);

  useEffect(() => {
    getDataGridConfig(corpId, setConfig, "AHC_HEALTH_RECORDS_PAGE");
  }, []);
  return (
    <Box>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="AHC Health Records" />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <EHRMain config={config} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AHCHealthRecordMain;
