import { Box, Grid, Typography, Stack } from "@mui/material";
import { Fragment } from "react";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import MuiPagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { StyledDataGrid } from "../../../../global/customDatagrids/CustomStyledDatagrid";

// function CustomPagination() {
//   const apiRef = useGridApiContext();
//   const page = useGridSelector(apiRef, gridPageSelector);
//   const pageCount = useGridSelector(apiRef, gridPageCountSelector);

//   return (
//     <Stack spacing={2} display="flex"
//     justifyContent="space-between"
//     alignItems="center"
//     sx={{ flexGrow: 1 }}>
//     <Pagination
//       color="primary"
//       variant="outlined"
//       shape="rounded"
//       page={page + 1}
//       count={pageCount}
//       // @ts-expect-error
//       renderItem={(item) => (
//         <PaginationItem
//           slots={{
//             previous: () => (
//               <Box sx={{ display: 'flex', alignItems: 'center'}}>
//                 <ArrowBackIcon />
//                 <Typography sx={{ marginLeft: '10px' }}>Previous</Typography>
//               </Box>
//             ),
//             next: () => (
//               <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                 <Typography sx={{ marginRight: '10px' }}>Next</Typography>
//                 <ArrowForwardIcon />
//               </Box>
//             ),
//           }}
//           {...item}
//         />
//       )}
//       onChange={(event, value) => apiRef.current.setPage(value - 1)}
//     />
//     </Stack>
//   );
// }

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
      renderItem={(item) => (
        <PaginationItem
          type="last"
          slots={{
            previous: () => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ArrowBackIcon />
                <Typography sx={{ marginLeft: "10px" }}>Previous</Typography>
              </Box>
            ),
            next: () => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ marginRight: "10px" }}>Next</Typography>
                <ArrowForwardIcon />
              </Box>
            ),
          }}
          {...item}
        />
      )}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    border: "none",
    "& .paxton-table--header": {
      "& .MuiDataGrid-columnHeaderTitleContainer": {
        padding: 0,
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 600,
        textTransform: "uppercase",
        fontSize: "16px",
      },
    },
    "& .paxton-table--row": {
      borderBottom: "0.5px solid #C5C5D3",
      margintop: 7,
      marginBottom: 7,
      backgroundColor: "#fff",
      paddingTop: 15,
      paddingBottom: 10,
      boxSizing: "content-box",
    },
    "& .MuiDataGrid-cell": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: 12,
      lineHeightt: 15,
      color: "##383838",
      textTransform: "capitalize",
      boxSizing: "none",
      borderBottom: "none ! important",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer": {
      border: "none",
    },
    "& .MuiDataGrid-viewport": {
      maxHeight: "unset !important",
    },
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "670px !important",
    },
    //'& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {display: 'none' }
  },
}));

const UpcomingData = ({ data }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item lg={12}>
          <Box
            style={{
              height: "75vh",
              width: "100%",
              padding: "20px",
              backgroundColor: "#FFFFFF",
              border: "0.5px solid grey",
              borderRadius: "16px",
              marginBlock: "20px",
            }}>
            <Box style={{ display: "flex", height: "100%" }}>
              <Box
                sx={{
                  flexGrow: 1,
                  width: "100%",
                }}>
                <StyledDataGrid
                  sx={{ border: "none" }}
                  {...data}
                  rowHeight={40}
                  disableRowSelectionOnClick
                  className={classes.root}
                  // getRowClassName={() => "paxton-table--row"}
                  getCellClassName={() => "paxton-table--cell"}
                  slots={{
                    pagination: CustomPagination,
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default UpcomingData;
