import { Box, Grid } from "@mui/material";
import { useState } from "react";
import DisplayCharts from "./bodyStats/displayCharts";
import OldCaseTabs from "./components/oldCaseTabs";
import OldCaseSummaryTabs from "./patientOldCaseSummary/oldCaseSummaryTabs";

const OldCaseIndex = ({ patientId }) => {
  const [selectedTab, setSelectedTab] = useState("default");
  const [defaultTab, setDefaultTab] = useState("1");
  const [caseId, setCaseId] = useState();
  const [selectedVital, setSelectedVital] = useState("");

  if (selectedTab === "bodystats") {
    return (
      <Box>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <DisplayCharts
              setSelectedTab={setSelectedTab}
              setDefaultTab={setDefaultTab}
              selectedVital={selectedVital}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }

  if (selectedTab === "oldailments") {
    return (
      <Box>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <OldCaseSummaryTabs
              caseId={caseId}
              setSelectedTab={setSelectedTab}
              setDefaultTab={setDefaultTab}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }
  return (
    <Box>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <OldCaseTabs
            patientId={patientId}
            setCaseId={setCaseId}
            setSelectedTab={setSelectedTab}
            defaultTab={defaultTab}
            setSelectedVital={setSelectedVital}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OldCaseIndex;
