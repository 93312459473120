import React, { Fragment, useEffect, useState } from "react";
import CustomDataGridLayout from "../../../../../assets/customDataGridLayout/customDataGridLayout";
import CustomAutocomplete from "../../../../../assets/customAutocomplete/customAutocomplete";
import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Portal,
  Stack,
  Toolbar,
  Tooltip,
} from "@mui/material";
import Papa from "papaparse";
import ParseCSV from "../../../../../assets/parseCSV/parseCSV";
import dayjs from "dayjs";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { saveData } from "../../../../../services/api/postApi";
import { useSnackbar } from "notistack";
import { handleDownloadMasterData, handleFetchMasterData } from "../../../../services/inventory";
import { Download } from "@mui/icons-material";
import { downloadCsv, isEveryKeyEmptyExcluding } from "../../../../../assets/utils";
import Close from "@mui/icons-material/Close";

const allowedExtensions = ["csv", "xlsx"];

const columns = [
  { field: "batchCode", headerName: "Batch Code", width: 150 },
  {
    field: "batchExpiryDate",
    headerName: "Batch Expiry Date (YYYY-MM-DD)",
    width: 150,
  },
  { field: "batchRemarks", headerName: "Batch Remarks", width: 150 },
  { field: "productCode", headerName: "Product Code", width: 150 },
  {
    field: "inwardSecondaryPackageQuantity",
    headerName: "Secondary Package Quantity",
    width: 220,
  },
  {
    field: "inwardPrimaryPackageQuantity",
    headerName: "Primary Package Quantity",
    width: 220,
  },
  {
    field: "inwardBaseUnitQuantity",
    headerName: "Base Unit Quantity",
    width: 150,
  },
  { field: "customerId", headerName: "Customer ID", width: 100 },
];

const UploadMedicinesModal = ({
  corpId = localStorage.getItem("CORPID"),
  userAuthID = localStorage.getItem("ORGUSERIDAUTH"),
  open,
  handleClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [medicineList, setMedicineList] = useState([]);
  const [medicineListUpdated, setMedicineListUpdated] = useState([]);
  const [error, setError] = useState("");

  console.log({ medicineListUpdated });

  useEffect(() => {
    if (medicineList) {
      setMedicineListUpdated(
        medicineList.map((item, index) => ({
          id: index,
          batchCode: item.batchcode,
          batchExpiryDate: item["batchexpirydate(yyyy-mm-dd)"],
          batchRemarks: item.batchremarks,
          productCode: item.productcode,
          inwardSecondaryPackageQuantity: item.secondarypackagequantity,
          inwardPrimaryPackageQuantity: item.primarypackagequantity,
          inwardBaseUnitQuantity: item.baseunitquantity,
          transactionBy: userAuthID,
          transactionDate: dayjs()?.format("YYYY-MM-DD"),
        }))
      );
    }
  }, [medicineList]);

  const handleSubmit = async () => {
    const url = BASE_URL + `inventory/saveTransaction?transactionType=PROCUREMENT&corpId=${corpId}`;
    const payload = medicineListUpdated.filter((v) => v.productCode).map(({ id, ...rest }) => rest);
    const result = await saveData(url, payload);
    if (result.error) {
      enqueueSnackbar(`${result.error.response.data.message}`, {
        variant: "error",
      });
    } else {
      enqueueSnackbar("Successfully Uploaded", {
        variant: "success",
      });
      if (result?.data?.nonValidProductCodeList?.length > 0) {
        downloadCsv(
          result?.data?.nonValidProductCodeList,
          "Error_From_data_NonValidProductCodeList"
        );
      }
    }
  };

  const handleFileChange = (e) => {
    setError("");
    if (e.target.files.length) {
      const inputFile = e.target.files[0];
      const fileExtension = inputFile?.type.split("/")[1];
      console.log({ fileExtension });
      if (!allowedExtensions.includes(fileExtension)) {
        setError("Please input a csv file");
        return;
      }
      console.log({ inputFile });
      handleParse(inputFile);
    }
  };

  const handleParse = (file) => {
    console.log({ file });
    if (!file) return alert("Enter a valid file");
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, {
        header: true,
        transformHeader: (header) => header.trim()?.replaceAll(/\s/g, "").toLowerCase(),
      });
      const parsedData = csv?.data;
      const rows = Object.keys(parsedData[0]);
      const columns = Object.values(parsedData[0]);
      const res = rows.reduce((acc, e, i) => {
        return [...acc, e];
      }, []);
      console.log({ columns, res, parsedData });
      if (!res.includes("productcode")) return alert("header is missing.");
      setMedicineList(parsedData);
    };
    reader.readAsText(file);
  };

  return (
    <Fragment>
      <Portal>
        <Dialog fullWidth={true} maxWidth={false} open={open} onClose={handleClose}>
          <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
            Upload Bulk Medicines
          </DialogTitle>

          <DialogActions sx={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </DialogActions>
          <DialogContent>
            <Box sx={{ marginBottom: 10 }}>
              <Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 2 }}
                spacing={2}>
                <Grid
                  item
                  lg={9}
                  md={9}
                  sm={12}
                  xs={12}
                  display="flex"
                  gap={2}
                  sx={{ flexWrap: "wrap" }}>
                  <Tooltip title="Use this file for adding inventory">
                    <Button
                      variant="contained"
                      onClick={() => {
                        const temp = [
                          {
                            "Batch Code": "",
                            "Batch Expiry Date (YYYY-MM-DD)": "",
                            "Batch Remarks": "",
                            "Product Code": "",
                            "Secondary Package Quantity": "",
                            "Primary Package Quantity": "",
                            "Base Unit Quantity": "",
                          },
                        ];
                        downloadCsv(temp, "Sample_File");
                      }}
                      startIcon={<Download sx={{ color: "#FFF" }} />}>
                      Sample File
                    </Button>
                  </Tooltip>

                  <Button variant="contained" component="label">
                    Upload File&nbsp;
                    <input onChange={handleFileChange} id="csvInput" name="file" type="File" />
                  </Button>
                  <Button
                    variant="contained"
                    disabled={
                      medicineListUpdated.every((obj) =>
                        isEveryKeyEmptyExcluding(obj, ["id", "transactionBy", "transactionDate"])
                      )
                        ? true
                        : false
                    }
                    onClick={() => {
                      handleSubmit();
                    }}>
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <Tooltip title="Use this file for product code, reference etc">
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleDownloadMasterData();
                      }}
                      startIcon={<Download sx={{ color: "#FFF" }} />}>
                      MasterData
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
              {/* <CustomDataGridLayout
          rows={medicineListUpdated}
          columns={columns}
          dataGridHeight={"53vh"}
          density="compact"
        />
     
      <AppBar
        position="fixed"
        color="inherit"
        sx={{
          top: "auto",
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container maxWidth={false}>
          <Toolbar>
          <Grid
              container
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={2}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    handleFetchMasterData();
                  }}
                  startIcon={<Download sx={{ color: "#FFF" }} />}
                >
                  MasterData
                </Button>
                <Stack direction={{ lg: "row", xs: "column" }}>
                  <ParseCSV
                    setList={setMedicineList}
                    corpId={corpId}
                    setSavedFile={setSavedFile}
                    medicine={true}
                  />
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar> */}
            </Box>
          </DialogContent>
        </Dialog>
      </Portal>
    </Fragment>
  );
};

export default UploadMedicinesModal;
