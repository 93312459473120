import { Box } from "@mui/material";

const MyImage = (props) => {
  const urlParam = function (name, w) {
    w = w || window;
    var rx = new RegExp("[&|?]" + name + "=([^&#]+)"),
      val = w.location.search.match(rx);
    return !val ? "" : val[1];
  };

  let ImageUrl = urlParam("ImageUrl");

  console.log({ ImageUrl });
  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
      <img height="600" border="0" align="center" src={ImageUrl} alt="I" />
    </Box>
  );
};

export default MyImage;
