import { Box, Grid, Typography } from "@mui/material";
import CustomTitles from "../../../global/titles/customTitles";
import StatusTracker from "./comps/statusTracker";
import VeBhopalStatusTracker from "./comps/veBhopalStatusTracker";
import { BASE_URL } from "../../../../assets/constantsFile";
import { getData } from "../../../../services/api/getApi";
import { useEffect, useState } from "react";
import OhcStatusTrackerNew from "./comps/ohcStatusTrackerNew";

const OHCStatusTrackerMain = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [data, setData] = useState([]);

  const getChartData = async () => {
    const url = BASE_URL + `org/ohcEmployeeHealthTracker?corpId=${corpId}`;
    const res = await getData(url);

    if (res.error) {
      setData([]);
    } else {
      setData(res.data);
    }
  };

  const [injuryList, setInjuryList] = useState([]);

  const getInjuryListData = async () => {
    const url = BASE_URL + `org/injuryStatusTracker?corpId=${corpId}`;

    const result = await getData(url, "");
    if (result.error) {
      setInjuryList([]);
    } else {
      setInjuryList(result.data);
    }
  };

  useEffect(() => {
    getChartData();
    getInjuryListData();
  }, []);

  console.log({ dataabx: data, injuryList });
  return (
    <Box>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="Analytics" />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {/* {corpId === "13a4b8c6-750f-442a-8414-f5465481e5d9" ? ( */}
          <OhcStatusTrackerNew />
          {/* ) : (
            <StatusTracker />
          )} */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default OHCStatusTrackerMain;
