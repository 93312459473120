import { Box, Grid, Typography } from "@mui/material";
import ChiefComplaintComp from "./adviceComponents/chiefComplaintComp";
import AdviceComp from "./adviceComponents/adviceComp";
import PersonalVisitRequiredComp from "./adviceComponents/personalVisitRequiredComp";
import NextVisitRequiredComp from "./adviceComponents/nextVisitRequiredComp";
import PrescribeNewTestComp from "./adviceComponents/prescribeNewTestComp";
import DiagnosisComp from "./adviceComponents/diagnosisComp";
import SymptomsComp from "./adviceComponents/symptomsComp";
import { useState } from "react";

const DiagnosisIndex = (props) => {
  let patientDetails = "";

  let symptomValue = "";
  let adviceValue = "";

  let obs = "";
  let prescriptionId = null;
  let prescriptionVM = "";

  let medicines = null;

  let personalVisitNeeded = false;
  let personalAppointmentDate = "";

  let nextAppointment = false;
  let nextAppointmentDate = "";

  let chiefComplaints = "";

  if (props.patientDetails) {
    patientDetails = props.patientDetails;
    symptomValue = patientDetails.symptom;
    obs = patientDetails.observations;

    prescriptionVM = patientDetails.appointments
      ? patientDetails.appointments[0].prescriptionVM
      : null;
    prescriptionId = prescriptionVM ? prescriptionVM.prescriptionId : null;
    medicines = prescriptionVM ? prescriptionVM.medicines : null;

    adviceValue = patientDetails.advice;

    chiefComplaints = patientDetails.chiefComplaints;

    personalVisitNeeded = patientDetails.appointments
      ? patientDetails.appointments[0].personalVisitNeeded
      : false;

    nextAppointment =
      patientDetails.appointments &&
      patientDetails.appointments[0].nextAppointmentDate
        ? true
        : false;

    nextAppointmentDate =
      patientDetails.appointments &&
      patientDetails.appointments[0].nextAppointmentDate
        ? patientDetails.appointments[0].nextAppointmentDate
        : new Date();

    personalAppointmentDate =
      patientDetails.appointments &&
      patientDetails.appointments[0].personalAppointmentDate
        ? patientDetails.appointments[0].personalAppointmentDate
        : new Date();
  }
  const [reportItems, setReportItems] = useState(patientDetails.reports);
  return (
    <Box>
      <Grid rowSpacing={1} container>
        <Grid item lg={12}>
          <ChiefComplaintComp
            chiefComplaintsValue={chiefComplaints}
            appointmentId={props.appointmentId}
          />
        </Grid>
        <Grid item lg={12}>
          <SymptomsComp
            symptomValue={symptomValue}
            appointmentId={props.appointmentId}
          />
        </Grid>
        <Grid item lg={12}>
          <DiagnosisComp observationContentValue={obs} caseId={props.caseId} />
        </Grid>

        <Grid item lg={12}>
          <PrescribeNewTestComp
            patientId={props.patientId}
            appointmentId={props.appointmentId}
            caseId={props.caseId}
            reportItems={reportItems}
          />
        </Grid>

        <Grid item lg={12}>
          <AdviceComp
            adviceValue={adviceValue}
            appointmentId={props.appointmentId}
          />
        </Grid>

        <Grid item lg={12}>
          <NextVisitRequiredComp
            isNextAppointment={nextAppointment}
            appointmentId={props.appointmentId}
            nextAppointmentDate={nextAppointmentDate}
          />
        </Grid>

        <Grid item lg={12}>
          <PersonalVisitRequiredComp
            isPersonalVisitNeeded={personalVisitNeeded}
            personalAppointmentDate={personalAppointmentDate}
            appointmentId={props.appointmentId}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DiagnosisIndex;
