import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Portal,
  SvgIcon,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { useGridApiRef } from "@mui/x-data-grid";
import { Fragment, useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getUrlExtension, maskText } from "../../../assets/utils";
import HearingIcon from "@mui/icons-material/Hearing";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import {
  ECGIcon,
  PFTIcon,
  XrayIcon,
  XrayIcon1,
} from "../../../assets/customicons/customIcons";
import SummarizeIcon from "@mui/icons-material/Summarize";
import AssessmentIcon from "@mui/icons-material/Assessment";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import vaccinationImg from "../../../corporatePortal/assets/images/vaccinationImg.jpeg";
import CustomDataPanels from "./customDataPanels";
import { fetchVendorRanges } from "../services/corporateServices";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { transformData, transformDataNew } from "../actions/actions";
import SingleUpload from "../components/upload/singleUpload";
import { BASE_URL } from "../../../assets/constantsFile";
import {
  EncryptedTypography,
  EncryptedTypographyEmpId,
  EncryptedTypographyMobile,
  EncryptedTypographyName,
} from "../components/encryptedTypography";
import { PhotoViewer } from "../../../assets/photoViewer";

const checkNormal = (val, property, list) => {
  let flag = false;

  if (val && list[property]) {
    if (
      list[property][0]?.pendRange === list[property][0]?.pstartRange ||
      (!list[property][0]?.pendRange && list[property][0]?.pstartRange) ||
      (list[property][0]?.pendRange && !list[property][0]?.pstartRange)
    ) {
      flag =
        parseFloat(val) <= parseFloat(list[property][0]?.pendRange) ||
        parseFloat(val) <= parseFloat(list[property][0]?.pstartRange);
    } else {
      flag =
        parseFloat(val) <= parseFloat(list[property][0]?.pendRange) &&
        parseFloat(val) >= parseFloat(list[property][0]?.pstartRange);
    }
  }
  return flag;
};

const CustomTypography = ({ title }) => {
  return (
    <Typography
      sx={{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeightt: 17,
        color: "#A8A8BD",
        textTransform: "capitalize",
      }}
    >
      {title}
    </Typography>
  );
};

const ShowToggleData = ({ data }) => {
  return (
    <Typography
      sx={{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: 12,
        lineHeightt: 15,
        color: "#383838",
        textTransform: "capitalize",
      }}
    >
      {data ? "Yes" : data === "false" ? "No" : ""}
    </Typography>
  );
};

const ShowVitalsString = ({ data }) => {
  let value =
    data?.toLowerCase() === "a" || data?.toLowerCase() === "absent" ? (
      <Typography
        sx={{
          fontFamily: "Roboto",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: 12,
          lineHeightt: 15,
          color: "#383838",
          textTransform: "capitalize",
        }}
      >
        {data}
      </Typography>
    ) : (
      <Typography
        sx={{
          fontFamily: "Roboto",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: 12,
          lineHeightt: 15,
          color: "#FFA500",
          textTransform: "capitalize",
        }}
      >
        {data}
      </Typography>
    );
  return <Fragment>{value}</Fragment>;
};

const ShowVitalsAlphaNumeric = ({ data, property, list }) => {
  let flag = false;

  if (data) {
    if (data.toLowerCase() === "a") {
      flag = true;
    } else {
      let wordArray = data.split("-");
      if (
        parseFloat(wordArray[0]) > parseFloat(list[property][0]?.pstartRange) &&
        parseFloat(wordArray[1]) < parseFloat(list[property][0]?.pendRange)
      ) {
        flag = true;
      }
    }
  }

  let value = flag ? (
    <Typography
      sx={{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: 12,
        lineHeightt: 15,
        color: "#383838",
        textTransform: "capitalize",
      }}
    >
      {data}
    </Typography>
  ) : (
    <Typography
      sx={{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: 12,
        lineHeightt: 15,
        color: "#FFA500",
        textTransform: "capitalize",
      }}
    >
      {data}
    </Typography>
  );
  return <Fragment>{value}</Fragment>;
};

const ReportIconMap = {
  bloodTestUrl: BloodtypeIcon,
  pftUrl: PFTIcon,
  audiometryUrl: HearingIcon,
  xrayFilmUrl: XrayIcon,
  xrayUrl: XrayIcon,
  ecgUrl: ECGIcon,
  eyeTestUrl: VisibilityIcon,
  form32Url: SummarizeIcon,
  form35Url: AssessmentIcon,
  xrayUrl: XrayIcon1,
  fitnessCertificateUrl: HealthAndSafetyIcon,
  default: AssignmentIcon,
};

const getIconsByReport = (property) => {
  let data = ReportIconMap[property] || AssignmentIcon;
  return data;
};
const HealthRecordDataGridNew = ({
  setApiRef,
  employeeList,
  corpId,
  config = {},
}) => {
  const [vendorConfigList, setVendorConfigList] = useState();
  useEffect(() => {
    fetchVendorRanges(setVendorConfigList);
  }, []);

  console.log({ config, vendorConfigList });

  const columns = [
    {
      field: "empId",
      renderHeader: (params) => <CustomTypography title="Emp. ID" />,
      width: 100,
      type: "number",
      align: "left",
      headerAlign: "left",
      renderCell: (cellValues) => (
        <EncryptedTypographyEmpId text={cellValues.value} />
      ),
      display: true,
      sequence: 1,
    },
    {
      field: "name",
      renderHeader: (params) => <CustomTypography title="Name" />,
      width: 200,
      valueFormatter: (value) => `${value.name}`,
      renderCell: (cellValues) => <EncryptedTypographyName data={cellValues} />,
      display: true,
      sequence: 1,
    },

    ...config
      .filter((val) => val.fieldType === "ENCRYPTED_TEXT")
      .map((value, index) => ({
        field: value.nameId,
        align: "center",
        headerAlign: "center",
        width: value.columnWidth || 130,
        renderHeader: (params) => <CustomTypography title={value.name} />,
        renderCell: (cellValues) => (
          <EncryptedTypographyMobile text={cellValues.value} />
        ),
        display: value.access,
        sequence: value.sequence,
      })),

    ...config
      .filter((val) => val.fieldType === "TEXT")
      .map((value, index) => ({
        field: value.nameId,
        align: "center",
        headerAlign: "center",
        width: value.columnWidth || 130,
        renderHeader: (params) => <CustomTypography title={value.name} />,
        display: value.access,
        sequence: value.sequence,
      })),
    ...config
      .filter((val) => val.fieldType === "REPORT_URL")
      .map((value, index) => ({
        field: value.nameId,
        renderHeader: (params) => <CustomTypography title={value.name} />,
        width: value.columnWidth || 120,
        align: "center",
        headerAlign: "center",
        renderCell: (cellValues) => {
          return (
            <IconButton
              disabled={cellValues.value ? false : true}
              aria-label="delete"
              size="small"
              onClick={() => {
                handleClickOpen(cellValues.value);
                setSelectedImage(cellValues.value);
              }}
            >
              {cellValues.value ? (
                <SvgIcon
                  component={getIconsByReport(value.nameId)}
                  inheritViewBox
                  fontSize="inherit"
                  sx={{ color: "#127DDD" }}
                />
              ) : cellValues.value === false ? (
                <SvgIcon
                  component={getIconsByReport(value.nameId)}
                  inheritViewBox
                  fontSize="inherit"
                  sx={{ color: "#127DDD" }}
                />
              ) : (
                <SvgIcon
                  component={getIconsByReport(value.nameId)}
                  inheritViewBox
                  fontSize="inherit"
                  sx={{ color: "#000000" }}
                />
              )}
            </IconButton>
          );
        },
        display: value.access,
        sequence: value.sequence,
      })),
    ...config
      .filter((val) => val.fieldType === "TOGGLE")
      .map((value, index) => ({
        field: value.nameId,
        renderHeader: (params) => <CustomTypography title={value.name} />,
        renderCell: (cellValues) => <ShowToggleData data={cellValues.value} />,
        width: value.columnWidth || 100,
        align: "center",
        headerAlign: "center",
        display: value.access,
        sequence: value.sequence,
      })),
    ...config
      .filter((val) => val.fieldType === "VITALS_NUMERIC")
      .map((value, index) => ({
        field: value.nameId,
        align: "center",
        headerAlign: "center",
        renderHeader: (params) => <CustomTypography title={value.name} />,
        width: value.columnWidth || 140,
        display: value.access,
        sequence: value.sequence,
        cellClassName: (params) => {
          if (params.value == null) {
            return "";
          }

          return clsx("super-app", {
            abnormal: !checkNormal(
              params.value,
              value.nameId,
              vendorConfigList
            ),
            normal: checkNormal(params.value, value.nameId, vendorConfigList),
          });
        },
      })),
    ...config
      .filter((val) => val.fieldType === "VITALS_STRING")
      .map((value, index) => ({
        field: value.nameId,
        align: "center",
        headerAlign: "center",
        renderHeader: (params) => <CustomTypography title={value.name} />,
        renderCell: (cellValues) => (
          <ShowVitalsString data={cellValues.value} />
        ),
        width: value.columnWidth || 100,
        display: value.access,
        sequence: value.sequence,
      })),
    ...config
      .filter((val) => val.fieldType === "VITALS_ALPHANUMERIC")
      .map((value, index) => ({
        field: value.nameId,
        align: "center",
        headerAlign: "center",
        renderHeader: (params) => <CustomTypography title={value.name} />,
        renderCell: (cellValues) => (
          <ShowVitalsAlphaNumeric
            data={cellValues.value}
            property={value.nameId}
            list={vendorConfigList}
          />
        ),
        width: value.columnWidth || 100,
        display: value.access,
        sequence: value.sequence,
      })),
    ...config
      .filter((val) => val.fieldType === "VACCINATION")
      .map((value, index) => ({
        field: value.nameId,
        renderHeader: (params) => <CustomTypography title={value.name} />,
        width: value.columnWidth || 140,
        align: "center",
        headerAlign: "center",
        renderCell: (cellValues) => {
          return (
            <IconButton
              disabled={cellValues.value ? false : true}
              aria-label="delete"
              size="small"
              onClick={() => {
                handleClickOpen(cellValues.value);
                setSelectedImage(vaccinationImg);
              }}
            >
              {cellValues.value ? (
                <VaccinesIcon fontSize="inherit" sx={{ color: "#127DDD" }} />
              ) : (
                <VaccinesIcon fontSize="inherit" />
              )}
            </IconButton>
          );
        },
        display: value.access,
        sequence: value.sequence,
      })),
    ...config
      .filter((val) => val.fieldType === "REPORT_UPLOAD")
      .map((value, index) => ({
        field: value.nameId,
        align: "center",
        headerAlign: "center",
        renderHeader: (params) => <CustomTypography title={value.name} />,
        width: value.columnWidth || 100,
        display: value.access,
        sequence: value.sequence,
        renderCell: (cellValues) => (
          <SingleUpload
            employeeID={cellValues.value}
            title="Upload"
            url={
              BASE_URL +
              `org/upload?empId=${cellValues.value}&fileType=CONSOLIDATED_REPORT&corpId=${corpId}`
            }
          />
        ),
      })),
  ];

  const apiRef = useGridApiRef();

  useEffect(() => {
    setApiRef(apiRef);
  }, [apiRef]);

  const [open, setOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(false);

  const handleClickOpen = (url) => {
    // const ext = getUrlExtension(url).toLowerCase();
    // if (ext === "pdf") {
    //   window.open(url);
    // } else {
    //   setOpen(true);
    // }
    setOpen(true);
    setImageUrl(url);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [selectedImage, setSelectedImage] = useState("");
  return (
    <Fragment>
      {/* <Portal>
        <Dialog open={open} onClose={handleClose} maxWidth={"xl"} fullWidth={true}>
          <DialogContent>
            <Box
              sx={{ minHeight: "70vh" }}
              display="flex"
              justifyContent="center"
              alignItems="center">
              {selectedImage ? <img width="100%" src={selectedImage} /> : "No Document."}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Portal> */}
      <PhotoViewer url={imageUrl} open={open} handleClose={handleClose} />
      <Box
        style={{
          height: "63vh",
          width: "100%",
          padding: "2px",
          backgroundColor: "#FFFFFF",
          border: "0.5px solid grey",
          borderRadius: "16px",
          marginBlock: "10px",
        }}
      >
        <Box style={{ display: "flex", height: "100%" }}>
          <Box
            sx={{
              flexGrow: 1,
              width: "100%",
              "& .super-app.abnormal": {
                //backgroundColor: "rgba(157, 255, 118, 0.49)",
                color: "#FFA500",
                fontWeight: "600",
              },
              "& .super-app.normal": {
                //backgroundColor: "#d47483",
                color: "#383838",
                fontWeight: "400",
              },
            }}
          >
            <CustomDataPanels
              apiRef={apiRef}
              corpId={corpId}
              setApiRef={setApiRef}
              dataList={transformDataNew(employeeList)}
              columns={columns
                ?.filter((v) => v.display)
                ?.sort((a, b) => a.sequence - b.sequence)}
            />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default HealthRecordDataGridNew;
