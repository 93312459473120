import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setLoginToken } from "../assets/utils";

const Logout = (props) => {
  let navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("SAVEDTOKEN");
    localStorage.clear();
    navigate("/orglogin");

    console.log("logged out");
  }, []);

  return "you have succesfully logged out.";
};

export default Logout;
