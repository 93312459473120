import { Button, IconButton, Tooltip } from "@mui/material";
import { Fragment, useContext } from "react";
import { CorprateContext } from "../../../common/context/usercontext";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const MaskingButton = ({ corpRole = JSON.parse(localStorage.getItem("CORPROLE")), type }) => {
  const { isMaskEnabled, setIsMaskEnabled } = useContext(CorprateContext);

  if (type === "ICON") {
    return (
      <Fragment>
        <Tooltip title="Show/Hide encrypted text.">
          <IconButton
            color="primary"
            size="small"
            sx={{ height: 36, borderRadius: 2, border: 1 }}
            onClick={() => {
              setIsMaskEnabled(!isMaskEnabled);
              localStorage.setItem("MASKEMPLOYEES", !isMaskEnabled);
            }}>
            {isMaskEnabled ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </Tooltip>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {corpRole && (
        <Button
          variant="contained"
          sx={{ height: 36, borderRadius: 2, px: 3 }}
          onClick={() => {
            setIsMaskEnabled(!isMaskEnabled);
            localStorage.setItem("MASKEMPLOYEES", !isMaskEnabled);
          }}>
          {isMaskEnabled ? "Unmask" : "Mask"}
        </Button>
      )}
    </Fragment>
  );
};

export default MaskingButton;
