import { Fragment, useEffect, useState } from "react";
import OhcDoughnutChart from "./ohcDoughnutChart";

const TopIllnessGraph = ({ data, opdGraphData }) => {
  const [graphData, setGraphData] = useState(data || []);

  useEffect(() => {
    if (data && data.length > 9) {
      data = data.slice(0, 9);
    }

    const total = data.reduce((acc, entry) => acc + entry.value, 0);
    let tempData = data.map((value) => ({
      ...value,
      percent: parseFloat((value.value * 100) / total).toFixed(2),
    }));
    setGraphData(tempData);
  }, [data]);

  console.log({ data123123: data, opdGraphData });
  return (
    <Fragment>
      <OhcDoughnutChart
        COLORS={[
          "#bf6328",
          "#2837bf",
          "#3e4052",
          "#523e4d",
          "#c92da2",
          "#2dc949",
          "#252b26",
          "#428ba0",
          "#0e4e60",
          "#bb2020",
        ]}
        data={graphData}
        title={"Top10 Illness - OPD"}
        labelHeading={"Total"}
        disableClickable={false}
        legendAlignment="right"
        layoutAlignmnment="vertical"
        innerRadius={60}
        outerRadius={100}
        dialogData={opdGraphData?.mapOfIllnessWithEmployeeDetails}
      />
    </Fragment>
  );
};

export default TopIllnessGraph;
