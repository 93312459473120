import { useCallback, useState, useContext } from "react";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { getUserToken, getUserTokenByMobile } from "../../../services/api/apiCalls";
import jwt_decode from "jwt-decode";
import { userContext } from "../../../common/context/usercontext";
import { useNavigate } from "react-router";
import selectArrow from "../../../assets/images/svg/selectArrow.svg";
import translate from "../../../assets/images/svg/translate.svg";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import MobileComp from "./components/mobileComp";
import OTPComp from "./components/otpComp";
import { BASE_URL_AUTH } from "../../../assets/constantsFile";

const OTPLoginForm = ({ setSeverity, setMessage, setOpenNotice, setShowOTPForm }) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const contextData = useContext(userContext);
  let navigate = useNavigate();

  const getOTPHandler = async () => {
    if (mobileNumber) {
      const url = BASE_URL_AUTH + `authenticate?sendOtp=true`;
      const data = {
        username: mobileNumber,
      };

      const otpData = await getUserTokenByMobile(url, data);

      if (otpData.error) {
        setSeverity("error");
        setMessage("OTP generation faliled.");
        setOpenNotice(true);
      } else {
        console.log({ otpData: otpData.data });
        setSeverity("success");
        setMessage("OTP Send to your registered mobile number");
        setOpenNotice(true);
      }
    }
  };

  const handleSubmitOTP = async (event, number) => {
    event.preventDefault();

    const url = BASE_URL_AUTH + `authenticate?authOnOTP=true`;
    const dataObj = {
      username: mobileNumber,
      password: otp,
    };

    if (mobileNumber && otp) {
      fetchDoctorDataHandler(url, dataObj, number);
    }
  };

  const fetchDoctorDataHandler = useCallback(async (url, data, number) => {
    const user = await getUserToken(url, data);

    if (user.error) {
      console.log({ "Error while authenticating user": user.error });
    } else {
      const data = user.data;
      const token = await data.token;
      contextData.authHeader = token;
      const dData = await jwt_decode(token);
      contextData.val = dData;
      localStorage.setItem("authHeader_local", token);
      localStorage.setItem("DOCID", dData.userID);
      contextData.docId = dData.userID;
      console.log({ "Success while authenticating user": dData });

      if (dData && dData.role === "DOCTOR" && number === dData.sub) {
        setSeverity("success");
        setMessage("Login successfull");
        setOpenNotice(true);
        navigate("/patients", { replace: true });
      } else {
        setSeverity("error");
        setMessage("Login failed! Enter Correct Credentials!");
        setOpenNotice(true);
      }
    }
  }, []);

  return (
    <Box component="form" onSubmit={(e) => handleSubmitOTP(e, mobileNumber)}>
      <Container maxWidth={"xs"}>
        <Grid container rowSpacing={3}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} display="flex" justifyContent="center">
            <MobileComp mobileNumber={mobileNumber} setMobileNumber={setMobileNumber} />
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <Stack direction="row" display="flex" justifyContent="space-between" spacing={3}>
              <Button
                sx={{ borderRadius: 3 }}
                variant="contained"
                size="large"
                fullWidth
                color="primary"
                onClick={getOTPHandler}
                disabled={mobileNumber.length != 10 ? true : false}>
                Send OTP
              </Button>
              <Button
                sx={{ borderRadius: 3, color: "#000" }}
                size="large"
                fullWidth
                onClick={getOTPHandler}
                disabled={mobileNumber.length != 10 ? true : false}>
                Resend
              </Button>
            </Stack>
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12} display="flex" justifyContent="center">
            <OTPComp otp={otp} setOtp={setOtp} />
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12} display="flex" justifyContent="center">
            <Button
              size="large"
              sx={{ borderRadius: 3 }}
              variant="contained"
              fullWidth
              type="submit"
              disabled={otp.length != 6 ? true : false}>
              Login
            </Button>
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12} display="flex" justifyContent="center">
            <Typography>Or</Typography>
          </Grid>

          <Grid
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center">
            <Typography>Login Using &nbsp; </Typography>
            <Button
              disableElevation
              sx={{
                color: "#127DDD",
                fontWeight: 600,
                textTransform: "capitalize",
              }}
              onClick={() => setShowOTPForm(false)}>
              E-Mail Address
            </Button>
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12} display="flex" justifyContent="center">
            <Button
              size="large"
              variant="outlined"
              fullWidth
              sx={{ borderRadius: 3, color: "#127DDD" }}
              startIcon={<img style={{ marginRight: 80 }} src={translate} />}
              endIcon={<img style={{ marginLeft: 80 }} src={selectArrow} />}>
              Select Language
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default OTPLoginForm;
