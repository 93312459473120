import RootTemplate from "../../global/templates/rootTemplate";
import CSRHealthRecordMain from "../../modules/csr/healthRecords/csrHealthRecordMain";

const CSRHealthRecordsIndex = () => {
  return (
    <RootTemplate>
      <CSRHealthRecordMain />
    </RootTemplate>
  );
};

export default CSRHealthRecordsIndex;
