import { Box, Grid, Stack, Typography } from "@mui/material";
import EChokitsalayaLogo from "../../logo.svg";

const LogoMain = () => {
  return (
    <Box>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Stack direction={"row"} alignItems="center" sx={{ ml: 0 }}>
            <img src={EChokitsalayaLogo} alt="logo" width="70%" />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LogoMain;
