import { Alert, Button, Portal, Snackbar } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useFileUpload } from "use-file-upload";
import { uploadFile } from "../../../../services/api/postApi";

const SingleUpload = ({ title, employeeID, url }) => {
  const [file, selectFile] = useFileUpload();
  console.log({ file });

  const handleSubmitNew = (name, size, source, file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", name);
    saveImage(formData);
  };

  const saveImage = async (data) => {
    const image = await uploadFile(url, data);

    if (image.error) {
      console.log("error");
      setSeverity("error");
      setMessage("Failed to upload.!");
      setOpenNotice(true);
    } else {
      const data = image.data;
      console.log({ success: data });
      setSeverity("success");
      setMessage("Successfully uploaded");
      setOpenNotice(true);
    }
  };

  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [openNotice, setOpenNotice] = useState(false);
  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };

  return (
    <Fragment>
      <Portal>
        <Snackbar open={openNotice} autoHideDuration={6000} onClose={handleCloseNotice}>
          <Alert onClose={handleCloseNotice} severity={severity} sx={{ width: "100%" }}>
            {message}
          </Alert>
        </Snackbar>
      </Portal>

      <Fragment>
        {/* {file ? (
          <div>
            <img src={file.source} alt="preview" />
            <span> Name: {file.name} </span>
            <span> Size: {file.size} </span>
          </div>
        ) : (
          <span>No file selected</span>
        )} */}

        <Button
          size="small"
          disabled={employeeID ? false : true}
          sx={{
            //fontWeight: "400",
            fontSize: "13px",
            lineHeight: "15px",
            color: "#FFFFFF",
            //textTransform: "capitalize",
            borderRadius: 10,
          }}
          variant="contained"
          startIcon={<AttachFileIcon style={{ color: "#FFFFFF" }} />}
          onClick={() =>
            selectFile({ accept: "*" }, ({ name, size, source, file }) => {
              handleSubmitNew(name, size, source, file);
              console.log("Files Selected", {
                name,
                size,
                source,
                file,
              });
            })
          }>
          {title}
        </Button>
      </Fragment>
    </Fragment>
  );
};

export default SingleUpload;
