import { Box } from "@mui/material";
import { Fragment } from "react";
import Details from "./details";

const Index = ({ patientDetails, patient, appointmentId, caseId }) => {
  return (
    <Fragment>
      <Box>
        <Details
          patientDetails={patientDetails}
          patient={patient}
          appointmentId={appointmentId}
          caseId={caseId}
        />
      </Box>
    </Fragment>
  );
};

export default Index;
