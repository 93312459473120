import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import pharmacy from "../assets/pharmacy.png";
import surgery from "../assets/surgery.png";
import physiotherapy from "../assets/physiotherapy.png";
import { useTheme } from "@emotion/react";

const CardBox = ({ data }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Box
      sx={{
        width: isSmallScreen || isExtraSmallScreen ? 320 : 360,
        height: 335,
        border: "3px solid #FFF",
        borderRadius: "16px",
        zIndex: 1,
        position: "relative",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Box
        component={"img"}
        src={data?.icon}
        style={{
          width: 100,
          height: 100,
          position: "absolute",
          top: -40,
          left: -40,
          zIndex: 999,
          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
          borderRadius: "50%",
        }}
      />

      <Box
        sx={{
          padding: "20px",
          textAlign: "right",
          display: "flex",
          flexDirection: "column",
          height: "95%",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            color: "#000",
            fontSize: "28px",
            fontWeight: "600",
            lineHeight: "150%",
            letterSpacing: "-0.616px",
          }}
        >
          {data.title}
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontSize: "18px",
            fontWeight: "400",
            lineHeight: "27px",
          }}
        >
          {data.description}
        </Typography>
        <Button
          sx={{
            backgroundColor: "#F1F3FF",
            color: "#004ABF",
            ":hover": {
              backgroundColor: "#0042ab",
            },
            width: "150px",
            borderRadius: "5px",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "110%",
            letterSpacing: "-0.266px",
            alignSelf: "flex-end",
          }}
          variant="contained"
        >
          Know More
        </Button>
      </Box>
    </Box>
  );
};

const WhyPeoplePreferUs = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [hovered, setHovered] = useState(false);
  const [initialSlide, setInitialSlide] = useState(true);

  const handleMouseEnter = () => {
    if (initialSlide) {
      setHovered(true);
      setInitialSlide(false);
    }
  };
  const temp = [
    {
      id: 1,
      icon: physiotherapy,
      title: "Physiotherapy",
      description:
        "Provides personalized treatment plans to improve mobility, reduce pain, & enhance overall physical function, ensuring patients achieve optimal recovery and well-being.",
    },
    {
      id: 2,
      icon: surgery,
      title: "Surgery",
      description:
        "Boasts the best surgeons, renowned for their expertise, precision, and compassionate care, ensuring top-quality surgical outcomes and patient satisfaction across various specialties.",
    },
    {
      id: 3,
      icon: pharmacy,
      title: "Pharmacy",
      description:
        "Expert medical advice & personalized care, ensuring timely diagnosis & treatment. Schedule your appointment today for a comprehensive health evaluation.",
    },
  ];
  return (
    <Fragment>
      <Box
        sx={{
          height: isSmallScreen || isExtraSmallScreen ? null : "74vh",
          paddingBlock: isSmallScreen || isExtraSmallScreen ? "20px" : "45px",
          paddingLeft: isSmallScreen || isExtraSmallScreen ? null : "128px",
          paddingRight: isSmallScreen || isExtraSmallScreen ? null : "128px",
        }}
        onMouseEnter={handleMouseEnter}
      >
        <Container maxWidth={false}>
          <Slide
            timeout={
              isSmallScreen || isExtraSmallScreen
                ? { enter: 0, exit: 0 }
                : { enter: 1000, exit: 1500 }
            }
            direction="left"
            in={isSmallScreen || isExtraSmallScreen ? true : hovered}
            mountOnEnter
            unmountOnExit
          >
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ marginBlock: 4 }}
              >
                <Typography
                  style={{
                    opacity: 0.7,
                    textAlign: "center",
                    color: "#000",
                    fontSize: "24px",
                    fontWeight: "500",
                    lineHeight: "26.4px",
                  }}
                >
                  Why people prefer us
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  marginBlock: isSmallScreen || isExtraSmallScreen ? 2 : 10,
                  display: "flex",
                  flexDirection:
                    isSmallScreen || isExtraSmallScreen ? "column" : "row",
                  justifyContent: "center",
                  alignItems:
                    isSmallScreen || isExtraSmallScreen ? "center" : "initial",
                  gap: 10,
                }}
              >
                {temp.map((item, index) => (
                  <CardBox key={index} data={item} />
                ))}
              </Grid>
            </Grid>
          </Slide>
        </Container>
      </Box>
    </Fragment>
  );
};

export default WhyPeoplePreferUs;
