import RootTemplate from "../../global/templates/rootTemplate";
import AHCForm21Main from "../../modules/ahc/form21/ahcForm21Main";

const AHCForm21Index = () => {
  return (
    <RootTemplate>
      <AHCForm21Main />
    </RootTemplate>
  );
};

export default AHCForm21Index;
