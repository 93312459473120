import { FormControlLabel, Stack } from "@mui/material";
import { IOSSwitch } from "./customSwitch";

export const CustomIOSSwitch = ({ property, formValues, setFormValues, disabled }) => {
  return (
    <Stack>
      <FormControlLabel
        disabled={disabled}
        control={
          <IOSSwitch
            sx={{ m: 0.8 }}
            checked={formValues[property] || false}
            onChange={(e) => {
              let newFormValues = { ...formValues };
              newFormValues[property] = e.target.checked;
              setFormValues(newFormValues);
            }}
          />
        }
        label=""
      />
    </Stack>
  );
};
