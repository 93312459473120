import { Box, IconButton, Stack, TextField } from "@mui/material";
import { Fragment } from "react";
import SendIcon from "@mui/icons-material/Send";
import { BASE_URL } from "../../assets/constantsFile";
import { saveData } from "../../services/api/postApi";
const defaultText = "Thank you for your query. we will shorlty connect with you! 👋";

const ChatFooter = ({
  chat,
  setChat,
  chatStack,
  setChatStack,
  disableMessgaeArea,
  setDisableMessgaeArea,
  corpId = localStorage.getItem("CORPID"),
  userID = localStorage.getItem("ORGUSERIDAUTH"),
}) => {
  const _submitHandler = async (e) => {
    e.preventDefault();
    // if (chat) {
    //   let client = {
    //     id: 1,
    //     chat: chat,
    //   };

    //   let bot = {
    //     id: 1,
    //     chat: "Thank you for your querry. we will shorlty connect with you! 👋",
    //   };

    //   setChat("");

    //   let newChatStack = [...chatStack, ...[{ bot: bot, client: client }]];
    //   setChatStack(newChatStack);
    // }

    if (chat) {
      const url = BASE_URL + `org/logs/corpSupport`;
      //|| "872cd841-9f7a-432d-b8e9-422b780bca10"|| 345
      const payload = {
        corpId: corpId,
        loggedUserId: userID,
        raisedQuery: chat,
        corpLogsType: "EMAIL",
      };

      const response = await saveData(url, payload, "");

      if (response.error) {
        console.log({ error: response.error });

        let client = {
          id: 1,
          chat: chat,
        };

        let bot = {
          id: 1,
          chat: "Failed to send. Try again later.",
        };

        let newChatStack = [...chatStack, ...[{ bot: bot, client: client }]];
        setChatStack(newChatStack);
        setDisableMessgaeArea(false);
      } else {
        console.log({ success: response.data });
        setDisableMessgaeArea(true);
        let client = {
          id: 1,
          chat: chat,
        };
        let bot = {
          id: 1,
          chat: defaultText,
        };

        let newChatStack = [...chatStack, ...[{ bot: bot, client: client }]];
        setChatStack(newChatStack);
        setChat("");
      }
    }
  };

  return (
    <Fragment>
      <form onSubmit={_submitHandler}>
        <Box
          sx={{ px: 3, height: 80, background: "rgb(255, 255, 255)", borderRadius: 4 }}
          display="flex"
          justifyContent="center"
          alignItems="center">
          <Stack
            direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%">
            <TextField
              disabled={disableMessgaeArea}
              // onKeyDown={(e) => {
              //   if (e.keyCode === 13 && !e.shiftKey) {
              //     e.preventDefault();
              //   }
              // }}
              value={chat}
              onChange={(e) => setChat(e.target.value)}
              placeholder="Type your message here..."
              fullWidth
              variant="outlined"
              // multiline
              // maxRows={2}
              sx={{
                "& fieldset": { border: "none" },
              }}
            />
            <IconButton type="submit" disabled={disableMessgaeArea}>
              <SendIcon />
            </IconButton>
          </Stack>
        </Box>
      </form>
    </Fragment>
  );
};

export default ChatFooter;
