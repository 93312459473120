import { Box, Grid, Typography } from "@mui/material";

const CustomTypography = ({ children }) => {
  return (
    <Typography
      sx={{
        fontWeight: "600",
        fontSize: 16,
        //lineHeight: 21,
        color: "#000000",
      }}
    >
      {children}
    </Typography>
  );
};

const DashboardHeaderCancelled = () => {
  return (
    <Box
      sx={{
        minHeight: "6vh",
        borderRadius: 3,
        pl: 2,
        mb: 1,
      }}
      display="flex"
      alignItems="center"
    >
      <Grid container display="flex" alignItems="center">
        <Grid item lg={1} md={1} sm={1} xs={1}>
          <CustomTypography> ID </CustomTypography>
        </Grid>

        <Grid item lg={3} md={3} sm={3} xs={3}>
          <CustomTypography> Name </CustomTypography>
        </Grid>

        <Grid item lg={1} md={1} sm={1} xs={1}>
          <CustomTypography> Cancelled By </CustomTypography>
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={2}>
          <CustomTypography> Reason </CustomTypography>
        </Grid>

        <Grid
          item
          lg={1}
          md={1}
          sm={1}
          xs={1}
          display="flex"
          justifyContent="center"
        >
          <CustomTypography> Last Visit </CustomTypography>
        </Grid>

        <Grid
          item
          lg={1}
          md={1}
          sm={1}
          xs={1}
          display="flex"
          justifyContent="center"
        >
          <CustomTypography> Time </CustomTypography>
        </Grid>

        <Grid
          item
          lg={1}
          md={1}
          sm={1}
          xs={1}
          display="flex"
          justifyContent="center"
        >
          <CustomTypography> Visit Type </CustomTypography>
        </Grid>

        <Grid
          item
          lg={2}
          md={2}
          sm={2}
          xs={2}
          display="flex"
          justifyContent="center"
        >
          <CustomTypography> Patient Info </CustomTypography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardHeaderCancelled;
