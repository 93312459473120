import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Fragment } from "react";
import calenderBlueIcon from "../../../../assets/images/calenderBlueIcon.png";
import fileBlueIcon from "../../../../assets/images/fileBlueIcon.png";
import videoBlueIcon from "../../../../assets/images/videoBlueIcon.png";

const ThreeStep = () => {
  return (
    <Fragment>
      <Box
        sx={{
          paddingBlock: "60px",
        }}>
        <Grid
          container
          columnSpacing={10}
          sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid item xs={4} lg={4}>
            <Box
              sx={{
                borderRadius: "20px",
                backgroundColor: "#FFF",
                boxShadow: "10px 10px 50px 10px rgba(229, 233, 246, 0.40)",
                height: "300px",
                width: "300px",
                padding: "35px",
              }}>
              <Box
                sx={{
                  backgroundColor: "#E5EFFF",
                  height: "75px",
                  width: "75px",
                  borderRadius: "10px",
                  marginBlock: "20px",
                }}>
                <Box
                  component="img"
                  src={calenderBlueIcon}
                  style={{ height: "48px", width: "48px", margin: "15px", marginBlock: "20px" }}
                />
              </Box>
              <Typography
                sx={{
                  color: "#404040",
                  textAlign: "justify",
                  fontSize: "20px",
                  fontWeight: "600",
                  lineHeight: "normal",
                  textTransform: "capitalize",
                  marginBlock: "10px",
                }}>
                01. Book Appointment
              </Typography>
              <Typography
                sx={{
                  color: "#404040",
                  textAlign: "justify",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  textTransform: "capitalize",
                  marginBlock: "10px",
                }}>
                Visit UNO.Care near you or call us at 1800-889-0189 to book an appointment
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} lg={4}>
            <Box
              sx={{
                borderRadius: "20px",
                backgroundColor: "#FFF",
                boxShadow: "10px 10px 50px 10px rgba(229, 233, 246, 0.40)",
                height: "300px",
                width: "300px",
                padding: "35px",
              }}>
              <Box
                sx={{
                  backgroundColor: "#E5EFFF",
                  height: "75px",
                  width: "75px",
                  borderRadius: "10px",
                  marginBlock: "20px",
                }}>
                <Box
                  component="img"
                  src={videoBlueIcon}
                  style={{ height: "48px", width: "48px", margin: "15px" }}
                />
              </Box>
              <Typography
                sx={{
                  color: "#404040",
                  textAlign: "justify",
                  fontSize: "20px",
                  fontWeight: "600",
                  lineHeight: "normal",
                  textTransform: "capitalize",
                  marginBlock: "10px",
                }}>
                02. Consultation
              </Typography>
              <Typography
                sx={{
                  color: "#404040",
                  textAlign: "justify",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  textTransform: "capitalize",
                  marginBlock: "10px",
                }}>
                Consult the best experienced MBBS/MD Doctors
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} lg={4}>
            <Box
              sx={{
                borderRadius: "20px",
                backgroundColor: "#FFF",
                boxShadow: "10px 10px 50px 10px rgba(229, 233, 246, 0.40)",
                height: "300px",
                width: "300px",
                padding: "35px",
              }}>
              <Box
                sx={{
                  backgroundColor: "#E5EFFF",
                  height: "75px",
                  width: "75px",
                  borderRadius: "10px",
                  marginBlock: "20px",
                }}>
                <Box
                  component="img"
                  src={fileBlueIcon}
                  style={{ height: "48px", width: "48px", margin: "15px" }}
                />
              </Box>
              <Typography
                sx={{
                  color: "#404040",
                  textAlign: "justify",
                  fontSize: "20px",
                  fontWeight: "600",
                  lineHeight: "normal",
                  textTransform: "capitalize",
                  marginBlock: "10px",
                }}>
                03. Digital Prescription
              </Typography>
              <Typography
                sx={{
                  color: "#404040",
                  textAlign: "justify",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  textTransform: "capitalize",
                  marginBlock: "10px",
                }}>
                Get your Digital Prescription
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default ThreeStep;
