import { Autocomplete, Box, FormControl, Grid, TextField } from "@mui/material";

const Gender = ({ formValues, setFormValues }) => {
  return (
    <Box>
      <Grid container>
        <Grid item xs>
          <Autocomplete
            fullWidth
            value={formValues["gender"] || null}
            onChange={(event, newValue) => {
              let newFormValues = { ...formValues };
              newFormValues["gender"] = newValue;
              setFormValues(newFormValues);
            }}
            sx={{ width: 200, mt: 0 }}
            size="small"
            disablePortal
            options={["MALE", "FEMALE", "DONOTDISCLOSE", "OTHER"]}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Gender"
                size="small"
                sx={{
                  backgroundColor: "#fff",
                  "& .MuiInputLabel-root": { color: "#D4D4D4 ! important" },
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { borderColor: "#D4D4D4 ! important" },
                  },
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Gender;
