import { Box, Grid, Typography } from "@mui/material";
import GoToConsultationPage from "./goToConsultationPage";

const CustomTypography = ({ children }) => {
  return (
    <Typography
      noWrap
      sx={{
        fontWeight: "400",
        fontSize: 15,
        //lineHeight: 19,
        color: "#6B6B6B",
      }}
    >
      {children}
    </Typography>
  );
};

const DashboardItemCancelled = ({ data }) => {
  return (
    <Box
      sx={{
        minHeight: "6vh",
        border: "0.5px solid #127DDD",
        borderRadius: 3,
        pl: 2,
        mb: 1,
      }}
      display="flex"
      alignItems="center"
    >
      <Grid container display="flex" alignItems="center">
        <Grid item lg={1} md={1} sm={1} xs={1}>
          <CustomTypography> {data?.patientId} </CustomTypography>
        </Grid>

        <Grid item lg={3} md={3} sm={3} xs={3}>
          <CustomTypography> {data?.name} </CustomTypography>
        </Grid>

        <Grid item lg={1} md={1} sm={1} xs={1}>
          <CustomTypography> {data?.canceledBy} </CustomTypography>
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={2} zeroMinWidth>
          <CustomTypography> {data?.reasonOfVisit} </CustomTypography>
        </Grid>

        <Grid
          item
          lg={1}
          md={1}
          sm={1}
          xs={1}
          display="flex"
          justifyContent="center"
        >
          <CustomTypography> {data?.lastVisit} </CustomTypography>
        </Grid>

        <Grid
          item
          lg={1}
          md={1}
          sm={1}
          xs={1}
          display="flex"
          justifyContent="center"
        >
          <Box
            sx={{
              height: "2rem",
              minWidth: "6rem",
              background: "#BFEAFF",
              borderRadius: "0.7rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CustomTypography> {data?.startTime} </CustomTypography>
          </Box>
        </Grid>

        <Grid
          item
          lg={1}
          md={1}
          sm={1}
          xs={1}
          display="flex"
          justifyContent="center"
        >
          <CustomTypography> {data?.consultationType} </CustomTypography>
        </Grid>

        <Grid
          item
          lg={2}
          md={2}
          sm={2}
          xs={2}
          display="flex"
          justifyContent="center"
        >
          <GoToConsultationPage
            patientDeatils={data?.patientDeatils}
            label="View"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardItemCancelled;
