import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Paper, Typography } from "@mui/material";
import TableHeader from "../tableHeader";
import Medicines from "../medicines";

const ViewCurrentPrescriptionsForm = (props) => {
  return (
    <Fragment>
      <Box sx={{ borderRadius: 15 }}>
        <Paper
          component={Stack}
          justifyContent="center"
          align="center"
          sx={{
            height: 25,
            background: "#BFEAFF",
            borderRadius: "15px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.50)",
          }}
        >
          <Typography
            variant="body2"
            sx={{ width: "100%", flexShrink: 0, fontWeight: "500" }}
          >
            Today
          </Typography>
        </Paper>

        <TableHeader />
      </Box>

      <Box sx={{ background: "#F3F3F3" }}>
        {props.medicines
          ? props.medicines.map((medicine, index) => (
              <Medicines key={index} index={index + 1} medicines={medicine} />
            ))
          : ""}
      </Box>
    </Fragment>
  );
};

export default ViewCurrentPrescriptionsForm;
