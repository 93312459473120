import { useParams } from "react-router-dom";
import RootTemplate from "../../global/templates/rootTemplate";
import EmployeeProfileMain from "../../modules/employeeprofile/employeeProfileMain";

const EmployeeProfileIndex = () => {
  const params = useParams();
  let employeeID = params?.employeeID;
  return (
    <RootTemplate>
      <EmployeeProfileMain employeeID={employeeID} isBack={true} />
    </RootTemplate>
  );
};

export default EmployeeProfileIndex;
