import { Container, Grid } from "@mui/material";
import LandingAppointmentLeft from "./landingAppointmentLeft";
import LandingAppointmentRight from "./landingAppointmentRight";

const LandingAppointment = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item lg={6}>
          <LandingAppointmentLeft />
        </Grid>
        <Grid item lg={6}>
          <LandingAppointmentRight />
        </Grid>
      </Grid>
    </Container>
  );
};

export default LandingAppointment;
