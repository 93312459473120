import { Box, Grid, Typography } from "@mui/material";
import OPD from "./comps/opd";
import RecordInjury from "./comps/recordInjury";
import CustomBreadcrumbs from "../../../global/breadcrumbs/customBreadcrumbs";
import CustomTitles from "../../../global/titles/customTitles";

const CustomTitlesForOPD = ({ title = "Corporate" }) => {
  return (
    <Box sx={{ pb: 0.5, px: 2 }}>
      <Grid container>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          alignItems="center"
        >
          <CustomBreadcrumbs />
        </Grid>
        <Grid
          item
          lg={3}
          md={3}
          sm={3}
          xs={3}
          display="flex"
          justifyContent="center"
          alignItems="center"
        ></Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={6}
          xs={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h6"> {title} </Typography>
        </Grid>
        <Grid
          item
          lg={3}
          md={3}
          sm={3}
          xs={3}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <RecordInjury />
        </Grid>
      </Grid>
    </Box>
  );
};

const OHCMyOPDMain = () => {
  return (
    <Box>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitlesForOPD title="My OPD" />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: "flex", justifyContent: "right" }}
        ></Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <OPD />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OHCMyOPDMain;
