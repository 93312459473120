import { Fragment, useState, useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Typography,
  TextField,
  Button,
  Stack,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApi";
import { saveData } from "../../../services/api/postApi";
import { useCallback } from "react";
import ReferDoctorForm from "./referDoctorForm";
import ReferredToComp from "./referredToComp";

const ReferADoctorIndex = ({ patientId, caseId, appointmentId }) => {
  useEffect(() => {
    fetchSpecializationHandler();
  }, []);

  const [specializationList, setSpecializationList] = useState([]);

  const fetchSpecializationHandler = useCallback(async () => {
    const url = BASE_URL + "clinic/getSpecializations";

    const user = await getData(url, "");

    if (user.error) {
      console.log("error");
    } else {
      const data1 = user.data;
      data1.push("Others");

      setSpecializationList(data1.filter((d) => d));

      console.log({ setSpecializationList: data1 });
    }
  }, []);

  const [isEdit, setIsEdit] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState("");

  if (isEdit) {
    return (
      <ReferDoctorForm
        patientId={patientId}
        caseId={caseId}
        appointmentId={appointmentId}
        selectedTitle={selectedTitle}
        setIsEdit={setIsEdit}
      />
    );
  }

  return (
    <Box>
      <ReferredToComp appointmentId={appointmentId} />
      <Box>
        <List>
          {specializationList.map((specialization, index) => (
            <ListItemButton
              key={index}
              onClick={() => {
                setIsEdit(true);
                setSelectedTitle(specialization);
              }}
              sx={{
                border: "0.5px solid #777777",
                borderRadius: "10px",
                mb: 2,
                "&.active, &:hover, &.active:hover": {
                  backgroundColor: "#127DDD",
                },
              }}
            >
              <ListItemText>{specialization}</ListItemText>

              <KeyboardArrowRightIcon />
            </ListItemButton>
          ))}
        </List>
      </Box>
    </Box>
  );
};
export default ReferADoctorIndex;
