import { Box, Grid } from "@mui/material";
import CustomTitles from "../../../global/titles/customTitles";
import { useEffect, useState } from "react";
import { _getCSREmployeeList } from "../../../global/services/corporateServices";
import CSRHRMain from "./comps/csrhrMain";

const CSRHealthRecordMain = ({ corpId = localStorage.getItem("CORPID") }) => {
  return (
    <Box>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="CSR Health Records" />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CSRHRMain />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CSRHealthRecordMain;
