import React from "react";
import blurHospital from "../../../../assets/images/blurHospital.png";
import { Box } from "@mui/material";

const Background = () => {
  return (
    // <Box sx={{ position: "relative", display: "inline-block", marginBlock: "30px" }}>
    <Box sx={{ position: "relative", display: "inline-block" }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          opacity: 0.5,
          background: "linear-gradient(180deg, #003B9E 0%, #409FFF 48.96%, #9ACFFF 98.96%)",
        }}></Box>
      <Box component="img" src={blurHospital} />
    </Box>
  );
};

export default Background;
