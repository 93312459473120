export const BASE_URL = "https://apibackend.uno.care/api/";
// export const BASE_URL = "https://apitest.uno.care/api/";
//export const BASE_URL = "http://127.0.0.1:8080/api/";
export const BASE_URL_AUTH = "https://auth.uno.care/";

export const medTypesList = [
  {
    value: "TABLET",
    label: "TAB",
  },
  {
    value: "SYRUP",
    label: "SYR",
  },
  {
    value: "INJECTION",
    label: "INJ",
  },
];

export const ZoomRoom_Params = "?minimal&floatSelf&leaveButton=on&people=off";

export const PAYMENTMODE = [
  {
    value: "CASH",
    label: "CASH",
  },
  {
    value: "UPI",
    label: "UPI",
  },
  {
    value: "CARD",
    label: "CARD",
  },
];

export const GENDERLIST = [
  {
    value: "MALE",
    label: "MALE",
  },
  {
    value: "FEMALE",
    label: "FEMALE",
  },
  {
    value: "OTHER",
    label: "OTHER",
  },
  {
    value: "DONOTDISCLOSE",
    label: "DONOTDISCLOSE",
  },
];

export const complaintOptions = [
  "Full body",
  "Head",
  "Top head",
  "Back head",
  "Left ear",
  "right ear",
  "forehead",
  "left eye",
  "right eye",
  "left cheek",
  "right cheek",
  "mouth",
  "Lips",
  "Chin",
  "Neck",
  "left shoulder",
  "right shoulder",
  "Upper back",
  "Mid back",
  "Lower back",
  "Chest",
  "stomach",
  "Left arm full",
  "left arm upto elbow",
  "left elbow",
  "left arm between elbow and wrist",
  "Left wrist",
  "left palm",
  "Left thumb",
  "left index finger",
  "left middle finger",
  "Left ring finger",
  "Left little finger",
  "Right arm full",
  "Right arm upto elbow",
  "Right elbow",
  "Right arm between elbow and wrist",
  "Right wrist",
  "Right palm",
  "Right thumb",
  "Right index finger",
  "Right middle finger",
  "Right ring finger",
  "Right little finger",
  "Waist",
  "Abdomen",
  "Left leg full",
  "Left foot",
  "Left thigh",
  "Left hip",
  "Left knee",
  "Left shin",
  "Left calf",
  "Left ankle",
  "left heel",
  "Left big toe",
  "Left index toe",
  "Left middle toe",
  "Left fourth toe",
  "Left little toe",
  "Left sole",
  "Right leg full",
  "Right foot",
  "Right thigh",
  "Right hip",
  "Right knee",
  "Right shin",
  "Right calf",
  "Right ankle",
  "Right heel",
  "Right big toe",
  "Right index toe",
  "Right middle toe",
  "Right fourth toe",
  "Right little toe",
  "Right sole",
  "loose motion",
];
