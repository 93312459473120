import { Fragment } from "react";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";

const CustomTypographyHeading = ({ children }) => (
  <Typography
    variant="h6"
    sx={{
      color: "#000",
      fontFamily: "Poppins",
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
    }}>
    {children}
  </Typography>
);

const MainPageLayoutWithBackTitleLeft = ({ title, children }) => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Box>
        <Grid container rowSpacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Stack direction="row" spacing={1} display="flex" alignItems="center">
              <IconButton onClick={() => navigate(-1)}>
                <KeyboardBackspaceIcon sx={{ color: "#000" }} />
              </IconButton>
              <CustomTypographyHeading>{title}</CustomTypographyHeading>
            </Stack>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default MainPageLayoutWithBackTitleLeft;
