import { Card, CardActions, Box, Grid, IconButton, TextField, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import saveIcon from "../../../../assets/images/svg/formEdit.svg";
import editIcon from "../../../../assets/images/svg/editIcon.svg";
import { BASE_URL } from "../../../../assets/constantsFile";
import { saveData } from "../../../../services/api/postApi";

const style = {
    row: {
        display: "flex",
        alignItems: "center",
        px: 1,
        py: 0.87,
        borderRadius: "16.39px",
        background: "#F4FBFF",
    },
    title: { fontFamily: "Roboto", fontWeight: "600", fontSize: "1rem", lineHeight: "19px", color: "#127DDD" },
    textRow: { display: "flex", alignItems: "center" },
    text: { fontFamily: "Roboto", fontWeight: "400", fontSize: "1rem", lineHeight: "19px", pl: 2 },
    border: { height: "36px", borderLeft: "1px solid #127ddd" },
    icon: { mt: 0.25, display: "flex", justifyContent: "center" },
};

const AdviceComp = ({ adviceValue, appointmentId }) => {
    const [editAdvice, setEditAdvice] = useState(adviceValue ? true : false);

    const [advice, setAdvice] = useState(adviceValue);

    const updateAdvice = (e) => {
        e.preventDefault();
        if (editAdvice === false) {
            let o = {
                appointmentId: appointmentId,
                advice: advice,
            };

            addAdvice(o);
        }

        setEditAdvice(!editAdvice);
    };

    const addAdvice = async (obj) => {
        const user = await saveData(BASE_URL + "doctor/prescription/advice", obj, "");

        if (user.error) {
            console.log(user.error);
        } else {
            console.log(user.data);
        }
    };
    return (
        <Fragment>
            <form onSubmit={updateAdvice}>
                <Grid container sx={style.row}>
                    <Grid item lg={3}>
                        <Typography sx={style.title}>Advice</Typography>
                    </Grid>
                    <Grid item lg={8} sx={style.textRow}>
                        <Box sx={style.border}></Box>
                        {editAdvice && <Typography sx={style.text}>{advice}</Typography>}
                        {!editAdvice && (
                            <TextField
                                label="Lifestyle, eg: Food/Liquid/Exercise/Rest"
                                size="small"
                                disabled={editAdvice}
                                fullWidth
                                variant="outlined"
                                sx={{
                                    py: 1,
                                    px: 0.5,
                                    "& .MuiOutlinedInput-root:hover": {
                                        "& > fieldset": {
                                            borderColor: "#127DDD",
                                        },
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "&.Mui-focused fieldset": {
                                            borderColor: "#127DDD",
                                        },
                                    },
                                }}
                                value={advice || ""}
                                onChange={(e) => {
                                    setAdvice(e.target.value);
                                }}
                                inputProps={{
                                    style: { fontSize: ".875rem", fontWeight: "500" },
                                }}
                                InputLabelProps={{
                                    style: { fontSize: ".875rem", fontWeight: "500", color: "#127DDD" },
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item lg={1} sx={style.icon}>
                        {editAdvice && (
                            <IconButton aria-label="edit" size="small" type="submit">
                                <img src={editIcon} sx={{ width: "100%" }} />
                            </IconButton>
                        )}
                        {!editAdvice && (
                            <IconButton aria-label="edit" size="small" type="submit">
                                <img src={saveIcon} sx={{ width: "100%" }} />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            </form>
        </Fragment>
    );
};

export default AdviceComp;
