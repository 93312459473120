import RootTemplate from "../../global/templates/rootTemplate";
import PreEmpHealthRecordsMain from "../../modules/preemployment/healthRecords/preEmpHealthRecordsMain";

const PreEmpHealthRecordIndex = () => {
  return (
    <RootTemplate>
      <PreEmpHealthRecordsMain />
    </RootTemplate>
  );
};

export default PreEmpHealthRecordIndex;
