import { Box, Container, Grid } from "@mui/material";
import AboutUsLeft from "./aboutUsLeft";
import AboutUsRight from "./aboutUsRight";

const AboutUs = () => {
  return (
    <Container>
      <Box sx={{ mb: 5, marginBlock: "20px" }}>
        <Grid container spacing={3}>
          <Grid item lg={6}>
            <AboutUsLeft />
          </Grid>
          <Grid item lg={6}>
            <AboutUsRight />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AboutUs;
