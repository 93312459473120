import { Box } from "@mui/material";
import Feature from "../../assets/images/feature.jpg";

const LandingFeaturesRight = () => {
  return (
    <Box sx={{ backgroundColor: "#fff", maxHeight: 500, overflow: "hidden" }}>
      <Box
        component="img"
        alt="card"
        src={Feature}
        sx={{
          display: "flex",
          width: "100%",
        }}
      />
    </Box>
  );
};

export default LandingFeaturesRight;
