import { Fragment } from "react";
import NoticeMainComp from "./noticeMainComp";

const ShowNoticeMessage = ({ noticeData }) => {
  return (
    <Fragment>
      <NoticeMainComp
        severityValue={noticeData.severity}
        messageValue={noticeData.message}
        openNoticeValue={noticeData.openNotice}
      />
    </Fragment>
  );
};

export default ShowNoticeMessage;
