import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, IconButton, Stack, Tab, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { BASE_URL } from "../../../assets/constantsFile";
import { getData } from "../../../services/api/getApi";
import Prescription from "../oldAilments/components/prescription";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import Report from "../oldAilments/components/report";

const OldCaseSummaryTabs = ({ setSelectedTab, setDefaultTab, caseId }) => {
  const [caseDetail, setCaseDetail] = useState([]);
  const [reportList, setReportList] = useState([]);
  const [appointmentList, setAppointmentList] = useState([]);

  const fetchCaseDetail = async () => {
    const url = BASE_URL + "patient/detailed/case/" + caseId;
    const data = await getData(url, "");

    if (data.error) {
      console.log({ "error getting case detail": data.error });
    } else {
      setCaseDetail(data.data);
      setReportList(data.data.reportVMs);
      setAppointmentList(data.data.appointmentList);
    }
  };

  useEffect(() => {
    fetchCaseDetail();
  }, []);

  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <TabContext value={value}>
        <Box>
          <TabList
            centered
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{
              mx: 3,
              width: "100%",
              border: "none",
              ".Mui-selected": {
                backgroundColor: "white !important",
                borderRadius: "5px",
                fontWeight: "500",

                "& p": {
                  color: "#127DDD !important",
                },
              },
              ".MuiTabs-indicator": {
                background: "#127DDD !important",
                borderRadius: "10px",
              },
            }}
          >
            <Tab
              label={
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: ".9rem",
                    fontWeight: 600,
                  }}
                >
                  Summary
                </Typography>
              }
              value="1"
              sx={{ width: "30%" }}
            />
            <Tab
              label={
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: ".9rem",
                    fontWeight: 600,
                  }}
                >
                  Prescription
                </Typography>
              }
              value="2"
              sx={{ width: "30%" }}
            />

            <Tab
              label={
                <Typography
                  variant="body1"
                  sx={{ fontSize: ".9rem", fontWeight: 600 }}
                >
                  Reports
                </Typography>
              }
              value="3"
              sx={{ width: "30%" }}
            />
          </TabList>
          <Box
            sx={{
              border: "1px solid black",
              borderRadius: "25px",
              px: 2,
              py: 2,
              height: "77vh",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Stack direction="row" justifyContent="flex-end">
              <IconButton
                size="large"
                onClick={() => {
                  setSelectedTab("default");
                  setDefaultTab("2");
                }}
              >
                <SettingsBackupRestoreIcon fontSize="large" color="primary" />
              </IconButton>
            </Stack>
            <TabPanel value="1" sx={{ p: 1 }}>
              {appointmentList.map((item, index) => (
                <Prescription key={index} data={item} />
              ))}
              {reportList.map((item, index) => (
                <Report key={index} data={item} />
              ))}
            </TabPanel>
            <TabPanel value="2" sx={{ p: 1 }}>
              {appointmentList.map((item, index) => (
                <Prescription key={index} data={item} />
              ))}
            </TabPanel>
            <TabPanel value="3" sx={{ p: 1 }}>
              {reportList.map((item, index) => (
                <Report key={index} data={item} />
              ))}
            </TabPanel>
          </Box>
        </Box>
      </TabContext>
    </Box>
  );
};

export default OldCaseSummaryTabs;
