import * as React from "react";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { createTheme, useTheme, ThemeProvider } from "@mui/material/styles";
import { colors } from "../../../assets/color";

// Theme.ts
const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.lightgray,
            },
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.darkgray,
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: colors.primary,
            },
            "& .MuiOutlinedInput-input": {
              color: colors.black,
            },
            "&:hover .MuiOutlinedInput-input": {
              color: colors.black,
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
              color: colors.black,
            },
            "& .MuiInputLabel-outlined": {
              color: colors.lightgray,
            },
            "&:hover .MuiInputLabel-outlined": {
              color: colors.lightgray,
            },
            "& .MuiInputLabel-outlined.Mui-focused": {
              color: colors.lightgray,
            },
          },
        },
      },
    },
  });

const CustomAutoComplete = (params) => {
  // useTheme is used to determine the dark or light mode of the docs to maintain the Autocomplete component default styles.
  const outerTheme = useTheme();

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <Autocomplete {...params} />
    </ThemeProvider>
  );
};

export default CustomAutoComplete;
