import { Box, Checkbox, Stack, TextField, Typography } from "@mui/material";
import { Fragment, useState } from "react";

const style = {
    checkbox: {
        p: 0,
        "&.Mui-checked": {
            color: "#127DDD",
        },
    },
};
const TimingComp = ({ element }) => {
    // const [text, setText] = useState(element.timing);
    const [isMorning, setIsMorning] = useState(element.m);
    const [isAfternoon, setIsAfternoon] = useState(element.a);
    const [isEvening, setIsEvening] = useState(element.e);
    const [isNight, setIsNight] = useState(element.n);

    return (
        <Fragment>
            <Box>
                <Box style={{ position: "relative", top: -4 }}>
                    <Typography
                        sx={{
                            fontSize: 7,
                            fontWeight: 400,
                            color: "rgba(0, 0, 0, 0.6)",
                            backgroundColor: "#fff",
                        }}
                    >
                        M-A-E-N
                    </Typography>
                </Box>
                <Stack direction="row" display="flex" justifyContent={"space-between"} sx={{ width: "100%" }}>
                    <Checkbox
                        size="small"
                        sx={style.checkbox}
                        name="m"
                        checked={isMorning || false}
                        onChange={(e) => {
                            setIsMorning(e.target.checked);
                            element["m"] = e.target.checked;
                        }}
                    />

                    <Checkbox
                        sx={style.checkbox}
                        size="small"
                        name="a"
                        checked={isAfternoon || false}
                        onChange={(e) => {
                            setIsAfternoon(e.target.checked);
                            element["a"] = e.target.checked;
                        }}
                    />
                    <Checkbox
                        sx={style.checkbox}
                        size="small"
                        name="e"
                        checked={isEvening || false}
                        onChange={(e) => {
                            setIsEvening(e.target.checked);
                            element["e"] = e.target.checked;
                        }}
                    />

                    <Checkbox
                        sx={style.checkbox}
                        size="small"
                        name="n"
                        checked={isNight || false}
                        onChange={(e) => {
                            setIsNight(e.target.checked);
                            element["n"] = e.target.checked;
                        }}
                    />
                </Stack>
            </Box>
        </Fragment>
    );
};

export default TimingComp;
