import { Box, Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { getTemplateListByDocId } from "../services/api/apiCalls";
import PrescriptionTemplateCard from "./prescriptionTemplateCard";

const PrescriptionTemplateList = (props) => {

    const [prescriptions, setPrescriptions] = useState([]);
    const[state,setState]=useState();
    const[flag,setFlag]=useState(false);


    const fetchPrescriptionsHandler = useCallback(async () => {

            const user = await getTemplateListByDocId(localStorage.getItem("DOCID"));
          
            if(user.error){
                setState({ error: user.error });
              
              } else {
                setState({ data: user.data });
              
              }
  
              const data = user.data;
    
          const transformedPrescriptions = data.map((prescriptionsData,size) => {
            return {
              docId: prescriptionsData.docId,
              templateName: prescriptionsData.templateName,
              medicines: prescriptionsData.medicines,
              templateId: prescriptionsData.templateId,
            };
          }); 
          setPrescriptions(data);     
      }, []);
    
      useEffect(() => {
          fetchPrescriptionsHandler();
      }, [fetchPrescriptionsHandler, flag]);


      const deleteHandler = (data) => {

        setFlag(!flag);

      }
   
    return (
        <Box sx={{mb:5}} >

            <Grid container spacing={2} columns={15}>

            {prescriptions.map((prescription) => (
                <PrescriptionTemplateCard
                  key={prescription.templateId}
                  prescription={prescription}
                  onDelelePrescription={deleteHandler}
                />
              ))}

            </Grid>
        </Box>
    );
}
 
export default PrescriptionTemplateList;