import React, { Fragment, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";

const CounterComp = ({ count, delay, suffix, title, description, color1, color2, color3 }) => {
  const [counter, setCounter] = useState(0);
  const [increment, setIncrement] = useState(1);

  useEffect(() => {
    if (count > counter) {
      const interval = setInterval(() => {
        setCounter((prevCounter) => prevCounter + increment);
      }, 1000 / count); // Adjust the duration based on the total count

      // Determine the increment value based on the total count
      setIncrement(Math.ceil(count / 10)); // You can change 10 to control when the delay occurs

      return () => {
        clearInterval(interval);
      };
    }
  }, [count, counter, increment]);

  return (
    <Fragment>
      <Grid container sx={{ padding: "10px" }}>
        <Grid item lg={12} sx={{ justifyContent: "center", alignItems: "center" }}>
          <Typography
            variant="h1"
            sx={{ color: "#2E88EC", textAlign: "center", fontWeight: "400" }}>
            {counter}
            {suffix}
          </Typography>
        </Grid>
        <Grid item lg={12} sx={{ height: "20px", justifyContent: "center", alignItems: "center" }}>
          <Typography
            variant="h6"
            sx={{ color: "#344767", textAlign: "center", fontWeight: "400" }}>
            {title}
          </Typography>
        </Grid>
        <Grid item lg={12} sx={{ height: "50px", justifyContent: "center", alignItems: "center" }}>
          <Typography variant="body1" sx={{ color: "#82869F", textAlign: "center" }}>
            {description}
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default CounterComp;
