import React, { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { medTypesList } from "../../assets/constantsFile";
import { getMedicineListByName } from "../../services/api/apiCalls";
import TableHeader from "../templateComponents/tableHeader";
import AddIcon from "@mui/icons-material/Add";

const PrescriptionTemplateEdit = (props) => {
  const [formValues, setFormValues] = useState(props.newPrescription);
  const [templateNameEdit, setTemplateNameEdit] = useState(props.templateName);

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];

    if (e.target.type === "checkbox") {
      newFormValues[i][e.target.name] = e.target.checked;
    } else {
      newFormValues[i][e.target.name] = e.target.value;
    }
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { medicineName: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  function submitHandler(event) {
    event.preventDefault();
    props.onEditPrescriptionTemplate(formValues, templateNameEdit);
  }

  const [medicineList, setMedicineList] = useState([]);
  const [state, setState] = useState();

  const fetchMedicinesHandler = useCallback(async (medName) => {
    console.log({ medName: medName });

    const user = await getMedicineListByName(medName);

    if (user.error) {
      setState({ error: user.error });
    } else {
      setState({ data: user.data });
    }

    const data = await user.data;
    setMedicineList(data.content);
  }, []);

  console.log({ medicineList: medicineList });

  return (
    <Box sx={{ width: "64vw" }}>
      <Box sx={{ flexGrow: 1, minHeight: "5vh", p: 1, background: "#F3F3F3" }}>
        <Grid container>
          <Grid item lg={9} md={12} sm={12} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Edit Template Name..."
              value={templateNameEdit}
              onChange={(e) => {
                setTemplateNameEdit(e.target.value);
              }}
            />
          </Grid>
          <Grid
            item
            lg={3}
            md={12}
            sm={12}
            xs={12}
            display="flex"
            justifyContent={"flex-end"}
          >
            <Button
              startIcon={<AddIcon />}
              onClick={() => addFormFields()}
              variant="outlined"
              sx={{ height: 40.25 }}
            >
              {" "}
              Add Medicine{" "}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <TableHeader />
      </Box>

      <Box sx={{ mt: 1 }}>
        <form onSubmit={submitHandler} id="myFormEdit">
          {formValues.map((element, index) => (
            <div key={index}>
              <Grid container columnSpacing={1}>
                <Grid item lg={3} md={12} sm={12} xs={12}>
                  <Autocomplete
                    fullWidth
                    freeSolo
                    disableClearable
                    selectOnFocus
                    size="small"
                    options={medicineList.map((option) => option.name)}
                    ListboxProps={{ sx: { fontSize: 11 } }}
                    onInputChange={(event, newInputValue) => {
                      element["medicineName"] = newInputValue;
                      fetchMedicinesHandler(newInputValue.toUpperCase());
                    }}
                    name="medicineName"
                    value={element.medicineName || ""}
                    onChange={(event, value) =>
                      (element["medicineName"] = value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                          style: { fontSize: 11 },
                        }}
                        InputLabelProps={{ style: { fontSize: 11 } }}
                      />
                    )}
                  />
                </Grid>

                <Grid item lg={1} md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    inputProps={{ style: { fontSize: 11 } }}
                    InputLabelProps={{ style: { fontSize: 11 } }}
                    name="dosage"
                    value={element.dosage || ""}
                    onFocus={(el) => {
                      el.target.select();
                    }}
                    onChange={(e) => handleChange(index, e)}
                    variant="outlined"
                    size="small"
                  />
                </Grid>

                <Grid item lg={2} md={12} sm={12} xs={12}>
                  <Stack direction="row">
                    <Checkbox
                      name="m"
                      checked={element.m || false}
                      onChange={(e) => handleChange(index, e)}
                    />

                    <Checkbox
                      name="a"
                      checked={element.a || false}
                      onChange={(e) => handleChange(index, e)}
                    />

                    <Checkbox
                      name="n"
                      checked={element.n || false}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </Stack>
                </Grid>

                <Grid item lg={1} md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    inputProps={{ style: { fontSize: 11 } }}
                    InputLabelProps={{ style: { fontSize: 11 } }}
                    name="duration"
                    type="number"
                    value={element.duration || 0}
                    onFocus={(el) => {
                      el.target.select();
                    }}
                    onChange={(e) => handleChange(index, e)}
                    variant="outlined"
                    size="small"
                  />
                </Grid>

                <Grid item lg={4} md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    inputProps={{ style: { fontSize: 11 } }}
                    InputLabelProps={{ style: { fontSize: 11 } }}
                    name="comment"
                    value={element.comment || ""}
                    onFocus={(el) => {
                      el.target.select();
                    }}
                    onChange={(e) => handleChange(index, e)}
                    variant="outlined"
                    size="small"
                  />
                </Grid>

                <Grid item lg={1} md={12} sm={12} xs={12}>
                  <IconButton
                    aria-label="remove"
                    onClick={() => removeFormFields(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider sx={{ mb: 1 }} />
                </Grid>
              </Grid>
            </div>
          ))}
        </form>
      </Box>
    </Box>
  );
};

export default PrescriptionTemplateEdit;
