import { Button, IconButton, Tooltip } from "@mui/material";
import { Fragment } from "react";
import DownloadIcon from "@mui/icons-material/Download";

const DownloadCSV = ({ exportCSV, type }) => {
  if (type === "ICON") {
    return (
      <Fragment>
        <Tooltip title="Download CSV">
          <IconButton
            size="small"
            color="primary"
            sx={{ height: 36, borderRadius: 2, border: 1 }}
            onClick={exportCSV}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <Button
        variant="contained"
        size="small"
        sx={{ height: 36, borderRadius: 2, px: 2 }}
        onClick={exportCSV}
      >
        Download CSV
      </Button>
    </Fragment>
  );
};

export default DownloadCSV;
