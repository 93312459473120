import { Box, Button, Divider, Grid, Paper, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { Component, useState } from 'react';

const weekdays = ['Monday','Tuesday','Wednesday','Thursaday','Friday','Saturday','Sunday'];

const SlotFormView = (props) => {

  
    const [slotValues, setSlotValues] = useState(props.slotData);


  
return (
  <Box sx={{
    height:"100%",
    width:"100%",
    p:1,
    background:'#fff'
  }}>
    
        <Grid container spacing={3} >


        <Grid item xs={12} >
          <Grid container alignItems="center">
          
          <Grid item xs={3} >
          <Typography variant="body2" sx={{fontWeight:500}}>SLOT NAME</Typography>
          </Grid>

          <Grid item xs={9} >
          <Typography variant="body2" sx={{fontWeight:500}}>{(slotValues !== 'null' && slotValues !=='undefined' ) ?  slotValues.slotName : " "}</Typography>
          </Grid>

          </Grid>
        </Grid>

        <Grid item xs={12} >
          <Grid container alignItems="center">

          <Grid item xs={3} >
          <Typography variant="body2" sx={{fontWeight:500}}>REPEAT FOR</Typography>
          </Grid>

          <Grid item xs={9} > 
          <Stack direction={'row'} alignItems="center">
          <Typography variant="body2" sx={{fontWeight:500}}>{(slotValues !== 'null') ?  slotValues.noOfWeeks: " "}</Typography>
    
            <Typography variant="body2" sx={{fontWeight:500}}>&nbsp;Weeks</Typography>
            </Stack>

          </Grid>

          </Grid>
        </Grid>

        <Grid item xs={12} >
          <Grid container alignItems="center">

          <Grid item xs={3} >
          <Typography variant="body2" sx={{fontWeight:500}}>REPEAT FOR</Typography>
          </Grid>

          <Grid item xs={9} >
          <Stack direction={"row"} alignItems="center">
          {slotValues.daysOfWeek.map((day) => (

            <Paper key={day} sx={{height:'auto', width:'auto',marginRight:1}} elevation={1}>

            <Typography variant="body2" sx={{fontWeight:500, m:.8}} >{weekdays[day]}</Typography>

            </Paper> 
          ))}
          </Stack>     
          
          </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} >
          <Grid container alignItems="center">

          <Grid item xs={3} >
          <Typography variant="body2" sx={{fontWeight:500}}>START TIME</Typography>
          </Grid>

          <Grid item xs={9} >

          <Stack direction={'row'}>
          <Typography variant="body2" sx={{fontWeight:500}}>{(slotValues !== 'null') ?  slotValues.startTime : ""}</Typography>
        </Stack>
          
          </Grid>
          </Grid>

        </Grid>

        <Grid item xs={12} >
          <Grid container alignItems="center">

          <Grid item xs={3} >
          <Typography variant="body2" sx={{fontWeight:500}}>END TIME</Typography>
          </Grid>

          <Grid item xs={9} >

          <Stack direction={'row'}>
          <Typography variant="body2" sx={{fontWeight:500}}>{(slotValues !== 'null') ?  slotValues.endTime : ""}</Typography>
           
        
        </Stack>
          
          </Grid>
          </Grid>

        </Grid>



        <Grid item xs={12} >
          <Grid container alignItems="center">

          <Grid item xs={3} >
          <Typography variant="body2" sx={{fontWeight:500}}>SCHEDULING TYPE</Typography>
          </Grid>

          <Grid item xs={9} >
          <Typography variant="body2" sx={{fontWeight:500}}>{(slotValues !== 'null' && slotValues.isActive) ?  "Enabled" : "Disabled"}</Typography>

          </Grid>
          </Grid>

        </Grid>
          
  
  
        </Grid>
      


  </Box>
);
}
 
export default SlotFormView;
