import React, { Fragment, useRef } from "react";
import GlobalTopbar from "./globalTopbar";
import Banner from "./banner";
import AboutUs from "./aboutUs";
import WhatWeOffer from "./whatWeOffer";
import Specialization from "./specialization";
import WhatWeDo from "./whatWeDo";
import WhyPeoplePreferUs from "./whyPeoplePreferUs";
import Testimonials from "./testimonials";
import Footer from "./footer";
import Clients from "./clients";
import QuickActionBtns from "./quickActionBtns";
import { Box } from "@mui/material";
import OverflowImg from "./overflowImg";

const LandingMain = () => {
  const aboutUsRef = useRef(null);
  const whatWeOfferRef = useRef(null);
  const specializationRef = useRef(null);
  const whatWeDoRef = useRef(null);
  const whyPeoplePreferUsRef = useRef(null);
  const clientsRef = useRef(null);
  const testimonialsRef = useRef(null);
  const footerRef = useRef(null);
  const moveCursorToForm = useRef(null);

  const scrollToSection = (section) => {
    const options = { behavior: "smooth", block: "start" };
    switch (section) {
      case "aboutUs":
        aboutUsRef.current.scrollIntoView(options);
        break;
      case "whatWeOffer":
        whatWeOfferRef.current.scrollIntoView(options);
        break;
      case "specialization":
        specializationRef.current.scrollIntoView(options);
        break;
      case "whatWeDo":
        whatWeDoRef.current.scrollIntoView(options);
        break;
      case "whyPeoplePreferUs":
        whyPeoplePreferUsRef.current.scrollIntoView(options);
        break;
      case "clients":
        clientsRef.current.scrollIntoView(options);
        break;
      case "testimonials":
        testimonialsRef.current.scrollIntoView(options);
        break;
      case "contactUs":
        footerRef.current.scrollIntoView(options);
        break;
      default:
        break;
    }
  };
  return (
    <Fragment>
      <GlobalTopbar scrollToSection={scrollToSection} moveCursorToForm={moveCursorToForm} />
      <Banner scrollToSection={scrollToSection} moveCursorToForm={moveCursorToForm} />
      <Box ref={clientsRef} sx={{ marginBlock: 0 }}>
        <Clients />
      </Box>

      <Box ref={whatWeOfferRef}>
        <WhatWeOffer scrollToSection={scrollToSection} />
      </Box>

      <Box ref={aboutUsRef}>
        <AboutUs scrollToSection={scrollToSection} />
      </Box>

      <Box>
        <OverflowImg />
      </Box>
      {/* <Box ref={testimonialsRef}>
        <Testimonials />
      </Box> */}
      <Box ref={specializationRef}>
        <Specialization scrollToSection={scrollToSection} />
      </Box>
      {/* <Box ref={whatWeDoRef}>
        <WhatWeDo scrollToSection={scrollToSection} />{" "}
      </Box> */}
      {/* <Box ref={whyPeoplePreferUsRef}>
        <WhyPeoplePreferUs />
      </Box> */}

      <Box ref={footerRef}>
        <Footer scrollToSection={scrollToSection} />
      </Box>
      <QuickActionBtns />
    </Fragment>
  );
};

export default LandingMain;
