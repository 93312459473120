import {
  Box,
  CardMedia,
  Container,
  Grid,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import {
  CustomHeadingChipCenter,
  CustomHeadingSubTitleCenter,
} from "../../global/headings/customHeadings";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ClientsTestimonialCard from "./clientsTestimonialCard";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ClientsTestimonials = () => {
  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
  };
  const slideList = [
    {
      id: 1,
      src: "https://source.unsplash.com/random",
      text: "This year, we have brought UNO.Care on board to organise the annual health camp in our factory. They have delivered highquality medical check-ups, an experience our workers have never had before",
    },
    {
      id: 2,
      src: "https://source.unsplash.com/random",
      text: "Health awareness sessions by UNO.care is their unique o􀈋ering. We sincerely believe that a healthy work force is a more productive workforce",
    },
    {
      id: 3,
      src: "https://source.unsplash.com/random",
      text: "Signing up for UNO.care’s OHC services has been a great decision for us. They have significantly improved the functioning of the centre and we are  experiencing major improvements in our workforce’s health.",
    },
    {
      id: 4,
      src: "https://source.unsplash.com/random",
      text: "UNO.Care has provided necessary equipment for our OHC. It was e􀈋ortless and greatly economical for us",
    },
    {
      id: 5,
      src: "https://source.unsplash.com/random",
      text: "The paramedical sta􀈋 from UNO.Care at our OHC is professionally trained and competent. We are very satisfied with their service.",
    },
    {
      id: 6,
      src: "https://source.unsplash.com/random",
      text: "UNO.Care assisted us in arranging a qualified doctor for our factory's Occupational Health Center. The doctor possesses the necessary qualifications and training to address the health needs of our workers, including emergency situations.",
    },
    {
      id: 7,
      src: "https://source.unsplash.com/random",
      text: "UNO.care makes medicines available at our centre and to our employees. It helps all of us save time and money",
    },
    {
      id: 8,
      src: "https://source.unsplash.com/random",
      text: "It’s a great relief to know that UNO.Care also provides group insurance service. Now, we have a reliable partner to take care of a crucial aspect of business.",
    },
    {
      id: 8,
      src: "https://source.unsplash.com/random",
      text: "It’s a great relief to know that UNO.Care also provides group insurance service. Now, we have a reliable partner to take care of a crucial aspect of business.",
    },
  ];
  const customRenderItem = (item, props) => (
    <item.type {...item.props} {...props} />
  );

  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  return (
    <Box sx={{ marginBlock: "20px", marginBottom: "100px" }}>
      <Container>
        <Grid container spacing={2} sx={{ marginBottom: "50px" }}>
          <Grid item lg={12}>
            <CustomHeadingChipCenter>
              Clients Testimonial
            </CustomHeadingChipCenter>
          </Grid>
          <Grid item lg={12}>
            <CustomHeadingSubTitleCenter>
              What Clients Says About Us!
            </CustomHeadingSubTitleCenter>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "93%" }}>
            <Carousel
              width="100%"
              showThumbs={false}
              showArrows={true}
              showStatus={false}
              autoPlay={true}
              infiniteLoop={true}
              centerMode={true}
              centerSlidePercentage={isSmallScreen ? 100 : 35}
              interval={5000}
              showIndicators={false}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <IconButton
                    title={label}
                    onClick={onClickHandler}
                    style={{
                      ...arrowStyles,
                      left: 15,
                      backgroundColor: "#FFFFFF",
                      marginTop: "10px",
                    }}
                  >
                    <ArrowBackIosIcon style={{ color: "#000000" }} />
                  </IconButton>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <IconButton
                    title={label}
                    onClick={onClickHandler}
                    style={{
                      ...arrowStyles,
                      right: 15,
                      backgroundColor: "#FFFFFF",
                      marginTop: "10px",
                    }}
                  >
                    <ArrowForwardIosIcon style={{ color: "#000000" }} />
                  </IconButton>
                )
              }
              renderItem={customRenderItem}
            >
              {slideList.map((item, index) => (
                <ClientsTestimonialCard key={index} data={item} />
              ))}
            </Carousel>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ClientsTestimonials;
