import { enqueueSnackbar } from "notistack";
import dayjs from "dayjs";
import { BASE_URL } from "../../../../assets/constantsFile";
import { getDataNew } from "../../../../services/api/getApi";
import { updateData } from "../../../../services/api/patchApi";
import { uploadFile } from "../../../../services/api/postApi";
import { ClientTicketTypes } from "../../../../assets/corpConstants";

export const getAllTickets = async (startDate, endDate, corpId, setTicketList) => {
  const url = BASE_URL + `org/getTickets/${startDate}?endDate=${endDate}&corpId=${corpId}`;

  const response = await getDataNew(url);

  if (response.error) {
    console.warn({ error: response.error });
    setTicketList([]);
  } else {
    console.log({ success: response.data });
    setTicketList(response.data?.filter((val) => ClientTicketTypes.includes(val.ticketType)));
  }
};

export const raiseTicket = async (data, handleClose, formData) => {
  const url = BASE_URL + `org/v2/raiseTicket`;

  // let formData = new FormData();

  // formData.append('portal', 'PATIENT_APP');
  // formData.append('empId', empId);
  // formData.append('ticketMode', 'MOBILE_APP');
  // formData.append('ticketRole', ticketRole); //ORG_EMPLOYEE

  console.log({ data });
  formData.append("userAuthId", data.userId);
  formData.append("raisedBy", data.name);
  formData.append("raisedById", data.userId);
  formData.append("raisedByMobileNo", data.mobile);
  formData.append("ticketType", data.selectedTicketType?.ticketType || "");

  formData.append("corpId", data.company?.corpId || "");
  formData.append("corpName", data.company?.orgName || "");
  formData.append("ticketCategory", "CORP");
  formData.append("status", "TICKET_RAISED");

  formData.append(
    "ticketInfo",
    JSON.stringify({
      sessionId: data.sessionType?.id || "",
      sessionDate: data.date ? dayjs(data.date).format("YYYY-MM-DD") : "",
      sessionName: data.sessionType?.sessionName || "",
      empId: data.empId || "",
      employeeName: data.empName || "",
      issue: data.issue || "",
      serviceName: data.serviceName?.value || "",
      remark: data.remark || "",
      remarks: data.remarks || "",
      serviceIssue: data.serviceIssue || "",
      empName: data.empName,
    })
  );

  const res = await uploadFile(url, formData);
  if (res.error) {
    console.warn({ error: res.error });
    enqueueSnackbar("Failed to raise ticket!", {
      variant: "error",
    });
  } else {
    console.log({ success: res.data });
    enqueueSnackbar("Successfully raised ticket.", {
      variant: "success",
    });
    handleClose();
  }
};

export const getSessionTypeList = async (setSessionTypeList) => {
  const url = BASE_URL + `org/awarenessSessions/list`;

  const res = await getDataNew(url);

  if (res.error) {
    console.warn({ error: res.error });
    setSessionTypeList([]);
  } else {
    console.log({ success: res.data });
    setSessionTypeList(res.data);
  }
};

export const getCompanyList = async (setCompanyList) => {
  const url = BASE_URL + `org/reporting/all`;

  const res = await getDataNew(url);

  if (res.error) {
    console.warn({ error: res.error });
    setCompanyList([]);
  } else {
    console.log({ success: res.data });
    setCompanyList(
      res.data
        .filter(
          (value, index, self) => index === self.findIndex((t) => t.orgName === value.orgName)
        )
        .map((value) => ({
          ...value,
          label: value.orgName,
        }))
    );
  }
};

export const updateTicket = async (data, date, status) => {
  const url = BASE_URL + `org/updateTicketStatus`;

  let ticketInfo = data?.ticketInfo;
  ticketInfo
    ? (ticketInfo["sessionDate"] = dayjs(date).format("YYYY-MM-DD"))
    : (ticketInfo = {
        sessionId: "",
        sessionDate: dayjs(date).format("YYYY-MM-DD"),
        sessionName: "",
      });

  const payload = {
    ticketId: data?.ticketId,
    ticketInfo: ticketInfo,
    status: status?.value,
  };
  const res = await updateData(url, payload);
  if (res.error) {
    console.warn({ error: res.error });
    enqueueSnackbar("Failed to update ticket!", {
      variant: "error",
    });
  } else {
    console.log({ success: res.data });
    enqueueSnackbar("Successfully updated ticket.", {
      variant: "success",
    });
  }
};
