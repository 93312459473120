import {
  Alert,
  Autocomplete,
  Button,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import NavBar from "../navbar/navBar";
import { getDataWithoutToken } from "../../../services/api/getApi";
import { saveDataWithoutAuth } from "../../../services/api/postApi";
import { BASE_URL } from "../../../assets/constantsFile";
import { useLocation } from "react-router";

const RaiseTicketMain = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const branchId = searchParams.get("branchId");

  console.log({ branchIdfromURL: branchId });

  const [selectedRoom, setSelectedRoom] = useState(null);

  const [formValues, setFormValues] = useState({
    raisedBy: "",
    raisedByMobile: "",
    complain: "",
  });

  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");
  const [openNotice, setOpenNotice] = useState(false);

  const handleClickNotice = () => {
    setOpenNotice(true);
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotice(false);
  };

  const [roomList, setRoomList] = useState([]);

  const fetchRoomNameList = async () => {
    // const branchId = "3fa85f64-5717-4562-b3fc-2c963f66afa6";
    const url = BASE_URL + `securityApp/public/room/search?branchId=${branchId}`;
    const data = await getDataWithoutToken(url);
    let tempData = [];
    if (data.error) {
      console.log({ "error getting Room Name List": data.error });
      console.log({ URL: url, STATUS: data.error });
      setRoomList([]);
    } else {
      tempData = data.data;
      setRoomList(tempData);
    }
  };

  useEffect(() => {
    fetchRoomNameList();
  }, []);

  const handleRaiseTicket = async () => {
    if (selectedRoom === null || selectedRoom === "") {
      setSeverity("error");
      setMessage("Room Cannot be Empty");
      setOpenNotice(true);
    } else if (formValues.complain === "") {
      setSeverity("error");
      setMessage("Complain be Empty");
      setOpenNotice(true);
    } else {
      // const branchId = "3fa85f64-5717-4562-b3fc-2c963f66afa6";
      const payload = {
        complain: formValues.complain,
        remarks: formValues.complain,
        branchId: branchId,
        roomId: selectedRoom?.roomId,
        raisedBy: formValues.raisedBy,
        raisedByMobileNo: formValues.raisedByMobile,
        ticketType: "SECURITY_APP",
        ticketCategory: "SECURITY_APP",
        ticketStatus: "TICKET_RAISED",
      };
      const url = BASE_URL + "securityApp/raiseTicket";
      const result = await saveDataWithoutAuth(url, payload);
      if (result?.data) {
        console.log({ SUCCESS: result?.data });
        setFormValues({
          raisedBy: "",
          raisedByMobile: "",
          complain: "",
        });
        setSeverity("success");
        setMessage("Saved successfully!");
        setOpenNotice(true);
        setSelectedRoom(null);
      } else {
        console.log({ error: result?.error });
        setSeverity("error");
        setMessage("An error occured");
        setOpenNotice(true);
      }
    }
  };

  console.log(selectedRoom);

  return (
    <Fragment>
      <Snackbar open={openNotice} autoHideDuration={2000} onClose={handleCloseNotice}>
        <Alert onClose={handleCloseNotice} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <NavBar />

      <Typography variant="h4" textAlign={"center"} sx={{ marginBlock: "20px" }}>
        Raise Ticket
      </Typography>
      <Container>
        <Grid container rowSpacing={2} sx={{ justifyContent: "center", alignItems: "center" }}>
          <Grid item xs={12} lg={12}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: "15px",
                color: "#6B6B6B",
                marginBlock: "5px",
              }}>
              Room Number
            </Typography>
            <Autocomplete
              fullWidth
              freeSolo
              disableClearable
              size="small"
              options={roomList}
              getOptionLabel={(option) => `${option.roomNumber}`}
              value={selectedRoom}
              onChange={(event, newValue) => {
                // Check if Autocomplete is cleared, then set selectedRoom to null
                setSelectedRoom(newValue === null ? null : newValue);
              }}
              onInputChange={(event, newInputValue) => {
                // If the input value is empty, set selectedRoom to null
                if (!newInputValue) {
                  setSelectedRoom(null);
                }
              }}
              renderOption={(props, option) => (
                <li {...props}>
                  <div>{option.roomNumber}</div>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    background: "#fff",
                    color: "#127DDD",
                    fontWeight: "300",
                    fontSize: "13px",
                    lineHeight: "15px",
                    "& input::placeholder": {
                      color: "#777777",
                      fontWeight: "300",
                      fontSize: "13px",
                      lineHeight: "15px",
                    },
                  }}
                  variant="outlined"
                  placeholder="Enter Room"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  error={selectedRoom === null ? true : false}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: "15px",
                color: "#6B6B6B",
                marginBlock: "5px",
              }}>
              Raised By
            </Typography>
            <TextField
              sx={{
                background: "#fff",
                color: "#127DDD",
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              placeholder="Enter Name"
              value={formValues.raisedBy}
              onChange={(e) => {
                setFormValues({ ...formValues, raisedBy: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12} lg={12}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: "15px",
                color: "#6B6B6B",
                marginBlock: "5px",
              }}>
              Raised By Mobile
            </Typography>
            <TextField
              sx={{
                background: "#fff",
                color: "#127DDD",
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              placeholder="Enter Mobile Number"
              value={formValues.raisedByMobile}
              onChange={(e) => {
                if (!isNaN(e.target.value) && e.target.value.length < 11) {
                  setFormValues({ ...formValues, raisedByMobile: e.target.value });
                }
              }}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: "15px",
                color: "#6B6B6B",
                marginBlock: "5px",
              }}>
              Complain
            </Typography>

            <TextField
              multiline
              sx={{
                background: "#fff",
                color: "#127DDD",
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: " 15px",
                "& input::placeholder": {
                  color: "#777777",
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: " 15px",
                },
              }}
              size="small"
              fullWidth
              placeholder="Enter complain"
              value={formValues.complain}
              onChange={(e) => {
                setFormValues({ ...formValues, complain: e.target.value });
              }}
              inputProps={{
                style: {
                  height: 100,
                },
              }}
              error={formValues.complain === "" ? true : false}
            />
          </Grid>
          <Grid item xs={12} lg={12} textAlign={"center"}>
            <Button
              onClick={() => {
                handleRaiseTicket();
              }}
              variant="contained"
              style={{
                borderRadius: "15px",
                height: "40px",
                width: "200px",
                textTransform: "none",
              }}>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "13px",
                  lineHeight: "15px",
                  color: "#FFFFFF",
                }}>
                Save
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default RaiseTicketMain;
