import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DashboardStats from "./comps/dashboardStats";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../../../assets/constantsFile";
import { getData } from "../../../../services/api/getApi";
import VitalsGraphs from "./comps/vitalsGraphs";
import VitalsGraphMahindra from "./comps/vitalsGraphMahindra";
import EmploymentTypeMahindra from "./comps/employmentTypeMahindra";
import SelectCamp from "../../../global/selectCamp/selectCamp";

const TestWise = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [orgStats, setOrgStats] = useState({ id: 0 });
  const [totalStats, setTotalStats] = useState();
  const [selectedCampId, setSelectedCampId] = useState("");
  const fetchOrgStats = async () => {
    if (selectedCampId !== "") {
      const url =
        BASE_URL +
        `org/stats?corpId=${corpId}&campCycleId=${
          selectedCampId === "null" ? "" : selectedCampId
        }`;
      const stats = await getData(url, "");
      if (stats.error) {
      } else {
        console.log({ orgstats: Object.entries(stats.data) });
        setOrgStats(stats.data);
        setTotalStats(stats.data["TOTAL STATS"]);
      }
    }
  };

  useEffect(() => {
    fetchOrgStats();
  }, [selectedCampId]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchOrgStats();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setTotalStats(orgStats["TOTAL STATS"]);
  }, [orgStats]);

  console.log({ totalStats });

  const [departmentList, setDepartmentList] = useState([]);
  const fetchDepartmentList = async () => {
    const url = BASE_URL + `org/departments?corpId=${corpId}`;

    const departments = await getData(url, "");
    if (departments.error) {
    } else {
      setDepartmentList(departments.data);
    }
  };

  useEffect(() => {
    fetchDepartmentList();
  }, []);

  const fetchOrgStatsByDepartment = async (department) => {
    let url = "";
    if (department) {
      url = BASE_URL + `org/stats?corpId=${corpId}&department=${department}`;
    } else {
      url =
        BASE_URL +
        `org/stats?corpId=${corpId}${
          selectedCampId === "null" ? "" : selectedCampId
        }`;
    }
    const stats = await getData(url, "");

    if (stats.error) {
    } else {
      setOrgStats(stats.data);
    }
  };

  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedEmployementType, setSelectedEmploymentType] = useState("ALL");
  console.log({ selectedEmployementType });
  return (
    <Container maxWidth={false} sx={{ backgroundColor: "#F5F5F5" }}>
      <Box sx={{ pt: 2, pb: 14 }}>
        <Grid container rowSpacing={4} columnSpacing={2}>
          <Grid item xs={2} md={2} sm={12} lg={12}>
            <SelectCamp
              selectedCampId={selectedCampId}
              setSelectedCampId={setSelectedCampId}
            />
          </Grid>
          <Grid item xs={12} md={8} sm={12} lg={8}>
            <DashboardStats
              totalStats={totalStats}
              hideCalendar={true}
              shaktiPumpCheck={
                corpId === "28372173-fbba-4e78-958d-ebefad5c4498" &&
                selectedCampId === 138856
              }
            />
          </Grid>
          <Grid item xs={12} md={4} sm={12} lg={4}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {corpId === "455f4926-ea38-476d-ad49-0c3587c65200" ? (
                  <Box
                    sx={{
                      boxSizing: "border-box",
                      background: "#FFFFFF",
                      border: "0.5px solid #979797",
                      borderRadius: 5,
                      p: 2,
                    }}
                  >
                    <Stack
                      direction="row"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Button
                        fullWidth
                        onClick={() => {
                          fetchOrgStatsByDepartment(null);
                          setSelectedDepartment(null);
                        }}
                        size="small"
                      >
                        <Typography
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: 13,
                            color: "#404040",
                            textTransform: "capitalize",
                          }}
                        >
                          Show All
                        </Typography>
                      </Button>
                      <Box
                        sx={{
                          width: 50,
                          height: 0,
                          border: "0.5px solid #6B6B6B",
                          transform: "rotate(90deg)",
                        }}
                      />
                      <EmploymentTypeMahindra
                        selectedEmploymentType={selectedEmployementType}
                        setSelectedEmploymentType={setSelectedEmploymentType}
                      />

                      <Box
                        sx={{
                          width: 50,
                          height: 0,
                          border: "0.5px solid #6B6B6B",
                          transform: "rotate(90deg)",
                        }}
                      />
                      <Autocomplete
                        value={selectedDepartment}
                        onChange={(event, newValue) => {
                          setSelectedDepartment(newValue);
                          fetchOrgStatsByDepartment(newValue);
                          console.log({ newValue });
                        }}
                        sx={{ width: 600 }}
                        size="small"
                        fullWidth
                        disablePortal
                        options={departmentList}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ "& fieldset": { border: "none" } }}
                            label="Select Department"
                            InputLabelProps={{
                              style: {
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: "500",
                                fontSize: 13,
                                color: "#404040",
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      boxSizing: "border-box",
                      background: "#FFFFFF",
                      border: "0.5px solid #979797",
                      borderRadius: 5,
                      p: 2,
                    }}
                  >
                    <Stack
                      direction="row"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Button
                        fullWidth
                        onClick={() => {
                          fetchOrgStatsByDepartment(null);
                          setSelectedDepartment(null);
                        }}
                        size="small"
                      >
                        <Typography
                          sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: 13,
                            color: "#404040",
                            textTransform: "capitalize",
                          }}
                        >
                          Show All
                        </Typography>
                      </Button>
                      <Box
                        sx={{
                          width: 50,
                          height: 0,
                          border: "0.5px solid #6B6B6B",
                          transform: "rotate(90deg)",
                        }}
                      />
                      <Autocomplete
                        value={selectedDepartment}
                        onChange={(event, newValue) => {
                          setSelectedDepartment(newValue);
                          fetchOrgStatsByDepartment(newValue);
                          console.log({ newValue });
                        }}
                        sx={{ width: 600 }}
                        size="small"
                        fullWidth
                        disablePortal
                        options={departmentList}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ "& fieldset": { border: "none" } }}
                            label="Select Department"
                            InputLabelProps={{
                              style: {
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: "500",
                                fontSize: 13,
                                color: "#404040",
                              },
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} sm={12} lg={12}>
            {corpId === "455f4926-ea38-476d-ad49-0c3587c65200" ? (
              <VitalsGraphMahindra
                selectedDepartment={selectedDepartment}
                selectedEmployementType={selectedEmployementType}
                selectedCampId={selectedCampId}
              />
            ) : (
              <VitalsGraphs
                selectedDepartment={selectedDepartment}
                selectedCampId={selectedCampId}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default TestWise;
