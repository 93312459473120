import { Typography } from "@mui/material";

export const CustomTypography = ({ children }) => {
  return (
    <Typography
      sx={{
        color: "#000",
        fontFamily: "Poppins",
        fontSize: 10,
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "normal",
      }}>
      {children}
    </Typography>
  );
};

export const CustomTypographyText = ({ children }) => {
  return (
    <Typography
      noWrap
      sx={{
        color: "#000",
        fontFamily: "Poppins",
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
      }}>
      {children}
    </Typography>
  );
};

export const CustomTypographyTextStatus = ({ children }) => {
  return (
    <Typography
      sx={{
        color: "#000",
        fontFamily: "Poppins",
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
      }}>
      {children}
    </Typography>
  );
};
