import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

const InsuranceForm = () => {
  return (
    <Container>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} textAlign="center">
            <Typography>Number of Employess</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField size="small" fullWidth label="Total Number of Employees" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <TextField size="small" fullWidth label="3 months to 18 years" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <TextField size="small" fullWidth label="19-35 year" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <TextField size="small" fullWidth label="36-45 year" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <TextField size="small" fullWidth label="45-55 Years" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <TextField size="small" fullWidth label="56-65 years" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <TextField size="small" fullWidth label="66-70 years" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <TextField size="small" fullWidth label="71-75 years" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <TextField size="small" fullWidth label="76-80 Years" />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} textAlign="center">
            <Typography>Type of Policy Required</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} textAlign="center">
            <FormGroup row sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Workmen Compensation polict"
              />
              <FormControlLabel control={<Checkbox />} label="Group Health Insurance" />
              <FormControlLabel control={<Checkbox />} label="Un-named Policy" />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} textAlign="center">
            <Typography>Add-Ons Required</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} textAlign="center">
            <FormGroup row sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Pre-Existing disease Cover"
              />
              <FormControlLabel control={<Checkbox />} label="Maternity Benifit" />
              <FormControlLabel control={<Checkbox />} label="No Capping on Room Rent" />
              <FormControlLabel control={<Checkbox />} label="0% copayment by employee" />
            </FormGroup>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default InsuranceForm;
