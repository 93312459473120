import React, { Fragment, useEffect, useState } from "react";
import { BASE_URL } from "../../../../assets/constantsFile";
import { getData } from "../../../../services/api/getApi";
import YearOnYearMultiBarChart from "./comps/yearOnYearMultiBarChart";
import YearOnYearStackedBarChart from "./comps/yearOnYearStackedBarChart";
import { Box, CircularProgress, Container, Grid } from "@mui/material";

const YearOnYearComparsion = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [comparisonData, setComparsionData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const fetchComparsionData = async () => {
    setIsLoading(true);
    const url = BASE_URL + `org/ahcStatusTracker/comparative?corpId=${corpId}`;
    const result = await getData(url);
    if (result.error) {
      setComparsionData({});
      setIsLoading(false);
    } else {
      setComparsionData(result.data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchComparsionData();
  }, []);

  const [comparisonEmpData, setComparsionEmpData] = useState({});
  const fetchComparsionEmpData = async () => {
    setIsLoading2(true);
    const url = BASE_URL + `org/ahcStatusDepartmentsData/comparative/${corpId}`;
    const result = await getData(url);
    if (result.error) {
      setComparsionEmpData({});
      setIsLoading2(false);
    } else {
      setComparsionEmpData(result.data);
      setIsLoading2(false);
    }
  };

  useEffect(() => {
    fetchComparsionEmpData();
  }, []);

  const [listOfCampId, setListOfCampId] = useState([]);
  const fetchListOfCamp = async () => {
    const url = BASE_URL + "org/camp/list/" + corpId;
    const result = await getData(url);
    if (result.error) {
      setListOfCampId([]);
    } else {
      setListOfCampId(result?.data);
    }
  };

  useEffect(() => {
    fetchListOfCamp();
  }, []);

  const convertComparisonData = (comparisonData) => {
    const multiCategoryData = {};
    const twoCategoryData = {};

    Object.keys(comparisonData || {}).forEach((camp, index) => {
      const graphValueMap = comparisonData[camp].graphValueMap;
      const campKey = listOfCampId.find(
        (item) => item?.id === parseInt(camp)
      )?.displayName;

      Object.keys(graphValueMap || {}).forEach((parameter) => {
        const categories = graphValueMap[parameter];

        if (Object.keys(categories || {}).length > 0) {
          // Multi-Category Parameters
          if (!multiCategoryData[parameter]) {
            multiCategoryData[parameter] = [];
          }

          Object.keys(categories || {}).forEach((category) => {
            const existingCategory = multiCategoryData[parameter].find(
              (item) => item.name === category
            );
            const categoryData = {
              [`${campKey}_percent`]: categories[category].percent,
              [`${campKey}_value`]: categories[category].value,
            };

            if (existingCategory) {
              Object.assign(existingCategory, categoryData);
            } else {
              multiCategoryData[parameter].push({
                name: category,
                ...categoryData,
              });
            }
          });
        }
        // else if (Object.keys(categories).length === 2) {
        //   // Two-Category Parameters (e.g., Urine, Audio, X-Ray, CBC)
        //   if (!twoCategoryData[parameter]) {
        //     twoCategoryData[parameter] = [];
        //   }

        //   const normalData = {
        //     [`${campKey}_Normal_percent`]: categories.NORMAL?.percent || 0,
        //     [`${campKey}_Normal_value`]: categories.NORMAL?.value || 0,
        //   };
        //   const medicationAdvisedData = {
        //     [`${campKey}_MedicationAdvised_percent`]:
        //       categories["MEDICATION ADVISED"]?.percent || 0,
        //     [`${campKey}_MedicationAdvised_value`]:
        //       categories["MEDICATION ADVISED"]?.value || 0,
        //   };

        //   twoCategoryData[parameter].push({
        //     name: campKey,
        //     ...normalData,
        //     ...medicationAdvisedData,
        //   });
        // }
      });
    });
    return { multiCategoryData, twoCategoryData };
  };

  const transformDataToDisplayNames = (transformData, listOfCampId) => {
    // Ensure listOfCampId is an array; use an empty array if it's not defined or invalid
    const validListOfCampId = Array.isArray(listOfCampId) ? listOfCampId : [];

    // Create a map for easy lookup of display names by campCycleId
    const campIdToDisplayName = validListOfCampId.reduce((map, item) => {
      if (item?.id) {
        map[item.id] = item.displayName;
      }
      return map;
    }, {});

    console.log({ campIdToDisplayName });

    // Create a new object with display names as keys
    const transformedData = {};

    Object.keys(transformData).forEach((campId) => {
      const displayName = campIdToDisplayName[campId];
      transformedData[displayName] = transformData[campId];
    });

    return transformedData;
  };

  const transformEmployeeData = (employeeData) => {
    const transformedData = {};

    Object.keys(employeeData).forEach((campId) => {
      const campData = employeeData[campId];
      const parameterData = campData.dataMaps;

      transformedData[campId] = {};

      Object.keys(parameterData).forEach((parameter) => {
        transformedData[campId][parameter] = {};

        Object.keys(parameterData[parameter]).forEach((category) => {
          transformedData[campId][parameter][category] =
            parameterData[parameter][category];
        });
      });
    });
    return transformDataToDisplayNames(transformedData, listOfCampId);
  };

  const transformedEmployeeData = transformEmployeeData(comparisonEmpData);

  const { multiCategoryData, twoCategoryData } =
    convertComparisonData(comparisonData);

  console.log({ multiCategoryData, twoCategoryData, transformedEmployeeData });

  if (isLoading || isLoading2) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Fragment>
      <Container maxWidth={false} sx={{ mt: 2 }}>
        <Grid container spacing={2} sx={{ display: "flex" }}>
          {checkIsListIsNotNull(multiCategoryData.BMI) &&
            filterArrayByCamps(multiCategoryData.BMI) && (
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <YearOnYearMultiBarChart
                  data={multiCategoryData.BMI}
                  dialogData={transformedEmployeeData}
                  graphHeight={250}
                  title={"BMI Chart"}
                  testKeyName={"BMI"}
                />
              </Grid>
            )}

          {checkIsListIsNotNull(multiCategoryData.BP) &&
            filterArrayByCamps(multiCategoryData.BP) && (
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <YearOnYearMultiBarChart
                  data={multiCategoryData.BP}
                  dialogData={transformedEmployeeData}
                  graphHeight={250}
                  title={"BP Chart"}
                  testKeyName={"BP"}
                />
              </Grid>
            )}

          {checkIsListIsNotNull(multiCategoryData.SUGAR) &&
            filterArrayByCamps(multiCategoryData.SUGAR) && (
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <YearOnYearMultiBarChart
                  data={multiCategoryData.SUGAR}
                  dialogData={transformedEmployeeData}
                  graphHeight={250}
                  title={"Sugar Chart"}
                  testKeyName={"SUGAR"}
                />
              </Grid>
            )}

          {checkIsListIsNotNull(multiCategoryData.EYE_TEST) &&
            filterArrayByCamps(multiCategoryData.EYE_TEST) && (
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <YearOnYearMultiBarChart
                  data={multiCategoryData.EYE_TEST}
                  dialogData={transformedEmployeeData}
                  graphHeight={250}
                  title={"Eye Chart"}
                  testKeyName={"EYE_TEST"}
                />
              </Grid>
            )}

          {checkIsListIsNotNull(multiCategoryData.HAEMOGLOBIN) &&
            filterArrayByCamps(multiCategoryData.HAEMOGLOBIN) && (
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <YearOnYearMultiBarChart
                  data={multiCategoryData.HAEMOGLOBIN}
                  dialogData={transformedEmployeeData}
                  graphHeight={250}
                  title={"Haemoglobin Chart"}
                  testKeyName={"HAEMOGLOBIN"}
                />
              </Grid>
            )}

          {checkIsListIsNotNull(multiCategoryData.ESR) &&
            filterArrayByCamps(multiCategoryData.ESR) && (
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <YearOnYearMultiBarChart
                  data={multiCategoryData.ESR}
                  dialogData={transformedEmployeeData}
                  graphHeight={250}
                  title={"ESR Chart"}
                  testKeyName={"ESR"}
                />
              </Grid>
            )}

          {checkIsListIsNotNull(multiCategoryData.PFT) &&
            filterArrayByCamps(multiCategoryData.PFT) && (
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <YearOnYearMultiBarChart
                  data={multiCategoryData.PFT}
                  dialogData={transformedEmployeeData}
                  graphHeight={250}
                  title={"PFT Chart"}
                  testKeyName={"PFT"}
                />
              </Grid>
            )}

          {checkIsListIsNotNull(multiCategoryData.URINE) &&
            filterArrayByCamps(multiCategoryData.URINE) && (
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <YearOnYearMultiBarChart
                  data={multiCategoryData.URINE}
                  dialogData={transformedEmployeeData}
                  graphHeight={250}
                  title={"Urine Chart"}
                  testKeyName={"URINE"}
                />
              </Grid>
              // <Grid item xs={12} sm={12} md={4} lg={4}>
              //   <YearOnYearStackedBarChart
              //     data={twoCategoryData.URINE}
              //     graphHeight={250}
              //     title={"Urine Chart"}
              //   />{" "}
              // </Grid>
            )}

          {checkIsListIsNotNull(twoCategoryData.AUDIOMETRY) &&
            filterArrayByCamps(multiCategoryData.AUDIOMETRY) && (
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <YearOnYearStackedBarChart
                  data={twoCategoryData.AUDIOMETRY}
                  dialogData={transformedEmployeeData}
                  graphHeight={250}
                  title={"Audiometry Chart"}
                  testKeyName={"AUDIOMETRY"}
                />{" "}
              </Grid>
            )}

          {checkIsListIsNotNull(twoCategoryData.XRAY) &&
            filterArrayByCamps(multiCategoryData.XRAY) && (
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <YearOnYearStackedBarChart
                  data={twoCategoryData.XRAY}
                  dialogData={transformedEmployeeData}
                  graphHeight={250}
                  title={"Xray Chart"}
                  testKeyName={"XRAY"}
                />{" "}
              </Grid>
            )}

          {checkIsListIsNotNull(twoCategoryData.CBC) &&
            filterArrayByCamps(multiCategoryData.CBC) && (
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <YearOnYearStackedBarChart
                  data={twoCategoryData.CBC}
                  dialogData={transformedEmployeeData}
                  graphHeight={250}
                  title={"CBC Chart"}
                  testKeyName={"CBC"}
                />{" "}
              </Grid>
            )}
        </Grid>
      </Container>
    </Fragment>
  );
};

export default YearOnYearComparsion;

const checkIsListIsNotNull = (array) => {
  return array && array.length > 0, array?.some((item) => item.value !== 0);
};

const filterArrayByCamps = (dataArray) => {
  if (!Array.isArray(dataArray) || dataArray.length === 0) {
    return null;
  }

  // Collect all unique camp keys from the objects
  const uniqueCamps = new Set();

  dataArray.forEach((item) => {
    Object.keys(item).forEach((key) => {
      // Match keys that have a date-like pattern followed by _percent or _value
      if (key.match(/.*_(percent|value)$/)) {
        // Extract the part before "_percent" or "_value"
        const campKey = key.split("_")[0];
        uniqueCamps.add(campKey);
      }
    });
  });

  // Check if the number of unique camps is less than 2
  if (uniqueCamps.size < 2) {
    return null;
  }

  return dataArray;
};
