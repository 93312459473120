import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { colors } from "../../../assets/color";
import { useNavigate } from "react-router";

const PatientInfoBar = ({ patient }) => {
  const navigate = useNavigate();

  const gender = patient?.gender.slice(0, 1);
  const age = patient?.age;
  const genderAndAge = gender + (age ? ", " + age : "");
  const Issue = patient?.issue || "Issue Not reported";

  return (
    <Box sx={{ backgroundColor: "#fff", borderRadius: 2 }}>
      {localStorage.getItem("DOCID") !==
        "33133431-bc45-42b9-b921-56e245a91654" && (
        <Grid container sx={{ p: 1, px: 2 }}>
          <Grid item lg={1}>
            <IconButton aria-label="back" onClick={() => navigate("/patients")}>
              <ArrowBackIcon fontSize="large" color={colors.black} />
            </IconButton>
          </Grid>
          <Grid item lg={5}>
            <Typography sx={{ fontWeight: 700, fontSize: 16 }}>
              {patient?.name}
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: 16,
                color: "#6B6B6B",
              }}
            >
              {genderAndAge}
            </Typography>
          </Grid>
          <Grid item lg={6}>
            <Box
              sx={{
                background: "#F1FAFF",
                height: "40px",
                borderRadius: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: 2,
                  color: colors.primary,
                }}
              >
                {Issue}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default PatientInfoBar;
