import { Box, Grid, Typography } from "@mui/material";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { getData } from "../../../../../services/api/getApi";
import { Fragment, useEffect, useState } from "react";
import BPRangeIMG from "../../../../assets/images/bp-range-chart3.png";
import CustomDoughnutChartNew from "./customDoughnutChartNew";
import StackedBarChart from "./stackedBarChart";
import { listSortedByProp } from "../../../../global/actions/actions";

const formatFloat = (val, total) =>
  parseFloat(((val * 100) / total).toFixed(2));

const getEmpBreakupLabels = (id) => {
  let labels = {
    ONROLLSMALL: "OnRoll",
    ONROLL: "ONROLL",
    CONTRACTORSMALL: "Contractor",
    CONTRACTOR: "CONTRACTOR",
  };

  if (id === "455f4926-ea38-476d-ad49-0c3587c65200") {
    labels = {
      ONROLLSMALL: "Officers",
      ONROLL: "OFFICERS",
      CONTRACTORSMALL: "Workers",
      CONTRACTOR: "WORKERS",
    };
  } else if (id === "c59a2fbc-bc3d-453c-aae4-313581793890") {
    labels = {
      ONROLLSMALL: "Staff",
      ONROLL: "STAFF",
      CONTRACTORSMALL: "OffRoll",
      CONTRACTOR: "OFFROLL",
    };
  }

  return labels;
};

const graphList = (
  value,
  graphValue,
  config,
  customLables,
  shaktipumpCheck
) => {
  const tempMap = new Map();
  config?.map((val) => {
    tempMap.set(val?.nameId, val?.access);
  });
  console.log({ shaktipumpCheck, tempMap });

  let empBreakupData = listSortedByProp(value?.EMPLOYEE_BREAKUP);
  empBreakupData = empBreakupData?.map((item) => ({
    count: item?.count,
    name: item?.name,
    legendLabel: customLables?.[item?.name],
    sequence: item?.sequence,
    value: item?.value,
  }));

  console.log({ 111: listSortedByProp(value?.URINE) });
  console.log({ 222: graphValue?.URINE });

  console.log({ employeebreakup: empBreakupData });
  let list = [
    {
      id: 1,
      type: "NONBPVITALS",
      name: "sugar",
      title: "Sugar Chart Percentage",
      labelHeading: "Total #",
      isActive:
        tempMap.get("SugarPercent") &&
        listSortedByProp(value?.SUGAR)?.length !== 0 &&
        !listSortedByProp(value?.SUGAR)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: [
        "#127DDD",
        "#458BCC",
        "#42BD53",
        "#FFE066",
        "#F8A649",
        "#F66",
        "#AA0101",
      ],
      chartdata: listSortedByProp(value?.SUGAR),
      dialogData: graphValue?.SUGAR,
    },
    {
      id: 2,
      type: "NONBPVITALS",
      name: "creatinine",
      title: "Creatinine Chart Percentage",
      labelHeading: "Total #",
      isActive:
        tempMap.get("CreatininePercent") &&
        listSortedByProp(value?.CREATININE)?.length !== 0 &&
        !listSortedByProp(value?.CREATININE)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      labelWidth: "120px",
      colors: ["#127DDD", "#42BD53", "#F66"],
      chartdata: listSortedByProp(value?.CREATININE),
      dialogData: graphValue?.CREATININE,
    },
    {
      id: 3,
      type: "NONBPVITALS",
      name: "eyetest",
      title: "Eye Test Chart",
      labelHeading: "Total #",
      isActive:
        tempMap.get("EyeTest") &&
        listSortedByProp(value?.EYE_TEST)?.length !== 0 &&
        !listSortedByProp(value?.EYE_TEST)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#FFE066", "#F66", "#F8A649", "#42BD53"],
      chartdata: listSortedByProp(value?.EYE_TEST),
      dialogData: graphValue?.EYE_TEST,
    },
    {
      id: 4,
      type: "NONBPVITALS",
      name: "sgpt",
      title: "SGPT Chart Percentage",
      labelHeading: "Total #",
      isActive:
        tempMap.get("SgptPercent") &&
        listSortedByProp(value?.SGPT)?.length !== 0 &&
        !listSortedByProp(value?.SGPT)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#FFE066", "#F8A649", "#F66"],
      chartdata: listSortedByProp(value?.SGPT),
      dialogData: graphValue?.SGPT,
    },
    {
      id: 5,
      type: "NONBPVITALS",
      name: "sgot",
      title: "SGOT Chart Percentage",
      labelHeading: "Total #",
      isActive:
        tempMap.get("SgotPercent") &&
        listSortedByProp(value?.SGOT)?.length !== 0 &&
        !listSortedByProp(value?.SGOT)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#FFE066", "#F8A649", "#F66"],
      chartdata: listSortedByProp(value?.SGOT),
      dialogData: graphValue?.SGOT,
    },
    {
      id: 6,
      type: "NONBPVITALS",
      name: "urine",
      title: "Urine Chart",
      labelHeading: "Total #",
      isActive:
        tempMap.get("UrinePercent") &&
        listSortedByProp(value?.URINE)?.length !== 0 &&
        !listSortedByProp(value?.URINE)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#F66"],
      chartdata: shaktipumpCheck
        ? listSortedByProp(value?.URINE)
        : listSortedByProp(value?.URINE),
      dialogData: graphValue?.URINE,
      // chartdata: listSortedByProp(value?.URINE),
      // dialogData: graphValue?.URINE,
    },
    {
      id: 7,
      type: "NONBPVITALS",
      name: "hepatitis",
      title: "Hepatitis Chart",
      labelHeading: "Total #",
      isActive:
        tempMap.get("HepatitisPercentHepatitisPercent") &&
        listSortedByProp(value?.HEPATITIS)?.length !== 0 &&
        !listSortedByProp(value?.HEPATITIS)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#F66"],
      chartdata: listSortedByProp(value?.HEPATITIS),
      dialogData: graphValue?.HEPATITIS,
    },
    {
      id: 8,
      type: "NONBPVITALS",
      name: "bmi",
      title: "BMI Chart",
      labelHeading: "Total #",
      isActive:
        tempMap.get("BMIPercent") &&
        listSortedByProp(value?.BMI)?.length !== 0 &&
        !listSortedByProp(value?.BMI)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#458BCC", "#42BD53", "#FFE066", "#F8A649", "#F66"],
      chartdata: listSortedByProp(value?.BMI),
      dialogData: graphValue?.BMI,
    },
    {
      id: 9,
      type: "NONBPVITALS",
      name: "cholestrol",
      title: "Cholestrol Chart",
      labelHeading: "Total #",
      isActive:
        tempMap.get("ChlestrolPercent") &&
        listSortedByProp(value?.TOTAL_CHOLESTROL)?.length !== 0 &&
        !listSortedByProp(value?.TOTAL_CHOLESTROL)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#F8A649", "#F66"],
      chartdata: listSortedByProp(value?.TOTAL_CHOLESTROL),
      dialogData: graphValue?.TOTAL_CHOLESTROL,
    },
    {
      id: 10,
      type: "NONBPVITALS",
      name: "ldl",
      title: "LDL Chart",
      labelHeading: "Total #",
      isActive:
        tempMap.get("LDLPercent") &&
        listSortedByProp(value?.LDL)?.length !== 0 &&
        !listSortedByProp(value?.LDL)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#458BCC", "#42BD53", "#F66"],
      chartdata: listSortedByProp(value?.LDL),
      dialogData: graphValue?.LDL,
    },
    {
      id: 11,
      type: "NONBPVITALS",
      name: "hdl",
      title: "HDL Chart",
      labelHeading: "Total #",
      isActive:
        tempMap.get("HDLPercent") &&
        listSortedByProp(value?.HDL)?.length !== 0 &&
        !listSortedByProp(value?.HDL)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#458BCC"],
      chartdata: listSortedByProp(value?.HDL),
      dialogData: graphValue?.HDL,
    },
    {
      id: 40,
      type: "NONBPVITALS",
      name: "pft",
      title: "PFT Chart",
      labelHeading: "Total #",
      isActive:
        tempMap.get("PFTPercent") &&
        listSortedByProp(value?.PFT)?.length !== 0 &&
        !listSortedByProp(value?.PFT)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#FFE066", "#F8A649", "#F66", "#AA0101"],
      chartdata: listSortedByProp(value?.PFT),
      dialogData: graphValue?.PFT,
    },
    {
      id: 40,
      type: "NONBPVITALS",
      name: "ESR",
      title: "ESR Chart Percentage",
      labelHeading: "Total #",
      isActive:
        tempMap.get("EsrPercent") &&
        listSortedByProp(value?.ESR)?.length !== 0 &&
        !listSortedByProp(value?.ESR)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#FFE066", "#F8A649", "#F66", "#AA0101"],
      chartdata: listSortedByProp(value?.ESR),
      dialogData: graphValue?.ESR,
    },
    {
      id: 12,
      type: "BPVITALS",
      name: "BP",
      title: "BP Chart Percentage",
      labelHeading: "Total #",
      labelWidth: "200px",
      isActive:
        tempMap.get("BpPercent") &&
        listSortedByProp(value?.BP)?.length !== 0 &&
        !listSortedByProp(value?.BP)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#458BCC", "#42BD53", "#F8A649", "#F07C7F", "#F66"],
      chartdata: listSortedByProp(value?.BP),
      dialogData: graphValue?.BP,
    },
    {
      id: 13,
      type: "EMPLOYEESBREAKUP",
      name: "employeesbreakup",
      title: "Employees Breakup",
      labelHeading: "Total #",
      isActive:
        tempMap.get("EmployeesBreakUp") &&
        listSortedByProp(empBreakupData)?.length !== 0 &&
        !listSortedByProp(empBreakupData)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: [
        "#006AFF",
        "#FFE066",
        "#42BD53",
        "#F66",
        "#127DDD",
        "#D45FFF",
        "#DDFFDF",
      ],
      chartdata: empBreakupData,
      dialogData: graphValue?.EMPLOYEE_BREAKUP,
    },
    {
      id: 14,
      type: "EMPLOYEESBREAKUP",
      name: "onRollCheckStatus",
      title: `${customLables?.ONROLLSMALL} Employees Test Status`,
      labelHeading: "Total #",
      isActive:
        tempMap.get("OnRollEmployeesCheckupStatus") &&
        listSortedByProp(value?.CHECKUP_STATUS_ONROLL)?.length !== 0 &&
        !listSortedByProp(value?.CHECKUP_STATUS_ONROLL)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#F66"],
      chartdata: listSortedByProp(value?.CHECKUP_STATUS_ONROLL),
      dialogData: graphValue?.CHECKUP_STATUS_ONROLL,
    },
    {
      id: 15,
      type: "EMPLOYEESBREAKUP",
      name: "offRollCheckStatus",
      title: `${customLables?.CONTRACTORSMALL} Employees Test Status`,
      labelHeading: "Total #",
      isActive:
        tempMap.get("OffRollEmployeesCheckupStatus") &&
        listSortedByProp(value?.CHECKUP_STATUS_OFFROLL)?.length !== 0 &&
        !listSortedByProp(value?.CHECKUP_STATUS_OFFROLL)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#F66"],
      chartdata: shaktipumpCheck
        ? [
            {
              name: "COMPLETED",
              value: 100,
              sequence: 1,
              count: 449,
            },
            {
              name: "PENDING",
              value: 0,
              sequence: 1,
              count: 0,
            },
          ]
        : listSortedByProp(value?.CHECKUP_STATUS_OFFROLL),
      dialogData: shaktipumpCheck
        ? {
            COMPLETED: graphValue?.CHECKUP_STATUS_OFFROLL?.COMPLETED,
            PENDING: [],
          }
        : graphValue?.CHECKUP_STATUS_OFFROLL,
    },

    {
      id: 16,
      type: "EMPLOYEESBREAKUP",
      name: "onrollhealthstatus",
      title: `${customLables?.ONROLLSMALL} Employees Health Status`,
      labelHeading: "Total #",
      isActive:
        tempMap.get("OnRollEmployeesHealthStatus") &&
        listSortedByProp(value?.ONROLL_EMPLOYEE_HEALTH)?.length !== 0 &&
        !listSortedByProp(value?.ONROLL_EMPLOYEE_HEALTH)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#F66", "#42BD53"],
      chartdata: listSortedByProp(value?.ONROLL_EMPLOYEE_HEALTH),
      dialogData: graphValue?.ONROLL_EMPLOYEE_HEALTH,
    },
    {
      id: 17,
      type: "EMPLOYEESBREAKUP",
      name: "contractorhealthstatus",
      title: `${customLables?.CONTRACTORSMALL} Employees Health Status`,
      labelHeading: "Total #",
      isActive:
        tempMap.get("ContractEmployeesHealthStatus") &&
        listSortedByProp(value?.CONTRACT_EMPLOYEE_HEALTH)?.length !== 0 &&
        !listSortedByProp(value?.CONTRACT_EMPLOYEE_HEALTH)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#F66", "#42BD53"],
      chartdata: listSortedByProp(value?.CONTRACT_EMPLOYEE_HEALTH),
      dialogData: graphValue?.CONTRACT_EMPLOYEE_HEALTH,
    },

    {
      id: 18,
      type: "EMPLOYEESBYDEPARTMENT",
      name: "onrollbydepartment",
      title: `Employees (${customLables?.ONROLLSMALL}) By Department`,
      mapName: "mapOfUnhealthyOnRollEmployeesByDepartment",
      labelHeading: "Total #",
      barWidth: 40,
      isActive:
        tempMap.get("UnhealthyOnRollEmployeesByDepartment") &&
        listSortedByProp(value?.UNHEALTHY_ONROLL)?.length !== 0 &&
        !listSortedByProp(value?.UNHEALTHY_ONROLL)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: [],
      chartdata: reorderSequence(listSortedByProp(value?.UNHEALTHY_ONROLL)),
      dialogData: graphValue?.UNHEALTHY_ONROLL,
      dialogDataHealthy: graphValue?.HEALTHY_ONROLL,
    },
    {
      id: 19,
      type: "EMPLOYEESBYDEPARTMENT",
      name: "contractorsbydepartment",
      title: `Employees (${customLables?.CONTRACTORSMALL}) By Department`,
      mapName: "mapOfUnhealthyContractEmployeesByDepartment",
      labelHeading: "Total #",
      barWidth: 40,
      isActive:
        tempMap.get("UnhealthyContractEmployeesByDepartment") &&
        listSortedByProp(value?.UNHEALTHY_CONTRACTOR)?.length !== 0 &&
        !listSortedByProp(value?.UNHEALTHY_CONTRACTOR)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: [],
      chartdata: listSortedByProp(value?.UNHEALTHY_CONTRACTOR),
      dialogData: graphValue?.UNHEALTHY_CONTRACTOR,
      dialogDataHealthy: graphValue?.HEALTHY_CONTRACTOR,
    },
    {
      id: 20,
      type: "NONBPVITALS",
      name: "haemoglobin",
      title: "Haemoglobin Chart",
      labelHeading: "Total #",
      isActive:
        tempMap.get("HaemoglobinPercent") &&
        listSortedByProp(value?.HAEMOGLOBIN)?.length !== 0 &&
        !listSortedByProp(value?.HAEMOGLOBIN)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#458BCC", "#42BD53", "#F66"],
      chartdata: listSortedByProp(value?.HAEMOGLOBIN),
      dialogData: graphValue?.HAEMOGLOBIN,
    },
    {
      id: 21,
      type: "NONBPVITALS",
      name: "triglyceride",
      title: "Triglyceride Chart",
      labelHeading: "Total #",
      isActive:
        tempMap.get("TriglyceridePercent") &&
        listSortedByProp(value?.TRIGLYCERIDE)?.length !== 0 &&
        !listSortedByProp(value?.TRIGLYCERIDE)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#F8A649", "#F66", "#AA0101"],
      chartdata: listSortedByProp(value?.TRIGLYCERIDE),
      dialogData: graphValue?.TRIGLYCERIDE,
    },
    {
      id: 22,
      type: "NONBPVITALS",
      name: "ecg",
      title: "ECG Chart",
      labelHeading: "Total #",
      isActive:
        tempMap.get("EcgPercent") &&
        listSortedByProp(value?.ECG)?.length !== 0 &&
        !listSortedByProp(value?.ECG)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#F8A649", "#F66", "#AA0101"],
      chartdata: listSortedByProp(value?.ECG),
      dialogData: graphValue?.ECG,
    },
    {
      id: 23,
      type: "NONBPVITALS",
      name: "xray",
      title: "Chest Xray Chart",
      labelHeading: "Total #",
      isActive:
        tempMap.get("XrayPercent") &&
        listSortedByProp(value?.XRAY)?.length !== 0 &&
        !listSortedByProp(value?.XRAY)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#F8A649", "#F66"],
      chartdata: listSortedByProp(value?.XRAY),
      dialogData: graphValue?.XRAY,
    },
    {
      id: 24,
      type: "NONBPVITALS",
      name: "pft",
      title: "PFT Chart",
      labelHeading: "Total #",
      isActive:
        tempMap.get("SgotPercent") &&
        listSortedByProp(value?.PFT)?.length !== 0 &&
        !listSortedByProp(value?.PFT)?.every(
          (item) => item.value === 0 || item.value === null
        )
          ? true
          : false,
      colors: ["#42BD53", "#FFE066", "#F8A649", "#F66", "#AA0101"],
      chartdata: listSortedByProp(value?.PFT),
      dialogData: graphValue?.PFT,
    },
  ];

  return list.filter((val) => val.isActive);
};

const reorderSequence = (data) => {
  const newSequenceOrder = [2, 5, 3, 1, 4, 6, 8, 7, 9, 10, 11, 12, 13, 14, 15];
  const sortedData = data.sort((a, b) => {
    return (
      newSequenceOrder.indexOf(a.sequence) -
      newSequenceOrder.indexOf(b.sequence)
    );
  });

  return sortedData;
};

const VitalsGraphs = ({
  corpId = localStorage.getItem("CORPID"),
  selectedDepartment,
  statusTrackerConfig = JSON.parse(
    localStorage.getItem("STATUSTRACKER_CONFIG")
  ),
  selectedCampId,
}) => {
  const [vitalData, setVitalData] = useState([]);
  const [graphData, setGraphData] = useState([]);

  const _fetchVitalData = async () => {
    if (selectedCampId !== "") {
      const url =
        BASE_URL +
        `org/ahcStatusTracker/${corpId}?department=${
          selectedDepartment || ""
        }&campCycleId=${selectedCampId === "null" ? "" : selectedCampId}`;

      const vitals = await getData(url, "");
      if (vitals.error) {
        setVitalData([]);
      } else {
        setVitalData(vitals.data.graphValueMap);
      }
    }
  };

  const _fetchGraphData = async () => {
    if (selectedCampId !== "") {
      const url =
        BASE_URL +
        `org/ahcStatusDepartmentsData/${corpId}?campCycleId=${
          selectedCampId === "null" ? "" : selectedCampId
        }`;

      const graphData = await getData(url);
      if (graphData.error) {
        setGraphData([]);
      } else {
        setGraphData(graphData.data.dataMaps);
      }
    }
  };

  useEffect(() => {
    _fetchVitalData();
  }, [selectedDepartment, selectedCampId]);

  useEffect(() => {
    _fetchGraphData();
  }, [selectedCampId]);

  console.log({ corpId, vitalData, graphData, selectedCampId });
  console.log({ statusTrackerConfig });

  return (
    <Box>
      <Grid container spacing={3}>
        {/************** Employees Breakup chart start **********/}
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            container
            spacing={3}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {graphList(
              vitalData,
              graphData,
              statusTrackerConfig,
              getEmpBreakupLabels(corpId),
              corpId === "28372173-fbba-4e78-958d-ebefad5c4498" &&
                selectedCampId === 138856 // Shakti Pump Change
            )
              ?.filter((value) => value.type === "EMPLOYEESBREAKUP")
              ?.map((val, index) => (
                <Grid item lg={4} md={4} sm={12} xs={12} key={index}>
                  <CustomDoughnutChartNew
                    COLORS={val?.colors}
                    data={val?.chartdata}
                    title={val?.title}
                    labelHeading={val?.labelHeading}
                    dialogData={val?.dialogData}
                    selectedDepartment={selectedDepartment}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
        {/************** Employees Breakup chart end **********/}

        {/************** Healthy/Unhealthy chart Start **********/}
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            container
            spacing={3}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {graphList(
              vitalData,
              graphData,
              statusTrackerConfig,
              getEmpBreakupLabels(corpId)
            )
              ?.filter((value) => value.type === "EMPLOYEESBYDEPARTMENT")
              ?.map((val, index) => (
                <Grid
                  item
                  lg={
                    graphList(
                      vitalData,
                      graphData,
                      statusTrackerConfig,
                      getEmpBreakupLabels(corpId)
                    )?.filter((value) => value.type === "EMPLOYEESBYDEPARTMENT")
                      .length > 1
                      ? 6
                      : 12
                  }
                  md={12}
                  sm={12}
                  xs={12}
                  key={index}
                >
                  <StackedBarChart
                    barWidth={val?.barWidth}
                    title={val?.title}
                    mapName={val?.mapName}
                    data={val?.chartdata}
                    dialogData={val?.dialogData}
                    dialogDataHealthy={val?.dialogDataHealthy}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
        {/************** Healthy/Unhealthy chart End **********/}

        {/************** BP chart Start **********/}
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {graphList(
            vitalData,
            graphData,
            statusTrackerConfig,
            getEmpBreakupLabels(corpId)
          )
            ?.filter((value) => value.type === "BPVITALS")
            ?.map((val, index) => (
              <Grid
                key={index}
                container
                spacing={3}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box
                    sx={{
                      backgroundColor: "#FFFFFF",
                      border: "1px solid grey",
                      height: "300px",
                      paddingInline: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <Typography
                      textAlign="left"
                      sx={{
                        paddingTop: "20px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "120%",
                      }}
                    >
                      BP Reference Chart
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="img"
                        src={BPRangeIMG}
                        width="100%%"
                        height={"240px"}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <CustomDoughnutChartNew
                    COLORS={val?.colors}
                    data={val?.chartdata}
                    title={val?.title}
                    labelHeading={val?.labelHeading}
                    dialogData={val?.dialogData}
                    labelWidth={val?.labelWidth}
                    selectedDepartment={selectedDepartment}
                  />
                </Grid>
              </Grid>
            ))}
        </Grid>
        {/************** BP chart end **********/}

        {/************** Other Vitals chart Start **********/}
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            container
            spacing={3}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {graphList(
              vitalData,
              graphData,
              statusTrackerConfig,
              getEmpBreakupLabels(corpId),
              corpId === "28372173-fbba-4e78-958d-ebefad5c4498" &&
                selectedCampId === 138856 // Shakti Pump Change
            )
              ?.filter((value) => value.type === "NONBPVITALS")
              ?.map((val, index) => (
                <Grid item lg={4} md={4} sm={12} xs={12} key={index}>
                  <CustomDoughnutChartNew
                    COLORS={val?.colors}
                    data={val?.chartdata}
                    title={val?.title}
                    labelHeading={"Total #"}
                    dialogData={val?.dialogData}
                    selectedDepartment={selectedDepartment}
                    labelWidth={val?.labelWidth}
                    shaktipumpCheck={
                      corpId === "28372173-fbba-4e78-958d-ebefad5c4498" &&
                      selectedCampId === 138856
                    }
                    // Shakti Pump Change
                  />
                </Grid>
              ))}
            {/* ralson and dhoot gurgaon*/}
            {(corpId === "53d4dccd-a3b4-4c35-8898-dd01e65d9919" ||
              corpId === "3cc44b7f-57f6-436d-a850-2634df250f24") && (
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <CustomDoughnutChartNew
                  COLORS={["#42BD53", "#FFE066", "#F8A649", "#F66", "#AA0101"]}
                  data={listSortedByProp(vitalData?.PFT)}
                  title={"PFT Chart"}
                  labelHeading={"Total #"}
                  dialogData={graphData?.PFT}
                  selectedDepartment={selectedDepartment}
                />
              </Grid>
            )}

            {corpId === "28372173-fbba-4e78-958d-ebefad5c4498" &&
              selectedCampId === 138856 && (
                <Fragment>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <CustomDoughnutChartNew
                      COLORS={["#42BD53", "#FFE066", "#F8A649", "#F66"]}
                      data={PFTData}
                      title={"PFT Chart"}
                      labelHeading={"Total #"}
                      dialogData={[]}
                      selectedDepartment={selectedDepartment}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <CustomDoughnutChartNew
                      COLORS={["#42BD53", "#FFE066", "#F8A649", "#F66"]}
                      data={XrayData}
                      title={"X-Ray Chart"}
                      labelHeading={"Total #"}
                      dialogData={[]}
                      selectedDepartment={selectedDepartment}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <CustomDoughnutChartNew
                      COLORS={["#42BD53", "#FFE066", "#F8A649", "#F66"]}
                      data={AudiometryData}
                      title={"Audiometry Chart"}
                      labelHeading={"Total #"}
                      dialogData={[]}
                      selectedDepartment={selectedDepartment}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <CustomDoughnutChartNew
                      COLORS={["#42BD53", "#FFE066", "#F8A649", "#F66"]}
                      data={CBCData}
                      title={"CBC Chart"}
                      labelHeading={"Total #"}
                      dialogData={[]}
                      selectedDepartment={selectedDepartment}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <CustomDoughnutChartNew
                      COLORS={["#42BD53", "#FFE066", "#F8A649", "#F66"]}
                      data={listSortedByProp(vitalData?.TOTAL_BILIRUBIN)}
                      title={"Total Bilirubin Chart"}
                      labelHeading={"Total #"}
                      dialogData={graphData?.TOTAL_BILIRUBIN}
                      selectedDepartment={selectedDepartment}
                    />
                  </Grid>
                </Fragment>
              )}
          </Grid>
        </Grid>
        {/************** Other Vitals chart End **********/}
      </Grid>
    </Box>
  );
};

export default VitalsGraphs;

const PFTData = [
  {
    name: "Normal",
    value: formatFloat(80, 121),
    sequence: 1,
    count: 80,
  },
  {
    name: "Restrictive stage",
    value: formatFloat(39, 121),
    sequence: 2,
    count: 39,
  },

  {
    name: "Severe",
    value: formatFloat(1, 121),
    sequence: 3,
    count: 1,
  },
  {
    name: "Very severe",
    value: formatFloat(1, 121),
    sequence: 4,
    count: 1,
  },
];

const XrayData = [
  {
    name: "Fit",
    value: formatFloat(185, 185),
    sequence: 1,
    count: 185,
  },
];

const AudiometryData = [
  {
    name: "Fit",
    value: formatFloat(89, 89),
    sequence: 1,
    count: 89,
  },
];

const CBCData = [
  {
    name: "Complete",
    value: formatFloat(481, 481),
    sequence: 1,
    count: 481,
  },
  // Shakti Pump Change
];
