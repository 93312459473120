import { Box, Grid, Typography } from "@mui/material";
import { Fragment } from "react";

const ReportHeaderComp = () => {
  return (
    <Fragment>
      <Box sx={{ background: "#D9D9D9", p: 1 }}>
        <Grid container columns={15}>
          <Grid
            item
            lg={3}
            md={15}
            sm={15}
            xs={15}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: "600", fontSize: ".875rem" }}
            >
              Name
            </Typography>
          </Grid>
          <Grid
            item
            lg={2}
            md={15}
            sm={15}
            xs={15}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: "600", fontSize: ".875rem" }}
            >
              Type
            </Typography>
          </Grid>

          <Grid
            item
            lg={3}
            md={15}
            sm={15}
            xs={15}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: "600", fontSize: ".875rem" }}
            >
              Date
            </Typography>
          </Grid>

          <Grid
            item
            lg={6}
            md={15}
            sm={15}
            xs={15}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: "600", fontSize: ".875rem" }}
            >
              URL
            </Typography>
          </Grid>

          <Grid item lg={1} md={15} sm={15} xs={15}></Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default ReportHeaderComp;
