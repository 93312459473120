import React, { Fragment } from "react";
import { Routes, Route } from "react-router-dom";
import AboutUs from "./pages/aboutUs";
import Career from "./pages/career";
import Home from "./pages/home";
import Location from "./pages/location";
import PageNotFound from "./pages/pageNotFound";
import Login from "./pages/login";
import Patients from "./pages/patients";
import Templates from "./pages/templates";
import Slots from "./pages/slots";
import Profiles from "./pages/profiles";
import Registration from "./pages/registration";
import MyImage from "./pages/myImage";
import Logout from "./pages/logout";
import Terms from "./pages/terms.jsx";
import Privacy from "./pages/privacy.jsx";
import PrivateRouter from "./common/privateRouter/privateRouter";
import CallPage from "./pages/callPage";
import ConsultationPage from "./pages/consultationPage";
import DigitalMarketingIndex from "./digitalMarketing/pages/digitalMarketingIndex";
import { CorporateRoutes } from "./corporatePortal/routes/corporateRoutes";
import { SecurityRoutes } from "./securityPortal/routes/securityRoutes.jsx";
import OrgLogin from "./corporatePortal/pages/orgLogin.jsx";
import CorpLanding from "./corporatePortal/pages/corpLanding.jsx";

const App = ({ corpId = localStorage.getItem("CORPID") }) => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/doctor" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/orglogin" element={<OrgLogin />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/home" element={<Home />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/career" element={<Career />} />
      <Route path="/location" element={<Location />} />

      <Route
        path="/patients"
        element={<PrivateRouter Page={Patients} Default={Login} />}
      />

      <Route
        path="/call"
        element={<PrivateRouter Page={CallPage} Default={Login} />}
      />

      <Route
        path="/consultation"
        element={<PrivateRouter Page={ConsultationPage} Default={Login} />}
      />
      <Route
        path="/templates"
        element={<PrivateRouter Page={Templates} Default={Login} />}
      />
      <Route
        path="/slots"
        element={<PrivateRouter Page={Slots} Default={Login} />}
      />
      <Route
        path="/profiles"
        element={<PrivateRouter Page={Profiles} Default={Login} />}
      />

      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route
        path="/registration"
        element={<PrivateRouter Page={Registration} Default={Login} />}
      />
      <Route path="/myimage" element={<MyImage />} />
      <Route path="/*" element={<PageNotFound />} />

      <Route path="/corplanding" element={<CorpLanding unauthenticated />} />

      {CorporateRoutes}
      {SecurityRoutes}

      {/*********** routes for digital marketing start ***************/}

      <Route path="/digitalmarketing" element={<DigitalMarketingIndex />} />
      {/*********** routes for routes for digital marketing end ***************/}
    </Routes>
  );
};

export default App;
