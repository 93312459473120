import React, { Fragment, useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Container,
  Grid,
  TextField,
} from "@mui/material";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { getData } from "../../../../../services/api/getApi";
import {
  convertMapToObject,
  convertMapToObjectNew,
  convertMapToObjectNew2,
  convertMapToObjectSorted,
  transformData,
} from "../../../../global/actions/actions";
import OhcDoughnutChart from "./ohcDoughnutChart";
import CustomBarGraphOhc from "./customBarChartOhc";
import DashboardStats from "./dashboardStats";
import TopIllnessGraph from "./topIllnessGraph";

const sortData = (data) => {
  // Extract the entries from the object
  let entries = Object.entries(data || {});

  // Map 'OTHERS' to 'undefined'
  entries = entries.map(([key, value]) =>
    // key === "OTHERS" ? ["undefined", value] :
    [key, value]
  );

  // Sort the entries based on the custom criteria
  entries.sort((a, b) => {
    if (a[0] === "<20") return -1;
    if (b[0] === "<20") return 1;
    if (a[0] === "undefined") return 1;
    if (b[0] === "undefined") return -1;
    return a[0].localeCompare(b[0]);
  });

  // Convert the entries back to an object
  return Object.fromEntries(entries);
};

const convertDataToArray = (data) => {
  if (!data || typeof data !== "object") {
    console.error("Invalid data object...");
    return [];
  }

  console.log({ data: Object.keys(data) });

  const dataArray = Object.keys(data).map((key) => ({
    name: key,
    sequence: data[key].sequence,
    percent: data[key].percent,
    value: data[key].value,
  }));

  // Sort the array based on the "name" property
  dataArray.sort((a, b) => {
    if (a.name === "TOTAL OPD") {
      return -1; // "TOTAL OPD" comes first
    } else if (b.name === "TOTAL OPD") {
      return 1; // "TOTAL OPD" comes first
    } else {
      return a.sequence - b.sequence; // Sort by sequence for other items
    }
  });

  return dataArray;
};

const durationOption = [
  { id: 1, label: "This Week", value: "THIS_WEEK", interval: "THIS_WEEK" },
  { id: 2, label: "This Month", value: "THIS_MONTH", interval: "THIS_MONTH" },
  { id: 3, label: "This Year", value: "THIS_YEAR", interval: "THIS_YEAR" },
];

const transformValue = (details, transform) => {
  if (details) {
    return Object.entries(details).reduce((acc, [key, detail]) => {
      console.log({ acc, detail, key });
      acc[key] = transform(detail);

      return acc;
    }, {});
  }
};

const OhcStatusTrackerNew = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [selectedDuration, setSelectedDuration] = useState("THIS_WEEK");
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const _getDepartmentList = async () => {
    const url = BASE_URL + `org/ohcStatusDepartments/${corpId}`;
    const departmentList = await getData(url, "");
    if (departmentList.error) {
      setDepartmentOptions([]);
    } else {
      const listTemp = departmentList.data.map((item, index) => ({
        id: index + 1,
        value: item,
        label: item,
      }));
      setDepartmentOptions(listTemp);
    }
  };
  useEffect(() => {
    _getDepartmentList();
  }, []);

  const [pieGraphData, setPieGraphData] = useState(null);
  const getGraphData = async () => {
    const url =
      BASE_URL +
      `org/ohcStatusTracker/detailed/${corpId}?duration=${
        selectedDuration || ""
      }&department=${selectedDepartment || ""}`;
    const graphData = await getData(url, "");
    if (graphData.error) {
      setPieGraphData(null);
    } else {
      console.log({ dta38712812: graphData?.data });

      const injuryBreakUp = {
        THIS_YEAR: {
          INDUSTRIAL: {
            sequence: 1,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisYear?.INDUSTRIAL?.count /
                (graphData?.data?.mapOfOhcStatsThisYear?.INDUSTRIAL?.count +
                  graphData?.data?.mapOfOhcStatsThisYear?.NON_INDUSTRIAL
                    ?.count +
                  graphData?.data?.mapOfOhcStatsThisYear?.INJURY_LOCATION_OTHERS
                    ?.count)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisYear?.INDUSTRIAL?.count,
          },
          NON_INDUSTRIAL: {
            sequence: 2,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisYear?.NON_INDUSTRIAL?.count /
                (graphData?.data?.mapOfOhcStatsThisYear?.INDUSTRIAL?.count +
                  graphData?.data?.mapOfOhcStatsThisYear?.NON_INDUSTRIAL
                    ?.count)) *
              //   +
              // graphData?.data?.mapOfOhcStatsThisYear?.INJURY_LOCATION_OTHERS
              //   ?.count
              100
            ).toFixed(2),
            value:
              graphData?.data?.mapOfOhcStatsThisYear?.NON_INDUSTRIAL?.count,
          },
          // UNDEFINED: {
          //   sequence: 3,
          //   percent: (
          //     (graphData?.data?.mapOfOhcStatsThisYear?.INJURY_LOCATION_OTHERS?.count /
          //       (graphData?.data?.mapOfOhcStatsThisYear?.INDUSTRIAL?.count +
          //         graphData?.data?.mapOfOhcStatsThisYear?.NON_INDUSTRIAL?.count +
          //         graphData?.data?.mapOfOhcStatsThisYear?.INJURY_LOCATION_OTHERS?.count)) *
          //     100
          //   ).toFixed(2),
          //   value: graphData?.data?.mapOfOhcStatsThisYear?.INJURY_LOCATION_OTHERS?.count,
          // },
        },
        THIS_MONTH: {
          INDUSTRIAL: {
            sequence: 1,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisMonth?.INDUSTRIAL?.count /
                (graphData?.data?.mapOfOhcStatsThisMonth?.INDUSTRIAL?.count +
                  graphData?.data?.mapOfOhcStatsThisMonth?.NON_INDUSTRIAL
                    ?.count)) *
              //    +
              // graphData?.data?.mapOfOhcStatsThisMonth
              //   ?.INJURY_LOCATION_OTHERS?.count
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisMonth?.INDUSTRIAL?.count,
          },
          NON_INDUSTRIAL: {
            sequence: 2,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisMonth?.NON_INDUSTRIAL?.count /
                (graphData?.data?.mapOfOhcStatsThisMonth?.INDUSTRIAL?.count +
                  graphData?.data?.mapOfOhcStatsThisMonth?.NON_INDUSTRIAL
                    ?.count)) *
              //   +
              // graphData?.data?.mapOfOhcStatsThisMonth
              //   ?.INJURY_LOCATION_OTHERS?.count
              100
            ).toFixed(2),
            value:
              graphData?.data?.mapOfOhcStatsThisMonth?.NON_INDUSTRIAL?.count,
          },
          // UNDEFINED: {
          //   sequence: 3,
          //   percent: (
          //     (graphData?.data?.mapOfOhcStatsThisMonth?.INJURY_LOCATION_OTHERS?.count /
          //       (graphData?.data?.mapOfOhcStatsThisMonth?.INDUSTRIAL?.count +
          //         graphData?.data?.mapOfOhcStatsThisMonth?.NON_INDUSTRIAL?.count +
          //         graphData?.data?.mapOfOhcStatsThisMonth?.INJURY_LOCATION_OTHERS?.count)) *
          //     100
          //   ).toFixed(2),
          //   value: graphData?.data?.mapOfOhcStatsThisMonth?.INJURY_LOCATION_OTHERS?.count,
          // },
        },
        THIS_WEEK: {
          INDUSTRIAL: {
            sequence: 1,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisWeek?.INDUSTRIAL?.count /
                (graphData?.data?.mapOfOhcStatsThisWeek?.INDUSTRIAL?.count +
                  graphData?.data?.mapOfOhcStatsThisWeek?.NON_INDUSTRIAL
                    ?.count)) *
              //    +
              // graphData?.data?.mapOfOhcStatsThisWeek?.INJURY_LOCATION_OTHERS
              //   ?.count
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisWeek?.INDUSTRIAL?.count,
          },
          NON_INDUSTRIAL: {
            sequence: 2,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisWeek?.NON_INDUSTRIAL?.count /
                (graphData?.data?.mapOfOhcStatsThisWeek?.INDUSTRIAL?.count +
                  graphData?.data?.mapOfOhcStatsThisWeek?.NON_INDUSTRIAL
                    ?.count)) *
              //    +
              // graphData?.data?.mapOfOhcStatsThisWeek?.INJURY_LOCATION_OTHERS
              //   ?.count
              100
            ).toFixed(2),
            value:
              graphData?.data?.mapOfOhcStatsThisWeek?.NON_INDUSTRIAL?.count,
          },
          // UNDEFINED: {
          //   sequence: 3,
          //   percent: (
          //     (graphData?.data?.mapOfOhcStatsThisWeek?.INJURY_LOCATION_OTHERS?.count /
          //       (graphData?.data?.mapOfOhcStatsThisWeek?.INDUSTRIAL?.count +
          //         graphData?.data?.mapOfOhcStatsThisWeek?.NON_INDUSTRIAL?.count +
          //         graphData?.data?.mapOfOhcStatsThisWeek?.INJURY_LOCATION_OTHERS?.count)) *
          //     100
          //   ).toFixed(2),
          //   value: graphData?.data?.mapOfOhcStatsThisWeek?.INJURY_LOCATION_OTHERS?.count,
          // },
        },
      };
      const injuryBreakUpEmployeeList = {
        THIS_YEAR: {
          INDUSTRIAL:
            graphData?.data?.mapOfOhcStatsThisYear?.INDUSTRIAL?.entities,
          NON_INDUSTRIAL:
            graphData?.data?.mapOfOhcStatsThisYear?.NON_INDUSTRIAL?.entities,
          // UNDEFINED: graphData?.data?.mapOfOhcStatsThisYear?.INJURY_LOCATION_OTHERS?.entities,
        },
        THIS_MONTH: {
          INDUSTRIAL:
            graphData?.data?.mapOfOhcStatsThisMonth?.INDUSTRIAL?.entities,
          NON_INDUSTRIAL:
            graphData?.data?.mapOfOhcStatsThisMonth?.NON_INDUSTRIAL?.entities,
          // UNDEFINED: graphData?.data?.mapOfOhcStatsThisMonth?.INJURY_LOCATION_OTHERS?.entities,
        },
        THIS_WEEK: {
          INDUSTRIAL:
            graphData?.data?.mapOfOhcStatsThisWeek?.INDUSTRIAL?.entities,
          NON_INDUSTRIAL:
            graphData?.data?.mapOfOhcStatsThisWeek?.NON_INDUSTRIAL?.entities,
          // UNDEFINED: graphData?.data?.mapOfOhcStatsThisWeek?.INJURY_LOCATION_OTHERS?.entities,
        },
      };

      const injurySeverity = {
        THIS_YEAR: {
          MILD: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisYear?.MILD?.count /
                  (graphData?.data?.mapOfOhcStatsThisYear?.MILD?.count +
                    graphData?.data?.mapOfOhcStatsThisYear?.SEVERE?.count +
                    graphData?.data?.mapOfOhcStatsThisYear?.CRITICAL?.count)) *
                // +
                // graphData?.data?.mapOfOhcStatsThisYear
                //   ?.INJURY_SEVERITY_OTHERS?.count
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisYear?.MILD?.count || 0,
          },
          SEVERE: {
            sequence: 2,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisYear?.SEVERE?.count /
                  (graphData?.data?.mapOfOhcStatsThisYear?.MILD?.count +
                    graphData?.data?.mapOfOhcStatsThisYear?.SEVERE?.count +
                    graphData?.data?.mapOfOhcStatsThisYear?.CRITICAL?.count)) *
                // +
                // graphData?.data?.mapOfOhcStatsThisYear
                //   ?.INJURY_SEVERITY_OTHERS?.count
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisYear?.SEVERE?.count || 0,
          },
          CRITICAL: {
            sequence: 3,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisYear?.CRITICAL?.count /
                  (graphData?.data?.mapOfOhcStatsThisYear?.MILD?.count +
                    graphData?.data?.mapOfOhcStatsThisYear?.SEVERE?.count +
                    graphData?.data?.mapOfOhcStatsThisYear?.CRITICAL?.count)) *
                // +
                // graphData?.data?.mapOfOhcStatsThisYear
                //   ?.INJURY_SEVERITY_OTHERS?.count
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisYear?.CRITICAL?.count || 0,
          },
          // UNDEFINED: {
          //   sequence: 3,
          //   percent: (
          //     (graphData?.data?.mapOfOhcStatsThisYear?.INJURY_SEVERITY_OTHERS?.count /
          //       (graphData?.data?.mapOfOhcStatsThisYear?.CRITICAL?.count +
          //         graphData?.data?.mapOfOhcStatsThisYear?.MILD?.count +
          //         graphData?.data?.mapOfOhcStatsThisYear?.SEVERE?.count +
          //         graphData?.data?.mapOfOhcStatsThisYear?.INJURY_SEVERITY_OTHERS?.count)) *
          //     100
          //   ).toFixed(2),
          //   value: graphData?.data?.mapOfOhcStatsThisYear?.INJURY_SEVERITY_OTHERS?.count,
          // },
        },
        THIS_MONTH: {
          MILD: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisMonth?.MILD?.count /
                  (graphData?.data?.mapOfOhcStatsThisMonth?.MILD?.count +
                    graphData?.data?.mapOfOhcStatsThisMonth?.SEVERE?.count +
                    graphData?.data?.mapOfOhcStatsThisMonth?.CRITICAL?.count)) *
                // +
                // graphData?.data?.mapOfOhcStatsThisMonth
                //   ?.INJURY_SEVERITY_OTHERS?.count
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisMonth?.MILD?.count || 0,
          },
          SEVERE: {
            sequence: 2,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisMonth?.SEVERE?.count /
                  (graphData?.data?.mapOfOhcStatsThisMonth?.MILD?.count +
                    graphData?.data?.mapOfOhcStatsThisMonth?.SEVERE?.count +
                    graphData?.data?.mapOfOhcStatsThisMonth?.CRITICAL?.count)) *
                // +
                // graphData?.data?.mapOfOhcStatsThisMonth
                //   ?.INJURY_SEVERITY_OTHERS?.count
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisMonth?.SEVERE?.count || 0,
          },
          CRITICAL: {
            sequence: 3,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisMonth?.CRITICAL?.count /
                  (graphData?.data?.mapOfOhcStatsThisMonth?.MILD?.count +
                    graphData?.data?.mapOfOhcStatsThisMonth?.SEVERE?.count +
                    graphData?.data?.mapOfOhcStatsThisMonth?.CRITICAL?.count)) *
                // +
                // graphData?.data?.mapOfOhcStatsThisMonth
                // ?.INJURY_SEVERITY_OTHERS?.count
                100
              ).toFixed(2) || 0,
            value:
              graphData?.data?.mapOfOhcStatsThisMonth?.CRITICAL?.count || 0,
          },

          // UNDEFINED: {
          //   sequence: 3,
          //   percent: (
          //     (graphData?.data?.mapOfOhcStatsThisMonth?.INJURY_SEVERITY_OTHERS?.count /
          //       (graphData?.data?.mapOfOhcStatsThisMonth?.CRITICAL?.count +
          //         graphData?.data?.mapOfOhcStatsThisMonth?.MILD?.count +
          //         graphData?.data?.mapOfOhcStatsThisMonth?.SEVERE?.count +
          //         graphData?.data?.mapOfOhcStatsThisMonth?.INJURY_SEVERITY_OTHERS?.count)) *
          //     100
          //   ).toFixed(2),
          //   value: graphData?.data?.mapOfOhcStatsThisMonth?.INJURY_SEVERITY_OTHERS?.count,
          // },
        },
        THIS_WEEK: {
          MILD: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisWeek?.MILD?.count /
                  (graphData?.data?.mapOfOhcStatsThisWeek?.MILD?.count +
                    graphData?.data?.mapOfOhcStatsThisWeek?.SEVERE?.count +
                    graphData?.data?.mapOfOhcStatsThisWeek?.CRITICAL?.count)) *
                // +
                // graphData?.data?.mapOfOhcStatsThisWeek
                //   ?.INJURY_SEVERITY_OTHERS?.count
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisWeek?.MILD?.count || 0,
          },
          SEVERE: {
            sequence: 2,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisWeek?.SEVERE?.count /
                  (graphData?.data?.mapOfOhcStatsThisWeek?.MILD?.count +
                    graphData?.data?.mapOfOhcStatsThisWeek?.SEVERE?.count +
                    graphData?.data?.mapOfOhcStatsThisWeek?.CRITICAL?.count)) *
                //  +
                // graphData?.data?.mapOfOhcStatsThisWeek
                //   ?.INJURY_SEVERITY_OTHERS?.count
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisWeek?.SEVERE?.count || 0,
          },
          CRITICAL: {
            sequence: 3,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisWeek?.CRITICAL?.count /
                  (graphData?.data?.mapOfOhcStatsThisWeek?.MILD?.count +
                    graphData?.data?.mapOfOhcStatsThisWeek?.SEVERE?.count +
                    graphData?.data?.mapOfOhcStatsThisWeek?.CRITICAL?.count)) *
                // +
                // graphData?.data?.mapOfOhcStatsThisWeek
                //   ?.INJURY_SEVERITY_OTHERS?.count
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisWeek?.CRITICAL?.count || 0,
          },

          // UNDEFINED: {
          //   sequence: 3,
          //   percent: (
          //     (graphData?.data?.mapOfOhcStatsThisWeek?.INJURY_SEVERITY_OTHERS?.count /
          //       (graphData?.data?.mapOfOhcStatsThisWeek?.CRITICAL?.count +
          //         graphData?.data?.mapOfOhcStatsThisWeek?.MILD?.count +
          //         graphData?.data?.mapOfOhcStatsThisWeek?.SEVERE?.count +
          //         graphData?.data?.mapOfOhcStatsThisWeek?.INJURY_SEVERITY_OTHERS?.count)) *
          //     100
          //   ).toFixed(2),
          //   value: graphData?.data?.mapOfOhcStatsThisWeek?.INJURY_SEVERITY_OTHERS?.count,
          // },
        },
      };
      const injurySeverityEmployeeList = {
        THIS_YEAR: {
          MILD: graphData?.data?.mapOfOhcStatsThisYear?.MILD?.entities,
          SEVERE: graphData?.data?.mapOfOhcStatsThisYear?.SEVERE?.entities,
          CRITICAL: graphData?.data?.mapOfOhcStatsThisYear?.CRITICAL?.entities,
          // UNDEFINED: graphData?.data?.mapOfOhcStatsThisYear?.INJURY_SEVERITY_OTHERS?.entities,
        },
        THIS_MONTH: {
          MILD: graphData?.data?.mapOfOhcStatsThisMonth?.MILD?.entities,
          SEVERE: graphData?.data?.mapOfOhcStatsThisMonth?.SEVERE?.entities,
          CRITICAL: graphData?.data?.mapOfOhcStatsThisMonth?.CRITICAL?.entities,
          // UNDEFINED: graphData?.data?.mapOfOhcStatsThisMonth?.INJURY_SEVERITY_OTHERS?.entities,
        },
        THIS_WEEK: {
          MILD: graphData?.data?.mapOfOhcStatsThisWeek?.MILD?.entities,
          SEVERE: graphData?.data?.mapOfOhcStatsThisWeek?.SEVERE?.entities,
          CRITICAL: graphData?.data?.mapOfOhcStatsThisWeek?.CRITICAL?.entities,
          // UNDEFINED: graphData?.data?.mapOfOhcStatsThisWeek?.INJURY_SEVERITY_OTHERS?.entities,
        },
      };

      const genderWiseInjury = {
        THIS_YEAR: {
          MALE: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisYear?.MALEINJURY?.count /
                  (graphData?.data?.mapOfOhcStatsThisYear?.MALEINJURY?.count +
                    graphData?.data?.mapOfOhcStatsThisYear?.FEMALEINJURY
                      ?.count)) *
                //   +
                // graphData?.data?.mapOfOhcStatsThisYear?.GENDER_INJURY_OTHERS
                //   ?.count
                100
              ).toFixed(2) || 0,
            value:
              graphData?.data?.mapOfOhcStatsThisYear?.MALEINJURY?.count || 0,
          },
          FEMALE: {
            sequence: 2,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisYear?.FEMALEINJURY?.count /
                  (graphData?.data?.mapOfOhcStatsThisYear?.MALEINJURY?.count +
                    graphData?.data?.mapOfOhcStatsThisYear?.FEMALEINJURY
                      ?.count)) *
                //   +
                // graphData?.data?.mapOfOhcStatsThisYear?.GENDER_INJURY_OTHERS
                //   ?.count
                100
              ).toFixed(2) || 0,
            value:
              graphData?.data?.mapOfOhcStatsThisYear?.FEMALEINJURY?.count || 0,
          },
          // UNDEFINED: {
          //   sequence: 3,
          //   percent: (
          //     (graphData?.data?.mapOfOhcStatsThisYear?.GENDER_INJURY_OTHERS?.count /
          //       (graphData?.data?.mapOfOhcStatsThisYear?.MALEINJURY?.count +
          //         graphData?.data?.mapOfOhcStatsThisYear?.FEMALEINJURY?.count +
          //         graphData?.data?.mapOfOhcStatsThisYear?.GENDER_INJURY_OTHERS?.count)) *
          //     100
          //   ).toFixed(2),
          //   value: graphData?.data?.mapOfOhcStatsThisYear?.GENDER_INJURY_OTHERS?.count,
          // },
        },
        THIS_MONTH: {
          MALE: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisMonth?.MALEINJURY?.count /
                  (graphData?.data?.mapOfOhcStatsThisMonth?.MALEINJURY?.count +
                    graphData?.data?.mapOfOhcStatsThisMonth?.FEMALEINJURY
                      ?.count)) *
                //    +
                // graphData?.data?.mapOfOhcStatsThisMonth
                //   ?.GENDER_INJURY_OTHERS?.count
                100
              ).toFixed(2) || 0,
            value:
              graphData?.data?.mapOfOhcStatsThisMonth?.MALEINJURY?.count || 0,
          },
          FEMALE: {
            sequence: 2,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisMonth?.FEMALEINJURY?.count /
                  (graphData?.data?.mapOfOhcStatsThisMonth?.MALEINJURY?.count +
                    graphData?.data?.mapOfOhcStatsThisMonth?.FEMALEINJURY
                      ?.count)) *
                //    +
                // graphData?.data?.mapOfOhcStatsThisMonth
                //   ?.GENDER_INJURY_OTHERS?.count
                100
              ).toFixed(2) || 0,
            value:
              graphData?.data?.mapOfOhcStatsThisMonth?.FEMALEINJURY?.count || 0,
          },
          // UNDEFINED: {
          //   sequence: 3,
          //   percent: (
          //     (graphData?.data?.mapOfOhcStatsThisMonth?.GENDER_INJURY_OTHERS?.count /
          //       (graphData?.data?.mapOfOhcStatsThisMonth?.MALEINJURY?.count +
          //         graphData?.data?.mapOfOhcStatsThisMonth?.FEMALEINJURY?.count +
          //         graphData?.data?.mapOfOhcStatsThisMonth?.GENDER_INJURY_OTHERS?.count)) *
          //     100
          //   ).toFixed(2),
          //   value: graphData?.data?.mapOfOhcStatsThisMonth?.GENDER_INJURY_OTHERS?.count,
          // },
        },
        THIS_WEEK: {
          MALE: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisWeek?.MALEINJURY?.count /
                  (graphData?.data?.mapOfOhcStatsThisWeek?.MALEINJURY?.count +
                    graphData?.data?.mapOfOhcStatsThisWeek?.FEMALEINJURY
                      ?.count)) *
                //    +
                // graphData?.data?.mapOfOhcStatsThisWeek?.GENDER_INJURY_OTHERS
                //   ?.count
                100
              ).toFixed(2) || 0,
            value:
              graphData?.data?.mapOfOhcStatsThisWeek?.MALEINJURY?.count || 0,
          },
          FEMALE: {
            sequence: 2,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisWeek?.FEMALEINJURY?.count /
                  (graphData?.data?.mapOfOhcStatsThisWeek?.MALEINJURY?.count +
                    graphData?.data?.mapOfOhcStatsThisWeek?.FEMALEINJURY
                      ?.count)) *
                //   +
                // graphData?.data?.mapOfOhcStatsThisWeek?.GENDER_INJURY_OTHERS
                //   ?.count
                100
              ).toFixed(2) || 0,
            value:
              graphData?.data?.mapOfOhcStatsThisWeek?.FEMALEINJURY?.count || 0,
          },
          // UNDEFINED: {
          //   sequence: 3,
          //   percent: (
          //     (graphData?.data?.mapOfOhcStatsThisWeek?.GENDER_INJURY_OTHERS?.count /
          //       (graphData?.data?.mapOfOhcStatsThisWeek?.MALEINJURY?.count +
          //         graphData?.data?.mapOfOhcStatsThisWeek?.FEMALEINJURY?.count +
          //         graphData?.data?.mapOfOhcStatsThisWeek?.GENDER_INJURY_OTHERS?.count)) *
          //     100
          //   ).toFixed(2),
          //   value: graphData?.data?.mapOfOhcStatsThisWeek?.GENDER_INJURY_OTHERS?.count,
          // },
        },
      };
      const genderWiseInjuryEmployeeList = {
        THIS_YEAR: {
          MALE: graphData?.data?.mapOfOhcStatsThisYear?.MALEINJURY?.entities,
          FEMALE:
            graphData?.data?.mapOfOhcStatsThisYear?.FEMALEINJURY?.entities,
          // UNDEFINED: graphData?.data?.mapOfOhcStatsThisYear?.GENDER_INJURY_OTHERS?.entities,
        },
        THIS_MONTH: {
          MALE: graphData?.data?.mapOfOhcStatsThisMonth?.MALEINJURY?.entities,
          FEMALE:
            graphData?.data?.mapOfOhcStatsThisMonth?.FEMALEINJURY?.entities,
          // UNDEFINED: graphData?.data?.mapOfOhcStatsThisMonth?.GENDER_INJURY_OTHERS?.entities,
        },
        THIS_WEEK: {
          MALE: graphData?.data?.mapOfOhcStatsThisWeek?.MALEINJURY?.entities,
          FEMALE:
            graphData?.data?.mapOfOhcStatsThisWeek?.FEMALEINJURY?.entities,
          // UNDEFINED: graphData?.data?.mapOfOhcStatsThisWeek?.GENDER_INJURY_OTHERS?.entities,
        },
      };

      const injuryTreatedAt = {
        THIS_YEAR: {
          REFERRED_OUTSIDE: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisYear?.REFERRED_OUTSIDE
                  ?.count /
                  (graphData?.data?.mapOfOhcStatsThisYear?.REFERRED_OUTSIDE
                    ?.count +
                    graphData?.data?.mapOfOhcStatsThisYear?.TREATED_IN_OHC
                      ?.count)) *
                //    +
                // graphData?.data?.mapOfOhcStatsThisYear?.TREATED_AT_OTHERS
                //   ?.count
                100
              ).toFixed(2) || 0,
            value:
              graphData?.data?.mapOfOhcStatsThisYear?.REFERRED_OUTSIDE?.count ||
              0,
          },
          TREATED_IN_OHC: {
            sequence: 2,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisYear?.TREATED_IN_OHC?.count /
                  (graphData?.data?.mapOfOhcStatsThisYear?.REFERRED_OUTSIDE
                    ?.count +
                    graphData?.data?.mapOfOhcStatsThisYear?.TREATED_IN_OHC
                      ?.count)) *
                //   +
                // graphData?.data?.mapOfOhcStatsThisYear?.TREATED_AT_OTHERS
                //   ?.count
                100
              ).toFixed(2) || 0,
            value:
              graphData?.data?.mapOfOhcStatsThisYear?.TREATED_IN_OHC?.count ||
              0,
          },
          // UNDEFINED: {
          //   sequence: 3,
          //   percent: (
          //     (graphData?.data?.mapOfOhcStatsThisYear?.TREATED_AT_OTHERS?.count /
          //       (graphData?.data?.mapOfOhcStatsThisYear?.REFERRED_OUTSIDE?.count +
          //         graphData?.data?.mapOfOhcStatsThisYear?.TREATED_IN_OHC?.count +
          //         graphData?.data?.mapOfOhcStatsThisYear?.TREATED_AT_OTHERS?.count)) *
          //     100
          //   ).toFixed(2),
          //   value: graphData?.data?.mapOfOhcStatsThisYear?.TREATED_AT_OTHERS?.count,
          // },
        },
        THIS_MONTH: {
          REFERRED_OUTSIDE: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisMonth?.REFERRED_OUTSIDE
                  ?.count /
                  (graphData?.data?.mapOfOhcStatsThisMonth?.REFERRED_OUTSIDE
                    ?.count +
                    graphData?.data?.mapOfOhcStatsThisMonth?.TREATED_IN_OHC
                      ?.count)) *
                //   +
                // graphData?.data?.mapOfOhcStatsThisMonth?.TREATED_AT_OTHERS
                //   ?.count
                100
              ).toFixed(2) || 0,
            value:
              graphData?.data?.mapOfOhcStatsThisMonth?.REFERRED_OUTSIDE
                ?.count || 0,
          },
          TREATED_IN_OHC: {
            sequence: 2,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisMonth?.TREATED_IN_OHC?.count /
                (graphData?.data?.mapOfOhcStatsThisMonth?.REFERRED_OUTSIDE
                  ?.count +
                  graphData?.data?.mapOfOhcStatsThisMonth?.TREATED_IN_OHC
                    ?.count)) *
              //   +
              // graphData?.data?.mapOfOhcStatsThisMonth?.TREATED_AT_OTHERS
              //   ?.count
              100
            ).toFixed(2),
            value:
              graphData?.data?.mapOfOhcStatsThisMonth?.TREATED_IN_OHC?.count ||
              0,
          },
          // UNDEFINED: {
          //   sequence: 3,
          //   percent: (
          //     (graphData?.data?.mapOfOhcStatsThisMonth?.TREATED_AT_OTHERS?.count /
          //       (graphData?.data?.mapOfOhcStatsThisMonth?.REFERRED_OUTSIDE?.count +
          //         graphData?.data?.mapOfOhcStatsThisMonth?.TREATED_IN_OHC?.count +
          //         graphData?.data?.mapOfOhcStatsThisMonth?.TREATED_AT_OTHERS?.count)) *
          //     100
          //   ).toFixed(2),
          //   value: graphData?.data?.mapOfOhcStatsThisMonth?.TREATED_AT_OTHERS?.count,
          // },
        },
        THIS_WEEK: {
          REFERRED_OUTSIDE: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisWeek?.REFERRED_OUTSIDE
                  ?.count /
                  (graphData?.data?.mapOfOhcStatsThisWeek?.REFERRED_OUTSIDE
                    ?.count +
                    graphData?.data?.mapOfOhcStatsThisWeek?.TREATED_IN_OHC
                      ?.count)) *
                //    +
                // graphData?.data?.mapOfOhcStatsThisWeek?.TREATED_AT_OTHERS
                //   ?.count
                100
              ).toFixed(2) || 0,
            value:
              graphData?.data?.mapOfOhcStatsThisWeek?.REFERRED_OUTSIDE?.count ||
              0,
          },
          TREATED_IN_OHC: {
            sequence: 2,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisWeek?.TREATED_IN_OHC?.count /
                  (graphData?.data?.mapOfOhcStatsThisWeek?.REFERRED_OUTSIDE
                    ?.count +
                    graphData?.data?.mapOfOhcStatsThisWeek?.TREATED_IN_OHC
                      ?.count)) *
                //    +
                // graphData?.data?.mapOfOhcStatsThisWeek?.TREATED_AT_OTHERS
                //   ?.count
                100
              ).toFixed(2) || 0,
            value:
              graphData?.data?.mapOfOhcStatsThisWeek?.TREATED_IN_OHC?.count ||
              0,
          },
          // UNDEFINED: {
          //   sequence: 3,
          //   percent: (
          //     (graphData?.data?.mapOfOhcStatsThisWeek?.TREATED_AT_OTHERS?.count /
          //       (graphData?.data?.mapOfOhcStatsThisWeek?.REFERRED_OUTSIDE?.count +
          //         graphData?.data?.mapOfOhcStatsThisWeek?.TREATED_IN_OHC?.count +
          //         graphData?.data?.mapOfOhcStatsThisWeek?.TREATED_AT_OTHERS?.count)) *
          //     100
          //   ).toFixed(2),
          //   value: graphData?.data?.mapOfOhcStatsThisWeek?.TREATED_AT_OTHERS?.count,
          // },
        },
      };
      const injuryTreatedAtEmployeeList = {
        THIS_YEAR: {
          REFERRED_OUTSIDE:
            graphData?.data?.mapOfOhcStatsThisYear?.REFERRED_OUTSIDE?.entities,
          TREATED_IN_OHC:
            graphData?.data?.mapOfOhcStatsThisYear?.TREATED_IN_OHC?.entities,
          // UNDEFINED: graphData?.data?.mapOfOhcStatsThisYear?.TREATED_AT_OTHERS?.entities,
        },
        THIS_MONTH: {
          REFERRED_OUTSIDE:
            graphData?.data?.mapOfOhcStatsThisMonth?.REFERRED_OUTSIDE?.entities,
          TREATED_IN_OHC:
            graphData?.data?.mapOfOhcStatsThisMonth?.TREATED_IN_OHC?.entities,
          // UNDEFINED: graphData?.data?.mapOfOhcStatsThisMonth?.TREATED_AT_OTHERS?.entities,
        },
        THIS_WEEK: {
          REFERRED_OUTSIDE:
            graphData?.data?.mapOfOhcStatsThisWeek?.REFERRED_OUTSIDE?.entities,
          TREATED_IN_OHC:
            graphData?.data?.mapOfOhcStatsThisWeek?.TREATED_IN_OHC?.entities,
          // UNDEFINED: graphData?.data?.mapOfOhcStatsThisWeek?.TREATED_AT_OTHERS?.entities,
        },
      };

      const genderWiseOPD = {
        THIS_YEAR: {
          MALE: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisYear?.MALEOPD?.count /
                  (graphData?.data?.mapOfOhcStatsThisYear?.MALEOPD?.count +
                    graphData?.data?.mapOfOhcStatsThisYear?.FEMALEOPD?.count)) *
                // +
                // graphData?.data?.mapOfOhcStatsThisYear?.GENDER_OPD_OTHERS
                //   ?.count
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisYear?.MALEOPD?.count || 0,
          },
          FEMALE: {
            sequence: 2,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisYear?.FEMALEOPD?.count /
                  (graphData?.data?.mapOfOhcStatsThisYear?.MALEOPD?.count +
                    graphData?.data?.mapOfOhcStatsThisYear?.FEMALEOPD?.count)) *
                // +
                // graphData?.data?.mapOfOhcStatsThisYear?.GENDER_OPD_OTHERS
                //   ?.count
                100
              ).toFixed(2) || 0,
            value:
              graphData?.data?.mapOfOhcStatsThisYear?.FEMALEOPD?.count || 0,
          },
          // UNDEFINED: {
          //   sequence: 3,
          //   percent: (
          //     (graphData?.data?.mapOfOhcStatsThisYear?.GENDER_OPD_OTHERS?.count /
          //       (graphData?.data?.mapOfOhcStatsThisYear?.MALEOPD?.count +
          //         graphData?.data?.mapOfOhcStatsThisYear?.FEMALEOPD?.count +
          //         graphData?.data?.mapOfOhcStatsThisYear?.GENDER_OPD_OTHERS?.count)) *
          //     100
          //   ).toFixed(2),
          //   value: graphData?.data?.mapOfOhcStatsThisYear?.GENDER_OPD_OTHERS?.count,
          // },
        },
        THIS_MONTH: {
          MALE: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisMonth?.MALEOPD?.count /
                  (graphData?.data?.mapOfOhcStatsThisMonth?.MALEOPD?.count +
                    graphData?.data?.mapOfOhcStatsThisMonth?.FEMALEOPD
                      ?.count)) *
                // +
                // graphData?.data?.mapOfOhcStatsThisMonth?.GENDER_OPD_OTHERS
                //   ?.count
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisMonth?.MALEOPD?.count || 0,
          },
          FEMALE: {
            sequence: 2,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisMonth?.FEMALEOPD?.count /
                  (graphData?.data?.mapOfOhcStatsThisMonth?.MALEOPD?.count +
                    graphData?.data?.mapOfOhcStatsThisMonth?.FEMALEOPD
                      ?.count)) *
                // +
                // graphData?.data?.mapOfOhcStatsThisMonth?.GENDER_OPD_OTHERS
                //   ?.count
                100
              ).toFixed(2) || 0,
            value:
              graphData?.data?.mapOfOhcStatsThisMonth?.FEMALEOPD?.count || 0,
          },
          // UNDEFINED: {
          //   sequence: 3,
          //   percent: (
          //     (graphData?.data?.mapOfOhcStatsThisMonth?.GENDER_OPD_OTHERS?.count /
          //       (graphData?.data?.mapOfOhcStatsThisMonth?.MALEOPD?.count +
          //         graphData?.data?.mapOfOhcStatsThisMonth?.FEMALEOPD?.count +
          //         graphData?.data?.mapOfOhcStatsThisMonth?.GENDER_OPD_OTHERS?.count)) *
          //     100
          //   ).toFixed(2),
          //   value: graphData?.data?.mapOfOhcStatsThisMonth?.GENDER_OPD_OTHERS?.count,
          // },
        },
        THIS_WEEK: {
          MALE: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisWeek?.MALEOPD?.count /
                  (graphData?.data?.mapOfOhcStatsThisWeek?.MALEOPD?.count +
                    graphData?.data?.mapOfOhcStatsThisWeek?.FEMALEOPD?.count)) *
                // +
                // graphData?.data?.mapOfOhcStatsThisWeek?.GENDER_OPD_OTHERS
                //   ?.count
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisWeek?.MALEOPD?.count || 0,
          },
          FEMALE: {
            sequence: 2,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisWeek?.FEMALEOPD?.count /
                  (graphData?.data?.mapOfOhcStatsThisWeek?.MALEOPD?.count +
                    graphData?.data?.mapOfOhcStatsThisWeek?.FEMALEOPD?.count)) *
                // +
                // graphData?.data?.mapOfOhcStatsThisWeek?.GENDER_OPD_OTHERS
                //   ?.count
                100
              ).toFixed(2) || 0,
            value:
              graphData?.data?.mapOfOhcStatsThisWeek?.FEMALEOPD?.count || 0,
          },
          // UNDEFINED: {
          //   sequence: 3,
          //   percent: (
          //     (graphData?.data?.mapOfOhcStatsThisWeek?.GENDER_OPD_OTHERS?.count /
          //       (graphData?.data?.mapOfOhcStatsThisWeek?.MALEOPD?.count +
          //         graphData?.data?.mapOfOhcStatsThisWeek?.FEMALEOPD?.count +
          //         graphData?.data?.mapOfOhcStatsThisWeek?.GENDER_OPD_OTHERS?.count)) *
          //     100
          //   ).toFixed(2),
          //   value: graphData?.data?.mapOfOhcStatsThisWeek?.GENDER_OPD_OTHERS?.count,
          // },
        },
      };
      const genderWiseOPDEmployeeList = {
        THIS_YEAR: {
          MALE: graphData?.data?.mapOfOhcStatsThisYear?.MALEOPD?.entities,
          FEMALE: graphData?.data?.mapOfOhcStatsThisYear?.FEMALEOPD?.entities,
          // UNDEFINED: graphData?.data?.mapOfOhcStatsThisYear?.GENDER_OPD_OTHERS?.entities,
        },
        THIS_MONTH: {
          MALE: graphData?.data?.mapOfOhcStatsThisMonth?.MALEOPD?.entities,
          FEMALE: graphData?.data?.mapOfOhcStatsThisMonth?.FEMALEOPD?.entities,
          // UNDEFINED: graphData?.data?.mapOfOhcStatsThisMonth?.GENDER_OPD_OTHERS?.entities,
        },
        THIS_WEEK: {
          MALE: graphData?.data?.mapOfOhcStatsThisWeek?.MALEOPD?.entities,
          FEMALE: graphData?.data?.mapOfOhcStatsThisWeek?.FEMALEOPD?.entities,
          // UNDEFINED: graphData?.data?.mapOfOhcStatsThisWeek?.GENDER_OPD_OTHERS?.entities,
        },
      };

      const footFallBreakUp = {
        THIS_YEAR: {
          OPD: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisYear?.OPDCOUNT?.count /
                  (graphData?.data?.mapOfOhcStatsThisYear?.OPDCOUNT?.count +
                    graphData?.data?.mapOfOhcStatsThisYear?.INJURYCOUNT
                      ?.count)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisYear?.OPDCOUNT?.count,
          },
          INJURY: {
            sequence: 2,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisYear?.INJURYCOUNT?.count /
                (graphData?.data?.mapOfOhcStatsThisYear?.OPDCOUNT?.count +
                  graphData?.data?.mapOfOhcStatsThisYear?.INJURYCOUNT?.count)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisYear?.INJURYCOUNT?.count,
          },
        },
        THIS_MONTH: {
          OPD: {
            sequence: 1,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisMonth?.OPDCOUNT?.count /
                (graphData?.data?.mapOfOhcStatsThisMonth?.OPDCOUNT?.count +
                  graphData?.data?.mapOfOhcStatsThisMonth?.INJURYCOUNT
                    ?.count)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisMonth?.OPDCOUNT?.count,
          },
          INJURY: {
            sequence: 2,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisMonth?.INJURYCOUNT?.count /
                (graphData?.data?.mapOfOhcStatsThisMonth?.OPDCOUNT?.count +
                  graphData?.data?.mapOfOhcStatsThisMonth?.INJURYCOUNT
                    ?.count)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisMonth?.INJURYCOUNT?.count,
          },
        },
        THIS_WEEK: {
          OPD: {
            sequence: 1,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisWeek?.OPDCOUNT?.count /
                (graphData?.data?.mapOfOhcStatsThisWeek?.OPDCOUNT?.count +
                  graphData?.data?.mapOfOhcStatsThisWeek?.INJURYCOUNT?.count)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisWeek?.OPDCOUNT?.count,
          },
          INJURY: {
            sequence: 2,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisWeek?.INJURYCOUNT?.count /
                (graphData?.data?.mapOfOhcStatsThisWeek?.OPDCOUNT?.count +
                  graphData?.data?.mapOfOhcStatsThisWeek?.INJURYCOUNT?.count)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisWeek?.INJURYCOUNT?.count,
          },
        },
      };

      console.log({
        213131212: convertMapToObject(
          pieGraphData?.mapOfDurationAndDepartmentGraph?.[selectedDuration]
        ),
      });

      const temp = {
        footFallBreakUp,

        injuryBreakUp,
        injuryBreakUpEmployeeList,

        injurySeverity,
        injurySeverityEmployeeList,

        injuryTreatedAt,
        injuryTreatedAtEmployeeList,

        genderWiseOPD,
        genderWiseOPDEmployeeList,

        genderWiseInjury,
        genderWiseInjuryEmployeeList,

        ...graphData?.data,
      };

      setPieGraphData(temp);
    }
  };

  const [opdGraphData, setOpdGraphData] = useState(null);
  const getGraphDataForOPD = async () => {
    const url =
      BASE_URL +
      `org/ohcStatusTracker/${corpId}?duration=${
        selectedDuration || ""
      }&department=${selectedDepartment || ""}`;
    const graphData = await getData(url, "");
    if (graphData.error) {
      setOpdGraphData(null);
    } else {
      setOpdGraphData(graphData.data);
    }
  };
  useEffect(() => {
    getGraphDataForOPD();
    getGraphData();
  }, [selectedDepartment, selectedDuration]);

  return (
    <Fragment>
      <Container maxWidth={false}>
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item xs={12} lg={4} sm={12} md={4}>
            <ButtonGroup
              size="small"
              variant="contained"
              sx={{ borderRadius: 5 }}
            >
              {durationOption.map((option) => (
                <Button
                  size="small"
                  key={option.id}
                  variant={
                    selectedDuration === option.value ? "contained" : "outlined"
                  }
                  sx={{ borderRadius: 5 }}
                  onClick={() => setSelectedDuration(option.value)}
                >
                  {option.label}
                </Button>
              ))}
            </ButtonGroup>
          </Grid>
          <Grid item xs={12} lg={4} sm={12} md={4}>
            <DashboardStats
              data={convertDataToArray(
                pieGraphData?.footFallBreakUp?.[selectedDuration]
              )}
            />
          </Grid>
          <Grid item xs={12} lg={4} sm={12} md={4}>
            {/* <NoOfCasesComp
              data={convertDataToArray(pieGraphData?.footFallBreakUp?.[selectedDuration])}
            /> */}
            <Autocomplete
              value={selectedDepartment}
              options={departmentOptions}
              getOptionLabel={(option) => option.label}
              onChange={(event, newValue) => setSelectedDepartment(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Department" size="small" />
              )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={5} mt={0}>
          {checkIsListIsNotNull(
            convertDataToArray(pieGraphData?.injuryBreakUp?.[selectedDuration])
          ) && (
            <Grid item xs={12} sm={12} md={3} lg={2}>
              <OhcDoughnutChart
                COLORS={["#D45FFF", "#127DDD", "#FFE066", "#DDFFDF"]}
                data={convertDataToArray(
                  pieGraphData?.injuryBreakUp?.[selectedDuration]
                )}
                boxHeight={400}
                title={"Injury Type"}
                labelHeading={"Total"}
                disableClickable={false}
                dialogData={
                  pieGraphData?.injuryBreakUpEmployeeList?.[selectedDuration]
                }
              />
            </Grid>
          )}

          {checkIsListIsNotNull(
            convertDataToArray(pieGraphData?.injurySeverity?.[selectedDuration])
          ) && (
            <Grid item xs={12} sm={12} md={3} lg={2}>
              <OhcDoughnutChart
                COLORS={["#0121c5", "#54fddd", "#f30c00", "#b5c1c1"]}
                data={convertDataToArray(
                  pieGraphData?.injurySeverity?.[selectedDuration]
                )}
                title={"Injury Severity"}
                labelHeading={"Total"}
                disableClickable={false}
                dialogData={
                  pieGraphData?.injurySeverityEmployeeList?.[selectedDuration]
                }
              />
            </Grid>
          )}

          {checkIsListIsNotNull(
            convertDataToArray(
              pieGraphData?.genderWiseInjury?.[selectedDuration]
            )
          ) && (
            <Grid item xs={12} sm={12} md={3} lg={2}>
              <OhcDoughnutChart
                COLORS={["#127DDD", "#D45FFF", "#FFE066"]}
                data={convertDataToArray(
                  pieGraphData?.genderWiseInjury?.[selectedDuration]
                )}
                title={"Gender Wise - Injury"}
                labelHeading={"Total"}
                disableClickable={false}
                dialogData={
                  pieGraphData?.genderWiseInjuryEmployeeList?.[selectedDuration]
                }
              />
            </Grid>
          )}

          {checkIsListIsNotNull(
            convertDataToArray(
              pieGraphData?.injuryTreatedAt?.[selectedDuration]
            )
          ) && (
            <Grid item xs={12} sm={12} md={3} lg={2}>
              <OhcDoughnutChart
                COLORS={["#D45FFF", "#127DDD", "#FFE066"]}
                data={convertDataToArray(
                  pieGraphData?.injuryTreatedAt?.[selectedDuration]
                )}
                title={" Treatment Place"}
                labelHeading={"Total"}
                disableClickable={false}
                dialogData={
                  pieGraphData?.injuryTreatedAtEmployeeList?.[selectedDuration]
                }
              />
            </Grid>
          )}

          {checkIsListIsNotNull(
            convertDataToArray(
              convertMapToObject(
                sortData(
                  transformValue(
                    pieGraphData?.mapOfDurationAndAgeGraph?.[selectedDuration],
                    (val) => val.count
                  )
                )
              )
            )
          ) && (
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <CustomBarGraphOhc
                data={convertMapToObject(
                  sortData(
                    transformValue(
                      pieGraphData?.mapOfDurationAndAgeGraph?.[
                        selectedDuration
                      ],
                      (val) => val.count
                    )
                  )
                )}
                title={"Age Group Wise Division - Injury"}
                labelHeading={""}
                disableClickable={false}
                boxHeight={"100%"}
                xAxisLabel="Age Group"
                dialogData={transformValue(
                  pieGraphData?.mapOfDurationAndAgeGraph?.[selectedDuration],
                  (val) => val.entities
                )}
              />
            </Grid>
          )}

          {checkIsListIsNotNull(
            convertDataToArray(pieGraphData?.genderWiseOPD?.[selectedDuration])
          ) && (
            <Grid item xs={12} sm={12} md={3} lg={2}>
              <OhcDoughnutChart
                COLORS={["#127DDD", "#D45FFF", "#FFE066"]}
                data={convertDataToArray(
                  pieGraphData?.genderWiseOPD?.[selectedDuration]
                )}
                title={"Gender Wise - OPD"}
                labelHeading={"Total"}
                disableClickable={false}
                dialogData={
                  pieGraphData?.genderWiseOPDEmployeeList?.[selectedDuration]
                }
              />
            </Grid>
          )}

          {checkIsListIsNotNull(
            convertMapToObject(opdGraphData?.mapOfIllnessTop10)
          ) && (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TopIllnessGraph
                data={convertMapToObject(opdGraphData?.mapOfIllnessTop10)}
                opdGraphData={opdGraphData}
              />
            </Grid>
          )}

          {checkIsListIsNotNull(
            convertMapToObject(sortData(opdGraphData?.mapOfAge))
          ) && (
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <CustomBarGraphOhc
                COLORS={[]}
                data={convertMapToObject(sortData(opdGraphData?.mapOfAge))}
                title={"Age Group Wise Division - OPD"}
                disableClickable={false}
                boxHeight={"100%"}
                xAxisLabel="Age Group"
                dialogData={opdGraphData?.mapOfAgeWithEmployeeDetails}
              />
            </Grid>
          )}

          {checkIsListIsNotNull(
            convertMapToObjectNew(
              pieGraphData?.mapOfDurationAndDepartmentGraph?.[selectedDuration]
            )
          ) && (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <CustomBarGraphOhc
                COLORS={[]}
                data={convertMapToObjectNew(
                  pieGraphData?.mapOfDurationAndDepartmentGraph?.[
                    selectedDuration
                  ]
                )}
                title={"Top Department - Injury"}
                labelHeading={""}
                disableClickable={false}
                boxHeight={"100%"}
                xAxisLabel="Departments"
                dialogData={transformValue(
                  pieGraphData?.mapOfDurationAndDepartmentGraph?.[
                    selectedDuration
                  ],
                  (val) => val.entities
                )}
                graphHeight={250}
                legendHeight={80}
              />
            </Grid>
          )}

          {checkIsListIsNotNull(
            convertMapToObject(opdGraphData?.mapOfDepartmentTop10)
          ) && (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <CustomBarGraphOhc
                COLORS={[]}
                data={convertMapToObject(opdGraphData?.mapOfDepartmentTop10)}
                title={"Top Department - OPD"}
                labelHeading={""}
                disableClickable={false}
                boxHeight={"100%"}
                xAxisLabel="Departments"
                dialogData={opdGraphData?.mapOfDepartmentWithEmployeeDetails}
                graphHeight={250}
                legendHeight={80}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default OhcStatusTrackerNew;

const checkIsListIsNotNull = (array) => {
  console.log({ array });
  return array && array.length > 0, array?.some((item) => item.value !== 0);
};
