import { Box, Grid, Stack } from "@mui/material";
import React, { Fragment } from "react";
import DisplayCard from "./displayCard";

const FireSafety = ({ healthChampionsList }) => {
  const data = [
    {
      id: 1,
      name: "Jaipal Singh",
      url: "",
    },
    {
      id: 2,
      name: "Hemraj Ashorkar",
      url: "",
    },
    {
      id: 3,
      name: "Lakhan Choudhary",
      url: "",
    },
    {
      id: 4,
      name: "Sunil Shanisare",
      url: "",
    },
    {
      id: 5,
      name: "Naushad Khan",
      url: "",
    },
    {
      id: 6,
      name: "Sunil Beohar",
      url: "",
    },
    {
      id: 7,
      name: "Shravan Kumar Mishra",
      url: "",
    },
    {
      id: 8,
      name: "Munnilal Choudhary",
      url: "",
    },
    {
      id: 9,
      name: "Chandrapal Kashyap",
      url: "",
    },
    // {
    //   id: 10,
    //   name: "Anand Prajapati",
    //   url: "",
    // },
    // {
    //   id: 11,
    //   name: "Ramprasad",
    //   url: "",
    // },
    // {
    //   id: 12,
    //   name: "Jitendra Singh Goad",
    //   url: "",
    // },
    // {
    //   id: 13,
    //   name: "Lokendra Pawar",
    //   url: "",
    // },
    {
      id: 14,
      name: "Gopal Dawar",
      url: "",
    },
    // {
    //   id: 15,
    //   name: "Pramod Shukla",
    //   url: "",
    // },
    {
      id: 16,
      name: "Jhabbu Wadekar",
      url: "",
    },
    {
      id: 17,
      name: "Sajeel Pramal",
      url: "",
    },
    {
      id: 18,
      name: "Leeladhar Thakre",
      url: "",
    },
    {
      id: 19,
      name: "Rajkumar Dhote",
      url: "",
    },
    // {
    //   id: 20,
    //   name: "Radheshyam Bundela",
    //   url: "",
    // },
    {
      id: 21,
      name: "Umesh Kumar Yadav",
      url: "",
    },
    // {
    //   id: 22,
    //   name: "Asha Chauhan",
    //   url: "",
    // },
    // {
    //   id: 23,
    //   name: "Poonam Lopez",
    //   url: "",
    // },
    {
      id: 24,
      name: "Navendra Rao",
      url: "",
    },
    // {
    //   id: 25,
    //   name: "Ashish Mishra",
    //   url: "",
    // },
    {
      id: 26,
      name: "Narendra Panchal",
      url: "",
    },
    {
      id: 27,
      name: "Rajesh Chauhan",
      url: "",
    },
    {
      id: 28,
      name: "Adhar Singh",
      url: "",
    },
    {
      id: 29,
      name: "Ravindra Wadekar",
      url: "",
    },
    {
      id: 30,
      name: "Ashish Sen",
      url: "",
    },
    // {
    //   id: 31,
    //   name: "Ashish Sen",
    //   url: "",
    // },
    // {
    //   id: 32,
    //   name: "Ashish Sen",
    //   url: "",
    // },
    // {
    //   id: 33,
    //   name: "Ashish Sen",
    //   url: "",
    // },
    // {
    //   id: 34,
    //   name: "Ashish Sen",
    //   url: "",
    // },
    // {
    //   id: 35,
    //   name: "Ashish Sen",
    //   url: "",
    // },
  ];
  return (
    <Fragment>
      <Box sx={{ mb: 4, mt: 2 }}>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Stack
              direction="row"
              spacing={3}
              useFlexGap
              flexWrap="wrap"
              display="flex"
              justifyContent="center"
            >
              {healthChampionsList.map((item, index) => (
                <DisplayCard key={index} item={item} type={"FIRE_SAFETY"} />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default FireSafety;
