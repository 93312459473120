import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Modal,
  Portal,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import WritePrescriptionTab from "./WritePrescriptionTab";

const WritePrescriptionModal = ({ open, handleClose }) => {
  const [tabvalue, setTabValue] = useState(false);
  return (
    <Portal>
      <Dialog open={open} onClose={handleClose} maxWidth={"xl"} fullWidth={true}>
        <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
          Write Prescription
        </DialogTitle>
        <DialogActions sx={{ position: "absolute", top: 0, right: 0 }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent style={{ height: "75vh" }}>
          <WritePrescriptionTab tabvalue={tabvalue} setTabValue={setTabValue} />
        </DialogContent>
      </Dialog>
    </Portal>
  );
};

export default WritePrescriptionModal;
