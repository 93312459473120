import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";
import tickCircleIcon from "../../../../assets/images/tickCircleIcon.png";

const LeftPart = () => {
  return (
    <Fragment>
      <Box sx={{ paddingInline: "60px" }}>
        <Typography
          sx={{
            color: "#FFF",
            fontSize: "48px",
            fontWeight: "700",
            lineHeight: "normal",
            marginBlock: "10px",
          }}>
          Welcome To Our Healthcare Center
        </Typography>
        <Typography
          sx={{
            color: "#FFF",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "normal",
            marginBlock: "10px",
          }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat.
        </Typography>
        <Box sx={{ display: "flex", marginBlock: "15px" }}>
          <Box component="img" src={tickCircleIcon} style={{ height: "20px", width: "20px" }} />
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "normal",
              marginLeft: "10px",
              marginTop: "1px",
            }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </Typography>
        </Box>
        <Box sx={{ display: "flex", marginBlock: "15px" }}>
          <Box component="img" src={tickCircleIcon} style={{ height: "20px", width: "20px" }} />
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "normal",
              marginLeft: "10px",
              marginTop: "1px",
            }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </Typography>
        </Box>
        <Box sx={{ display: "flex", marginBlock: "15px" }}>
          <Box component="img" src={tickCircleIcon} style={{ height: "20px", width: "20px" }} />
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "normal",
              marginLeft: "10px",
              marginTop: "1px",
            }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </Typography>
        </Box>
        <Box sx={{ display: "flex", marginBlock: "15px" }}>
          <Box component="img" src={tickCircleIcon} style={{ height: "20px", width: "20px" }} />
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "normal",
              marginLeft: "10px",
              marginTop: "1px",
            }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
};

export default LeftPart;
