import React, { Fragment, useEffect, useState } from "react";
import CustomDataGridLayout from "../../../../../assets/customDataGridLayout/customDataGridLayout";
import CustomAutocomplete from "../../../../../assets/customAutocomplete/customAutocomplete";
import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Portal,
  Stack,
  Toolbar,
  Tooltip,
} from "@mui/material";
import Papa from "papaparse";
import ParseCSV from "../../../../../assets/parseCSV/parseCSV";
import dayjs from "dayjs";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { saveData } from "../../../../../services/api/postApi";
import { useSnackbar } from "notistack";
import {
  handleDownloadMasterData,
  handleFetchMasterData,
} from "../../../../services/inventory";
import { Download } from "@mui/icons-material";
import {
  downloadCsv,
  isEveryKeyEmptyExcluding,
} from "../../../../../assets/utils";
import Close from "@mui/icons-material/Close";
import { updateData } from "../../../../../services/api/patchApi";

const allowedExtensions = ["csv", "xlsx"];

const DefineReorderPointBulkUpload = ({
  corpId = localStorage.getItem("CORPID"),
  userAuthID = localStorage.getItem("ORGUSERIDAUTH"),
  open,
  handleClose,
  requiredFile = [],
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [productList, setProductList] = useState([]);
  const [productListUpdated, setProductListUpdated] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    if (productList) {
      setProductListUpdated(
        productList.map((item, index) => ({
          id: index,
          productCode: item.productcode,
          minimumUnit: item.minimumunit,
        }))
      );
    }
  }, [productList]);

  const handleSubmit = async () => {
    const payload = productListUpdated
      .filter((v) => v.productCode)
      .map(({ id, ...rest }) => rest);
    const url = BASE_URL + `inventory/updateReorderPoint/${corpId}`;
    const result = await updateData(url, payload);
    if (result.error) {
      enqueueSnackbar(`An Error Occured`, {
        variant: "error",
      });
    } else {
      enqueueSnackbar(`Successfully Updated`, {
        variant: "success",
      });
      if (result.data.duplicateInCSV.length > 0) {
        downloadCsv(result.data.duplicateInCSV, "duplicateInCSV");
      } else if (result.data.invalidProductCodeList.length > 0) {
        downloadCsv(
          result.data.invalidProductCodeList,
          "invalidProductCodeList"
        );
      } else if (result.data.inventoryNotFoundList.length > 0) {
        downloadCsv(result.data.inventoryNotFoundList, "inventoryNotFoundList");
      }
      handleClose();
    }
  };

  const handleFileChange = (e) => {
    setError("");
    if (e.target.files.length) {
      const inputFile = e.target.files[0];
      const fileExtension = inputFile?.type.split("/")[1];
      console.log({ fileExtension });
      if (!allowedExtensions.includes(fileExtension)) {
        setError("Please input a csv file");
        return;
      }
      console.log({ inputFile });
      handleParse(inputFile);
    }
  };

  const handleParse = (file) => {
    console.log({ file });
    if (!file) return alert("Enter a valid file");
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, {
        header: true,
        transformHeader: (header) =>
          header.trim()?.replaceAll(/\s/g, "").toLowerCase(),
      });
      const parsedData = csv?.data;
      const rows = Object.keys(parsedData[0]);
      const columns = Object.values(parsedData[0]);
      const res = rows.reduce((acc, e, i) => {
        return [...acc, e];
      }, []);
      console.log({ columns, res, parsedData });
      if (!res.includes("productcode")) return alert("header is missing.");
      setProductList(parsedData);
    };
    reader.readAsText(file);
  };

  return (
    <Fragment>
      <Portal>
        <Dialog
          fullWidth={true}
          maxWidth={false}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle sx={{ display: "flex", justifyContent: "center" }}>
            Define Reorder Point in bulk
          </DialogTitle>

          <DialogActions sx={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </DialogActions>
          <DialogContent>
            <Box sx={{ marginBottom: 10 }}>
              <Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 2 }}
                spacing={2}
              >
                <Grid
                  item
                  lg={9}
                  md={9}
                  sm={12}
                  xs={12}
                  display="flex"
                  gap={2}
                  sx={{ flexWrap: "wrap" }}
                >
                  <Tooltip title="Use this file for adding inventory">
                    <Button
                      variant="contained"
                      onClick={() => {
                        downloadCsv(
                          requiredFile
                            .map((item) => ({
                              ...item,
                              minimumUnit: "",
                            }))
                            .map(
                              ({
                                reorderPoint,
                                availableQuantity,
                                corpName,
                                ...rest
                              }) => rest
                            ),
                          "Inventory Data File"
                        );
                      }}
                      startIcon={<Download sx={{ color: "#FFF" }} />}
                    >
                      Inventory Data File
                    </Button>
                  </Tooltip>

                  <Button variant="contained" component="label">
                    Upload File&nbsp;
                    <input
                      onChange={handleFileChange}
                      id="csvInput"
                      name="file"
                      type="File"
                    />
                  </Button>
                  <Button
                    variant="contained"
                    disabled={
                      productListUpdated.every((obj) =>
                        isEveryKeyEmptyExcluding(obj, ["id"])
                      )
                        ? true
                        : false
                    }
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </Dialog>
      </Portal>
    </Fragment>
  );
};

export default DefineReorderPointBulkUpload;
