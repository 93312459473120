import { useCallback, useEffect, useState, useContext } from "react";
import { Box, Button, Typography } from "@mui/material";
import unoLogo from "../../../assets/images/svg/unoLogo.svg";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import OTPLoginForm from "./otpLoginForm";
import UserNameLoginForm from "./userNameLoginForm";
import { Link, useNavigate } from "react-router-dom";

const LoginRightPane = ({ path, setSeverity, setMessage, setOpenNotice, handleCloseNotice }) => {
  const [showOTPForm, setShowOTPForm] = useState(false);

  const navigate = useNavigate();
  return (
    <Box>
      <Grid container>
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center">
          <Box
            onClick={() => navigate("/")}
            component="img"
            sx={{
              height: 200,
              width: "40%",
              maxHeight: "100%",
              objectFit: "contain",
              cursor: "pointer",
            }}
            alt="Logo"
            src={unoLogo}
          />
        </Grid>
        <Grid lg={12} md={12} sm={12} xs={12} display="flex" justifyContent="center">
          <Typography fontSize="2rem" fontWeight={600} color="#404040" sx={{ mt: 4, mb: 4 }}>
            Login
          </Typography>
        </Grid>
        <Grid lg={12} md={12} sm={12} xs={12}>
          {showOTPForm ? (
            <OTPLoginForm
              setShowOTPForm={setShowOTPForm}
              setSeverity={setSeverity}
              setMessage={setMessage}
              setOpenNotice={setOpenNotice}
            />
          ) : (
            <UserNameLoginForm
              path={path}
              setShowOTPForm={setShowOTPForm}
              setSeverity={setSeverity}
              setMessage={setMessage}
              setOpenNotice={setOpenNotice}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginRightPane;
