import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Tab } from "@mui/material";
import React, { Fragment, useState } from "react";
import RecordOpd from "./comps/recordOpd/recordOpd";
import RecordInjury from "./comps/recordInjury/recordInjury";
import CustomTitles from "../../../global/titles/customTitles";

const OhcMyOpdMain = () => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomTitles title="My OPD" />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box sx={{ width: "100%" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  variant="scrollable"
                  scrollButtons="auto"
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Record OPD" value="1" />
                  <Tab label="Record Injury" value="2" />
                  {/* <Tab label="Bulk Record" value="3" /> */}
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ p: 0 }}>
                <RecordOpd />
              </TabPanel>

              <TabPanel value="2" sx={{ p: 0 }}>
                <RecordInjury />
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default OhcMyOpdMain;
