import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Tab } from "@mui/material";
import { useState } from "react";
import TestWise from "../../../ahc/statusTracker/testWise";
import PeStatusTracker from "../../../preemployment/statusTracker/comps/peStatusTracker";
import StatusTracker from "../../../ohc/statusTracker/comps/statusTracker";

const AnalyticsComp = () => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Grid container>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {/* <Typography variant="h6">Analytics</Typography> */}
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ pt: 2 }}>
                <Grid container rowSpacing={2}>
                  <Grid item lg={6}>
                    <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
                      <TabList
                        centered
                        onChange={handleChange}
                        variant="fullWidth"
                        TabIndicatorProps={{
                          style: { backgroundColor: "white" },
                        }}
                      >
                        <Tab
                          label="AHC Analytics"
                          value="1"
                          sx={{ height: 20 }}
                        />
                        <Tab
                          label="Pre-Employment Analytics"
                          value="2"
                          sx={{ height: 20 }}
                        />
                        <Tab
                          label="OHC Analytics"
                          value="3"
                          sx={{ height: 20 }}
                        />
                      </TabList>
                    </Box>
                  </Grid>

                  <Grid item lg={12} sx={{ mb: 10 }}>
                    <TabPanel value="1" sx={{ p: 0 }}>
                      <TestWise />
                    </TabPanel>
                    <TabPanel value="2" sx={{ p: 0 }}>
                      <PeStatusTracker />
                    </TabPanel>
                    <TabPanel value="3" sx={{ p: 0 }}>
                      <StatusTracker />
                    </TabPanel>
                  </Grid>
                </Grid>
              </Box>
            </TabContext>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AnalyticsComp;
