import { createSvgIcon } from "@mui/material";
import * as React from "react";

export const HomeIcon = createSvgIcon(
  <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />,
  "Home"
);

export const XrayIcon1 = createSvgIcon(
  // credit: plus icon from https://heroicons.com/
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path d="M200-80q-50 0-85-35t-35-85q0-17 11.5-28.5T120-240q17 0 28.5 11.5T160-200t11.5 28.5Q183-160 200-160q15 0 33.5-9t38.5-26q20-17 39.5-38.5T350-280q38-51 64-105t26-95v-360q0-17 11.5-28.5T480-880q17 0 28.5 11.5T520-840v360q0 41 26 95t64 105q19 25 38.5 46.5T688-195q20 17 38.5 26t33.5 9q17 0 28.5-11.5T800-200t11.5-28.5Q823-240 840-240t28.5 11.5Q880-217 880-200q0 50-35 85t-85 35q-24 0-54.5-14T643-133q-32-25-63.5-58.5T520-264q-15-21-23.5-28.5T480-300q-8 0-16.5 7.5T440-264q-28 39-59.5 72.5T317-133q-32 25-62.5 39T200-80Zm20-129-90-73q-14-11-22-26.5t-8-33.5q0-18 7-34t21-27q10-8 22-7.5t20 10.5q8 10 7.5 22.5T167-357q-2 2-4.5 6t-2.5 9q0 4 1.5 7.5t5.5 6.5l101 80q-14 13-26 23t-22 16Zm106-102L190-420q-14-11-22-27t-8-34q0-18 7-34.5t21-27.5q10-8 22.5-7t20.5 11q8 10 7 22t-11 20q-3 2-5 7t-2 10q0 4 1.5 7.5t5.5 6.5l133 106q-8 13-16.5 25T326-311Zm69-129L250-557q-14-11-22-28t-8-35q0-19 8-35t22-28q9-8 21.5-7t20.5 11q8 10 7 22t-11 20q-2 2-5 7t-3 11q0 2 8 15l112 91v33q0 10-1.5 20t-3.5 20Zm5-186-92-77q-14-11-21-26.5t-7-32.5q0-32 23-55t55-23q16 0 29 5t13 25q0 20-13 25t-29 5q-8 0-13 6t-5 13q0 4 1.5 7t4.5 5l54 45v78Zm165 186q-2-10-3.5-20t-1.5-20v-33l112-91q4-3 8-15 0-6-3-11t-5-7q-10-8-11-20t7-22q8-10 20.5-11t22.5 7q14 12 21.5 28.5T740-620q0 18-8 34.5T710-558L565-440Zm-5-186v-78l54-45q3-2 4.5-5t1.5-7q0-8-5-13.5t-13-5.5q-16 0-29-5t-13-25q0-20 13-25t29-5q32 0 55 23t23 55q0 17-7 32.5T652-703l-92 77Zm74 315q-9-12-17.5-24.5T600-361l132-106q3-2 7-15 0-2-6-15-10-8-11-20.5t7-22.5q8-10 20-11t22 7q14 11 21.5 27.5T800-482q0 18-8 34.5T770-420L634-311Zm106 102q-10-6-22-16t-26-23l101-80q3-2 7-14 0-5-2-9t-4-6q-10-8-11-20.5t7-22.5q8-10 20-10.5t22 7.5q14 11 21 27t7 34q0 18-7.5 33.5T831-282l-91 73Z" />
  </svg>,
  "Plus"
);

export const XrayIcon = createSvgIcon(
  // credit: plus icon from https://heroicons.com/
  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
    <path d="M240 384c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16zm160 32c8.8 0 16-7.2 16-16s-7.2-16-16-16-16 7.2-16 16 7.2 16 16 16zM624 0H16C7.2 0 0 7.2 0 16v32c0 8.8 7.2 16 16 16h608c8.8 0 16-7.2 16-16V16c0-8.8-7.2-16-16-16zm0 448h-48V96H64v352H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h608c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM480 248c0 4.4-3.6 8-8 8H336v32h104c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H336v32h64c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48v-16h-64v16c0 26.5-21.5 48-48 48s-48-21.5-48-48 21.5-48 48-48h64v-32H200c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h104v-32H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h136v-32H200c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h104v-24c0-4.4 3.6-8 8-8h16c4.4 0 8 3.6 8 8v24h104c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H336v32h136c4.4 0 8 3.6 8 8v16z" />
  </svg>,
  "Plus"
);

export const PFTIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512">
    <path d="M636.11 390.15C614.44 308.85 580.07 231 534.1 159.13 511.98 124.56 498.03 96 454.05 96 415.36 96 384 125.42 384 161.71v60.11l-32.88-21.92a15.996 15.996 0 0 1-7.12-13.31V16c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v170.59c0 5.35-2.67 10.34-7.12 13.31L256 221.82v-60.11C256 125.42 224.64 96 185.95 96c-43.98 0-57.93 28.56-80.05 63.13C59.93 231 25.56 308.85 3.89 390.15 1.3 399.84 0 409.79 0 419.78c0 61.23 62.48 105.44 125.24 88.62l59.5-15.95c42.18-11.3 71.26-47.47 71.26-88.62v-87.49l-85.84 57.23a7.992 7.992 0 0 1-11.09-2.22l-8.88-13.31a7.992 7.992 0 0 1 2.22-11.09L320 235.23l167.59 111.72a7.994 7.994 0 0 1 2.22 11.09l-8.88 13.31a7.994 7.994 0 0 1-11.09 2.22L384 316.34v87.49c0 41.15 29.08 77.31 71.26 88.62l59.5 15.95C577.52 525.22 640 481.01 640 419.78c0-9.99-1.3-19.94-3.89-29.63z" />
  </svg>,
  "Plus"
);

export const ECGIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
  >
    <path d="M80-600v-120q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v120h-80v-120H160v120H80Zm80 440q-33 0-56.5-23.5T80-240v-120h80v120h640v-120h80v120q0 33-23.5 56.5T800-160H160Zm240-120q11 0 21-5.5t15-16.5l124-248 44 88q5 11 15 16.5t21 5.5h240v-80H665l-69-138q-5-11-15-15.5t-21-4.5q-11 0-21 4.5T524-658L400-410l-44-88q-5-11-15-16.5t-21-5.5H80v80h215l69 138q5 11 15 16.5t21 5.5Zm80-200Z" />
  </svg>,
  "Plus"
);

export const PendingIcon = createSvgIcon(
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="42.000000pt"
    height="42.000000pt"
    viewBox="0 0 42.000000 42.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,42.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M138 329 c-39 -39 -48 -55 -48 -84 0 -19 -9 -51 -20 -72 -26 -49 -13
-73 39 -73 30 0 40 -4 44 -20 12 -45 102 -45 114 0 4 16 14 20 44 20 49 0 64
22 41 65 -8 16 -19 46 -23 65 l-7 35 -1 -40 c0 -22 6 -49 14 -59 32 -43 23
-46 -125 -46 -148 0 -157 3 -125 46 8 10 15 35 15 54 0 41 31 96 58 104 16 5
38 45 29 52 -1 1 -23 -20 -49 -47z m116 -244 c-12 -30 -76 -30 -88 0 -4 12 5
15 44 15 39 0 48 -3 44 -15z"
      />
      <path
        d="M235 356 c-16 -16 -23 -31 -19 -43 8 -22 33 -43 51 -43 18 0 53 37
53 55 0 17 -35 55 -50 55 -6 0 -22 -11 -35 -24z m53 -5 c8 -4 12 -19 10 -32
-4 -33 -53 -40 -63 -9 -11 36 22 61 53 41z"
      />
    </g>
  </svg>,
  "Plus"
);

export const DateIcon = createSvgIcon(
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3_14980)">
      <path
        d="M3.71094 2.75V2M8.71094 2.75V2"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.71094 8L5.46094 7.25V9.25"
        stroke="black"
        strokeWidth="0.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9609 5.25H5.58594M1.21094 5.25H3.14844M6.71094 8.75V7.75C6.71094 7.61739 6.76362 7.49021 6.85738 7.39645C6.95115 7.30268 7.07833 7.25 7.21094 7.25C7.34355 7.25 7.47072 7.30268 7.56449 7.39645C7.65826 7.49021 7.71094 7.61739 7.71094 7.75V8.75C7.71094 8.88261 7.65826 9.00979 7.56449 9.10355C7.47072 9.19732 7.34355 9.25 7.21094 9.25C7.07833 9.25 6.95115 9.19732 6.85738 9.10355C6.76362 9.00979 6.71094 8.88261 6.71094 8.75Z"
        stroke="black"
        strokeWidth="0.6"
        strokeLinecap="round"
      />
      <path
        d="M7.21094 11.75H5.21094C3.32544 11.75 2.38244 11.75 1.79694 11.164C1.21094 10.5785 1.21094 9.6355 1.21094 7.75V6.75C1.21094 4.8645 1.21094 3.9215 1.79694 3.336C2.38244 2.75 3.32544 2.75 5.21094 2.75H7.21094C9.09644 2.75 10.0394 2.75 10.6249 3.336C11.2109 3.9215 11.2109 4.8645 11.2109 6.75V7.75C11.2109 9.6355 11.2109 10.5785 10.6249 11.164C10.2984 11.491 9.86094 11.6355 9.21094 11.699"
        stroke="black"
        strokeWidth="0.6"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3_14980">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0.210938 0.75)"
        />
      </clipPath>
    </defs>
  </svg>,
  "Plus"
);

export const TypeIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className="size-6"
  >
    <path
      fillRule="evenodd"
      d="M5.25 2.25a3 3 0 0 0-3 3v4.318a3 3 0 0 0 .879 2.121l9.58 9.581c.92.92 2.39 1.186 3.548.428a18.849 18.849 0 0 0 5.441-5.44c.758-1.16.492-2.629-.428-3.548l-9.58-9.581a3 3 0 0 0-2.122-.879H5.25ZM6.375 7.5a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"
      clipRule="evenodd"
    />
  </svg>,
  "Plus"
);

export const NumberIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="size-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V13.5Zm0 2.25h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V18Zm2.498-6.75h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V13.5Zm0 2.25h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V18Zm2.504-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5Zm0 2.25h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V18Zm2.498-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5ZM8.25 6h7.5v2.25h-7.5V6ZM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0 0 12 2.25Z"
    />
  </svg>,
  "Plus"
);

export const EmployeeIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className="size-6"
  >
    <path
      fillRule="evenodd"
      d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z"
      clipRule="evenodd"
    />
  </svg>,
  "Plus"
);

export const CompletedIcon = createSvgIcon(
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="42.000000pt"
    height="42.000000pt"
    viewBox="0 0 42.000000 42.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,42.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M175 381 c-44 -11 -70 -27 -99 -61 -82 -98 -32 -246 94 -280 72 -19
160 21 195 91 17 32 20 69 5 69 -5 0 -10 -8 -10 -17 0 -10 -9 -33 -20 -51 -80
-130 -280 -74 -280 79 0 93 70 153 173 146 51 -3 71 4 50 16 -18 10 -80 14
-108 8z"
      />
      <path
        d="M291 249 l-74 -80 -32 31 c-18 18 -35 28 -38 22 -4 -5 12 -26 33 -47
l39 -38 80 84 c72 75 95 110 74 108 -5 0 -41 -36 -82 -80z"
      />
    </g>
  </svg>,
  "Plus"
);

export const CompanyNameIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className="size-6"
  >
    <path
      fillRule="evenodd"
      d="M4.125 3C3.089 3 2.25 3.84 2.25 4.875V18a3 3 0 0 0 3 3h15a3 3 0 0 1-3-3V4.875C17.25 3.839 16.41 3 15.375 3H4.125ZM12 9.75a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5H12Zm-.75-2.25a.75.75 0 0 1 .75-.75h1.5a.75.75 0 0 1 0 1.5H12a.75.75 0 0 1-.75-.75ZM6 12.75a.75.75 0 0 0 0 1.5h7.5a.75.75 0 0 0 0-1.5H6Zm-.75 3.75a.75.75 0 0 1 .75-.75h7.5a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75ZM6 6.75a.75.75 0 0 0-.75.75v3c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75v-3A.75.75 0 0 0 9 6.75H6Z"
      clipRule="evenodd"
    />
    <path d="M18.75 6.75h1.875c.621 0 1.125.504 1.125 1.125V18a1.5 1.5 0 0 1-3 0V6.75Z" />
  </svg>,
  "Plus"
);

export const DateIcon1 = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className="size-6"
  >
    <path d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" />
    <path
      fillRule="evenodd"
      d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z"
      clipRule="evenodd"
    />
  </svg>,
  "Plus"
);

export const DateChangeIcon = createSvgIcon(
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="42.000000pt"
    height="42.000000pt"
    viewBox="0 0 42.000000 42.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,42.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M140 340 c0 -22 5 -40 10 -40 6 0 10 18 10 40 0 22 -4 40 -10 40 -5
0 -10 -18 -10 -40z"
      />
      <path
        d="M260 340 c0 -22 5 -40 10 -40 6 0 10 18 10 40 0 22 -4 40 -10 40 -5
0 -10 -18 -10 -40z"
      />
      <path
        d="M74 325 c-17 -17 -24 -34 -22 -53 l3 -27 155 0 155 0 3 27 c2 19 -5
36 -22 53 -30 30 -46 32 -46 6 0 -33 -11 -51 -31 -51 -15 0 -19 7 -19 35 0 34
-1 35 -40 35 -38 0 -40 -2 -40 -30 0 -19 -6 -33 -16 -37 -19 -7 -34 13 -34 45
0 29 -15 28 -46 -3z"
      />
      <path
        d="M54 216 c-3 -8 -2 -39 3 -68 11 -72 28 -79 165 -76 130 4 138 9 141
94 l2 59 -153 3 c-127 2 -153 0 -158 -12z"
      />
    </g>
  </svg>,
  "Plus"
);
