import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { Fragment } from "react";
import logo from "../../design3/assets/logo.png";
import instagramIcon from "../assets/instagramIcon.png";
import facebookIcon from "../assets/facebookIcon.png";
import linkedInIcon from "../assets/linkedInIcon.png";
import twitterIcon from "../assets/twitterIcon.png";
import { CallOutlined, LocationOn, Mail } from "@mui/icons-material";
import { useNavigate } from "react-router";

const Footer = ({ scrollToSection }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const pages = [
    { title: "Clients", onClick: () => scrollToSection("clients") },
    //{ title: "About us", onClick: () => scrollToSection("aboutUs") },
    // { title: "Terms & Conditions", onClick: () => navigate("/terms") },
    { title: "Services", onClick: () => scrollToSection("whatWeOffer") },

    // {
    //   title: "Prefer us",
    //   onClick: () => scrollToSection("specialization"),
    // },

    // { title: "Best for you", onClick: () => scrollToSection("whatWeDo") },

    { title: "Testimonials", onClick: () => scrollToSection("testimonials") },

    // { title: "Contact Us", onClick: () => scrollToSection("contactUs") },
  ];

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          backgroundSize: "cover",
          width: "100%",
          alignContent: "center",
          paddingTop: isSmallScreen ? "20px" : "40px",
          paddingBottom: isSmallScreen ? "20px" : "20px",
        }}>
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
            sx={{
              paddingInline: isSmallScreen || isExtraSmallScreen ? 0 : "50px",
            }}>
            <Grid item lg={12}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              sx={{
                alignItems: "center",
                marginBlock: isSmallScreen || isExtraSmallScreen ? 2 : 0,
              }}>
              <Box>
                <img src={logo} style={{ height: 48 }} />
              </Box>

              <Box
                sx={{
                  borderRadius: "12px",
                }}>
                <Box sx={{ display: "flex", gap: 2, marginBlock: 1.5 }}>
                  <LocationOn sx={{ color: "#000" }} />
                  <Typography
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "150%",
                      letterSpacing: "-0.44px",
                    }}>
                    Uno.care, Indore, Madhya Pradesh
                  </Typography>
                </Box>

                <Link
                  onClick={() => (window.location = "mailto:support@uno.care")}
                  sx={{
                    textDecorationLine: "none",
                  }}>
                  <Box sx={{ display: "flex", gap: 2, marginBlock: 1.5 }}>
                    <Mail sx={{ color: "#000" }} />
                    <Typography
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "150%",
                        letterSpacing: "-0.44px",
                      }}>
                      support@unocare.care
                    </Typography>
                  </Box>
                </Link>
                {/* <Link
                  onClick={() => {
                    window.location.href = "tel:18008890189";
                  }}
                  sx={{
                    textDecorationLine: "none",
                  }}
                >
                  <Box sx={{ display: "flex", gap: 2, marginBlock: 1.5 }}>
                    <CallOutlined sx={{ color: "#000" }} />
                    <Typography
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "150%",
                        letterSpacing: "-0.44px",
                      }}
                    >
                      1800 8890 189
                    </Typography>
                  </Box>
                </Link> */}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              sx={{
                paddingRight: isExtraSmallScreen || isSmallScreen ? null : "50px",
              }}>
              <Typography
                sx={{
                  color: "#666666",
                  fontFamily: "Poppins",
                  fontSize: "26px",
                  fontWeight: "500",
                  lineHeight: "150%",
                  letterSpacing: "-0.44px",
                }}>
                Quick Links
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}>
                <Box
                  sx={
                    {
                      // display: "flex",
                      // justifyContent: "space-between",
                      // flexWrap: "wrap",
                    }
                  }>
                  {pages.map((page) => (
                    <Typography
                      key={page.title}
                      onClick={page.onClick}
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontWeight: "500",
                        lineHeight: "150%",
                        letterSpacing: "-0.44px",
                        marginBlock: 1,
                        width:
                          isSmallScreen || isExtraSmallScreen
                            ? page.title === "Terms & Conditions" || page.title === "Contact Us"
                              ? "200px"
                              : "150px"
                            : page.title === "Terms & Conditions" ||
                              page.title === "Contact Us" ||
                              page.title === "Privacy Policy"
                            ? "200px"
                            : "150px",
                        ":hover": {
                          cursor: "pointer",
                        },
                      }}>
                      {page.title}
                    </Typography>
                  ))}
                </Box>
                <Box
                  sx={
                    {
                      // display: "flex",
                      // justifyContent: "space-between",
                      // flexWrap: "wrap",
                    }
                  }>
                  <Typography
                    onClick={() => {
                      navigate("/terms");
                    }}
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontWeight: "500",
                      lineHeight: "150%",
                      letterSpacing: "-0.44px",
                      marginBlock: 1,
                      ":hover": {
                        cursor: "pointer",
                      },
                    }}>
                    Terms & Conditions
                  </Typography>
                  <Typography
                    onClick={() => {
                      navigate("/privacy");
                    }}
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontWeight: "500",
                      lineHeight: "150%",
                      letterSpacing: "-0.44px",
                      marginBlock: 1,
                      ":hover": {
                        cursor: "pointer",
                      },
                    }}>
                    Privacy Policy
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} sx={{}}>
              <Typography
                sx={{
                  color: "#666666",
                  fontFamily: "Poppins",
                  fontSize: "26px",
                  fontWeight: "500",
                  lineHeight: "150%",
                  letterSpacing: "-0.44px",
                }}>
                Talk To Us!
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  marginBlock: isSmallScreen || isExtraSmallScreen ? 2 : 3,
                }}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="Yourmail@gmail.com"
                  sx={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    backgroundColor: "#FFF",
                    "& .MuiOutlinedInput-root": {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      borderTopLeftRadius: "5px",
                      borderBottomLeftRadius: "5px",
                      backgroundColor: "#FFF",
                      paddingRight: 0,
                    },
                    "& .MuiOutlinedInput-input": {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      borderTopLeftRadius: "5px",
                      borderBottomLeftRadius: "5px",
                      backgroundColor: "#FFF",
                    },
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    height: "100%",
                    backgroundColor: "#0463FA",
                  }}>
                  Send
                </Button>
              </Box>

              <Link
                underline="none"
                href="https://www.facebook.com/profile.php?id=100088725383722&mibextid=LQQJ4d"
                target={"_blank"}>
                <Box
                  component={"img"}
                  src={facebookIcon}
                  sx={{
                    height: 30,
                    width: 30,
                    cursor: "pointer",
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": {
                      transform: "translateY(-10px)",
                    },
                  }}
                />
              </Link>
              <Link
                underline="none"
                href="https://instagram.com/uno.care?igshid=YmMyMTA2M2Y="
                target={"_blank"}>
                <Box
                  component={"img"}
                  src={instagramIcon}
                  sx={{
                    height: 30,
                    width: 30,
                    cursor: "pointer",
                    marginInline: "10px",
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": {
                      transform: "translateY(-10px)",
                    },
                  }}
                />
              </Link>
              <Link underline="none" target={"_blank"}>
                <Box
                  component={"img"}
                  src={linkedInIcon}
                  sx={{
                    height: 30,
                    width: 30,
                    cursor: "pointer",
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": {
                      transform: "translateY(-10px)",
                    },
                  }}
                />
              </Link>
              <Link underline="none" target={"_blank"}>
                <Box
                  component={"img"}
                  src={twitterIcon}
                  sx={{
                    height: 30,
                    width: 30,
                    cursor: "pointer",
                    marginInline: "10px",
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": {
                      transform: "translateY(-10px)",
                    },
                  }}
                />
              </Link>
            </Grid>
          </Grid>
        </Container>
        <Divider sx={{ width: "100%", color: "#FFF", marginBlock: 3 }} />
        <Box
          sx={{
            textAlign: { xs: "center", sm: "center", md: "left", lg: "left" },
            paddingInline: isSmallScreen || isExtraSmallScreen ? "20px" : "75px",
          }}>
          <Typography
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "150%",
              letterSpacing: "-0.44px",
            }}>
            © Uno.care, All Rights Reserved
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
};

export default Footer;
