import { Box, Container, IconButton, Typography } from "@mui/material";
import React from "react";
import { Fragment } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import CallIcon from "@mui/icons-material/Call";

const TopBar = () => {
  return (
    <Fragment>
      <Container maxWidth={false} sx={{ backgroundColor: "#EFF5FF" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingBlock: "10px",
            backgroundColor: "#EFF5FF",
            paddingInline: "20px",
          }}>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ display: "flex", marginRight: "5px", marginBlock: "5px" }}>
              {/* <LocationOnIcon sx={{ color: "#0463FA", height: "20px", marginRight: "5px" }} />
              <Typography variant="body2" gutterBottom>
                Indore, Madhya Pradesh
              </Typography> */}
            </Box>

            <Box sx={{ display: "flex", marginRight: "5px", marginBlock: "5px" }}>
              {/* <AccessTimeIcon sx={{ color: "#0463FA", height: "20px", marginRight: "5px" }} /> */}
              {/* <Typography variant="body2" gutterBottom>
                Mon - Sun : 09.00 AM - 09.00 PM
              </Typography> */}
            </Box>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box sx={{ display: "flex", marginRight: "5px", marginBlock: "5px" }}>
              <CallIcon sx={{ color: "#0463FA", height: "20px", marginRight: "5px" }} />
              <Typography variant="body2" gutterBottom>
                1800 8890 189
              </Typography>
            </Box>
            <Box sx={{ display: "flex", marginRight: "5px", marginTop: "-2px" }}>
              <IconButton
                sx={{ cursor: "pointer", display: "flex" }}
                underline="none"
                href="https://www.facebook.com/profile.php?id=100088725383722&mibextid=LQQJ4d"
                target={"_blank"}>
                <FacebookIcon
                  sx={{
                    color: "#0463FA",
                    height: "20px",
                    marginRight: "5px",
                  }}
                />
              </IconButton>
              <IconButton href="">
                <TwitterIcon sx={{ color: "#0463FA", height: "20px", marginRight: "5px" }} />
              </IconButton>
              <IconButton href="">
                <LinkedInIcon sx={{ color: "#0463FA", height: "20px", marginRight: "5px" }} />
              </IconButton>
              <IconButton
                sx={{ cursor: "pointer", display: "flex" }}
                underline="none"
                href="https://instagram.com/uno.care?igshid=YmMyMTA2M2Y="
                target={"_blank"}>
                <InstagramIcon sx={{ color: "#0463FA", height: "20px", marginRight: "5px" }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Container>
    </Fragment>
  );
};

export default TopBar;
