import { Box, Button, Drawer, Portal } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { BASE_URL } from "../../assets/constantsFile";
import { getData } from "../../services/api/getApi";

const StethoStream = ({ caseId, appointmentId, patientId }) => {
  const [open, setOpen] = useState(false);
  const [stethoURL, setStethoURL] = useState(
    "https://stream.ayudevicestech.com/listen/unocareiframerefresh"
  );

  const handleDrawerOpen = () => {
    setOpen(true);
    getPDetails();
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setStethoURL(
      "https://stream.ayudevicestech.com/listen/unocareiframerefresh"
    );
  };

  const [pDetails, setPdetails] = useState();

  const getPDetails = async () => {
    const url =
      BASE_URL +
      "patient/detailed/" +
      patientId +
      "?appointmentId=" +
      appointmentId +
      "&caseId=" +
      caseId;

    const user = await getData(url, "");

    if (user.error) {
    } else {
      const data = user.data;
      setPdetails(data);
      console.log({ setPdetails: data });
      setStethoURL(data?.appointments[0]?.stethoURL);
    }
  };

  useEffect(() => {
    getPDetails();
  }, []);
  return (
    <Fragment>
      <Box>
        <Button
          disabled={!stethoURL}
          size="small"
          variant="contained"
          sx={{ fontSize: 12 }}
          onClick={handleDrawerOpen}
        >
          Stetho Live
        </Button>
      </Box>

      <Portal>
        <Drawer
          sx={{
            width: 600,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 600,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <iframe
            id="LiveStreaming"
            title="Sample"
            height="100%"
            width="100%"
            allow="camera; microphone; clipboard-read; clipboard-write"
            src={stethoURL}
            samesite="none"
          />
          <Box
            style={{
              position: "absolute",
              bottom: 0,
              height: "6vh",
              width: "100%",
              backgroundColor: "#127DDD",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              fullWidth
              style={{ color: "#fff" }}
              onClick={handleDrawerClose}
            >
              close
            </Button>
          </Box>
        </Drawer>
      </Portal>
    </Fragment>
  );
};

export default StethoStream;
