import { Box, Grid, Typography } from "@mui/material";

const TableHeaderView = (props) => {
        return (

          <Box sx={{ background:"#D9D9D9", p:1 }} >

            <Grid container align="center">
              <Grid item lg={4} md={12} sm={12} xs={12} >
                <Typography variant="body2" sx={{ fontWeight:600 }}>Medicine</Typography>
              </Grid>
              <Grid item lg={2} md={12} sm={12} xs={12} >
              <Typography variant="body2" sx={{ fontWeight:600 }}>Dosage</Typography>
              </Grid>
              <Grid item lg={1} md={12} sm={12} xs={12} >
              <Typography variant="body2" sx={{ fontWeight:600 }}>Days</Typography>
              </Grid>
              <Grid item lg={5} md={12} sm={12} xs={12} > 
              <Typography variant="body2" sx={{ fontWeight:600 }}>Instruction</Typography>
              </Grid>
            </Grid>
            </Box>
        );
}
 
export default TableHeaderView;