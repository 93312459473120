import React from "react";
import { Fragment } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Divider, Grid, IconButton, Modal, Portal, Typography } from "@mui/material";
import unologo from "../../../../../assets/images/unologo.png";
import bigGreenTick from "../../../../../assets/images/bigGreenTick.png";

const PopUpMobile = ({ handleClose, open }) => {
  return (
    <Fragment>
      <Portal>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={open}
          onClose={handleClose}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(187, 187, 187, 0.1)",
            },
          }}>
          <Box
            sx={{
              backgroundColor: "#fff",
              boxShadow: "0px 1px 4px 1px rgba(0, 0, 0, 0.1)",
              borderRadius: "5px",
              padding: "15px",
              width: "350px",
              padding: "20px",
            }}>
            {/* <Box sx={{ minHeight: "130px" }}> */}
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Grid container>
              <Grid
                item
                xs={12}
                lg={12}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Box src={unologo} component="img" style={{ height: "60px" }} />
              </Grid>

              <Grid
                item
                xs={12}
                lg={12}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography
                  sx={{
                    color: "#404040",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "normal",
                    marginBlock: "5px",
                  }}>
                  Sab Theek Hojayega!
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={12}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography
                  sx={{
                    color: "#404040",
                    fontSize: "20px",
                    fontWeight: "600",
                    lineHeight: "normal",
                    marginBlock: "10px",
                  }}>
                  Thanks, You’re All Set!
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={12}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography
                  sx={{
                    color: "#8A8A8A",
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "normal",
                    marginBlock: "10px",
                  }}>
                  You Have Successfully Submitted Your Responses And Your Appointment Has Been
                  Booked Successfully .
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={12}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Box
                  src={bigGreenTick}
                  component="img"
                  style={{ height: "40px", marginBlock: "10px" }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={12}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Divider sx={{ color: "8A8A8A", width: "100%", marginBottom: "10px" }} />
              </Grid>
              <Grid
                item
                xs={12}
                lg={12}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography
                  sx={{
                    color: "#8A8A8A",
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "normal",
                    marginBlock: "10px",
                  }}>
                  You Can Visit Our Website Or Exit The Form
                </Typography>
              </Grid>
              <Grid item xs={6} lg={6} sx={{ textAlign: "center", marginBlock: "10px" }}>
                <Button
                  variant="contained"
                  sx={{ textTransform: "capitalize", borderRadius: "10px" }}
                  onClick={handleClose}>
                  <Typography
                    sx={{
                      color: "#FFF",
                      paddingInline: "10px",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }}>
                    Visit WebSite
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={6} lg={6} sx={{ textAlign: "center", marginBlock: "10px" }}>
                <Button
                  variant="outlined"
                  sx={{ textTransform: "capitalize", borderRadius: "10px" }}
                  onClick={handleClose}>
                  <Typography
                    sx={{
                      color: "#127DDD",
                      paddingInline: "10px",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "normal",
                    }}>
                    Exit Form
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Portal>
    </Fragment>
  );
};

export default PopUpMobile;
