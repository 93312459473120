import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Fragment } from "react";
import sideElement from "../../../../assets/images/sideElement.png";

const TitleComp = ({ title, alignRight, alignLeft, buttomTitle }) => {
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={1} lg={1}>
          {alignLeft === true ? (
            <Box component="img" src={sideElement} style={{ height: "70px", width: "50px" }} />
          ) : null}
        </Grid>
        <Grid item xs={10} lg={10}>
          <Typography
            sx={{
              color: "#127DDD",
              textAlign: "center",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              textTransform: "uppercase",
            }}>
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          lg={1}
          sx={{
            textAlign: "right",
          }}>
          {alignRight === true ? (
            <Box component="img" src={sideElement} style={{ height: "70px", width: "50px" }} />
          ) : null}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default TitleComp;
