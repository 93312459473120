import React, { useState, useEffect } from "react";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const UpArrowButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Hide the button when the scrollbar is at the top (scrollY is 0)
      if (scrollY > 0) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Scroll back to the top when the button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Add smooth scrolling effect
    });
  };

  return (
    <Fab
      className={`up-arrow-button ${isVisible ? "visible" : ""}`}
      onClick={scrollToTop}
      color="primary" // Customize the color as needed
      aria-label="scroll-to-top"
      sx={{ position: "fixed", bottom: "30px", right: "20px" }} // Adjust position as needed
    >
      <KeyboardArrowUpIcon />
    </Fab>
  );
};

export default UpArrowButton;
