import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Modal,
  Paper,
  Portal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { BASE_URL } from "../../../../assets/constantsFile";
import { getData } from "../../../../services/api/getApi";
import CallIcon from "@mui/icons-material/Call";
import { isBrowser, isMobile } from "react-device-detect";
const KamDetailModal = ({
  open,
  onClose,
  corpId = localStorage.getItem("CORPID"),
}) => {
  const [kamData, setKamData] = useState("");

  const fetchKamData = async () => {
    const url = BASE_URL + `org/kam?corpId=${corpId}`;
    const result = await getData(url);
    if (result.data) {
      setKamData(result.data);
    } else {
      setKamData(result.error);
    }
  };

  useEffect(() => {
    fetchKamData();
  }, []);

  return (
    <Portal>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backdropFilter: "blur(5px)",
        }}
        open={open}
        onClose={onClose}
      >
        <Box
          sx={{
            width: isBrowser ? "420px" : "385px",
            // height: isMobile ? "50%" : "35%",
            bgcolor: "background.paper",
            borderRadius: "15px",
            boxShadow: 24,
            p: 2.5,
          }}
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Typography
            gutterBottom
            variant="h5"
            sx={{
              //   textAlign: "center",
              fontFamily: `${("Noto Sans", "sans-serif")}`,
              fontWeight: "400",
              lineHeight: "17px",
              color: "000",
              marginTop: "-25px",
              marginBottom: "10px",
            }}
          >
            Key Account Manager
          </Typography>
          <Box
            sx={{ display: "flex", alignItems: "center", marginTop: "30px" }}
          >
            <Paper
              sx={{
                // width: 180,
                // height: 150,
                // backgroundColor: "primary.light",
                // color: "text.primary",
                // p: 3,
                // boxShadow: 1,

                mr: "20px",
              }}
              elevation={3}
            >
              <Box
                component={"img"}
                src={kamData.photoUrl}
                style={{
                  width: 160,
                  height: 160,
                  borderRadius: 5,
                  // objectFit: "contain",
                }}
              />
            </Paper>
            <Box>
              <Typography variant="h5">{kamData.name}</Typography>
              <Typography variant="h6">{kamData.email}</Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6">{kamData.mobile}</Typography>
                <IconButton
                  sx={{ marginLeft: "10px" }}
                  onClick={() => window.open(`tel:${kamData?.mobile}`)}
                >
                  <CallIcon sx={{ color: "#127DDD" }} />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Portal>
  );
};

export default KamDetailModal;
