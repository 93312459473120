import { Box, Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import stethoscope17 from "../../../assets/images/stethoscope17.png";
import morehorizontal from "../../../assets/images/morehorizontal.png";
import calendar11 from "../../../assets/images/calendar11.png";
import Line57 from "../../../assets/images/Line57.png";

const OldAilmentComponent = ({
  diseaseName = "N/A",
  docName = "N/A",
  date = "N/A",
  onClick,
  visible,
}) => {
  return (
    <Fragment>
      <Box sx={{ marginBottom: 0 }} onClick={onClick}>
        <Box
          sx={{
            border: "1px solid #000000",
            height: "100px",
            width: "270px",
            borderRadius: "18px",
            marginX: "auto",
          }}
        >
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography
                  sx={{
                    p: 1,
                    px: 1,
                    fontWeight: 600,
                    fontSize: "15px",
                    lineHeight: "27px",
                    color: "#000000",
                  }}
                >
                  {diseaseName}
                </Typography>
              </Grid>
              <Grid item sx={{ display: "flex" }}>
                <Grid item lg={1} md={1} sm={1} xs={1}>
                  <img
                    src={stethoscope17}
                    style={{ height: "18px", width: "18px", marginLeft: 5 }}
                  />
                </Grid>
                <Grid item lg={11} md={11} sm={11} xs={11}>
                  <Typography
                    sx={{
                      px: 1,
                      fontWeight: 300,

                      fontSize: "13px",
                      lineHeight: "19px",

                      color: "#000000",
                    }}
                  >
                    {docName}
                  </Typography>
                </Grid>
                <Grid item lg={1} md={1} sm={1} xs={1}>
                  <img
                    src={morehorizontal}
                    style={{ height: "18px", width: "18px" }}
                  />
                </Grid>
              </Grid>
              <Grid item sx={{ display: "flex" }}>
                <Grid item lg={1}>
                  <img
                    src={calendar11}
                    style={{ height: "18px", width: "18px", marginLeft: 5 }}
                  />
                </Grid>
                <Grid item lg={12}>
                  <Typography
                    sx={{
                      px: 1,
                      fontWeight: 300,

                      fontSize: "13px",
                      lineHeight: "19px",

                      color: "#000000",
                    }}
                  >
                    {date}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {visible ? (
          ""
        ) : (
          <Fragment>
            <Box
              sx={{
                width: "24px",
                height: "24px",
                backgroundColor: "#127DDD",
                marginX: "auto",
                borderRadius: "50%",
                marginTop: -1.5,
              }}
            ></Box>

            <Box
              sx={{
                width: "14px",
                height: "14px",
                backgroundColor: "#C9E5FF",
                marginX: "auto",
                borderRadius: "50%",
                marginTop: -2.4,
              }}
            ></Box>
            <Box
              sx={{
                marginX: "auto",
                width: "17px",
                height: "17px",
                marginTop: 1,
                marginBottom: 0.5,
                // marginTop: 5,
              }}
            >
              <img src={Line57} style={{ height: "22px", marginLeft: "5px" }} />
            </Box>
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};

export default OldAilmentComponent;
