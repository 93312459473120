import { useCallback, useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Link,
  Stack,
  Typography,
} from "@mui/material";

import { getUserToken } from "../../services/api/apiCalls";
import jwt_decode from "jwt-decode";
import { userContext } from "../../common/context/usercontext";
import { useNavigate } from "react-router";
import selectArrow from "../../assets/images/svg/selectArrow.svg";
import translate from "../../assets/images/svg/translate.svg";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import UserNameComp from "./components/userNameComp";
import PasswordComp from "./components/passwordComp";
import { BASE_URL_AUTH } from "../../assets/constantsFile";

const UserNameLoginForm = ({ setSeverity, setMessage, setOpenNotice, setShowOTPForm }) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isRememberMe, setIsRememberMe] = useState(
    localStorage.getItem("SAVEDTOKEN") ? true : false
  );
  const contextData = useContext(userContext);
  let navigate = useNavigate();

  const handleSubmit = (event, name) => {
    event.preventDefault();
    const url = BASE_URL_AUTH + "authenticate";

    const dataObj = {
      username: userName,
      password: password,
    };

    fetchDoctorDataHandler(url, dataObj, isRememberMe, name);
  };

  const fetchDoctorDataHandler = useCallback(async (url, data, rememberMe, name) => {
    const user = await getUserToken(url, data);
    if (user.error) {
      console.log({ "Error while authenticating user": user.error });
    } else {
      const data = user.data;

      const token = await data.token;
      contextData.authHeader = token;
      const dData = await jwt_decode(token);
      contextData.val = dData;
      localStorage.setItem("authHeader_local", token);
      localStorage.setItem("DOCID", dData.userID);
      console.log({ "Success while authenticating user": dData });

      const role = await dData.role;
      const sub = await dData.sub;
      console.log({ role });
      console.log({ sub });

      if (dData && role === "DOCTOR" && sub === name) {
        setSeverity("success");
        setMessage("Login successfull");
        setOpenNotice(true);
        navigate("/patients", { replace: true });
      } else {
        setSeverity("error");
        setMessage("Login failed! Enter Correct Credentials!");
        setOpenNotice(true);
      }
      if (rememberMe) {
        localStorage.setItem("SAVEDTOKEN", token);
      } else {
        localStorage.removeItem("SAVEDTOKEN");
      }
    }
  }, []);

  return (
    <Box component="form" onSubmit={(e) => handleSubmit(e, userName)}>
      <Container maxWidth={"xs"}>
        <Grid container rowSpacing={3}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <UserNameComp userName={userName} setUserName={setUserName} />
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <PasswordComp password={password} setPassword={setPassword} />
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
            <Stack
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    value={isRememberMe}
                    color="primary"
                    onChange={(e) => setIsRememberMe(e.target.checked)}
                  />
                }
                label="Remember me"
                sx={{ ".MuiFormControlLabel-label": { fontSize: 14 } }}
              />

              <Link href="#" underline="none" sx={{ fontSize: 14 }}>
                Forgot password?
              </Link>
            </Stack>
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12} display="flex" justifyContent="center">
            <Button
              size="large"
              sx={{ borderRadius: 3 }}
              fullWidth
              variant="contained"
              type="submit">
              Login
            </Button>
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12} display="flex" justifyContent="center">
            <Typography>Or</Typography>
          </Grid>

          <Grid
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center">
            <Typography>Login Using &nbsp;</Typography>
            <Button
              disableElevation
              sx={{
                color: "#127DDD",
                fontWeight: 600,
                textTransform: "capitalize",
              }}
              onClick={() => setShowOTPForm(true)}>
              OTP
            </Button>
          </Grid>

          <Grid xl={12} lg={12} md={12} sm={12} xs={12} display="flex" justifyContent="center">
            <Button
              size="large"
              variant="outlined"
              fullWidth
              sx={{ borderRadius: 3, color: "#127DDD" }}
              startIcon={<img style={{ marginRight: 80 }} src={translate} />}
              endIcon={<img style={{ marginLeft: 80 }} src={selectArrow} />}>
              Select Language
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default UserNameLoginForm;
