import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, Stack, Typography } from "@mui/material";
import {
  BASE_URL,
  complaintOptions,
} from "../../../../../../assets/constantsFile";
import { getData } from "../../../../../../services/api/getApi";
import { useEffect } from "react";

const CustomAutocompleteComplaint = ({
  formValues,
  setFormValues,
  property,
}) => {
  useEffect(() => {
    if (formValues[property] === null) {
      setFormValues({ ...formValues, [property]: null });
    }
  }, [formValues[property]]);

  return (
    <Box>
      <Stack>
        <Typography
          sx={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: 13,
            lineHeightt: 17,
            color: "#000",
            textTransform: "capitalize",
          }}
        >
          {"Complaint"}
        </Typography>

        <Autocomplete
          size="small"
          fullWidth
          value={formValues[property] || ""}
          onChange={(event, newValue, reason) => {
            setFormValues({ ...formValues, [property]: newValue });
          }}
          onInputChange={(event, newInputValue, reason) => {
            setFormValues({ ...formValues, [property]: newInputValue });
          }}
          options={complaintOptions}
          freeSolo
          getOptionLabel={(option) => `${option}`}
          //   renderOption={(props, option) => (
          //     <li {...props}>
          //       <Box>
          //         <Typography sx={{ textTransform: "capitalize" }}>
          //           {option?.toUpperCase()}
          //         </Typography>
          //       </Box>
          //     </li>
          //   )}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              sx={{
                "& .MuiInputLabel-root": { color: "#D4D4D4 ! important" },
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: "#D4D4D4 ! important" },
                },
                color: "#777777",
                fontSize: 12,
                backgroundColor: "#fff",
                "& input::placeholder": {
                  fontSize: 12,
                },
              }}
              label={""}
              placeholder="Enter Complaint"
              InputLabelProps={{
                style: {
                  fontSize: 10,
                },
              }}
            />
          )}
        />
      </Stack>
    </Box>
  );
};
export default CustomAutocompleteComplaint;
