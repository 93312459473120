import { Box, Grid } from "@mui/material";
import IssueReportedElementComp from "./detail/issueReportedElementComp";
import Detail from "./detail";
import { Fragment } from "react";
import ReferredToComp from "../referaDoctor/referredToComp";

const Details = ({ patientDetails, appointmentId }) => {
  const testDoc =
    localStorage.getItem("DOCID") === "33133431-bc45-42b9-b921-56e245a91654";

  console.log({ "display patient details": patientDetails });
  return (
    <Box>
      <Grid rowSpacing={1} container>
        {!testDoc && (
          <Grid item lg={12}>
            <IssueReportedElementComp patientDetails={patientDetails} />
          </Grid>
        )}

        <Grid item lg={12}>
          <Detail
            patientDetails={patientDetails}
            label="Disease History"
            keyValue="preExistingConditions"
          />
        </Grid>

        <Grid item lg={12}>
          <Detail
            patientDetails={patientDetails}
            label="Drug History"
            keyValue="drugHistory"
          />
        </Grid>

        <Grid item lg={12}>
          <Detail
            patientDetails={patientDetails}
            label="Drug Allergy"
            keyValue="drugAllergy"
          />
        </Grid>

        <Grid item lg={12}>
          <Detail
            patientDetails={patientDetails}
            label="Habits"
            keyValue="habits"
          />
        </Grid>

        <Grid item lg={12}>
          <Detail
            patientDetails={patientDetails}
            label="Occupation"
            keyValue="occupation"
          />
        </Grid>

        <Grid item lg={12}>
          <Detail
            patientDetails={patientDetails}
            label="Parental History"
            keyValue="parentalHistory"
          />
        </Grid>
        <Grid item lg={12}>
          <ReferredToComp appointmentId={appointmentId} />
        </Grid>

        {patientDetails && patientDetails.gender === "FEMALE" && (
          <Fragment>
            <Grid item lg={12}>
              <Detail
                patientDetails={patientDetails}
                label="Menstual History"
                keyValue={"menstualHistory"}
              />
            </Grid>
            <Grid item lg={12}>
              <Detail
                patientDetails={patientDetails}
                label="Obstratic History"
                keyValue="obstraticHistory"
              />
            </Grid>
            <Grid item lg={12}>
              <Detail
                patientDetails={patientDetails}
                label="Marital History"
                keyValue="maritalHistory"
              />
            </Grid>
            <Grid item lg={12}>
              <Detail
                patientDetails={patientDetails}
                label="Surgical History"
                keyValue="surgicalHistory"
              />
            </Grid>
            <Grid item lg={12}>
              <Detail
                patientDetails={patientDetails}
                label="Personal History"
                keyValue="personalHistory"
              />
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Box>
  );
};

export default Details;
