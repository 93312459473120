import React, { Fragment, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Container,
  Grid,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { BASE_URL } from "../../../../../assets/constantsFile";
import { getData } from "../../../../../services/api/getApi";
import CustomDoughnutChartNew from "../../../ahc/statusTracker/comps/customDoughnutChartNew";
import { convertMapToObject, convertMapToObjectSorted } from "../../../../global/actions/actions";
import OhcDoughnutChart from "./ohcDoughnutChart";
import CustomBarGraphOhc from "./customBarChartOhc";
import NoOfCasesComp from "./noOfCasesComp";
import DashboardStats from "./dashboardStats";
import TopIllnessGraph from "./topIllnessGraph";

const sortData = (data) => {
  // Extract the entries from the object
  let entries = Object.entries(data || {});

  // Map 'OTHERS' to 'undefined'
  entries = entries.map(([key, value]) => (key === "OTHERS" ? ["undefined", value] : [key, value]));

  // Sort the entries based on the custom criteria
  entries.sort((a, b) => {
    if (a[0] === "<20") return -1;
    if (b[0] === "<20") return 1;
    if (a[0] === "undefined") return 1;
    if (b[0] === "undefined") return -1;
    return a[0].localeCompare(b[0]);
  });

  // Convert the entries back to an object
  return Object.fromEntries(entries);
};

const convertDataToArray = (data) => {
  if (!data || typeof data !== "object") {
    console.error("Invalid data object...");
    return [];
  }

  console.log({ data: Object.keys(data) });

  const dataArray = Object.keys(data).map((key) => ({
    name: key,
    sequence: data[key].sequence,
    percent: data[key].percent,
    value: data[key].value,
  }));

  // Sort the array based on the "name" property
  dataArray.sort((a, b) => {
    if (a.name === "TOTAL OPD") {
      return -1; // "TOTAL OPD" comes first
    } else if (b.name === "TOTAL OPD") {
      return 1; // "TOTAL OPD" comes first
    } else {
      return a.sequence - b.sequence; // Sort by sequence for other items
    }
  });

  return dataArray;
};

const durationOption = [
  { id: 1, label: "This Week", value: "THIS_WEEK", interval: "THIS_WEEK" },
  { id: 2, label: "This Month", value: "THIS_MONTH", interval: "THIS_MONTH" },
  { id: 3, label: "This Year", value: "THIS_YEAR", interval: "THIS_YEAR" },
];

const VeBhopalStatusTracker = ({ corpId = localStorage.getItem("CORPID") }) => {
  const [selectedDuration, setSelectedDuration] = useState("THIS_WEEK");
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const _getDepartmentList = async () => {
    const url = BASE_URL + `org/ohcStatusDepartments/${corpId}`;
    const departmentList = await getData(url, "");
    if (departmentList.error) {
      setDepartmentOptions([]);
    } else {
      const listTemp = departmentList.data.map((item, index) => ({
        id: index + 1,
        value: item,
        label: item,
      }));
      setDepartmentOptions(listTemp);
    }
  };
  useEffect(() => {
    _getDepartmentList();
  }, []);

  const [pieGraphData, setPieGraphData] = useState(null);
  const getGraphData = async () => {
    const url =
      BASE_URL +
      `org/ohcStatusTracker/detailed/${corpId}?duration=${selectedDuration || ""}&department=${
        selectedDepartment || ""
      }`;
    const graphData = await getData(url, "");
    if (graphData.error) {
      setPieGraphData(null);
    } else {
      const footFallBreakUp = {
        THIS_YEAR: {
          OPD: {
            sequence: 1,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisYear?.OPDCOUNT /
                (graphData?.data?.mapOfOhcStatsThisYear?.OPDCOUNT +
                  graphData?.data?.mapOfOhcStatsThisYear?.INJURYCOUNT)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisYear?.OPDCOUNT,
          },
          INJURY: {
            sequence: 2,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisYear?.INJURYCOUNT /
                (graphData?.data?.mapOfOhcStatsThisYear?.OPDCOUNT +
                  graphData?.data?.mapOfOhcStatsThisYear?.INJURYCOUNT)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisYear?.INJURYCOUNT,
          },
        },
        THIS_MONTH: {
          OPD: {
            sequence: 1,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisMonth?.OPDCOUNT /
                (graphData?.data?.mapOfOhcStatsThisMonth?.OPDCOUNT +
                  graphData?.data?.mapOfOhcStatsThisMonth?.INJURYCOUNT)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisMonth?.OPDCOUNT,
          },
          INJURY: {
            sequence: 2,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisMonth?.INJURYCOUNT /
                (graphData?.data?.mapOfOhcStatsThisMonth?.OPDCOUNT +
                  graphData?.data?.mapOfOhcStatsThisMonth?.INJURYCOUNT)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisMonth?.INJURYCOUNT,
          },
        },
        THIS_WEEK: {
          OPD: {
            sequence: 1,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisWeek?.OPDCOUNT /
                (graphData?.data?.mapOfOhcStatsThisWeek?.OPDCOUNT +
                  graphData?.data?.mapOfOhcStatsThisWeek?.INJURYCOUNT)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisWeek?.OPDCOUNT,
          },
          INJURY: {
            sequence: 2,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisWeek?.INJURYCOUNT /
                (graphData?.data?.mapOfOhcStatsThisWeek?.OPDCOUNT +
                  graphData?.data?.mapOfOhcStatsThisWeek?.INJURYCOUNT)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisWeek?.INJURYCOUNT,
          },
        },
      };

      const injuryBreakUp = {
        THIS_YEAR: {
          INDUSTRIAL: {
            sequence: 1,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisYear?.INDUSTRIAL /
                (graphData?.data?.mapOfOhcStatsThisYear?.INDUSTRIAL +
                  graphData?.data?.mapOfOhcStatsThisYear?.NON_INDUSTRIAL +
                  graphData?.data?.mapOfOhcStatsThisYear?.INJURY_LOCATION_OTHERS)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisYear?.INDUSTRIAL,
          },
          NON_INDUSTRIAL: {
            sequence: 2,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisYear?.NON_INDUSTRIAL /
                (graphData?.data?.mapOfOhcStatsThisYear?.INDUSTRIAL +
                  graphData?.data?.mapOfOhcStatsThisYear?.NON_INDUSTRIAL +
                  graphData?.data?.mapOfOhcStatsThisYear?.INJURY_LOCATION_OTHERS)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisYear?.NON_INDUSTRIAL,
          },
          UNDEFINED: {
            sequence: 3,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisYear?.INJURY_LOCATION_OTHERS /
                (graphData?.data?.mapOfOhcStatsThisYear?.INDUSTRIAL +
                  graphData?.data?.mapOfOhcStatsThisYear?.NON_INDUSTRIAL +
                  graphData?.data?.mapOfOhcStatsThisYear?.INJURY_LOCATION_OTHERS)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisYear?.INJURY_LOCATION_OTHERS,
          },
        },
        THIS_MONTH: {
          INDUSTRIAL: {
            sequence: 1,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisMonth?.INDUSTRIAL /
                (graphData?.data?.mapOfOhcStatsThisMonth?.INDUSTRIAL +
                  graphData?.data?.mapOfOhcStatsThisMonth?.NON_INDUSTRIAL +
                  graphData?.data?.mapOfOhcStatsThisMonth?.INJURY_LOCATION_OTHERS)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisMonth?.INDUSTRIAL,
          },
          NON_INDUSTRIAL: {
            sequence: 2,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisMonth?.NON_INDUSTRIAL /
                (graphData?.data?.mapOfOhcStatsThisMonth?.INDUSTRIAL +
                  graphData?.data?.mapOfOhcStatsThisMonth?.NON_INDUSTRIAL +
                  graphData?.data?.mapOfOhcStatsThisMonth?.INJURY_LOCATION_OTHERS)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisMonth?.NON_INDUSTRIAL,
          },
          UNDEFINED: {
            sequence: 3,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisMonth?.INJURY_LOCATION_OTHERS /
                (graphData?.data?.mapOfOhcStatsThisMonth?.INDUSTRIAL +
                  graphData?.data?.mapOfOhcStatsThisMonth?.NON_INDUSTRIAL +
                  graphData?.data?.mapOfOhcStatsThisMonth?.INJURY_LOCATION_OTHERS)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisMonth?.INJURY_LOCATION_OTHERS,
          },
        },
        THIS_WEEK: {
          INDUSTRIAL: {
            sequence: 1,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisWeek?.INDUSTRIAL /
                (graphData?.data?.mapOfOhcStatsThisWeek?.INDUSTRIAL +
                  graphData?.data?.mapOfOhcStatsThisWeek?.NON_INDUSTRIAL +
                  graphData?.data?.mapOfOhcStatsThisWeek?.INJURY_LOCATION_OTHERS)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisWeek?.INDUSTRIAL,
          },
          NON_INDUSTRIAL: {
            sequence: 2,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisWeek?.NON_INDUSTRIAL /
                (graphData?.data?.mapOfOhcStatsThisWeek?.INDUSTRIAL +
                  graphData?.data?.mapOfOhcStatsThisWeek?.NON_INDUSTRIAL +
                  graphData?.data?.mapOfOhcStatsThisWeek?.INJURY_LOCATION_OTHERS)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisWeek?.NON_INDUSTRIAL,
          },
          UNDEFINED: {
            sequence: 3,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisWeek?.INJURY_LOCATION_OTHERS /
                (graphData?.data?.mapOfOhcStatsThisWeek?.INDUSTRIAL +
                  graphData?.data?.mapOfOhcStatsThisWeek?.NON_INDUSTRIAL +
                  graphData?.data?.mapOfOhcStatsThisWeek?.INJURY_LOCATION_OTHERS)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisWeek?.INJURY_LOCATION_OTHERS,
          },
        },
      };

      const injurySeverity = {
        THIS_YEAR: {
          MILD: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisYear?.MILD /
                  (graphData?.data?.mapOfOhcStatsThisYear?.MILD +
                    graphData?.data?.mapOfOhcStatsThisYear?.SEVERE +
                    graphData?.data?.mapOfOhcStatsThisYear?.CRITICAL +
                    graphData?.data?.mapOfOhcStatsThisYear?.INJURY_SEVERITY_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisYear?.MILD || 0,
          },
          SEVERE: {
            sequence: 2,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisYear?.SEVERE /
                  (graphData?.data?.mapOfOhcStatsThisYear?.MILD +
                    graphData?.data?.mapOfOhcStatsThisYear?.SEVERE +
                    graphData?.data?.mapOfOhcStatsThisYear?.CRITICAL +
                    graphData?.data?.mapOfOhcStatsThisYear?.INJURY_SEVERITY_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisYear?.SEVERE || 0,
          },
          CRITICAL: {
            sequence: 3,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisYear?.CRITICAL /
                  (graphData?.data?.mapOfOhcStatsThisYear?.MILD +
                    graphData?.data?.mapOfOhcStatsThisYear?.SEVERE +
                    graphData?.data?.mapOfOhcStatsThisYear?.CRITICAL +
                    graphData?.data?.mapOfOhcStatsThisYear?.INJURY_SEVERITY_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisYear?.CRITICAL || 0,
          },
          UNDEFINED: {
            sequence: 3,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisYear?.INJURY_SEVERITY_OTHERS /
                (graphData?.data?.mapOfOhcStatsThisYear?.CRITICAL +
                  graphData?.data?.mapOfOhcStatsThisYear?.MILD +
                  graphData?.data?.mapOfOhcStatsThisYear?.SEVERE +
                  graphData?.data?.mapOfOhcStatsThisYear?.INJURY_SEVERITY_OTHERS)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisYear?.INJURY_SEVERITY_OTHERS,
          },
        },
        THIS_MONTH: {
          MILD: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisMonth?.MILD /
                  (graphData?.data?.mapOfOhcStatsThisMonth?.MILD +
                    graphData?.data?.mapOfOhcStatsThisMonth?.SEVERE +
                    graphData?.data?.mapOfOhcStatsThisMonth?.CRITICAL +
                    graphData?.data?.mapOfOhcStatsThisMonth?.INJURY_SEVERITY_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisMonth?.MILD || 0,
          },
          SEVERE: {
            sequence: 2,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisMonth?.SEVERE /
                  (graphData?.data?.mapOfOhcStatsThisMonth?.MILD +
                    graphData?.data?.mapOfOhcStatsThisMonth?.SEVERE +
                    graphData?.data?.mapOfOhcStatsThisMonth?.CRITICAL +
                    graphData?.data?.mapOfOhcStatsThisMonth?.INJURY_SEVERITY_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisMonth?.SEVERE || 0,
          },
          CRITICAL: {
            sequence: 3,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisMonth?.CRITICAL /
                  (graphData?.data?.mapOfOhcStatsThisMonth?.MILD +
                    graphData?.data?.mapOfOhcStatsThisMonth?.SEVERE +
                    graphData?.data?.mapOfOhcStatsThisMonth?.CRITICAL +
                    graphData?.data?.mapOfOhcStatsThisMonth?.INJURY_SEVERITY_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisMonth?.CRITICAL || 0,
          },

          UNDEFINED: {
            sequence: 3,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisMonth?.INJURY_SEVERITY_OTHERS /
                (graphData?.data?.mapOfOhcStatsThisMonth?.CRITICAL +
                  graphData?.data?.mapOfOhcStatsThisMonth?.MILD +
                  graphData?.data?.mapOfOhcStatsThisMonth?.SEVERE +
                  graphData?.data?.mapOfOhcStatsThisMonth?.INJURY_SEVERITY_OTHERS)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisMonth?.INJURY_SEVERITY_OTHERS,
          },
        },
        THIS_WEEK: {
          MILD: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisWeek?.MILD /
                  (graphData?.data?.mapOfOhcStatsThisWeek?.MILD +
                    graphData?.data?.mapOfOhcStatsThisWeek?.SEVERE +
                    graphData?.data?.mapOfOhcStatsThisWeek?.CRITICAL +
                    graphData?.data?.mapOfOhcStatsThisWeek?.INJURY_SEVERITY_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisWeek?.MILD || 0,
          },
          SEVERE: {
            sequence: 2,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisWeek?.SEVERE /
                  (graphData?.data?.mapOfOhcStatsThisWeek?.MILD +
                    graphData?.data?.mapOfOhcStatsThisWeek?.SEVERE +
                    graphData?.data?.mapOfOhcStatsThisWeek?.CRITICAL +
                    graphData?.data?.mapOfOhcStatsThisWeek?.INJURY_SEVERITY_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisWeek?.SEVERE || 0,
          },
          CRITICAL: {
            sequence: 3,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisWeek?.CRITICAL /
                  (graphData?.data?.mapOfOhcStatsThisWeek?.MILD +
                    graphData?.data?.mapOfOhcStatsThisWeek?.SEVERE +
                    graphData?.data?.mapOfOhcStatsThisWeek?.CRITICAL +
                    graphData?.data?.mapOfOhcStatsThisWeek?.INJURY_SEVERITY_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisWeek?.CRITICAL || 0,
          },

          UNDEFINED: {
            sequence: 3,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisWeek?.INJURY_SEVERITY_OTHERS /
                (graphData?.data?.mapOfOhcStatsThisWeek?.CRITICAL +
                  graphData?.data?.mapOfOhcStatsThisWeek?.MILD +
                  graphData?.data?.mapOfOhcStatsThisWeek?.SEVERE +
                  graphData?.data?.mapOfOhcStatsThisWeek?.INJURY_SEVERITY_OTHERS)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisWeek?.INJURY_SEVERITY_OTHERS,
          },
        },
      };

      const injuryTreatedAt = {
        THIS_YEAR: {
          REFERRED_OUTSIDE: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisYear?.REFERRED_OUTSIDE /
                  (graphData?.data?.mapOfOhcStatsThisYear?.REFERRED_OUTSIDE +
                    graphData?.data?.mapOfOhcStatsThisYear?.TREATED_IN_OHC +
                    graphData?.data?.mapOfOhcStatsThisYear?.TREATED_AT_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisYear?.REFERRED_OUTSIDE || 0,
          },
          TREATED_IN_OHC: {
            sequence: 2,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisYear?.TREATED_IN_OHC /
                  (graphData?.data?.mapOfOhcStatsThisYear?.REFERRED_OUTSIDE +
                    graphData?.data?.mapOfOhcStatsThisYear?.TREATED_IN_OHC +
                    graphData?.data?.mapOfOhcStatsThisYear?.TREATED_AT_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisYear?.TREATED_IN_OHC || 0,
          },
          UNDEFINED: {
            sequence: 3,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisYear?.TREATED_AT_OTHERS /
                (graphData?.data?.mapOfOhcStatsThisYear?.REFERRED_OUTSIDE +
                  graphData?.data?.mapOfOhcStatsThisYear?.TREATED_IN_OHC +
                  graphData?.data?.mapOfOhcStatsThisYear?.TREATED_AT_OTHERS)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisYear?.TREATED_AT_OTHERS,
          },
        },
        THIS_MONTH: {
          REFERRED_OUTSIDE: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisMonth?.REFERRED_OUTSIDE /
                  (graphData?.data?.mapOfOhcStatsThisMonth?.REFERRED_OUTSIDE +
                    graphData?.data?.mapOfOhcStatsThisMonth?.TREATED_IN_OHC +
                    graphData?.data?.mapOfOhcStatsThisMonth?.TREATED_AT_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisMonth?.REFERRED_OUTSIDE || 0,
          },
          TREATED_IN_OHC: {
            sequence: 2,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisMonth?.TREATED_IN_OHC /
                (graphData?.data?.mapOfOhcStatsThisMonth?.REFERRED_OUTSIDE +
                  graphData?.data?.mapOfOhcStatsThisMonth?.TREATED_IN_OHC +
                  graphData?.data?.mapOfOhcStatsThisMonth?.TREATED_AT_OTHERS)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisMonth?.TREATED_IN_OHC || 0,
          },
          UNDEFINED: {
            sequence: 3,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisMonth?.TREATED_AT_OTHERS /
                (graphData?.data?.mapOfOhcStatsThisMonth?.REFERRED_OUTSIDE +
                  graphData?.data?.mapOfOhcStatsThisMonth?.TREATED_IN_OHC +
                  graphData?.data?.mapOfOhcStatsThisMonth?.TREATED_AT_OTHERS)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisMonth?.TREATED_AT_OTHERS,
          },
        },
        THIS_WEEK: {
          REFERRED_OUTSIDE: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisWeek?.REFERRED_OUTSIDE /
                  (graphData?.data?.mapOfOhcStatsThisWeek?.REFERRED_OUTSIDE +
                    graphData?.data?.mapOfOhcStatsThisWeek?.TREATED_IN_OHC +
                    graphData?.data?.mapOfOhcStatsThisWeek?.TREATED_AT_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisWeek?.REFERRED_OUTSIDE || 0,
          },
          TREATED_IN_OHC: {
            sequence: 2,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisWeek?.TREATED_IN_OHC /
                  (graphData?.data?.mapOfOhcStatsThisWeek?.REFERRED_OUTSIDE +
                    graphData?.data?.mapOfOhcStatsThisWeek?.TREATED_IN_OHC +
                    graphData?.data?.mapOfOhcStatsThisWeek?.TREATED_AT_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisWeek?.TREATED_IN_OHC || 0,
          },
          UNDEFINED: {
            sequence: 3,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisWeek?.TREATED_AT_OTHERS /
                (graphData?.data?.mapOfOhcStatsThisWeek?.REFERRED_OUTSIDE +
                  graphData?.data?.mapOfOhcStatsThisWeek?.TREATED_IN_OHC +
                  graphData?.data?.mapOfOhcStatsThisWeek?.TREATED_AT_OTHERS)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisWeek?.TREATED_AT_OTHERS,
          },
        },
      };

      const genderWiseOPD = {
        THIS_YEAR: {
          MALE: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisYear?.MALEOPD /
                  (graphData?.data?.mapOfOhcStatsThisYear?.MALEOPD +
                    graphData?.data?.mapOfOhcStatsThisYear?.FEMALEOPD +
                    graphData?.data?.mapOfOhcStatsThisYear?.GENDER_OPD_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisYear?.MALEOPD || 0,
          },
          FEMALE: {
            sequence: 2,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisYear?.FEMALEOPD /
                  (graphData?.data?.mapOfOhcStatsThisYear?.MALEOPD +
                    graphData?.data?.mapOfOhcStatsThisYear?.FEMALEOPD +
                    graphData?.data?.mapOfOhcStatsThisYear?.GENDER_OPD_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisYear?.FEMALEOPD || 0,
          },
          UNDEFINED: {
            sequence: 3,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisYear?.GENDER_OPD_OTHERS /
                (graphData?.data?.mapOfOhcStatsThisYear?.MALEOPD +
                  graphData?.data?.mapOfOhcStatsThisYear?.FEMALEOPD +
                  graphData?.data?.mapOfOhcStatsThisYear?.GENDER_OPD_OTHERS)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisYear?.GENDER_OPD_OTHERS,
          },
        },
        THIS_MONTH: {
          MALE: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisMonth?.MALEOPD /
                  (graphData?.data?.mapOfOhcStatsThisMonth?.MALEOPD +
                    graphData?.data?.mapOfOhcStatsThisMonth?.FEMALEOPD +
                    graphData?.data?.mapOfOhcStatsThisMonth?.GENDER_OPD_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisMonth?.MALEOPD || 0,
          },
          FEMALE: {
            sequence: 2,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisMonth?.FEMALEOPD /
                  (graphData?.data?.mapOfOhcStatsThisMonth?.MALEOPD +
                    graphData?.data?.mapOfOhcStatsThisMonth?.FEMALEOPD +
                    graphData?.data?.mapOfOhcStatsThisMonth?.GENDER_OPD_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisMonth?.FEMALEOPD || 0,
          },
          UNDEFINED: {
            sequence: 3,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisMonth?.GENDER_OPD_OTHERS /
                (graphData?.data?.mapOfOhcStatsThisMonth?.MALEOPD +
                  graphData?.data?.mapOfOhcStatsThisMonth?.FEMALEOPD +
                  graphData?.data?.mapOfOhcStatsThisMonth?.GENDER_OPD_OTHERS)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisMonth?.GENDER_OPD_OTHERS,
          },
        },
        THIS_WEEK: {
          MALE: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisWeek?.MALEOPD /
                  (graphData?.data?.mapOfOhcStatsThisWeek?.MALEOPD +
                    graphData?.data?.mapOfOhcStatsThisWeek?.FEMALEOPD +
                    graphData?.data?.mapOfOhcStatsThisWeek?.GENDER_OPD_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisWeek?.MALEOPD || 0,
          },
          FEMALE: {
            sequence: 2,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisWeek?.FEMALEOPD /
                  (graphData?.data?.mapOfOhcStatsThisWeek?.MALEOPD +
                    graphData?.data?.mapOfOhcStatsThisWeek?.FEMALEOPD +
                    graphData?.data?.mapOfOhcStatsThisWeek?.GENDER_OPD_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisWeek?.FEMALEOPD || 0,
          },
          UNDEFINED: {
            sequence: 3,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisWeek?.GENDER_OPD_OTHERS /
                (graphData?.data?.mapOfOhcStatsThisWeek?.MALEOPD +
                  graphData?.data?.mapOfOhcStatsThisWeek?.FEMALEOPD +
                  graphData?.data?.mapOfOhcStatsThisWeek?.GENDER_OPD_OTHERS)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisWeek?.GENDER_OPD_OTHERS,
          },
        },
      };

      const genderWiseInjury = {
        THIS_YEAR: {
          MALE: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisYear?.MALEINJURY /
                  (graphData?.data?.mapOfOhcStatsThisYear?.MALEINJURY +
                    graphData?.data?.mapOfOhcStatsThisYear?.FEMALEINJURY +
                    graphData?.data?.mapOfOhcStatsThisYear?.GENDER_INJURY_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisYear?.MALEINJURY || 0,
          },
          FEMALE: {
            sequence: 2,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisYear?.FEMALEINJURY /
                  (graphData?.data?.mapOfOhcStatsThisYear?.MALEINJURY +
                    graphData?.data?.mapOfOhcStatsThisYear?.FEMALEINJURY +
                    graphData?.data?.mapOfOhcStatsThisYear?.GENDER_INJURY_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisYear?.FEMALEINJURY || 0,
          },
          UNDEFINED: {
            sequence: 3,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisYear?.GENDER_INJURY_OTHERS /
                (graphData?.data?.mapOfOhcStatsThisYear?.MALEINJURY +
                  graphData?.data?.mapOfOhcStatsThisYear?.FEMALEINJURY +
                  graphData?.data?.mapOfOhcStatsThisYear?.GENDER_INJURY_OTHERS)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisYear?.GENDER_INJURY_OTHERS,
          },
        },
        THIS_MONTH: {
          MALE: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisMonth?.MALEINJURY /
                  (graphData?.data?.mapOfOhcStatsThisMonth?.MALEINJURY +
                    graphData?.data?.mapOfOhcStatsThisMonth?.FEMALEINJURY +
                    graphData?.data?.mapOfOhcStatsThisMonth?.GENDER_INJURY_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisMonth?.MALEINJURY || 0,
          },
          FEMALE: {
            sequence: 2,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisMonth?.FEMALEINJURY /
                  (graphData?.data?.mapOfOhcStatsThisMonth?.MALEINJURY +
                    graphData?.data?.mapOfOhcStatsThisMonth?.FEMALEINJURY +
                    graphData?.data?.mapOfOhcStatsThisMonth?.GENDER_INJURY_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisMonth?.FEMALEINJURY || 0,
          },
          UNDEFINED: {
            sequence: 3,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisMonth?.GENDER_INJURY_OTHERS /
                (graphData?.data?.mapOfOhcStatsThisMonth?.MALEINJURY +
                  graphData?.data?.mapOfOhcStatsThisMonth?.FEMALEINJURY +
                  graphData?.data?.mapOfOhcStatsThisMonth?.GENDER_INJURY_OTHERS)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisMonth?.GENDER_INJURY_OTHERS,
          },
        },
        THIS_WEEK: {
          MALE: {
            sequence: 1,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisWeek?.MALEINJURY /
                  (graphData?.data?.mapOfOhcStatsThisWeek?.MALEINJURY +
                    graphData?.data?.mapOfOhcStatsThisWeek?.FEMALEINJURY +
                    graphData?.data?.mapOfOhcStatsThisWeek?.GENDER_INJURY_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisWeek?.MALEINJURY || 0,
          },
          FEMALE: {
            sequence: 2,
            percent:
              (
                (graphData?.data?.mapOfOhcStatsThisWeek?.FEMALEINJURY /
                  (graphData?.data?.mapOfOhcStatsThisWeek?.MALEINJURY +
                    graphData?.data?.mapOfOhcStatsThisWeek?.FEMALEINJURY +
                    graphData?.data?.mapOfOhcStatsThisWeek?.GENDER_INJURY_OTHERS)) *
                100
              ).toFixed(2) || 0,
            value: graphData?.data?.mapOfOhcStatsThisWeek?.FEMALEINJURY || 0,
          },
          UNDEFINED: {
            sequence: 3,
            percent: (
              (graphData?.data?.mapOfOhcStatsThisWeek?.GENDER_INJURY_OTHERS /
                (graphData?.data?.mapOfOhcStatsThisWeek?.MALEINJURY +
                  graphData?.data?.mapOfOhcStatsThisWeek?.FEMALEINJURY +
                  graphData?.data?.mapOfOhcStatsThisWeek?.GENDER_INJURY_OTHERS)) *
              100
            ).toFixed(2),
            value: graphData?.data?.mapOfOhcStatsThisWeek?.GENDER_INJURY_OTHERS,
          },
        },
      };

      const temp = {
        footFallBreakUp,
        injuryBreakUp,
        injurySeverity,
        injuryTreatedAt,
        genderWiseOPD,
        genderWiseInjury,
        ...graphData?.data,
      };

      setPieGraphData(temp);
    }
  };

  const [opdGraphData, setOpdGraphData] = useState(null);
  const getGraphDataForOPD = async () => {
    const url =
      BASE_URL +
      `org/ohcStatusTracker/${corpId}?duration=${selectedDuration || ""}&department=${
        selectedDepartment || ""
      }`;
    const graphData = await getData(url, "");
    if (graphData.error) {
      setOpdGraphData(null);
    } else {
      setOpdGraphData(graphData.data);
    }
  };
  useEffect(() => {
    getGraphDataForOPD();
    getGraphData();
  }, [selectedDepartment, selectedDuration]);

  return (
    <Fragment>
      <Container maxWidth={false}>
        <Grid container spacing={2} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Grid item xs={12} lg={4} sm={12} md={4}>
            <ButtonGroup size="small" variant="contained" sx={{ borderRadius: 5 }}>
              {durationOption.map((option) => (
                <Button
                  size="small"
                  key={option.id}
                  variant={selectedDuration === option.value ? "contained" : "outlined"}
                  sx={{ borderRadius: 5 }}
                  onClick={() => setSelectedDuration(option.value)}>
                  {option.label}
                </Button>
              ))}
            </ButtonGroup>
          </Grid>
          <Grid item xs={12} lg={4} sm={12} md={4}>
            <DashboardStats
              data={convertDataToArray(pieGraphData?.footFallBreakUp?.[selectedDuration])}
            />
          </Grid>
          <Grid item xs={12} lg={4} sm={12} md={4}>
            {/* <NoOfCasesComp
              data={convertDataToArray(pieGraphData?.footFallBreakUp?.[selectedDuration])}
            /> */}
            <Autocomplete
              value={selectedDepartment}
              options={departmentOptions}
              getOptionLabel={(option) => option.label}
              onChange={(event, newValue) => setSelectedDepartment(newValue)}
              renderInput={(params) => <TextField {...params} label="Department" size="small" />}
            />
          </Grid>
        </Grid>

        <Grid container spacing={5} mt={0}>
          <Grid item xs={12} sm={12} md={3} lg={2}>
            <OhcDoughnutChart
              COLORS={["#D45FFF", "#127DDD", "#FFE066", "#DDFFDF"]}
              data={convertDataToArray(pieGraphData?.injuryBreakUp?.[selectedDuration])}
              boxHeight={400}
              title={"Injury Type"}
              labelHeading={"Total"}
              disableClickable={true}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={2}>
            <OhcDoughnutChart
              COLORS={["#0121c5", "#54fddd", "#f30c00", "#b5c1c1"]}
              data={convertDataToArray(pieGraphData?.injurySeverity?.[selectedDuration])}
              title={"Injury Severity"}
              labelHeading={"Total"}
              disableClickable={true}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={2}>
            <OhcDoughnutChart
              COLORS={["#127DDD", "#D45FFF", "#FFE066"]}
              data={convertDataToArray(pieGraphData?.genderWiseInjury?.[selectedDuration])}
              title={"Gender Wise - Injury"}
              labelHeading={"Total"}
              disableClickable={true}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={2}>
            <OhcDoughnutChart
              COLORS={["#D45FFF", "#127DDD", "#FFE066"]}
              data={convertDataToArray(pieGraphData?.injuryTreatedAt?.[selectedDuration])}
              title={" Treatment Place"}
              labelHeading={"Total"}
              disableClickable={true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CustomBarGraphOhc
              data={convertMapToObject(
                sortData(pieGraphData?.mapOfDurationAndAgeGraph?.[selectedDuration])
              )}
              title={"Age Group Wise Division - Injury"}
              labelHeading={""}
              disableClickable={true}
              boxHeight={"100%"}
              xAxisLabel="Age Group"
            />
          </Grid>

          {/* <Grid item xs={12} sm={12} md={3} lg={2}>
            <OhcDoughnutChart
              COLORS={["#42BD53", "#F66"]}
              data={convertDataToArray(pieGraphData?.footFallBreakUp?.[selectedDuration])}
              title={"# of Cases"}
              labelHeading={"Total"}
              disableClickable={true}
            />
          </Grid> */}

          <Grid item xs={12} sm={12} md={3} lg={2}>
            <OhcDoughnutChart
              COLORS={["#127DDD", "#D45FFF", "#FFE066"]}
              data={convertDataToArray(pieGraphData?.genderWiseOPD?.[selectedDuration])}
              title={"Gender Wise - OPD"}
              labelHeading={"Total"}
              disableClickable={true}
            />
          </Grid>

          {/* <Grid item xs={12} sm={12} md={6} lg={4}>
            <CustomBarGraphOhc
              data={convertMapToObject(opdGraphData?.mapOfIllnessTop10)}
              title={"Top Illness"}
              labelHeading={""}
              disableClickable={true}
              boxHeight={"100%"}
              xAxisLabel="Illness"
            />
          </Grid> */}

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TopIllnessGraph data={convertMapToObject(opdGraphData?.mapOfIllnessTop10)} />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CustomBarGraphOhc
              COLORS={[]}
              data={convertMapToObject(sortData(opdGraphData?.mapOfAge))}
              title={"Age Group Wise Division - OPD"}
              disableClickable={true}
              boxHeight={"100%"}
              xAxisLabel="Age Group"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <CustomBarGraphOhc
              COLORS={[]}
              data={convertMapToObject(
                pieGraphData?.mapOfDurationAndDepartmentGraph?.[selectedDuration]
              )}
              title={"Top Department - Injury"}
              labelHeading={""}
              disableClickable={true}
              boxHeight={"100%"}
              xAxisLabel="Departments"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <CustomBarGraphOhc
              COLORS={[]}
              data={convertMapToObject(opdGraphData?.mapOfDepartmentTop10)}
              title={"Top Department - OPD"}
              labelHeading={""}
              disableClickable={true}
              boxHeight={"100%"}
              xAxisLabel="Departments"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default VeBhopalStatusTracker;
