import { Box, Grid, Stack } from "@mui/material";
import React, { Fragment } from "react";
import DisplayCard from "./displayCard";

const FirstAid = ({ healthChampionsList }) => {
  const data = [
    {
      id: 1,
      name: "Ashok Awadhiya",
      url: "",
    },
    {
      id: 2,
      name: "Deepak Yadav",
      url: "",
    },
    {
      id: 3,
      name: "Brijlal Rama More",
      url: "",
    },
    {
      id: 4,
      name: "Krishna Kumar Singh",
      url: "",
    },
    {
      id: 5,
      name: "Bharat Patidar",
      url: "",
    },
    {
      id: 6,
      name: "Rakesh bhatt",
      url: "",
    },
    {
      id: 7,
      name: "Raghuveer Shaishwar",
      url: "",
    },
    {
      id: 8,
      name: "Poonam Chand Atude",
      url: "",
    },
    {
      id: 9,
      name: "Ummed Singh Sahite",
      url: "",
    },
    // {
    //   id: 10,
    //   name: "Akhilesh Kumar",
    //   url: "",
    // },
    {
      id: 11,
      name: "Badri Prasad Bamanka",
      url: "",
    },
    {
      id: 12,
      name: "Narendra Panchal",
      url: "",
    },
    {
      id: 13,
      name: "Abhimanyu Dubey",
      url: "",
    },
    // {
    //   id: 14,
    //   name: "Akhilesh Kumar",
    //   url: "",
    // },
    {
      id: 15,
      name: "Umesh Kumar Yadav",
      url: "",
    },
    // {
    //   id: 16,
    //   name: "Ramayan Shukla",
    //   url: "",
    // },
    // {
    //   id: 17,
    //   name: "Pushpendra Shukla",
    //   url: "",
    // },
    {
      id: 18,
      name: "Naushad Khan",
      url: "",
    },
    {
      id: 19,
      name: "Gopal Dawar",
      url: "",
    },
    {
      id: 20,
      name: "Dashrath Yadav",
      url: "",
    },
    {
      id: 21,
      name: "Munnilal Choudhary",
      url: "",
    },
    {
      id: 22,
      name: "Hemraj Asodeker",
      url: "",
    },
    {
      id: 23,
      name: "Lakhan Choudhary",
      url: "",
    },
    // {
    //   id: 24,
    //   name: "Mangal Singh",
    //   url: "",
    // },
    // {
    //   id: 25,
    //   name: "Hariram Rai",
    //   url: "",
    // },
    // {
    //   id: 26,
    //   name: "Rakesh Singh Sikarwar",
    //   url: "",
    // },
    // {
    //   id: 27,
    //   name: "Ramprasad",
    //   url: "",
    // },
    {
      id: 28,
      name: "Vijay Kumar Dwedi",
      url: "",
    },
    {
      id: 29,
      name: "Shyam Sunder Soni",
      url: "",
    },
    {
      id: 30,
      name: "Chandrapal Kashyap",
      url: "",
    },
  ];
  return (
    <Fragment>
      <Box sx={{ mb: 4, mt: 2 }}>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Stack
              direction="row"
              spacing={3}
              useFlexGap
              flexWrap="wrap"
              display="flex"
              justifyContent="center"
            >
              {healthChampionsList.map((item, index) => (
                <DisplayCard key={index} item={item} type={"FIRST_AID"} />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default FirstAid;
