import RootTemplate from "../../global/templates/rootTemplate";
import AHCStatusTrackerMain from "../../modules/ahc/statusTracker/ahcStatusTrackerMain";

const AHCStatusTrackerIndex = () => {
  return (
    <RootTemplate>
      <AHCStatusTrackerMain />
    </RootTemplate>
  );
};

export default AHCStatusTrackerIndex;
