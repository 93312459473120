import { Box, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import LogoIcon from "../../../assets/images/LogoIcon.png";
import logoHospital from "../../../assets/images/logoHospital.png";

const NavBar = () => {
  return (
    <Fragment>
      <Grid
        container
        sx={{
          padding: "10px",
          boxShadow: "0px 1px 6px 1px rgba(0, 0, 0, 0.15)",
          mb: 1,
        }}>
        <Grid item xs={6} lg={9}>
          <Box
            component={"img"}
            src={LogoIcon}
            width={135}
            height={25}
            alt="Apna Cliniq logo"
            style={{ padding: "2px", cursor: "pointer" }}
          />
          <Box sx={{ marginY: "5px" }}>
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "15px",
                lineHeight: "18px",
                color: "#127DDD",
              }}>
              Sab Theek Ho Jaega
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} lg={3} textAlign={"right"}>
          <Box
            component={"img"}
            src={logoHospital}
            width={135}
            height={55}
            alt="Apna Cliniq logo"
            style={{ padding: "2px", cursor: "pointer" }}
          />
          <Box sx={{ marginY: "5px" }}></Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default NavBar;
