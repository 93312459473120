import HWI from "../assets/images/ticketingSystem/aware seesion 1.png";
import CAI from "../assets/images/ticketingSystem/Corp admin 1.png";
import CEI from "../assets/images/ticketingSystem/corp employee 1.png";
import INTI from "../assets/images/ticketingSystem/Internal 1.png";
import PHAI from "../assets/images/ticketingSystem/pharmacy 1.png";
import PEI from "../assets/images/ticketingSystem/pre employment 1.png";
import SRI from "../assets/images/ticketingSystem/service req 1.png";

import TaskAltIcon from "@mui/icons-material/TaskAlt";
import NotificationsIcon from "@mui/icons-material/Notifications";
import RecommendIcon from "@mui/icons-material/Recommend";
import { colors } from "./color";

export const CorpName = "TEST";

export const FILE_TYPE = [
  { value: "XRAY", label: "Xray" },
  { value: "AUDIOMETRY", label: "Audiometry" },
  { value: "BLOODTEST", label: "Blood Test" },
  { value: "PFT", label: "PFT" },
  { value: "ECG", label: "Ecg" },
];

export const MACHINE_NUMBER = [
  { value: "machine1", label: "Machine 1" },
  { value: "machine2", label: "Machine 2" },
  { value: "machine3", label: "Machine 3" },
  { value: "machine4", label: "Machine 4" },
  { value: "machine5", label: "Machine 5" },
];

export const DATASHEET_SEQ = [
  "createGoogleSheet",
  "pasteLink",
  "tabHrData",
  "uploadHrList",
  "tabSmdToggle",
  "copySmdToggle",
  "tabDefectExecution",
  "copyDefectExecution",
  "tabSmdUpload",
  "copySmdUpload",
  "tabDefectUpload",
  "copyDefectUpload",
  "tabSmdFinal",
  "copySmdFinal",
  "tabDefectFinal",
  "copyDefectFinal",
  "tabAnchorSequence",
  "tabSnop",
];

export const DISPATCH_SEQ = [
  "boxing",
  "scan",
  "dowloadIndex",
  "printIndex",
  "pasteIndex",
  "errorReport",
  "generateSnopMail",
  "sendMail",
  "createInvoice",
  "sendDelivery",
];

export const UPLOAD_SEQ = [
  "pftReport",
  "audiometryReport",
  "bloodTestReport",
  "ecgReport",
  "xrayReport",
];
export const REPORTING = [
  "anchorReport",
  "uploadAnchorSeq",
  "mpAnchorSeq",
  "mpNonAnchorSeq",
  "form21",
  "qcManualReport",
  "qcTech",
  "qcTechReport",
];

export const CORPORATE_SERVICES = [
  "Digitisation of Health records",
  "Annual Health camps",
  "Occupational health centre management",
  "Pre Employment health tests",
  "Care assistance for surgeries and emergencies",
  "Group/Individual Insurence",
  "CSR",
  "Health awareness sessions",
  "Ambulance services",
];

export const StatusList = [
  {
    id: 0,
    label: "All",
    value: "ALL",
  },
  {
    id: 1,
    label: "Pending for confirmation",
    value: "TICKET_RAISED",
    color: "#e2435c",
    icon: <NotificationsIcon fontSize="10" sx={{ color: "#fff" }} />,
  },
  {
    id: 2,
    label: "Booking Confirmed",
    value: "BOOKING_CONFIRMED",
    color: "#eab676",
    icon: <RecommendIcon fontSize="10" sx={{ color: "#fff" }} />,
  },
  {
    id: 3,
    label: "Completed",
    value: "COMPLETED",
    color: "#3caf4a",
    icon: <TaskAltIcon fontSize="10" sx={{ color: "#fff" }} />,
  },
];

export const StatusListForNonFilter = [
  {
    id: 1,
    label: "Pending for confirmation",
    value: "TICKET_RAISED",
    color: colors.pending,
    icon: <NotificationsIcon fontSize="10" sx={{ color: "#000" }} />,
  },
  {
    id: 2,
    label: "Booking Confirmed",
    value: "BOOKING_CONFIRMED",
    color: colors.bookingConfirmed,
    icon: <RecommendIcon fontSize="10" sx={{ color: "#000" }} />,
  },
  {
    id: 3,
    label: "Completed",
    value: "COMPLETED",
    color: colors.completed,
    icon: <TaskAltIcon fontSize="10" sx={{ color: "#000" }} />,
  },
];

export const TicketCategoryList = [
  {
    id: 1,
    title: "Awareness Session",
    label: "Awareness Session",
    imageUrl: HWI,
    ticketType: "HEALTH_AWARENESS",
  },
  {
    id: 2,
    title: "Pre Employment",
    label: "Pre Employment",
    imageUrl: PEI,
    ticketType: "PRE_EMPLOYMENT",
  },
  {
    id: 3,
    title: "Inquiry for new service",
    label: "Inquiry for new service",
    imageUrl: CAI,
    ticketType: "NEW_SERVICE_INQUIRY",
  },
  {
    id: 4,
    title: "Query on active/completed service",
    label: "Query on active/completed service",
    imageUrl: CEI,
    ticketType: "SERVICE_ISSUE",
  },
  {
    id: 5,
    title: "Emergency",
    label: "Emergency",
    imageUrl: CEI,
    ticketType: "EMERGENCY",
  },
  {
    id: 6,
    title: "General Query",
    label: "General Query",
    imageUrl: INTI,
    ticketType: "CORP_ADMIN",
  },
];

export const TicketTypeLabel = {
  HEALTH_AWARENESS: "Awareness Session",
  PRE_EMPLOYMENT: "Pre Employment",
  NEW_SERVICE_INQUIRY: "Inquiry for new service",
  SERVICE_ISSUE: "Query on active/completed service",
  EMERGENCY: "Emergency",
  CORP_ADMIN: "General Query",
  CORP_EMPLOYEE: "Personal Medical Request",
};

export const ClientTicketTypes = [
  "HEALTH_AWARENESS",
  "PRE_EMPLOYMENT",
  "NEW_SERVICE_INQUIRY",
  "SERVICE_ISSUE",
  "EMERGENCY",
  "CORP_ADMIN",
];
