import { Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { StatusListForNonFilter, TicketTypeLabel } from "../../../../../assets/corpConstants";
import {
  DateIcon1,
  EmployeeIcon,
  NumberIcon,
  TypeIcon,
} from "../../../../../assets/customicons/customIcons";
import {
  CustomTypography,
  CustomTypographyText,
  CustomTypographyTextStatus,
} from "../../../../global/customTypography/customTypography";

const TicketCardView = ({ ticket }) => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Box
        onClick={() =>
          navigate(`/corporatehome/tickets/ticketview/${ticket.ticketId}`, { state: ticket })
        }>
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <Box>
              <Stack direction={"row"} display="flex" justifyContent="center" alignItems="center">
                <Box
                  sx={{
                    width: 15,
                    height: 80,
                    background:
                      StatusListForNonFilter.find((element) => element.value === ticket?.status)
                        ?.color || "lightgray",
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10,
                  }}></Box>
                <Card variant="outlined" sx={{ width: "100%" }}>
                  <CardContent>
                    <Stack direction={"row"}>
                      <Grid container spacing={1}>
                        <Grid item lg={2} display="flex" alignItems="center">
                          <Stack spacing={2} flex={1} width="100%">
                            <Stack direction="row" spacing={1}>
                              <NumberIcon fontSize="10" />
                              <CustomTypography>Number</CustomTypography>
                            </Stack>
                            <CustomTypographyText>{ticket?.ticketId}</CustomTypographyText>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          lg={2}
                          display="flex"
                          justifyContent="center"
                          alignItems="center">
                          <Stack spacing={2}>
                            <Stack direction="row" spacing={1}>
                              <DateIcon1 fontSize="10" />
                              <CustomTypography>Date</CustomTypography>
                            </Stack>
                            <CustomTypographyText>
                              {ticket?.date ? dayjs(ticket?.date).format("LL") : ""}
                            </CustomTypographyText>
                          </Stack>
                        </Grid>
                        <Grid item lg={2} display="flex" alignItems="center">
                          <Stack spacing={2}>
                            <Stack direction="row" spacing={1}>
                              <TypeIcon fontSize="10" />
                              <CustomTypography>Type</CustomTypography>
                            </Stack>
                            <CustomTypographyText>
                              {ticket?.ticketType ? TicketTypeLabel[ticket?.ticketType] : "n/a"}
                            </CustomTypographyText>
                          </Stack>
                        </Grid>
                        {/* <Grid item lg={2} display="flex" alignItems="center">
                          <Stack spacing={2}>
                            <Stack direction="row" spacing={1}>
                              <CompanyNameIcon fontSize="10" />
                              <CustomTypography>Company Name</CustomTypography>
                            </Stack>
                            <CustomTypographyText>{ticket?.corpName}</CustomTypographyText>
                          </Stack>
                        </Grid> */}
                        <Grid item lg={3} display="flex" alignItems="center">
                          <Stack spacing={2}>
                            <Stack direction="row" spacing={1}>
                              <EmployeeIcon fontSize="10" />
                              <CustomTypography>Employee Name</CustomTypography>
                            </Stack>
                            <CustomTypographyText>{ticket?.raisedBy || "n/a"}</CustomTypographyText>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          display="flex"
                          justifyContent="center"
                          alignItems="center">
                          <Box
                            component={Stack}
                            sx={{
                              background:
                                StatusListForNonFilter.find(
                                  (element) => element.value === ticket?.status
                                )?.color || "lightgray",
                              px: 3,
                              py: 1,
                              borderRadius: 3,
                              minWidth: 210,
                              minHeight: 40,
                            }}
                            direction="row"
                            spacing={1}
                            display="flex"
                            justifyContent="center"
                            alignItems="center">
                            {StatusListForNonFilter.find(
                              (element) => element.value === ticket?.status
                            )?.icon || <NotificationsIcon fontSize="10" sx={{ color: "#fff" }} />}
                            <CustomTypographyTextStatus>
                              {
                                StatusListForNonFilter.find(
                                  (element) => element.value === ticket?.status
                                )?.label
                              }
                            </CustomTypographyTextStatus>
                          </Box>
                        </Grid>
                      </Grid>
                    </Stack>
                  </CardContent>
                </Card>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default TicketCardView;
