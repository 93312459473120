import { Box, Grid, Stack, Typography } from "@mui/material";
import Expired from "../../../../assets/images/expired.png";
import Workinprogress from "../../../../assets/images/workinprogress.png";

const DashboardStats = ({ data }) => {
  return (
    <Grid container columnSpacing={2} alignItems="center" rowSpacing={1}>
      <Grid item xs={9} sm={9} md={6} lg={6}>
        <Box
          sx={{
            boxSizing: "border-box",
            background: "#FFFFFF",
            border: "0.5px solid #8A8A8A",
            borderRadius: 5,
            px: 2,
            py: 1,
          }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack ction="column" justifyContent="space-between">
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: 13,
                  lineHeightt: 16,
                  color: "#8A8A8A",
                }}>
                Total OPDs
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: 20,
                  lineHeightt: 27,
                  color: "#FC1C5C",
                }}>
                {data && !isNaN(data[0]?.value) ? `${data[0]?.value} ` : "n/a"}
                <span style={{ fontSize: 14 }}>
                  {data && !isNaN(data[0]?.percent) ? `(${data[0]?.percent}%)` : "n/a"}
                </span>
              </Typography>
            </Stack>
            <Box>
              <Box component="img" alt="Expired" src={Expired} sx={{ height: 50, width: 50 }} />
            </Box>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={9} sm={9} md={6} lg={6}>
        <Box
          sx={{
            boxSizing: "border-box",
            background: "#FFFFFF",
            border: "0.5px solid #8A8A8A",
            borderRadius: 5,
            px: 2,
            py: 1,
          }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack ction="column" justifyContent="space-between">
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: 13,
                  lineHeightt: 16,
                  color: "#8A8A8A",
                }}>
                Total Injuries
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: 20,
                  lineHeightt: 20,
                  color: "#FF9F00",
                }}>
                {data && !isNaN(data[1]?.value) ? `${data[1]?.value} ` : "n/a"}
                <span style={{ fontSize: 14 }}>
                  {data && !isNaN(data[1]?.percent) ? `(${data[1]?.percent}%)` : "n/a"}
                </span>
              </Typography>
            </Stack>
            <Box>
              <Box
                component="img"
                alt="Expired"
                src={Workinprogress}
                sx={{ height: 50, width: 50 }}
              />
            </Box>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DashboardStats;
