import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useReactToPrint } from "react-to-print";
import { BASE_URL } from "../../assets/constantsFile";
import { getDelay } from "../../assets/utils";
import { prescriptionContext } from "../../common/context/usercontext";
import { getData } from "../../services/api/getApi";
import { saveData } from "../../services/api/postApi";
import PrintReportComponent from "../viewReport/printReportComponent";
import ShowPrescriptions from "./showPrescriptions";

const EndConsultation = (props) => {
  const prescriptionContextData = useContext(prescriptionContext);
  const [open, setOpen] = useState(false);
  const [showPrescription, setShowPrescription] = useState(false);

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
    addNewPrescriptionHandler();
  };

  const handleClose = () => {
    setShowPrescription(false);
    setOpen(false);
  };
  const textData =
    props.label === "END CONSULTATION"
      ? "Do you want to finish consultation?"
      : "Do you want to submit prescription?";
  function goBackToPatientPage() {
    handleClose();

    markAttended();
    navigate("/patients", { replace: true, state: { test: "TEST" } });
  }

  const buttonColor =
    props.label === "END CONSULTATION" ? "#F80000" : "#127DDD";

  const markAttended = async () => {
    const user = await saveData(
      BASE_URL + "clinic/markAttended/" + props.appointmentId,
      "",
      ""
    );

    if (user.error) {
      console.log("error");
    } else {
      const data = user.data;
      console.log({ success: user.data });
    }
  };

  // save presciptions main call
  const addNewPrescriptionHandler = () => {
    const data = prescriptionContextData.prescriptionData;

    console.log({ data });

    if (data && data.length > 0) {
      let isValidated = false;

      const transformedData = data.map((value, i) => {
        return {
          medicineName: value?.medicineName,
          dosage: value?.dosage,
          m: value?.m,
          a: value?.a,
          e: value?.e,
          n: value?.n,

          duration: value?.duration,
          durationString: value?.durationString,

          frequencyString: value?.frequencyString?.frequency,
          frequencyStringVernacular:
            value?.frequencyString?.frequencyVernacular,
          comment: value?.comment?.instruction,
          commentVernacular: value?.comment?.instructionVernacular,

          timing:
            (value.m ? "Morning" : "") +
            (value.m && (value.a || value.e || value.n) ? "-" : "") +
            (value.a ? "Afternoon" : "") +
            (value.a && (value.e || value.n) ? "-" : "") +
            (value.e ? "Evening" : "") +
            (value.e && value.n ? "-" : "") +
            (value.n ? "Night" : ""),
        };
      });

      isValidated = transformedData.some(validateFields);
      console.log({ validation: isValidated });

      if (!isValidated) {
        const prescription = {
          docId: localStorage.getItem("DOCID"),
          patientId: props.patientId,
          medicines: transformedData,
          appointmentId: props.appointmentId,
          prescriptionId: prescriptionContextData.prescriptionId,
          advice: prescriptionContextData.prescriptionVM
            ? prescriptionContextData.prescriptionVM.advice
            : "",
        };

        savePrescription(prescription);
        setContent(
          <Stack
            sx={{ width: "100%" }}
            direction="row"
            display="flex"
            justifyContent="center"
            spacing={2}
          >
            <Button
              onClick={() => setShowPrescription(true)}
              variant="outlined"
              size="small"
            >
              Yes
            </Button>
            <Button
              onClick={handleClose}
              autoFocus
              variant="outlined"
              size="small"
            >
              No
            </Button>
          </Stack>
        );
        setContent1(textData);
      } else {
        setContent(
          <Stack
            sx={{ width: "100%" }}
            direction="row"
            display="flex"
            justifyContent="center"
            spacing={2}
          >
            <Button
              onClick={handleClose}
              autoFocus
              variant="outlined"
              size="small"
            >
              OK
            </Button>
          </Stack>
        );
        setContent1("fields in Rx cannot be empty!");
      }
    } else {
      setContent(
        <Stack
          sx={{ width: "100%" }}
          direction="row"
          display="flex"
          justifyContent="center"
          spacing={2}
        >
          <Button
            onClick={() => setShowPrescription(true)}
            variant="outlined"
            size="small"
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            autoFocus
            variant="outlined"
            size="small"
          >
            No
          </Button>
        </Stack>
      );
      setContent1(textData);
    }
  };

  // save pres call
  const savePrescription = async (p) => {
    const response = await saveData(BASE_URL + "doctor/prescription", p, "");

    if (response.error) {
      alert("error");
    } else {
      getPDetails();
    }
  };
  //validation
  const validateFields = (data) => {
    let validate = 0;
    data.medicineName ? (validate = 1) : (validate = 0);
    return validate === 0;
  };

  const [content1, setContent1] = useState(
    "Do you want to submit prescription?"
  );
  const [content, setContent] = useState(
    <Stack
      sx={{ width: "100%" }}
      direction="row"
      display="flex"
      justifyContent="center"
      spacing={2}
    >
      <Button
        onClick={() => setShowPrescription(true)}
        variant="outlined"
        size="small"
      >
        Yes
      </Button>
      <Button onClick={handleClose} autoFocus variant="outlined" size="small">
        No
      </Button>
    </Stack>
  );

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [pDetails, setPdetails] = useState();

  const getPDetails = async () => {
    const url = BASE_URL + "doctor/appointment/details/" + props.appointmentId;

    const token = "";

    const user = await getData(url, token);

    if (user.error) {
    } else {
      const data = user.data;

      const transformedPatients = data.map((patientData, index) => {
        return {
          index: index,
          advice: patientData.advice,
          docId: patientData.docId,
          clinicId: patientData.clinicId,
          patientId: patientData.patientId,

          patient: {
            patientId: patientData.patientId,
            name: patientData.patientName,
            gender: patientData.gender,
            age: patientData.age,
          },
          name: patientData.patientName,
          gender: patientData.gender,
          age: patientData.age,
          attended: patientData.attended, /////to be added
          issue: patientData.issue, /////to be added
          chiefComplaints: patientData.chiefComplaints,

          paymentRequired: patientData.paymentRequired,
          symptom: patientData.symptom,
          startTime: patientData.startTime,
          appointmentDelay: getDelay(patientData.startTime),
          endTime: patientData.endTime,
          date: patientData.date,
          appointmentId: patientData.appointmentId,
          observation: patientData.observation,
          slotNo: patientData.slotNo,
          consultationStartTime: patientData.consultationStartTime,
          consultationEndTime: patientData.consultationEndTime,
          consultationType: patientData.consultationType,
          caseId: patientData.caseId,
          doctorName: patientData.doctorName,
          doctorSpeciality: patientData.doctorSpeciality,
          amountPayable: patientData.amountPayable,
          amountCollected: patientData.amountCollected,
          modeOfPayment: patientData.modeOfPayment,
          patientName: patientData.patientName,
          firstConsultationDate: patientData.firstConsultationDate,
          lastConsultationDate: patientData.lastConsultationDate,

          firstName: patientData.firstName,
          middleName: patientData.middleName,
          lastName: patientData.lastName,
          fatherName: patientData.fatherName,
          dateOfBirth: patientData.dateOfBirth,

          email: patientData.email,
          mobile: patientData.mobile,
          countryCode: patientData.countryCode,

          guardianFirstName: patientData.guardianFirstName,
          guardianLastName: patientData.guardianLastName,
          guardianContactNumber: patientData.guardianContactNumber,
          source: patientData.source,

          videoURL: patientData.videoURL,
          vitals: patientData.vitals,
          prescriptionVM: patientData.prescriptionVM,
          reports: patientData.reports,
          paymentVM: patientData.paymentVM,
          personalVisitNeeded: patientData.personalVisitNeeded,
          nextAppointmentDate: patientData.nextAppointmentDate,
          patientInfoVM: patientData.patientInfoVM,

          patientDeatils: {
            advice: patientData.advice,
            patientData: patientData,
            appointmentId: patientData.appointmentId,
            patientId: patientData.patientId,
            caseId: patientData.caseId,
            docId: patientData.docId,
            videoURL: patientData.videoURL,

            patient: {
              patientId: patientData.patientId,
              name: patientData.patientName,
              gender: patientData.gender,
              age: patientData.age,
              issue: patientData.issue,
            },
            vitals: patientData.vitals,
            reports: patientData.reports,
            prescriptionVM: patientData.prescriptionVM,
            observation: patientData.observation ? patientData.observation : "",
            issue: patientData.issue,
            patientMedicalHistory: {
              patientName: patientData.patientName,
              habits: patientData.habits,
              preExistingConditions: patientData.preExistingConditions,
              drugHistory: patientData.drugHistory,
              drugAllergy: patientData.drugAllergy,
              parentalHistory: patientData.parentalHistory,
              occupation: patientData.occupation,
            },
          },

          printPrescription: patientData,
        };
      });

      setPdetails(transformedPatients[0]);
      console.log({ transformedPatients: transformedPatients });
    }
  };

  useEffect(() => {
    getPDetails();
  }, [props.patientDetails]);
  console.log({ pDetails: pDetails });
  return (
    <Fragment>
      <Fragment>
        <Box component={"iframe"} sx={{ width: 0, height: 0, display: "none" }}>
          <Box
            ref={componentRef}
            sx={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }}
          >
            <PrintReportComponent patientDetails={pDetails} />
          </Box>
        </Box>
      </Fragment>
      {/* ---------- Submit or End Button-------------*/}
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          color={props.label === "END CONSULTATION" ? "error" : "primary"}
          variant="outlined"
          onClick={handleClickOpen}
          sx={{
            height: 40,
            borderRadius: "23px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontWeight: 600,
              color: buttonColor,
              fontSize: "14px",
            }}
          >
            {props.label}
          </Typography>
        </Button>

        {/* ---------- Show Prescription Dialog box-------------*/}
        <Dialog
          fullWidth
          maxWidth={showPrescription ? "md" : "xs"}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" align="center">
            {content1}
          </DialogTitle>

          {showPrescription ? (
            <DialogContent dividers>
              <ShowPrescriptions
                patientId={props.patientId}
                appointmentId={props.appointmentId}
                caseId={props.caseId}
                patientDetails={pDetails}
              />
            </DialogContent>
          ) : (
            ""
          )}

          <DialogActions>
            {showPrescription ? (
              <Stack
                sx={{ width: "100%" }}
                direction="row"
                display="flex"
                justifyContent="center"
                spacing={2}
              >
                {props.patientDetails?.consultationType === "WALKIN" ? (
                  <Button
                    variant="contained"
                    sx={{ width: 90 }}
                    onClick={handlePrint}
                  >
                    Print!
                  </Button>
                ) : (
                  ""
                )}

                <Button
                  onClick={goBackToPatientPage}
                  variant="outlined"
                  size="small"
                >
                  Submit
                </Button>
                <Button
                  onClick={handleClose}
                  autoFocus
                  variant="outlined"
                  size="small"
                >
                  Cancel
                </Button>
              </Stack>
            ) : (
              <Fragment>{content}</Fragment>
            )}
          </DialogActions>
        </Dialog>
      </Box>
    </Fragment>
  );
};

export default EndConsultation;
