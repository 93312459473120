import {
    Autocomplete,
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Slide,
    Snackbar,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PrescriptionTemplateList from "./prescriptionTemplateList";
import React, { forwardRef, Fragment, useCallback, useEffect, useState } from "react";
import { BASE_URL } from "../assets/constantsFile";
import PrescriptionTemplateFormCreate from "./templateForms/prescriptionTemplateFormCreate";
import TableHeader from "./templateComponents/tableHeader";
import MuiAlert from "@mui/material/Alert";
import { saveData } from "../services/api/postApi";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PrescriptionTemplateMainComponent = (props) => {
    const [templateContent, setTemplateContent] = useState(<PrescriptionTemplateList />);

    const [openNotice, setOpenNotice] = useState(false);
    const [updateList, setUpdateList] = useState(false);
    const [severity, setSeverity] = useState("info");
    const [message, setMessage] = useState("");

    const handleClickNotice = () => {
        setOpenNotice(true);
    };

    const handleCloseNotice = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenNotice(false);
    };

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function addNewPrescriptionHandler(newPrescription, templateNameAdd) {
        if (templateNameAdd) {
            const pres = {
                docId: localStorage.getItem("DOCID"),
                templateName: templateNameAdd,
                medicines: newPrescription,
            };

            const response = await saveData(BASE_URL + "doctor/prescription/template", pres);
            if (response.error) {
                setSeverity("error");
                setMessage("Template create failed!");
                setOpenNotice(true);
            } else {
                setSeverity("success");
                setMessage("Template created successfully!");
                setOpenNotice(true);
                setUpdateList(true);
            }
            const data = await response.data;
        }
    }

    useEffect(() => {
        setTemplateContent(<PrescriptionTemplateList key={Math.floor(Math.random() * 10)} />);
    }, [updateList]);

    return (
        <Container maxWidth={false} disableGutters>
            <Snackbar open={openNotice} autoHideDuration={6000} onClose={handleCloseNotice}>
                <Alert onClose={handleCloseNotice} severity={severity} sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>

            <Box>
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box sx={{ mt: 1, minHeight: "8vh", p: 1 }}>
                            <Grid container spacing={0}>
                                <Grid item lg={8}></Grid>
                                <Grid item lg={4}>
                                    <Stack direction="row" justifyContent="flex-end" alignItems="center">
                                        <Button
                                            startIcon={<AddIcon sx={{ color: "#fff" }} />}
                                            variant="contained"
                                            sx={{ background: "#208F94" }}
                                            onClick={handleClickOpen}
                                        >
                                            <Typography sx={{ color: "#fff" }}>Add new template</Typography>
                                        </Button>
                                    </Stack>

                                    <Fragment>
                                        <Dialog
                                            maxWidth={"md"}
                                            open={open}
                                            onClose={handleClose}
                                            TransitionComponent={Transition}
                                        >
                                            <DialogTitle sx={{ background: "#D9D9D9" }} id="alert-dialog-title">
                                                Create New Template
                                            </DialogTitle>

                                            <DialogContent dividers>
                                                <Box sx={{ flexGrow: 1, minHeight: "10vh" }}>
                                                    <PrescriptionTemplateFormCreate
                                                        onAddPrescriptionTemplate={addNewPrescriptionHandler}
                                                    />
                                                </Box>
                                            </DialogContent>

                                            <DialogActions>
                                                <Button onClick={handleClose} variant="outlined" size="small">
                                                    cancel
                                                </Button>
                                                <Button
                                                    autoFocus
                                                    onClick={handleClose}
                                                    form="myFormCreate"
                                                    type="submit"
                                                    variant="outlined"
                                                    size="small"
                                                >
                                                    save
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </Fragment>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box
                            sx={{
                                borderTop: "3px solid #208F94",
                                p: 2,
                                maxHeight: "80vh",
                                overflow: "auto",
                                "&::-webkit-scrollbar": {
                                    display: "none",
                                },
                            }}
                        >
                            {templateContent}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default PrescriptionTemplateMainComponent;
