import { Box, Grid } from "@mui/material";
import React, { Fragment } from "react";
import CustomTitles from "../../../global/titles/customTitles";
import HealthSessionMain2 from "../comp/healthSessionMain2";

const BookSessions = () => {
  return (
    <Box>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomTitles title="Book Sessions" />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <HealthSessionMain2 />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BookSessions;
