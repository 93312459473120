import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Grid, Tab, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import CurrentCaseIndex from "../../currentCaseInfo/currentCaseIndex";
import EndConsultation from "../../endConsultation/endConsultation";
import OldCaseIndex from "../../oldCaseInfo/oldCaseIndex";
import StethoStream from "../../stethoStreaming/stethoStream";
import PatientInfoBar from "../components/patientInfoBar";

const PrimaryTabComp = ({
  buttonLabel = "SUBMIT PRESCRIPTION",
  patient,
  patientDetails,
  source,
}) => {
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Box>
        <Grid container>
          {source === "CONSULTATIONWITHOUTVIDEO" ? (
            <Grid item lg>
              <PatientInfoBar patient={patient} />
            </Grid>
          ) : null}

          <Grid item lg>
            <Grid container display="flex" alignItems="center">
              <Grid item lg={6}>
                <TabList
                  //centered
                  onChange={handleChange}
                  sx={{
                    ".Mui-selected": {
                      backgroundColor: "#fff ! important",
                      "& p": {
                        px: 2,
                        py: 1,
                        color: "#fff",
                        background: "#127DDD",
                        borderRadius: 6,
                      },
                    },
                  }}
                >
                  <Tab
                    value="1"
                    label={
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: 12,
                        }}
                      >
                        Current Case Info
                      </Typography>
                    }
                  />
                  <Tab
                    value="2"
                    label={
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: 12,
                        }}
                      >
                        Old Case Info
                      </Typography>
                    }
                  />
                </TabList>
              </Grid>
              <Grid item lg={2} display="flex" justifyContent="center">
                <StethoStream
                  patientId={patientDetails.patientId}
                  appointmentId={patientDetails.appointmentId}
                  caseId={patientDetails.caseId}
                />
              </Grid>
              <Grid item lg={4} display="flex" justifyContent="flex-end">
                <EndConsultation
                  patientId={patientDetails.patientId}
                  appointmentId={patientDetails.appointmentId}
                  caseId={patientDetails.caseId}
                  patientDetails={patientDetails}
                  label={buttonLabel}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <TabPanel value="1" sx={{ p: 1 }}>
          <CurrentCaseIndex patient={patient} patientDetails={patientDetails} />
        </TabPanel>
        <TabPanel value="2" sx={{ p: 1 }}>
          <OldCaseIndex
            patientId={patientDetails.patientId}
            appointmentId={patientDetails.appointmentId}
            caseId={patientDetails.caseId}
          />
        </TabPanel>
      </Box>
    </TabContext>
  );
};

export default PrimaryTabComp;
