import { Fragment } from "react";
import CustomSelect from "../../../../../global/components/customSelect";

const EmploymentTypeFilter = ({
  selectedEmploymentType,
  setSelectedEmploymentType,
  corpId = localStorage.getItem("CORPID"),
}) => {
  const employmentTypeOptions = [
    { label: "ALL", value: "ALL" },
    {
      label:
        corpId === "c59a2fbc-bc3d-453c-aae4-313581793890"
          ? "STAFF"
          : corpId === "455f4926-ea38-476d-ad49-0c3587c65200"
          ? "OFFICERS"
          : "ONROLL",
      value: "ONROLL",
    },
    {
      label:
        corpId === "c59a2fbc-bc3d-453c-aae4-313581793890"
          ? "OFFROLL"
          : corpId === "455f4926-ea38-476d-ad49-0c3587c65200"
          ? "WORKERS"
          : "CONTRACTOR",
      value: "CONTRACTOR",
    },
  ];
  return (
    <Fragment>
      <CustomSelect
        value={selectedEmploymentType}
        setvalue={setSelectedEmploymentType}
        options={employmentTypeOptions}
        label="Employment Type"
      />
    </Fragment>
  );
};

export default EmploymentTypeFilter;
