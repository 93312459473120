import React from "react";
import Slider from "react-slick";
import ultratechColor from "../../assets/ultratechColor.png";
import caseColor from "../../assets/caseColor.png";
import maanColor from "../../assets/maanColor.png";
import arneelColor from "../../assets/arneelColor.png";
import shaktiColor from "../../assets/shaktiColor.png";
import nationalColor from "../../assets/nationalColor.png";
import liugongColor from "../../assets/liugongColor.png";
import bpclColor from "../../assets/bpclColor.png";
import eicherColor from "../../assets/eicherColor.png";
import forceColor from "../../assets/forceColor.png";
import landtColor from "../../assets/landtColor.png";
import mahindraColor from "../../assets/mahindraColor.png";
import panasonicColor from "../../assets/panasonicColor.png";
import { Box, Stack } from "@mui/material";

const Slider1 = () => {
  const clients = [
    { imgHovered: ultratechColor, imgHovered2: bpclColor },
    { imgHovered: caseColor, imgHovered2: eicherColor },
    { imgHovered: maanColor, imgHovered2: forceColor },
    { imgHovered: arneelColor, imgHovered2: landtColor },
    { imgHovered: shaktiColor, imgHovered2: liugongColor },
    { imgHovered: nationalColor, imgHovered2: panasonicColor },
    { imgHovered: liugongColor, imgHovered2: mahindraColor },
  ];

  const clients2 = [
    { imgHovered: bpclColor },
    { imgHovered: eicherColor },
    { imgHovered: forceColor },
    { imgHovered: landtColor },
    { imgHovered: mahindraColor },
    { imgHovered: panasonicColor },
    { imgHovered: liugongColor },
  ];

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    swipeToSlide: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <Box>
      <Slider {...settings}>
        {clients.map((client, index) => (
          <Box key={index} sx={{ paddingInline: "25px", paddingBlock: "50px" }}>
            <Stack spacing={3}>
              <img src={client.imgHovered} alt={`client-${index}`} style={{ height: 82 }} />
              <img src={client.imgHovered2} alt={`client-${index}`} style={{ height: 82 }} />
            </Stack>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default Slider1;
