import { Box, Card, CardMedia } from "@mui/material"
import { useEffect, useState } from "react"

const ProfileImage = (props) => {

    const [profileData, setProfileData] = useState(props.profileData);

    const [imageURL, setImageURL] = useState("");

    useEffect (() => {

        if(props.profileData && props.profileData.profileInfo){
            setImageURL(props.profileData.profileInfo.imageURL);
        }


    },[props.profileData])

    return (

        <Box>
        <Card sx={{ display: "flex" }}>
          <CardMedia
            component="img"
            sx={{ height: "72vh" }}
            image={imageURL}
            alt="profile IMG"
          />

        </Card>
        </Box>
    );
}
 
export default ProfileImage;